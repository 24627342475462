import React, { useEffect, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Link, useNavigate } from 'react-router-dom';
import Highlighter from 'react-highlight-words';
import { individualSearch } from '../../../services/searchService';
import { processNavigation } from './helper';
import { channelLabelSelector } from '../../../utils/helpers';

export const WebView = ({ item, highlightWords }) => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);

  const handleTypeSearch = (type, id, name) => {
    individualSearch(type, id)
      .then(response => {
        if (response?.data?.data) {
          const navigateToResultPage = processNavigation(type);
          navigate(`${navigateToResultPage}`, { state: { id, name, search: true } });
        }
      })
      .catch(error => console.error(error));
  };

  useEffect(() => {
    setRefresh(!refresh);
  }, [highlightWords]);

  return (
    <div
      className="custom-grid-columns mb-2  grid cursor-pointer gap-4 rounded-[9px] border border-solid border-white200 bg-white px-4.5 py-5 hover:bg-primaryLight md:hidden"
      onClick={() =>
        item.type === 'accountUser'
          ? handleTypeSearch(item.type, item.id, item.name)
          : handleTypeSearch(item.type, item.referenceId, item.name)
      }
    >
      <div className="flex items-center">
        {/* <img src={item.companyLogo} alt={item.detailsTitle} /> */}
        <Tippy content={item.name} placement="bottom" arrow={true}>
          <h2 className="ml-2 max-w-[150px] truncate text-xs font-medium leading-4.5 text-gray-dark">
            <Highlighter
              key={item}
              searchWords={highlightWords}
              highlightStyle={{ fontWeight: 'bold' }}
              autoEscape={true}
              textToHighlight={item.name}
            />
          </h2>
        </Tippy>
        <ul className="text-gray-1500 ml-6 list-disc text-xs font-medium leading-4.5">
          <li>
            <Link
              style={{ color: 'gray' }}
              onClick={() =>
                item.type === 'accountUser'
                  ? handleTypeSearch(item.type, item.id, item.name)
                  : handleTypeSearch(item.type, item.referenceId, item.name)
              }
            >
              {item.type.charAt(0).toUpperCase() + item.type.slice(1)}
            </Link>
          </li>
        </ul>
      </div>
      {/* <div>
        <p className="text-gray-1500 truncate text-xs font-medium leading-4.5" title={item.pagePermission}>
          {item.pagePermission}
        </p>
      </div> */}

      {item.channelType && (
        <div className="flex flex-col items-center">
          <p className="text-gray-1500 truncate text-xs font-medium leading-4.5" title={item.channelType}>
            {channelLabelSelector(item.channelType)}
          </p>
        </div>
      )}

      {/* Commented for future needs */}

      {/* <div className="flex flex-col items-center">
        <p className="text-gray-1500 truncate text-xs font-medium leading-4.5" title={item.pages}>
          {item.pages}
        </p>
      </div> */}
      {/* <div>
        <p className="text-gray-1500 truncate text-xs font-medium leading-4.5" title={item.accountDetails}>
          {item.accountDetails}
        </p>
      </div> */}
    </div>
  );
};
