import React, { useContext, useEffect, useState } from 'react';
import { constants } from '../constants';
import UserActivityList from '../UserActivityList';
import FilterComponentDP from '../../../components/FilterComponentDP';
import { SocketContext } from '../../../context/socketIoContext/socketIoContext';
import ChannelListContext from '../../../context/channelListContext';
import { getAlerts } from '../../../services/alertsService';
import { formatChannelSelectList } from '../../Reports/helper';
import { processTimestamp } from './helper';
import { useToastBox } from '../../../customHooks/useToastBox';

const AlertSummary = ({ classes, isFilter, setFilter, isLoading }) => {
  const { socket, alerts, setAlerts } = useContext(SocketContext);
  const { channelList } = useContext(ChannelListContext);
  const [channelOptions, setChannelOptions] = useState([]);
  const [channelValue, setChannelValue] = useState('');
  const [alertFilter, setAlertFilter] = useState({ channel: '', workspace: '' });
  const [filteredAlert, setFilteredAlert] = useState(alerts);
  const { handleErrorToast } = useToastBox();

  const onApply = () => {
    setAlertFilter({ ...alertFilter, channel: channelValue });
    setFilteredAlert(
      alerts.filter(alert => {
        return alert.channel === channelValue;
      })
    );
    setFilter(false);
  };

  useEffect(() => {
    if (channelList?.length > 0) {
      setChannelOptions(formatChannelSelectList(channelList, true));
    }

    if (!alerts.length) {
      getAlerts()
        .then(response => {
          const { data } = response.data;
          const processedAlertsData = processTimestamp(data);

          setAlerts(processedAlertsData);
        })
        .catch(error => {
          handleErrorToast(error.message);
        });
    }
  }, []);

  return isLoading ? null : (
    <div className={`${classes.alertSummary} ${classes.card} ${classes.customVerticalScrollbar}`}>
      <div className={classes.alertHeader}>
        <h3>{constants.alertSummary.title}</h3>
        <button aria-label="Filter" className={`btn-link ${classes.viewAll}`} onClick={() => setFilter(true)}>
          {constants.alertSummary.filterName}
        </button>
        {isFilter && (
          <FilterComponentDP
            setFilter={setFilter}
            channelOptions={channelOptions}
            setChannelValue={setChannelValue}
            onApply={onApply}
          />
        )}
      </div>
      <UserActivityList
        classes={classes}
        alertsArray={alertFilter.channel ? filteredAlert : alerts}
        socket={socket}
        alertFilter={alertFilter}
        isLoading={isLoading}
      />
    </div>
  );
};

export default AlertSummary;
