export const resetConstants = {
  // loginTitles: ["email", "password"],
  title: 'Reset Password',
  shortTitle: 'Reset',

  emailModal: {},
  otpModal: {
    subHeading: 'Authenticate Your Account',
    details:
      'Protecting your data is our highest priority. Please confirm your account by entering the authentication code sent to your registered mobile number and email',
    resendCode: 'Resend Code',
  },
  validationErrorMessages: {
    emptyString: '',
    resetError: 'resetError',

    email: {
      required: 'Please enter a  email address',
      validateError: 'Please enter a valid email address',
      title: 'email',
      verifyError: 'Something went wrong with the email verification',
    },
    password: {
      required: 'Please enter your password',
      title: 'password',
    },
    confirmPassword: {
      title: 'confirmPassword',
    },
  },
};
