/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import classes from './dashboard.module.scss';
import ChannelSubbed from './ChannelSubbed';
import WorkSpaces from './WorkSpaces';
import AlertBox from './AlertBox';
import { constants } from './constants';
import AlertSummary from './AlertSummary';
import ChannelList from '../../components/ChannelSetup';
import { subscriptionPlans } from '../../constants/constants';
import CommonDashboard from '../../components/CommonDashboard';
import useAuth from '../../customHooks/useAuth';
import Loader from '../../components/Loader';
import { getUserInfo } from '../../utils/getUserInfo';
import { getBannerDisplayDuration, displayBanner, isValidISOString } from '../../utils/helpers';

const Dashboard = () => {
  let subscriptionType;
  const userInfo = JSON.parse(getUserInfo());
  const subscriptionCode = userInfo?.user?.subscriptionType;
  if (subscriptionCode) {
    subscriptionType = CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8);
  }
  const [isFilter, setFilter] = useState(false);
  const [workSpaceLoaded, setWorkSpaceLoaded] = useState(subscriptionType == subscriptionPlans.individual ? true : false);
  const [channelLoaded, setChannelLoaded] = useState(false);
  const [redirectLoaded, setRedirectLoaded] = useState(true);
  const { auth } = useAuth();

  const userName = userInfo.user.name;

  // render,setrender for channel summary update
  const [render, setRender] = useState(true);
  sessionStorage.setItem('page', 'Home');

  const trailEndDate = userInfo?.subscriptionDetails?.trialEnd ? userInfo?.subscriptionDetails?.trialEnd : null;

  return (
    <>
      <CommonDashboard hideHeader={(!channelLoaded || !workSpaceLoaded) && !redirectLoaded}>
        {(!channelLoaded || !workSpaceLoaded || !redirectLoaded) && <Loader />}
        {(channelLoaded || workSpaceLoaded) && redirectLoaded && (
          <>
            <h2 className={classes.titleMob}>
              {constants.welcomeMessageStart}
              {auth.user?.user?.name ? auth.user.user.name : userName}
            </h2>

            <div className={classes.alertMob}>
              <AlertBox classes={classes} />
            </div>
          </>
        )}

        {/* render,setrender for channel summary update */}
        <ChannelList
          render={render}
          setRender={setRender}
          isLoading={!channelLoaded || !workSpaceLoaded || !redirectLoaded}
          onLoad={() => setRedirectLoaded(true)}
          setLoader={() => setRedirectLoaded(false)}
        />
        <div className={classes.coreConsole}>
          <div className={classes.detailedConsole}>
            {(channelLoaded || workSpaceLoaded) && redirectLoaded && (
              <>
                <h2 className={classes.title}>
                  {constants.welcomeMessageStart}
                  {auth.user?.user?.name ? auth.user.user.name : userName}
                </h2>
                {isValidISOString(trailEndDate) && displayBanner(trailEndDate) ? (
                  <div className={classes.alertBanner}>
                    Your free trial will end in {getBannerDisplayDuration(trailEndDate)}.
                  </div>
                ) : null}
              </>
            )}
            {/* render,setrender for channel summary update */}
            {redirectLoaded && (
              <ChannelSubbed
                classes={classes}
                render={render}
                onLoad={() => setChannelLoaded(true)}
                setLoader={() => setChannelLoaded(false)}
                isLoading={!channelLoaded || !workSpaceLoaded}
              />
            )}
            {subscriptionType !== subscriptionPlans?.individual && redirectLoaded && (
              <WorkSpaces
                classes={classes}
                onLoad={() => setWorkSpaceLoaded(true)}
                isLoading={!channelLoaded || !workSpaceLoaded}
              />
            )}
            {(channelLoaded || workSpaceLoaded) && redirectLoaded && (
              <div className={classes.alertBoxWeb}>
                <AlertBox classes={classes} />
              </div>
            )}
          </div>
          {(channelLoaded || workSpaceLoaded) && redirectLoaded && (
            <AlertSummary
              classes={classes}
              isFilter={isFilter}
              setFilter={setFilter}
              isLoading={!channelLoaded || !workSpaceLoaded}
            />
          )}
        </div>
      </CommonDashboard>
    </>
  );
};
export default Dashboard;
