import blueRidge from '../../assets/temp-images/blueRidge.jpg';
import IBMIcon from '../../assets/temp-images/ibm.png';
import InfyIcon from '../../assets/temp-images/infy.png';

export const navData = [
  {
    userType: 'Workspace A',
    highlight: true,
    protectOn: false,
    pathHistory: [{ title: '3 Admin Users' }],
  },
];
export const currentUser = 'Steveharvey911@gmail.com';
export const textConstants = {
  googleTagManager: 'googletagmanager',
  googleAnalytics: 'ganalytics',
  noData: 'No permissions available.',
  noPermission: 'You do not have the required admin permissions to view other users of this account.',
  noAccounts: 'No accounts found',
  noAccess: 'User does not have permission to access workspace',
  addUser: 'Add User',
  addUserChannel: 'Add User to Channel',
  facebookAds: 'facebookads',
  facebookPages: 'facebookpages',
  linkedinPages: 'linkedinpages',
  linkedinAds: 'linkedinads',
  googleAds: 'googleads',
  googleSearchConsole: 'googlesearchconsole',
  googleMyBusiness: 'googlemybusiness',
  twitterAds: 'twitterads',
  googleMerchantCentre: 'googlemerchantcenter',
  alertMessage: 'Are you sure you want to remove the user from ',
  alertTitle: 'Alert!',
  requestToRemove: 'You do not have the right access. Request to remove the user?',
  gadsNoData: 'Account setup in progress.',
};
export const pageList = [
  {
    title: 'Infosys Blog',
    imgUrl: InfyIcon,
    roles: [
      {
        channelName: 'Admin',
        pagesDetails: [
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
            removed: true,
          },
          {
            logo: blueRidge,
            title: 'SJakesulla@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
          {
            logo: blueRidge,
            title: 'Charliemunger23@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
        ],
      },
      {
        channelName: 'Editor',
        pagesDetails: [
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
        ],
      },
      {
        channelName: 'Viewer',
        pagesDetails: [
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'IBM Facebook',
    imgUrl: IBMIcon,
    roles: [
      {
        channelName: 'Admin',
        pagesDetails: [
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],

            removed: true,
          },
          {
            logo: blueRidge,
            title: 'SJakesulla@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
          {
            logo: blueRidge,
            title: 'Charliemunger23@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
        ],
      },
      {
        channelName: 'Editor',
        pagesDetails: [
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
        ],
      },
      {
        channelName: 'Viewer',
        pagesDetails: [],
      },
    ],
  },
];
