import React from 'react';
import CryptoJS from 'crypto-js';
import useAuth from '../../../customHooks/useAuth';
// import { constants } from '../../../constants/constants';
import { useNavigate } from 'react-router-dom';
import { profileConstants } from '../constants';
import { subscriptionPlans } from '../../../constants/constants';
import { getUserInfo } from '../../../utils/getUserInfo';
import Icons from '../../../assets/Icons';
import classes from '../../Dashboard/dashboard.module.scss';

const ProfilePreview = ({ setEditProfile, editProfile, setProfileInfo, nameInitials }) => {
  const navigate = useNavigate();
  const { auth } = useAuth();
  const userDetails = auth?.user?.user ? auth?.user?.user : JSON.parse(getUserInfo())?.user;

  let subscriptionType;
  const userInfo = JSON.parse(getUserInfo());
  const subscriptionCode = userInfo?.user?.subscriptionType;
  if (subscriptionCode) {
    subscriptionType = CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8);
  }

  const handleEdit = () => {
    setProfileInfo(userDetails);
    setEditProfile(!editProfile);
  };

  return (
    <>
      <div className="flex w-full flex-col overflow-hidden bg-gray-light1 md:-mx-5 md:-mt-4 md:mb-4 md:w-[calc(100%+40px)]  md:overflow-visible">
        <div className="flexCenter justify-between border-b border-white200 bg-white p-4 sm:flex-col sm:items-start sm:justify-start ">
          <div className="flexCenter">
            <span className=" text-base font-semibold leading-6 text-gray-dark">{profileConstants.profileHeading}</span>
          </div>
          <div className="flex space-x-2 md:hidden">
            <button
              className="btn-secondary-outline xs:w-full xs:justify-center"
              onClick={handleEdit}
              data-testid="edit-button"
            >
              {profileConstants.editProfile}
            </button>
            {userDetails.owner && subscriptionType !== subscriptionPlans.enterprise && (
              <button
                className="btn-secondary-outline xs:w-full xs:justify-center"
                onClick={() => {
                  navigate('/billing');
                }}
                data-testid="billing-button"
              >
                {profileConstants.billingInfo}
              </button>
            )}
          </div>
        </div>
        <div className=" p-4 md:pt-8">
          <div className=" flex items-center rounded-lg border border-white200 bg-white p-8 md:flex-col md:justify-center md:border-none md:bg-transparent md:p-0">
            <div className=" flex h-[130px] w-[130px] min-w-[130px] items-center justify-center overflow-hidden rounded-full bg-gray-dark1 text-5.3xl font-semibold leading-15 text-white">
              {userDetails.profileImage ? (
                <img className="h-[130px] w-[130px] object-cover" src={userDetails.profileImage} alt="profile image"></img>
              ) : (
                <span>{nameInitials}</span>
              )}
            </div>
            <div className="grid w-full grid-cols-3 gap-6 px-10 md:grid-cols-1 md:px-0 md:pt-10">
              <div className=" flex flex-col">
                <label className=" text-xs leading-4.5 text-gray-medium">{profileConstants.firstName}</label>
                <p className=" mt-[0.125rem] text-xs font-medium leading-4.5 text-gray-dark">{userDetails.firstName}</p>
              </div>
              <div className=" flex flex-col">
                <label className=" text-xs leading-4.5 text-gray-medium">{profileConstants.lastName}</label>
                <p className=" mt-[0.125rem] text-xs font-medium leading-4.5 text-gray-dark">{userDetails.lastName}</p>
              </div>
              <div className=" flex flex-col">
                <label className=" text-xs leading-4.5 text-gray-medium">Alternate Email Address (Optional)</label>
                <p className=" mt-[0.125rem] text-xs font-medium leading-4.5 text-gray-dark">Nil</p>
              </div>
              <div className=" flex flex-col">
                <label className=" text-xs leading-4.5 text-gray-medium">{profileConstants.phoneNo}</label>
                <p className=" mt-[0.125rem] text-xs font-medium leading-4.5 text-gray-dark">
                  {`${userDetails.countryCode} ${userDetails.phone}`}
                </p>
              </div>
              <div className=" flex flex-col">
                <label className=" text-xs leading-4.5 text-gray-medium">{profileConstants.email}</label>
                <p className=" mt-[0.125rem] text-xs font-medium leading-4.5 text-gray-dark">{userDetails.email}</p>
              </div>
              <div className="hidden space-y-3 md:flex md:space-y-0 md:space-x-3 xs:flex-col xs:space-y-3 xs:space-x-0">
                <button
                  className="btn-secondary-outline block h-11 md:w-full xs:justify-center"
                  data-testid="edit-button1"
                  onClick={handleEdit}
                >
                  {profileConstants.editProfile}
                </button>
                {userDetails.owner && subscriptionType !== subscriptionPlans.enterprise && (
                  <button
                    className="btn-secondary-outline h-11 justify-center md:w-full"
                    onClick={() => {
                      navigate('/billing');
                    }}
                    data-testid="billing-button"
                  >
                    {profileConstants.billingInfo}
                  </button>
                )}
              </div>
            </div>
          </div>
          <div className="pt-4">
            <div className={`${classes.alert} ${classes.success}`}>
              <div className=" flex items-center">
                <div className={classes.alertIcon}>
                  <Icons.InfoIcon />
                </div>
                <div className={classes.alertTitle}>
                  <div className=" py-3 pl-3 text-sm italic leading-6 text-gray-600">
                    Click on the{' '}
                    <div className="-mb-1 inline-block px-2 pr-1">
                      <Icons.AddIcon />
                    </div>{' '}
                    next to a channel, to connect to Hydra.<br></br>A green tick means you are already connected, but you can
                    click on the
                    <div className="-mb-1 inline-block px-2 pr-1">
                      {' '}
                      <Icons.AddIcon />
                    </div>{' '}
                    to connect another email address.
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
export default ProfilePreview;
