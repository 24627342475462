/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Icons from '../../assets/Icons';
import { useNavigate } from 'react-router-dom';

const AboutUs = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="app-l-terms-holder">
        <section className="relative overflow-hidden bg-gradient-to-r from-primaryMedium to-primaryDark py-4 pt-6 ">
          <div className="relative z-10 mx-auto max-w-7xl px-5">
            <button
              className=" flex items-center text-sm font-medium text-white hover:opacity-50"
              onClick={() => navigate('/')}
            >
              <Icons.leftArrow />
              <span className=" ml-2.5">Back</span>
            </button>
            <h1 className=" m-0 p-0 text-2.1xl font-semibold leading-[2.75rem] text-white md:mt-1.5 md:text-base">
              About Us
            </h1>
          </div>
          <div className=" rotating absolute -bottom-27 -right-8.5 h-[180px] w-[180px] text-gray-medium1 md:-right-24 md:-bottom-28">
            <Icons.paymentShape1 />
          </div>
          <div className=" rotating absolute -top-27 -left-8.5 h-[180px] w-[180px] text-gray-medium1 md:-top-[133px] md:-left-17">
            <Icons.paymentShape1 />
          </div>
        </section>
        <section className="mx-auto max-w-7xl px-5 pt-5 pb-10">
          {' '}
          <div className="mb-3 mt-5 flex flex-col space-y-3">
            <p>
              Incorporated in March 2021, Hi Hydra Limited, also known as Hydra, is an innovative SaaS platform specifically
              designed to provide agencies and digital teams with the seamless and effortless monitoring and management of
              access permissions across the major social channels and ad accounts.
            </p>
          </div>
          <div className="mb-3 flex flex-col space-y-3">
            <p>Hydra was founded by experienced digital agency owners, SaaS marketers and entrepreneurs.</p>
          </div>
          <div className="mb-3 flex flex-col space-y-3">
            <p>
              Co-founder, Lana Thorne, came up with the initial idea as a frustrated digital agency finance director (and
              co-owner), shocked at the risks associated with her colleagues still having access to a client’s channels years
              after the agency had stopped working with them. Most of the risk sits with the client – namely, unauthorised
              access to sensitive competitive information, the ability to sabotage brand reputation and even a breach of GDPR
              and privacy obligations.
            </p>
          </div>
          <div className="mb-3 flex flex-col space-y-3">
            <p>
              Coupled with Co-founders, Justin Jon Thorne’s and Paul Court’s experience with onboarding new clients into a
              PPC and SEO agency, a prototype was developed during lockdown.
            </p>
          </div>
          <div className="mb-3 flex flex-col space-y-3">
            <p>
              The MVP available now is the culmination of two years’ worth of development and product/market fit validation
              and we are very proud of the result.
            </p>
          </div>
          <div className="mb-3 flex flex-col space-y-3">
            <p>We successfully closed our seed round in January 2023 and launched in alpha in September 2023.</p>
          </div>
          <div className="mb-3 flex flex-col space-y-3">
            <p>
              We understand the complexities and challenges associated with managing multiple social media accounts, as well
              as the importance of maintaining control over who has access to them.
            </p>
          </div>
          <div className="mb-3 flex flex-col space-y-3">
            <p>
              Whether you're a digital marketer responsible for multiple client accounts or an enterprise wishing to manage
              the potential risk associated with internal and external access to critical brand assets, Hydra provides a
              unique solution.
            </p>
          </div>
          <div className="mb-3 flex flex-col space-y-3">
            <p>
              With Hydra, you can monitor who has access, change permissions, add and remove users and produce reports from a
              single platform.
            </p>
          </div>
          <div className="mb-3 flex flex-col space-y-3">
            <p>
              Our alert functionality means admins know exactly who is added, removed, or is granted potentially risky
              access, even if that permission has been granted in the end channel.
            </p>
          </div>
          <div className="mb-3 flex flex-col space-y-3">
            <p>
              Our Workspace functionality allows teams to group together clients, brands or regions into easy to manage
              workspaces and provide visibility of who has access to what, to colleagues in IST, Risk and HR.
            </p>
          </div>
        </section>
      </section>
    </>
  );
};

export default AboutUs;
