import { API } from '../constants/api';
import { get } from '../utils/apiHandler';

export const getChannelCount = async () => {
  try {
    return (await get(API.channel_count)).data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getChannelPermissionList = async () => {
  try {
    return await (
      await get(`${API.channel_permission_list}`)
    ).data;
  } catch (error) {
    throw new Error(error.message);
  }
};
