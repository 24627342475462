import Bing from '../../assets/images/bingSquare.png';
import fbSquare from '../../assets/images/fbSquare.png';
import gAdsSquare from '../../assets/images/gAdsSquare.png';
import gAnalyticsSquare from '../../assets/images/gAnalyticsSquare.png';
import linkedinSquare from '../../assets/images/linkedinSquare.png';
import twitterSquare from '../../assets/images/twitterSquare.png';

export const channelJoined = [
  {
    title: 'Facebook',
    link: '#',
    pageCount: '3',
    permissionCount: '4',
    imgUrl: fbSquare,
  },
  {
    title: 'Twitter',
    link: '#',
    pageCount: '1',
    permissionCount: '4',
    imgUrl: twitterSquare,
  },
  {
    title: 'Google Ads',
    link: '#',
    pageCount: '2',
    permissionCount: '4',
    imgUrl: gAdsSquare,
  },
  {
    title: 'LinkedIn',
    link: '#',
    pageCount: '2',
    permissionCount: '4',
    imgUrl: linkedinSquare,
  },
  {
    title: 'Bing',
    link: '#',
    pageCount: '2',
    permissionCount: '4',
    imgUrl: Bing,
  },
  {
    title: 'Google Analytics',
    link: '#',
    pageCount: '2',
    permissionCount: '4',
    imgUrl: gAnalyticsSquare,
  },
];
export const workspaces = [
  {
    title: 'Workspace 1',
    link: '#',
  },
  {
    title: 'Workspace 2',
    link: '#',
  },
  {
    title: 'Workspace 3',
    link: '#',
  },
  {
    title: 'Workspace 4',
    link: '#',
  },
  {
    title: 'Workspace 5',
    link: '#',
  },
];

export const constants = {
  welcomeMessageStart: `Hello `,
  welcomeMessageEnd: ` Welcome back`,
  channelSubbed: {},
  workSpaces: {},
  alertBox: {
    button: 'Manage',
    pluralMessage: 'user license(s) have been used',
    singularMessage: 'user license has been used',
  },
  alertSummary: {
    title: 'Alerts Summary',
    filterName: 'Filter',
  },
  workspaceSummaryHeading: 'Workspaces',
  workspaceSummaryTitle: 'Workspace',
  Account: 'Account',
  Accounts: 'Accounts',
  Admin: 'Admin',
  Admins: 'Admins',
  workspaceViewAll: 'View All',
  createWorkspace: 'Create Workspace',
  message: {
    refreshChannelSuccess: 'Channel refreshed',
    noDataMessage: 'Setup your first channel',
  },
  label: {
    viewAll: 'View All',
    page: 'Page',
    account: 'Account',
    accounts: 'Accounts',
    pages: 'Pages',
    permission: 'Permission',
    permissions: 'Permissions',
    refreshChannel: 'Refresh Channel',
  },
  tooltipMessage: {
    viewChannel: 'View Channel',
  },
};
