import React from 'react';

import { Dialog, Transition } from '@headlessui/react';
import { Fragment } from 'react';
import Icons from '../../../assets/Icons';

export const AlertModal = ({
  isOpen,
  setIsOpen,
  heading,
  message,
  handleConfirm,
  confirmAlert,
  hideCancel,
  handleCancel = () => {},
}) => {
  function closeModal() {
    setIsOpen(false);
  }

  return (
    <>
      <Transition appear show={isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-25" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[500px] transform overflow-hidden rounded-2xl bg-white text-center align-middle shadow-xl transition-all">
                  <Dialog.Title
                    as="div"
                    className="alert-modal-header relative flex min-h-[6.5rem]  items-center justify-center overflow-hidden rounded-t-lg bg-red-700 p-4 text-black shadow-none md:min-h-[7.938rem]"
                  >
                    <div className="z-10 p-5 text-white">
                      <Icons.AlertIcon />
                    </div>
                  </Dialog.Title>

                  <div className="mt-4">
                    <p className=" px-5 pt-4 pb-4 text-base font-semibold text-black">{heading}</p>
                    {Array.isArray(message) ? (
                      message?.map((email, index) => (
                        <div key={index}>
                          <p className=" px-5 pt-2 text-base font-light text-black">{`${email.email} : ${email.message}`}</p>
                        </div>
                      ))
                    ) : (
                      <p className=" px-5 pt-2 text-base font-light text-black">{message}</p>
                    )}
                  </div>

                  <div className="footer-buttons flex w-full items-center  justify-center space-x-2 p-8 pt-8 md:pt-8">
                    {!hideCancel && (
                      <button
                        className="btn-secondary-outline mx-auto w-full  !min-w-0 border-red-700 text-red-700 hover:!bg-white hover:text-red-700"
                        onClick={() => {
                          closeModal();
                          handleCancel();
                        }}
                      >
                        Cancel
                      </button>
                    )}
                    <button
                      className={
                        !hideCancel
                          ? 'w-full !min-w-0 bg-red-700 text-center text-white'
                          : 'mx-auto w-full !min-w-0 bg-red-700 text-white'
                      }
                      onClick={() => {
                        closeModal();
                        handleConfirm();
                      }}
                    >
                      <span>{confirmAlert ? confirmAlert : 'Proceed'}</span>
                    </button>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
