import React from 'react';
import Icons from '../../assets/Icons';
import { Disclosure } from '@headlessui/react';

const HelpCard = ({ data }) => {
  return (
    <Disclosure key={data.id}>
      {({ open }) => (
        <>
          <Disclosure.Button
            className={`${
              open ? 'rounded-none rounded-t-lg border-b-0 !border-primary !bg-primaryLight' : ''
            } flex w-full items-center justify-between rounded-lg border border-white200 bg-white300 px-4 py-3 text-xs font-medium leading-4.5 text-gray-dark`}
          >
            <span className=" max-w-[90%] text-left">{data.heading}</span>
            <span className={`${open ? 'rotate-180 transform' : ''}`}>
              <span className={`${open ? 'hidden' : ''}`}>
                <Icons.PlusIcon />
              </span>
              <span className={`${open ? '!flex' : ''} hidden`}>
                <Icons.MinusIcon />
              </span>
            </span>
          </Disclosure.Button>
          <Disclosure.Panel className=" !-mt-[3px] rounded-b-lg border border-t-0 border-primary bg-primaryLight p-4 pt-0 text-xs leading-4.5 text-gray-medium">
            <span className="whitespace-pre-wrap leading-6 " dangerouslySetInnerHTML={{ __html: data.content }} />
          </Disclosure.Panel>
        </>
      )}
    </Disclosure>
  );
};
export default HelpCard;
