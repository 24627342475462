import { subbedChannelIconsConstants } from '../Dashboard/ChannelSubbed/constants';
import { getChannelLogo } from '../../utils/helpers';
import { monthNames } from './constants';

export const processChannelList = data => {
  const pageList = [];
  let pageDetails = [];
  data?.forEach(element => {
    const channel = element.channelType;
    const obj = { checked: true, channel: channel };
    switch (channel) {
      case subbedChannelIconsConstants.channelType.googleAdsTitle:
        obj.logo = subbedChannelIconsConstants.icons.googleAds;
        obj.channelName = subbedChannelIconsConstants.title.googleAdsTitle;
        obj.provider = subbedChannelIconsConstants.provider.googleAdsProvider;
        obj.roleLabel = subbedChannelIconsConstants.roleLabels.googleAdsRoleLabel;

        break;
      case subbedChannelIconsConstants.channelType.googleMerchant:
        obj.logo = subbedChannelIconsConstants.icons.googleMerchantCentre;
        obj.channelName = subbedChannelIconsConstants.title.googleMerchantTitle;
        obj.provider = subbedChannelIconsConstants.provider.googleAdsProvider;
        obj.roleLabel = subbedChannelIconsConstants.roleLabels.googleMerchantCenterRoleLabel;

        break;
      case subbedChannelIconsConstants.channelType.googleAnalyticsTitle:
        obj.logo = subbedChannelIconsConstants.icons.googleAnalytics;
        obj.channelName = subbedChannelIconsConstants.title.googleAnalyticsTitle;
        obj.provider = subbedChannelIconsConstants.provider.googleAnalyticsProvider;
        obj.roleLabel = subbedChannelIconsConstants.roleLabels.googleAnalyticsRoleLabel;
        obj.permissionLabel = subbedChannelIconsConstants.permissionLabels.googleAnalyticsPermissionLabel;

        break;
      case subbedChannelIconsConstants.channelType.googleBusiness:
        obj.logo = subbedChannelIconsConstants.icons.googleBusiness;
        obj.channelName = subbedChannelIconsConstants.title.googleBusinessTitle;
        obj.provider = subbedChannelIconsConstants.provider.googleBusinessProvider;
        obj.roleLabel = subbedChannelIconsConstants.roleLabels.googleMyBusinessRoleLabel;

        break;

      case subbedChannelIconsConstants.channelType.googleMyBusiness:
        obj.logo = subbedChannelIconsConstants.icons.googleBusiness;
        obj.channelName = subbedChannelIconsConstants.title.googleMyBusinessTitle;
        obj.provider = subbedChannelIconsConstants.provider.googleMyBusinessProvider;
        obj.roleLabel = subbedChannelIconsConstants.roleLabels.googleMyBusinessRoleLabel;

        break;

      case subbedChannelIconsConstants.channelType.googleSearchConsole:
        obj.logo = subbedChannelIconsConstants.icons.googleSearchConsole;
        obj.channelName = subbedChannelIconsConstants.title.googleSearchConsoleTitle;
        obj.provider = subbedChannelIconsConstants.provider.googleSearchProvider;
        obj.roleLabel = subbedChannelIconsConstants.roleLabels.googleSearchConsoleRoleLabel;

        break;

      case subbedChannelIconsConstants.channelType.googleTagManager:
        obj.logo = subbedChannelIconsConstants.icons.googleTagManager;
        obj.channelName = subbedChannelIconsConstants.title.googleTagManagerTitle;
        obj.provider = subbedChannelIconsConstants.provider.googleTagManagerProvider;
        obj.roleLabel = subbedChannelIconsConstants.roleLabels.googleTagManagerRoleLabel;
        obj.permissionLabel = subbedChannelIconsConstants.permissionLabels.googleTagManagerPermissionLabel;

        break;
      case subbedChannelIconsConstants.channelType.bingAds:
        obj.logo = subbedChannelIconsConstants.icons.bing;
        obj.channelName = subbedChannelIconsConstants.title.bingAds;
        obj.provider = subbedChannelIconsConstants.provider.bingAdsProvider;

        break;
      case subbedChannelIconsConstants.channelType.facebookAdTitle:
        obj.logo = subbedChannelIconsConstants.icons.facebookAds;
        obj.channelName = subbedChannelIconsConstants.title.facebookAdTitle;
        obj.provider = subbedChannelIconsConstants.provider.facebookAdProvider;
        obj.roleLabel = subbedChannelIconsConstants.roleLabels.facebookAdsRoleLabel;

        break;
      case subbedChannelIconsConstants.channelType.facebookPagesTitle:
        obj.logo = subbedChannelIconsConstants.icons.facebook;
        obj.channelName = subbedChannelIconsConstants.title.facebookPagesTitle;
        obj.provider = subbedChannelIconsConstants.provider.facebookPagesProvider;
        obj.roleLabel = subbedChannelIconsConstants.roleLabels.facebookPageLabel;

        break;
      case subbedChannelIconsConstants.channelType.linkedInAds:
        obj.logo = subbedChannelIconsConstants.icons.linkedin;
        obj.channelName = subbedChannelIconsConstants.title.linkedInAdsTitle;
        obj.provider = subbedChannelIconsConstants.provider.linkedInAdsProvider;
        obj.roleLabel = subbedChannelIconsConstants.roleLabels.linkedInPageLabel;

        break;
      case subbedChannelIconsConstants.channelType.linkedInPages:
        obj.logo = subbedChannelIconsConstants.icons.linkedin;
        obj.channelName = subbedChannelIconsConstants.title.linkedInPagesTitle;
        obj.provider = subbedChannelIconsConstants.provider.linkedInPagesProvider;
        obj.roleLabel = subbedChannelIconsConstants.roleLabels.linkedInPageLabel;

        break;

      case subbedChannelIconsConstants.channelType.twitterAds:
        obj.logo = subbedChannelIconsConstants.icons.twitter;
        obj.channelName = subbedChannelIconsConstants.title.twitterAdsTitle;
        obj.provider = subbedChannelIconsConstants.provider.twitterAdsProvider;
        obj.roleLabel = subbedChannelIconsConstants.roleLabels.twitterAdsRoleLabel;

        break;
      case subbedChannelIconsConstants.channelType.twitterPages:
        obj.logo = subbedChannelIconsConstants.icons.twitter;
        obj.channelName = subbedChannelIconsConstants.title.twitterPagesTitle;
        obj.provider = subbedChannelIconsConstants.provider.twitterPagesProvider;
        obj.roleLabel = subbedChannelIconsConstants.roleLabels.twitterAdsRoleLabel;

        break;

      default:
        break;
    }

    element.accounts?.forEach(element => {
      const channelDetails = getChannelLogo(channel);
      const page = {};
      page.channel = channel;
      page.logo = element?.picture ? element?.picture : channelDetails.imgUrl;
      page.id = element.id;
      page.accountId = element.accountId;
      page.title = element.accountName;
      page.lastLogin =
        element.location && element.lastLogin
          ? [
              {
                location: element.location,
                dateTime: processLastLoginDate(element.lastLogin),
              },
            ]
          : [
              {
                location: '-',
                dateTime: '',
              },
            ];
      page.roleOptions = [];
      page.role = element.role ? element.role : ['-'];
      page.permission = element.permission ? element.permission : ['-'];
      page.selected = false;
      page.mobileChecked = false;
      page.displayRole = element?.userDetails?.rolesLabel ? [element?.userDetails?.rolesLabel[0]] : ['-'];
      page.displayPermission = element?.userDetails?.containerAccess
        ? [element?.userDetails?.containerAccess[0].permission]
        : ['-'];
      page.roleLabel = obj.roleLabel;
      page.permissionLabel = obj.permissionLabel;

      pageDetails.push(page);
    });
    obj.pagesDetails = pageDetails;
    pageList.push(obj);
    pageDetails = [];
  });

  return pageList;
};

export const getCompanyList = data => {
  const companyList = [{ value: 'All', label: 'All' }];
  data?.forEach(element => {
    element?.pagesDetails?.forEach(name => {
      const obj = { value: name.title, label: name.title };
      if (!companyList.filter(e => e.value === name.title).length) {
        companyList.push(obj);
      }
    });
  });

  return companyList;
};

export const processLastLoginDate = date => {
  const newDate = new Date(date);
  const result = `${newDate.getDay()} ${monthNames[newDate.getMonth()]},${newDate.getUTCHours()}:${newDate.getMinutes()}`;
  return result;
};
