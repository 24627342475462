import React, { useState } from 'react';
import CommonDashboard from '../../components/CommonDashboard';
import ProfilePreview from './ProfilePreview';
import useAuth from '../../customHooks/useAuth';
import ChannelList from '../../components/ChannelSetup';
import ProfileEdit from './ProfileEdit';
import Loader from '../../components/Loader';

const Profile = () => {
  sessionStorage.setItem('page', 'profile');
  const { auth } = useAuth();
  const [editProfile, setEditProfile] = useState(false);
  const [profileInfo, setProfileInfo] = useState(false);
  const [loading, setIsLoading] = useState(false);

  const nameInitials = auth.user?.user?.name
    .match(/(\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toUpperCase();

  return loading ? (
    <Loader />
  ) : (
    <div className="md:bg-gray-light1">
      <CommonDashboard hideHeader={false}>
        <div
          className="md:order-1" // to hide channellist in profile page mobile view
        >
          <ChannelList shouldAuthenticate={true} setLoader={value => setIsLoading(value)} isLoading={false} />
        </div>

        {/* Profile View */}
        {editProfile === false && (
          <ProfilePreview
            setEditProfile={setEditProfile}
            editProfile={editProfile}
            setProfileInfo={setProfileInfo}
            nameInitials={nameInitials}
          />
        )}

        {/* Profile Edit */}
        {editProfile === true && (
          <ProfileEdit
            setEditProfile={setEditProfile}
            editProfile={editProfile}
            profileInfo={profileInfo}
            nameInitials={nameInitials}
          />
        )}
      </CommonDashboard>
    </div>
  );
};
export default Profile;
