import React, { useState, useEffect } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import classes from './messaging.module.scss';
import Icons from '../../assets/Icons';
import ChannelList from '../../components/ChannelSetup';
import CommonDashboard from '../../components/CommonDashboard';
import { inboxList, sentList } from './constants';
import CustomModal from '../../components/CustomModal';
import { listMessages } from '../../services/SuperAdmin/broadcastService';
import { markAsRead } from '../../services/SuperAdmin/broadcastService';
import Loader from '../../components/Loader';
import NoData from '../../components/Nodata';
//import { Disclosure } from '@headlessui/react';

const Messaging = () => {
  sessionStorage.setItem('page', 'Messaging');
  const [isLoading, setIsLoading] = useState(true);
  const [tabsetMessageType, setTabsetMessageType] = useState('Inbox');
  const [selectedMessage, setSelectedMessage] = useState();
  const [screenflow, setScreenflow] = useState(1);
  const [customModalOpen, setCustomModalOpen] = useState(false);
  const [currentView, setCurrentView] = useState(inboxList);
  const [messageList, setMessageList] = useState([]);
  const tabSwitcher = type => {
    setSelectedMessage(0);
    switch (type) {
      case 'Inbox':
        setCurrentView(inboxList);
        break;
      case 'Sent':
        setCurrentView(sentList);
        break;
      default:
        break;
    }
  };

  const formatDate = value => {
    const date = new Date(value);
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    let formattedDate = date.toLocaleString('en-US', options);
    return formattedDate;
  };

  useEffect(() => {
    listMessages()
      .then(response => {
        if (response?.status === 201 || response?.status === 200) {
          setMessageList(response?.data?.data);
          setIsLoading(false);
        }
      })
      .catch(err => {
        setIsLoading(false);
        console.log('err', err);
      });
    markAsRead()
      .then(response => {
        if (response?.status === 201 || response?.status === 200) {
          sessionStorage.setItem('message_count', 0);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  }, []);

  const BroadcastComponent = () => {
    return (
      <>
        <div className={classes.modalContent}>
          <div className={classes.formController}>
            <div className={`${classes.formControl}`}>
              <label>Subject</label>
              <input className={`${classes.formInput}`} placeholder="Enter the broadcast subject here" />
              <span className={`${classes.helptext} ${classes.error}`}>Fill the field</span>
            </div>
            <div className={`${classes.formControl}`}>
              <label>Send to</label>
              <div className={classes.broadcastWrapper}>
                <label className="form-checkbox -regular-mobile">
                  <input className="form-checkbox-input" type="checkbox" name="" />
                  <div className="form-check-circle">
                    <Icons.TickSmall />
                  </div>
                  <span className="label-text">Admin Users</span>
                </label>

                <label className="form-checkbox -regular-mobile">
                  <input className="form-checkbox-input" type="checkbox" name="" />
                  <div className="form-check-circle">
                    <Icons.TickSmall />
                  </div>
                  <span className="label-text">Viewers</span>
                </label>
              </div>
            </div>
            <div className={`${classes.formControl} ${classes.editor}`}>
              <label>Message</label>
              <Editor
                init={{
                  height: '100%',
                  menubar: false,
                  statusbar: false,
                  placeholder: 'Write your text here',
                  content_style: `body { font-family:"Poppins", sans-serif; font-size:14px;color:#272727 }`,
                }}
              />
            </div>
          </div>
        </div>
        <div className={classes.modalFooter}>
          <div className={classes.modalFtRight}>
            <button aria-label="Discard" className="btn-outline" onClick={() => setCustomModalOpen(false)}>
              <span>Discard</span>
            </button>
            <button aria-label="Send" className="btn-primary-1">
              <span>Send</span>
            </button>
          </div>
        </div>
      </>
    );
  };
  return isLoading ? (
    <Loader />
  ) : (
    <CommonDashboard classList={classes.messagingWrapper} hideHeader={false}>
      <span className={classes.dvOnly}>
        <ChannelList shouldAuthenticate={false} onLoad={() => {}} isLoading={false} />
      </span>
      <div className={classes.coreConsole}>
        <div className=" border-b border-white200 bg-white p-4">
          <h3 className=" cursor-default pl-5 !text-lg font-semibold md:pl-0">Broadcasts</h3>

          {/* <div className={classes.newBroadcast}>
            <button
              aria-label="New Broadcast"
              className={`btn-outline ${classes.viewAll}`}
              onClick={() => setCustomModalOpen(true)}
            >
              <span>New Broadcast</span> <Icons.Plus />
            </button>
          </div> */}
        </div>

        <div className="p-2 px-10 pl-4 pt-0 md:px-5 md:pt-0">
          <div className=" rounded-lg">
            {/* Expand and Collapse view for messages */}
            {/* <div className="custom-vertical-scrollbar inset-0 m-4 overflow-auto rounded-lg ">
              <div className="w-full">
                <div className=" space-y-4 ">
                  {messageList?.length > 0
                    ? messageList?.map((data, index) => (
                        <Disclosure key={index}>
                          {({ open }) => (
                            <>
                              <Disclosure.Button
                                className={`${
                                  open ? 'rounded-none rounded-t-lg border-b-0 !border-primary !bg-primaryLight' : ''
                                } flex w-full items-center justify-between rounded-lg border border-white200 bg-white300 px-4 py-3 text-xs font-medium leading-4.5 text-gray-dark md:bg-white`}
                              >
                                <h4 className=" flex w-full  items-center justify-between truncate text-left  text-base font-semibold leading-normal text-black">
                                  <div className="truncate pr-5">{data?.Message?.subject}</div>
                                  <div className="ml-auto   mr-4 flex whitespace-nowrap text-xs text-gray-dark1">
                                    {formatDate(data?.Message?.timestamp)}
                                  </div>
                                </h4>
                                <span className={`${open ? 'rotate-180 transform' : ''}`}>
                                  <span className={`${open ? 'hidden' : ''}`}>
                                    <Icons.PlusIcon />
                                  </span>
                                  <span className={`${open ? '!flex' : ''} hidden`}>
                                    <Icons.MinusIcon />
                                  </span>
                                </span>
                              </Disclosure.Button>
                              <Disclosure.Panel className=" !-mt-[3px] rounded-b-lg border border-t-0 border-primary bg-primaryLight p-4 pt-0 text-xs leading-4.5 text-gray-medium">
                                <h4 className="font-regular text-left text-sm leading-normal text-gray-dark">
                                  {data?.Message?.message}
                                </h4>
                              </Disclosure.Panel>
                            </>
                          )}
                        </Disclosure>
                      ))
                    : null}
                </div>
              </div>
            </div> */}

            <div className="custom-vertical-scrollbar flex max-h-[calc(100vh-210px)] flex-col space-y-3 overflow-auto p-5 pr-3">
              {messageList?.length > 0
                ? messageList?.map((data, index) => (
                    <div
                      key={index}
                      className="align-left relative flex flex-col justify-start rounded-xl border border-gray-light9/30 bg-white  shadow-5xl md:flex-col"
                    >
                      <h4 className=" mt-1 mb-1 flex justify-between truncate border-b border-gray-light11/50 p-3 px-4 py-2 text-left  text-base font-semibold leading-normal text-black">
                        <div className="truncate pr-5">{data?.Message?.subject}</div>
                        <div className="ml-auto   flex whitespace-nowrap text-xs text-gray-dark1">
                          {formatDate(data?.Message?.timestamp)}
                        </div>
                      </h4>
                      <div className="flex min-h-[100px] flex-col p-3 px-4 pb-5">
                        <h4 className="font-regular text-left text-sm leading-normal text-gray-dark">
                          {data?.Message?.message}
                        </h4>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
          {messageList?.length == 0 && !isLoading && <NoData message={'No messages'} showImage={true} emptyMessage={true} />}
        </div>

        <div className="hidden">
          <div className={classes.broadcastMessages}>
            <div className={classes.broadcastMessagesList}>
              <div className={classes.broadcastType}>
                <button
                  aria-label="Inbox has 12 messages"
                  className={`${classes.tabsetTab} ${tabsetMessageType === 'Inbox' && classes.active}`}
                  onClick={() => {
                    tabSwitcher('Inbox');
                    setTabsetMessageType('Inbox');
                  }}
                >
                  Inbox ({inboxList.length})
                </button>
                <button
                  aria-label="Sent Box has 12 messages"
                  className={`${classes.tabsetTab} ${tabsetMessageType === 'Sent' && classes.active}`}
                  onClick={() => {
                    tabSwitcher('Sent');
                    setTabsetMessageType('Sent');
                  }}
                >
                  Sent({sentList.length})
                </button>
              </div>
            </div>
            <div className={classes.broadcastMessagesWrapper}>
              <div className={classes.msgList}>
                {currentView.length !== 0 ? (
                  <ul>
                    {currentView.map((element, index) => {
                      return (
                        <li key={`currentList__${index}`}>
                          <button
                            aria-label={`read message from ${element.userName}`}
                            className={`${classes.messageItem} ${selectedMessage === index && classes.active}`}
                            onClick={() => {
                              setSelectedMessage(index);
                              setScreenflow(2);
                            }}
                          >
                            <div className={classes.imageIcon}>{element.userNameMin}</div>
                            <div className={classes.msgDetails}>
                              <div className={classes.userName}>{element.userName}</div>
                              <div className={classes.subject}>
                                <span className={classes.subjectWorkspace}>{element.workspace}:</span>
                                <span className={classes.subjectText}>{element.subject}</span>
                              </div>
                              <div className={classes.date}>{element.date}</div>
                            </div>
                          </button>
                        </li>
                      );
                    })}
                  </ul>
                ) : (
                  <div className={classes.noRecords}>Your {tabsetMessageType} is empty</div>
                )}
              </div>
              <div className={`${classes.broadcastDetails} ${screenflow === 2 && classes.screenflowActive}`}>
                <div className={`${classes.card} `}>
                  {screenflow && (
                    <div className={`${classes.navigationScreen} ${classes.mvOnly}`}>
                      <button aria-label="Go back to Inbox" className={classes.navBtn} onClick={() => setScreenflow(1)}>
                        <Icons.ChevronLeft />
                        <span>Inbox</span>
                      </button>
                    </div>
                  )}
                  {currentView[selectedMessage] ? (
                    <div className={classes.messageDetailed}>
                      <div className={classes.mdRow}>
                        <div className={classes.mdImageIcon}>{currentView[selectedMessage].userNameMin}</div>
                        <div className={classes.mdDetails}>
                          <div className={classes.mdDetailsLeft}>
                            <div className={classes.mdDetailsWrapper}>
                              <div className={classes.mdDetailsName}>{currentView[selectedMessage].userName}</div>
                              <div className={classes.mdDetailsId}>{currentView[selectedMessage].mailId}</div>
                            </div>
                            <div className={classes.mdDetailsSubject}>
                              <div className={classes.mdDetailsWorkspace}>{currentView[selectedMessage].workspace}</div>
                              <div className={classes.mdDetailsWSSubject}>{currentView[selectedMessage].subject}</div>
                            </div>
                            <div className={classes.mdDetailsCC}>
                              <div className={classes.mdDetailsCCTitle}>To:</div>
                              <div className={classes.mdDetailsWSSubject}>
                                {currentView[selectedMessage].toList.slice(0, 4).map((element, index) => {
                                  return (
                                    <div className={classes.mailID} key={`mail_id_${index}`}>
                                      {element}
                                    </div>
                                  );
                                })}
                                {currentView[selectedMessage].toList.length > 4 && (
                                  <button className={classes.moreItems} aria-label="Show more mail ids">
                                    {`+ `}
                                    {currentView[selectedMessage].toList.length - 4}
                                    {` others`}
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>

                          <div className={classes.mdDate}>{currentView[selectedMessage].date}</div>
                        </div>
                      </div>
                      <div className={classes.mdRow}>
                        <div className={classes.mdData}>{currentView[selectedMessage].data}</div>
                      </div>
                    </div>
                  ) : (
                    <div className={classes.noRecords}>Please select a message to view it in detail.</div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {customModalOpen && (
        <CustomModal
          setCustomModalOpen={setCustomModalOpen}
          headerTitle={'New Broadcast Message'}
          content={<BroadcastComponent />}
          size={'lg'}
          classList={'pageLikeModalMob'}
        />
      )}
    </CommonDashboard>
  );
};
export default Messaging;
