/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { RadioGroup } from '@headlessui/react';
import { subscriptionPlans } from '../../constants/constants';
import { getPriceDetails } from '../../services/paymentService';
import Loader from '../Loader';
import { useToastBox } from '../../customHooks/useToastBox';

const PricingPlans = ({ onSelect }) => {
  const currentUrl = window.location.pathname;
  const [priceData, setPriceData] = useState({});
  const [annualPlan, setAnnualPlan] = useState(false);
  const { handleErrorToast } = useToastBox();
  const currencyFinder = (code, rate, annualPlan) => {
    switch (code) {
      case 'usd':
        return `$ ${rate} / month`;
      case '-':
        return `--`;
      case 'gbp':
        return annualPlan ? `£ ${rate} / year` : `£ ${rate} / month`;
      case 'enterprise':
        return 'Contact Sales';
      default:
        return `${rate} ${code?.toUpperCase()}/ month`;
    }
  };

  const plans = [
    {
      id: 1,
      name: subscriptionPlans?.individual,
      rate: priceData?.individual?.price ? Number.parseFloat(priceData?.individual?.price) : '-',
      currency: priceData?.individual?.currency ? priceData?.individual?.currency : '-',
      details: [
        <li key={`uniqueKey_1_1`}>Monitor the pages, profiles and channels where you have admin access </li>,
        <li key={`uniqueKey_1_2`}>See who else has access to your key channels</li>,
        <li key={`uniqueKey_1_3`}>Upgrade to team subscription anytime</li>,
      ],
      planBuy: 'Buy Plan',
    },
    {
      id: 2,
      name: subscriptionPlans?.team,
      rate:
        annualPlan && priceData?.annual?.price
          ? Number.parseFloat(priceData?.annual?.price)
          : priceData?.team?.price
          ? Number.parseFloat(priceData?.team?.price)
          : '-',
      currency: priceData?.team?.currency ? priceData?.team?.currency : '-',
      annual: annualPlan,
      details: [
        <li key={`uniqueKey_2_1`}>Monitor the pages, profiles and channels where you have admin access</li>,
        <li key={`uniqueKey_2_2`}>See who else has access to your key channels</li>,
        <li key={`uniqueKey_2_3`}>Change access permissions and remove users on accounts where you have admin access</li>,
        <li key={`uniqueKey_2_4`}>
          Invite team members to manage permissions or provide view only access to HR, Risk, IST or clients
        </li>,
        <li key={`uniqueKey_2_5`}>Create and schedule channel reports and distribute to stakeholders and clients</li>,
        <li key={`uniqueKey_2_6`}>Create workspaces to group key profiles together</li>,
        <li key={`uniqueKey_2_7`}>Alerts for key changes to permissions across critical channels</li>,
        <li key={`uniqueKey_2_8`}>
          {annualPlan
            ? 'Up to 5 users included, £15 per month for each additional license up to 100 users.'
            : 'Up to 5 users included, £15 per additional license up to 100 users.'}
        </li>,
      ],
      planBuy: 'Buy Plan',
    },
    {
      id: 3,
      name: subscriptionPlans?.enterprise,
      rate: '100',
      currency: 'enterprise',
      details: [
        <li key={`uniqueKey_3_1`}>Monitor the pages, profiles and channels for your brands</li>,
        <li key={`uniqueKey_3_2`}>Monitor who has access to your key channels and ad managers for multiple brands</li>,
        <li key={`uniqueKey_3_3`}>Change access permissions and remove users across critical external SaaS platforms</li>,
        <li key={`uniqueKey_3_4`}>Control who can manage permissions and provide view only access to HR, Risk and IST</li>,
        <li key={`uniqueKey_3_5`}>Create and schedule channel reports and distribute to key stakeholders</li>,
        <li key={`uniqueKey_3_6`}>
          Create workspaces to group key profiles together and manage view-only access to filtered views
        </li>,
        <li key={`uniqueKey_3_7`}>Alerts for key changes to permissions across critical channels</li>,
        <li key={`uniqueKey_3_8`}>Unlimited users</li>,
      ],
      planBuy: 'Contact Sales Team',
    },
  ];

  const [selected, setSelected] = useState(plans[1]);
  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    getPriceDetails()
      .then(response => {
        if (response?.status === 201 || response?.status === 200) {
          setPriceData(response?.data?.data);
          setIsLoading(false);
        }
      })
      .catch(error => {
        console.log('error', error);
        if (currentUrl == '/payment') handleErrorToast('Unable to fetch price details ');
        setIsLoading(false);
      });
  }, []);

  const handleAnnualSelection = event => {
    setAnnualPlan(event?.target?.checked);
  };

  return loading ? (
    <Loader />
  ) : (
    <RadioGroup value={selected} onChange={setSelected}>
      <div className="pricingPlan flex  justify-center space-x-10 md:flex-col md:space-x-0 md:space-y-4" data-testid="pp">
        {plans.map(plan => (
          <RadioGroup.Option
            key={plan.name}
            value={plan}
            className="pricingList relative flex min-h-full cursor-default rounded-[26px] border border-primary200 bg-white py-6 px-4.5 md:min-h-[223px] md:w-full md:rounded-[16px] md:py-4 md:px-4"
          >
            {({ active }) => (
              <>
                <div className="flex w-full  items-start justify-between">
                  <div className="flex h-full w-full items-start">
                    <div className="flex h-full w-full flex-col">
                      <RadioGroup.Label
                        as="h2"
                        className="mb-1.5 text-center text-xs font-semibold uppercase leading-5 text-gray-dark md:mb-0.5"
                      >
                        {plan.name}
                      </RadioGroup.Label>

                      <h3 className="mb-6 flex flex-col items-center justify-center border-b border-primary200 pb-[13px] text-base font-semibold leading-6 text-gray-dark md:mb-3">
                        {currencyFinder(plan.currency, plan.rate, plan.annual)}
                        {plan.name.toLowerCase() == 'team' && annualPlan ? (
                          <span className=" mt-2 text-sm font-medium ">
                            ( Equivalent to £ {Math.ceil(Number.parseFloat(priceData?.annual?.price) / 12)} / month )
                          </span>
                        ) : null}
                        <div className=" flex pt-2">
                          {plan.name.toLowerCase() == 'team' ? (
                            <div className="flex-row">
                              <label className="inline-flex cursor-pointer items-center justify-center">
                                <input
                                  type="checkbox"
                                  value=""
                                  className="peer sr-only"
                                  onClick={event => handleAnnualSelection(event)}
                                />
                                <div className="after:start-[2px] peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                                <span className="k ml-2 text-sm font-medium ">Annual Plan</span>
                              </label>
                            </div>
                          ) : null}
                        </div>
                      </h3>

                      <ul>{plan.details}</ul>
                      {plan.name.toLowerCase() == 'enterprise' ? (
                        <a
                          className="mx-auto mt-auto flex max-w-max justify-center rounded-full bg-primary py-[8px] px-6 text-xs font-semibold text-white"
                          href="https://calendly.com/hydra-jt/"
                          target="_blank"
                          rel="noreferrer"
                        >
                          {plan.planBuy}
                        </a>
                      ) : plan.name.toLowerCase() == 'team' ? (
                        <div className="grid grid-cols-2 gap-[5px]">
                          <button
                            className=" mx-auto mt-auto flex w-full max-w-full justify-center whitespace-nowrap rounded-full border-2 border-solid border-primary py-[7px] px-6 text-xs font-semibold text-primary"
                            onClick={() => onSelect(plan.name.toLowerCase(), true, annualPlan)}
                          >
                            14 day Free Trial
                          </button>
                          <button
                            className="mx-auto mt-auto flex w-full max-w-full justify-center whitespace-nowrap rounded-full bg-primary py-[8px] px-6 text-xs font-semibold text-white"
                            onClick={() => onSelect(plan.name.toLowerCase(), false, annualPlan)}
                          >
                            {plan.planBuy}
                          </button>
                        </div>
                      ) : (
                        <button
                          className="mx-auto mt-auto flex max-w-max justify-center rounded-full bg-primary py-[8px] px-6 text-xs font-semibold text-white"
                          onClick={() => onSelect(plan.name.toLowerCase(), false, annualPlan)}
                        >
                          {plan.planBuy}
                        </button>
                      )}
                    </div>
                  </div>
                </div>
              </>
            )}
          </RadioGroup.Option>
        ))}
      </div>
    </RadioGroup>
  );
};
export default PricingPlans;
