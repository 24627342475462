import React, { useRef, useEffect } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import config from '../../config';
const Captcha = props => {
  const reCAPTCHARef = useRef();
  const recaptchaKey = config.DEVELOPMENT.REACT_APP_RECAPTCHASITEKEY;

  useEffect(() => {
    if (props.resetCaptcha) {
      reCAPTCHARef.current.reset();
      props.setReset();
    }
  }, [props.resetCaptcha]);

  return (
    <div className="captcha-container flex flex-col  items-center justify-center">
      <ReCAPTCHA ref={reCAPTCHARef} sitekey={recaptchaKey} onChange={props.onChange} />
      {props.error !== '' ? (
        <span style={{ color: 'red', fontSize: 'small', alignSelf: 'center' }}>{props.error}</span>
      ) : null}
    </div>
  );
};

export default Captcha;
