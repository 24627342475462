import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import { loginConstants } from '../LoginModal/constants';
import { resetConstants } from '../ResetPasswordModal/constants';
import { OtpVerificationModal } from '../OtpVerificationModal';
import { ConfirmPasswordModal } from '../ConfirmPasswordModal';
import InputText from '../../InputText';
import useFormHandler from '../../../customHooks/useFormHandler';
import Icons from '../../../assets/Icons';

export const ResetPasswordModal = props => {
  const [resetFormData, setResetFormData] = useState({});
  const [otpModal, setOtpModal] = useState(false);
  const [confirmPasswordModal, setConfirmPasswordModal] = useState(false);
  const [resetMessage, setResetMessage] = useState('');
  const [responseData, setResponseData] = useState('');

  const { user, setUser, formError, setFormError, handleEmailChange, handleResetFormSubmit, handleOtpSubmit } =
    useFormHandler(resetFormData, setResetFormData);

  const handleEmailSubmit = event => {
    handleResetFormSubmit(
      event,
      resetConstants.validationErrorMessages.resetError,
      resetConstants.validationErrorMessages.email.title,
      resetConstants.validationErrorMessages.email.required
    )
      .then(response => {
        setUser(response.data.data);
        if (response.data.data) {
          setOtpModal(true);
        }
        setFormError(values => ({
          ...values,
          [resetConstants.validationErrorMessages.resetError]: '',
        }));
      })
      .catch(error => {
        setFormError(values => ({
          ...values,
          [resetConstants.validationErrorMessages.resetError]: error.message,
        }));
      });
  };

  const handleTwoFactorAuthentication = event => {
    handleOtpSubmit(event, true)
      .then(response => {
        // setMessageModal(true);
        setConfirmPasswordModal(true);
        setResetMessage(response.data.data.message);
        setResponseData(response.data.data);

        setOtpModal(false);

        setFormError(values => ({
          ...values,
          [loginConstants.validationErrorMessages.loginError]: '',
        }));
      })
      .catch(error => {
        setFormError(values => ({
          ...values,
          [loginConstants.validationErrorMessages.loginError]: error.message,
        }));
      });
  };

  return (
    <>
      {otpModal === false ? (
        confirmPasswordModal ? (
          <ConfirmPasswordModal
            isOpen={confirmPasswordModal}
            setIsOpen={setConfirmPasswordModal}
            message={resetMessage}
            setResetPasswordModalIsOpen={props.setIsOpen}
            resetPasswordIsOpen={props.isOpen}
            heading={resetConstants.title}
            isForgotPassword={true}
            data={responseData}
          />
        ) : (
          <Transition appear show={props.isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={() => {}}>
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0"
                enterTo="opacity-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100"
                leaveTo="opacity-0"
              >
                <div className="fixed inset-0 bg-black bg-opacity-60" />
              </Transition.Child>

              <div className="fixed inset-0 overflow-y-auto">
                <div className="flex min-h-full items-center justify-center p-4 text-center ">
                  <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0 scale-95"
                    enterTo="opacity-100 scale-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100 scale-100"
                    leaveTo="opacity-0 scale-95"
                  >
                    <Dialog.Panel className="w-full max-w-[27.375rem] transform overflow-hidden rounded-lg  transition-all">
                      <>
                        <Dialog.Title
                          as="div"
                          className="login-modal-header relative flex  min-h-[6.5rem] items-center justify-center overflow-hidden bg-primary300 p-4 text-black shadow-none md:min-h-[7.938rem]"
                        >
                          <div className="z-10 text-white">
                            <h2 className="mb-2 text-2xl font-bold leading-9 md:mb-3">{resetConstants.title}</h2>
                          </div>
                          <button className="absolute top-4 right-4 z-10 text-white" onClick={() => props.setIsOpen(false)}>
                            <Icons.CloseIcon />
                          </button>
                        </Dialog.Title>
                        <div className="bg-white  p-8 md:px-4.5 md:pt-6 md:pb-10">
                          <div className="user-details-grid grid w-full grid-cols-1 gap-6 md:gap-4">
                            <InputText
                              className="flex flex-col"
                              id="email"
                              placeholder="Email Address"
                              type="email"
                              label="Email Address"
                              name="email"
                              onChange={event => {
                                setFormError(values => ({
                                  ...values,
                                  [resetConstants.validationErrorMessages.resetError]: '',
                                }));
                                handleEmailChange(event);
                              }}
                              error={formError.email}
                            />
                          </div>
                          {formError.email ? null : (
                            <div
                              style={{
                                color: 'red',
                                fontSize: 'small',
                                alignSelf: 'self-start',
                              }}
                            >
                              {formError.resetError}
                            </div>
                          )}
                          <div className="footer-buttons flex w-full items-center justify-between pt-8 md:pt-8">
                            <button className="w-full bg-primary text-white" onClick={handleEmailSubmit}>
                              {props.constants.shortTitle}
                            </button>
                          </div>
                        </div>
                      </>
                    </Dialog.Panel>
                  </Transition.Child>
                </div>
              </div>
            </Dialog>
          </Transition>
        )
      ) : (
        <OtpVerificationModal
          isOpen={otpModal}
          setIsOpen={setOtpModal}
          constants={resetConstants}
          handleSubmit={handleTwoFactorAuthentication}
          handleReset={() =>
            setFormError(values => ({
              ...values,
              [loginConstants.validationErrorMessages.loginError]: '',
            }))
          }
          user={user}
          error={formError.loginError}
          resetPassword={true}
        />
      )}
    </>
  );
};
