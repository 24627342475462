import React from 'react';
import Icons from '../../../../assets/Icons';
import classes from './filter.module.scss';
const RoleDP = ({ setFilter, options }) => {
  return (
    <div className={`${classes.dropdownCustom} ${classes.bottom} ${classes.onlyMobilePanel}`}>
      <div className={classes.dropdownBackdrop} />
      <div className={classes.dropdownContent}>
        <div className={classes.dropdownHeader}>
          <h3 className={classes.title}>Role</h3>
          <div className={classes.actiongroup}>
            <button
              className={`btn-icon ${classes.closeBtn}`}
              aria-label="Close Filter Alert"
              onClick={() => setFilter(false)}
              data-testid='close-filter'
            >
              <Icons.Cross />
            </button>
          </div>
        </div>
        <div className={`${classes.dropdownBody} ${classes.optionList}`}>
          <ul className={classes.dropdownList}>
            {options?.map((element, index) => {
              return (
                <li role={'presentation'} key={`roleList__${index}`}>
                  <button aria-label={element.label} className={classes.dpItem}>
                    {element.label}
                  </button>
                </li>
              );
            })}
          </ul>
        </div>
      </div>
    </div>
  );
};
export default RoleDP;
