import React from 'react';
import { tagContants } from './constants';

const MultiTag = props => {
  const { handleChange, handleKeyDown, handleKeyUp, value } = props;
  return (
    <div className="container overflow-auto p-0">
      <input
        type="text"
        id={tagContants.tagId}
        name={tagContants.name}
        onChange={handleChange}
        onKeyDown={handleKeyDown}
        onKeyUp={handleKeyUp}
        value={value}
        placeholder={tagContants.messageContent}
        className="flex flex-col border-none"
        autoComplete="off"
      />
    </div>
  );
};

export default MultiTag;
