/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from 'react';
import CryptoJS from 'crypto-js';
import { useCookies } from 'react-cookie';
import { Fragment } from 'react';
import { Dialog, Transition, Tab } from '@headlessui/react';
import Tippy from '@tippyjs/react';
import { getProfiles, removeProfile } from '../../../services/ChannelAuthentication/getProfileService';
import { ConfirmationModal } from '../../../components/Modal/ConfirmationModal';
import { channelCodes, constants as commonConstants } from '../../../constants/constants';
import CheckAuthenticateContext from '../../../context/checkAuthenticateContext';
import Icons from '../../../assets/Icons';
import './custom.css';
import NoData from '../../../components/Nodata';
import { useToastBox } from '../../../customHooks/useToastBox';
import Loader from '../../../components/Loader';
import { getUserInfo } from '../../../utils/getUserInfo';
import { channelLabelSelector } from '../../../utils/helpers';
import { profileConstants } from '../constants';

export const ProfileListingModal = ({ isListingModalOpen, setIsListingModalOpen, handleAuthentication, data }) => {
  const userId = JSON.parse(getUserInfo())?.user?.id;
  let userRole;
  const userRoleCode = JSON.parse(getUserInfo())?.user.role;
  if (userRoleCode) {
    userRole = CryptoJS?.AES?.decrypt(userRoleCode, commonConstants.localStorageUser)?.toString(CryptoJS.enc.Utf8);
  }
  const [cookies, setCookie, removeCookie] = useCookies();
  const { handleErrorToast, handleSuccessToast } = useToastBox();
  const [loading, setIsLoading] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [message, setMessage] = useState('No profiles found');
  const [profileData, setProfileData] = useState({});
  const [selectedData, setSelectedData] = useState({});
  const [accountWarning, setAccountWarning] = useState(false);
  const [modalMessage, setModalMessage] = useState('');
  const [modalSubMessage, setModalSubMessage] = useState('');
  const { setProfileInfo } = useContext(CheckAuthenticateContext);

  const onUserRemove = () => {
    setIsLoading(true);
    removeProfile(selectedData?.id, data?.channel, selectedData?.email)
      .then(response => {
        setIsLoading(false);
        if (response?.status === 201 || response?.status === 200) {
          if (`${data.channel}Token_${userId}` in cookies) {
            removeCookie(`${data.channel}Token_${userId}`, { path: '/' });
            removeCookie(`${data.channel}Token_${userId}_userInfo`, { path: '/' });
            if (`${data.channel}TokenSecret_${userId}` in cookies) {
              removeCookie(`${data.channel}TokenSecret_${userId}`, { path: '/' });
            }
          }
          handleSuccessToast(response?.data?.data);
          getProfileData(true);
        }
      })
      .catch(error => {
        console.error(error);
        handleErrorToast('Unable to remove user');
        setIsLoading(false);
      });
  };

  const getProfileData = save => {
    if (data?.provider && data?.channel) {
      setIsLoading(true);
      getProfiles(data?.provider, userId, data?.channel)
        .then(response => {
          setIsLoading(false);
          if (response?.status === 201 || response?.status === 200) {
            setProfileData(response?.data?.data);
            //store the status of the profile in sessionstorage/context
            if (save) {
              sessionStorage.setItem('profileInformation', JSON.stringify(response?.data?.data));
            }
          }
        })
        .catch(error => {
          console.error(error);
          handleErrorToast('Unable to fetch profiles');
          setMessage('Unable to fetch profiles');
          setIsLoading(false);
        });
    }
  };

  const handleClick = () => {
    if (userRole !== 'user') {
      setModalMessage(
        `You must log out of any ${channelLabelSelector(
          data.channel
        ).toLowerCase()} account that is logged in the browser before adding a new authentication`
      );
      setModalSubMessage('Do you wish to continue with the authentication?');
      setConfirmModal(true);
      setAccountWarning(true);
    } else {
      handleAuthentication(); // to display modal when trying to  authenticate as a viewer
    }
  };

  const handleModalConfirm = () => {
    if (accountWarning) {
      setAccountWarning(false);
      //remove token before further authentication - linkedin, fb, twitter
      if (`${data.channel}Token_${userId}` in cookies) {
        removeCookie(`${data.channel}Token_${userId}`, { path: '/' });
        removeCookie(`${data.channel}Token_${userId}_userInfo`, { path: '/' });
        if (`${data.channel}TokenSecret_${userId}` in cookies) {
          removeCookie(`${data.channel}TokenSecret_${userId}`, { path: '/' });
        }
      }
      handleAuthentication();
    } else {
      onUserRemove();
    }
  };

  useEffect(() => {
    getProfileData();
  }, [data]);

  const handleModalClose = () => {
    setProfileInfo(true);
    setIsListingModalOpen(false);
  };

  return (
    <>
      <Transition appear show={isListingModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[31.25rem] transform overflow-hidden rounded-lg  transition-all">
                  <Dialog.Title
                    as="div"
                    className="login-modal-header relative flex min-h-[6.5rem]  items-center justify-center overflow-hidden rounded-t-lg bg-primary300 p-4 text-black shadow-none md:min-h-[7.938rem]"
                  >
                    <div className="z-10 text-white">
                      <h2 className="mb-2 text-2xl font-bold leading-9 md:mb-3">
                        {profileData.length === 1
                          ? profileConstants?.profileModal?.titleSingular
                          : profileConstants?.profileModal?.titlePlural}
                      </h2>
                    </div>
                    <button className="absolute top-4 right-4 z-10 text-white" onClick={() => handleModalClose()}>
                      <Icons.CloseIcon />
                    </button>
                  </Dialog.Title>
                  <div className=" custom-vertical-scrollbar bg-white">
                    {loading && (
                      <div className="profile-loader">
                        <Loader />
                      </div>
                    )}

                    <div className="grid max-h-[310px] w-full gap-2 overflow-auto overflow-x-hidden  border-t border-white200   px-6 py-8 pb-0">
                      {profileData?.length > 0 &&
                        profileData?.map((profData, index) => {
                          return (
                            <div className="form-checkbox flex items-center" key={index}>
                              <label
                                htmlFor="react-option"
                                className={`inline-flex h-10.5 w-full items-center justify-between rounded-lg  ${
                                  cookies[`${data?.channel}Token_${userId}_userInfo`]?.id == profData?.providerId
                                    ? 'border border-green-600'
                                    : 'border border-white200'
                                }  bg-white px-4 text-gray-500  peer-checked:border-[2px] peer-checked:border-primary `}
                              >
                                <Tippy
                                  content={`${profData?.firstName}${' '}${profData?.lastName}`}
                                  placement="top"
                                  arrow={true}
                                >
                                  <div className=" max-w-xs items-center truncate text-left text-xs text-gray-dark">
                                    {`${profData?.email ? profData?.email : ''} (${' '}${profData?.firstName}${' '}${
                                      profData?.lastName ? `${profData?.lastName} ` : ''
                                    })  
                                       
                                      `}
                                  </div>
                                </Tippy>
                                <div>
                                  {cookies[`${data?.channel}Token_${userId}_userInfo`]?.id == profData?.providerId ? (
                                    <div className=" mr-6 ml-0.5 rounded-lg bg-green-600 px-1.5 py-[2] text-xs text-white">
                                      Active
                                    </div>
                                  ) : null}

                                  <button
                                    className="absolute top-3 right-3 z-10 text-gray-dark1"
                                    onClick={() => {
                                      setModalMessage('Are you sure you want to remove profile?');
                                      setModalSubMessage('All data related to the account will be removed ');
                                      setConfirmModal(true);
                                      setSelectedData(profData);
                                    }}
                                  >
                                    <Icons.DeleteIcon />
                                  </button>
                                </div>
                              </label>
                            </div>
                          );
                        })}
                    </div>

                    {profileData && profileData?.length == 0 && !loading && (
                      <div className="py-6">
                        <NoData message={message} />
                      </div>
                    )}
                    <div className="bg-white py-4 px-6  pt-8">
                      <button
                        className="w-full rounded-full bg-primary p-2.5 text-sm font-semibold leading-6 text-white duration-200 ease-in hover:bg-primary500"
                        aria-label="Authenticate & add Channel"
                        onClick={() => handleClick()}
                      >
                        {profileConstants?.profileModal?.button}
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <ConfirmationModal
        isConfirmModalOpen={confirmModal}
        setIsConfirmModalOpen={setConfirmModal}
        handleClose={() => {
          setConfirmModal(false);
          setTimeout(() => {
            setAccountWarning(false);
          }, 200);
        }}
        handleConfirm={() => handleModalConfirm()}
        message={modalMessage}
        subMessage={modalSubMessage}
        isOwner={true}
        showCancel={true}
        accountWarning={accountWarning}
        channel={data?.provider?.charAt(0)?.toUpperCase() + data?.provider?.slice(1)?.toLowerCase()}
      />
    </>
  );
};
