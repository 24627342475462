import { getChannelDetails } from '../WorkspacesDetailed/helper';
import { textConstants } from './constants';

export const processPermissionListPageAccount = async data => {
  const pageList = [];
  data?.forEach(element => {
    const userList = [];
    const obj = {
      title: element?.name,
      accountId: element?.accountId,
      channelType: element?.channelType,
      redirectionURL: element?.details?.manageUsersUrl,
      setupInProgress: element?.setupInProgress,
    };
    const channelDetails = getChannelDetails(element.channelType);
    obj.imgUrl = element?.details?.picture ? element?.details?.picture : channelDetails.imgUrl;
    obj.channelName = channelDetails.title;
    element?.ChannelAccountUsers?.forEach(async user => {
      const userDetails = { removed: false };
      userDetails.accountName = element.channelType;
      userDetails.accountTitle = element.name;
      userDetails.id = user?.id;
      userDetails.channelUserId = user?.channelUserId;
      userDetails.accountId = element.accountId;
      userDetails.email = user?.email;
      userDetails.title = obj.channelType == textConstants.googleAds ? user?.email : user?.name;
      userDetails.removed = false;
      userDetails.logo = null;
      userDetails.lastLogin = [{ dateTime: '-', location: '-' }];
      userDetails.channelType = obj.channelType;
      userDetails.channelAccountId = user?.channelAccountId;
      userDetails.isBusinessAssociated = user?.userDetails?.isBusinessAssociated;

      userDetails.role = user.roles;
      userDetails.permission = user.permissions ? user.permissions : '';

      userDetails.displayRole = user.details?.rolesLabel ? user.details?.rolesLabel : [];
      userDetails.displayPermission = user.details?.permissionsLabel ? user.details?.permissionsLabel : '-';

      userDetails.roleLabel = channelDetails.roleLabel;
      userDetails.permissionLabel = channelDetails?.permissionLabel ? channelDetails.permissionLabel : '-';

      userList.push(userDetails);
    });
    obj.pagesDetails = userList;
    pageList.push(obj);
  });

  return pageList;
};
