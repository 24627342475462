/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Dialog, Transition } from '@headlessui/react';
import Icons from '../../../assets/Icons';
import InputText from '../../InputText';
import useFormHandler from '../../../customHooks/useFormHandler';
import { useToastBox } from '../../../customHooks/useToastBox';
import { createCheckoutSession } from '../../../services/paymentService';
import Loader from '../../Loader';
import { ConfirmationModal } from '../ConfirmationModal';
import { getUserInfo } from '../../../utils/getUserInfo';

export const LicenseUpgradeModal = props => {
  const userInfo = JSON.parse(getUserInfo());
  const tenantId = userInfo?.user?.tenantId;

  const { handleSuccessToast, handleErrorToast } = useToastBox();
  const [addUserFormData, setAddUserFormData] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);
  const [isTrial, setIsTrial] = useState(false);
  const [annualPlan, setAnnualPlan] = useState(false);

  const [loading, setIsLoading] = useState(false);

  const { formError } = useFormHandler(addUserFormData, setAddUserFormData);

  const handleProceed = () => {
    //To-Do
  };

  const handleAnnualSelection = event => {
    setAnnualPlan(event?.target?.checked);
  };

  const handleSubmit = () => {
    setIsLoading(true);
    let plan = {
      subscriptionType: annualPlan ? 'annual' : 'team',
      tenantId: tenantId,
      upgrade: true,
      upgradeType: annualPlan ? 'annual' : 'team',
    };
    if (isTrial) {
      plan.trial = true;
    }
    createCheckoutSession(plan)
      .then(response => {
        if (response?.status === 201 || response?.status === 200) {
          window.location.href = response?.data?.data?.checkoutUrl;
          //setIsLoading(false);
        }
      })
      .catch(err => {
        handleErrorToast(err.message);
        setIsLoading(false);
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Transition appear show={props.isOpen} as={Fragment}>
          <Dialog as="div" className="license-upgrade-modal relative z-10" onClose={() => {}}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-60" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center ">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-[650px] transform rounded-lg  transition-all">
                    <>
                      <Dialog.Title
                        as="div"
                        className="login-modal-header relative  min-h-[6.5rem]  items-center overflow-hidden rounded-t-lg bg-primary300 p-4 text-black shadow-none md:min-h-[7.938rem]"
                      >
                        <div className="z-10 text-white">
                          <h2 className="mb-2 text-2xl font-bold leading-9 md:mb-3">{'Teams Plan'}</h2>
                          <h2 className="mb-2 flex flex-col items-center justify-center text-2xl font-bold leading-9 md:mb-3">
                            {annualPlan ? '£ 1188 / year' : '£ 125 / month'}
                            <span className=" mt-2 mb-2 text-sm font-medium ">
                              {annualPlan ? `( Equivalent to £ ${Math.ceil(1188 / 12)} / month )` : null}
                            </span>
                            <label className="mt-1 inline-flex cursor-pointer items-center justify-center">
                              <input
                                type="checkbox"
                                value=""
                                className="peer sr-only"
                                onClick={event => handleAnnualSelection(event)}
                              />
                              <div className="after:start-[2px] peer relative h-6 w-11 rounded-full bg-gray-200 after:absolute after:top-[2px] after:left-1 after:h-5 after:w-5 after:rounded-full after:border after:border-gray-300 after:bg-white after:transition-all after:content-[''] peer-checked:bg-blue-600 peer-checked:after:right-1 peer-checked:after:left-0 peer-checked:after:translate-x-full peer-checked:after:border-white peer-focus:outline-none peer-focus:ring-4 peer-focus:ring-blue-300 rtl:peer-checked:after:-translate-x-full dark:border-gray-600 dark:bg-gray-700 dark:peer-focus:ring-blue-800"></div>
                              <span className="k ml-2 text-sm font-medium ">Annual Plan</span>
                            </label>
                          </h2>
                        </div>

                        <button className="absolute top-4 right-4 z-10 text-white" onClick={() => props.setIsOpen(false)}>
                          <Icons.CloseIcon />
                        </button>
                      </Dialog.Title>
                      <div className="modal-content  rounded-b-lg bg-white p-8 md:px-4.5 md:pt-6 md:pb-10">
                        <ul>
                          <li key={`uniqueKey_2_1`}>Monitor the pages, profiles and channels where you have admin access</li>
                          <li key={`uniqueKey_2_2`}>See who else has access to your key channels</li>
                          <li key={`uniqueKey_2_3`}>
                            Change access permissions and remove users on accounts where you have admin access
                          </li>
                          <li key={`uniqueKey_2_4`}>
                            Invite team members to manage permissions or provide view only access to HR, Risk, IST or clients
                          </li>
                          <li key={`uniqueKey_2_5`}>
                            Create and schedule channel reports and distribute to stakeholders and clients
                          </li>
                          <li key={`uniqueKey_2_6`}>Create workspaces to group key profiles together</li>
                          <li key={`uniqueKey_2_7`}>Alerts for key changes to permissions across critical channels</li>
                          <li key={`uniqueKey_2_8`}>
                            {annualPlan
                              ? 'Up to 5 users included, £15 per month for each additional license up to 100 users.'
                              : 'Up to 5 users included, £15 per additional license up to 100 users.'}
                          </li>
                        </ul>

                        <div className="footer-buttons grid w-full  grid-cols-2 items-center justify-between gap-2 pt-8 md:pt-8">
                          <button
                            className="btn-secondary-outline  w-full !min-w-0 text-primary hover:!bg-white hover:text-primary"
                            onClick={() => {
                              setConfirmModal(true);
                              setIsTrial(true);
                            }}
                          >
                            {'14 day Free Trial'}
                          </button>
                          <button className={`w-full !min-w-0 bg-primary text-white `} onClick={() => setConfirmModal(true)}>
                            {'Buy now'}
                          </button>
                        </div>
                      </div>
                    </>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
      {confirmModal && (
        <ConfirmationModal
          isConfirmModalOpen={confirmModal}
          setIsConfirmModalOpen={setConfirmModal}
          handleClose={() => setConfirmModal(false)}
          handleConfirm={() => handleSubmit()}
          message={'Are you sure you want to upgrade your Hydra license from individual to teams?'}
          subMessage={'Please note that once upgraded, you cannot revert back to  individual license.'}
          isOwner={true}
          showCancel={true}
        />
      )}
    </>
  );
};
