/* eslint-disable no-unused-vars */
import React from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import Icons from '../../assets/Icons';

const PhoneInputField = props => {
  const textInput = (
    <div className={`${props.showVerified ? 'verified' : ''} relative flex items-center`}>
      <PhoneInput
        country={'gb'}
        inputProps={{
          id: props?.id,
        }}
        preferredCountries={['gb', 'us']}
        value={props.phoneNumber}
        enableSearch
        onChange={(value, country) => {
          props.onPhoneInput(value);
          const phoneNumber = value.replace(country?.dialCode, '');
          props.onCountryChange(country?.dialCode, 'countryCode');
          if (phoneNumber?.length >= 0) {
            props.onPhoneChange(phoneNumber, 'phone');
          }
        }}
        placeholder={'Enter phone number with code'}
      />
      {props.showVerified && (
        <>
          {props.isVerified ? (
            <div className="channelTickIcon absolute right-4 top-4">
              <Icons.CircleTick />
            </div>
          ) : (
            <button
              className={`absolute right-4 top-4 text-xs font-medium leading-[0.875rem] ${
                props.isVerifyDisabled ? 'text-gray-light10' : 'text-primary'
              }`}
              onClick={props.onVerifyClick}
              disabled={props.isVerifyDisabled}
            >
              Verify
            </button>
          )}
        </>
      )}
    </div>
  );

  return (
    <div className={props.className}>
      <label htmlFor={props.id} className={props.showVerified ? 'text-xs leading-4.5 text-gray-dark' : ''}>
        {props.label}
        <span className="absolute top-1">
          <Icons.AsteriskIcon />
        </span>
        {props.hint && <span className={props.hintClassName}>({props.hintText})</span>}
      </label>
      {textInput}
      {props.error !== '' ? (
        <span style={{ color: 'red', fontSize: 'small', alignSelf: 'self-start' }}>{props.error}</span>
      ) : null}
    </div>
  );
};

export default PhoneInputField;
