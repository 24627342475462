import React, { useEffect, useState } from 'react';
import CommonDashboard from '../../components/CommonDashboard';
import Icons from '../../assets/Icons';
import { useNavigate } from 'react-router-dom';
import Loader from '../../components/Loader';
import { getUserInfo } from '../../utils/getUserInfo';

const ErrorPage = ({ networkDown }) => {
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const userInfo = JSON.parse(getUserInfo());
  sessionStorage.setItem('page', 'Nodata');
  const navigate = useNavigate();
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    window.addEventListener('online', handleOnline);
    window.addEventListener('offline', handleOffline);

    return () => {
      window.removeEventListener('online', handleOnline);
      window.removeEventListener('offline', handleOffline);
    };
  }, []);

  const handleOnline = () => {
    setIsOnline(true);
  };

  const handleOffline = () => {
    setIsOnline(false);
  };
  useEffect(() => {
    setIsLoading(false);
  }, []);

  const ErrorPageContent = () => {
    return (
      <div className=" flex h-full w-full flex-col items-center justify-center ">
        <div className=" mb-12 ">{networkDown || !isOnline ? <Icons.NoNetwork /> : <Icons.ErrorImage />}</div>
        <div className=" flex max-w-[850px] flex-col items-center justify-center text-center text-lg font-semibold leading-6 text-primary100">
          <h2 className=" text-2xl font-semibold leading-8 text-primary100">
            {networkDown ? 'Oops! Slow or no internet connection' : 'An internal server error has occurred'}
          </h2>
          <h3 className=" mt-4 mb-4 text-lg font-medium leading-6 text-gray-400">
            {networkDown || !isOnline
              ? 'Please check your internet connection and try again'
              : 'Please check your internet connection and try reloading the page.'}
          </h3>
          <button
            className="btn-secondary-outline mt-4 flex h-10 w-32 justify-center bg-primary text-base text-white "
            onClick={() => {
              setIsLoading(true);
              setTimeout(() => {
                userInfo == null ? navigate('/') : navigate('/dashboard');
              }, 500);
            }}
          >
            <span>Reload</span>
          </button>
        </div>
      </div>
    );
  };
  return isLoading ? (
    <Loader />
  ) : userInfo !== null ? (
    <CommonDashboard>
      <ErrorPageContent />
    </CommonDashboard>
  ) : (
    <div className=" flex h-screen w-full flex-col items-center justify-center ">
      <ErrorPageContent />
    </div>
  );
};
export default ErrorPage;
