export const addLicenseOwnerConstants = {
  countryCodes: [
    { value: '+91', label: '+91' },
    { value: '+92', label: '+92' },
    { value: '+93', label: '+93' },
    { value: '+44', label: '+44' },
    { value: '+9444', label: '+9444' },
  ],

  licenseOwnerTitles: ['firstName', 'lastName', 'tenantName', 'subscription', 'phone', 'email', 'referralCode'],

  successMessage: 'Invitation email has been sent to license owner',

  isEmptyMessage: '',

  textConstants: {
    reset: 'Reset',
    addLicenseOwner: 'Add User',
  },

  formDataInitialValue: {
    countryCode: '+44',
    subscription: 'enterprise',
  },

  defaultSubscription: 'Enterprise',

  subscriptions: [{ value: 'enterprise', label: 'Enterprise' }],

  validationErrorMessages: {
    emptyString: '',
    addLicenseOnwerError: 'addLicenseOnwerError',
    firstName: {
      required: 'First name cannot be empty',
      onlyAlphabets: 'Sorry, name must only include alphabets',
    },
    lastName: {
      required: 'Last name cannot be empty',
      onlyAlphabets: 'Sorry, name must only include alphabets',
    },
    email: {
      required: 'Email cannot be empty',
      validateError: 'Please enter a valid email address',
    },
    phone: {
      required: 'Phone number cannot be empty',
      validateError: 'Please enter a valid phone number',
      maxLimit: 'Phone number cannot exceed 14 digits',
      minLimit: 'Phone number should be at least 10 digits',
    },
    tenantName: {
      required: 'Tenant name cannot be empty',
      validateError: 'Please enter a valid tenant name',
    },
    // subscription: {
    //   required: 'Subscription cannot be empty',
    //   validateError: 'Please enter a valid subscription',
    // },
  },
};
export const userRoles = {
  adminUser: 'Admin User',
  viewer: 'Viewer',
  clientAdmin: 'clientAdmin',
  user: 'user',
};
