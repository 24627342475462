/* eslint-disable no-unused-vars */
import React, { useState } from 'react';

const Loader = () => {
  return (
    <div className=" fixed z-30 flex h-full w-full items-center justify-center bg-white bg-opacity-[0.85] top-0 left-0">
      <div className="spinner">
        <div className="bounce1" />
        <div className="bounce2" />
        <div className="bounce3" />
      </div>
    </div>
  );
};
// const spinner = () => {
//   return (
//     <div className=" fixed z-30 flex h-full w-full items-center justify-center bg-white bg-opacity-[0.85]">
//       <div className="spinner">
//         <div className="bounce1" />
//         <div className="bounce2" />
//         <div className="bounce3" />
//       </div>
//     </div>
//   );
// };
export default Loader;
