import React, { useState, useEffect, useContext } from 'react';
import { useCookies } from 'react-cookie';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import CryptoJS from 'crypto-js';
import classes from './channel.module.scss';
import { SetupChannelModal } from './SetupChannelModal';
import Icons from '../../assets/Icons';
import { authenticateChannel, getchannelSetupMessage, listImageUrl, storeAuthToken, processChannels } from './helper';
import { getAuthToken } from '../../components/ChannelSetup/helper';
import { constants } from './constants';
import { useToastBox } from '../../customHooks/useToastBox';
import { channelCount, channelListService } from '../../services/channelListService';
import useAuth from '../../customHooks/useAuth';
import { MessageModal } from '../Modal/MessageModal';
import { ProfileListingModal } from '../../features/Profile/profileListingModal';
import { loginConstants } from '../Modal/LoginModal/constants';
import ChannelListContext from '../../context/channelListContext';
import { handleChannelList, handleEncryption } from '../../components/Modal/LoginModal/helper';
import { getUserInfo } from '../../utils/getUserInfo';
import { subbedChannelList } from '../../services/channelListService';
import CheckAuthenticateContext from '../../context/checkAuthenticateContext';

// render,setrender for channel summary update
const ChannelList = ({ render, setRender, shouldAuthenticate, isLoading, onLoad, setLoader }) => {
  const location = useLocation();

  const channelAuthModalDisplay = sessionStorage.getItem(constants?.storageConstants?.channelAuthModalDisplay);
  const [isSetupModalOpen, setIsSetupModalOpen] = useState(channelAuthModalDisplay === 'true' ? true : false);

  const { profileInfo, setProfileInfo } = useContext(CheckAuthenticateContext);
  const [isListingModalOpen, setIsListingModalOpen] = useState(false);
  const [selectedChannel, setSelectedChannel] = useState({});
  const [cookies, setCookie, removeCookie] = useCookies();
  const navigate = useNavigate();
  const { handleSuccessToast, handleErrorToast } = useToastBox();
  const [searchParams] = useSearchParams();
  const { setChannelCount, auth } = useAuth();
  const [channelSetupMessage, setChannelSetupMessage] = useState(location?.state?.message ? location?.state?.message : '');
  const searchCode = sessionStorage.getItem(constants?.storageConstants?.channelSearchCode);
  const [openConfirmationModal, setOpenConfirmationModal] = useState(false);

  const userInfo = JSON.parse(getUserInfo());
  const userId = auth?.user?.user?.id ? auth?.user?.user?.id : userInfo?.user?.id;
  const userRole = auth?.user?.user?.role ? auth?.user?.user?.role : userInfo?.user?.role;
  const decryptedRole = CryptoJS.AES.decrypt(userRole, loginConstants.localKey).toString(CryptoJS.enc.Utf8);
  const disableNavigation = decryptedRole === constants.viewer;
  const { channelList, setChannelList } = useContext(ChannelListContext);
  const [channels, setChannels] = decryptedRole === constants.viewer ? useState([]) : useState(listImageUrl(channelList));
  // eslint-disable-next-line no-unused-vars
  const [profileInformation, setProfileInformation] = useState(sessionStorage.getItem('profileInformation'));

  // This same function can be called for refresh channel
  const checkAlreadyAuthenticated = (provider, channel, title, redirectTo, newAuthentication = false) => {
    // if (decryptedRole === constants.viewer) {
    //   setOpenConfirmationModal(true);
    // } else {
    if (`${channel}Token_${userId}` in cookies && !newAuthentication) {
      handleSuccessToast(constants.alreadyAuthenticateMessage);
    } else {
      setLoader(true);
      authenticateChannel(provider, channel, title, redirectTo);
    }
    // }
  };

  const handleConfirm = () => {
    setOpenConfirmationModal(false);
  };

  const handleChannelSelection = data => {
    if (decryptedRole === constants.viewer) {
      setOpenConfirmationModal(true);
    } else {
      setSelectedChannel(data);
      setIsListingModalOpen(true);
    }
  };

  const handleChannelAuth = () => {
    const { provider, channel, redirectTo, title, workspaceId, workspaceName, pageId } = JSON.parse(
      localStorage.getItem('authChannel')
    );
    let code = '';
    let verifier = '';
    if (provider === 'twitter') {
      code = searchParams.get('oauth_token');
      verifier = searchParams.get('oauth_verifier');
    } else {
      code = searchParams.get('code');
    }
    getAuthToken(provider, code, verifier)
      .then(async response => {
        const authChannelInfo = await storeAuthToken(response, channel, setCookie, removeCookie, userId);

        if (!authChannelInfo.accountsFound) {
          setChannelSetupMessage(
            `${constants.successMessage}\n${
              channel === 'facebookpages' || channel === 'linkedinpages' ? constants.noPagesAlert : constants.noAccountsAlert
            }`
          );
        } else {
          setChannelSetupMessage(getchannelSetupMessage(authChannelInfo.profileInfo, constants.successMessage));
        }
        // setIsSetupModalOpen(true);
        channelCount()
          .then(response => {
            setChannelCount(response?.data?.data?.count);
            // render,setrender for channel summary update
            setRender(!render);
            if (redirectTo === '/userPermissionChannelView') {
              if (authChannelInfo?.accountsFound === 0) {
                handleErrorToast(
                  getchannelSetupMessage(
                    `${channel}Token_${userId}_userInfo` in cookies
                      ? cookies[`${channel}Token_${userId}_userInfo`]
                      : authChannelInfo?.profileInfo,
                    `${constants.refreshMessage}\n${
                      channel === 'facebookpages' || channel === 'linkedinpages'
                        ? constants.noPagesAlert
                        : constants.noAccountsAlert
                    }`,
                    title
                  )
                );
              } else {
                handleSuccessToast(
                  getchannelSetupMessage(
                    `${channel}Token_${userId}_userInfo` in cookies
                      ? cookies[`${channel}Token_${userId}_userInfo`]
                      : authChannelInfo?.profileInfo,
                    constants.refreshMessage,
                    title
                  )
                );
              }
              navigate(redirectTo, {
                state: { channelName: title, channelType: channel, channelProvider: provider },
                replace: true,
              });
            } else if (redirectTo === '/workspace-detailed') {
              handleSuccessToast(
                getchannelSetupMessage(
                  `${channel}Token_${userId}_userInfo` in cookies
                    ? cookies[`${channel}Token_${userId}_userInfo`]
                    : authChannelInfo?.profileInfo,
                  constants.refreshMessage,
                  title
                )
              );
              navigate(redirectTo, {
                state: { id: workspaceId, name: workspaceName },
                replace: true,
              });
            } else if (redirectTo === '/profile') {
              sessionStorage.setItem(constants?.storageConstants?.channelAuthModalDisplay, true);
              navigate(redirectTo, {
                state: {
                  // isAuthenticatedAtProfile: true,

                  message: authChannelInfo.accountsFound
                    ? getchannelSetupMessage(authChannelInfo.profileInfo, constants.successMessage)
                    : `${getchannelSetupMessage(authChannelInfo.profileInfo, constants.successMessage)}\n${
                        channel === 'facebookpages' || channel === 'linkedinpages'
                          ? constants.noPagesAlert
                          : constants.noAccountsAlert
                      }`,
                },
                replace: true,
              });
            } else if (redirectTo === '/dashboard') {
              sessionStorage.setItem(constants?.storageConstants?.channelAuthModalDisplay, true);
              // channel refresh scenario
              navigate(redirectTo, {
                state: {
                  // isAuthenticatedAtDashboard: true,
                  message: getchannelSetupMessage(
                    `${channel}Token_${userId}_userInfo` in cookies
                      ? cookies[`${channel}Token_${userId}_userInfo`]
                      : authChannelInfo?.profileInfo,
                    constants.refreshMessage,
                    title
                  ),
                },
                replace: true,
              });
            } else if (redirectTo === '/userPermissionPageAccount') {
              navigate(redirectTo, {
                state: { id: pageId },
                replace: true,
              });
            }
            onLoad();
          })
          .catch(error => {
            console.log('channel count error', error);
          });
      })
      .catch(error => {
        console.log('authentication fail issue', error);
        handleErrorToast(constants.failureMessage);
        removeCookie(`${channel}Token_${userId}`, { path: '/' });
        removeCookie(`${channel}Token_${userId}_userInfo`, { path: '/' });
        if (`${channel}TokenSecret_${userId}` in cookies) {
          removeCookie(`${channel}TokenSecret_${userId}`, { path: '/' });
        }
        // navigate('/dashboard');
        if (redirectTo === '/userPermissionChannelView') {
          navigate(redirectTo, {
            state: { channelName: title, channelType: channel, channelProvider: provider },
          });
        } else if (redirectTo === '/workspace-detailed') {
          navigate(redirectTo, {
            state: { id: workspaceId },
          });
        } else {
          navigate(redirectTo);
        }
        onLoad();
      });
  };

  useEffect(() => {
    if (channelAuthModalDisplay === 'true') {
      sessionStorage.setItem(constants?.storageConstants?.channelAuthModalDisplay, false);
    }
  }, []);

  useEffect(() => {
    if (
      channelList?.length === 0 &&
      sessionStorage.getItem(loginConstants.localChannelKey) !== null &&
      sessionStorage.getItem(loginConstants.localChannelKey) !== '[]'
    ) {
      setChannelList(JSON.parse(sessionStorage.getItem(loginConstants.localChannelKey)));
      setChannels(listImageUrl(JSON.parse(sessionStorage.getItem(loginConstants.localChannelKey))));
    } else if (channelList?.length === 0 && sessionStorage.getItem(loginConstants.localChannelKey) === null) {
      channelListService().then(response => {
        const encryptedData = handleEncryption(response?.data?.data);
        handleChannelList(encryptedData, setChannelList);
        setChannels(listImageUrl(encryptedData));
      });
    } else if (channelList?.length > 0) {
      setChannels(listImageUrl(channelList));
      if (sessionStorage.getItem(loginConstants.localChannelKey) === null) {
        channelListService().then(response => {
          const encryptedData = handleEncryption(response?.data?.data);
          handleChannelList(encryptedData, setChannelList);
          // handleChannelList(response.data.data, setChannelList);
        });
      }
    }
    if (constants.callBackUrl.includes(location.pathname) && searchCode != location.search) {
      sessionStorage.setItem(constants?.storageConstants?.channelSearchCode, location?.search);
      setLoader();
      handleChannelAuth();
    }
  }, []);

  useEffect(() => {
    if (decryptedRole !== constants.viewer) {
      if (channels?.length > 0) {
        subbedChannelList()
          .then(response => {
            setChannels(processChannels(channels, response?.data?.data));
            setProfileInfo(false);
          })
          .catch(error => console.error(error));
      }
    }
  }, [profileInfo, profileInformation]);

  return isLoading ? null : (
    <>
      <div className={classes.channelWrapper}>
        <div className={classes.channelList}>
          <div className={classes.headerWrapper}>
            <h3 className={classes.channelTitle}>Channels</h3>
            {/* <button aria-label="View All" className={`btn-link ${classes.viewAll}`}>
              View All
            </button> */}
          </div>
          <ul className={classes.channelListWrapper}>
            {channels?.map(data => {
              const decryptedState = JSON.parse(
                CryptoJS.AES.decrypt(data.isDisabled, loginConstants.channelStoreKey).toString(CryptoJS.enc.Utf8)
              );
              return (
                <>
                  {!decryptedState ? (
                    <li role="presentation" key={`dashboardChannelList__${data.id}`}>
                      <div
                        className={classes.channelItem}
                        onClick={() => {
                          if (!shouldAuthenticate && !disableNavigation) {
                            navigate('/userPermissionChannelView', {
                              state: {
                                channelName: data.title,
                                channelType: data.channel,
                                fromDashBoard: true,
                                channelProvider: data.provider,
                              },
                            });
                          }
                        }}
                      >
                        <img src={data.imgUrl} alt={data.channel} className={classes.imgIcon} />
                        <div className={classes.title}>{data.displayName}</div>
                        <div className={classes.action}>
                          {shouldAuthenticate && (
                            <div>
                              {data?.showAuthenticate && (
                                <div className=" channelTickIcon mr-2 flex ">
                                  <Icons.CircleTick />
                                </div>
                              )}
                              <Tippy content="Setup Channel" placement="bottom" arrow={true}>
                                <button
                                  className={classes.addBtn}
                                  aria-label="Add the Channel"
                                  //onClick={() => checkAlreadyAuthenticated(data.provider, data.channel, data.title, '/profile')}
                                  onClick={() => handleChannelSelection(data)}
                                >
                                  <Icons.AddIcon />
                                </button>
                              </Tippy>
                            </div>
                          )}
                        </div>
                      </div>
                    </li>
                  ) : null}
                </>
              );
            })}
          </ul>
          <ul className={classes.channelListWrapper}>
            {channels?.map(data => {
              const decryptedState = JSON.parse(
                CryptoJS.AES.decrypt(data?.isDisabled, loginConstants.channelStoreKey).toString(CryptoJS.enc.Utf8)
              );

              return (
                <>
                  {decryptedState ? (
                    <>
                      <li
                        role="presentation"
                        className="mb-1 flex items-center rounded-md md:relative md:flex-col md:justify-center"
                      >
                        <div className=" flex w-full items-center justify-between rounded-md  md:relative md:flex-col md:justify-center">
                          <div className={classes.channelItem}>
                            <div className="flex w-full items-center md:flex-col md:justify-center">
                              <div className=" flex w-full items-center space-x-1 md:flex-col md:pb-2 ">
                                <img src={data.imgUrl} alt={data.channel} className={classes.imgIcon} />
                                <div className={classes.title}>{data.displayName}</div>
                              </div>
                              <div className="whitespace-nowrap rounded-md bg-gray-light10 py-[2px] px-1 text-[10px] font-medium text-white">
                                Coming soon
                              </div>
                            </div>
                          </div>
                        </div>
                      </li>
                    </>
                  ) : null}
                </>
              );
            })}
          </ul>
        </div>
      </div>
      {openConfirmationModal && (
        <MessageModal
          isOpen={true}
          setIsOpen={setOpenConfirmationModal}
          message={constants.viewerSetupChannelMessage}
          setResetPasswordModalIsOpen={handleConfirm}
          heading={constants.setupChannelHeading}
          confirmAlert={constants.ok}
          handleConfirm={() => handleConfirm()}
        />
      )}
      <SetupChannelModal
        isSetupModalOpen={isSetupModalOpen}
        setIsSetupModalOpen={setIsSetupModalOpen}
        message={channelSetupMessage}
        showICon={true}
        location={location}
      />
      <ProfileListingModal
        isListingModalOpen={isListingModalOpen}
        setIsListingModalOpen={setIsListingModalOpen}
        handleAuthentication={() =>
          checkAlreadyAuthenticated(
            selectedChannel.provider,
            selectedChannel.channel,
            selectedChannel.title,
            '/profile',
            true
          )
        }
        data={selectedChannel}
      />
    </>
  );
};
export default ChannelList;
