import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import useAuth from '../../../customHooks/useAuth';
import Loader from '../../../components/Loader';
import SuccessIcon from '../../../assets/images/SuccessfullIcon.png';

const SuccessMessage = () => {
  const { clearData } = useAuth();
  const search = useLocation().search;
  const navigate = useNavigate();
  const planType = new URLSearchParams(search).get('type');
  const isTrial = new URLSearchParams(search).get('isTrial') === 'true' ? true : false;
  const isUpgrade = new URLSearchParams(search).get('upgrade') === 'true' ? true : false;
  const trialPeriod = new URLSearchParams(search).get('trialPeriod');

  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    if (isUpgrade) {
      clearData();
    }
    setTimeout(() => {
      setIsLoading(false);
    }, 3000);
  }, []);
  return loading ? (
    <Loader />
  ) : (
    <section className="mx-auto flex max-w-7xl  flex-col items-center px-5 pt-27 pb-48 md:pt-14 md:pb-10">
      <div className="flex max-w-[517px] flex-col items-center text-center">
        <img src={SuccessIcon} alt="successIcon" className="mb-7.5" />
        <h2 className="mb-4 text-2xl font-semibold leading-9 text-gray-dark">
          {isTrial
            ? `Congratulations!, You have successfully activated a ${trialPeriod}-day free trial for the ${planType} license`
            : planType === 'annual'
            ? `Congratulations!, You have successfully purchased the ${planType} team license.`
            : `Congratulations!, You have successfully purchased the ${planType} license.`}
        </h2>

        <button
          className="flex min-w-[152px] flex-col items-center justify-center rounded-[22px] border-2 border-solid border-primary bg-white py-2.5 text-sm font-semibold leading-6 text-primary"
          onClick={() => navigate('/', { state: { fromPayment: true } })}
        >
          Continue to Login
        </button>
      </div>
    </section>
  );
};

export default SuccessMessage;
