import React from 'react';
import Tippy from '@tippyjs/react';
import CryptoJS from 'crypto-js';
import Icons from '../../../../assets/Icons';
import { subscriptionPlans } from '../../../../constants/constants';
import { getChannelDetails } from '../../../../features/UsersPermissionsChannelView/helper';
import { textConstants } from '../../../Modal/AddUserModal/constants';
import { getUserInfo } from '../../../../utils/getUserInfo';

const TooltipMessage = ({ name, id }) => {
  let subscriptionType;
  const userInfo = JSON.parse(getUserInfo());
  const subscriptionCode = userInfo?.user?.subscriptionType;
  if (subscriptionCode) {
    subscriptionType = CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8);
  }
  return (
    <div className="max-w-[14.0625rem]">
      <div className=" flex items-center">
        <div className="tooltip-icon ">
          <Icons.EditIcon />
        </div>
        <div className="flex flex-col pl-2">
          <div className=" mb-1 text-[10px] font-semibold leading-[15px] text-white">{name}</div>
          <div className=" mb-1 text-[10px] font-semibold leading-[15px] text-white">{id}</div>

          <div className=" text-[10px] font-medium leading-[15px] text-white">
            {subscriptionType == subscriptionPlans?.individual
              ? 'Click on account to view permissions.'
              : 'Click on account to edit permissions.'}
          </div>
        </div>
      </div>
    </div>
  );
};

const WorkspaceList = ({
  channelList,
  selectedWorkspace,
  setSelectedWorkspace,
  setSelectedAccountId,
  setAccountName,
  setRedirectionURL,
  onAccountClick,
  page,
  setSearchKeyword,
  setSearchResults,
  setSetupStatus,
}) => {
  const handleAccountIdDisplay = (accountId, channel) => {
    const prefixesToCheck = ['accounts/', 'act_', 'customers/', 'locations/', 'urn:li:organization:'];
    for (let i = 0; i < prefixesToCheck.length; i++) {
      const prefix = prefixesToCheck[i];
      const pattern = new RegExp(`^${prefix}(.*)$`);
      const match = accountId.match(pattern);
      if (match) {
        if (channel === 'googletagmanager' || channel === 'googleads') {
          const extractedId = handleIdExtraction(match[1]);
          return extractedId;
        } else {
          return match[1];
        }
      }
    }
    if (channel === 'googlemerchantcenter') {
      const extractedId = handleIdExtraction(accountId);
      return extractedId;
    } else {
      return accountId;
    }
  };

  const handleIdExtraction = accountId => {
    const accountIdParts = accountId.split('/');
    const extractedString = accountIdParts[accountIdParts.length - 1];
    return extractedString;
  };

  return (
    <>
      <div className="w-[15.125rem] flex-shrink-0 overflow-auto border-r border-white200 bg-white py-4 px-2  md:w-full md:border-none md:p-0">
        <div className="w-full">
          <ul className="flex flex-col gap-2 md:flex md:flex-row md:overflow-auto md:p-5">
            {channelList.map((data, index) => {
              if (data?.accountId) {
                return (
                  <li
                    role="presentation"
                    key={`workdspaceList__${index}`}
                    onClick={() => {
                      setAccountName(data.title);
                      setSelectedAccountId(data?.accountId);
                      setRedirectionURL(data?.redirectionURL);
                      setSearchKeyword('');
                      setSearchResults([]);
                      if (page === 'workspace') {
                        onAccountClick(data);
                      }
                      if (data?.channelType === textConstants.googleAds) {
                        setSetupStatus(data?.setupInProgress);
                      }
                      setSelectedWorkspace(index);
                    }}
                  >
                    <Tippy
                      placement="bottom"
                      arrow={true}
                      content={
                        <TooltipMessage
                          name={
                            data?.title === data?.accountId &&
                            (data?.channelType === 'googleads' || data?.channelType === 'googlesearchconsole')
                              ? ''
                              : data?.title
                          }
                          id={handleAccountIdDisplay(data?.accountId, data?.channelType)}
                        />
                      }
                      key={`workdspaceList__${index}`}
                    >
                      <div
                        className={`flex cursor-pointer items-center gap-x-2 rounded-lg p-2 outline-transparent transition-all duration-300  hover:outline hover:outline-offset-0 hover:outline-primaryLight  md:min-w-[13.9375rem] md:border md:border-solid md:border-white200 md:py-4.5 md:px-3.5 ${
                          selectedWorkspace === index &&
                          'active-state bg-primaryLight  md:bg-white  md:shadow-4xl md:outline md:outline-2 md:outline-offset-0 md:outline-primary md:hover:outline md:hover:outline-primary'
                        }`}
                      >
                        <img
                          src={data?.imgUrl}
                          alt={data?.title}
                          className="h-6 w-6 shrink-0 rounded-full object-contain"
                          onError={event => {
                            event.currentTarget.src = getChannelDetails(data?.channelType).imgUrl;
                          }}
                        />
                        <div className="heading w-full truncate text-sm font-normal capitalize leading-4.5  text-gray-dark md:text-sm md:font-semibold md:leading-5.5">
                          {data?.title === data?.accountId && data?.channelType === 'googleads'
                            ? `Account Id: ${data?.title?.split('/')[1]}`
                            : data?.title
                            ? data?.title
                            : '-'}
                          {data?.title !== data?.accountId && (
                            <div className=" truncate text-xs italic text-gray-700" style={{ 'text-transform': 'none' }}>
                              {handleAccountIdDisplay(data?.accountId, data?.channelType)}
                            </div>
                          )}
                        </div>
                      </div>
                    </Tippy>
                  </li>
                );
              }
            })}
          </ul>
        </div>
      </div>
    </>
  );
};
export default WorkspaceList;
