import React from 'react';

const ChannelHeader = ({ headerDetails, constants }) => {
  return (
    <div className="flex items-center">
      <div className="flex h-7 w-7 items-center justify-center overflow-hidden   object-contain">
        <img src={headerDetails.logo} alt={headerDetails.channelName} />
      </div>
      <div className="ml-2 overflow-hidden">
        <h2 className="left-4.5 truncate text-xs font-semibold text-gray-dark" title={headerDetails.channelName}>
          {headerDetails.channelName}
        </h2>
        <p className="text-[10px] font-medium leading-3.5 text-gray-medium">
          {headerDetails.pageCount} {constants.label.pages}
        </p>
      </div>
    </div>
  );
};

export default ChannelHeader;
