import React from 'react';
import CryptoJS from 'crypto-js';
import Icons from '../../assets/Icons';
import { Link } from 'react-router-dom';
import classes from './profile.module.scss';
import useAuth from '../../customHooks/useAuth';
import { subscriptionPlans } from '../../constants/constants';

import { getUserInfo } from '../../utils/getUserInfo';
const ProfileDropdown = ({ toggleHandle }) => {
  const { logout, auth } = useAuth();
  let subscriptionType;
  const userInfo = JSON.parse(getUserInfo());
  const subscriptionCode = userInfo?.user?.subscriptionType;
  if (subscriptionCode) {
    subscriptionType = CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8);
  }

  const userDetails = auth?.user?.user ? auth?.user?.user : JSON.parse(getUserInfo())?.user;

  const nameInitials = auth.user?.user?.name
    .match(/(\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toUpperCase();
  const userProfileImage = auth?.user?.user?.profileImage
    ? auth.user.user.profileImage
    : JSON.parse(getUserInfo())?.user?.profileImage;

  return (
    <div className={`${classes.dropdownCustom} ${classes.bottom} ${classes.mobFloat} ${classes.profileDropdown}`}>
      <div className={classes.dropdownBackdrop} />
      <div className={classes.dropdownContent}>
        <div className={classes.dropdownHeader}>
          <h3 className={classes.title}>
            <button aria-label="Open Profile" className={`${classes.circleProfile} cursor-default`}>
              {userProfileImage ? (
                <span>
                  <img src={userProfileImage} alt="profile image"></img>
                </span>
              ) : (
                <span>{nameInitials}</span>
              )}
            </button>
            <div className={classes.ProfileDetails}>
              <div className={classes.ProfileName}>{auth.user?.user?.name}</div>
              <div className={classes.ProfileEmail}>{auth.user?.user?.email}</div>
            </div>
          </h3>
          <div className={classes.actiongroup}>
            <button
              className={`btn-icon ${classes.closeBtn}`}
              aria-label="Close Profile Options"
              onClick={() => toggleHandle(false)}
            >
              <Icons.Cross />
            </button>
          </div>
        </div>
        <div className={classes.dropdownBody}>
          <ul className={classes.profileList}>
            <li role="presentation">
              <Link to="/profile" className={classes.ProfileLink}>
                My Profile
              </Link>
            </li>
            {userDetails?.owner && subscriptionType !== subscriptionPlans.enterprise && (
              <li role="presentation">
                <Link to="/billing" className={classes.ProfileLink}>
                  Billing Information
                </Link>
              </li>
            )}
            {/* {userRole !== 'user' && (
              <li role="presentation">
                <Link to={''} className={classes.ProfileLink}>
                  Subscription Settings
                </Link>
              </li>
            )} */}
            <li
              role="presentation"
              onClick={() => {
                logout();
              }}
            >
              <Link to={''} className={`${classes.ProfileLink} ${classes.logoutLink}`}>
                Logout
              </Link>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};
export default ProfileDropdown;
