import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useCookies } from 'react-cookie';
import Tippy from '@tippyjs/react';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../../customHooks/useAuth';
import { channelJoined, constants as dashBoardConstants } from '../constants';
import { constants as commonConstants, subscriptionPlans } from '../../../constants/constants';
import { subbedChannelIconsConstants } from './constants';
import { subbedChannelList, channelCount } from '../../../services/channelListService';
import { getAccountService } from '../../../services';
import { getImageUrls } from './helper';
import { authenticateChannel, getchannelSetupMessage } from '../../../components/ChannelSetup/helper';
import Icons from '../../../assets/Icons';
import NoData from '../../../components/Nodata';
import { useToastBox } from '../../../customHooks/useToastBox';
import { getUserInfo, setUserInfo } from '../../../utils/getUserInfo';
import { labelDisplay } from '../../../utils/helpers';
import { constants } from '../../../components/ChannelSetup/constants';
import { storeSubbedChannelList } from '../../../components/Modal/LoginModal/helper';

// render,setrender for channel summary update
const ChannelSubbed = ({ classes, render, onLoad, setLoader, isLoading }) => {
  let subscriptionType;
  const userInfo = JSON.parse(getUserInfo());
  const subscriptionCode = userInfo?.user?.subscriptionType;
  if (subscriptionCode) {
    subscriptionType = CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8);
  }
  const [channelList, setChannelList] = useState([]);
  const [disableRefresh, setDisableRefresh] = useState({ value: false, selectedIndex: '' });
  const [dashboardChannelCount, setdashboardChannelCount] = useState(0);
  const { setChannelCount, auth } = useAuth();
  const [cookies] = useCookies();
  const navigate = useNavigate();
  const userId = auth?.user?.user?.id ? auth?.user?.user?.id : userInfo?.user?.id;
  const { handleSuccessToast, handleErrorToast } = useToastBox();
  // eslint-disable-next-line no-unused-vars
  const [subbedChannels, setSubbedChannels] = useState([]);

  const fetchChannelList = () => {
    subbedChannelList()
      .then(res => {
        const imageData = getImageUrls(res?.data?.data);
        storeSubbedChannelList(res?.data?.data, setSubbedChannels);
        setChannelList(imageData);
        channelCount()
          .then(response => {
            onLoad();
            sessionStorage.setItem('message_count', response?.data?.data?.messageReadStatus);
            setdashboardChannelCount(response?.data?.data?.count);
            setChannelCount(response?.data?.data?.count);
            const user = JSON.parse(getUserInfo());
            user.channelCount = response?.data?.data?.count;
            setUserInfo(JSON.stringify(user));
          })
          .catch(error => {
            onLoad();
            console.error(error);
          });
      })
      .catch(error => {
        onLoad();
        console.error(error);
      });
  };

  function throttle(mainFunction, delay = 15000) {
    let timerFlag = null;
    return (...args) => {
      if (timerFlag === null) {
        mainFunction(...args);
        timerFlag = setTimeout(() => {
          setDisableRefresh(prevState => ({
            ...prevState,
            value: false,
          }));
          timerFlag = null;
        }, delay);
      }
    };
  }

  const handleRefresh = throttle((provider, channel, title) => refreshChannel(provider, channel, title));

  const refreshChannel = (provider, channel, title) => {
    setLoader();
    sessionStorage.setItem('showModal', true);
    if (`${channel}Token_${userId}` in cookies) {
      const token = cookies[`${channel}Token_${userId}`];
      let tokenSecret = '';
      if (
        provider === subbedChannelIconsConstants?.provider?.twitterAdsProvider ||
        provider === subbedChannelIconsConstants?.provider?.twitterPagesProvider
      ) {
        tokenSecret = cookies[`${channel}TokenSecret_${userId}`];
      }
      getAccountService(channel, token, tokenSecret)
        .then(() => {
          setDisableRefresh(prevState => ({
            ...prevState,
            value: true,
          }));
          handleSuccessToast(
            getchannelSetupMessage(cookies[`${channel}Token_${userId}_userInfo`], constants.refreshMessage, title)
          );
          fetchChannelList();
          onLoad();
        })
        .catch(error => {
          if (
            error?.message === commonConstants.error.tokenExpired ||
            error?.code === commonConstants?.errorCode?.tokenExpired
          ) {
            onLoad();
            handleErrorToast(`${error?.message}. ${commonConstants.error.autheticateChannel}`);
            setTimeout(() => {
              authenticateChannel(provider, channel, title, '/dashboard');
            }, 3500);
          } else {
            onLoad();
            handleErrorToast(`${error?.message}`);
          }
        });
    } else {
      onLoad();
      authenticateChannel(provider, channel, title, '/dashboard');
    }
  };

  useEffect(() => {
    fetchChannelList();

    // render,setrender for channel summary update
  }, [render]);

  return (
    <>
      {channelJoined && !isLoading && (
        <div
          className={
            subscriptionType == subscriptionPlans?.individual && channelList?.length === 0 && !isLoading
              ? `${classes.channelConsole} ${classes.card} ${classes.channelNoData}`
              : `${classes.channelConsole} ${classes.card}`
          }
        >
          <div className={classes.channelHeader}>
            {(auth?.channelCount > 0 || dashboardChannelCount > 0) && (
              <h3>
                {auth?.channelCount ? auth.channelCount : dashboardChannelCount}{' '}
                {/* {auth.channelCount > 1
                ? subbedChannelIconsConstants.channels
                : dashboardChannelCount > 1
                ? subbedChannelIconsConstants.channels
                : subbedChannelIconsConstants.channel} */}
                {auth.channelCount == 1 || dashboardChannelCount == 1
                  ? subbedChannelIconsConstants.channel
                  : subbedChannelIconsConstants.channels}
              </h3>
            )}
            {auth?.channelCount ? (
              <button
                aria-label={dashBoardConstants?.label?.viewAll}
                className={`btn-link ${classes.viewAll}`}
                onClick={() => navigate('/UserPermissionChannel')}
              >
                {dashBoardConstants?.label?.viewAll}
              </button>
            ) : null}
          </div>
          <div className={classes.channelBody}>
            {channelList?.length === 0 && !isLoading && (
              <div className={`${classes.cardEmpty}`}>
                <NoData message={dashBoardConstants?.message?.noDataMessage} showImage={false} isLinkPresent={true} />
              </div>
            )}
            {channelList?.map((element, index) => {
              return (
                <div
                  className={`${classes.card} `}
                  key={`channelList__${index}`}
                  onClick={() => {
                    navigate('/userPermissionChannelView', {
                      state: {
                        channelName: element.title,
                        channelType: element.channelType,
                        fromDashBoard: true,
                        channelProvider: element.provider,
                      },
                    });
                  }}
                >
                  <div className={classes.cardHeader}>
                    <div className={classes.cardHeaderDetails}>
                      <div className={classes.logoImg}>
                        <img src={element.imgUrl} alt={element.title} />
                      </div>
                      <Tippy content={element.title} placement="bottom" arrow={true}>
                        <div className={classes.cardTitle}>{element.title}</div>
                      </Tippy>
                    </div>

                    <div
                      className={classes.externalLink}
                      onClick={e => {
                        e.stopPropagation();
                        navigate('/userPermissionChannelView', {
                          state: {
                            channelName: element.title,
                            channelType: element.channelType,
                            fromDashBoard: true,
                            channelProvider: element.provider,
                          },
                        });
                      }}
                    >
                      <Tippy content={dashBoardConstants?.tooltipMessage?.viewChannel} placement="bottom" arrow={true}>
                        <Link>
                          <Icons.Export />
                        </Link>
                      </Tippy>
                    </div>
                  </div>
                  <div className={classes.cardRow}>
                    <div className={classes.legendIcon}>
                      <Icons.User />
                    </div>
                    <div className={classes.legendTitle}>
                      {element.accountCount == null ? 0 : element.accountCount}{' '}
                      {labelDisplay(element.accountCount, element.channelType)}
                      {/* {element.accountCount == 1 ? dashBoardConstants?.label?.page : dashBoardConstants?.label?.pages} */}
                    </div>
                  </div>
                  <div className={classes.cardRow}>
                    <div className={classes.legendIcon}>
                      <Icons.Lock />
                    </div>
                    <div className={classes.legendTitle}>
                      {element.userCount == null ? 0 : element.userCount}{' '}
                      {element.userCount == 1
                        ? dashBoardConstants?.label?.permission
                        : dashBoardConstants?.label?.permissions}
                    </div>
                  </div>
                  <div className={classes.cardFooter}>
                    <button
                      className={`btn ${classes.refreshBtn}`}
                      aria-label={dashBoardConstants?.label?.refreshChannel}
                      onClick={e => {
                        e.stopPropagation();
                        setDisableRefresh(prevState => ({
                          ...prevState,
                          selectedIndex: index,
                        }));
                        handleRefresh(element.provider, element.channelType, element.title);
                      }}
                      disabled={disableRefresh.value && disableRefresh.selectedIndex === index}
                    >
                      <Icons.Refresh />
                      {disableRefresh.value ? (
                        <Tippy content="Background sync is in progress" placement="bottom" arrow={true}>
                          <div>{dashBoardConstants?.label?.refreshChannel}</div>
                        </Tippy>
                      ) : (
                        <div>{dashBoardConstants?.label?.refreshChannel}</div>
                      )}
                    </button>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};

export default ChannelSubbed;
