import { API } from '../constants/api';
import { post } from '../utils/apiHandler';

export const verifyEmail = async data => {
  try {
    const response = await post(`${API.verify_email}?token=${data}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
