import React from 'react';
import ChannelHeader from './ChannelHeader';
import CompanyCard from './CompanyCard';

const ChannelsList = ({
  data,
  constants,
  filter,
  pageList,
  setPageList,
  droppedData,
  setDroppedData,
  tempId,
  workspaceRender,
  setWorkspaceRender,
  createWorkspaceData,
}) => {
  function handleDragStart(event, key) {
    event.dataTransfer.setData('text/plain', key);
  }

  return (
    <div className=" custom-vertical-scrollbar flex w-[298px] min-w-[298px] flex-col rounded-lg border border-white200 bg-white">
      <div className="border-gray-1600 border-b border-solid p-4">
        <ChannelHeader headerDetails={data} constants={constants} />
      </div>

      <div className="mr-2  overflow-hidden pl-4 pt-4 pb-5 pr-2 hover:overflow-auto">
        {data.pagesDetails?.map((item, index) => {
          if (Object.values(filter).length && filter.value !== 'All') {
            if (filter.value === item.title) {
              return (
                <div draggable onDragStart={event => handleDragStart(event, item.id)} key={`companyCard__${index}`}>
                  <CompanyCard
                    PageDetails={item}
                    constants={constants}
                    enableClose={false}
                    pageList={pageList}
                    setPageList={setPageList}
                    droppedData={droppedData}
                    setDroppedData={setDroppedData}
                    tempId={tempId}
                    workspaceRender={workspaceRender}
                    setWorkspaceRender={setWorkspaceRender}
                    createWorkspaceData={createWorkspaceData}
                  />
                </div>
              );
            }
          } else {
            return (
              <div draggable onDragStart={event => handleDragStart(event, item.id)} key={`companyCard__${index}`}>
                <CompanyCard
                  PageDetails={item}
                  constants={constants}
                  enableClose={false}
                  pageList={pageList}
                  setPageList={setPageList}
                  droppedData={droppedData}
                  setDroppedData={setDroppedData}
                  tempId={tempId}
                  workspaceRender={workspaceRender}
                  setWorkspaceRender={setWorkspaceRender}
                  createWorkspaceData={createWorkspaceData}
                />
              </div>
            );
          }
        })}
      </div>
    </div>
  );
};
export default ChannelsList;
