import React from 'react';
import Icons from '../../../../assets/Icons';

const OnSuccessModal = ({ message, showIcon }) => {
  return (
    <div className="bg-white px-6 pb-6">
      {showIcon ? (
        <div className="m-auto mb-6 w-15.5 text-green-dark100">
          <Icons.CircleTick />
        </div>
      ) : (
        <div className="alertIcon z-10 flex justify-center pb-7 text-red-700">
          <Icons.AlertIcon />
        </div>
      )}
      <h4 className="mb-2 flex justify-center font-semibold leading-normal text-gray-dark">{message}</h4>
    </div>
  );
};
export default OnSuccessModal;
