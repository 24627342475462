import { API } from '../../constants/api';
import { put } from '../../utils/apiHandler';

export const editWorkspace = async (
  data,
  workspaceId,
  createWorkspaceData,
  existingViewersList,
  newViewersList,
  removedViewerIds,
  removedInvitedViewerIds
) => {
  const expiringViewerData = sessionStorage.getItem('expiringViewers');
  let expiringViewers = [];
  let newViewersData = newViewersList;
  if (expiringViewerData) {
    expiringViewers = JSON.parse(expiringViewerData);
    newViewersData = newViewersList.filter(function (item) {
      return !expiringViewers.includes(item);
    });
  }

  const addedViewersSet = new Set(newViewersData);
  const deletedViewersSet = new Set(removedViewerIds);
  const existingViewersSet = new Set(existingViewersList);
  const deleteInvitedViewersSet = new Set(removedInvitedViewerIds);
  const addedExpiringViewersSet = new Set(expiringViewers);
  try {
    const postData = {
      workspaceId: workspaceId,
      ...(createWorkspaceData.nameChange && { name: createWorkspaceData.name }), // if there has been a change to the workspace name,then only the workspace name is being passed
      accounts: [
        {
          addedAccounts: data.accounts.addedAccounts,
          deletedAccounts: data.accounts.deletedAccounts,
        },
      ],
      users: [
        {
          addedUsers: data.users.addedUsers,
          deletedUsers: data.users.deletedUsers,
        },
      ],
      viewers: [
        {
          addedViewers: [...addedViewersSet],
          deletedViewers: [...deletedViewersSet],
          existingViewers: [...existingViewersSet],
          deleteInvitedViewers: [...deleteInvitedViewersSet],
          addedExpiringViewers: [...addedExpiringViewersSet],

          //after proper testing will remove

          // addedViewers: newViewersList,
          // deletedViewers: removedViewerIds,
          // existingViewers: existingViewersList,
          // deleteInvitedViewers: removedInvitedViewerIds,
        },
      ],
    };
    const response = await put(API.workspace_detailed, postData);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
