import React, { useState } from 'react';
import Icons from '../../../../assets/Icons';
import { MessageModal } from '../../../../components/Modal/MessageModal';
import { getChannelDetails } from '../../../UsersPermissionsChannelView/helper';
import { constants as modalConstants } from '../../constants';
const ChannelsListCard = ({
  PageDetails,
  droppedData,
  constants,
  createWorkspaceData,
  workspaceId,
  setCreateWorkspaceData,

  workspaceActualInfo,
}) => {
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const handleCardSelection = (event, PageDetails) => {
    const radix = 10;
    if (event.target.checked) {
      if (!createWorkspaceData?.accounts?.includes(parseInt(PageDetails?.id, radix))) {
        PageDetails.mobileChecked = true;
        createWorkspaceData?.accounts.push(parseInt(PageDetails?.id, radix));
      }
    } else if (workspaceActualInfo?.accounts?.includes(PageDetails?.id)) {
      PageDetails.mobileChecked = true;
      // Trying to remove an account added by the different user
      droppedData?.forEach(item => {
        if (item?.id === PageDetails?.id && item?.addedBy !== createWorkspaceData?.loggedinUser) {
          setConfirmationModal(true);
        } else if (item?.id === PageDetails?.id && item?.addedBy === createWorkspaceData?.loggedinUser) {
          PageDetails.mobileChecked = false;
          setCreateWorkspaceData(values => ({
            ...values,
            accounts: createWorkspaceData?.accounts?.filter(item => item !== PageDetails?.id),
          }));
        }
      });
    } else {
      PageDetails.mobileChecked = false;

      setCreateWorkspaceData(values => ({
        ...values,
        accounts: createWorkspaceData?.accounts?.filter(item => item !== PageDetails?.id),
      }));
    }
  };

  const handleAccountsConfirm = () => {
    PageDetails.mobileChecked = false;
    setCreateWorkspaceData(values => ({
      ...values,
      accounts: createWorkspaceData?.accounts?.filter(item => item !== PageDetails?.id),
    }));
  };

  return (
    <div className="channel-outline relative mb-4 cursor-pointer overflow-hidden rounded-lg border border-solid border-white200 bg-white hover:outline hover:outline-2">
      <div className=" bg-gray-1700 flex items-center border-b border-solid border-white200 px-5 py-3">
        <div className="bg-gray-1700 flex h-7 w-7 items-center justify-center overflow-hidden rounded-md border border-solid border-white200 object-contain">
          <img
            src={PageDetails.logo}
            alt="company logo"
            onError={event => {
              event.currentTarget.src = getChannelDetails(PageDetails?.channel).imgUrl;
            }}
          />
        </div>
        <h2
          className="ml-2 w-[calc(100%-62px)] truncate text-sm font-semibold leading-[21px] text-gray-dark"
          title={PageDetails?.title}
        >
          {PageDetails?.title}
        </h2>
        <div className=" absolute right-4">
          <label className="form-checkbox">
            <input
              className="form-checkbox-input"
              type="checkbox"
              id={`${PageDetails?.id}${PageDetails?.accountId}${PageDetails?.mobileChecked}`}
              key={`${PageDetails?.id}${PageDetails?.accountId}${PageDetails?.mobileChecked}`}
              name={constants.droppableFormTitles.checkboxName}
              onChange={event => handleCardSelection(event, PageDetails)}
              checked={
                workspaceId
                  ? createWorkspaceData?.accounts?.find(item => item === PageDetails?.id)
                  : PageDetails?.id === createWorkspaceData?.loggedinUser
                  ? true
                  : createWorkspaceData?.accounts?.find(item => item === PageDetails?.id)
              }
            />
            <div className="form-check-circle">
              <Icons.TickSmall />
            </div>
          </label>
        </div>
      </div>
      {/*---------- Needed ----------- */}
      {/* <div className="flex items-start py-4 px-5 text-gray-1800">
        <Icons.LockIcon />
        <div className="ml-2">
          <p className="text-sm font-normal leading-[21px] text-gray-medium">{constants.permissions.title}</p>
          {PageDetails.edit ? (
            <p className="text-sm font-medium leading-[21px] text-gray-dark">{constants.permissions.editView}</p>
          ) : (
            <p className="text-sm font-medium leading-[21px] text-gray-dark">{constants.permissions.view}</p>
          )}
        </div>
      </div> */}
      {openConfirmationModal && (
        <MessageModal
          isOpen={true}
          setIsOpen={setConfirmationModal}
          message={modalConstants.infoMessages.modalMessage}
          setResetPasswordModalIsOpen={setConfirmationModal}
          heading={modalConstants.label.messageHeading}
          buttonConfirmLabel={modalConstants.button.confirm}
          buttonCancelLabel={modalConstants.button.cancel}
          handleConfirm={() => handleAccountsConfirm()}
        />
      )}
    </div>
  );
};
const MobileChannelsList = ({
  data,
  selectedCard,
  itemIndex,
  droppedData,

  constants,
  setCreateWorkspaceData,
  createWorkspaceData,

  workspaceId,
  workspaceActualInfo,
}) => {
  return (
    <div className={`${selectedCard === itemIndex ? 'w-full' : 'hidden'}`}>
      {data?.pagesDetails?.map((item, index) => (
        <ChannelsListCard
          PageDetails={item}
          key={`companyCard__${index}`}
          droppedData={droppedData}
          constants={constants}
          setCreateWorkspaceData={setCreateWorkspaceData}
          createWorkspaceData={createWorkspaceData}
          workspaceId={workspaceId}
          workspaceActualInfo={workspaceActualInfo}
        />
      ))}
    </div>
  );
};
export default MobileChannelsList;
