import { processChannelList } from '../UserPermissionChannel/helper';
import { constants } from './constants';
import { editWorkspace } from '../../services/Workspace/editWorkspaceService';
import { constants as commonConstants } from '../../constants/constants';
import { deleteWorkspaceService } from '../../services';
import { getChannelLogo } from '../../utils/helpers';

const handleWorkspaceName = (event, setCreateWorkspaceData, setCreateWorkspaceFormError) => {
  if (!event.target.value.trim() || event.target.value === undefined) {
    setCreateWorkspaceFormError(values => ({
      ...values,
      [event.target.name]: constants?.validationErrorMessages[event.target.name],
    }));
  } else {
    setCreateWorkspaceData(values => ({
      ...values,
      [event.target.name]: event.target.value,
      nameChange: true,
    }));
    setCreateWorkspaceFormError(values => ({
      ...values,
      [event.target.name]: constants?.validationErrorMessages?.emptyString,
    }));
  }
};

const handleDragOver = event => {
  event.preventDefault();
};

const handleDrop = (event, createWorkspaceData, pageList, setDroppedData, setPageList, setRender, render) => {
  event.preventDefault();
  const radix = 10;
  const id = event.dataTransfer.getData('text/plain');
  createWorkspaceData?.accounts?.push(parseInt(id, radix));
  pageList?.map((item, channelIndex) =>
    item?.pagesDetails?.map(async (card, cardIndex) => {
      if (card.id == id) {
        card.addedBy = createWorkspaceData?.loggedinUser;
        await setDroppedData(values => [...values, card]); // add card to droppeddata
        // await finalDataToSend(droppedData, createWorkspaceData, setCreateWorkspaceData);
        pageList[channelIndex]?.pagesDetails?.splice(cardIndex, 1); //remove card from pagelist
        setPageList(pageList);
        setRender(!render);
      }
    })
  );
};

const handleWebCancel = (
  setDroppedData,
  setCreateWorkspaceData,
  setCreateWorkspaceFormError,
  setRender,
  render,
  setWorkspaceRender,
  workspaceRender,
  setPageList,
  temp,
  setSelectedUsers,
  setViewOnlyUsers,
  setCancel,
  loggedinUser,
  setIsFormSubmitted,
  setIsViewerFormSubmitted,
  setRenderViewer = () => {}
) => {
  if (document.getElementById(constants?.droppableFormTitles.workspaceId) !== null) {
    document.getElementById(constants?.droppableFormTitles.workspaceId).value = '';
  }
  setDroppedData([]);
  setCreateWorkspaceData({
    name: '',
    accounts: [],
    users: [],
    workspaceOwner: '',
    loggedinUser: loggedinUser,
    existingViewers: [],
    newViewers: [],
    deletedViewers: [],
    deleteInvitedViewers: [],
  });
  setCreateWorkspaceFormError({});
  setRender(!render);
  setWorkspaceRender(!workspaceRender);
  setPageList(processChannelList(temp));
  setSelectedUsers([]);
  setViewOnlyUsers([]);
  setCancel(true);
  setIsFormSubmitted({ submitted: false, formUsers: [] });
  setIsViewerFormSubmitted({ added: false, formViewers: [] });
  setRenderViewer(false);
};

const handleWebEditCancel = (
  setDroppedData,
  setCreateWorkspaceData,
  setCreateWorkspaceFormError,
  setRender,
  render,
  setWorkspaceRender,
  workspaceRender,
  setPageList,
  temp,
  workspaceDetailData,
  setSelectedUsers,
  setViewOnlyUsers,
  setIsFormSubmitted,
  setIsViewerFormSubmitted,
  setCancel,
  setRenderViewer,
  setExistingViewersList,
  setRemovedViewerIds
) => {
  document.getElementById(constants?.droppableFormTitles.workspaceId).value = workspaceDetailData?.name;
  setSelectedUsers(processUserAdminId(workspaceDetailData?.adminDetails));
  setIsFormSubmitted({ submitted: true, formUsers: processUserAdminId(workspaceDetailData?.adminDetails) });
  setIsViewerFormSubmitted({
    added: true,
    formViewers: workspaceDetailData.invitedUsers
      ? processExistingViewersId([...workspaceDetailData.userDetails, ...workspaceDetailData.invitedUsers])
      : processExistingViewersId([...workspaceDetailData.userDetails]),
  });
  setCancel(true);
  setCreateWorkspaceData(values => ({
    ...values,
    name: workspaceDetailData?.name,
    accounts: processDroppedDataId(workspaceDetailData?.WorkspaceAccounts),
    users: processUserAdminId(workspaceDetailData?.adminDetails),
  }));
  setDroppedData(processDroppedData(workspaceDetailData?.WorkspaceAccounts));
  setPageList(
    filterPageListWithDroppedData(
      processDroppedDataIdWithAddedUsers(workspaceDetailData?.WorkspaceAccounts).sort(),
      processChannelList(temp)
    )
  );
  setViewOnlyUsers([]);
  setRender(!render);
  setWorkspaceRender(!workspaceRender);
  setCreateWorkspaceFormError({});
  setRenderViewer(false);
  setExistingViewersList([]);
  setRemovedViewerIds([]);
};

const handleMobileCancel = (
  setDroppedData,
  setCreateWorkspaceData,
  setCreateWorkspaceFormError,
  setWorkspaceRender,
  workspaceRender,
  setPageList,
  temp,
  setIsFormSubmitted = () => {},
  createWorkspaceData = {}
) => {
  document.getElementById(constants?.droppableFormTitles?.workspaceMobileId).value = '';
  var get = document.getElementsByName(constants?.droppableFormTitles?.checkboxName);

  for (var i = 0; i < get?.length; i++) {
    get[i].checked = false;
  }
  setDroppedData([]);
  setCreateWorkspaceData({
    name: '',
    accounts: [],
    users: [],
    workspaceOwner: '',
    loggedinUser: createWorkspaceData?.loggedinUser,
  });
  setCreateWorkspaceFormError({});
  setPageList(processChannelList(temp));
  setWorkspaceRender(!workspaceRender);
  setIsFormSubmitted({ submitted: false, formUsers: [] });
};

const handleMobileEditCancel = (
  workspaceActualInfo,
  setCreateWorkspaceData,
  setDroppedData,
  setPageList,
  setIsFormSubmitted,
  temp,
  setSelectedUsers,
  workspaceDetailData,
  setViewOnlyUsers,
  setIsViewerFormSubmitted,
  setCancel,
  setRenderViewer,
  setExistingViewersList,
  setRemovedViewerIds
) => {
  document.getElementById(constants?.droppableFormTitles?.workspaceMobileId).value = workspaceActualInfo?.name;
  setCreateWorkspaceData(values => ({
    ...values,
    name: workspaceActualInfo?.name,
    accounts: workspaceActualInfo?.accounts?.sort(),
    users: workspaceActualInfo?.users?.sort(),
    workspaceOwner: workspaceActualInfo?.workspaceOwner,
  }));
  setDroppedData(processDroppedData(workspaceDetailData?.WorkspaceAccounts));
  setPageList(processChannelList(temp));
  setIsFormSubmitted({
    submitted: true,
    formUsers: workspaceActualInfo?.users,
    accountsSubmited: true,
    formAccounts: workspaceActualInfo?.accounts,
  });
  setSelectedUsers(JSON.parse(JSON.stringify(workspaceActualInfo?.users)));
  setViewOnlyUsers([]);
  setRenderViewer(false);
  setExistingViewersList([]);
  setRemovedViewerIds([]);
  setIsViewerFormSubmitted({
    added: true,
    formViewers: workspaceDetailData?.invitedUsers
      ? processExistingViewersId([...workspaceDetailData.userDetails, ...workspaceDetailData.invitedUsers])
      : processExistingViewersId([...workspaceDetailData.userDetails]),
  });
  setCancel(true);
};

const handleSave = async (
  createWorkspaceData,
  createWorkspaceFormError,
  handleSuccessToast,
  handleErrorToast,
  createWorkspaceService,
  setDroppedData,
  setCreateWorkspaceData,
  setCreateWorkspaceFormError,
  mobileview,
  setWorkspaceRender,
  workspaceRender,
  setPageList,
  temp,
  navigate,
  workspaceId,
  workspaceActualInfo,
  selectedUsers,
  setSelectedUsers,
  setIsFormSubmitted = () => {},
  setIsViewerFormSubmitted,
  setRender = () => {},
  render = '',
  userList,
  existingViewersList,
  newViewersList,
  removedViewerIds,
  removedInvitedViewerIds,
  setRemovedInvitedViewerIds,
  setFailedEmailIdsList,
  setViewOnlyUsers,
  setCancel,
  setIsLoading,
  setToastMessage
) => {
  const errorFlag = Object.values(createWorkspaceFormError)?.every(value => value === undefined || value === '');
  if (createWorkspaceData?.name !== '' && errorFlag) {
    createWorkspaceData?.accounts?.sort();
    // HAPPY FLOW ON COMMENT : WORKING, DONT KNOW WHICH SCENARIO NEEDS IT
    // userList.filter(function (item) {
    //   if (createWorkspaceData.users.indexOf(item) === -1) {
    //     createWorkspaceData.users.push(item);
    //   }
    // });
    if (createWorkspaceData && createWorkspaceData?.existingViewers) {
      existingViewersList.forEach(viewer => {
        if (!createWorkspaceData.existingViewers.includes(viewer)) {
          createWorkspaceData.existingViewers.push(viewer);
        }
      });
    }
    if (createWorkspaceData && createWorkspaceData?.newViewers) {
      newViewersList.forEach(viewer => {
        if (!createWorkspaceData.newViewers.includes(viewer)) {
          createWorkspaceData.newViewers.push(viewer);
        }
      });
    }
    // createWorkspaceData?.existingViewers?.push(...existingViewersList);
    // createWorkspaceData?.newViewers?.push(...newViewersList);
    createWorkspaceData?.deleteInvitedViewers?.push(...removedInvitedViewerIds);
    createWorkspaceData?.deletedViewers?.push(...removedViewerIds);
    //Edit
    if (workspaceId) {
      const usersUpdateInfo = {
        users: {
          addedUsers: [],
          deletedUsers: [],
        },
        accounts: {
          addedAccounts: [],
          deletedAccounts: [],
        },
      };
      createWorkspaceData.users = JSON.parse(JSON.stringify(selectedUsers));
      if (JSON.stringify(workspaceActualInfo) === JSON.stringify(createWorkspaceData)) {
        handleErrorToast(constants?.validationErrorMessages?.nochange);
        setIsLoading(false);
      } else {
        const data = processEditWorkspace(usersUpdateInfo, createWorkspaceData, workspaceActualInfo);
        editWorkspace(
          data,
          workspaceId,
          createWorkspaceData,
          existingViewersList,
          newViewersList,
          removedViewerIds,
          removedInvitedViewerIds
        )
          .then(response => {
            const invitationStatusList = handleInvitationStatusDetails(response?.data?.data?.data?.newViewersInvitation);
            if (invitationStatusList?.length > 0) {
              setFailedEmailIdsList(invitationStatusList);
              setIsLoading(false);
              setToastMessage(response.data.data.message);
            } else {
              setIsLoading(false);
              handleSuccessToast(response.data.data.message);
              navigate(commonConstants?.routes?.dashboard);
            }
          })
          .catch(error => {
            handleErrorToast(error?.message);
            setIsLoading(false);
            navigate(commonConstants?.routes?.dashboard);
          });
      }
    } else {
      createWorkspaceData.users = selectedUsers; // Add all users from selected users to createWorkspace.users
      if (!createWorkspaceData.users.includes(createWorkspaceData?.loggedinUser)) {
        // to remove duplication on adding workspace owner
        createWorkspaceData.users.push(createWorkspaceData?.loggedinUser);
      }
      createWorkspaceService(createWorkspaceData)
        .then(response => {
          const invitationStatusList = handleInvitationStatusDetails(response?.data?.data?.newViewersInvitation);
          if (invitationStatusList?.length > 0) {
            setFailedEmailIdsList(invitationStatusList);
            setIsLoading(false);
            setToastMessage(`${constants?.droppableFormTitles?.successMessage} ${response?.data?.data?.name}`);
          } else {
            handleSuccessToast(`${constants?.droppableFormTitles?.successMessage} ${response?.data?.data?.name}`);
            if (mobileview) {
              handleMobileCancel(
                setDroppedData,
                setCreateWorkspaceData,
                setCreateWorkspaceFormError,
                setWorkspaceRender,
                workspaceRender,
                setPageList,
                temp
              );
            } else {
              handleWebCancel(
                setDroppedData,
                setCreateWorkspaceData,
                setCreateWorkspaceFormError,
                setRender,
                render,
                setWorkspaceRender,
                workspaceRender,
                setPageList,
                temp,
                setSelectedUsers,
                setViewOnlyUsers,
                setCancel,
                createWorkspaceData.loggedinUser,
                setIsFormSubmitted,
                setIsViewerFormSubmitted
              );
            }
            setIsLoading(false);
            navigate(commonConstants?.routes?.dashboard);
          }
        })
        .catch(error => {
          setIsLoading(false);
          handleErrorToast(error?.message);
        });
    }
  } else {
    if (createWorkspaceData?.name === '') {
      setIsLoading(false);
      handleErrorToast(constants?.validationErrorMessages[constants?.droppableFormTitles?.workspaceName]);
    }
    // Empty workspaces are allowed now
    // createWorkspaceData.accounts?.length === 0
    //   ? handleErrorToast(constants?.validationErrorMessages[constants?.droppableFormTitles.channelSelected])
    //   : null;
  }
};

const processEditWorkspace = (usersUpdateInfo, createWorkspaceData, workspaceActualInfo) => {
  usersUpdateInfo.users.addedUsers = createWorkspaceData?.users;
  usersUpdateInfo.accounts.addedAccounts = createWorkspaceData?.accounts;
  workspaceActualInfo?.users?.forEach(userId => {
    if (!createWorkspaceData?.users?.includes(userId) && !usersUpdateInfo?.users?.deletedUsers?.includes(userId)) {
      usersUpdateInfo?.users?.deletedUsers?.push(userId);
    }
    usersUpdateInfo.users.addedUsers = usersUpdateInfo?.users?.addedUsers?.filter(itemid => itemid !== userId);
  });
  workspaceActualInfo?.accounts?.forEach(userId => {
    if (!createWorkspaceData?.accounts?.includes(userId) && !usersUpdateInfo?.accounts?.deletedAccounts?.includes(userId)) {
      usersUpdateInfo?.accounts?.deletedAccounts?.push(userId);
    }
    usersUpdateInfo.accounts.addedAccounts = usersUpdateInfo?.accounts?.addedAccounts?.filter(itemid => itemid !== userId);
  });
  return usersUpdateInfo;
};

const processDataId = data => {
  const dataId = [];
  data?.map(item => {
    item?.accounts?.map(account => {
      dataId?.push(account?.id);
    });
  });
  return dataId;
};

const processDroppedDataId = (data = []) => {
  const dataId = [];
  data?.map(item => {
    dataId?.push(item?.channelAccount?.id);
  });
  return dataId;
};

const processDroppedDataIdWithAddedUsers = (data = []) => {
  const dataId = [];
  data?.map(item => {
    const accountDetails = {};
    accountDetails.id = item?.channelAccount?.id;
    accountDetails.addedBy = item?.createdBy;
    dataId?.push(accountDetails);
  });
  return dataId;
};

const processUserAdminId = (data = []) => {
  const dataId = [];
  data?.map(item => {
    dataId?.push(item?.userId);
  });
  return dataId;
};

const processExistingViewersId = (data = []) => {
  const dataId = [];
  data?.map(item => {
    dataId?.push(item?.userId);
  });
  return dataId;
};

const processInvitedViewersIds = (data = []) => {
  const dataId = [];
  data?.map(item => {
    dataId?.push(item?.id);
  });
  return dataId;
};

const handleCardClose = (
  PageDetails,
  pageList,
  setPageList,
  droppedData,
  setDroppedData,
  tempId,
  workspaceRender,
  setWorkspaceRender,
  createWorkspaceData,
  setConfirmationModal
) => {
  // Trying to remove an account added by the same user
  if (tempId?.includes(PageDetails?.id) && PageDetails?.addedBy === createWorkspaceData?.loggedinUser) {
    setDroppedData(removeDataFromDroppedData(PageDetails?.id, droppedData, createWorkspaceData));
    setPageList(addRemovedDataToPagelist(PageDetails, pageList));
    setWorkspaceRender(!workspaceRender);
    PageDetails.addedBy = null;
  }
  // Trying to remove an account added by a different admin user, this case is only valid when we complete Add user functionality
  else {
    setConfirmationModal(true);
  }
};

const removeDataFromDroppedData = (id, droppedData, createWorkspaceData) => {
  droppedData?.forEach((element, index) => {
    if (element?.id === id) {
      droppedData?.splice(index, 1);
    }
  });
  createWorkspaceData?.accounts?.splice(createWorkspaceData?.accounts?.indexOf(id), 1);
  return droppedData;
};

const addRemovedDataToPagelist = (PageDetails, pageList) => {
  pageList?.forEach(element => {
    if (element?.channel === PageDetails?.channel) {
      element?.pagesDetails?.push(PageDetails);
    }
  });
  return pageList;
};

const processDroppedData = (data = []) => {
  let processedData = [];
  let processedAccount;
  data?.map(element => {
    const channelDetails = getChannelLogo(element?.channelAccount?.channelType);
    processedAccount = {};
    processedAccount.logo = channelDetails.imgUrl; //dummyLogo
    processedAccount.workspaceId = element?.workspaceId;
    processedAccount.id = element?.channelAccount?.id;
    processedAccount.accountId = element?.channelAccount?.accountId;
    processedAccount.title = element?.channelAccount?.name;
    processedAccount.channel = element?.channelAccount?.channelType;
    processedAccount.addedBy = element?.createdBy;
    processedData.push(processedAccount);
  });
  return processedData;
};

const processSelectedViewersData = (data = []) => {
  let processedData = [];
  let processedViewer;
  data.map(element => {
    processedViewer = {};
    processedViewer.firstName = element?.User?.firstName;
    processedViewer.lastName = element?.User?.lastName;
    processedViewer.email = element?.User?.email;
    processedViewer.id = element?.userId;
    processedData.push(processedViewer);
  });
  return processedData;
};

const processInvitedViewersData = (data = []) => {
  let processedData = [];
  let processedViewer;
  data.map(element => {
    processedViewer = {};
    processedViewer.acceptedOn = element?.acceptedOn;
    processedViewer.active = element?.active;
    processedViewer.email = element?.email;
    processedViewer.invitedBy = element?.invitedBy;
    processedViewer.tenantId = element?.tenantId;
    processedViewer.id = element?.id;
    processedData?.push(processedViewer);
  });
  return processedData;
};

const filterPageListWithDroppedData = (droppedDataId, pageListData) => {
  pageListData?.forEach(element => {
    droppedDataId?.forEach(item => {
      element.pagesDetails = element?.pagesDetails?.filter(obj => obj?.id !== item?.id);
    });
  });
  return pageListData;
};

const handleConfirmClick = (
  PageDetails,
  droppedData,
  createWorkspaceData,
  setDroppedData,
  workspaceRender,
  setWorkspaceRender,
  tempId,
  pageList,
  setPageList
) => {
  if (tempId.includes(PageDetails.id)) {
    setPageList(addRemovedDataToPagelist(PageDetails, pageList));
  }
  setDroppedData(removeDataFromDroppedData(PageDetails?.id, droppedData, createWorkspaceData));
  setWorkspaceRender(!workspaceRender);
};

const handleInvitationStatusDetails = invitationStatusDetails => {
  //Filter array of failed Email IDs
  return invitationStatusDetails
    ?.filter(invitaionStatus => {
      return invitaionStatus.isInvitationSent === false;
    })
    .map(invitaionStatus => {
      //Show reason for failed cases
      return {
        email: invitaionStatus?.email,
        message: invitaionStatus?.message,
      };
      // return invitaionStatus?.email;
    });
};

const handleDeleteWorkspace = (createWorkspaceData, handleSuccessToast, handleErrorToast, navigate) => {
  deleteWorkspaceService(createWorkspaceData?.name, createWorkspaceData?.loggedinUser)
    .then(response => {
      handleSuccessToast(response?.data?.data);
      navigate('/dashboard');
    })
    .catch(error => {
      handleErrorToast(error?.message);
    });
};

export {
  handleWorkspaceName,
  handleDragOver,
  handleDrop,
  handleSave,
  handleWebCancel,
  handleWebEditCancel,
  handleMobileCancel,
  handleMobileEditCancel,
  processDataId,
  handleCardClose,
  processDroppedData,
  processDroppedDataId,
  filterPageListWithDroppedData,
  processUserAdminId,
  processExistingViewersId,
  processInvitedViewersIds,
  processSelectedViewersData,
  processInvitedViewersData,
  handleConfirmClick,
  processDroppedDataIdWithAddedUsers,
  handleDeleteWorkspace,
};
