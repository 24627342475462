import { userCount } from '../../../services';

const listUserLicences = async tenantId => {
  try {
    return await userCount(tenantId);
  } catch (error) {
    throw new Error(error?.message);
  }
};

export { listUserLicences };
