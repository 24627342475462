/* eslint-disable no-unused-vars */
import React, { useRef, useState, useEffect } from 'react';
import Logo from '../../assets/images/logo.svg';
import Icons from '../../assets/Icons';
import InputText from '../../components/InputText';
import useFormHandler from '../../customHooks/useFormHandler';
import { addLicenseOwnerConstants, userRoles } from './constants';
import { AlertModal } from '../../components/Modal/AlertModal';
import { useToastBox } from '../../customHooks/useToastBox';
import messageClasses from '../Messaging/messaging.module.scss';
import PhoneInputField from '../../components/PhoneInputField';
import useAuth from '../../customHooks/useAuth';
import CryptoJS from 'crypto-js';
import { loginConstants } from '../../components/Modal/LoginModal/constants';
import {
  LicenseOwnerList,
  BlockUser,
  UnblockUser,
  AllUserList,
  DeleteUser,
  DeleteTenant,
} from '../../services/SuperAdmin/userActionService';
import { sendBroadcast } from '../../services/SuperAdmin/broadcastService';
import { Tab } from '@headlessui/react';
import { getUserInfo } from '../../utils/getUserInfo';
import Loader from '../../components/Loader';
import Table from '../../components/Table';
import NoData from '../../components/Nodata';
import { Disclosure } from '@headlessui/react';
import Pagination from '../../components/Pagination';
function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}
const AddLicense = () => {
  const { auth } = useAuth();
  const [licenseOwner, setLicenseOwner] = useState({});
  const [userData, setUserData] = useState([]);
  const [ownerData, setOwnerData] = useState([]);
  const [metaData, setMetaData] = useState();
  const [ownerMetaData, setOwnerMetaData] = useState();
  const [page, setPage] = useState(1);
  const [pageRange, setPageRange] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [ownerPage, setOwnerPage] = useState(1);
  const [ownerPageSize, setOwnerPageSize] = useState(10);
  const [addLicenseOwnerFormData, setAddLicenseOwnerFormData] = useState(addLicenseOwnerConstants.formDataInitialValue);
  const [apiError, setApiError] = useState(false);
  const licenseOwnerInputRef = useRef();
  const [loading, setIsLoading] = useState(false);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [selectedUser, setSelectedUser] = useState({ id: '', active: null });
  const isLicenseOwner = true;
  const emailIdProp = '';
  const [broadcastForm, setBroadcastForm] = useState({
    subject: '',
    message: '',
    notifyAdmin: false,
    notifyViewer: false,
    notifyLicenceOwner: false,
  });
  const currentTab = sessionStorage.getItem('ActiveTab');

  const [selectedIndex, setSelectedIndex] = useState(currentTab ? currentTab : 0);
  const [phoneNumber, setPhoneNumber] = useState('');
  const [deleteUser, setDeleteUser] = useState();
  const [openDeleteAlert, setOpenDeleteAlert] = useState(false);
  const [deleteTenant, setDeleteTenant] = useState({ id: '' });
  const [openDeleteTenantAlert, setOpenDeleteTenantAlert] = useState(false);
  const [ownerSearchKeyword, setOwnerSearchKeyword] = useState('');
  const [userSearchKeyword, setUserSearchKeyword] = useState('');

  const {
    formError,
    handleTenantNameChange,
    handleNameChange,
    handleEmailChange,
    handlePhoneChange,
    handleAddLicenseOwner,
    handleFormReset,
    handleCountryCodeChange,
    handleSubscriptionChange,
    resetForm,
    handleReferralCodeChange,
  } = useFormHandler(addLicenseOwnerFormData, setAddLicenseOwnerFormData, false, emailIdProp, isLicenseOwner);

  const { handleSuccessToast, handleErrorToast } = useToastBox();
  const { logout } = useAuth();

  const handleAddLicenseOwnerSubmitResponse = res => {
    //in uat there is no res.statusText node
    // if (res.statusText === 'OK') {
    resetForm();
    setPhoneNumber('');
    handleSuccessToast(addLicenseOwnerConstants.successMessage);
    // }
  };

  const handleAddLicenseOwnerSubmitFailure = err => {
    if (err.message !== '') {
      handleErrorToast(err.message);
    }
    setApiError(err.message);
  };

  const handleAddLicenseOwnerSubmit = event => {
    setApiError('');
    setLicenseOwner(addLicenseOwnerFormData);
    handleAddLicenseOwner(event)
      .then(res => handleAddLicenseOwnerSubmitResponse(res))
      .catch(err => handleAddLicenseOwnerSubmitFailure(err));
  };

  const tableColumns = [
    {
      Header: 'Name',
      accessor: item => `${item?.firstName} ${item?.lastName}`,
    },
    {
      Header: 'email',
      accessor: item => item?.email,
    },
    {
      Header: 'Type',
      accessor: item =>
        item.type === userRoles?.clientAdmin ? userRoles?.adminUser : item.type == userRoles?.user ? userRoles?.viewer : '-',
    },

    {
      Header: 'Tenant Name',
      accessor: item => (item?.Tenant?.name ? checkTenantName(item?.Tenant?.name) : '-'),
    },
    {
      Header: 'Actions',
      accessor: item => (
        <div className="flex items-center">
          <button
            className={`btn-link flex items-center gap-x-0.5 md:mt-4`}
            aria-label="Request to Remove"
            onClick={() => {
              setOpenDeleteAlert(true);
              setDeleteUser({ id: item?.id });
            }}
          >
            <Icons.TrashIcon />
          </button>
        </div>
      ),
    },
  ];

  const ownerTableColumns = [
    {
      Header: 'Name',
      accessor: item => `${item?.firstName} ${item?.lastName}`,
    },
    {
      Header: 'email',
      accessor: item => item?.email,
    },

    {
      Header: 'Tenant Name',
      accessor: item => (item?.Tenant?.name ? checkTenantName(item?.Tenant?.name) : '-'),
    },
    {
      Header: 'Referral Code',
      accessor: item => (item?.Tenant?.referralCode ? item?.Tenant?.referralCode : '-'),
    },
    {
      Header: 'Status',
      accessor: item =>
        item?.active ? (
          <button
            className={` min-w-[60px] cursor-default rounded-md border border-solid border-green-dark text-xs font-semibold text-green-dark `}
          >
            {'Active'}
          </button>
        ) : (
          <button
            className={` min-w-[60px] rounded-md border border-solid border-red-dark text-xs font-semibold text-red-dark  `}
          >
            {'Blocked'}
          </button>
        ),
    },
    {
      Header: 'Actions',
      accessor: item => (
        <div className="flex items-center space-x-3">
          <button
            className={` h-6 w-20 rounded-md border border-solid border-red-dark bg-red-100 text-red-dark  md:w-full md:!min-w-0 ${
              item?.active == false || item?.id == '' ? 'disable disabled:opacity-25' : null
            }`}
            disabled={item?.active == false || item?.id == ''}
            onClick={() => {
              setSelectedUser({ id: item?.tenantId, active: item?.active });
              setOpenAlertModal(true);
            }}
          >
            {'Block'}
          </button>

          <button
            className={`  h-6 w-20 rounded-md border border-solid  border-green-dark bg-green-100 text-green-dark md:w-full md:!min-w-0 ${
              item?.active || item?.id == '' ? 'disable disabled:opacity-25' : null
            }`}
            disabled={item?.active || item?.id == ''}
            onClick={() => {
              setSelectedUser({ id: item?.tenantId, active: item?.active });
              handleUnBlockUser();
            }}
          >
            {'Unblock'}
          </button>
          <button
            className={`btn-link flex items-center gap-x-0.5 md:mt-4`}
            aria-label="Request to Remove"
            onClick={() => {
              setOpenDeleteTenantAlert(true);
              setDeleteTenant({ id: item?.tenantId });
            }}
          >
            <Icons.TrashIcon />
          </button>
        </div>
      ),
    },
  ];

  const handleBlockUser = () => {
    setIsLoading(true);
    BlockUser(selectedUser?.id)
      .then(async response => {
        if (response?.status === 201 || response?.status === 200) {
          getLicenseOwnerData('block');
        }
      })
      .catch(error => {
        console.log('error', error);
        handleErrorToast('Unable to block user ');
        setIsLoading(false);
      });
  };

  const handleDeleteUser = () => {
    setIsLoading(true);
    DeleteUser(deleteUser?.id)
      .then(async response => {
        if (response?.status === 201 || response?.status === 200) {
          handleSuccessToast('User deleted successfully');
          getAllUsersList();
        }
      })
      .catch(error => {
        console.log('error', error);
        handleErrorToast('Unable to delete user ');
        setIsLoading(false);
      });
  };

  const handleDeleteTenant = () => {
    setIsLoading(true);
    DeleteTenant(deleteTenant?.id)
      .then(async response => {
        if (response?.status === 201 || response?.status === 200) {
          handleSuccessToast('License Owner deleted successfully');
          getLicenseOwnerData();
        }
      })
      .catch(error => {
        console.log('error', error);
        handleErrorToast(error?.message);
        setIsLoading(false);
      });
  };

  const handleUnBlockUser = () => {
    setIsLoading(true);
    UnblockUser(selectedUser?.id)
      .then(async response => {
        if (response?.status === 201 || response?.status === 200) {
          getLicenseOwnerData('unblock');
        }
      })
      .catch(error => {
        console.log('error', error);
        handleErrorToast('Unable to unblock user ');
        setIsLoading(false);
      });
  };

  const getLicenseOwnerData = type => {
    setIsLoading(true);
    LicenseOwnerList(ownerPage, ownerPageSize, ownerSearchKeyword, type)
      .then(async response => {
        if (response?.status === 201 || response?.status === 200) {
          setIsLoading(false);
          setOwnerMetaData(response?.data?.data);
          setOwnerData(response?.data?.data?.results?.rows);
          if (type == 'block') {
            handleSuccessToast('User blocked successfully');
          } else if (type == 'unblock') {
            handleSuccessToast('User unblocked successfully');
          }
        }
      })
      .catch(error => {
        handleErrorToast('Unable to fetch license owner details ');
        console.log('error', error);
        setIsLoading(false);
      });
  };

  const getAllUsersList = type => {
    setIsLoading(true);
    AllUserList(page, pageSize, userSearchKeyword, type)
      .then(async response => {
        if (response?.status === 201 || response?.status === 200) {
          setIsLoading(false);
          setMetaData(response?.data?.data);
          setUserData(response?.data?.data?.results?.rows);
        }
      })
      .catch(error => {
        handleErrorToast('Unable to fetch user details ');
        console.error('error', error);
        setIsLoading(false);
      });
  };

  const handleFormInput = (data, type) => {
    let temp = { ...broadcastForm };
    switch (type) {
      case 'subject':
        temp.subject = data;
        break;
      case 'adminUser':
        temp.notifyAdmin = data.target.checked;
        break;
      case 'viewer':
        temp.notifyViewer = data.target.checked;
        break;
      case 'licenseOwner':
        temp.notifyLicenceOwner = data.target.checked;
        break;
      case 'message':
        temp.message = data;
        break;
    }
    setBroadcastForm(temp);
  };

  const postMessage = () => {
    setIsLoading(true);
    sendBroadcast(broadcastForm)
      .then(response => {
        if (response?.status === 201 || response?.status === 200) {
          setBroadcastForm({
            subject: '',
            message: '',
            notifyAdmin: false,
            notifyViewer: false,
            notifyLicenceOwner: false,
          });
          handleSuccessToast(response?.data?.data);
          setIsLoading(false);
        }
      })
      .catch(err => {
        handleErrorToast(err.message);
        setIsLoading(false);
      });
  };

  const submitButtonCheck = () => {
    if (
      broadcastForm.subject !== '' &&
      broadcastForm.message !== '' &&
      (broadcastForm.notifyAdmin || broadcastForm.notifyLicenceOwner || broadcastForm.notifyViewer)
    ) {
      return true;
    } else {
      return false;
    }
  };
  const resetButtonCheck = () => {
    if (
      broadcastForm.subject == '' &&
      broadcastForm.message == '' &&
      !broadcastForm.notifyAdmin &&
      !broadcastForm.notifyLicenceOwner &&
      !broadcastForm.notifyViewer
    ) {
      return true;
    } else {
      return false;
    }
  };

  const checkTenantName = name => {
    if (name?.includes('GMT+0000')) {
      return '-';
    } else {
      return name;
    }
  };

  useEffect(() => {
    getLicenseOwnerData();
  }, [ownerPage, ownerPageSize]);
  useEffect(() => {
    getAllUsersList();
  }, [page, pageSize]);

  return loading ? (
    <Loader />
  ) : (
    <main className="min-h-screen">
      <header className="relative z-20 flex h-21.5 w-full items-center justify-between overflow-hidden bg-primary300 pb-6.5 pt-6 pl-15 md:h-18 md:pl-5">
        <img src={Logo} alt="Logo" className="relative z-10" />
        <div className="  absolute -bottom-34.5 -right-16.5 h-[180px] w-[180px] text-gray-medium1 md:-right-10 md:-bottom-34.5">
          <Icons.paymentShape1 />
        </div>
        <div className="  absolute  -top-32.5 -left-8.5 h-[180px] w-[180px] text-gray-medium1 md:-top-[133px] md:-left-17">
          <Icons.paymentShape1 />
        </div>

        <button
          className="z-10 mr-8 text-white"
          onClick={() => {
            logout();
          }}
        >
          Logout
        </button>
      </header>
      <div className="xl:px-10">
        <div
          className={`mx-auto my-5 !mt-20  rounded-xl border border-white200 ${
            selectedIndex == 2 || selectedIndex == 3 ? 'max-w-[70%] xl:w-full xl:max-w-[100%]' : 'max-w-[60%]'
          }`}
        >
          <Tab.Group
            onChange={event => {
              sessionStorage.setItem('ActiveTab', event);
              setSelectedUser({ id: '', active: null });
              setSelectedIndex(event);
              setPhoneNumber('');
              setOwnerSearchKeyword('');
              setUserSearchKeyword('');
              if (selectedIndex == 3) {
                getAllUsersList('clear');
              }
              if (selectedIndex == 2) {
                getLicenseOwnerData('clear');
              }
            }}
            selectedIndex={selectedIndex}
          >
            <Tab.List className="flex space-x-1 overflow-hidden rounded-t-xl border-b border-white200  bg-gray-light7 xs:flex-col">
              <Tab
                className={({ selected }) =>
                  classNames(
                    'w-full  border-r-0  border-l-0 py-4 text-base font-semibold leading-5 text-blue-700 shadow-none outline-none',
                    '',
                    selected ? '!border-b-2 border-primary' : '!text-gray-dark hover:text-primary'
                  )
                }
              >
                Add a License Owner
              </Tab>

              <Tab
                className={({ selected }) =>
                  classNames(
                    'w-full  border-r-0  border-l-0 py-4 text-base font-semibold leading-5 text-blue-700 shadow-none outline-none',
                    'text-gray-medium',
                    selected ? '!border-b-2 border-primary' : '!text-gray-dark hover:text-primary'
                  )
                }
              >
                Broadcast Form{' '}
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'w-full  border-r-0  border-l-0 py-4 text-base font-semibold leading-5 text-blue-700 shadow-none outline-none',
                    'text-gray-medium',
                    selected ? '!border-b-2 border-primary' : '!text-gray-dark hover:text-primary'
                  )
                }
              >
                Manage License Owner{' '}
              </Tab>
              <Tab
                className={({ selected }) =>
                  classNames(
                    'w-full  border-r-0  border-l-0 py-4 text-base font-semibold leading-5 text-blue-700 shadow-none outline-none',
                    'text-gray-medium',
                    selected ? '!border-b-2 border-primary' : '!text-gray-dark hover:text-primary'
                  )
                }
              >
                Delete User{' '}
              </Tab>
            </Tab.List>

            <Tab.Panels>
              <Tab.Panel className={classNames('rounded-xl bg-white p-5 md:p-0', '')}>
                <div className="md:w-full md:px-5">
                  <div className="">
                    <div className="user-details-grid grid grid-cols-2 gap-x-4 gap-y-6 md:grid-cols-1 md:p-5">
                      <InputText
                        className="flex flex-col"
                        id="firstName"
                        placeholder="First Name"
                        type="text"
                        label="First Name"
                        name="firstName"
                        onChange={handleNameChange}
                        error={formError.firstName}
                      />

                      <InputText
                        className="flex flex-col"
                        id="lastName"
                        placeholder="Last Name"
                        type="text"
                        label="Last Name"
                        name="lastName"
                        onChange={handleNameChange}
                        error={formError.lastName}
                      />

                      <InputText
                        className="flex flex-col"
                        id="tenantName"
                        placeholder="Tenant Name"
                        type="text"
                        label="Tenant Name"
                        name="tenantName"
                        onChange={handleTenantNameChange}
                        error={formError.tenantName}
                      />

                      <InputText
                        className="flex flex-col"
                        id="subscription"
                        placeholder="Subscription"
                        type="subscription"
                        label="Subscription"
                        name="subscription"
                        subscriptions={addLicenseOwnerConstants.subscriptions}
                        // onChange={handleNameChange}
                        error={formError.subscription}
                        selectOnChange={handleSubscriptionChange}
                        disabled={true}
                      />

                      <PhoneInputField
                        className="flex flex-col"
                        id="phone"
                        placeholder="Phone Number"
                        type="tel"
                        label="Phone Number"
                        hintClassName="font-normal text-[0.625rem] text-gray-400 ml-2"
                        name="phone"
                        countryCodes={addLicenseOwnerConstants.countryCodes}
                        onPhoneChange={handlePhoneChange}
                        error={formError.phone}
                        onCountryChange={handleCountryCodeChange}
                        autoComplete="new-password"
                        selectInputRef={licenseOwnerInputRef}
                        onPhoneInput={value => setPhoneNumber(value)}
                        phoneNumber={phoneNumber}
                      />

                      <InputText
                        className="flex flex-col"
                        id="email"
                        placeholder="Email Address"
                        type="email"
                        label="Email Address"
                        name="email"
                        onChange={handleEmailChange}
                        error={formError.email}
                        emailId={emailIdProp}
                      />
                      <InputText
                        className="flex flex-col"
                        id="referralCode"
                        placeholder="Referral Code"
                        type="text"
                        label="Referral Code"
                        hint={true}
                        hintClassName="font-normal text-[0.625rem] text-gray-500 ml-2"
                        hintText="Optional"
                        name="referralCode"
                        onChange={handleReferralCodeChange}
                        error={formError.referralCode}
                        isOptional={true}
                        maxLength={12}
                        value={addLicenseOwnerFormData.referralCode}
                      />
                    </div>
                    <div className=" footer-buttons flex w-full items-center justify-end space-x-3 pt-8 md:bottom-0 md:border-b-0 md:border-t md:border-solid md:border-white200 md:bg-white md:px-4 md:pt-6 md:pb-8 md:shadow-stickyBottomCard">
                      <button
                        className="w-full border-2 border-solid border-primary text-primary"
                        onClick={event => {
                          setApiError('');
                          handleFormReset(event, licenseOwnerInputRef);
                          setPhoneNumber('');
                        }}
                      >
                        {addLicenseOwnerConstants.textConstants.reset}
                      </button>
                      <button className=" w-full bg-primary text-white " onClick={handleAddLicenseOwnerSubmit}>
                        {addLicenseOwnerConstants.textConstants.addLicenseOwner}
                      </button>
                    </div>
                  </div>
                </div>
              </Tab.Panel>

              <Tab.Panel className={classNames('rounded-xl bg-white p-5 md:p-0', '')}>
                <div className="md:w-full md:px-5">
                  <div className="md:p-5">
                    <div className=" user-details-grid">
                      <div className={messageClasses.modalContent}>
                        <div className={messageClasses.formController}>
                          <div className={`${messageClasses.formControl}`}>
                            <InputText
                              className="flex flex-col"
                              placeholder="Enter subject here (Max limit 100 characters)"
                              type="text"
                              label="Subject"
                              onChange={event => handleFormInput(event.target.value, 'subject')}
                              value={broadcastForm.subject}
                              maxLength={100}
                            />
                          </div>
                          <div className={`${messageClasses.formControl}`}>
                            <label>
                              Send to{' '}
                              <span className="absolute top-1">
                                <Icons.AsteriskIcon />
                              </span>
                            </label>

                            <div className={messageClasses.broadcastWrapper}>
                              <div className="flex w-full space-x-5 xs:flex-col xs:space-x-0">
                                <label className="form-checkbox -regular-mobile">
                                  <input
                                    className="form-checkbox-input"
                                    type="checkbox"
                                    name=""
                                    onChange={event => handleFormInput(event, 'adminUser')}
                                    checked={broadcastForm.notifyAdmin}
                                  />
                                  <div className="form-check-circle">
                                    <Icons.TickSmall />
                                  </div>
                                  <span className="label-text">Admin Users</span>
                                </label>

                                {/* <label className="form-checkbox -regular-mobile">
                                <input
                                  className="form-checkbox-input"
                                  type="checkbox"
                                  name=""
                                  onChange={event => handleFormInput(event, 'viewer')}
                                  checked={broadcastForm.notifyViewer}
                                />
                                <div className="form-check-circle">
                                  <Icons.TickSmall />
                                </div>
                                <span className="label-text">Viewers</span>
                              </label> */}
                                <label className="form-checkbox -regular-mobile">
                                  <input
                                    className="form-checkbox-input"
                                    type="checkbox"
                                    name=""
                                    onChange={event => handleFormInput(event, 'licenseOwner')}
                                    checked={broadcastForm.notifyLicenceOwner}
                                  />
                                  <div className="form-check-circle">
                                    <Icons.TickSmall />
                                  </div>
                                  <span className="label-text">License Owners</span>
                                </label>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <label className="!mt-2 block">
                          Message{' '}
                          <span className="absolute top-1">
                            <Icons.AsteriskIcon />
                          </span>
                        </label>
                        <textarea
                          type="text"
                          className=" h-25 w-full rounded-md border border-white200 p-3 shadow-none ring-0 placeholder:text-sm focus:border-primary"
                          placeholder="Type your message here..."
                          onChange={event => handleFormInput(event.target.value, 'message')}
                          value={broadcastForm.message}
                        />
                      </div>
                    </div>
                  </div>
                  <div className=" footer-buttons flex w-full items-center justify-end space-x-3 pt-8 md:bottom-0 md:border-b-0 md:border-t md:border-solid md:border-white200 md:bg-white md:px-4 md:pt-6 md:pb-8 md:shadow-stickyBottomCard">
                    <button
                      className={`w-full border-2 border-solid border-primary text-primary ${
                        resetButtonCheck() ? 'disable disabled:opacity-25' : null
                      }`}
                      onClick={() => {
                        setBroadcastForm({
                          subject: '',
                          message: '',
                          notifyAdmin: false,
                          notifyViewer: false,
                          notifyLicenceOwner: false,
                        });
                      }}
                      disabled={resetButtonCheck()}
                    >
                      {'Reset'}
                    </button>
                    <button
                      className={` w-full bg-primary text-white ${
                        !submitButtonCheck() ? 'disable disabled:opacity-25' : null
                      }`}
                      onClick={() => postMessage()}
                      disabled={!submitButtonCheck()}
                    >
                      {'Send'}
                    </button>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel className={classNames('rounded-xl bg-white p-5 md:p-0', '')}>
                <div className="md:w-full md:px-5">
                  <div className="flexCenter tableFilter mb-5 justify-between border-b border-white200 bg-white p-4 md:flex-col md:items-start md:justify-start">
                    <div className="flexCenter sm:pb-4">
                      <span className=" mb-1  text-base font-medium leading-6 text-gray-dark">License Owners</span>
                      <span className=" mx-2 h-1 w-1 rounded-full bg-gray-light5"></span>
                      <span className=" text-xs leading-[18px] text-gray-medium ">
                        {ownerMetaData?.totalResults === 1
                          ? `${ownerMetaData?.totalResults} User`
                          : `${ownerMetaData?.totalResults} Users`}{' '}
                      </span>
                    </div>
                    <div className="relative ml-auto mt-auto flex w-[500px] items-center md:my-0 md:mb-2 md:w-full">
                      <input
                        placeholder="Type name or referral code and press enter to search"
                        type="text"
                        className=" h-10 w-full rounded border border-gray-medium/30 pl-2 pr-18 text-sm !outline-none placeholder:text-xs"
                        onChange={event => {
                          setOwnerSearchKeyword(event.target.value);
                          if (event.target.value?.length == 0) {
                            getLicenseOwnerData('clear');
                          }
                        }}
                        value={ownerSearchKeyword}
                        onKeyUp={e => {
                          if (e.key === 'Enter' && e?.target?.value?.length >= 3) {
                            setOwnerPage(1);
                            getLicenseOwnerData();
                          } else if (e.key === 'Enter' && e?.target?.value?.length < 3) {
                            handleErrorToast('Please enter at least 3 characters');
                          }
                        }}
                      />

                      <button
                        className="absolute right-14 top-[10px] flex items-center justify-center text-gray-dark1"
                        onClick={() => {
                          setOwnerSearchKeyword('');
                          getLicenseOwnerData('clear');
                        }}
                      >
                        <div className=" h-4 w-4">
                          <Icons.CloseIcon />
                        </div>
                      </button>

                      <button
                        className=" absolute right-0 flex h-10 w-10 items-center justify-center rounded-r-md bg-primary text-white shadow-2xl"
                        onClick={() => {
                          if (ownerSearchKeyword?.trim().length >= 3) {
                            setOwnerPage(1);
                            getLicenseOwnerData();
                          } else {
                            handleErrorToast('Please enter at least 3 characters');
                          }
                        }}
                      >
                        {' '}
                        <Icons.Search />
                      </button>
                    </div>
                  </div>
                  <div className="xl:!hidden">
                    {ownerData?.length > 0 ? (
                      <Table
                        data={ownerData}
                        metaData={ownerMetaData}
                        columns={ownerTableColumns}
                        pagination={true}
                        page={ownerPage}
                        pageRange={pageRange}
                        setPageRange={value => setPageRange(value)}
                        pageSize={ownerPageSize}
                        paging={false}
                        setPageSize={value => setOwnerPageSize(value)}
                        setPage={value => setOwnerPage(value)}
                        showPagination={ownerMetaData?.totalResults <= 10 ? false : true}
                      />
                    ) : (
                      <div className="py-10">
                        <NoData message={'No results found'} showImage={true} />
                      </div>
                    )}
                  </div>
                  <div className="inset-0 mt-3 !hidden overflow-auto rounded-lg border border-white200 bg-white p-4 pt-6 md:border-none md:bg-transparent md:p-0 xl:!block">
                    <div className=" overflow-hidden rounded-lg border-none border-white200 pb-20 xl:h-full xl:overflow-auto xl:rounded-none xl:border-none">
                      <ul className="userListWrapper hidden xl:block xl:h-full xl:space-y-2">
                        {ownerData && ownerData?.length == 0 && <NoData message={'No users found'} showImage={true} />}
                        {ownerData &&
                          ownerData?.length > 0 &&
                          ownerData?.map((value, index) => (
                            <li
                              className="grid grid-cols-5 border-0 border-white200 bg-white py-4 hover:bg-primaryLight xl:rounded-lg xl:border xl:py-3 xl:px-4 xl:pr-9"
                              key={index}
                            >
                              <div className="col-span-5 !hidden w-full flex-col xl:!flex">
                                <Disclosure>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button className=" relative flex w-full flex-col justify-between rounded-lg !bg-transparent p-0 text-left  text-sm">
                                        <div className="w-full justify-between">
                                          <span className="truncate pr-1  text-xs font-medium text-gray-dark">
                                            {`${value?.firstName} ${value?.lastName}`}
                                          </span>
                                          <div>
                                            <span className="text-xs font-medium text-gray-dark">{value?.email}</span>
                                          </div>
                                        </div>
                                        <div
                                          className={`${
                                            open ? '!hidden' : ''
                                          } mt-[0.125rem] w-full text-[0.625rem] font-medium leading-4 text-gray-medium`}
                                        ></div>

                                        <div className={`${open ? 'rotate-180 transform' : ''} absolute top-1.5 -right-5.5`}>
                                          <Icons.DownArrow />
                                        </div>
                                      </Disclosure.Button>
                                      <Disclosure.Panel className="!flex w-full pt-2">
                                        <div className="!grid w-full !grid-cols-2 pt-2 text-sm text-gray-500 xs:!grid-cols-1 xs:gap-y-2">
                                          <div className=" addedBy flex-col !items-start !justify-start text-xs font-medium leading-4  text-gray-dark">
                                            <label className=" mb-[0.125rem] text-[0.625rem] leading-4 text-gray-dark1">
                                              {' '}
                                              Tenant Name
                                            </label>
                                            <span>{value?.Tenant?.name ? checkTenantName(value?.Tenant?.name) : '-'}</span>
                                          </div>
                                          <div className=" addedBy flex-col !items-start !justify-start text-xs font-medium leading-4  text-gray-dark">
                                            <label className=" mb-[0.125rem] text-[0.625rem] leading-4 text-gray-dark1">
                                              {' '}
                                              Referral Code
                                            </label>
                                            <span>{value?.Tenant?.referralCode ? value?.Tenant?.referralCode : '-'}</span>
                                          </div>
                                          <div className="flex">
                                            <div className=" addedBy w-full flex-col !items-start !justify-start text-xs font-medium leading-4  text-gray-dark">
                                              <label className=" mb-[0.125rem] text-[0.625rem] leading-4 text-gray-dark1">
                                                {' '}
                                                Status
                                              </label>
                                              {value?.active ? (
                                                <button
                                                  className={` min-w-[60px] rounded-md border border-solid border-green-dark text-xs font-semibold text-green-dark `}
                                                >
                                                  {'Active'}
                                                </button>
                                              ) : (
                                                <button
                                                  className={` min-w-[60px] rounded-md border border-solid border-red-dark text-xs font-semibold text-red-dark  `}
                                                >
                                                  {'Blocked'}
                                                </button>
                                              )}
                                            </div>
                                          </div>
                                        </div>

                                        <div className="-mr-6 flex space-x-3">
                                          <div className="flex items-center space-x-3">
                                            <button
                                              className={` h-6 w-20 rounded-md border border-solid border-red-dark bg-red-100 text-red-dark  md:w-full md:!min-w-0 ${
                                                value?.active == false || value?.id == ''
                                                  ? 'disable disabled:opacity-25'
                                                  : null
                                              }`}
                                              disabled={value?.active == false || value?.id == ''}
                                              onClick={() => {
                                                setSelectedUser({ id: value?.tenantId, active: value?.active });
                                                setOpenAlertModal(true);
                                              }}
                                            >
                                              {'Block'}
                                            </button>

                                            <button
                                              className={`  h-6 w-20 rounded-md border border-solid  border-green-dark bg-green-100 text-green-dark md:w-full md:!min-w-0 ${
                                                value?.active || value?.id == '' ? 'disable disabled:opacity-25' : null
                                              }`}
                                              disabled={value?.active || value?.id == ''}
                                              onClick={() => {
                                                setSelectedUser({ id: value?.tenantId, active: value?.active });
                                                handleUnBlockUser();
                                              }}
                                            >
                                              {'Unblock'}
                                            </button>
                                            <button
                                              className={`btn-link flex items-center gap-x-0.5 md:mt-4`}
                                              aria-label="Request to Remove"
                                              onClick={() => {
                                                setOpenDeleteTenantAlert(true);
                                                setDeleteTenant({ id: value?.tenantId });
                                              }}
                                            >
                                              <Icons.TrashIcon />
                                            </button>
                                          </div>
                                        </div>
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              </div>
                            </li>
                          ))}
                      </ul>
                      {metaData?.totalResults > 10 && (
                        <Pagination
                          metaData={ownerMetaData}
                          page={ownerPage}
                          pageRange={pageRange}
                          setPageRange={value => setPageRange(value)}
                          pageSize={ownerPageSize}
                          setPageSize={value => setOwnerPageSize(value)}
                          setPage={value => setOwnerPage(value)}
                        />
                      )}
                    </div>
                  </div>
                </div>
              </Tab.Panel>
              <Tab.Panel className={classNames('rounded-xl bg-white p-5 md:p-0', '')}>
                <div className="md:w-full md:px-5">
                  <div className="flexCenter tableFilter mb-5 justify-between border-b border-white200 bg-white p-4 md:flex-col md:items-start md:justify-start">
                    <div className="flexCenter sm:pb-4">
                      <span className=" mb-1  text-base font-medium leading-6 text-gray-dark">Users</span>
                      <span className=" mx-2 h-1 w-1 rounded-full bg-gray-light5"></span>
                      <span className=" text-xs leading-[18px] text-gray-medium ">
                        {metaData?.totalResults === 1 ? `${metaData?.totalResults} User` : `${metaData?.totalResults} Users`}{' '}
                      </span>
                    </div>
                    <div className="relative ml-auto mt-auto flex w-[500px] items-center md:my-0 md:mb-2 md:w-full">
                      <input
                        placeholder="Type name and press enter to search"
                        type="text"
                        className=" h-10 w-full rounded border border-gray-medium/30 pl-2 pr-18 text-sm !outline-none placeholder:text-xs"
                        onChange={event => {
                          setUserSearchKeyword(event.target.value);
                          if (event.target.value?.length == 0) {
                            getAllUsersList('clear');
                          }
                        }}
                        onKeyUp={e => {
                          if (e.key === 'Enter' && e?.target?.value?.length >= 3) {
                            getAllUsersList();
                            setPage(1);
                          } else if (e.key === 'Enter' && e?.target?.value?.length < 3) {
                            handleErrorToast('Please enter at least 3 characters');
                          }
                        }}
                        value={userSearchKeyword}
                      />

                      <button
                        className="absolute right-14 top-[10px] flex items-center justify-center text-gray-dark1"
                        onClick={() => {
                          setUserSearchKeyword('');
                          getAllUsersList('clear');
                        }}
                      >
                        <div className=" h-4 w-4">
                          <Icons.CloseIcon />
                        </div>
                      </button>

                      <button
                        className=" absolute right-0 flex h-10 w-10 items-center justify-center rounded-r-md bg-primary text-white shadow-2xl"
                        onClick={() => {
                          if (userSearchKeyword?.trim().length >= 3) {
                            getAllUsersList();
                            setPage(1);
                          } else {
                            handleErrorToast('Please enter at least 3 characters');
                          }
                        }}
                      >
                        <Icons.Search />
                      </button>
                    </div>
                  </div>
                  <div className=" xl:!hidden">
                    {userData?.length > 0 ? (
                      <Table
                        data={userData}
                        metaData={metaData}
                        columns={tableColumns}
                        pagination={true}
                        page={page}
                        pageSize={pageSize}
                        pageRange={pageRange}
                        setPageRange={value => setPageRange(value)}
                        paging={false}
                        setPageSize={value => setPageSize(value)}
                        setPage={value => setPage(value)}
                        showPagination={metaData?.totalResults <= 10 ? false : true}
                      />
                    ) : (
                      <div className="py-10">
                        <NoData message={'No results found'} showImage={true} />
                      </div>
                    )}
                  </div>
                  <div className="inset-0 mt-3 !hidden overflow-auto rounded-lg border border-white200 bg-white p-4 pt-6 md:border-none md:bg-transparent md:p-0 xl:!block">
                    <div className=" overflow-hidden rounded-lg border-none border-white200 pb-20 xl:h-full xl:overflow-auto xl:rounded-none xl:border-none">
                      <ul className="userListWrapper hidden xl:block xl:h-full xl:space-y-2">
                        {userData && userData?.length == 0 && <NoData message={'No users found'} showImage={true} />}
                        {userData &&
                          userData?.length > 0 &&
                          userData?.map((value, index) => (
                            <li
                              className="grid grid-cols-5 border-0 border-white200 bg-white py-4 hover:bg-primaryLight xl:rounded-lg xl:border xl:py-3 xl:px-4 xl:pr-9"
                              key={index}
                            >
                              <div className="col-span-5 !hidden w-full flex-col xl:!flex">
                                <Disclosure>
                                  {({ open }) => (
                                    <>
                                      <Disclosure.Button className=" relative flex w-full flex-col justify-between rounded-lg !bg-transparent p-0 text-left  text-sm">
                                        <div className="w-full justify-between">
                                          <span className="truncate pr-1  text-xs font-medium text-gray-dark">
                                            {`${value?.firstName} ${value?.lastName}`}
                                          </span>
                                          <div>
                                            <span className="text-xs font-medium text-gray-dark">{value?.email}</span>
                                          </div>
                                        </div>
                                        <div
                                          className={`${
                                            open ? '!hidden' : ''
                                          } mt-[0.125rem] w-full text-[0.625rem] font-medium leading-4 text-gray-medium`}
                                        ></div>

                                        <div className={`${open ? 'rotate-180 transform' : ''} absolute top-1.5 -right-5.5`}>
                                          <Icons.DownArrow />
                                        </div>
                                      </Disclosure.Button>
                                      <Disclosure.Panel className="!flex w-full pt-2">
                                        <div className="!grid w-full !grid-cols-2 pt-2 text-sm text-gray-500 xs:!grid-cols-1 xs:gap-y-2">
                                          <div className=" addedBy flex-col !items-start !justify-start text-xs font-medium leading-4  text-gray-dark">
                                            <label className=" mb-[0.125rem] text-[0.625rem] leading-4 text-gray-dark1">
                                              {' '}
                                              Tenant Name
                                            </label>
                                            <span>{value?.Tenant?.name ? checkTenantName(value?.Tenant?.name) : '-'}</span>
                                          </div>
                                          <div className="flex">
                                            <div className=" addedBy w-full flex-col !items-start !justify-start text-xs font-medium leading-4  text-gray-dark">
                                              <label className=" mb-[0.125rem] text-[0.625rem] leading-4 text-gray-dark1">
                                                {' '}
                                                Type
                                              </label>
                                              <span>
                                                {value?.type === userRoles?.clientAdmin
                                                  ? userRoles?.adminUser
                                                  : value?.type == userRoles?.user
                                                  ? userRoles?.viewer
                                                  : '-'}
                                              </span>
                                            </div>
                                          </div>
                                        </div>

                                        <div className="-mr-6 flex space-x-3">
                                          <button
                                            className={`btn-link flex items-center gap-x-0.5 md:mt-4`}
                                            aria-label="Request to Remove"
                                            onClick={() => {
                                              setOpenDeleteAlert(true);
                                              setDeleteUser({ id: value?.id });
                                            }}
                                          >
                                            <Icons.TrashIcon />
                                          </button>
                                        </div>
                                      </Disclosure.Panel>
                                    </>
                                  )}
                                </Disclosure>
                              </div>
                            </li>
                          ))}
                        {metaData?.totalResults > 10 && (
                          <Pagination
                            metaData={metaData}
                            page={page}
                            setPageSize={setPageSize}
                            setPage={setPage}
                            pageSize={pageSize}
                            pageRange={pageRange}
                            setPageRange={value => setPageRange(value)}
                          />
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </Tab.Panel>
            </Tab.Panels>
          </Tab.Group>
          <AlertModal
            isOpen={openAlertModal}
            setIsOpen={setOpenAlertModal}
            heading={'Are you Sure?'}
            message={'This will block the user from Hydra. To pause subscription please go to Stripe.'}
            handleConfirm={() => handleBlockUser()}
          />
          <AlertModal
            isOpen={openDeleteAlert}
            setIsOpen={setOpenDeleteAlert}
            heading={'Are you Sure?'}
            message={
              'Deleting this user will permanently remove them from Hydra and all of their workspaces will be transferred to the corresponding license owner. Are you sure you want to continue?'
            }
            handleConfirm={() => handleDeleteUser()}
          />
          <AlertModal
            isOpen={openDeleteTenantAlert}
            setIsOpen={setOpenDeleteTenantAlert}
            heading={'Are you Sure?'}
            message={
              'Deleting this license owner will permanently remove them from Hydra, along with all their associated data, including workspaces. Any client admins and viewers added by this license owner will also be removed. Are you sure you want to continue?'
            }
            handleConfirm={() => handleDeleteTenant()}
          />
        </div>
      </div>
    </main>
  );
};

export default AddLicense;
