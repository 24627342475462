import CryptoJS from 'crypto-js';
import { constants } from './constants';
import { loginConstants } from '../../components/Modal/LoginModal/constants';
import { generateReportService } from '../../services';

const formatChannelSelectList = (channelList, isAllNeeded) => {
  let channelSelectData = [];
  const obj = { key: 0, value: '', label: '' };
  if (isAllNeeded) {
    obj.key = channelSelectData.length + 1;
    obj.value = '';
    obj.label = 'All';
    channelSelectData.push(obj);
  }

  channelList?.forEach((item, index) => {
    const decryptedState =
      typeof item.isDisabled !== 'boolean'
        ? JSON.parse(CryptoJS.AES.decrypt(item?.isDisabled, loginConstants.channelStoreKey).toString(CryptoJS.enc.Utf8))
        : item?.isDisabled;

    if (!decryptedState && item?.showAuthenticate && (item?.allowCreate || item?.allowDelete || item?.allowEdit)) {
      const selectOption = {};
      selectOption['key'] = `channelIndex_${item.channel}_${index}`;
      selectOption['value'] = item.channel;
      selectOption['label'] = item.displayName;
      channelSelectData.push(selectOption);
    }
  });
  return channelSelectData;
};

const formatPageSelectList = accountList => {
  let channelAccountList = [];

  if (accountList?.results?.length > 1) {
    const obj = {};
    obj.key = channelAccountList.length + 1;
    obj.value = 'All';
    obj.label = 'All';
    channelAccountList.push(obj);
  }

  if (accountList?.results?.length > 0) {
    accountList?.results?.forEach((item, index) => {
      const selectOption = {};
      selectOption['key'] = `pageIndex_${item?.accountId}_${index}`;
      selectOption['value'] = item?.accountId;
      selectOption['label'] = item?.accountName;
      channelAccountList.push(selectOption);
    });
  }
  return channelAccountList;
};

const handleDownload = (reportContent, pageOptions, handleSuccessToast, handleErrorToast, setIsLoading) => {
  const errorFlag = checkIsEmpty(reportContent, pageOptions, handleErrorToast);
  if (!errorFlag) {
    setIsLoading(true);
    generateReportService(reportContent).then(response => {
      if (response?.data?.data?.url?.message) {
        setIsLoading(false);
        handleErrorToast(response?.data?.data?.url?.message);
      } else {
        setIsLoading(false);
        downloadReport(response.data.data?.url, handleSuccessToast);
      }
    });
  }
};

const checkIsEmpty = (reportContent, pageOptions, handleErrorToast) => {
  let flag = false;

  if (Object.keys(reportContent?.channel).length === 0) {
    flag = true;
    handleErrorToast(constants?.validationMessages?.isEmptyMessages?.channel);
  } else if (Object.keys(reportContent?.page).length === 0 && pageOptions.length > 0) {
    flag = true;
    handleErrorToast(constants?.validationMessages?.isEmptyMessages?.page);
  } else if (reportContent?.from === undefined || reportContent?.to === undefined) {
    flag = true;
    handleErrorToast(constants?.validationMessages?.isEmptyMessages?.date);
  } else if (reportContent?.page === constants?.isEmptyString && pageOptions.length === 0) {
    flag = true;
    handleErrorToast(constants?.validationMessages?.isEmptyMessages?.noAccounts);
  }
  return flag;
};

const downloadReport = (data, handleSuccessToast) => {
  const link = document.createElement('a');
  link.href = data;
  link.setAttribute('download', `${data}`);

  // Append to html link element page
  document.body.appendChild(link);

  // Start download
  link.click();

  // Clean up and remove the link
  link.parentNode.removeChild(link);
  handleSuccessToast(constants?.validationMessages?.downloadReportMessage);
};

const processUserList = async data => {
  const userList = [];
  if (data?.length > 1) {
    const obj = { value: 'All', label: 'All', key: 0 };
    userList.push(obj);
  }
  data?.forEach(async (element, index) => {
    const user = { value: '', label: '', key: index + 1 };
    user.value = element?.id;
    user.label = `${element?.email}`;
    user.isAdmin = element?.type === 'clientAdmin';
    userList.push(user);
  });

  return userList;
};

const handleDistributionSave = (reportContent, pageOptions, handleErrorToast) => {
  let flag = true;
  if (Object.keys(reportContent?.channel)?.length === 0) {
    handleErrorToast(constants?.validationMessages?.isEmptyMessages?.channel);
    flag = false;
  } else if (reportContent?.page?.length === 0 && pageOptions.length > 0) {
    flag = false;
    handleErrorToast(constants?.validationMessages?.isEmptyMessages?.page);
  } else if (reportContent?.groupName === undefined || reportContent?.groupName === '') {
    flag = false;
    handleErrorToast(constants?.validationMessages?.isEmptyMessages?.groupName);
  } else if (reportContent?.userList.length === 0) {
    flag = false;
    handleErrorToast(constants?.validationMessages?.isEmptyMessages?.userList);
  } else if (Object.keys(reportContent?.regularity)?.length === 0) {
    flag = false;
    handleErrorToast(constants?.validationMessages?.isEmptyMessages?.regularity);
  }
  return flag;
};

const handlePageSelection = (eventValue, reportContent, setReportContent, pageOptions, refresh, setRefresh) => {
  if (eventValue?.value === 'All') {
    reportContent.page = [];
    pageOptions?.forEach(page => {
      // if (page.value !== 'All') {
      //   reportContent.page.push(page.value);
      // }
      reportContent.page.push(page);
      setRefresh(!refresh);
    });
  } else {
    setReportContent(values => ({ ...values, page: [eventValue] }));
  }
};

const regularityFinder = regularity => {
  switch (regularity) {
    case 'daily':
      return { value: 'daily', label: 'Daily' };
    case 'weekly':
      return { value: 'weekly', label: 'Weekly' };
    case 'monthly':
      return { value: 'monthly', label: 'Monthly' };
    case 'quarterly':
      return { value: 'quarterly', label: 'Quarterly' };
    default:
      return { value: 'daily', label: 'Daily' };
  }
};

const channelFinder = (channelList, channel) => {
  let selectedChannel;
  selectedChannel = channelList?.length > 0 ? channelList?.filter(data => data.value?.includes(channel)) : selectedChannel;

  return selectedChannel[0];
};

const pageFinder = (pageList, page) => {
  if (page?.length > 1) {
    let pages = [];
    pageList?.forEach(page => {
      pages.push(page);
    });
    return pages;
  } else {
    let selectedPage = [];
    selectedPage = pageList?.length > 0 ? pageList?.filter(data => data.value?.includes(page)) : selectedPage;
    return selectedPage;
  }
};

const userFinder = (userList, users) => {
  if (userList?.length - 1 == users?.length) {
    return [constants.allOption];
  } else {
    return users;
  }
};

export {
  formatChannelSelectList,
  formatPageSelectList,
  handleDownload,
  downloadReport,
  processUserList,
  handleDistributionSave,
  handlePageSelection,
  regularityFinder,
  channelFinder,
  pageFinder,
  userFinder,
};
