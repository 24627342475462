export const profileConstants = {
  profileHeading: 'My Profile',
  editProfile: 'Edit Profile',
  billingInfo: 'Billing Information',
  firstName: 'First Name',
  lastName: 'Last Name',
  phoneNo: 'Phone Number',
  email: 'Email Address',
  save: 'Save Changes',
  nil: 'Nil',
  validationErrorMessages: {
    firstName: {
      required: 'First name cannot be empty',
      onlyAlphabets: 'Sorry, name must only include alphabets',
    },
    lastName: {
      required: 'Last name cannot be empty',
      onlyAlphabets: 'Sorry, name must only include alphabets',
    },
    phone: {
      required: 'Phone number cannot be empty',
      validateError: 'Please enter a valid phone number',
      maxLimit: 'Phone number cannot exceed 15 digits',
      minLimit: 'Phone number should be at least 10 digits',
    },
  },
  noChange: 'No Change',
  sucessMessage: 'Sucessfully updated the info',
  alternateEmail: 'Alternate Email Address',
  alternateEmailInfo: ' Authentication is required once you edit the Alternate Email Address',
  emailEditInfo: 'Authentication is required once you edit the Email Address',
  mobileEditInfo: 'Authentication is required once you edit the Phone Number',
  profileModal: {
    button: 'Change Profile / Add New Profile',
    titleSingular: 'Profile',
    titlePlural: 'Profiles',
  },
  verifyPhoneModal: {
    shortTitle: 'Verify',
    title: 'Verify Your Phone Number',
    otpModal: {
      details: 'Please enter the authentication code sent to your registered phone number',
      resendCode: 'Resend Code',
    },
    sendOtpFail: 'Otp cannot be sent to the registered Phone Number. Make sure it is a valid Phone Number',
    otpVerificationFail: 'Otp verification failed.',
    otpVerificationSuccess: 'Phone Number verified successfully',
  },
};
