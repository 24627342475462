/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Icons from '../../assets/Icons';
import { useNavigate } from 'react-router-dom';
// import NoData from '../../components/Nodata';

const JoinTeam = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="app-l-terms-holder">
        <section className="relative overflow-hidden bg-gradient-to-r from-primaryMedium to-primaryDark py-4 pt-6 ">
          <div className="relative z-10 mx-auto max-w-7xl px-5">
            <button
              className=" flex items-center text-sm font-medium text-white hover:opacity-50"
              onClick={() => navigate('/')}
            >
              <Icons.leftArrow />
              <span className=" ml-2.5">Back</span>
            </button>
            <h1 className=" m-0 p-0 text-2.1xl font-semibold leading-[2.75rem] text-white md:mt-1.5 md:text-base">
              Join our team
            </h1>
          </div>
          <div className=" rotating absolute -bottom-27 -right-8.5 h-[180px] w-[180px] text-gray-medium1 md:-right-24 md:-bottom-28">
            <Icons.paymentShape1 />
          </div>
          <div className=" rotating absolute -top-27 -left-8.5 h-[180px] w-[180px] text-gray-medium1 md:-top-[133px] md:-left-17">
            <Icons.paymentShape1 />
          </div>
        </section>
        <section className="mx-auto max-w-7xl px-5 pt-5 pb-10">
          {/* <div className="mb-4 w-full">
            <h2 className="mb-2 text-xl font-semibold leading-6 text-gray-dark"> Disclaimer</h2>
          </div> */}
        </section>
        {/* <NoData message={'No Data Found'} showImage={true} /> */}
      </section>
    </>
  );
};

export default JoinTeam;
