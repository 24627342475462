import Bing from '../../../assets/images/bingAds.png';
import fbSquare from '../../../assets/images/fbSquare.png';
import gAdsSquare from '../../../assets/images/gAdsSquare.png';
import gAnalyticsSquare from '../../../assets/images/gAnalyticsSquare.png';
import linkedinSquare from '../../../assets/images/linkedinSquare.png';
import twitterSquare from '../../../assets/images/twitterSquare.png';
import gBuisnessCentre from '../../../assets/images/google_my_business_logo.svg';
import gSearch from '../../../assets/images/google_search_console.svg';
import gTagManager from '../../../assets/images/google_tag_manager.svg';
import FbAds from '../../../assets/images/facebook_ads.svg';
import gMerchantCentre from '../../../assets/images/google_merchant_centre.svg';

export const subbedChannelIconsConstants = {
  channelType: {
    facebookAdTitle: 'facebookads',
    facebookPagesTitle: 'facebookpages',
    googleAnalyticsTitle: 'ganalytics',
    googleAdsTitle: 'googleads',
    googleMerchant: 'googlemerchantcenter',
    googleMyBusiness: 'googlemybusiness',
    googleTagManager: 'googletagmanager',
    googleSearchConsole: 'googlesearchconsole',
    twitterPages: 'twitterpages',
    twitterAds: 'twitterads',
    linkedInPages: 'linkedinpages',
    linkedInAds: 'linkedinads',
    bingAds: 'bingads',
  },
  icons: {
    facebook: fbSquare,
    googleAnalytics: gAnalyticsSquare,
    googleAds: gAdsSquare,
    bing: Bing,
    twitter: twitterSquare,
    linkedin: linkedinSquare,
    googleSearchConsole: gSearch,
    googleTagManager: gTagManager,
    googleBusiness: gBuisnessCentre,
    facebookAds: FbAds,
    googleMerchantCentre: gMerchantCentre,
  },
  title: {
    facebookAdTitle: 'Facebook Ads',
    facebookPagesTitle: 'Facebook Pages',
    googleAnalyticsTitle: 'Google Analytics',
    googleAdsTitle: 'Google Ads',
    googleMerchantTitle: 'Google Merchant Center',
    googleMyBusinessTitle: 'Google My Business',
    googleTagManagerTitle: 'Google Tag Manager',
    googleSearchConsoleTitle: 'Google Search Console',
    twitterPagesTitle: 'Twitter Pages',
    twitterAdsTitle: 'Twitter Ads',
    linkedInPagesTitle: 'LinkedIn Pages',
    linkedInAdsTitle: 'LinkedIn Ads',
    bingAdsTitle: 'Bing Ads',
  },
  provider: {
    googleAnalyticsProvider: 'google',
    googleAdsProvider: 'google',
    googleBusinessProvider: 'google',
    googleMyBusinessProvider: 'google',
    googleTagManagerProvider: 'google',
    googleSearchProvider: 'google',
    facebookAdProvider: 'facebook',
    facebookPagesProvider: 'facebook',
    twitterPagesProvider: 'twitter',
    twitterAdsProvider: 'twitter',
    linkedInPagesProvider: 'linkedin',
    linkedInAdsProvider: 'linkedin',
    bingAdsProvider: 'microsoft',
  },
  roleLabels: {
    googleAnalyticsRoleLabel: 'Standard roles',
    googleTagManagerRoleLabel: 'Account access',
    googleMerchantCenterRoleLabel: 'User access',
    googleMyBusinessRoleLabel: 'Access',
    googleAdsRoleLabel: 'Account access',
    googleSearchConsoleRoleLabel: 'Permission',
    facebookAdsRoleLabel: 'Ad account roles',
    facebookPageLabel: 'Business roles',
    linkedInPageLabel: 'Role',
    twitterAdsRoleLabel: 'Role',
  },
  permissionLabels: {
    googleTagManagerPermissionLabel: 'Container access',
    facebookPagesPermissionLabel: 'Page account roles',
    googleAnalyticsPermissionLabel: 'Data restrictions',
    googleAnalyticsLabel: 'Data restrictions (GA4 properties only)',
  },
  channels: 'Channels',
  channel: 'Channel',
};
