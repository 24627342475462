import { API } from '../constants/api';
import { post, get, del, patch } from '../utils/apiHandler';
import { getUserInfo } from '../utils/getUserInfo';

export const distributeReport = async (reportContent, pages, users, editMode, groupId) => {
  let response;
  try {
    const userInfo = JSON.parse(getUserInfo());
    const userId = userInfo?.user?.id;
    const data = {
      reportType: reportContent.reportType,
      channel: reportContent.channel?.value,
      name: reportContent.groupName,
      createdBy: userId,
      page: pages.length ? pages : reportContent.page,
      pageName: reportContent.pageName || 'All',
      userList: users.length ? users : reportContent.userList,
      regularity: reportContent?.regularity?.value,
    };
    if (editMode) {
      response = await patch(`${API.distributeReport}/${groupId}`, data);
    } else {
      response = await post(`${API.distributeReport}`, data);
    }

    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getReportDetails = async (userId, page, pageSize) => {
  try {
    const response = await get(`${API.distributeReport}/${userId}?limit=${pageSize}&page=${page}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const deleteReport = async groupId => {
  try {
    const response = await del(`${API.distributeReport}/${groupId}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
