import React, { useState, useEffect } from 'react';
import CommonDashboard from '../../components/CommonDashboard';
import CreateWorkspaceMobileView from './MobileView';
import CreateWorkspaceWebView from './Webview';
import { constants } from './constants';
import useAuth from '../../customHooks/useAuth';
import { processChannelList } from '../UserPermissionChannel/helper';
import { getChannelAccountList } from '../../services/Workspace/getWorkspaceService';
import {
  filterPageListWithDroppedData,
  processDataId,
  processDroppedData,
  processDroppedDataId,
  processDroppedDataIdWithAddedUsers,
  processUserAdminId,
  processExistingViewersId,
  processInvitedViewersIds,
  processSelectedViewersData,
  processInvitedViewersData,
} from './helper';
import { useLocation } from 'react-router-dom';
import { getWorkspaceDetailService } from '../../services';
import { constants as commonConstants } from '../../constants/constants';
import Loader from '../../components/Loader';
import { getUserInfo } from '../../utils/getUserInfo';
const CreateWorkspace = () => {
  sessionStorage.setItem('page', 'Create Workspace');
  const { auth } = useAuth();
  const loggeedInInfo = JSON.parse(getUserInfo());
  const loggedinUser = auth?.user?.user?.id ? auth?.user?.user?.id : loggeedInInfo.user.id;
  const [workspaceRender, setWorkspaceRender] = useState(true);
  const [temp, setTemp] = useState([]); // to hold the actual user permission channel backend response
  const [tempId, setTempId] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  // For edit
  const location = useLocation();
  const createWorkspaceInfo = JSON.parse(localStorage.getItem(commonConstants.localStorageWorkspaceInfo));
  const getWorkspaceInfo = location.state ? location.state : createWorkspaceInfo;
  const [workspaceId, setWorkspaceId] = useState();
  const [workspaceDetailData, setWorkspaceDetailData] = useState([]);
  const [workspaceActualInfo, setworkspaceActualInfo] = useState({
    name: '',
    accounts: [],
    users: [],
    workspaceOwner: '',
    loggedinUser: loggedinUser,
    existingViewers: [],
    newViewers: [],
    deletedViewers: [],
    deleteInvitedViewers: [],
  });
  // Web view
  const [pageList, setPageList] = useState([]); // to add,remove data on new workspace creation - data shown in the draggable area
  const [droppedData, setDroppedData] = useState([]);
  const [createWorkspaceFormError, setCreateWorkspaceFormError] = useState({});
  const [createWorkspaceData, setCreateWorkspaceData] = useState({
    name: '',
    accounts: [],
    users: [],
    workspaceOwner: '',
    loggedinUser: loggedinUser,
    existingViewers: [],
    newViewers: [],
    deletedViewers: [],
    deleteInvitedViewers: [],
  });
  const [addedViewers, setAddedViewers] = useState([]);
  const [invitedViewers, setInvitedViewers] = useState([]);
  const [invitedViewersIds, setInvitedViewersIds] = useState([]);
  const [invitedViewersList, setInvitedViewersList] = useState([]);
  // Mobile view
  const [pageMobileList, setPageMobileList] = useState([]); // to add,remove data on new workspace creation - data shown in the draggable area
  const [droppedMobileData, setDroppedMobileData] = useState([]);
  const [createWorkspaceMobileFormError, setCreateWorkspaceMobileFormError] = useState({});
  const [createWorkspaceMobileData, setCreateWorkspaceMobileData] = useState({
    name: '',
    accounts: [],
    users: [],
    workspaceOwner: '',
    loggedinUser: loggedinUser,
    existingViewers: [],
    newViewers: [],
    deletedViewers: [],
    deleteInvitedViewers: [],
  });

  useEffect(() => {
    if (temp?.length === 0 && getWorkspaceInfo?.isWorkspaceEdit && !workspaceId) {
      // workspace edit
      getWorkspaceDetailService(getWorkspaceInfo.workspaceId).then(async response => {
        setWorkspaceId(response.data.data.workSpace.id);
        //submit data
        setCreateWorkspaceData(values => ({
          ...values,
          name: response.data.data.workSpace.name,
          accounts: processDroppedDataId(response.data.data.workSpace.WorkspaceAccounts).sort(),
          users: processUserAdminId(response.data.data.workSpace.adminDetails).sort(),
          workspaceOwner: response.data.data.workSpace.createdBy,
          //viewer
          existingViewers: processExistingViewersId(response.data.data.workSpace.userDetails).sort(),
          deleteInvitedViewers: processInvitedViewersIds(response?.data?.data?.invitedUsers).sort(),
        }));
        //actual for comparison
        setworkspaceActualInfo(values => ({
          ...values,
          name: response.data.data.workSpace.name,
          accounts: processDroppedDataId(response.data.data.workSpace.WorkspaceAccounts).sort(),
          users: processUserAdminId(response.data.data.workSpace.adminDetails).sort(),
          workspaceOwner: response.data.data.workSpace.createdBy,
          //viewer
          existingViewers: processExistingViewersId(response.data.data.workSpace.userDetails).sort(),
          deleteInvitedViewers: processInvitedViewersIds(response?.data?.data?.invitedUsers).sort(),
        }));
        setDroppedData(processDroppedData(response.data.data.workSpace.WorkspaceAccounts));
        //newviewer
        setAddedViewers(processSelectedViewersData(response.data.data.workSpace.userDetails));
        setInvitedViewers(processInvitedViewersData(response?.data?.data?.invitedUsers));
        setInvitedViewersIds(processInvitedViewersIds(response?.data?.data?.invitedUsers));
        setWorkspaceDetailData(response.data.data.workSpace);
        // mobileview
        setCreateWorkspaceMobileData(values => ({
          ...values,
          name: response.data.data.workSpace.name,
          accounts: processDroppedDataId(response.data.data.workSpace.WorkspaceAccounts).sort(),
          users: processUserAdminId(response.data.data.workSpace.adminDetails).sort(),
          workspaceOwner: response.data.data.workSpace.createdBy,
          //viewer
          existingViewers: processExistingViewersId(response.data.data.workSpace.userDetails).sort(),
          deleteInvitedViewers: processInvitedViewersIds(response?.data?.data?.invitedUsers).sort(),
        }));
        setDroppedMobileData(processDroppedData(response.data.data.workSpace.WorkspaceAccounts));

        getChannelAccountList().then(res => {
          setPageList(
            filterPageListWithDroppedData(
              processDroppedDataIdWithAddedUsers(response.data.data.workSpace.WorkspaceAccounts).sort(),
              processChannelList(res.data)
            )
          );
          setPageMobileList(processChannelList(res.data));
          setTemp(res.data);
          setTempId(processDataId(res.data));
          setIsLoading(false);
        });
      });
    } else if (temp?.length === 0 && getWorkspaceInfo?.isWorkspaceCreate) {
      getChannelAccountList().then(res => {
        setPageList(processChannelList(res.data));
        setTemp(res.data);
        setTempId(processDataId(res.data));
        // mobileview
        setPageMobileList(processChannelList(res.data));
        setIsLoading(false);
      });
    } else {
      setIsLoading(false);
    }
  }, [workspaceRender]);

  // set authdata accordingly
  const navData = [
    {
      userType: 'Hydra Authenticated User',
      protectOn: true,
      pathHistory: [
        { title: auth.user?.user?.name, highlight: true },
        { title: auth?.channelCount == 1 ? `${auth?.channelCount} Channel` : `${auth?.channelCount} Channels` },
      ],
    },
  ];

  return isLoading ? (
    <Loader />
  ) : (
    <CommonDashboard hideHeader={false}>
      {/*Web view*/}
      <CreateWorkspaceWebView
        pageList={pageList}
        setPageList={setPageList}
        workspaceRender={workspaceRender}
        setWorkspaceRender={setWorkspaceRender}
        constants={constants}
        navData={navData}
        droppedData={droppedData}
        setDroppedData={setDroppedData}
        createWorkspaceFormError={createWorkspaceFormError}
        setCreateWorkspaceFormError={setCreateWorkspaceFormError}
        createWorkspaceData={createWorkspaceData}
        setCreateWorkspaceData={setCreateWorkspaceData}
        mobileview={false}
        tempId={tempId}
        temp={temp}
        // editworkspace
        workspaceId={workspaceId}
        workspaceDetailData={workspaceDetailData}
        workspaceActualInfo={workspaceActualInfo}
        // newedit
        addedViewers={addedViewers}
        setAddedViewers={setAddedViewers}
        invitedViewers={invitedViewers}
        setInvitedViewers={setInvitedViewers}
        invitedViewersIds={invitedViewersIds}
        setInvitedViewersIds={setInvitedViewersIds}
        invitedViewersList={invitedViewersList}
        setInvitedViewersList={setInvitedViewersList}
        setLoader={value => setIsLoading(value)}
      />
      {/*Mobile view*/}
      <CreateWorkspaceMobileView
        pageList={pageMobileList}
        constants={constants}
        droppedData={droppedMobileData}
        setDroppedData={setDroppedMobileData}
        createWorkspaceFormError={createWorkspaceMobileFormError}
        setCreateWorkspaceFormError={setCreateWorkspaceMobileFormError}
        createWorkspaceData={createWorkspaceMobileData}
        setCreateWorkspaceData={setCreateWorkspaceMobileData}
        mobileview={true}
        workspaceRender={workspaceRender}
        setWorkspaceRender={setWorkspaceRender}
        setPageList={setPageMobileList}
        temp={temp}
        // editworkspace
        workspaceId={workspaceId}
        workspaceDetailData={workspaceDetailData}
        workspaceActualInfo={workspaceActualInfo}
        // newedit
        addedViewers={addedViewers}
        setAddedViewers={setAddedViewers}
        invitedViewers={invitedViewers}
        setInvitedViewers={setInvitedViewers}
        invitedViewersIds={invitedViewersIds}
        setInvitedViewersIds={setInvitedViewersIds}
        invitedViewersList={invitedViewersList}
        setInvitedViewersList={setInvitedViewersList}
        setLoader={value => setIsLoading(value)}
      />
    </CommonDashboard>
  );
};
export default CreateWorkspace;
