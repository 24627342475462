import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Tippy from '@tippyjs/react';
import FilterReorderingDP from '../../components/FilterReorderingDP';
import IndividualWorkspacesList from './components/IndividualWorkspacesList';
import BreadCrumb from '../../components/BreadCrumb';
import CommonDashboard from '../../components/CommonDashboard';
import Loader from '../../components/Loader';
import useAuth from '../../customHooks/useAuth';
import { listWorkspace } from '../../services/workspaceSummeryService';
import { channelCount } from '../../services/channelListService';
import { navData } from './constants';
import { loginConstants } from '../../components/Modal/LoginModal/constants';
import { processWorkspaceList } from './helper';
import Icons from '../../assets/Icons';
import NoData from '../../components/Nodata';
import { getUserInfo } from '../../utils/getUserInfo';
import { useToastBox } from '../../customHooks/useToastBox';

const WorkspaceList = () => {
  sessionStorage.setItem('page', 'Workspace List');
  const [filterDP, setFilterDP] = useState(false);
  const [loading, setIsLoading] = useState(true);
  const [channelMobileSelection, setChannelMobileSelection] = useState(0);
  const [workspaceSummery, setWorkspaceSummery] = useState([]);
  const [workspaceFilter, setWorkspaceFilter] = useState({});
  const [workspaceListCount, setWorkspaceListCount] = useState({});
  const { auth } = useAuth();
  const navigate = useNavigate();
  const userInfo = JSON.parse(getUserInfo());
  const userType = auth?.user?.user?.type ? auth?.user?.user?.type : userInfo?.user?.role;
  const decryptedRole = CryptoJS.AES.decrypt(userType, loginConstants.localKey).toString(CryptoJS.enc.Utf8);
  const { handleErrorToast } = useToastBox();

  navData[0].pathHistory[0].title = auth.user?.user?.name;

  const setWorkspaceDetails = () => {
    listWorkspace()
      .then(response => {
        const responseData = response.data.data,
          workspaceCount = responseData.length;
        setWorkspaceListCount(workspaceCount);
        navData[0].pathHistory[1].title =
          workspaceCount == 1 ? `${workspaceCount} Workspace` : `${workspaceCount} Workspaces`;
        const processedWorkspaceData = processWorkspaceList(responseData);
        setWorkspaceSummery(processedWorkspaceData);
        setIsLoading(false);
      })
      .catch(error => {
        handleErrorToast(error?.message);
        setIsLoading(false);
      });
  };

  const onFilterApply = (data, company) => {
    setWorkspaceSummery(data);
    setWorkspaceFilter(company);
  };

  const navigateToCreateWorkspace = () => {
    navigate('/createWorkspace');
  };

  useEffect(() => {
    setWorkspaceDetails();
    //For getting message count for viewers
    if (decryptedRole == 'user') {
      channelCount()
        .then(response => {
          sessionStorage.setItem('message_count', response?.data?.data?.messageReadStatus);
        })
        .catch(error => {
          console.error(error);
        });
    }
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <CommonDashboard hideHeader={false}>
      <div className="flex w-full flex-col md:-mx-5 md:-mt-4 md:w-[calc(100%+40px)]">
        <BreadCrumb
          navData={navData}
          role={decryptedRole}
          backToPrev={false}
          workspaceCount={auth.workspaceCount ? auth.workspaceCount : workspaceListCount}
        >
          <div className="ml-auto flex items-center space-x-2 md:ml-0 md:pt-6 md:pb-4">
            <div className=" relative md:w-full">
              <button
                data-testid="filter_id"
                aria-label="Filter"
                className="rounded-full border border-primary px-5.5 py-1.5 text-xs font-semibold leading-4.5 text-primary hover:bg-primary hover:text-white md:w-full"
                onClick={() => setFilterDP(true)}
              >
                Filter
              </button>
              {filterDP && (
                <FilterReorderingDP
                  data={workspaceSummery}
                  setFilter={setFilterDP}
                  onApply={onFilterApply}
                  workspace={true}
                />
              )}
            </div>
            {/* Edit commented for future use */}
            {/* {!editMode ? (
              <button
                aria-label="Edit"
                className="rounded-full border border-primary px-5.5 py-1.5 text-xs font-semibold leading-4.5 text-primary hover:bg-primary hover:text-white md:w-full"
                onClick={() => setEditMode(true)}
              >
                Edit
              </button>
            ) : (
              <button
                aria-label="Save Changes"
                className="rounded-full border border-primary bg-primary px-5.5 py-1.5 text-xs font-semibold leading-4.5 text-white hover:bg-primaryMedium md:w-full"
                onClick={() => setEditMode(false)}
              >
                <span>Save Changes</span>
              </button>
            )} */}
            {decryptedRole !== 'user' && (
              <button
                data-testid="create_workspace_id"
                aria-label="Create Workspace"
                className="rounded-full border border-primary bg-primary px-5.5 py-1.5 text-xs font-semibold leading-4.5 text-white hover:bg-primaryMedium md:hidden"
                onClick={navigateToCreateWorkspace}
              >
                <span>Create Workspace</span>
              </button>
            )}
          </div>
        </BreadCrumb>
        <div className="hidden space-x-2 overflow-y-auto bg-gray-light1 pt-4 pl-4 md:flex">
          {workspaceSummery.length !== 0 &&
            workspaceSummery.map((data, index) => {
              return (
                <div
                  key={`individualworkspaceHeader__${index}`}
                  onClick={() => setChannelMobileSelection(index)}
                  className="float-left mb-1 flex min-h-[4rem] min-w-[223px] items-center rounded-lg border border-white200  bg-white p-4 pb-3 hover:border-primary  hover:shadow-4xl hover:outline hover:outline-1 hover:outline-primary focus:shadow-4xl"
                >
                  <div className=" mr-2.5">
                    <img src={data.logo} alt={data.name} />
                  </div>
                  <div>
                    <div className=" text-xs font-semibold leading-4.5 text-gray-dark">{data.name}</div>
                    {/* admin user count for future use */}
                    {/* <div className="text-xxs font-medium leading-[0.9375rem] text-gray-medium">{data.pagesDetails.length} Pages</div> */}
                  </div>
                  <div className="ml-auto text-primary">
                    <Tippy content="View Workspace" placement="bottom" arrow={true}>
                      <Link to={'#'}>
                        <Icons.Export />
                      </Link>
                    </Tippy>
                  </div>
                </div>
              );
            })}
        </div>
        <div className="flex h-full w-full  space-x-4 overflow-auto bg-gray-light1 p-4 pb-16 md:h-auto md:space-x-0 md:pb-38">
          {workspaceSummery.length !== 0 ? (
            workspaceSummery.map((element, index) => {
              if (element.checked) {
                return (
                  <>
                    <IndividualWorkspacesList
                      itemIndex={index}
                      data={element}
                      key={`individualWorkspace__${index}`}
                      // editMode={editMode}
                      selectedCard={channelMobileSelection}
                      filter={workspaceFilter}
                    />
                  </>
                );
              }
            })
          ) : (
            <div className="w-full">
              <NoData message={'No Data'} showImage={true} />
            </div>
          )}
        </div>
      </div>
    </CommonDashboard>
  );
};
export default WorkspaceList;
