import React from 'react';

const channelList = [
  {
    userInfo: 'Hydra Authenticated User',
    userName: 'Steve911@gmail.com',
    addedByYou: [<span key={'uniqueUser_Steve911@gmail.com'}>You</span>],
    addedBy: [<span key={'uniqueUser_Steve911@gmail.com'}>License Holder</span>],
    verified: [
      <span key={'uniqueUser_Steve911@gmail.com'} className="verified">
        Verified
      </span>,
    ],
    unverified: [],
    lastLoginDate: '17 Jan ',
    lastLoginTime: '07:23 PM',
  },
  {
    userInfo: 'Hydra Authenticated User',
    userName: 'Steve911@gmail.com',
    addedByYou: [<span key={'uniqueUser_Steve911@gmail.com'}>Devon Lane</span>],
    addedBy: [],
    verified: [],
    unverified: [
      <span key={'uniqueUser_Steve911@gmail.com'} className="unVerified">
        Unverified
      </span>,
    ],
    lastLoginDate: '17 Jan ',
    lastLoginTime: '07:23 PM',
  },
  {
    userInfo: 'Hydra Authenticated User',
    userName: 'Steve911@gmail.com',
    addedByYou: [<span key={'uniqueUser_Steve911@gmail.com'}>You</span>],
    addedBy: [<span key={'uniqueUser_Steve911@gmail.com'}>License Holder</span>],
    verified: [
      <span key={'uniqueUser_Steve911@gmail.com'} className="verified">
        Verified
      </span>,
    ],
    unverified: [],
    lastLoginDate: '17 Jan ',
    lastLoginTime: '07:23 PM',
  },
  {
    userInfo: 'Hydra Authenticated User',
    userName: 'Steve911@gmail.com',
    addedByYou: [<span key={'uniqueUser_Steve911@gmail.com'}>Devon Lane</span>],
    addedBy: [],
    verified: [],
    unverified: [
      <span key={'uniqueUser_Steve911@gmail.com'} className="unVerified">
        Unverified
      </span>,
    ],
    lastLoginDate: '17 Jan ',
    lastLoginTime: '07:23 PM',
  },
  {
    userInfo: 'Hydra Authenticated User',
    userName: 'Steve911@gmail.com',
    addedByYou: [<span key={'uniqueUser_Steve911@gmail.com'}>You</span>],
    addedBy: [<span key={'uniqueUser_Steve911@gmail.com'}>License Holder</span>],
    verified: [
      <span key={'uniqueUser_Steve911@gmail.com'} className="verified">
        Verified
      </span>,
    ],
    unverified: [],
    lastLoginDate: '17 Jan ',
    lastLoginTime: '07:23 PM',
  },
  {
    userInfo: 'Hydra Authenticated User',
    userName: 'Steve911@gmail.com',
    addedByYou: [<span key={'uniqueUser_Steve911@gmail.com'}>Devon Lane</span>],
    addedBy: [],
    verified: [],
    unverified: [
      <span key={'uniqueUser_Steve911@gmail.com'} className="unVerified">
        Unverified
      </span>,
    ],
    lastLoginDate: '17 Jan ',
    lastLoginTime: '07:23 PM',
  },
];

const TableConstants = [
  { title: '', field: 'icon' },
  { title: 'User', field: 'email' },
  { title: 'Added By', field: 'invitedby' },
  { title: 'Verification Status', field: 'InvitationStatus' },
  { title: 'Last Login', field: 'lastLogin' },
];

const userInfo = 'Hydra Authenticated User';

const userDetails = {
  invitedByYou: 'You',
  licenseOwner: 'License Owner',
  adminUser: 'Admin User',
};

const alertModalConstants = {
  heading: 'Are you sure you want to continue?',
  message:
    'Deleting this user will permanently remove them from Hydra and all of their workspaces will be transferred to you.',
};

const deleteNowModalConstants = {
  heading: 'Are you sure you want to delete this user now?',
};

export { channelList, TableConstants, userInfo, userDetails, alertModalConstants, deleteNowModalConstants };
