import React from 'react';
import Icons from '../../../assets/Icons';

export const alertsActivityContants = {
  add: 'add-user-channel',
  edit: 'update-user-channel',
  remove: 'delete-user-channel',
  subscriptionSuccess: 'payment-success',
  externalUpdate: 'external-channel-update',
};

export const alertIcons = {
  addICon: <Icons.Restricted />,
  editIcon: <Icons.InfoIcon2 />,
  removeIcon: <Icons.Caution />,
  success: <Icons.CircleTick />,
};
