import { searchConstants } from './constants';

const processNavigation = searchType => {
  switch (searchType) {
    case searchConstants.type.user:
      return searchConstants.naviagationConstants.user;
    case searchConstants.type.workspace:
      return searchConstants.naviagationConstants.workspace;
    case searchConstants.type.page:
      return searchConstants.naviagationConstants.page;
    case searchConstants.type.nonAdminUsers:
      return searchConstants.naviagationConstants.nonAdminUsers;
    default:
      break;
  }
};

export { processNavigation };
