import { deleteUserFromList, userList, revokeUserFromList } from '../../../services';

const listUsers = async (tenantId, page, pageSize) => {
  try {
    const response = await userList(tenantId, page, pageSize);
    return response;
  } catch (error) {
    console.error(error);
    // throw new Error(error.message);
  }
};

const handleDeleteUserConfimation = (
  deleteUser,
  setDeleteUser,
  handleSuccessToast,
  handleErrorToast,
  setUserListReload,
  userListReload,
  setIsLoading,
  setDeleteNow
) => {
  setIsLoading(true);
  deleteUserFromList(deleteUser)
    .then(response => {
      setIsLoading(false);
      handleSuccessToast(response.data.data.message);
      setDeleteUser({ email: '', deletedBy: '' });
      setDeleteNow(false);
      setUserListReload(!userListReload);
    })
    .catch(error => {
      setIsLoading(false);
      handleErrorToast(error.message);
    });
};

const handleRevokeUserConfimation = (
  revokeUser,
  setRevokeUser,
  handleSuccessToast,
  handleErrorToast,
  setUserListReload,
  userListReload,
  setIsLoading,
  setRevokeErrorModal
) => {
  setIsLoading(true);
  revokeUserFromList(revokeUser)
    .then(response => {
      setIsLoading(false);
      handleSuccessToast(response.data.data);
      setRevokeUser({ email: '' });
      setUserListReload(!userListReload);
    })
    .catch(error => {
      if (error.message === 'No free licenses available to revoke') {
        setRevokeErrorModal(true);
      } else {
        handleErrorToast(error.message);
      }
      setIsLoading(false);
    });
};

export { listUsers, handleDeleteUserConfimation, handleRevokeUserConfimation };
