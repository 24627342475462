import { subbedChannelIconsConstants } from '../Dashboard/ChannelSubbed/constants';
import { textConstants } from '../UsersPermissionsChannelView/constants';

export const workSpaceDetailedItems = async data => {
  const pageList = [];
  data?.WorkspaceAccounts?.forEach(element => {
    const userList = [];
    const channelDetails = getChannelDetails(element?.channelAccount?.channelType);
    const obj = {
      imgUrl: element?.channelAccount?.details?.picture ? element?.channelAccount?.details?.picture : channelDetails.imgUrl,
      channelType: element?.channelAccount?.channelType,
      accountId: element?.channelAccount?.accountId,
      redirectionURL: element?.channelAccount?.details?.manageUsersUrl,
      setupInProgress: element?.channelAccount?.details?.setupInProgress,
    };
    obj.title = element?.channelAccount?.name;
    element?.channelAccount?.ChannelAccountUsers?.forEach(user => {
      const userDetails = { removed: false };
      userDetails.email = user?.email;
      userDetails.title = element?.channelAccount?.channelType == textConstants.googleAds ? user?.email : user?.name;
      userDetails.id = user?.id;
      userDetails.accountName = element?.channelAccount?.name;
      userDetails.accountId = element?.channelAccount?.accountId;
      userDetails.channelUserId = user?.channelUserId;
      userDetails.removed = false;
      userDetails.logo = null;
      userDetails.channelType = obj?.channelType;
      userDetails.channelAccountId = user?.channelAccountId;
      userDetails.isBusinessAssociated = user?.details?.isBusinessAssociated;

      userDetails.role = user?.roles;

      userDetails.permission = user?.permissions ? user?.permissions : '';

      userDetails.displayRole = user?.details?.rolesLabel ? user?.details?.rolesLabel : [];
      userDetails.displayPermission = user?.details?.permissionsLabel ? user?.details?.permissionsLabel : '-';

      userDetails.roleLabel = channelDetails?.roleLabel;
      userDetails.permissionLabel = channelDetails?.permissionLabel ? channelDetails?.permissionLabel : null;

      userDetails.lastLogin = [{ dateTime: '-', location: '-' }];

      if (element?.channelAccount?.channelType === textConstants?.googleMerchantCentre) {
        userDetails.isMainAccountUser = user?.details?.isMainAccountUser ? true : false;
        userDetails.isSubAccount = element?.channelAccount?.details?.isSubAccount ? true : false;
      }

      userList.push(userDetails);
    });
    obj.pagesDetails = userList;
    pageList.push(obj);
  });
  return pageList;
};

export const getChannelDetails = channelType => {
  const element = { channelType: channelType, imgUrl: '', title: '', provider: '', roleLabel: '' };
  switch (element?.channelType) {
    case subbedChannelIconsConstants?.channelType?.googleAdsTitle:
      element.imgUrl = subbedChannelIconsConstants?.icons?.googleAds;
      element.title = subbedChannelIconsConstants?.title?.googleAdsTitle;
      element.provider = subbedChannelIconsConstants?.provider?.googleAdsProvider;
      element.roleLabel = subbedChannelIconsConstants?.roleLabels?.googleAdsRoleLabel;
      break;
    case subbedChannelIconsConstants?.channelType?.googleAnalyticsTitle:
      element.imgUrl = subbedChannelIconsConstants?.icons?.googleAnalytics;
      element.title = subbedChannelIconsConstants?.title?.googleAnalyticsTitle;
      element.provider = subbedChannelIconsConstants?.provider?.googleAnalyticsProvider;
      element.roleLabel = subbedChannelIconsConstants?.roleLabels?.googleAnalyticsRoleLabel;
      element.permissionLabel = subbedChannelIconsConstants?.permissionLabels?.googleAnalyticsLabel;
      break;
    case subbedChannelIconsConstants?.channelType?.googleMerchant:
      element.imgUrl = subbedChannelIconsConstants?.icons?.googleMerchantCentre;
      element.title = subbedChannelIconsConstants?.title?.googleMerchantTitle;
      element.provider = subbedChannelIconsConstants?.provider?.googleBusinessProvider;
      element.roleLabel = subbedChannelIconsConstants?.roleLabels?.googleMerchantCenterRoleLabel;

      break;
    case subbedChannelIconsConstants?.channelType?.googleMyBusiness:
      element.imgUrl = subbedChannelIconsConstants?.icons?.googleBusiness;
      element.title = subbedChannelIconsConstants?.title?.googleMyBusinessTitle;
      element.provider = subbedChannelIconsConstants?.provider?.googleMyBusinessProvider;
      element.roleLabel = subbedChannelIconsConstants?.roleLabels?.googleMyBusinessRoleLabel;
      break;
    case subbedChannelIconsConstants?.channelType?.googleSearchConsole:
      element.imgUrl = subbedChannelIconsConstants?.icons?.googleSearchConsole;
      element.title = subbedChannelIconsConstants?.title?.googleSearchConsoleTitle;
      element.provider = subbedChannelIconsConstants?.provider?.googleSearchProvider;
      element.roleLabel = subbedChannelIconsConstants?.roleLabels?.googleSearchConsoleRoleLabel;

      break;
    case subbedChannelIconsConstants?.channelType?.googleTagManager:
      element.imgUrl = subbedChannelIconsConstants?.icons?.googleTagManager;
      element.title = subbedChannelIconsConstants?.title?.googleTagManagerTitle;
      element.provider = subbedChannelIconsConstants?.provider?.googleTagManagerProvider;
      element.roleLabel = subbedChannelIconsConstants?.roleLabels?.googleTagManagerRoleLabel;
      element.permissionLabel = subbedChannelIconsConstants?.permissionLabels?.googleTagManagerPermissionLabel;

      break;
    case subbedChannelIconsConstants?.channelType?.bingAds:
      element.imgUrl = subbedChannelIconsConstants?.icons?.bing;
      element.title = subbedChannelIconsConstants?.title?.bingAdsTitle;
      element.provider = subbedChannelIconsConstants?.provider?.bingAdsProvider;
      break;
    case subbedChannelIconsConstants?.channelType?.facebookAdTitle:
      element.imgUrl = subbedChannelIconsConstants?.icons?.facebookAds;
      element.title = subbedChannelIconsConstants?.title?.facebookAdTitle;
      element.provider = subbedChannelIconsConstants?.provider?.facebookAdProvider;
      element.roleLabel = subbedChannelIconsConstants?.roleLabels?.facebookAdsRoleLabel;
      break;
    case subbedChannelIconsConstants?.channelType?.facebookPagesTitle:
      element.imgUrl = subbedChannelIconsConstants?.icons?.facebook;
      element.title = subbedChannelIconsConstants?.title?.facebookPagesTitle;
      element.provider = subbedChannelIconsConstants?.provider?.facebookPagesProvider;
      element.roleLabel = subbedChannelIconsConstants?.roleLabels?.facebookPageLabel;
      element.permissionLabel = subbedChannelIconsConstants?.permissionLabels?.facebookPagesPermissionLabel;

      break;
    case subbedChannelIconsConstants?.channelType?.linkedInAds:
      element.imgUrl = subbedChannelIconsConstants?.icons?.linkedin;
      element.title = subbedChannelIconsConstants?.title?.linkedInAdsTitle;
      element.provider = subbedChannelIconsConstants?.provider?.linkedInAdsProvider;
      element.roleLabel = subbedChannelIconsConstants?.roleLabels?.linkedInPageLabel;
      break;
    case subbedChannelIconsConstants?.channelType?.linkedInPages:
      element.imgUrl = subbedChannelIconsConstants?.icons?.linkedin;
      element.title = subbedChannelIconsConstants?.title?.linkedInPagesTitle;
      element.provider = subbedChannelIconsConstants?.provider?.linkedInPagesProvider;
      element.roleLabel = subbedChannelIconsConstants?.roleLabels?.linkedInPageLabel;
      break;
    case subbedChannelIconsConstants?.channelType?.twitterAds:
      element.imgUrl = subbedChannelIconsConstants?.icons?.twitter;
      element.title = subbedChannelIconsConstants?.title?.twitterAdsTitle;
      element.provider = subbedChannelIconsConstants?.provider?.twitterAdsProvider;
      element.roleLabel = subbedChannelIconsConstants?.roleLabels?.twitterAdsRoleLabel;

      break;
    case subbedChannelIconsConstants?.channelType?.twitterPages:
      element.imgUrl = subbedChannelIconsConstants?.icons?.twitter;
      element.title = subbedChannelIconsConstants?.title?.twitterPagesTitle;
      element.provider = subbedChannelIconsConstants?.provider?.twitterPagesProvider;
      element.roleLabel = subbedChannelIconsConstants?.roleLabels?.twitterAdsRoleLabel;

      break;
    default:
      break;
  }
  return element;
};
