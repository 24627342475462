import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import Icons from '../../../assets/Icons';
import classes from './workspace.module.scss';
import NoData from '../../../components/Nodata';

const PageBuilder = ({ pageData, editMode, navData }) => {
  const navigate = useNavigate();
  const [selectedToRemove, setSelectedToRemove] = useState(pageData?.selected);

  return (
    <div
      className="workspaceListCard m-[1px] cursor-pointer rounded-lg border border-white200 bg-white hover:border-primary hover:outline hover:outline-1 hover:outline-primary"
      onClick={() => {
        navigate('/workspace-detailed', {
          state: { id: navData?.id, name: navData?.name, fromAccount: true, accountName: pageData?.channelAccount?.name },
        });
      }}
    >
      <div>
        <div className=" relative flex items-center overflow-hidden rounded-t-lg border-b border-white200 p-4 md:bg-blue-light">
          <div className="flex items-center truncate">
            <img className="h-[20px]" src={pageData?.channelAccount?.logo} alt={pageData?.channelAccount?.channelName} />
            <div className=" truncate pl-2 text-[13px] font-semibold leading-4.5 text-gray-dark">
              {pageData?.channelAccount?.channelName}
            </div>
          </div>
          <div className=" absolute right-4">
            {editMode && (
              <label className="form-checkbox">
                <input
                  className="form-checkbox-input"
                  type="checkbox"
                  name=""
                  onChange={e => {
                    setSelectedToRemove(e.value);
                  }}
                  checked={selectedToRemove}
                />
                <div className="form-check-circle">
                  <Icons.TickSmall />
                </div>
              </label>
            )}
          </div>
        </div>
      </div>
      <div className=" truncate px-4 py-3">
        <div className=" truncate text-xs font-medium leading-[1.3125rem] text-gray-dark">
          <Tippy content={pageData?.channelAccount?.name} placement="bottom">
            <div className="truncate">Account: {pageData?.channelAccount?.name}</div>
          </Tippy>
        </div>
        {pageData?.channelAccount?.details?.regionCode && (
          <div className=" mt-1 truncate text-xs text-gray-medium">
            Region: {pageData?.channelAccount?.details?.regionCode}
          </div>
        )}
      </div>
    </div>
  );
};
const IndividualWorkspacesList = ({ data, editMode, selectedCard, itemIndex, filter }) => {
  const [openRoleSelector, setOpenRoleSelector] = useState({
    isOpen: false,
    optionList: [],
  });

  const navigate = useNavigate();

  return (
    <div className={`${classes.workSpaceWrapper} ${selectedCard === itemIndex ? classes.active : ''}`}>
      <div className=" flex w-[265px] min-w-[265px] flex-col rounded-lg border border-white200 bg-white md:w-full md:min-w-full md:border-none md:bg-transparent">
        <div className="flex items-center border-b border-white200 p-4 md:hidden">
          <div className=" mr-2.5">
            <img src={data.logo} alt={data.name} />
          </div>
          <div>
            <div className=" text-xs font-semibold leading-4.5 text-gray-dark">{data.name}</div>
            {/* Admin Users count for future use */}
            {/* <div className="text-xxs font-medium leading-[0.9375rem] text-gray-medium">
              {data.WorkspaceAccounts.length} Admin Users
            </div> */}
          </div>
          <div className="ml-auto text-primary">
            <Tippy content="View Workspace" placement="bottom" arrow={true}>
              <div
                data-testid="workspacelist_naviagte_id"
                className="cursor-pointer"
                onClick={() => {
                  navigate('/workspace-detailed', { state: { id: data.id, name: data.name } });
                }}
              >
                {/* <Link to={`/workspaces-detailed/${data.id}`}>
                  <Icons.Export />
                </Link> */}

                <Icons.Export />
              </div>
            </Tippy>
          </div>
        </div>
        <div className=" flex h-full flex-col space-y-4 overflow-y-auto p-4 md:p-0">
          {data.WorkspaceAccounts?.length > 0 &&
            data.WorkspaceAccounts?.map((element, index) => {
              if (element?.channelAccount) {
                if (Object.values(filter).length && filter.value !== 'All') {
                  if (filter.value === element.channelAccount?.channelName) {
                    return (
                      <PageBuilder
                        navData={data}
                        pageData={element}
                        key={`pageBuilder__${index}`}
                        editMode={editMode}
                        setOpenRoleSelector={setOpenRoleSelector}
                        openRoleSelector={openRoleSelector}
                      />
                    );
                  }
                } else {
                  return (
                    <PageBuilder
                      navData={data}
                      pageData={element}
                      key={`pageBuilder__${index}`}
                      editMode={editMode}
                      setOpenRoleSelector={setOpenRoleSelector}
                    />
                  );
                }
              }
            })}
          {data?.WorkspaceAccounts?.length == 0 && <NoData message={'No accounts'} showImage={false} />}
        </div>
        <div className="bottom-0 w-full !pb-[74px] md:fixed md:left-0 md:bg-white md:px-5 md:pt-6 md:pb-8 md:shadow-stickyBottomCard">
          <div className="hidden md:flex">
            <button
              aria-label="Create Workspace"
              className="w-full rounded-full border border-primary bg-primary px-5.5 py-1.5 text-xs font-semibold leading-4.5 text-white hover:bg-primaryMedium"
            >
              <span>Create Workspace</span>
            </button>
          </div>
          {editMode && (
            <div className=" sticky bottom-0 flex justify-center space-x-2 border-t border-white200 p-4 md:border-none md:px-0 md:pb-0">
              <button
                aria-label="Select All"
                className="h-8 w-full whitespace-nowrap rounded-full border border-primary px-2 text-xs font-semibold leading-6 text-primary hover:bg-primary hover:text-white"
              >
                <span>Select All</span>
              </button>
              <button
                aria-label="Remove Selected"
                className="h-8 w-full whitespace-nowrap rounded-full border border-primary px-2 text-xs font-semibold leading-6 text-primary hover:bg-primary hover:text-white"
              >
                <span>Remove Selected</span>
              </button>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};
export default IndividualWorkspacesList;
