const config = {
  DEVELOPMENT: {
    REACT_APP_RECAPTCHASITEKEY: '6LelmEIjAAAAAF0wA5sajsb5taQ6SfoA_xkAPSOe',
    // REACT_APP_RECAPTCHASITEKEY: '6Lf6VzwjAAAAAMFGRm8B-EEuo-KD6LqyD06K6eAE',
    // REACT_APP_RECAPTCHASITEKEY: '6LeIxAcTAAAAAJcZVRqyHh71UMIEGNQ_MXjiZKhI',
    REACT_APP_HYDRA_BASE_URL: 'https://api.hihydra.com',
    // REACT_APP_HYDRA_BASE_URL: 'http://localhost:3001',

    //QA Endpoint
    //REACT_APP_HYDRA_BASE_URL: 'https://qa-api.hihydra.com',

    SOCKET_IO_BASE_URL: 'https://api.hihydra.com',

    // SOCKET_IO_BASE_URL: 'http://localhost:3001',
  },
};
export default config;
