import React from 'react';
import Header from '../DashboardHeader';
import classes from './common.module.scss';

const CommonDashboard = props => {
  return (
    <div className={classes.contentWrapper}>
      {!props.hideHeader && <Header />}
      <div className={`${classes.dashboardWrapper}  ${props.classList ? props.classList : ''}`}>{props.children}</div>
    </div>
  );
};
export default CommonDashboard;
