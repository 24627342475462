import VisaCard from "../../assets/images/visacard.jpg";
import Mastercard from "../../assets/images/mastercard.jpg";
export const cardSaved = [
  {
    cardUser: "Steve Aoki",
    cardName: "Visa",
    endingDigit: "2434",
    cardLogo: VisaCard,
    default: true,
  },
  {
    cardUser: "Steve Aoki",
    cardName: "Master",
    endingDigit: "2434",
    cardLogo: Mastercard,
    default: false,
  },
];
export const subscriptionsInvoices=[
  {
    planName:'Enterprise Plan',
    total:'$149',
    paidOn:'10/11/22',
    paidUsing:'**** **** **** **69',
    paidCardLog:VisaCard
  },
  {
    planName:'Enterprise Plan',
    total:'$149',
    paidOn:'10/11/22',
    paidUsing:'**** **** **** **69',
    paidCardLog:VisaCard
  }
]