const tabs = ['All', 'Pages', 'Workspaces', 'Users', 'Account Users'];

const tabTypes = {
  all: 'All',
  page: 'page',
  workspace: 'workspace',
  user: 'user',
  accountUser: 'accountUser',
};

const tabTitle = {
  all: 'All',
  pages: 'Pages',
  workspaces: 'Workspaces',
  users: 'Users',
  accountUsers: 'Account Users',
};

const textConstants = {
  noData: 'No search results',
};

export { tabs, tabTypes, tabTitle, textConstants };
