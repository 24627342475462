// Signup validations

const EmailValidation = email => {
  const expression =
    // eslint-disable-next-line
    /(?!.*\.{2})^([a-z\d!#$%&'*+\-[\]/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+(\.[a-z\d!#$%&'*+\-[\]/=?^_`{|}~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]+)*|"((([\t]*\r\n)?[\t]+)?([\x01-\x08\x0b\x0c\x0e-\x1f\x7f\x21\x23-\x5b\x5d-\x7e\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|\\[\x01-\x09\x0b\x0c\x0d-\x7f\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]))*(([\t]*\r\n)?[\t]+)?")@(([a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\d\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.)+([a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]|[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF][a-z\d\-._~\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF]*[a-z\u00A0-\uD7FF\uF900-\uFDCF\uFDF0-\uFFEF])\.?$/i;
  return expression.test(String(email));
};

const OnlyDigitsValidation = phone => {
  const expression = /^[0-9]*$/;
  return expression.test(String(phone));
};

const OnlyAlphabetsAndWhiteSpaces = name => {
  const expression = /^[A-Za-z ]+$/;
  return expression.test(String(name));
};

const PasswordValidation = (password, setDynamicValidation) => {
  const checkForSpecialCharacter = /[`!@#$%^&*()_+\-=[\][\]{};':"\\|,.<>[\]/?~]/;
  const checkForDigits = /\d/;

  const minLength = 9;
  const maxLength = 15;
  const checkLength = minLength <= password.length && password.length <= maxLength;
  const checkUpperAndLowerCases = password.toUpperCase() !== password && password.toLowerCase() !== password;
  const checkSpecialCharacterAndDigits =
    checkForSpecialCharacter.test(String(password)) && checkForDigits.test(String(password));
  const validatePassword = checkLength && checkUpperAndLowerCases && checkSpecialCharacterAndDigits;
  setDynamicValidation({
    lengthLimit: checkLength,
    lowerCase: password.toUpperCase() !== password,
    specialCharacter: checkForSpecialCharacter.test(String(password)),
    upperCase: password.toLowerCase() !== password,
    number: checkForDigits.test(String(password)),
  });
  return validatePassword;
};

export { EmailValidation, OnlyDigitsValidation, OnlyAlphabetsAndWhiteSpaces, PasswordValidation };
