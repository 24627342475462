export const signupConstants = {
  title: 'Signup',
  signupTitles: ['firstName', 'lastName', 'phone', 'email', 'password', 'confirmPassword', 'captchaToken', 'referralCode'],
  defaultCountryCode: '+91',
  validationErrorMessages: {
    firstName: {
      required: 'First name cannot be empty',
      onlyAlphabets: 'Sorry, name must only include alphabets',
    },
    lastName: {
      required: 'Last name cannot be empty',
      onlyAlphabets: 'Sorry, name must only include alphabets',
    },
    email: {
      required: 'Email cannot be empty',
      validateError: 'Please enter a valid email address',
    },
    phone: {
      required: 'Phone number cannot be empty',
      validateError: 'Please enter a valid phone number',
      maxLimit: 'Phone number cannot exceed 14 digits',
      minLimit: 'Phone number should be at least 10 digits',
    },
    password: {
      required: 'Password cannot be empty',
      validateError: 'Please enter a valid password',
    },

    confirmPassword: {
      required: 'Confirm password cannot be empty',
      validateError: 'Password confirmation does not match',
    },

    countryCode: {
      required: '',
    },
    captchaToken: {
      required: 'Select captcha',
    },
    apiError: 'apiError',
  },

  textConstants: {
    modalHeading: 'Sign up for Hydra',
    alreadyAUser: 'Already a user?',
    login: 'Login',
    reset: 'Reset',
    submit: 'Sign Up',
    passwordRequirement: 'Password must include',
    passwordRequirements: [
      { label: 'Between 9 and 15 characters', identifier: 'lengthLimit' },
      { label: 'A lowercase character', identifier: 'lowerCase' },
      { label: 'A special character', identifier: 'specialCharacter' },
      { label: 'An uppercase character', identifier: 'upperCase' },
      { label: 'A number', identifier: 'number' },
    ],
  },
  dynamicValidationInitialState: {
    lengthLimit: false,
    lowerCase: false,
    specialCharacter: false,
    upperCase: false,
    number: false,
  },
  countryCodes: [
    { value: '+91', label: '+91' },
    { value: '+92', label: '+92' },
    { value: '+93', label: '+93' },
    { value: '+44', label: '+44' },
    { value: '+9444', label: '+9444' },
  ],
  formDataInitialValue: {
    countryCode: '+91',
  },
  otpModal: {
    subHeading: 'Authenticate Your Account',
    details:
      'Protecting your data is our highest priority. Please confirm your account by entering the authentication code sent to your registered mobile number and email',
    resendCode: 'Resend Code',
  },
};
