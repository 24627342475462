import { channelIconsConstants } from '../../components/ChannelSetup/constants';
import Workspace from '../../assets/images/Workspace.svg';

export const processWorkspaceList = data => {
  data?.forEach(element => {
    element.logo = Workspace;
    element.checked = true;
    element?.WorkspaceAccounts?.forEach(workspace => {
      if (workspace?.channelAccount) {
        switch (workspace?.channelAccount?.channelType) {
          case channelIconsConstants?.title?.googleAdsTitle:
            workspace.channelAccount.logo = channelIconsConstants?.icons?.googleAds;
            workspace.channelAccount.channelName = channelIconsConstants?.channelName?.googleads;
            workspace.selected = true;
            break;
          case channelIconsConstants?.title?.googleAnalyticsTitle:
            workspace.channelAccount.logo = channelIconsConstants?.icons?.googleAnalytics;
            workspace.channelAccount.channelName = channelIconsConstants?.channelName?.ganalytics;
            workspace.selected = true;
            break;
          case channelIconsConstants?.title?.googleMerchantCenterTitle:
            workspace.channelAccount.logo = channelIconsConstants?.icons?.googleMerchantCentre;
            workspace.channelAccount.channelName = channelIconsConstants?.channelName?.googlemerchantcenter;
            workspace.selected = true;

            break;
          case channelIconsConstants?.title?.googleBusinessCenterTitle:
            workspace.channelAccount.logo = channelIconsConstants?.icons?.googleBusinessCenter;
            workspace.channelAccount.channelName = channelIconsConstants?.channelName?.googlebusinesscenter;
            workspace.selected = true;
            break;
          case channelIconsConstants?.title?.googleMyBusinessTitle:
            workspace.channelAccount.logo = channelIconsConstants?.icons?.googleBusinessCenter;
            workspace.channelAccount.channelName = channelIconsConstants?.channelName?.googlemybusiness;
            workspace.selected = true;
            break;
          case channelIconsConstants?.title?.googleSearchConsoleTitle:
            workspace.channelAccount.logo = channelIconsConstants?.icons?.googleSearchConsole;
            workspace.channelAccount.channelName = channelIconsConstants?.channelName?.googlesearchconsole;
            workspace.selected = true;
            break;
          case channelIconsConstants?.title?.googleTagmanagerTitle:
            workspace.channelAccount.logo = channelIconsConstants?.icons?.googleTagManager;
            workspace.channelAccount.channelName = channelIconsConstants?.channelName?.googletagmanager;
            workspace.selected = true;
            break;
          case channelIconsConstants?.title?.bingAds:
            workspace.channelAccount.logo = channelIconsConstants?.icons?.bing;
            workspace.channelAccount.channelName = channelIconsConstants?.channelName?.bing;
            workspace.selected = true;
            break;
          case channelIconsConstants?.title?.facebookAdsTitle:
            workspace.channelAccount.logo = channelIconsConstants?.icons?.facebook;
            workspace.channelAccount.channelName = channelIconsConstants?.channelName?.facebookads;
            workspace.selected = true;
            break;
          case channelIconsConstants?.title?.facebookPageTitle:
            workspace.channelAccount.logo = channelIconsConstants?.icons?.facebook;
            workspace.channelAccount.channelName = channelIconsConstants?.channelName?.facebookpages;
            workspace.selected = true;
            break;
          case channelIconsConstants?.title?.linkedInAdsTitle:
            workspace.channelAccount.logo = channelIconsConstants?.icons?.linkedin;
            workspace.channelAccount.channelName = channelIconsConstants?.channelName?.linkedinads;
            workspace.selected = true;
            break;
          case channelIconsConstants?.title?.linkedInPagesTitle:
            workspace.channelAccount.logo = channelIconsConstants?.icons?.linkedin;
            workspace.channelAccount.channelName = channelIconsConstants?.channelName?.linkedinpages;
            workspace.selected = true;
            break;
          case channelIconsConstants?.title?.twitterAdsTitle:
            workspace.channelAccount.logo = channelIconsConstants?.icons?.twitter;
            workspace.channelAccount.channelName = channelIconsConstants?.channelName?.twitterads;
            workspace.selected = true;
            break;
          case channelIconsConstants?.title?.twitterPagesTitle:
            workspace.channelAccount.logo = channelIconsConstants?.icons?.twitter;
            workspace.channelAccount.channelName = channelIconsConstants?.channelName?.twitterpages;
            workspace.selected = true;
            break;
          default:
            break;
        }
      }
    });
  });

  return data;
};

export const getWorkspaceCompanyList = data => {
  const companyList = [{ value: 'All', label: 'All' }];
  data?.forEach(element => {
    element?.WorkspaceAccounts?.forEach(workspace => {
      const obj = { value: workspace?.channelAccount?.channelName, label: workspace?.channelAccount?.channelName };
      if (!companyList.filter(e => e.value === workspace?.channelAccount?.channelName).length) {
        companyList.push(obj);
      }
    });
  });

  return companyList;
};
