/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Icons from '../../../assets/Icons';
import MobileChannelsList from './MobileChannelsList';
import { useToastBox } from '../../../customHooks/useToastBox';
import { AlertModal } from '../../../components/Modal/AlertModal';
import Loader from '../../../components/Loader';
// import { AddUsersModal } from '../AddUsers';
import {
  handleDeleteWorkspace,
  handleMobileCancel,
  handleMobileEditCancel,
  handleSave,
  handleWorkspaceName,
} from '../helper';
import { createWorkspaceService } from '../../../services';
import { AddAdminUsersModal } from '../AddAdminUsers';
import { constants as modalConstants } from '../constants';
import { constants as commonConstants } from '../../../constants/constants';

const CreateWorkspaceMobileView = ({
  pageList,
  constants,
  droppedData,
  setDroppedData,
  createWorkspaceFormError,
  setCreateWorkspaceFormError,
  createWorkspaceData,
  setCreateWorkspaceData,
  mobileview,
  workspaceRender,
  setWorkspaceRender,
  setPageList,
  temp,
  // editworkspace
  workspaceId,
  workspaceDetailData,
  workspaceActualInfo,
  addedViewers,
  setAddedViewers,
  invitedViewers,
  setInvitedViewers,
  invitedViewersIds,
  setInvitedViewersIds,
  invitedViewersList,
  setInvitedViewersList,
  setLoader,
}) => {
  const navigate = useNavigate();
  const [addPages, setAddPages] = useState(false);
  const [channelMobileSelection, setChannelMobileSelection] = useState(0);
  const { handleSuccessToast, handleErrorToast } = useToastBox();
  const [isSetupModalOpen, setIsSetupModalOpen] = useState(false);
  const [userList, setUserList] = useState([]);
  const [selectedUsers, setSelectedUsers] = useState(JSON.parse(JSON.stringify(createWorkspaceData?.users))); //Object passing without reference
  const [isFormSubmitted, setIsFormSubmitted] = useState({
    submitted: false,
    formUsers: [],
    accountsSubmited: false,
    formAccounts: [],
  });
  //Viewers
  const [existingViewersList, setExistingViewersList] = useState([]);
  const [newViewersList, setNewViewersList] = useState([]);
  const [failedEmailIdsList, setFailedEmailIdsList] = useState([]);
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const [viewOnlyUsers, setViewOnlyUsers] = useState([]);
  const [cancel, setCancel] = useState(false);
  const [isViewerFormSubmitted, setIsViewerFormSubmitted] = useState({ added: false, formViewers: [] });
  const [renderViewer, setRenderViewer] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const [removedViewerIds, setRemovedViewerIds] = useState([]);
  const [removedInvitedViewerIds, setRemovedInvitedViewerIds] = useState([]);
  const [render, setRender] = useState(true);
  const [isLoading, setIsLoading] = useState(false);
  const [isDeleteMobileConfirmOpen, setIsDeleteMobileConfirmOpen] = useState(false);

  useEffect(() => {
    setSelectedUsers(JSON.parse(JSON.stringify(createWorkspaceData?.users)));
    if (failedEmailIdsList.length > 0) {
      setConfirmationModal(true);
    }
  }, [createWorkspaceData?.users, failedEmailIdsList]);

  const handleBackClick = () => {
    setAddPages(false);
    isFormSubmitted.accountsSubmited
      ? setCreateWorkspaceData(values => ({ ...values, accounts: isFormSubmitted?.accounts }))
      : setCreateWorkspaceData(values => ({
          ...values,
          accounts: JSON.parse(JSON.stringify(workspaceActualInfo?.accounts)),
        }));
  };

  const handleAddPagesClick = () => {
    setAddPages(true);
    setIsFormSubmitted(values => ({
      ...values,
      accountsSubmited: true,
      accounts: JSON.parse(JSON.stringify(createWorkspaceData?.accounts)),
    }));
  };

  const handleConfirmation = () => {
    handleSuccessToast(toastMessage);
    navigate(commonConstants.routes.dashboard);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <div className="hidden h-full w-full  md:-mx-5 md:-mt-4 md:block md:w-[calc(100%+40px)]">
      <div className="flex items-center px-5 pt-4 pb-6">
        <button className="text-gray-dark1" onClick={() => (addPages ? handleBackClick() : navigate('/dashboard'))}>
          <Icons.ChevronLeft />
        </button>
        <h2 className="text-gray-1300 ml-3.5 text-base font-semibold leading-6">
          {workspaceId ? constants.label.editWorkspace : constants.label.createWorkspace}
        </h2>
      </div>
      <div className="mb-4 flex flex-col px-5">
        <label
          htmlFor="workspaceName"
          className="mb-2 overflow-hidden text-ellipsis whitespace-nowrap text-left text-sm font-medium leading-[21px] text-gray-medium"
        >
          {constants.label.workspaceName}
        </label>
        <input
          type="text"
          id={constants.droppableFormTitles.workspaceMobileId}
          name={constants.droppableFormTitles.workspaceName}
          placeholder="Workspace Name"
          defaultValue={workspaceId ? workspaceDetailData.name : null}
          className="h-12 rounded-lg border border-solid border-gray-dark1 py-3 px-4 text-sm font-medium leading-5.5 text-gray-dark"
          onChange={event => handleWorkspaceName(event, setCreateWorkspaceData, setCreateWorkspaceFormError)}
        />
      </div>

      {addPages ? (
        <>
          <div className="px-5">
            <h2 className="mb-2 text-sm font-medium leading-[21px] text-gray-medium">Channels</h2>
          </div>
          <div className="mb-4 -mt-[20px] flex min-h-[90px] items-center gap-x-2 overflow-auto px-5">
            {pageList.length !== 0 ? (
              pageList.map((element, index) => {
                return (
                  <div key={`individualChannelHeader__${index}`}>
                    <div
                      className={` w-[223px] cursor-pointer rounded-lg border border-solid border-white200 py-3 px-3.5 hover:shadow-4xl   hover:outline-1 hover:outline-primary focus:shadow-4xl ${
                        channelMobileSelection === index ? 'channel-outline-active' : ''
                      }`}
                      onClick={() => setChannelMobileSelection(index)}
                    >
                      <div className="flex items-center">
                        <div className="flex h-7 w-7 items-center justify-center overflow-hidden   object-contain">
                          <img src={element.logo} alt={element.channelName} />
                        </div>
                        <div className="ml-2 overflow-hidden">
                          <h2 className="left-4.5 truncate text-xs font-semibold text-gray-dark" title={element.channelName}>
                            {element.channelName}
                          </h2>
                          <p className="text-[10px] font-medium leading-3.5 text-gray-medium">
                            {element.pageCount} {constants.label.pages}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                );
              })
            ) : (
              <div>{constants.infoMessages.noData}</div>
            )}
          </div>
          <div className="bg-gray-1200 h-full w-full space-x-0 overflow-auto border-t border-solid border-white200 p-4 px-5 ">
            {pageList.length !== 0 ? (
              pageList.map((element, index) => {
                return (
                  <MobileChannelsList
                    itemIndex={index}
                    data={element}
                    key={`individualMobileChannel__${index}`}
                    selectedCard={channelMobileSelection}
                    droppedData={droppedData}
                    constants={constants}
                    setCreateWorkspaceData={setCreateWorkspaceData}
                    createWorkspaceData={createWorkspaceData}
                    workspaceId={workspaceId}
                    workspaceActualInfo={workspaceActualInfo}
                  />
                );
              })
            ) : (
              <div>{constants.infoMessages.noData}</div>
            )}
          </div>
        </>
      ) : (
        <div className="grid grid-cols-3 gap-2 px-5">
          <button
            aria-label="Add Users"
            className="h-9 rounded-[26px] border-[1.5px] border-solid border-primary pb-2 pt-[5px] text-sm font-medium leading-[21px] text-primary"
            onClick={() => setIsSetupModalOpen(true)}
          >
            {constants.button.addUser}
          </button>
          <button
            aria-label="Add Pages"
            className="h-9 rounded-[26px] border-[1.5px] border-solid border-primary pb-2 pt-[5px] text-sm font-medium leading-[21px] text-primary"
            onClick={() => {
              handleAddPagesClick();
            }}
          >
            {constants.button.addPages}
          </button>
          {workspaceId && createWorkspaceData?.workspaceOwner === createWorkspaceData?.loggedinUser && (
            <button
              className={
                'flexCenter h-7.5 rounded-full border border-red-dark bg-red-light px-3.5 text-xs font-medium text-red-dark hover:bg-red-dark hover:text-white lg:justify-center xs:w-full'
              }
              onClick={() => setIsDeleteMobileConfirmOpen(true)}
            >
              <Icons.DeleteIcon strokeColour={'currentColor'} />

              <span className=" ml-1.5">Delete Workspace</span>
            </button>
          )}
        </div>
      )}

      <div className="fixed bottom-0  w-full   border-t border-solid border-white200 bg-white px-5 pt-6 pb-8 shadow-stickyBottomCard">
        {addPages ? (
          <div className="w-full">
            <button
              aria-label="Add Selected Pages"
              className={`h-11  w-full rounded-[26px] bg-primary pb-2 pt-[5px] text-sm font-medium leading-[21px] text-white 
              ${createWorkspaceData.name === '' ? 'pointer-events-none opacity-40 ' : ''}
            `}
              onClick={() => {
                setAddPages(false);
              }}
            >
              {constants.button.addSelectedPages}
            </button>
          </div>
        ) : (
          <div className="grid grid-cols-2 gap-2">
            <button
              aria-label="Cancel"
              className="h-11 rounded-[26px] border-[1.5px] border-solid border-primary pb-2 pt-[5px] text-sm font-medium leading-[21px] text-primary"
              onClick={() => {
                workspaceId
                  ? handleMobileEditCancel(
                      workspaceActualInfo,
                      setCreateWorkspaceData,
                      setDroppedData,
                      setPageList,
                      setIsFormSubmitted,
                      temp,
                      setSelectedUsers,
                      workspaceDetailData,
                      setViewOnlyUsers,
                      setIsViewerFormSubmitted,
                      setCancel,
                      setRenderViewer,
                      setExistingViewersList,
                      setRemovedViewerIds
                    )
                  : handleMobileCancel(
                      setDroppedData,
                      setCreateWorkspaceData,
                      setCreateWorkspaceFormError,
                      setWorkspaceRender,
                      workspaceRender,
                      setPageList,
                      temp,
                      setIsFormSubmitted,
                      createWorkspaceData,
                      setSelectedUsers,
                      setViewOnlyUsers,
                      setCancel,
                      createWorkspaceData.loggedinUser,
                      setIsFormSubmitted,
                      setIsViewerFormSubmitted,
                      setRenderViewer
                    );
              }}
            >
              {constants.button.cancel}
            </button>
            <button
              aria-label="Save"
              className={` h-11  rounded-[26px] bg-primary pb-2 pt-[5px] text-sm font-medium leading-[21px] text-white ${
                createWorkspaceData.name === '' ? 'pointer-events-none opacity-40 ' : ''
              } `}
              onClick={() =>
                handleSave(
                  createWorkspaceData,
                  createWorkspaceFormError,
                  handleSuccessToast,
                  handleErrorToast,
                  createWorkspaceService,
                  setDroppedData,
                  setCreateWorkspaceData,
                  setCreateWorkspaceFormError,
                  mobileview,
                  setWorkspaceRender,
                  workspaceRender,
                  setPageList,
                  temp,
                  navigate,
                  workspaceId,
                  workspaceActualInfo,
                  selectedUsers,
                  setSelectedUsers,
                  setIsFormSubmitted,
                  setIsViewerFormSubmitted,
                  setRender,
                  render,
                  userList,
                  existingViewersList,
                  newViewersList,
                  removedViewerIds,
                  removedInvitedViewerIds,
                  setRemovedInvitedViewerIds,
                  setFailedEmailIdsList,
                  setViewOnlyUsers,
                  setCancel,
                  setIsLoading,
                  setToastMessage
                )
              }
            >
              {constants.button.save}
            </button>
          </div>
        )}
      </div>
      {/* <AddUsersModal
        isSetupModalOpen={isSetupModalOpen}
        setIsSetupModalOpen={setIsSetupModalOpen}
        userList={value => setUserList(value)}
        selectedUsers={selectedUsers}

        existingViewersList={value => setExistingViewersList(value)}
        newViewersList={value => setNewViewersList(value)}
      /> */}
      <AddAdminUsersModal
        isSetupModalOpen={isSetupModalOpen}
        setIsSetupModalOpen={setIsSetupModalOpen}
        userList={value => setUserList(value)}
        createWorkspaceData={createWorkspaceData}
        workspaceId={workspaceId}
        // newedit
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        addedViewers={addedViewers}
        setAddedViewers={setAddedViewers}
        invitedViewers={invitedViewers}
        setInvitedViewers={setInvitedViewers}
        invitedViewersIds={invitedViewersIds}
        setInvitedViewersIds={setInvitedViewersIds}
        invitedViewersList={invitedViewersList}
        setInvitedViewersList={setInvitedViewersList}
        setViewOnlyUsers={setViewOnlyUsers}
        viewOnlyUsers={viewOnlyUsers}
        setCancel={setCancel}
        cancel={cancel}
        workspaceActualInfo={workspaceActualInfo}
        isFormSubmitted={isFormSubmitted}
        setIsFormSubmitted={setIsFormSubmitted}
        isViewerFormSubmitted={isViewerFormSubmitted}
        setIsViewerFormSubmitted={setIsViewerFormSubmitted}
        handleSuccessToast={handleSuccessToast}
        //viewer
        existingViewersList={value => setExistingViewersList(value)}
        newViewersList={value => setNewViewersList(value)}
        removedViewerIds={value => setRemovedViewerIds(value)}
        removedInvitedViewerIds={value => setRemovedInvitedViewerIds(value)}
        renderViewer={renderViewer}
        setRenderViewer={setRenderViewer}
      />
      {openConfirmationModal && (
        <AlertModal
          isOpen={true}
          setIsOpen={setConfirmationModal}
          heading={modalConstants.infoMessages.viewerInvitationStatusMessage}
          message={failedEmailIdsList}
          handleConfirm={() => handleConfirmation()}
          confirmAlert={modalConstants.button.ok}
          hideCancel={true}
        />
      )}
      {isDeleteMobileConfirmOpen && (
        <AlertModal
          isOpen={true}
          setIsOpen={setIsDeleteMobileConfirmOpen}
          heading={constants.label.messageHeading}
          message={constants.infoMessages.deleteWorkspaceMessage}
          handleConfirm={() => handleDeleteWorkspace(createWorkspaceData, handleSuccessToast, handleErrorToast, navigate)}
          confirmAlert={constants.button.ok}
        />
      )}
    </div>
  );
};
export default CreateWorkspaceMobileView;
