/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Select from 'react-select';
import Icons from '../../../assets/Icons';
import InputText from '../../InputText';
import useFormHandler from '../../../customHooks/useFormHandler';
import { MessageModal } from '../MessageModal';
import { ConfirmationModal } from '../ConfirmationModal';
import { AdditionalLicenseModal } from '../AdditionalLicenseModal';
import { addUserConstants, textConstants, roleLabels, permissionLabels } from './constants';
import { useToastBox } from '../../../customHooks/useToastBox';
import useAuth from '../../../customHooks/useAuth';
import Loader from '../../Loader';
import { getUserInfo } from '../../../utils/getUserInfo';

export const AddUserModal = props => {
  const userInfo = JSON.parse(getUserInfo());
  const [addUserFormData, setAddUserFormData] = useState({});
  const [messageModal, setMessageModal] = useState(false);
  const [resetMessage, setResetMessage] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [additionalLicenseModal, setAdditionalLicenseModal] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [openRoleSelector, setOpenRoleSelector] = useState({
    isOpen: false,
    optionList: [],
  });
  const [openPermissionSelector, setOpenPermissionSelector] = useState({
    isOpen: false,
    optionList: [],
  });

  const filteredOptions = props?.roleList?.filter(option => !option.isDisabled);

  const { handleSuccessToast, handleErrorToast } = useToastBox();
  const { formError, setFormError, handleEmailChange, handleAddUserSubmit } = useFormHandler(
    addUserFormData,
    setAddUserFormData
  );
  const { auth } = useAuth();

  const handleEmailSubmit = event => {
    setIsLoading(true);
    handleAddUserSubmit(
      event,
      addUserConstants.validationErrorMessages.resetError,
      addUserConstants.validationErrorMessages.email.title,
      addUserConstants.validationErrorMessages.email.required,
      auth.user.user.tenantName,
      props.isExpiringLicense
    )
      .then(() => {
        setIsLoading(false);
        setResetMessage(addUserConstants.textConstants.successMessage);
        setMessageModal(true);
        handleSuccessToast(addUserConstants.textConstants.successToast);
        setFormError(values => ({
          ...values,
          [addUserConstants.validationErrorMessages.addUserError]: '',
        }));
        props.setUserListReload(!props.userListReload);
      })
      .catch(error => {
        setIsLoading(false);

        if (error.message == 'Limit reached') {
          setConfirmModal(true);
        } else {
          if (!formError[addUserConstants.validationErrorMessages.email.title]) {
            handleErrorToast(error.message);
          }
          setFormError(values => ({
            ...values,
            [addUserConstants.validationErrorMessages.addUserError]: error.message,
          }));
        }
      });
  };

  const handleMessageModalClose = isOpen => {
    setMessageModal(isOpen);
    props.setIsOpen(isOpen);
  };

  const roleLabelSelector = channel => {
    switch (channel) {
      case textConstants.googleAnalytics:
        return roleLabels.googleAnalytics;
      case textConstants.googleMerchantCentre:
        return roleLabels.googleMerchantCentre;
      case textConstants.googleMyBusiness:
        return roleLabels.googleMyBusiness;
      case textConstants.googleTagManager:
        return roleLabels.googleTagManager;
      default:
        return 'Role';
    }
  };

  const permissionLabelSelector = channel => {
    switch (channel) {
      case textConstants.googleAnalytics:
        return permissionLabels.googleAnalytics;
      case textConstants.googleTagManager:
        return permissionLabels.googleTagManager;
      default:
        return 'Permission';
    }
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : messageModal ? (
        <MessageModal
          isOpen={messageModal}
          setIsOpen={handleMessageModalClose}
          message={resetMessage}
          setResetPasswordModalIsOpen={props.setIsOpen}
          resetPasswordIsOpen={props.isOpen}
          heading={addUserConstants.textConstants.heading}
        />
      ) : confirmModal ? (
        <ConfirmationModal
          isConfirmModalOpen={confirmModal}
          setIsConfirmModalOpen={setConfirmModal}
          handleClose={() => setConfirmModal(false)}
          handleConfirm={() => {
            setConfirmModal(false);
            if (userInfo?.user?.owner && !props.isTrialPeriod) {
              setAdditionalLicenseModal(true);
            }
          }}
          message={userInfo?.user?.owner ? 'The maximum number of licenses has been reached' : 'License Limit Exceeded'}
          subMessage={
            props.isTrialPeriod
              ? 'You will be able to purchase additional licenses once your free trial is over.'
              : userInfo?.user?.owner
              ? `To buy additional licenses click on 'Proceed'`
              : `You've reached the license limit. Please reach out to the license owner to purchase additional licenses.`
          }
          isOwner={userInfo?.user?.owner}
          isTrialPeriod={props.isTrialPeriod}
          showCancel={false}
        />
      ) : additionalLicenseModal ? (
        <AdditionalLicenseModal
          setIsOpen={setAdditionalLicenseModal}
          isOpen={additionalLicenseModal}
          setUserListReload={props.setUserListReload}
        />
      ) : (
        <Transition appear show={props.isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => {}}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-60" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center ">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-[438px] transform rounded-lg  transition-all">
                    <>
                      <Dialog.Title
                        as="div"
                        className="login-modal-header relative flex min-h-[6.5rem]  items-center justify-center overflow-hidden rounded-t-lg bg-primary300 p-4 text-black shadow-none md:min-h-[7.938rem]"
                      >
                        <div className="z-10 text-white">
                          <h2 className="mb-2 text-2xl font-bold leading-9 md:mb-3">
                            {addUserConstants.textConstants.heading}
                          </h2>
                        </div>
                        <button className="absolute top-4 right-4 z-10 text-white" onClick={() => props.setIsOpen(false)}>
                          <Icons.CloseIcon />
                        </button>
                      </Dialog.Title>
                      <div className="rounded-b-lg  bg-white p-8 md:px-4.5 md:pt-6 md:pb-10">
                        <div className="user-details-grid grid w-full grid-cols-1 gap-6 md:gap-4">
                          <InputText
                            className="flex flex-col"
                            id="email"
                            placeholder="Email Address"
                            type="email"
                            label="Email Address"
                            name="email"
                            onChange={event => {
                              setFormError(values => ({
                                ...values,
                                [addUserConstants.validationErrorMessages.addUserError]: '',
                              }));
                              handleEmailChange(event);
                              props.handleEmailInput(event?.target?.value);
                            }}
                            error={formError.email}
                          />
                        </div>
                        {formError.email ? null : (
                          <div
                            style={{
                              color: 'red',
                              fontSize: 'small',
                              alignSelf: 'self-start',
                            }}
                          >
                            {formError.addUserError}
                          </div>
                        )}
                        {props.showRoleSelector ? (
                          <>
                            <div
                              className="mt-4"
                              onClick={() =>
                                setOpenRoleSelector({
                                  isOpen: true,
                                  optionList: filteredOptions,
                                })
                              }
                            >
                              <label className="mb-2 flex w-full items-start truncate text-xs font-semibold leading-4.5 text-gray-medium">
                                {roleLabelSelector(props.selectedChannel)}
                                <svg width="6" height="6" viewBox="0 0 6 6" fill="none" xmlns="http://www.w3.org/2000/svg">
                                  <path
                                    opacity="0.8"
                                    d="M1.859 5.008L1.196 4.527L1.95 3.253L0.624 2.668L0.871 1.888L2.288 2.213L2.431 0.744H3.25L3.393 2.213L4.823 1.888L5.07 2.668L3.731 3.253L4.485 4.527L3.822 5.008L2.847 3.877L1.859 5.008Z"
                                    fill="#EF3B39"
                                  ></path>
                                </svg>
                              </label>
                              <Select
                                options={filteredOptions}
                                placeholder="Select"
                                classNamePrefix="hydra-dropdown"
                                isMulti={props.selectedChannel === textConstants.googleMerchantCentre}
                                className="add-user-select"
                                onChange={value => props.handleRoleInput(value)}
                              />
                            </div>
                            {(props.selectedChannel === textConstants.googleTagManager ||
                              props.selectedChannel === textConstants.googleAnalytics) && (
                              <div
                                className="mt-4"
                                onClick={() =>
                                  setOpenPermissionSelector({
                                    isOpen: true,
                                    optionList: props.permissionList,
                                  })
                                }
                              >
                                <label className="mb-2 flex w-full items-start truncate text-xs font-semibold leading-4.5 text-gray-medium">
                                  {permissionLabelSelector(props.selectedChannel)}
                                  {props.selectedChannel === textConstants.googleTagManager && (
                                    <svg
                                      width="6"
                                      height="6"
                                      viewBox="0 0 6 6"
                                      fill="none"
                                      xmlns="http://www.w3.org/2000/svg"
                                    >
                                      <path
                                        opacity="0.8"
                                        d="M1.859 5.008L1.196 4.527L1.95 3.253L0.624 2.668L0.871 1.888L2.288 2.213L2.431 0.744H3.25L3.393 2.213L4.823 1.888L5.07 2.668L3.731 3.253L4.485 4.527L3.822 5.008L2.847 3.877L1.859 5.008Z"
                                        fill="#EF3B39"
                                      ></path>
                                    </svg>
                                  )}
                                </label>
                                <Select
                                  options={props.permissionList}
                                  appear
                                  isMulti={props.selectedChannel === textConstants.googleAnalytics}
                                  placeholder="Select"
                                  classNamePrefix="hydra-dropdown"
                                  className="add-user-select"
                                  onChange={value => props.handlePermissionInput(value)}
                                />
                              </div>
                            )}
                          </>
                        ) : null}
                        <div className="footer-buttons grid w-full  grid-cols-2 items-center justify-between gap-2 pt-8 md:pt-8">
                          <button
                            className="btn-secondary-outline  w-full !min-w-0 text-primary hover:!bg-white hover:text-primary"
                            onClick={() => props.setIsOpen(false)}
                          >
                            {addUserConstants.textConstants.cancel}
                          </button>
                          <button
                            className="w-full !min-w-0 bg-primary text-white"
                            onClick={props.fromChannel ? props.handleSubmit : handleEmailSubmit}
                          >
                            {addUserConstants.textConstants.submit}
                          </button>
                        </div>
                      </div>
                    </>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};
