import 'react-toastify/dist/ReactToastify.css';
import { toast, Slide } from 'react-toastify';

export const useToastBox = () => {
  const handleSuccessToast = msg => {
    toast.success(msg, {
      transition: Slide,
      hideProgressbar: false,
      autoClose: 3000,
    });
  };
  const handleErrorToast = msg => {
    toast.error(msg, {
      transition: Slide,
      hideProgressbar: false,
      autoClose: 3000,
    });
  };
  const handleWarningToast = msg => {
    toast.warning(msg, {
      transition: Slide,
      hideProgressbar: false,
      autoClose: 5000,
      pauseOnHover: true,
    });
  };
  return {
    handleSuccessToast,
    handleErrorToast,
    handleWarningToast,
  };
};
