/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import CryptoJS from 'crypto-js';
import classes from './header.module.scss';
import ProfileDropdown from '../ProfileDropdown';
import Searchbar from '../Searchbar';
import Sidebar from '../Sidebar';
import { NotificationsModal } from '../NotificationsModal';
import { useOutsideClick } from '../../customHooks/useOutsideClick';
import Icons from '../../assets/Icons';
import { useNavigate } from 'react-router';
import useAuth from '../../customHooks/useAuth';
import { loginConstants } from '../Modal/LoginModal/constants';
import { constants as commonConstants } from '../../constants/constants';
import { useEffect } from 'react';
import NotificationIcon from '../../assets/Icons/notificationIcon';
import { getNotifications } from '../../services/dashboardHeaderService';
import { useContext } from 'react';
import { SocketContext } from '../../context/socketIoContext/socketIoContext';
import { useToastBox } from '../../customHooks/useToastBox';
import { getUserInfo } from '../../utils/getUserInfo';

const Header = () => {
  const currentUrl = window.location.pathname;
  const { socket, notificationCount, setNotificationCount, notifications, setNotifications } = useContext(SocketContext);

  const [isOpen, setIsOpen] = useState(false);
  const [notificationModalOpen, setNotificationModalOpen] = useState(false);
  const [isOpenProfileDP, setIsOpenProfileDP] = useState(false);
  // enable overflow hidden on body when this is enabled
  const [isOpenMobileSearch, setIsOpenMobileSearch] = useState(false);
  const closeAllDropdowns = () => {
    setIsOpenProfileDP(false);
  };
  const navigate = useNavigate();
  const { handleErrorToast } = useToastBox();

  const { auth } = useAuth();

  const userRole = auth?.user?.user?.role ? auth?.user?.user?.role : JSON.parse(getUserInfo())?.user.role;
  const userProfileImage = auth?.user?.user?.profileImage
    ? auth.user.user.profileImage
    : JSON.parse(getUserInfo())?.user.profileImage;
  const decryptedRole = CryptoJS.AES.decrypt(userRole, loginConstants.localKey).toString(CryptoJS.enc.Utf8);
  const nameInitials = auth.user?.user?.name
    .match(/(\b\S)?/g)
    .join('')
    .match(/(^\S|\S$)?/g)
    .join('')
    .toUpperCase();
  // sample outside click hook
  const ref = useOutsideClick(closeAllDropdowns);

  const onNotificationClick = () => {
    setNotificationCount(0);
  };

  useEffect(() => {
    if (!notifications?.length && window.location.pathname !== '/error') {
      getNotifications()
        .then(res => {
          setNotifications(res?.data);
        })
        .catch(error => handleErrorToast(error?.message));
    }
  }, []);

  return (
    <div
      className={`${classes.headerWrapper}  ${isOpen ? classes.active : ''} ${
        isOpenMobileSearch ? classes.SearchMobileOn : ''
      }`}
    >
      <div className={classes.navigation}>
        <button className={classes.menuIcon} aria-label="Open menu" onClick={() => setIsOpen(!isOpen)}>
          <Icons.Hamburger />
        </button>
        <div className={classes.dropdownBackdrop} />
        <div className={`${classes.mobilePanelWrapper}`}>
          <div className={classes.closeHeader}>
            <button
              className={`${classes.menuIcon} ${classes.menuIconClose}`}
              aria-label="Open menu"
              onClick={() => setIsOpen(!isOpen)}
            >
              <Icons.CloseIcon />
            </button>
          </div>

          <Sidebar classes={classes} role={decryptedRole} />
          <div className={classes.otherGrap}>
            <div className={classes.grapElement1} />
            <div className={classes.grapElement2} />
          </div>
        </div>
      </div>
      <div className={classes.companyLogo} data-testid="logo">
        <Icons.HydraLogo />
      </div>
      <div className={classes.searchSection}>
        {currentUrl !== '/error' && <Searchbar classes={classes} openMobileSearchHandle={setIsOpenMobileSearch} />}
      </div>
      <div className={classes.actionGroup}>
        <button
          className={`${classes.commonActionbtn} ${classes.helpIcon}`}
          aria-label="Support"
          onClick={() => navigate('/help')}
        >
          <Icons.Help />
        </button>
        <button
          className={`${classes.commonActionbtn} ${classes.searchToggle}`}
          aria-label="Search Bar toggle"
          onClick={() => setIsOpenMobileSearch(true)}
        >
          <Icons.Search />
        </button>
        <button
          className={classes.commonActionbtn}
          aria-label="Alert Notifications"
          onClick={() => {
            setNotificationModalOpen(!notificationModalOpen);
            onNotificationClick();
          }}
        >
          <NotificationIcon count={notificationCount} />
        </button>
        <div className={classes.profileWrapperBtn} ref={ref}>
          <button
            aria-label="Open Profile"
            className={classes.circleProfile}
            onClick={() => setIsOpenProfileDP(!isOpenProfileDP)}
          >
            {userProfileImage ? (
              <span>
                <img src={userProfileImage} alt="profile image"></img>
              </span>
            ) : (
              <span>{nameInitials}</span>
            )}
          </button>
          {isOpenProfileDP && <ProfileDropdown toggleHandle={setIsOpenProfileDP} />}
        </div>
      </div>
      <div className={classes.otherGrapMobile}>
        <div className={classes.grapElement1} />
        <div className={classes.grapElement2} />
      </div>
      <NotificationsModal
        notificationModalOpen={notificationModalOpen}
        setNotificationModalOpen={setNotificationModalOpen}
        notificationArray={notifications}
        socket={socket}
      />
    </div>
  );
};
export default Header;
