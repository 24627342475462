/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import Tippy from '@tippyjs/react';
import { Dialog, Transition } from '@headlessui/react';
import Icons from '../../../assets/Icons';
import InputText from '../../InputText';
import useFormHandler from '../../../customHooks/useFormHandler';
import { useToastBox } from '../../../customHooks/useToastBox';
import Loader from '../../Loader';
import { addAdditionalLicense } from '../../../services';
import { ConfirmationModal } from '../ConfirmationModal';
import { getUserInfo } from '../../../utils/getUserInfo';

export const RemoveLicenseModal = props => {
  const userInfo = JSON.parse(getUserInfo());
  const tenantId = userInfo?.user?.tenantId;
  const { handleSuccessToast, handleErrorToast } = useToastBox();
  const [addUserFormData, setAddUserFormData] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);

  const [loading, setIsLoading] = useState(false);

  const [count, setCount] = useState(props.userLicenseData.removableLicenses);

  const { formError } = useFormHandler(addUserFormData, setAddUserFormData);

  const handleProceed = () => {
    setConfirmModal(false);
    setIsLoading(true);
    addAdditionalLicense(count, tenantId, true)
      .then(response => {
        if (response?.status === 201 || response?.status === 200) {
          setTimeout(() => {
            props.setIsOpen(false);
            handleSuccessToast('Successfully removed additional licenses!');
            setIsLoading(false);
          }, 3000);
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.log('error', error);
        handleErrorToast('something went wrong');
      });
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Transition appear show={props.isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => {}}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-60" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center ">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-[600px] transform rounded-lg  transition-all">
                    <>
                      <Dialog.Title
                        as="div"
                        className="login-modal-header relative flex min-h-[6.5rem]  items-center justify-center overflow-hidden rounded-t-lg bg-primary300 p-4 text-black shadow-none md:min-h-[7.938rem]"
                      >
                        <div className="z-10 text-white">
                          <h2 className="mb-2 text-2xl font-bold leading-9 md:mb-3">{'Remove Additional License'}</h2>
                        </div>
                        <button className="absolute top-4 right-4 z-10 text-white" onClick={() => props.setIsOpen(false)}>
                          <Icons.CloseIcon />
                        </button>
                      </Dialog.Title>
                      <div className="rounded-b-lg  bg-white p-8 md:px-4.5 md:pt-6 md:pb-10">
                        <div className=" mb-4 flex justify-center">
                          <p className="text-base font-semibold text-gray-dark">
                            {`The Teams subscription comes with ${props.userLicenseData.base} licenses and these cannot be removed.`}
                          </p>
                        </div>

                        <div className="my-[10px]">
                          <div className=" flex  items-center justify-center gap-3">
                            <button
                              className="btn-secondary-outline flex  h-[40px]  w-[40px] !min-w-0  items-center justify-center rounded-md py-[5px] text-[20px] text-primary hover:!bg-white hover:text-primary"
                              onClick={() => setCount(count - 1)}
                              disabled={count === 0}
                            >
                              -
                            </button>
                            <div className="user-details-grid additional-license-modal-input">
                              <InputText
                                className="payment-inputs flex flex-col"
                                id="number"
                                placeholder=""
                                type="number"
                                value={count}
                                onChange={event => {
                                  if (
                                    event.target.value >= 0 &&
                                    event.target.value <= props.userLicenseData.removableLicenses
                                  ) {
                                    setCount(Number(event.target.value));
                                  }
                                }}
                              />
                            </div>
                            <button
                              className={`flex  h-[40px] w-[40px] !min-w-0 items-center justify-center rounded-md bg-primary px-[14px] py-[5px] text-[20px] text-white ${
                                count === props.userLicenseData.removableLicenses ? 'opacity-70' : ''
                              }`}
                              onClick={() => setCount(count + 1)}
                              disabled={count === props.userLicenseData.removableLicenses}
                            >
                              +
                            </button>
                          </div>
                        </div>
                        <p className="  mt-5 text-justify text-xs text-gray-dark">
                          <strong>Note: </strong>
                          {`The number of additional licenses available for removal includes both unused additional licenses and additional licenses used by users marked for 'Remove at next billing'.`}
                        </p>

                        <div className="footer-buttons grid w-full  grid-cols-2 items-center justify-between gap-2 pt-8 md:pt-8">
                          <button
                            className="btn-secondary-outline  w-full !min-w-0 text-primary hover:!bg-white hover:text-primary"
                            onClick={() => props.setIsOpen(false)}
                          >
                            {'Cancel'}
                          </button>
                          <button
                            className={`w-full !min-w-0 bg-primary text-white ${count === 0 ? 'opacity-70' : null}`}
                            onClick={() => setConfirmModal(true)}
                            disabled={count === 0}
                          >
                            {'Proceed'}
                          </button>
                        </div>
                      </div>
                    </>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
      {confirmModal && (
        <ConfirmationModal
          isConfirmModalOpen={confirmModal}
          setIsConfirmModalOpen={setConfirmModal}
          handleClose={() => setConfirmModal(false)}
          handleConfirm={() => handleProceed()}
          message={`Are you sure you want to remove ${count} additional license(s)?`}
          subMessage={`These licenses will be marked as 'Expiring licenses', and you can use them until the end of the billing cycle.`}
          isOwner={false}
          showCancel={true}
          removeConfirmation={true}
        />
      )}
    </>
  );
};
