import { SocketContext } from './socketIoContext';
import { React } from 'react';
import { socket } from './socketIoContext';
import { useState } from 'react';
import { useEffect } from 'react';
import { constants } from '../../constants/constants';
import { processTimestamp } from '../../features/Dashboard/AlertSummary/helper';
import { getUserInfo } from '../../utils/getUserInfo';

function SocketProvider(props) {
  const [notificationCount, setNotificationCount] = useState(0);
  const [notifications, setNotifications] = useState([]);
  const [alerts, setAlerts] = useState([]);
  const [newUser, setNewUser] = useState(0);

  const notificationListener = message => {
    setNotifications(arr => [message, ...arr]);
    setNotificationCount(count => count + 1);
  };

  const alertsListener = message => {
    const processedAlertsData = processTimestamp([message]);
    setAlerts(arr => [processedAlertsData[0], ...arr]);
  };

  useEffect(() => {
    const userId = JSON.parse(getUserInfo())?.user?.id;
    if (userId) {
      socket.emit(constants.connectedEvent, userId);
    }

    socket.on(constants.notificationEvent, notificationListener);

    socket.on(constants.alertEvent, alertsListener);

    return () => {
      socket.off(constants.alertEvent, alertsListener);
      socket.off(constants.notificationEvent, notificationListener);
    };
  }, [newUser]);
  return (
    <SocketContext.Provider
      value={{
        socket,
        notificationCount,
        setNotificationCount,
        notifications,
        setNotifications,
        alerts,
        setAlerts,
        newUser,
        setNewUser,
      }}
    >
      {props.children}
    </SocketContext.Provider>
  );
}

export default SocketProvider;
