import { API } from '../constants/api';
import { post } from '../utils/apiHandler';

export const twoFactorAuthentication = async (data, isForgotPassword) => {
  try {
    const response = isForgotPassword
      ? await post(`${API.verify_otp}?isForgotPassword=${true}`, data)
      : await post(API.verify_otp, data);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
