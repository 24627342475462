export const general = [
  {
    id: 1,
    heading: 'What channels are available in Hydra? ',
    content:
      ' &#x25C9 Facebook Ads \n &#x25C9 Facebook Pages \n &#x25C9 Google Ads    \n &#x25C9 Google Analytics   \n &#x25C9 Google Merchant Centre \n &#x25C9 Google Search Console   \n &#x25C9 Google Tag Manager  \n &#x25C9 LinkedIn Ads \n &#x25C9 LinkedIn Pages',
  },
  {
    id: 2,
    heading: 'What are the various access permissions in Hydra? ',
    content:
      '<table class="faq-table "><tr><td><b>Licence Owner</b> </td><td>The individual who purchased the licence and has the highest-level of permissions. They can cancel the subscription and add and remove admin users.  </td></tr><tr><td><b>Admin User</b></td><td>Can create workspaces and create admin and viewer users for workspaces, but cannot invite users into Hydra or remove other admins or cancel the subscription.</td></tr><tr><td><b>Viewer</b> </td><td>Can only view data within a workspace they have been invited to. </td></tr></table>',
  },
  {
    id: 3,
    heading: 'How do I invite a user to Hydra? ',
    content:
      'Only the Licence Owner can invite admins into Hydra via the user management dashboard. Simply click on ‘Add User’. The user management dashboard can be accessed from the left hand navigation, by clicking on ‘User’ or from the bottom of the main dashboard by clicking on ‘Manage’ in the green licence box. ',
  },
  {
    id: 4,
    heading: 'How does the alert functionality work? ',
    content:
      'After re-authenticating a channel, Hydra will notify Hydra admin users of any permission changes, or if a user has been added or removed. Hydra will identify if the change took place In-Channel, or via Hydra. Notifications will appear in the Hydra alerts panel in the dashboard and a summary of all changes will be sent via email.\n\nPlease note, we are currently unable to notify if any changes have taken place to permissions in Facebook pages.',
  },
  {
    id: 5,
    heading: 'When the License Owner cancels their subscription, what happens to the data of client admins and viewers?',
    content:
      'Upon cancellation, the data of client admins and viewers associated with that License Owner—comprising workspaces, channel accounts, and other created content—will undergo permanent deletion after the 7-day mark. Should the License Owner choose to resubscribe within this timeframe, all data, including that of client admins and viewers, will be preserved.',
  },
];

export const facebookAds = [
  {
    id: 1,
    heading: 'How do I edit user roles?',
    content: 'Hydra users can only edit roles that are associated with a Facebook business manager.',
  },
  {
    id: 2,
    heading: 'How do I add/remove a user?',
    content: `By clicking on the ‘Edit’ button, within the relevant ad account, Hydra will redirect you to the user permissions in-channel. Once you re-authenticate within Hydra, any changes you made in-channel will be reflected in Hydra and alerts are created for other admin users.`,
  },
  {
    id: 3,
    heading: 'Why can’t I see certain users in Hydra?',
    content: `Hydra only integrates with Facebook Business Manager profiles, so the user must be associated with the Business Manager to appear in Hydra. Hydra cannot currently show page roles directly in a Facebook page. We recommend, as does Facebook, that all users should be associated with a business manager.\n\nIf you see a message in Hydra stating, ‘You do not have the required admin permissions to view other users of this account,’ but you should have those permissions, you will need to request access from the business manager admin. `,
  },
];

export const googleAds = [
  {
    id: 1,
    heading: 'How do I add/remove a user or change a user’s permission? ',
    content: 'User addition, deletion, and changing permissions can be performed in Hydra.',
  },
  {
    id: 2,
    heading: 'Why can’t I see the name of an account?',
    content: `This means the account is only partially set up or the person who set-up the account did not define a name for the ad account. The default for all new Google Ad accounts is simply ‘Google Ad Account’ until a name is defined.`,
  },
  {
    id: 3,
    heading: 'Why can’t I add a user?',
    content: `Make sure the user’s email domain (e.g. @gmail.com) is allowed. To do this go into security in Google Ad settings,  then to allowed domains, by default this should be set to ‘no restrictions’. If domains are defined, simply add the new domain to the list. `,
  },
];

export const googleAnalytics = [
  {
    id: 1,
    heading: 'How do I add/remove a user or change a user’s permission? ',
    content: 'Standard roles and data restrictions can be edited in Hydra. You can also add and remove users in Hydra.  ',
  },
];

export const googleMerchantCentre = [
  {
    id: 1,
    heading: 'Why aren’t my changes appearing when I re-authenticate? ',
    content: 'There is a delay with Google Merchant Centre APIs so data updating in Hydra might take a little time. ',
  },
  {
    id: 2,
    heading: 'Are there any limitations to what I can do in Hydra with the Merchant Centre? ',
    content: `  &#x25C9 Users in sub accounts cannot be added to a main account  \n  &#x25C9 Main account users listed in sub accounts cannot be edited \n  &#x25C9 Main account users have inherited access to sub accounts`,
  },
  {
    id: 3,
    heading: 'Why can’t I add a user to a multi-client/advanced account? ',
    content: `If a user is in a sub account, they cannot be added to a multi-client/advanced account. `,
  },
  {
    id: 4,
    heading: 'Why are there users listed that I can’t edit?',
    content: `This may be because multi-client/advanced account users have inherited access to sub accounts and can’t be edited. `,
  },
  {
    id: 5,
    heading: 'Can I give a user multiple roles? ',
    content: `Yes, you can select multiple roles for a user.`,
  },
  {
    id: 6,
    heading: 'What are the different access levels?',
    content: `There are 4 access levels (source: Google Merchant Center Help):\n\n&#x25C9 <b>Standard</b> – Ability to sign in to Google Merchant Center and access everything in the account, except the 'Merchant Center programmes' tab and dashboards or reports that are restricted.\n&#x25C9 <b>Admin</b> – Standard account access, plus the ability to add, delete or edit user roles in the 'Users' tab.\n&#x25C9 <b>Email contacts</b> – No account access, but will receive emails based on preferences.\n&#x25C9 <b>Reporting manager</b> – Users with the reporting manager access level have permissions to view and edit all reports and dashboards within the account. Reporting managers may customise the level of access to reports and dashboards to other users, choosing to make those restricted or not.`,
  },
];

export const otherChannels = [
  {
    id: 1,
    heading: 'What are the limitations with these channels in Hydra?',
    content:
      'For Search Console, we can only show you what accounts you have access to. We are working with the channel in order to provide visibility on who else has access and to allow users to add and remove permissions. We can take you directly into the back end of Search Console by clicking on the ‘Redirect to Channel’ button in the dashboard.\n\nFor LinkedIn and LinkedIn Pages, we can show you what accounts and profiles you have access to, and who else has access to those assets. We can direct you straight into the backend of each channel and account by clicking on the ‘Edit’ button in the dashboard.',
  },
];
export const permissions = [
  {
    id: 1,
    heading: 'How do I set up and authenticate a channel?',
    content:
      'You can authenticate a channel by clicking on the ‘+’ icon next to the channel name from the My Profile screen. A pop-up will appear with the ‘Change profile/Add new profile’ option. It is possible to add multiple email accounts, but remember to switch between them to manage permissions associated with your chosen email address.',
  },
  {
    id: 2,
    heading: 'How can I see what permissions I have for each channel?',
    content:
      'Once you have clicked into a channel or ad account (for example, Facebook ads or Google Analytics) Your specific permissions are listed down the left hand-side of the dashboard. ',
  },
  {
    id: 3,
    heading: 'How can I see who else has a permission on a specific ad account or social channel?',
    content: 'By clicking on the name of the desired account or page, you will see the other users and what role they have.',
  },
  {
    id: 4,
    heading: 'How can I edit the permissions of a user?',
    content:
      'A permission can be changed if a pen icon appears in the top right of the user’s information in the dashboard. Click on the pen, and select the permission you wish to grant.',
  },
  {
    id: 5,
    heading: 'I don’t have admin access to an account, but I’m able to see all permissions in the account. Why?',
    content:
      'The permission list shown by Hydra is at account level. If you share access to an account with another admin user under the same License owner, then you will be able to see the permissions available for the other admin user too. You will not be able to change a permission or add and remove users if you are not an admin in the end channel.',
  },
];
export const workspace = [
  {
    id: 1,
    heading: 'What is a workspace?',
    content:
      'Workspaces group together pages and ad accounts. For example, agencies may want to group together all client profiles into a single workspace, or perhaps group together all accounts and pages managed by a Pod or project team. Enterprises and digital teams may want to group together brands or regions. Once a Workspace is created, you can give view access to members of the team who don’t need access to the end channels, but may need visibility of who does have access - for example, IST, Risk or HR may want to see who has access to a channel but does not need to work in the channels themselves.',
  },
  {
    id: 2,
    heading: 'How do I add users to a workspace?',
    content:
      'You can add new users from the ‘Add/edit users’ button in the create workspace window. There are 2 types of users that can be added. \n  &#x25C9 <b>Workspace Admins</b>: Admins are able to invite new users to a workspace, add and remove ad accounts and pages to the workspace and rename the workspace. \n  &#x25C9 <b>Viewers</b>: Viewers will only be able to view who has access to each of the pages and ad accounts added to a workspace.',
  },
];

export const reports = [
  {
    id: 1,
    heading: 'How do I get a report of my channel activity?',
    content:
      'There is a ‘Report’ section in Hydra in the right hand navigation panel where you can download a report of your channel activity for a selected time period or set-up a recurring report and manage the distribution list. Please note, only email addresses with a Hydra licence may be added to a distribution list.',
  },
];

export const payments = [
  {
    id: 1,
    heading: 'How do I cancel my subscription?',
    content:
      'You can cancel your subscription from the ‘Billing Information’ section, found under your profile image, top right.',
  },
  {
    id: 2,
    heading: 'How do I change my payment method?',
    content: `You can change your payment method in the Billing Information section.`,
  },
  {
    id: 3,
    heading: 'What payment methods do you accept?',
    content: 'Most major credit and debit cards.',
  },
  {
    id: 4,
    heading: 'How do I sign-up for an Enterprise Licence?',
    content:
      'Click on the link on the website homepage to ‘contact sales’ and you can select a convenient time to have an initial call. When you are ready to proceed, we will send you a contract and once signed, you will receive an invite to Hydra where you can set-up your profile and invite your team.',
  },
  {
    id: 5,
    heading: 'Can I upgrade my Individual Licence to a Teams Licence?',
    content:
      'Absolutely, but you are unable to do this in Hydra currently. Create a ticket using our ‘Help’ interface or call our office number and someone will be delighted to help you.',
  },
  {
    id: 6,
    heading: 'Can I upgrade my Teams Licence to an Enterprise Licence? ',
    content:
      'Absolutely, but you are unable to do this in Hydra currently. Create a ticket using our ‘Help’ interface or call our office number and someone will be delighted to help you.',
  },
  {
    id: 7,
    heading: 'Can I buy more slots for my Teams Licence?',
    content:
      'Not at this moment, but we will be rolling out the ability to add more slots to a Teams Licence shortly. You can of course buy an additional Teams License for 10 more slots, although you will need to use an alternative email address or upgrade to an Enterprise Licence, which has unlimited users.',
  },
  {
    id: 8,
    heading: 'When I try to buy a licence, I see a message stating that my email is already in use?',
    content:
      'This means that your email address is already associated with an instance of Hydra. You will need to request removal from the existing instance, before you can buy a licence using that email.',
  },
  {
    id: 9,
    heading:
      'My agency invited me into their instance of Hydra so I could grant them access to our channels. I now wish to buy my own instance of Hydra to use in my own business but I am unable to use my email address for the new licence?',
    content:
      'You will need to raise a ticket with Hydra, so someone from our support team can remove you from your agency’s instance of Hydra.',
  },
];

export const proration = [
  {
    id: 1,
    heading: 'What is the maximum number of licenses I can purchase?',
    content: 'You can purchase up to 100 licenses.',
  },
  {
    id: 2,
    heading: 'How does proration work when adding licenses?',
    content: `If you add licenses during your billing cycle, you'll only be charged for the remaining days of the current cycle. 
    `,
  },
  {
    id: 3,
    heading: 'What happens if I remove licenses mid-cycle?',
    content: `When you remove licenses, you won't be charged for the unused portion of the billing cycle. This means you won't incur charges for the time you didn't utilize.`,
  },
  {
    id: 4,
    heading: 'Can I make changes to my licenses at any time?',
    content: `Absolutely! Hydra provides the flexibility to add or remove licenses whenever you need. Our proration system ensures your billing aligns with your current usage.
    `,
  },
  {
    id: 5,
    heading: 'Is proration automatic, or do I need to do anything?',
    content: `Proration is automatic in Hydra's billing system. When you make changes to your licenses, our system recalculates the charges accordingly, providing a hassle-free experience.`,
  },
  {
    id: 6,
    heading: 'Will I see the prorated amount on my billing statement?',
    content:
      'Yes, your billing statement will clearly show the prorated amount for any changes made mid-cycle. We believe in transparency to keep you informed about your charges.',
  },
  {
    id: 7,
    heading: 'How often can I modify my licenses',
    content: 'You can modify your licenses at any time, as frequently as your needs change.',
  },
];
