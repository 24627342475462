import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import OnSuccessModal from './OnSuccessModal';
import Icons from '../../../assets/Icons';

export const SetupChannelModal = ({ isSetupModalOpen, setIsSetupModalOpen, message, showICon }) => {
  return (
    <Transition appear show={isSetupModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={setIsSetupModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-lg transform overflow-hidden rounded-lg  transition-all">
                <Dialog.Title as="div" className={`'md:pb-2.5' bg-white px-6 pt-6 pb-2.5 md:px-5 md:pt-5 md:pb-3`}>
                  <button
                    className="absolute top-4 right-4 z-10 text-gray-dark1"
                    onClick={() => {
                      sessionStorage.setItem('showModal', false);
                      setIsSetupModalOpen(false);
                    }}
                  >
                    <Icons.CloseIcon />
                  </button>
                </Dialog.Title>

                <OnSuccessModal message={message} showIcon={showICon} />
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
