export const inboxList = [
  {
    userName: "Taylor Swift",
    userNameMin: "TS",
    mailId: "taylorswift666@gmail.com",
    date: "Yesterday",
    subject: "Latest Updates",
    workspace: "IBM Facebook",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
  {
    userName: "Chris Martin",
    userNameMin: "CM",
    mailId: "taylorswift666@gmail.com",
    date: "10/11/22",
    subject: "Latest Updates & Permissions",
    workspace: "IBM Facebook",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
  {
    userName: "Tony Stark",
    userNameMin: "TS",
    mailId: "taylorswift666@gmail.com",
    date: "10/11/22",
    subject: "Social Campaign",
    workspace: "New Workspace",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
  {
    userName: "Tony Stark",
    userNameMin: "TS",
    mailId: "taylorswift666@gmail.com",
    date: "10/11/22",
    subject: "Social Campaign",
    workspace: "New Workspace",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
  {
    userName: "Tony Stark",
    userNameMin: "TS",
    mailId: "taylorswift666@gmail.com",
    date: "10/11/22",
    subject: "Social Campaign",
    workspace: "New Workspace",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
  {
    userName: "Tony Stark",
    userNameMin: "TS",
    mailId: "taylorswift666@gmail.com",
    date: "10/11/22",
    subject: "Social Campaign",
    workspace: "New Workspace",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
  {
    userName: "Tony Stark",
    userNameMin: "TS",
    mailId: "taylorswift666@gmail.com",
    date: "10/11/22",
    subject: "Social Campaign",
    workspace: "New Workspace",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
  {
    userName: "Tony Stark",
    userNameMin: "TS",
    mailId: "taylorswift666@gmail.com",
    date: "10/11/22",
    subject: "Social Campaign",
    workspace: "New Workspace",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
];
export const sentList = [
  {
    userName: "Swift T",
    userNameMin: "ST",
    mailId: "taylorswift666@gmail.com",
    date: "Yesterday",
    subject: "Latest Updates",
    workspace: "IBM Facebook",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
  {
    userName: "Chris Martin",
    userNameMin: "CM",
    mailId: "taylorswift666@gmail.com",
    date: "10/11/22",
    subject: "Latest Updates & Permissions",
    workspace: "Blog",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
  {
    userName: "Tony Stark",
    userNameMin: "TS",
    mailId: "taylorswift666@gmail.com",
    date: "10/11/22",
    subject: "Social Campaign",
    workspace: "New Workspace",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
  {
    userName: "Tony Stark",
    userNameMin: "TS",
    mailId: "taylorswift666@gmail.com",
    date: "10/11/22",
    subject: "Social Campaign",
    workspace: "New Workspace",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
  {
    userName: "Tony Stark",
    userNameMin: "TS",
    mailId: "taylorswift666@gmail.com",
    date: "10/11/22",
    subject: "Social Campaign",
    workspace: "New Workspace",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
  {
    userName: "Tony Stark",
    userNameMin: "TS",
    mailId: "taylorswift666@gmail.com",
    date: "10/11/22",
    subject: "Social Campaign",
    workspace: "New Workspace",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
  {
    userName: "Tony Stark",
    userNameMin: "TS",
    mailId: "taylorswift666@gmail.com",
    date: "10/11/22",
    subject: "Social Campaign",
    workspace: "New Workspace",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
  {
    userName: "Tony Stark",
    userNameMin: "TS",
    mailId: "taylorswift666@gmail.com",
    date: "10/11/22",
    subject: "Social Campaign",
    workspace: "New Workspace",
    toList: [
      "steve911$gmail.com",
      "martinchris@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
      "tonystark@gmail.com",
      "tonystark@gmail.com",
      "milesken@gmail.com",
    ],
    data: "hi message",
  },
];
