import CryptoJS from 'crypto-js';
import { channelIconsConstants } from './constants';
import { loginConstants } from '../Modal/LoginModal/constants';
import { getAccountService, getAuthTokenService, getAuthUrlService } from '../../services';

const listImageUrl = data => {
  data?.forEach(channels => {
    const stringValue = channels?.isDisabled.toString();
    switch (channels.channel) {
      case channelIconsConstants.title.facebookPageTitle:
        channels.imgUrl = channelIconsConstants.icons.facebook;
        channels.title = channelIconsConstants.channelName.facebookpages;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.facebookAdsTitle:
        channels.imgUrl = channelIconsConstants.icons.facebookAds;
        channels.title = channelIconsConstants.channelName.facebookads;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.googleAnalyticsTitle:
        channels.imgUrl = channelIconsConstants.icons.googleAnalytics;
        channels.title = channelIconsConstants.channelName.ganalytics;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.googleAdsTitle:
        channels.imgUrl = channelIconsConstants.icons.googleAds;
        channels.title = channelIconsConstants.channelName.googleads;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.googleMerchantCenterTitle:
        channels.imgUrl = channelIconsConstants.icons.googleMerchantCentre;
        channels.title = channelIconsConstants.channelName.googlemerchantcenter;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.googleMyBusinessTitle:
        channels.imgUrl = channelIconsConstants.icons.googleBusinessCenter;
        channels.title = channelIconsConstants.channelName.googlemybusiness;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.googleTagmanagerTitle:
        channels.imgUrl = channelIconsConstants.icons.googleTagManager;
        channels.title = channelIconsConstants.channelName.googletagmanager;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.googleSearchConsoleTitle:
        channels.imgUrl = channelIconsConstants.icons.googleSearchConsole;
        channels.title = channelIconsConstants.channelName.googlesearchconsole;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.twitterPagesTitle:
        channels.imgUrl = channelIconsConstants.icons.twitter;
        channels.title = channelIconsConstants.channelName.twitterpages;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.twitterAdsTitle:
        channels.imgUrl = channelIconsConstants.icons.twitter;
        channels.title = channelIconsConstants.channelName.twitterads;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.linkedInPagesTitle:
        channels.imgUrl = channelIconsConstants.icons.linkedin;
        channels.title = channelIconsConstants.channelName.linkedinpages;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.linkedInAdsTitle:
        channels.imgUrl = channelIconsConstants.icons.linkedin;
        channels.title = channelIconsConstants.channelName.linkedinads;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.instagram:
        channels.imgUrl = channelIconsConstants.icons.instagram;
        channels.title = channelIconsConstants.channelName.linkedinads;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.tiktok:
        channels.imgUrl = channelIconsConstants.icons.tiktok;
        channels.title = channelIconsConstants.channelName.tiktok;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.tiktokAds:
        channels.imgUrl = channelIconsConstants.icons.tiktok;
        channels.title = channelIconsConstants.channelName.tiktokAds;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      case channelIconsConstants.title.youtube:
        channels.imgUrl = channelIconsConstants.icons.youtube;
        channels.title = channelIconsConstants.channelName.youtube;
        channels.isDisabled =
          typeof channels.isDisabled === 'boolean'
            ? CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString()
            : channels.isDisabled;
        break;
      default:
        channels.imgUrl = channelIconsConstants.icons.bing;
        channels.title = channelIconsConstants.channelName.bing;
    }
  });
  return data;
};

const authenticateChannel = async (provider, channel, title, redirectTo, workspaceId = '', workspaceName = '', pageId) => {
  const authChannel = { provider, channel, title, redirectTo, workspaceId, workspaceName, pageId };
  localStorage.setItem('authChannel', JSON.stringify(authChannel));
  const redirectUrl = await getRedirectUrl(provider);
  window.location.href = redirectUrl.data.data.url;
};

const getRedirectUrl = provider => {
  const response = getAuthUrlService(provider);
  return response;
};

const getAuthToken = (provider, authCode, verifier = '') => {
  const response = getAuthTokenService(provider, authCode, verifier);
  return response;
};

const storeAuthToken = async (response, channel, setCookie, removeCookie, userId) => {
  const oneDay = 24 * 60 * 60; // seconds in one day
  // Even though all channels have the same provider, so same token, still storing as separate channeltokens to notify backend that the user chose this respective channel
  setCookie(`${channel}Token_${userId}`, response.data.data.token.access_token, {
    path: '/',
    maxAge: oneDay,
  });

  const profileInfo = response.data.data.user;
  setCookie(`${channel}Token_${userId}_userInfo`, profileInfo, {
    path: '/',
    maxAge: oneDay,
  });
  if (response.data.data.token.token_secret) {
    setCookie(`${channel}TokenSecret_${userId}`, response.data.data.token.token_secret, {
      path: '/',
      maxAge: oneDay,
    });
  }
  // get accounts api
  const authSuccess = await getAccounts(
    channel,
    response.data.data.token.access_token,
    response.data.data.token.token_secret
  );
  if (authSuccess.data.data.length === 0) {
    removeCookie(`${channel}Token_${userId}`, { path: '/' });
    removeCookie(`${channel}Token_${userId}_userInfo`, { path: '/' });
    if (response.data.data.token.token_secret) {
      removeCookie(`${channel}TokenSecret_${userId}`, { path: '/' });
    }
  }

  const authChannelInfo = {
    accountsFound: authSuccess.data.data.length,
    profileInfo: profileInfo,
  };

  return authChannelInfo;
};

const getAccounts = (channel, token, tokenSecret = '') => {
  const response = getAccountService(channel, token, tokenSecret);
  return response;
};

const getchannelSetupMessage = (profileInfo, message, title = '') => {
  const newMessage = `Profile ${profileInfo?.firstName} ${profileInfo?.lastName ? profileInfo?.lastName : ''} ${
    profileInfo?.email ? `[${profileInfo?.email}]` : ''
  } ${message} ${title ? title : ''}`;
  return newMessage;
};

const processChannels = (channels, subbedChannelList) => {
  const updatedChannels = channels?.map(channel => {
    const shouldAuthenticate = subbedChannelList?.some(({ channelType }) => channelType === channel.channel);
    if (shouldAuthenticate) {
      channel.showAuthenticate = true;
    } else if (channel.showAuthenticate) {
      // eslint-disable-next-line no-unused-vars
      const { showAuthenticate, ...rest } = channel;
      return rest;
    }
    return channel;
  });
  return updatedChannels;
};

export { listImageUrl, authenticateChannel, getAuthToken, storeAuthToken, getchannelSetupMessage, processChannels };
