import { userTypes } from './constants';

const filterUserTypes = userData => {
  userData?.forEach(user => {
    switch (user.type) {
      case userTypes.clientAdmin:
        if (user.owner) {
          user.userType = userTypes.licenceOwner;
        } else {
          user.userType = userTypes.adminUser;
        }
        break;
      case userTypes.user:
        user.userType = userTypes.viewer;
        break;
      default:
        break;
    }
  });
  return userData;
};

const filterUserCount = users => {
  const filteredUsers = users.filter(user => user.type !== 'clientAdmin' || user.owner !== true);

  return filteredUsers.length;
};

export { filterUserTypes, filterUserCount };
