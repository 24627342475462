import moment from 'moment';
import { channelCodes, channelLabel, roleLabels, permissionLabels } from '../constants/constants';
import { useToastBox } from '../customHooks/useToastBox';
import { subbedChannelIconsConstants } from '../features/Dashboard/ChannelSubbed/constants';

export const channelLabelSelector = channel => {
  switch (channel) {
    case channelCodes.facebookAds:
      return channelLabel.facebookAds;
    case channelCodes.facebookPages:
      return channelLabel.facebookPages;
    case channelCodes.googleAnalytics:
      return channelLabel.googleAnalytics;
    case channelCodes.googleAds:
      return channelLabel.googleAds;
    case channelCodes.googleMerchantCentre:
      return channelLabel.googleMerchantCentre;
    case channelCodes.googleMyBusiness:
      return channelLabel.googleMyBusiness;
    case channelCodes.googleSearchConsole:
      return channelLabel.googleSearchConsole;
    case channelCodes.googleTagManager:
      return channelLabel.googleTagManager;
    case channelCodes.linkedinAds:
      return channelLabel.linkedinAds;
    case channelCodes.linkedinPages:
      return channelLabel.linkedinPages;
    case channelCodes.twitterAds:
      return channelLabel.twitterAds;
    default:
      return '';
  }
};

const equalityChecker = (arr1, arr2, path) => {
  if (arr1?.length !== arr2?.length) {
    return false;
  }

  for (let i = 0; i < arr1?.length; i++) {
    if (JSON.stringify(arr1[i].value) !== JSON.stringify(arr2[i].value) && path == 'channel') {
      return false;
    } else if (JSON.stringify(arr1[i].value) !== JSON.stringify(arr2[i]) && path !== 'channel') {
      return false;
    }
  }

  return true;
};

export const saveChecker = (rolePermission, data, channelType, path) => {
  if (
    channelType === channelCodes.googleMerchantCentre ||
    channelType === channelCodes.facebookAds ||
    channelType === channelCodes.facebookPages
  ) {
    return !equalityChecker(rolePermission?.role, data?.role, path);
  } else if (channelType === channelCodes.googleTagManager) {
    if (path == 'channel') {
      return (
        rolePermission?.permission?.value !== data?.permission?.value || rolePermission?.role?.value !== data?.role?.value
      );
    } else {
      return rolePermission?.permission?.value !== data?.permission[0] || rolePermission?.role?.value !== data?.role[0];
    }
  } else if (channelType === channelCodes.googleAnalytics) {
    if (path == 'channel') {
      return (
        !equalityChecker(rolePermission.permission, data.permission, path) ||
        rolePermission?.role?.value !== data?.role?.value
      );
    } else if (path == 'pageAccount') {
      return (
        !equalityChecker(rolePermission?.permission, data?.permission, path) || rolePermission?.role?.value !== data?.role
      );
    } else {
      return (
        !equalityChecker(rolePermission?.permission, data?.permission, path) || rolePermission?.role?.value !== data?.role[0]
      );
    }
  } else if (channelType === channelCodes.googleMyBusiness || channelType === channelCodes.googleAds) {
    if (path == 'channel') {
      return rolePermission?.role?.value !== data?.role?.value;
    } else {
      return rolePermission?.role?.value !== data?.role[0];
    }
  }
};

const roleLabelSelector = channel => {
  switch (channel) {
    case channelCodes.googleAnalytics:
      return roleLabels.googleAnalytics;
    case channelCodes.googleMerchantCentre:
      return roleLabels.googleMerchantCentre;
    case channelCodes.googleMyBusiness:
      return roleLabels.googleMyBusiness;
    case channelCodes.googleTagManager:
      return roleLabels.googleTagManager;
    default:
      return 'Role';
  }
};

const permissionLabelSelector = channel => {
  switch (channel) {
    case channelCodes.googleAnalytics:
      return permissionLabels.googleAnalytics;
    case channelCodes.googleTagManager:
      return permissionLabels.googleTagManager;
    default:
      return 'Permission';
  }
};

export const addAuthenticationHandler = (channel, userData) => {
  const { handleErrorToast } = useToastBox();

  if (userData?.email?.length == 0) {
    handleErrorToast('Please enter email');
    return false;
  }
  if (userData?.roles?.length == 0) {
    handleErrorToast(`Please select ${roleLabelSelector(channel).toLowerCase()}`);
    return false;
  }
  if (channel == channelCodes.googleTagManager && !userData?.permissions) {
    handleErrorToast(`Please select ${permissionLabelSelector(channel).toLowerCase()}`);
    return false;
  }
  return true;
};

export const labelDisplay = (count, channel) => {
  if (count == 1 && (channel === channelCodes.facebookPages || channel === channelCodes.linkedinPages)) {
    return 'Page';
  } else if (count > 1 && (channel === channelCodes.facebookPages || channel === channelCodes.linkedinPages)) {
    return 'Pages';
  } else if (count == 1) {
    return 'Account';
  } else if (count > 1) {
    return 'Accounts';
  }
};

export const displaySubheader = channel => {
  if (channel === channelCodes.twitterAds || channel === channelCodes.googleSearchConsole) {
    return false;
  } else {
    return true;
  }
};

export const getChannelLogo = channelType => {
  const element = { channelType: channelType, imgUrl: '' };
  switch (element.channelType) {
    case subbedChannelIconsConstants.channelType.googleAdsTitle:
      element.imgUrl = subbedChannelIconsConstants.icons.googleAds;

      break;
    case subbedChannelIconsConstants.channelType.googleAnalyticsTitle:
      element.imgUrl = subbedChannelIconsConstants.icons.googleAnalytics;

      break;
    case subbedChannelIconsConstants.channelType.googleMerchant:
      element.imgUrl = subbedChannelIconsConstants.icons.googleMerchantCentre;

      break;
    case subbedChannelIconsConstants.channelType.googleMyBusiness:
      element.imgUrl = subbedChannelIconsConstants.icons.googleBusiness;

      break;
    case subbedChannelIconsConstants.channelType.googleSearchConsole:
      element.imgUrl = subbedChannelIconsConstants.icons.googleSearchConsole;

      break;
    case subbedChannelIconsConstants.channelType.googleTagManager:
      element.imgUrl = subbedChannelIconsConstants.icons.googleTagManager;

      break;
    case subbedChannelIconsConstants.channelType.bingAds:
      element.imgUrl = subbedChannelIconsConstants.icons.bing;

      break;
    case subbedChannelIconsConstants.channelType.facebookAdTitle:
      element.imgUrl = subbedChannelIconsConstants.icons.facebookAds;

      break;
    case subbedChannelIconsConstants.channelType.facebookPagesTitle:
      element.imgUrl = subbedChannelIconsConstants.icons.facebook;

      break;
    case subbedChannelIconsConstants.channelType.linkedInAds:
      element.imgUrl = subbedChannelIconsConstants.icons.linkedin;

      break;
    case subbedChannelIconsConstants.channelType.linkedInPages:
      element.imgUrl = subbedChannelIconsConstants.icons.linkedin;

      break;
    case subbedChannelIconsConstants.channelType.twitterAds:
      element.imgUrl = subbedChannelIconsConstants.icons.twitter;

      break;
    case subbedChannelIconsConstants.channelType.twitterPages:
      element.imgUrl = subbedChannelIconsConstants.icons.twitter;
      break;
    default:
      break;
  }
  return element;
};

export const getInfoContent = channel => {
  switch (channel) {
    case channelCodes.facebookAds:
      return [
        'User roles in pages associated with the business can only be edited from Hydra.',
        'Tasks are interrelated choosing one role will automatically choose related roles.',
        'User addition and deletion cannot be performed from Hydra. The edit button will redirect to the user permissions on Facebook.',
      ];

    case channelCodes.facebookPages:
      return [
        'User roles in pages associated with business can only be edited from Hydra.',
        'Users that are not associated with the business account of a page cannot be edited from hydra.',
        'Tasks are inter-related, choosing one role will automatically choose related roles.',
        'User addition and deletion cannot be performed from Hydra.The edit button will redirect to the user permissions on Facebook.',
        'Pages not associated with a business cannot be redirected from Hydra.',
      ];

    case channelCodes.googleAnalytics:
      return [
        'Standard roles and data restrictions can be edited.Multiple values for data restriction can be chosen.',
        'User addition , deletion can be performed from Hydra.',
      ];

    case channelCodes.googleAds:
      return [
        'User addition, deletion, and updation can be performed from Hydra.',
        'For partially setup accounts, account names will not be displayed.',
      ];

    case channelCodes.googleMerchantCentre:
      return [
        'There is a delay in google merchant center APIs so data updation in Hydra might take time.',
        'User in sub account cannot be added to a main account.',
        'Main account users listed in sub account cannot be edited.',
        'Main account users have inherited access to sub accounts.',
        'Multiples roles for users can be selected.',
        'User addition,updation and deletion is possible from Hydra.',
      ];

    case channelCodes.googleMyBusiness:
      return [
        'Invited users cannot be edited.',
        'User addition , updation and deletion is possible from Hydra.',
        'There can only be one primary owner per account. Updating the primary owner status will revoke the existing primary owner status.',
      ];

    case channelCodes.googleSearchConsole:
      return [
        'User addition, deletion, and updation cannot be performed from Hydra. The redirect to account button will redirect to the user permissions on Google Search Console.',
        'User listing is not available in Hydra.',
      ];
    case channelCodes.googleTagManager:
      return [
        'There is API quota limits. So data is not fetched properly.',
        'Account access and container access can be edited.',
        'User addition , updation and deletion is possible from Hydra.',
      ];
    case channelCodes.linkedinAds:
      return [
        'User addition , deletion and updation cannot be performed from Hydra.The edit button will redirect to the user permissions on Linkedin Ads.',
        'Edit user option is feasible for the accounts owned by the authenticated user with necessary permissions.',
      ];
    case channelCodes.linkedinPages:
      return [
        'User addition , deletion and updation cannot be performed from Hydra. The edit button will redirect to the user permissions on Linkedin Page.',
        'Edit user option is feasible for the accounts owned by the authenticated user with necessary permissions.',
      ];
    case channelCodes.twitterAds:
      return ['Channel coming soon'];
    default:
      return [];
  }
};

export const displayBanner = trialEndDate => {
  const trialEndPeriod = moment(trialEndDate);
  const currentDate = moment();
  const duration = moment.duration(trialEndPeriod.diff(currentDate));
  const remainingHours = Math.floor(duration.asHours());
  const remainingMinutes = Math.floor(duration.asMinutes()) % 60;
  const remainingDays = Math.ceil(duration.asDays());
  if (
    (remainingDays <= 1 && remainingHours <= 0 && remainingMinutes <= 0) ||
    remainingDays < 0 ||
    remainingDays > 3 ||
    trialEndDate === 'null' ||
    trialEndDate === 'undefined' ||
    trialEndDate === null
  ) {
    return false;
  } else return true;
};

export const getBannerDisplayDuration = trialEndDate => {
  const trialEndPeriod = moment(trialEndDate);
  const currentDate = moment();

  const duration = moment.duration(trialEndPeriod.diff(currentDate));

  // Get the remaining hours and minutes
  const remainingDay = Math.ceil(duration.asDays());
  const remainingHours = Math.floor(duration.asHours());
  const remainingMinutes = Math.floor(duration.asMinutes()) % 60;

  if (remainingDay === 1 && remainingHours > 0 && remainingMinutes > 0) {
    return `${remainingHours} hours and ${remainingMinutes} minutes`;
  } else if (remainingDay === 1 && remainingHours > 0 && remainingMinutes === 0) {
    return `${remainingHours} hours`;
  } else if (remainingDay === 1 && remainingHours === 0 && remainingMinutes > 0) {
    return `${remainingMinutes} minutes`;
  } else if (remainingDay > 1) return `${remainingDay} days`;
};

export const getTrailEndDate = trialEndDate => {
  const dateTime = moment(trialEndDate);
  const formattedDate = dateTime.format('DD MMMM YYYY');
  const formattedTime = dateTime.format('hh:mm A');

  return `Your free trial will end on ${formattedDate} at  ${formattedTime}`;
};

export const getUpcomingMonth = () => {
  const currentMoment = moment();
  const nextMonth = currentMoment.add(1, 'months').format('MMMM');
  return nextMonth;
};

export const isValidISOString = str => {
  return !isNaN(Date.parse(str)) && str.endsWith('Z');
};
