import Tippy from '@tippyjs/react';
import React from 'react';
import { Link } from 'react-router-dom';
import Icons from '../../assets/Icons';
import AccessLocked from '../../assets/images/access_locked.svg';
import SetupInProgress from '../../assets/images/account_setup.svg';
import EmptyMessage from '../../assets/images/empty_messages.svg';

const NoData = ({
  message,
  showImage,
  isLinkPresent = false,
  accountLocked = false,
  setupInProgress = false,
  emptyMessage = false,
}) => {
  return (
    <div className=" flex h-full w-full items-center justify-center lg:min-h-[15vh]">
      <div className="flex h-[calc(100%-56px)] flex-col items-center justify-center ">
        {showImage && (
          <>
            {accountLocked ? (
              <img src={AccessLocked} className="  h-11/12 mb-12 w-full" alt="Access Locked" />
            ) : setupInProgress ? (
              <img src={SetupInProgress} className=" h-11/12 mb-12 w-full max-w-xs " alt="Setup in Progress" />
            ) : emptyMessage ? (
              <img src={EmptyMessage} className=" mt-12 h-full w-full " alt="No messages" />
            ) : (
              <div className=" mb-12 ">
                <Icons.NodataIcon />
              </div>
            )}
          </>
        )}
        <h2 className="item-center flex max-w-[28.125rem] text-center text-lg font-semibold leading-6 text-primary100">
          {message}
          {isLinkPresent && (
            <Tippy content="Go to" placement="bottom" arrow={true}>
              <Link to={'/profile'} className="ml-2 mt-1.5">
                <Icons.Export />
              </Link>
            </Tippy>
          )}
        </h2>
      </div>
    </div>
  );
};
export default NoData;
