import React from 'react';
import Icons from '../../assets/Icons';

const CheckBox = props => {
  return (
    <div className="-mt-[8px] flex items-center">
      <div className="checkboxCustom flex items-center">
        <input
          type="checkbox"
          data-testid="cb"
          id={props.id}
          name={props.name}
          value={props.value}
          className={props.className}
          onClick={props.onClick}
        />
        <div className="border-grey-900 focus-within:border-blue-medium flex h-5 w-5 flex-shrink-0 items-center justify-center rounded  border-[0.0625rem] ">
          <Icons.TickSmall />
        </div>
      </div>
      <label htmlFor={props.htmlFor} className="ml-3 !mb-0 !text-sm !font-medium !leading-5.5 !text-gray-500">
        {props.label}
      </label>
    </div>
  );
};

export default CheckBox;
