import { API } from '../../constants/api';
import { get } from '../../utils/apiHandler';
import { CustomError } from '../../utils/types';

export const getAccountService = async (channel, token, tokenSecret = '') => {
  let authAccountParams = '';
  try {
    if (tokenSecret !== '') {
      //twitterapi
      authAccountParams = `channel=${channel}&token=${token}&tokenSecret=${tokenSecret}`;
    } else {
      // other apis
      authAccountParams = `channel=${channel}&token=${token}`;
    }
    const response = await get(`${API.get_accounts}?${authAccountParams}`);
    return response;
  } catch (error) {
    console.log('get accounts error getaccountsservice', error);
    throw new CustomError(error?.message, error?.code);
  }
};
