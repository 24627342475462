export const addUserConstants = {
  textConstants: {
    heading: 'Add user',
    submit: 'Add User',
    cancel: 'Cancel',
    successMessage: 'User added successfully. An invitation link will be sent to the email address.',
    successToast: 'Invitation Send',
  },
  validationErrorMessages: {
    emptyString: '',
    addUserError: 'addUserError',

    email: {
      required: 'Please enter a  email address',
      validateError: 'Please enter a valid email address',
      title: 'email',
    },
  },
};

export const textConstants = {
  googleTagManager: 'googletagmanager',
  googleAnalytics: 'ganalytics',
  googleSearchConsole: 'googlesearchconsole',
  googleMyBusiness: 'googlemybusiness',
  facebookAds: 'facebookads',
  facebookPages: 'facebookpages',
  linkedinPages: 'linkedinpages',
  linkedinAds: 'linkedinads',
  addUser: 'Add User',
  twitterAds: 'twitterads',
  googleAds: 'googleads',
  googleMerchantCentre: 'googlemerchantcenter',
  noData: 'No permissions available.',
  alertMessage: 'Are you sure you want to remove the user from ',
  alertTitle: 'Alert!',
  requestToRemove: 'You do not have the right access. Request to remove the user?',
  removeError: 'The caller does not have permission',
};

export const roleLabels = {
  googleAnalytics: 'Standard roles',
  googleMerchantCentre: 'User access',
  googleMyBusiness: 'Access',
  googleTagManager: 'Account access',
};

export const permissionLabels = {
  googleAnalytics: 'Data restrictions (GA4 properties only):',
  googleTagManager: 'Container access',
};
