import config from '../config';

export const BASE_URL = config.DEVELOPMENT.REACT_APP_HYDRA_BASE_URL;

export const SOCKET_IO_URL = config.DEVELOPMENT.SOCKET_IO_BASE_URL;

export const constants = {
  UrlCode: 'code',
  UrlToken: 'token',

  customMessages: {
    refreshRequired: 'Primary ownership changed successfully. Changes require channel refresh.',
  },
  error: {
    undefined: "Cannot read properties of undefined (reading 'data')",
    undefinedMessage: 'Something went wrong',
    tokenExpired: 'Token expired',
    autheticateChannel: 'Authenticating to continue',
    refreshChannel: 'Refreshing to continue',
    noPermission: 'The caller does not have permission',
    tokenNotFound: 'Token not found',
    refreshRequired: 'Changes require channel refresh. Refresh channel and try again',
    addedInvitationSent: 'Invitation has been sent to user. The user will be listed here when he accepts the invitation',
    serverError: 'Internal server Error',
    blockedUser: 'Your account is blocked please contact customer care',
    editPermission: `User permissions can be updated only with edit permission. Please check and authenticate channel.`,
  },
  errorCode: {
    tokenExpired: 401,
  },
  routes: {
    dashboard: '/dashboard',
    userManagement: '/userManagement',
    createWorkspace: '/createWorkspace',
    searchResults: '/search-results',
  },
  externalLinks: [
    '/reset-password',
    '/auth/reset-password',
    '/invitation',
    '/google/callback',
    '/facebook/callback',
    '/payment-confirmation',
    '/payment',
    '/privacyPolicy',
    '/TermsAndConditions',
    '/disclaimer',
    '/siteMap',
    '/aboutUs',
    '/meetTheTeam',
    '/joinTeam',
    '/error',
    '/signup',
  ],
  localStorageUser: 'userInfo',
  localStorageWorkspaceInfo: 'createWorkspaceInfo',
  notificationEvent: 'notification',
  alertEvent: 'alert',
  connectedEvent: 'connected',
  unauthorizedCode: 401,
  unauthorizedMessage: 'Please authenticate',
};

export const subscriptionPlans = {
  individual: 'individual',
  team: 'team',
  enterprise: 'enterprise',
  annual: 'annual',
};

export const channelCodes = {
  googleTagManager: 'googletagmanager',
  googleAnalytics: 'ganalytics',
  googleSearchConsole: 'googlesearchconsole',
  googleMyBusiness: 'googlemybusiness',
  facebookAds: 'facebookads',
  facebookPages: 'facebookpages',
  linkedinPages: 'linkedinpages',
  linkedinAds: 'linkedinads',
  twitterAds: 'twitterads',
  googleAds: 'googleads',
  googleMerchantCentre: 'googlemerchantcenter',
  instagram: 'instagram',
  tiktok: 'tiktok',
  tiktokAds: 'tiktokAds',
  youtubeAds: 'youtubeAds',
};

export const channelLabel = {
  googleTagManager: 'Google Tag Manager',
  googleAnalytics: 'Google Analytics',
  googleSearchConsole: 'Google Search Console',
  googleMyBusiness: 'Google My Business',
  facebookAds: 'Facebook Ads',
  facebookPages: 'Facebook Pages',
  linkedinPages: 'Linkedin Pages',
  linkedInPages: 'LinkedIn Pages',
  linkedinAds: 'Linkedin Ads',
  linkedInAds: 'LinkedIn Ads',
  twitterAds: 'Twitter Ads',
  googleAds: 'Google Ads',
  googleMerchantCentre: 'Google Merchant Center',
};

export const roleLabels = {
  googleAnalytics: 'Standard roles',
  googleMerchantCentre: 'User access',
  googleMyBusiness: 'Access',
  googleTagManager: 'Account access',
};

export const permissionLabels = {
  googleAnalytics: 'Data restrictions (GA4 properties only):',
  googleTagManager: 'Container access',
};

export const ACCEPTED_IMAGE_TYPES = ['image/jpeg', 'image/png', 'image/svg+xml', 'image/webp'];
