import { API } from '../../constants/api';
import { post } from '../../utils/apiHandler';

export const generateReportService = async reportData => {
  let reportPage = [];
  await reportData?.page?.forEach(page => {
    if (page.value !== 'All') {
      reportPage.push(page.value);
    }
  });

  const postData = {
    channel: reportData?.channel?.value,
    from: reportData?.from,
    page: reportPage,
    reportType: reportData?.reportType,
    to: reportData?.to,
  };
  try {
    const response = await post(API.generateReport, postData);

    return response;
    // return dummyData;
  } catch (error) {
    throw new Error(error?.message);
  }
};
