import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Icons from '../../assets/Icons';
import { useNavigate } from 'react-router';
import { formatDateTime } from './helper';
import NoData from '../Nodata';
export const NotificationsModal = ({ notificationModalOpen, setNotificationModalOpen, notificationArray }) => {
  const navigate = useNavigate();

  return (
    <Transition appear show={notificationModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10 md:z-[5]" onClose={setNotificationModalOpen}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="custom-vertical-scrollbar fixed inset-0 overflow-y-auto ">
          <div className="flex h-screen justify-end md:relative">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="h-full w-full max-w-[385px] transform overflow-hidden bg-white  transition-all md:absolute md:bottom-0 md:h-[calc(100%-72px)] md:max-w-full">
                <Dialog.Title
                  as="div"
                  className="item-center justify-space-between relative mb-4 flex w-full bg-white p-4 md:border-b md:border-solid md:border-white200"
                >
                  <div className="w-full">
                    <h2 className="text-[15px] font-medium leading-4.5 text-gray-medium md:text-base md:font-semibold md:leading-6 md:text-gray-dark ">
                      Notifications
                    </h2>
                  </div>
                  <div className="flex items-center md:hidden">
                    <button className="mr-4 hidden text-xs font-medium leading-4.5 text-primary">Filter</button>

                    <button className=" text-gray-dark1" onClick={() => setNotificationModalOpen(false)}>
                      <Icons.CloseIcon />
                    </button>
                  </div>
                </Dialog.Title>
                <div className="mr-2 h-[calc(100%-70px)] overflow-auto pl-4 pr-2 pb-2 ">
                  {notificationArray?.length == 0 ? (
                    <NoData message="No Notifications" showImage={false} />
                  ) : (
                    notificationArray?.map((item, index) => {
                      return (
                        <div
                          className="border-gray-1600 mb-4 flex w-full items-start overflow-hidden rounded-lg border border-solid bg-white300 py-4 pl-3.5 pr-4"
                          key={index}
                        >
                          <div>
                            <div
                              className={`flex h-7 w-7 items-center justify-center rounded-full border border-solid  
                            border-red-light1 bg-red-light text-red-dark`}
                            >
                              <Icons.NotificationIcon />
                            </div>
                          </div>
                          <div className="ml-3 w-full">
                            <h2 className="mb-0.5 text-xs font-semibold leading-4.5 text-gray-dark">{item.message}</h2>
                            <p className="text-xs font-normal text-gray-medium">{formatDateTime(item.timeStamp)}</p>
                            {item.details?.checkoutUrl ? (
                              <div className="mt-4 flex items-center justify-end gap-x-2 md:justify-start">
                                <button
                                  className="h-8 rounded-2xl border-[1.5px] border-solid border-primary px-4.5 pt-1 pb-1 text-xs font-semibold leading-4.5 text-primary xs:w-full"
                                  onClick={() => {
                                    window.location.href = item.details?.checkoutUrl;
                                  }}
                                >
                                  Update Subscription;
                                </button>
                              </div>
                            ) : item.details?.channelAccountId ? (
                              <div className="mt-4 flex items-center justify-end gap-x-2 md:justify-start">
                                <button
                                  className="h-8 rounded-2xl border-[1.5px] border-solid border-primary px-4.5 pt-1 pb-1 text-xs font-semibold leading-4.5 text-primary xs:w-full"
                                  onClick={() => {
                                    navigate('/userPermissionChannelView', {
                                      state: { channelType: item?.details?.channel },
                                    });
                                  }}
                                >
                                  Go to Channel view
                                </button>
                              </div>
                            ) : item?.details?.workspace?.id && !item?.message.includes('remove') ? (
                              <div className="mt-4 flex items-center justify-end gap-x-2 md:justify-start">
                                <button
                                  className="h-8 rounded-2xl border-[1.5px] border-solid border-primary px-4.5 pt-1 pb-1 text-xs font-semibold leading-4.5 text-primary xs:w-full"
                                  onClick={() => {
                                    navigate('/workspace-detailed', {
                                      state: {
                                        id: item?.details?.workspace?.id,
                                        workspaceName: item?.details?.workspace?.name,
                                      },
                                    });
                                  }}
                                >
                                  Go to workspace details.
                                </button>
                              </div>
                            ) : item?.workspace?.workspace && !item?.message.includes('remove') ? (
                              <div className="mt-4 flex items-center justify-end gap-x-2 md:justify-start">
                                <button
                                  className="h-8 rounded-2xl border-[1.5px] border-solid border-primary px-4.5 pt-1 pb-1 text-xs font-semibold leading-4.5 text-primary xs:w-full"
                                  onClick={() => {
                                    navigate('/workspace-detailed', {
                                      state: {
                                        id: item?.workspace?.workspace?.id,
                                      },
                                    });
                                  }}
                                >
                                  Go to workspace details.
                                </button>
                              </div>
                            ) : null}
                          </div>
                        </div>
                      );
                    })
                  )}
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
