import { post, get, put } from '../utils/apiHandler';
import { API } from '../constants/api';

export const createCheckoutSession = async data => {
  try {
    return await post(`${API.checkoutSession}`, data);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const confirmPayment = async data => {
  try {
    return await post(`${API.addSubscription}`, data);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getSubscriptionDetails = async tenantId => {
  try {
    const response = await get(`${API.getSubscription}?tenantId=${tenantId}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const cancelSubscription = async (tenantId, isTrail) => {
  try {
    const response = await put(`${API.cancelSubscription}?tenantId=${tenantId}&isTrial=${isTrail}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const updatePaymentDetails = async (sessionId, tenantId, update, invoice) => {
  try {
    if (update) {
      const response = await put(`${API.updateSubscription}?sessionId=${sessionId}&tenantId=${tenantId}&invoice=${invoice}`);
      return response;
    } else {
      const response = await put(`${API.updateSubscription}?sessionId=${sessionId}&tenantId=${tenantId}`);
      return response;
    }
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const getPriceDetails = async () => {
  try {
    const response = await get(`${API.getPriceDetails}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const getInvoiceDetails = async tenantId => {
  try {
    const response = await get(`${API.getInvoiceDetails}?tenantId=${tenantId}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
