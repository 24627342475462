import FbIcon from '../../assets/images/fbIcon.svg';
import gAnalyticsIcon from '../../assets/images/gAnalytics.svg';
import gBuisnessCentre from '../../assets/images/google_my_business_logo.svg';
import gSearch from '../../assets/images/google_search_console.svg';
import gTagManager from '../../assets/images/google_tag_manager.svg';
import gAds from '../../assets/images/gAds.svg';
import twitter from '../../assets/images/twitter.svg';
import linkedin from '../../assets/images/linkedin.svg';
import bingAds from '../../assets/images/bingAds.svg';
import FbAds from '../../assets/images/facebook_ads.svg';
import gMerchantCentre from '../../assets/images/google_merchant_centre.svg';
import tiktok from '../../assets/images/tiktokIcon.svg';
import youtube from '../../assets/images/youtubeIcon.svg';
import instagram from '../../assets/images/instaIcon.svg';

export const channelIconsConstants = {
  title: {
    facebookPageTitle: 'facebookpages',
    facebookAdsTitle: 'facebookads',
    googleAnalyticsTitle: 'ganalytics',
    googleAdsTitle: 'googleads',
    googleMerchantCenterTitle: 'googlemerchantcenter',
    googleMyBusinessTitle: 'googlemybusiness',
    googleTagmanagerTitle: 'googletagmanager',
    googleSearchConsoleTitle: 'googlesearchconsole',
    twitterPagesTitle: 'twitterpages',
    twitterAdsTitle: 'twitterads',
    linkedInPagesTitle: 'linkedinpages',
    linkedInAdsTitle: 'linkedinads',
    bingTitle: 'bingads',
    linkedin: 'linkedin',
    instagram: 'instagram',
    tiktok: 'tiktok',
    tiktokAds: 'tiktokAds',
    youtube: 'youtube',
  },
  icons: {
    facebook: FbIcon,
    googleAnalytics: gAnalyticsIcon,
    googleBusinessCenter: gBuisnessCentre,
    googleSearchConsole: gSearch,
    googleTagManager: gTagManager,
    googleAds: gAds,
    bing: bingAds,
    facebookAds: FbAds,
    googleMerchantCentre: gMerchantCentre,
    twitter,
    linkedin,
    tiktok: tiktok,
    youtube: youtube,
    instagram: instagram,
  },
  channelName: {
    facebookads: 'Facebook Ads',
    facebookpages: 'Facebook Pages',
    ganalytics: 'Google Analytics',
    googleads: 'Google Ads',
    googlemerchantcenter: 'Google Merchant Center',
    googlemybusiness: 'Google My Business',
    googletagmanager: 'Google Tag Manager',
    googlesearchconsole: 'Google Search Console',
    twitterpages: 'Twitter Pages',
    twitterads: 'Twitter Ads',
    linkedinpages: 'LinkedIn Pages ',
    linkedinads: 'LinkedIn Ads',
    bingads: 'Bing Ads',
    instagram: 'Instagram',
    tiktok: 'Tiktok',
    tiktokAds: 'Tiktok Ads',
    youtube: 'Youtube',
  },
};

export const constants = {
  callBackUrl: ['/google/callback', '/facebook/callback', '/linkedin/callback', '/twitter/callback'],
  //successMessage: `Channel ${JSON.parse(localStorage.getItem('authChannel'))?.title} has been added successfully.`,
  successMessage: ` has been added successfully for  ${JSON.parse(localStorage.getItem('authChannel'))?.title} channel.`,
  //refreshMessage: `Channel ${JSON.parse(localStorage.getItem('authChannel'))?.title} has been refreshed successfully.`,
  refreshMessage: `  refreshed  for channel `,
  noAccountsAlert: 'No accounts found with this account',
  noPagesAlert: 'No pages found with this account',
  failureMessage: 'Failed to authenticate channel',
  alreadyAuthenticateMessage: 'Already authenticated channel',
  viewerSetupChannelMessage: 'Please join the channel as an admin user to authenticate it.',
  setupChannelHeading: 'Info',
  ok: 'Ok',
  viewer: 'user',
  storageConstants: {
    channelAuthModalDisplay: 'channelAuthModalDisplay',
    channelSearchCode: 'channelSearchCode',
  },
};
