import React, { useState, useCallback, Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Cropper from 'react-easy-crop';
import Icons from '../../assets/Icons';
import { profileUpload } from '../../services';
import getCroppedImg from './helper';
import { useToastBox } from '../../customHooks/useToastBox';
import { cropConstants } from './constants';
import Loader from '../Loader';

const EasyCrop = ({
  image,
  setCroppedImage,
  setShowCropDialogue,
  setprofileImageUrl,
  setAvatarChange,
  isOpen,
  setIsOpen,
}) => {
  const [isLoading, setIsLoading] = useState(false);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedAreaPixels, setCroppedAreaPixels] = useState(null);
  const { handleErrorToast } = useToastBox();

  const onZoomChange = zoom => {
    setZoom(zoom);
  };

  const onCropComplete = useCallback((croppedArea, croppedAreaPixels) => {
    setCroppedAreaPixels(croppedAreaPixels);
  }, []);

  const showCroppedImage = useCallback(async () => {
    setIsLoading(true);
    try {
      const croppedImage = await getCroppedImg(image, croppedAreaPixels);

      uploadImageToServer({ croppedImage });
    } catch (e) {
      setIsLoading(false);
      console.error(e);
    }
  }, [croppedAreaPixels, image]);

  const uploadImageToServer = props => {
    const { croppedImage } = props;

    fetch(croppedImage)
      .then(res => res.blob())
      .then(blob => {
        const file = new File([blob], croppedImage, { type: 'image/jpeg' });

        // Create a FormData object to send the file to the server
        const formData = new FormData();
        formData.append('image', file);

        profileUpload(formData)
          .then(response => {
            setIsLoading(false);
            setprofileImageUrl(response?.data?.data);
            setAvatarChange(true);
            setCroppedImage(croppedImage);
            setShowCropDialogue(false);
          })
          .catch(error => {
            console.log('error', error);
            setIsLoading(false);
            handleErrorToast(error.message);
            console.error(error);
          });
      })
      .catch(error => {
        console.error(error);
        setIsLoading(false);
      });
  };

  const handleCancel = () => {
    setCrop({});
    setShowCropDialogue(false);
  };

  return isLoading ? (
    <Loader />
  ) : (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[27.375rem] transform overflow-hidden rounded-lg  transition-all">
                <>
                  <Dialog.Title
                    as="div"
                    className=" relative flex  min-h-[6.5rem] items-center justify-center overflow-hidden bg-primary300 p-4 text-black shadow-none md:min-h-[7.938rem]"
                  >
                    <div className="z-10 text-white">
                      <h4 className="mb-2 text-2xl font-bold leading-9 md:mb-3">{cropConstants.cropImage}</h4>
                    </div>
                    <button className="absolute top-4 right-4 z-10 text-white" onClick={() => setIsOpen(false)}>
                      <Icons.CloseIcon />
                    </button>
                  </Dialog.Title>
                  <div className="relative  bg-white p-8 md:px-4.5 md:pt-6 md:pb-10">
                    <div className=" relative h-80">
                      <Cropper
                        image={image}
                        aspect={5 / 5}
                        cropShape="square"
                        zoom={zoom}
                        crop={crop}
                        onCropChange={setCrop}
                        onZoomChange={onZoomChange}
                        onCropComplete={onCropComplete}
                      ></Cropper>
                    </div>
                    <div>
                      <div className=" flex items-center justify-center overflow-hidden rounded-b-md bg-gray-light6 py-5">
                        <span className="pr-2 font-medium text-gray-medium"> {cropConstants.zoom}</span>
                        <input
                          type="range"
                          min={1}
                          max={3}
                          step={0.1}
                          value={zoom}
                          onInput={e => {
                            onZoomChange(e.target.value);
                          }}
                          style={{ width: '50%' }}
                        ></input>
                      </div>
                      <div className="footer-buttons grid w-full  grid-cols-2 items-center justify-between gap-2 pt-6">
                        <button
                          className="btn-secondary-outline  w-full !min-w-0 text-primary hover:!bg-white hover:text-primary"
                          onClick={handleCancel}
                        >
                          {cropConstants.cancel}
                        </button>
                        <button className="w-full !min-w-0 bg-primary text-white" onClick={showCroppedImage}>
                          {cropConstants.crop}
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};

export default EasyCrop;
