import React from "react";
import Icons from ".";

const notyIconStyle = {
  position: "relative",
  display: "flex"
};
const notyNumStyle = {
  position: "absolute",
  right: "-4px",
  backgroundColor: "#e41e3f",
  fontSize: "9px",
  color: "white",
  display: "inline",
  padding: "0 3px",
  top: "-6px",
  fontWeight: "bold",
  borderRadius: "20px",
  minWidth:"12px",
};
const NotificationIcon = ({ count })=> {
  return (
    <div>
      <div style={notyIconStyle}>
        {count > 0 ? <div style={notyNumStyle}>{count}</div> : null}

        <Icons.Alert/>
      </div>
    </div>
  );
}

export default NotificationIcon;