import React, { Fragment, useState, useEffect } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { resetConstants } from '../ResetPasswordModal/constants';
import InputText from '../../InputText';
import useFormHandler from '../../../customHooks/useFormHandler';
import { constants } from '../../../constants/constants';
import { verifyRegistrationLink } from '../../../services/signUpService';
import { useToastBox } from '../../../customHooks/useToastBox';
import Icons from '../../../assets/Icons';
import { signupConstants } from '../SignUpModal/constants';

export const ConfirmPasswordModal = props => {
  const [resetPasswordFormData, setResetPasswordFormData] = useState({});
  const [passwordShown, setPasswordShown] = useState(false);
  const [retypePasswordShown, setRetypePasswordShown] = useState(false);
  const [responseData, setResponseData] = useState({});
  // eslint-disable-next-line
  const [searchParams] = useSearchParams();

  const navigate = useNavigate();
  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPassword = () => {
    setRetypePasswordShown(!retypePasswordShown);
  };
  const {
    formError,
    setFormError,
    handlePasswordChange,
    handleConfirmPasswordChange,
    handlePasswordReset,
    handleVerfiyEmail,
    dynamicPasswordValidation,
  } = useFormHandler(resetPasswordFormData, setResetPasswordFormData);

  const { handleSuccessToast, handleErrorToast } = useToastBox();

  const actualToken = searchParams.get(constants.UrlToken);
  const code = searchParams.get(constants.UrlCode) ? searchParams.get(constants.UrlCode) : 0;

  const handleNewPasswordSubmit = event => {
    handlePasswordReset(
      event,
      responseData,
      resetConstants.validationErrorMessages.password.required,
      resetConstants.validationErrorMessages.email.verifyError,
      code,
      actualToken,
      props.isForgotPassword ? props.data : null
    )
      .then(() => {
        handleSuccessToast('Password reset Successfully');
        if (props.setResetPasswordModalIsOpen) {
          props.setResetPasswordModalIsOpen(false);
        } else {
          // window.history.pushState({}, null, '/');
          navigate('/lo-invitation');
        }
        setFormError(values => ({
          ...values,
          [resetConstants.validationErrorMessages.resetError]: '',
        }));
      })
      .catch(error => {
        setFormError(values => ({
          ...values,
          [resetConstants.validationErrorMessages.resetError]: error.message,
        }));
      });
  };

  const handleVerify = () => {
    if (code) {
      verifyRegistrationLink(code, actualToken)
        .then(res => {
          handleSuccessToast('Link verified succesfully');
          setResponseData(res.data.data);
        })
        .catch(err => {
          handleErrorToast(err.message);
          navigate('/');
        });
    } else {
      handleVerfiyEmail(actualToken)
        .then(res => {
          setResponseData(res.data.data);
          handleSuccessToast('Link verified succesfully');
        })
        .catch(err => {
          handleErrorToast(err.message);
          navigate('/');
        });
    }
  };

  useEffect(() => {
    if (!props.isForgotPassword) {
      handleVerify();
    }
  }, []);

  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={props.setIsOpen}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[27.375rem] transform overflow-hidden rounded-lg  transition-all">
                  {/* {otpModal === false ? ( */}

                  <>
                    <Dialog.Title
                      as="div"
                      className="login-modal-header relative flex  min-h-[6.5rem] items-center justify-center overflow-hidden bg-primary300 p-4 text-black shadow-none md:min-h-[7.938rem]"
                    >
                      <div className="z-10 text-white">
                        <h2 className="mb-2 text-2xl font-bold leading-9 md:mb-3">{resetConstants.title}</h2>
                      </div>
                      <button className="absolute top-4 right-4 z-10 text-white" onClick={() => props.setIsOpen(false)}>
                        <Icons.CloseIcon />
                      </button>
                    </Dialog.Title>
                    <div className="bg-white  p-8 md:px-4.5 md:pt-6 md:pb-10">
                      <div className="user-details-grid grid w-full grid-cols-1 gap-6 md:gap-4">
                        <div>
                          <InputText
                            className="flex flex-col"
                            id="password"
                            placeholder="Password"
                            type={passwordShown ? 'text' : 'password'}
                            label="Password"
                            isPassword={true}
                            onClick={togglePassword}
                            passwordShown={passwordShown}
                            name="password"
                            disabled={false}
                            maxLength={15}
                            onChange={event => {
                              setFormError(values => ({
                                ...values,
                                [resetConstants.validationErrorMessages.resetError]: '',
                              }));
                              handlePasswordChange(event);
                            }}
                            error={formError.password}
                          />
                          <InputText
                            className="mt-4 flex flex-col"
                            id="confirmPassword"
                            placeholder="Retype Password"
                            type={retypePasswordShown ? 'text' : 'password'}
                            label="Retype Password"
                            isPassword={true}
                            onClick={toggleConfirmPassword}
                            passwordShown={retypePasswordShown}
                            name="confirmPassword"
                            maxLength={15}
                            onChange={event => {
                              setFormError(values => ({
                                ...values,
                                [resetConstants.validationErrorMessages.resetError]: '',
                              }));
                              handleConfirmPasswordChange(event);
                            }}
                            error={formError.confirmPassword}
                            disabled={resetPasswordFormData.password ? false : true}
                          />
                        </div>
                      </div>
                      {formError.password || formError.confirmPassword ? null : (
                        <div
                          style={{
                            color: 'red',
                            fontSize: 'small',
                            alignSelf: 'self-start',
                          }}
                        >
                          {formError.resetError}
                        </div>
                      )}
                      <div className="mt-8 w-full rounded-lg bg-primaryLight p-4">
                        <p className="mb-2  overflow-hidden text-ellipsis whitespace-nowrap text-left text-xs font-semibold leading-[1.125rem] text-gray-dark">
                          {signupConstants.textConstants.passwordRequirement}
                        </p>
                        <ul className="password-container grid w-full grid-cols-1 gap-[0.375rem] text-left md:grid-cols-1">
                          {signupConstants.textConstants.passwordRequirements.map((el, i) => (
                            <>
                              <li key={i}>
                                <div
                                  className={`${
                                    dynamicPasswordValidation[el.identifier] ? 'channelTickIcon' : 'emptyTick'
                                  }  mr-2 flex `}
                                >
                                  <Icons.CircleTick />
                                  <span>{el.label}</span>
                                </div>
                              </li>
                            </>
                          ))}
                        </ul>
                      </div>

                      <div className="footer-buttons flex w-full items-center justify-between pt-8 md:pt-8">
                        <button className="w-full bg-primary text-white" onClick={handleNewPasswordSubmit}>
                          {resetConstants.shortTitle}
                        </button>
                      </div>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
