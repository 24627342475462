/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import Tippy from '@tippyjs/react';
import BreadCrumb from '../../components/BreadCrumb';
import CommonDashboard from '../../components/CommonDashboard';
import { AddUserModal } from '../../components/Modal/AddUserModal';
import { ConfirmationModal } from '../../components/Modal/ConfirmationModal';
import WorkspaceList from '../../components/EditUserPermissionPages/components/WorkspaceList';
import Icons from '../../assets/Icons';
import { navData, textConstants } from './constants';
import { workspaceDetailed } from '../../services/Workspace/workspaceDetailed';
import { getChannelDetails, workSpaceDetailedItems } from './helper';
import { useLocation } from 'react-router-dom';
import { useToastBox } from '../../customHooks/useToastBox';
import classes from './workspace.module.scss';
import { useNavigate } from 'react-router-dom';
import { constants as commonConstants } from '../../constants/constants';
import { subbedChannelIconsConstants } from '../Dashboard/ChannelSubbed/constants';
import useAuth from '../../customHooks/useAuth';
import { getAccountService } from '../../services';
import { useCookies } from 'react-cookie';
import {
  getChannelRoles,
  removeUser,
  saveUserPermissionEdit,
  addUser,
  requestToRemoveUser,
} from '../../services/userPermissionChannelViewService';
import IndividualChannel from '../../components/EditUserPermissionPages/components/IndividualChannel';
import { AlertModal } from '../../components/Modal/AlertModal';
import { loginConstants } from '../../components/Modal/LoginModal/constants';
import Loader from '../../components/Loader';
import NoData from '../../components/Nodata';
import { authenticateChannel } from '../../components/ChannelSetup/helper';
import { getUserInfo } from '../../utils/getUserInfo';
import ChannelErrorPage from '../../components/ChannelErrorPage';
import { SetupChannelModal } from '../../components/ChannelSetup/SetupChannelModal';
import { saveChecker, addAuthenticationHandler, displaySubheader } from '../../utils/helpers';
import { channelLabelSelector, getInfoContent } from '../../utils/helpers';

const WorkspacesDetailed = () => {
  const navigate = useNavigate();
  sessionStorage.setItem('page', 'WorkspaceDetailed');
  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editUser, setEditUser] = useState(0);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [requestToRemove, setRequestToRemove] = useState(false);
  const location = useLocation();
  const { handleErrorToast, handleSuccessToast } = useToastBox();
  const [workspaceDetailedList, setWorkSpaceDetailedList] = useState([]);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [setupStatus, setSetupStatus] = useState('');
  const [addUserModal, setAddUserModal] = useState(false);
  const [editUserData, setEditUserData] = useState({ email: '', roles: [] });
  const [roleList, setRoleList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [removeUserData, setRemoveUserData] = useState({});
  const [rolePermission, setRolePermission] = useState({ role: {}, permission: {} });
  const [rolePermissionLabel, setRolePermissionLabel] = useState({ role: 'Role', permission: 'Permission' });
  const [channelType, setChannelType] = useState('');
  const [redirected, setRedirected] = useState(false);
  const [channelToken, setChannelToken] = useState('');
  const [userData, setUserData] = useState({ email: '', roles: [] });
  const [isSetupModalOpen, setIsSetupModalOpen] = useState(false);
  const [redirectionURL, setRedirectionURL] = useState('');
  const [confirmModal, setConfirmModal] = useState(false);
  const [refreshConfirm, setRefreshConfirm] = useState(false);
  const [accountName, setAccountName] = useState('');
  const { auth } = useAuth();
  const [cookies] = useCookies();
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [noAccess, setNoAccess] = useState(false);

  const userInfo = JSON.parse(getUserInfo());
  const userType = auth?.user?.user?.type ? auth?.user?.user?.type : userInfo?.user?.role;
  const userId = auth?.user?.user?.id ? auth?.user?.user?.id : userInfo?.user?.id;
  const decryptedRole = CryptoJS.AES.decrypt(userType, loginConstants.localKey).toString(CryptoJS.enc.Utf8);
  const workspaceId = location?.state?.id ? location?.state?.id : sessionStorage.getItem('workspaceId', workspaceId);
  sessionStorage.setItem('workspaceId', workspaceId);
  const disableButtons = channelType == textConstants?.googleSearchConsole || channelType == textConstants?.twitterAds;

  const fromSearch = location?.state?.search;
  const fromAccount = location?.state?.fromAccount;
  const accountTitle = location?.state?.accountName;

  const workspaceName = location?.state?.workspaceName;

  const setInitialData = path => {
    if (workspaceId) {
      workspaceDetailed(workspaceId)
        .then(res => {
          navData[0].userType = res.data?.workSpace?.name;
          workSpaceDetailedItems(res.data?.workSpace).then(response => {
            setWorkSpaceDetailedList(response);
            navData[0].decryptedRole = location?.state?.name;
            navData[0].pathHistory[0].title = res.data?.adminUserCount
              ? res.data?.adminUserCount == 1
                ? `${res.data.adminUserCount} Admin User`
                : `${res.data.adminUserCount} Admin Users`
              : '0 Admin Users';

            if (selectedWorkspace === null) {
              setDefaultAccount(response[0]);
            }
            if (path == 'fromRefresh') {
              setIsSetupModalOpen(true);
            }
          });
          setIsLoading(false);
        })
        .catch(err => {
          navData[0].userType = workspaceName;
          navData[0].pathHistory[0].title = '-';
          if (err?.message == textConstants.noAccess) {
            setNoAccess(true);
          } else {
            handleErrorToast(err.message);
          }
          setIsLoading(false);
        });
    } else {
      setIsLoading(false);
    }
  };
  useEffect(() => {
    setInitialData();
  }, [navData]);

  useEffect(() => {
    if (fromAccount) {
      workspaceDetailedList?.map((value, index) => {
        if (value?.title == accountTitle) {
          setSelectedWorkspace(index);
          setAccountName(value?.title);
          setSelectedAccountId(value?.accountId);
          setRedirectionURL(value?.redirectionURL);
          onAccountClick(value);
        }
      });
    }
  }, [workspaceDetailedList]);

  const searchFunction = keyword => {
    let searchArray;
    setSearchKeyword(keyword);
    searchArray =
      keyword.length > 0
        ? workspaceDetailedList[selectedWorkspace].pagesDetails?.filter(data =>
            data.title?.toLowerCase().includes(keyword.toLowerCase().trim())
          )
        : searchArray;
    if (keyword.length > 0) {
      setSearchResults([...searchArray]);
    } else if (keyword.length == 0) {
      setSearchResults([]);
    }
  };

  const handleEditWorkspaceClick = () => {
    const createWorkspaceInfo = {
      isWorkspaceEdit: true,
      workspaceId: workspaceId,
    };
    navigate(commonConstants.routes.createWorkspace, {
      state: createWorkspaceInfo,
    });
    localStorage.setItem(commonConstants.localStorageWorkspaceInfo, JSON.stringify(createWorkspaceInfo));
  };

  const onAccountClick = data => {
    setChannelType(data?.channelType);
    const channelTokenTemp = cookies[`${data?.channelType}Token_${auth.user?.user?.id}`];
    setChannelToken(channelTokenTemp);
    getChannelRoles(data?.channelType, channelTokenTemp).then(res => {
      setRolePermissionLabel({
        ...rolePermissionLabel,
        role: res?.data?.roles?.displayName,
        permission: res?.data?.permissions?.displayName,
      });
      const userRoleList = res.data.roles.data;
      const roleOptions = [];
      userRoleList?.forEach(roles => {
        const role = {
          label: roles?.name,
          value: roles?.id,
          isDisabled:
            data?.channelType == textConstants?.facebookPages || data?.channelType == textConstants.googleAds
              ? roles?.disabled
              : false,
        };
        roleOptions.push(role);
      });
      setRoleList(roleOptions);
      let userPermissionList = null;
      if (data?.channelType === textConstants?.googleTagManager || data?.channelType === textConstants?.googleAnalytics) {
        userPermissionList = res.data.permissions.data ? res.data.permissions.data : null;
        const permissionOption = [];
        userPermissionList?.forEach(permissions => {
          const permission = { label: permissions.name, value: permissions.id };
          permissionOption.push(permission);
        });
        setPermissionList(permissionOption);
      }
    });
  };

  function showGoToChannelButton(channelType) {
    switch (channelType) {
      case textConstants.googleAds:
      case textConstants.googleAnalytics:
        return true;
      default:
        return false;
    }
  }

  const onEditClick = data => {
    if (channelType === textConstants?.googleTagManager || channelType === textConstants?.googleAnalytics) {
      if (data.permission) {
        let temp = [];
        const checkId = data.permission;
        if (checkId?.length) {
          checkId?.forEach(item => {
            let permission = permissionList.filter(r => r.value === item);
            if (permission) {
              temp?.push({ label: permission[0]?.label, value: permission[0]?.value });
            }
          });
        }

        if (temp.length) {
          setRolePermission({
            ...rolePermission,
            role: roleList.filter(role => role.value === data.role[0])[0],
            permission: channelType === textConstants?.googleTagManager ? temp[0] : temp,
          });
        } else {
          setRolePermission({
            ...rolePermission,
            role: roleList.filter(role => role.value === data.role[0])[0],
            permission: [],
          });
        }
      } else {
        setRolePermission({
          ...rolePermission,
          role: roleList.filter(role => role.value === data.role[0])[0],
          permission: [],
        });
      }
    } else if (
      channelType === textConstants?.googleMerchantCentre ||
      channelType === textConstants?.facebookAds ||
      channelType === textConstants?.facebookPages
    ) {
      let temp = [];
      const checkId = data.role;
      if (checkId?.length) {
        checkId?.forEach(item => {
          let role = roleList.filter(r => r.value === item);
          if (role?.length) {
            temp?.push({ label: role[0]?.label, value: role[0]?.value });
          }
        });
      }
      if (temp.length) {
        setRolePermission({
          ...rolePermission,
          role: temp,
          permission: {},
        });
      } else {
        setRolePermission({
          ...rolePermission,
          role: [],
          permission: {},
        });
      }
    } else {
      setRolePermission({
        ...rolePermission,
        role: roleList.filter(role => role.value === data.role[0])[0],
        permission: {},
      });
    }
  };

  const saveEditChanges = data => {
    setIsLoading(true);
    const channelDetails = getChannelDetails(data?.channelType);
    if (channelToken === undefined) {
      handleErrorToast(`${commonConstants?.error?.tokenNotFound}. ${commonConstants?.error?.autheticateChannel}`);
      setIsLoading(true);
      setTimeout(() => {
        authenticateChannel(
          channelDetails?.provider,
          data?.channelType,
          channelDetails?.title,
          '/workspace-detailed',
          workspaceId,
          navData[0].userType
        );
      }, 3500);
    } else {
      if (saveChecker(rolePermission, data, channelType, 'workspace')) {
        saveUserPermissionEdit(editUserData, channelType, data.accountId, data.channelUserId, channelToken, accountName)
          .then(response => {
            if (response?.data?.data === commonConstants?.customMessages?.refreshRequired) {
              handleSuccessToast(`${response?.data?.data}. ${commonConstants.error.refreshChannel}`);
              refreshChannel(channelDetails?.provider, channelType, channelDetails?.title, '/workspace-detailed');
            } else {
              handleSuccessToast('User Updated Successfully');
              setIsLoading(false);
              setInitialData();
            }
            setEditUser(null);
          })
          .catch(error => {
            setIsLoading(false);
            if (
              error?.message === commonConstants.error.tokenExpired ||
              // error?.code === commonConstants?.errorCode?.tokenExpired ||
              error?.message === commonConstants.error.refreshRequired
            ) {
              // onLoad();
              if (channelType === textConstants?.googleMyBusiness) {
                setRefreshConfirm(true);
              } else {
                handleErrorToast(`${error?.message}. ${commonConstants.error.autheticateChannel}`);
                setIsLoading(true);
                setTimeout(() => {
                  authenticateChannel(
                    channelDetails?.provider,
                    data?.channelType,
                    channelDetails?.title,
                    '/workspace-detailed',
                    workspaceId,
                    navData[0].userType
                  );
                }, 3500);
              }
            } else {
              setEditUser(null);
              setIsLoading(false);
              setInitialData();
              handleErrorToast(`${error?.message}`);
            }
          });
      } else {
        setIsLoading(false);
        setEditUser(null);
      }
    }
  };

  const onRoleChange = (e, data) => {
    if (channelType === textConstants?.googleTagManager) {
      setEditUserData({
        ...editUserData,
        email: data,
        roles: [e.value],
        permissions: [rolePermission.permission[0]?.value],
      });
    } else if (channelType === textConstants?.googleAnalytics) {
      let tempPermissions = [];
      rolePermission.permission?.map(element => {
        tempPermissions.push(element.value);
      });
      if (tempPermissions?.length) {
        setEditUserData({ ...editUserData, email: data, roles: [e.value], permissions: [...tempPermissions] });
      } else {
        setEditUserData({ ...editUserData, email: data, roles: [e.value], permissions: [] });
      }
    } else if (
      channelType === textConstants?.facebookAds ||
      channelType === textConstants?.facebookPages ||
      channelType === textConstants?.googleMerchantCentre
    ) {
      {
        let tempRoles = [];
        e?.map(element => {
          tempRoles.push(element.value);
        });
        if (tempRoles?.length) {
          setEditUserData({ ...editUserData, email: data, roles: [...tempRoles] });
        } else {
          setEditUserData({ ...editUserData, email: data, roles: [] });
        }
      }
    } else {
      setEditUserData({ ...editUserData, email: data, roles: [e.value] });
    }
    setRolePermission({ ...rolePermission, role: e });
  };

  const onPermissionChange = (e, data) => {
    if (channelType == textConstants?.googleAnalytics) {
      let tempPermissions = [];
      e?.map(element => {
        tempPermissions.push(element.value);
      });

      if (tempPermissions?.length) {
        setEditUserData({
          ...editUserData,
          permissions: [...tempPermissions],
          roles: [rolePermission.role.value],
          email: data,
        });
      }
    } else {
      setEditUserData({ ...editUserData, permissions: [e.value], roles: [rolePermission.role?.value], email: data });
    }
    setRolePermission({ ...rolePermission, permission: e });
  };

  const onUserRemove = data => {
    setIsOpen(true);
    setRemoveUserData(data);
  };

  const onRemoveConfirm = () => {
    const channelDetails = getChannelDetails(channelType);
    if (channelToken === undefined) {
      handleErrorToast(`${commonConstants?.error?.tokenNotFound}. ${commonConstants?.error?.autheticateChannel}`);
      setIsLoading(true);

      setTimeout(() => {
        authenticateChannel(
          channelDetails?.provider,
          channelType,
          channelDetails?.title,
          '/workspace-detailed',
          workspaceId,
          navData[0].userType
        );
      }, 3500);
    } else {
      setIsLoading(true);
      removeUser(
        channelType,
        channelToken,
        removeUserData.channelUserId,
        removeUserData.accountId,
        removeUserData.email,
        accountName
      )
        .then(res => {
          setIsLoading(false);
          setIsOpen(false);
          handleSuccessToast(res.data);
          setInitialData();
          setEditUser(0);
        })
        .catch(error => {
          setIsLoading(false);
          if (
            error?.message === commonConstants.error.tokenExpired
            // || error?.code === commonConstants?.errorCode?.tokenExpired
          ) {
            handleErrorToast(`${error?.message}. ${commonConstants.error.autheticateChannel}`);
            const channelDetails = getChannelDetails(channelType);
            setIsLoading(true);
            setTimeout(() => {
              authenticateChannel(
                channelDetails.provider,
                channelDetails?.channelType,
                channelDetails.title,
                '/workspace-detailed',
                workspaceId,
                navData[0].userType
              );
            }, 3500);
          } else {
            handleErrorToast(`${error?.message}`);
            setIsLoading(false);
            if (error.message === commonConstants.error.noPermission) {
              setRequestToRemove(true);
            }
          }
        });
    }
  };

  const handleModalInput = (data, type) => {
    let temp = { ...userData };
    switch (type) {
      case 'email':
        temp.email = data;
        break;
    }
    setUserData(temp);
  };

  const handlePermissionInput = data => {
    if (channelType == textConstants?.googleAnalytics) {
      let tempPermissions = [];
      data?.map(element => {
        tempPermissions.push(element.value);
      });

      if (tempPermissions?.length) {
        setUserData({
          ...userData,
          permissions: [...tempPermissions],
        });
      }
    } else {
      setUserData({
        ...userData,
        permissions: [data.value],
      });
    }
  };

  const handleRoleInput = data => {
    if (channelType == textConstants?.googleMerchantCentre) {
      let tempRoles = [];
      data?.map(element => {
        tempRoles.push(element.value);
      });

      if (tempRoles?.length) {
        setUserData({
          ...userData,
          roles: [...tempRoles],
        });
      }
    } else {
      setUserData({
        ...userData,
        roles: [data.value],
      });
    }
  };

  const handleSubmit = () => {
    const channelDetails = getChannelDetails(channelType);
    if (channelToken === undefined) {
      handleErrorToast(`${commonConstants?.error?.tokenNotFound}. ${commonConstants?.error?.autheticateChannel}`);
      setIsLoading(true);

      setTimeout(() => {
        authenticateChannel(
          channelDetails?.provider,
          channelType,
          channelDetails?.title,
          '/workspace-detailed',
          workspaceId,
          navData[0].userType
        );
      }, 3500);
    }
    if (addAuthenticationHandler(channelType, userData)) {
      setIsLoading(true);
      addUser(channelType, channelToken, selectedAccountId, userData, accountName)
        .then(res => {
          setIsLoading(false);
          if (res.data?.data == commonConstants.error.addedInvitationSent) {
            handleSuccessToast(commonConstants.error.addedInvitationSent);
          } else {
            handleSuccessToast('User added successfully');
          }
          setInitialData();
          setAddUserModal(false);
          setIsLoading(false);
        })
        .catch(error => {
          // setIsLoading(false);
          // handleErrorToast(err.message);
          setIsLoading(false);
          if (
            error?.message === commonConstants.error.tokenExpired
            // ||error?.code === commonConstants?.errorCode?.tokenExpired
          ) {
            handleErrorToast(`${error?.message}. ${commonConstants.error.autheticateChannel}`);
            setIsLoading(true);
            setTimeout(() => {
              authenticateChannel(
                channelDetails?.provider,
                channelType,
                channelDetails?.title,
                '/workspace-detailed',
                workspaceId,
                navData[0].userType
              );
            }, 3500);
          } else {
            handleErrorToast(`${error?.message}`);
            setIsLoading(false);
          }
        });
    }
  };

  const onRequestToRemoveConfirm = () => {
    setIsLoading(true);
    const data = {
      channel: channelType,
      channelAccountId: removeUserData?.channelAccountId,
      action: 'remove',
      channelAccountUserId: removeUserData?.id,
    };
    requestToRemoveUser(data)
      .then(() => {
        handleSuccessToast('Request to remove sent to Admin User with access.');
        setRequestToRemove(false);
        setIsLoading(false);
      })
      .catch(error => {
        handleErrorToast(error.message);
        setIsLoading(false);
      });
  };

  function showEditButton(channelType) {
    switch (channelType) {
      case textConstants?.twitterAds:
      case textConstants?.facebookAds:
      case textConstants?.facebookPages:
      case textConstants?.linkedinPages:
      case textConstants?.linkedinAds:
        return true;
      default:
        return false;
    }
  }

  const setDefaultAccount = data => {
    if (data !== undefined) {
      setAccountName(data?.title);
      setSelectedAccountId(data?.accountId);
      setRedirectionURL(data?.redirectionURL);
      setSelectedWorkspace(0);
      onAccountClick(data);
      if (channelType == textConstants.googleAds) {
        setSetupStatus(data?.setupInProgress);
      }
    }
  };

  const refreshChannel = (provider, channel, title, path) => {
    const channelDetails = getChannelDetails(channelType);
    setIsLoading(true);
    if (`${channel}Token_${userId}` in cookies) {
      const token = cookies[`${channel}Token_${userId}`];
      let tokenSecret = '';
      if (
        provider === subbedChannelIconsConstants?.provider?.twitterAdsProvider ||
        provider === subbedChannelIconsConstants?.provider?.twitterPagesProvider
      ) {
        tokenSecret = cookies[`${channel}TokenSecret_${userId}`];
      }
      getAccountService(channel, token, tokenSecret)
        .then(() => {
          setTimeout(() => {
            setInitialData('fromRefresh');
          }, 3500);
        })
        .catch(error => {
          if (
            error?.message === commonConstants.error.tokenExpired ||
            error?.code === commonConstants?.errorCode?.tokenExpired
          ) {
            handleErrorToast(`${error?.message}. ${commonConstants.error.autheticateChannel}`);
            setIsLoading(true);
            setTimeout(() => {
              authenticateChannel(
                channelDetails?.provider,
                channelType,
                channelDetails?.title,
                '/workspace-detailed',
                workspaceId,
                navData[0].userType
              );
            }, 3500);
          } else {
            handleErrorToast(`${error?.message}`);
          }
        });
    } else {
      authenticateChannel(provider, channel, title, path);
    }
  };

  useEffect(() => {
    document.addEventListener(
      'visibilitychange',
      function () {
        if (redirected) {
          setConfirmModal(true);
          setRedirected(false);
        }
      },
      { once: true }
    );
  }, [redirected]);

  const TooltipMessage = ({ channel }) => {
    return (
      <div className="max-w-[325px]">
        <div className=" flex items-center">
          <div className="flex flex-col pl-2">
            <ul className=" list-disc pl-4">
              {getInfoContent(channel)?.map((value, index) => (
                <li key={index} className=" mb-1 text-xs font-normal leading-normal text-white">
                  {value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <CommonDashboard classList="flex flex-col md:p-0 md:bg-gray-light1 md:h-screen">
      {isLoading && <Loader />}
      <BreadCrumb
        navData={navData}
        classList="bg-white"
        role={decryptedRole}
        backToPrev={true}
        prevPage={location.state?.fromDashBoard ? '/dashboard' : fromSearch ? '/search-results' : '/workspacesList'}
      >
        {decryptedRole !== 'user' && (
          <div className={classes.actionGroup}>
            {selectedWorkspace != null && !disableButtons && (
              <div className="edit-icon ml-auto flex items-center">
                {/* {showEditButton(channelType) && (
                  <Tippy
                    placement="left"
                    arrow={true}
                    content={
                      redirectionURL?.length
                        ? 'Verified by Hydra officials'
                        : channelType == textConstants?.facebookAds || channelType == textConstants?.facebookPages
                        ? 'The account cannot be edited since it is not associated with a business'
                        : 'Could not redirect'
                    }
                  >
                    <span>
                      <Icons.InfoIcon />
                    </span>
                  </Tippy>
                )} */}

                {showEditButton(channelType) && (
                  <a
                    className={`btn-outline  mr-3 flex cursor-pointer items-center justify-center px-2 text-white ${
                      redirectionURL?.length == 0 ? 'link-disabled' : null
                    }`}
                    href={redirectionURL?.length ? redirectionURL : null}
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={() => {
                      redirectionURL?.length ? setRedirected(true) : null;
                    }}
                  >
                    <span className="text-sm">Edit</span>
                  </a>
                )}

                {(workspaceDetailedList[selectedWorkspace]?.pagesDetails?.length == 0 || setupStatus) &&
                  showGoToChannelButton(channelType) && (
                    <a
                      className={`btn-outline  mr-3 flex cursor-pointer items-center justify-center px-2 text-white ${
                        redirectionURL?.length == 0 ? 'link-disabled' : null
                      }`}
                      href={redirectionURL?.length ? redirectionURL : null}
                      target="_blank"
                      rel="noopener noreferrer"
                      onClick={() => {
                        redirectionURL?.length ? setRedirected(true) : null;
                      }}
                    >
                      <span className="text-sm">Go to Channel</span>
                    </a>
                  )}

                {!showEditButton(channelType) &&
                  workspaceDetailedList[selectedWorkspace]?.pagesDetails?.length > 0 &&
                  !setupStatus && (
                    <button
                      className="btn-secondary-outline mr-2 bg-primary px-4.5 text-white md:w-full "
                      onClick={() => {
                        setAddUserModal(true);
                        setEditUser(0);
                        setUserData({ email: '', roles: [] });
                      }}
                    >
                      <span>{textConstants?.addUserChannel}</span>
                    </button>
                  )}
              </div>
            )}
            {!noAccess && (
              <button
                aria-label="Edit Workspace"
                data-testid="edit-btn"
                className="btn-outline"
                onClick={handleEditWorkspaceClick}
              >
                <span>Edit Workspace</span>
              </button>
            )}
          </div>
        )}
      </BreadCrumb>
      <div className="flex h-[calc(100%-81px)] md:h-auto md:flex-col md:overflow-visible">
        <WorkspaceList
          channelList={workspaceDetailedList}
          selectedWorkspace={selectedWorkspace}
          setSelectedWorkspace={setSelectedWorkspace}
          setSelectedAccountId={setSelectedAccountId}
          setRedirectionURL={setRedirectionURL}
          editMode={editMode}
          setEditMode={setEditMode}
          setAccountName={setAccountName}
          onAccountClick={onAccountClick}
          page="workspace"
          setSearchResults={setSearchResults}
          setSearchKeyword={setSearchKeyword}
          setSetupStatus={setSetupStatus}
        />
        <div className=" h-full w-full  overflow-auto bg-gray-light1 p-4 md:flex-col md:border-t md:border-white200">
          {displaySubheader(channelType) &&
            workspaceDetailedList[selectedWorkspace] &&
            workspaceDetailedList[selectedWorkspace]?.pagesDetails?.length !== 0 && (
              <div className="-m-4 mb-4 -mr-[20px] flex w-[calc(100%+30px)] items-center border-b border-white200 bg-white p-4 pr-4 md:flex-col md:items-start">
                <div className="flex items-center xs:flex-wrap">
                  <div className=" text-sm font-semibold capitalize text-gray-dark">{accountName ? accountName : null}</div>
                  <div className=" mx-2 h-1 w-1 rounded-full bg-slate-400"></div>
                  <div className=" mr-2 text-sm font-normal capitalize text-gray-medium">
                    {channelType ? channelLabelSelector(channelType) : null}
                  </div>

                  <Tippy placement="bottom" arrow={true} content={<TooltipMessage channel={channelType} />}>
                    <span className="  text-primary">
                      <Icons.InfoIcon />
                    </span>
                  </Tippy>
                  <div className=" mx-2 h-1 w-1 rounded-full bg-slate-400"></div>
                  <div className="text-sm  text-gray-medium">
                    {workspaceDetailedList[selectedWorkspace]?.pagesDetails?.length}{' '}
                    {workspaceDetailedList[selectedWorkspace]?.pagesDetails?.length == 1 ? 'User' : 'Users'}
                  </div>
                </div>
                <div className="relative ml-auto mt-auto flex w-[300px] items-center sm:w-full md:my-5">
                  <input
                    placeholder="search for a user"
                    type="text"
                    className=" h-8 w-full rounded-full border border-gray-medium/30 pl-2 pr-14 text-sm !outline-none placeholder:text-xs"
                    onChange={event => searchFunction(event.target.value)}
                    value={searchKeyword}
                  />
                  {searchKeyword?.length > 0 && (
                    <button
                      className="absolute right-10 top-[5px] flex items-center justify-center text-gray-dark1"
                      onClick={() => {
                        setSearchKeyword('');
                        setSearchResults([]);
                      }}
                    >
                      <div className=" h-4 w-4">
                        <Icons.CloseIcon />
                      </div>
                    </button>
                  )}
                  <button className=" absolute right-0 flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white shadow-2xl">
                    {' '}
                    <Icons.Search />
                  </button>
                </div>
              </div>
            )}
          <div className="grid grid-cols-3 gap-4 md:grid-cols-1">
            {isOpen && (
              <AlertModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                handleConfirm={() => onRemoveConfirm()}
                heading={textConstants?.alertTitle}
                message={`${textConstants?.alertMessage} ${removeUserData.accountName} ?`}
              />
            )}
            {requestToRemove && (
              <AlertModal
                isOpen={requestToRemove}
                setIsOpen={setRequestToRemove}
                handleConfirm={() => onRequestToRemoveConfirm()}
                heading={textConstants?.alertTitle}
                message={textConstants?.requestToRemove}
              />
            )}
            {workspaceDetailedList?.length !== 0 &&
            !disableButtons &&
            workspaceDetailedList[selectedWorkspace]?.pagesDetails?.length !== 0
              ? (searchResults?.length == 0 && searchKeyword?.length == 0
                  ? workspaceDetailedList[selectedWorkspace].pagesDetails
                  : searchResults
                ).map((element, index) => {
                  return (
                    <IndividualChannel
                      itemIndex={index}
                      data={element}
                      currentUser={auth.user?.user?.email}
                      key={`individualChannel__${index}`}
                      selectedWorkspace={selectedWorkspace}
                      editUser={editUser}
                      setEditUser={setEditUser}
                      saveEditChanges={saveEditChanges}
                      setEditMode={setEditMode}
                      onRoleChange={onRoleChange}
                      onPermissionChange={onPermissionChange}
                      roleList={roleList}
                      permissionList={permissionList.length ? permissionList : null}
                      onUserRemove={onUserRemove}
                      onEditClick={onEditClick}
                      rolePermission={rolePermission}
                      rolePermissionLabel={rolePermissionLabel}
                      userRole={decryptedRole}
                      channelType={element.channelType}
                    />
                  );
                })
              : null}
          </div>
          {searchResults?.length == 0 && searchKeyword?.length > 0 && (
            <NoData message={'No results found'} showImage={true} />
          )}
          {(workspaceDetailedList?.length == 0 || workspaceDetailedList[selectedWorkspace]?.pagesDetails?.length == 0) &&
            !disableButtons &&
            channelType !== textConstants.facebookPages &&
            !isLoading && (
              <NoData
                message={
                  noAccess
                    ? textConstants.noAccess
                    : workspaceDetailedList?.length == 0
                    ? textConstants.noAccounts
                    : channelType === 'googleads' && workspaceDetailedList?.length > 0 && setupStatus
                    ? textConstants.gadsNoData
                    : textConstants.noPermission
                }
                showImage={true}
                accountLocked={
                  (workspaceDetailedList?.length == 0 && !noAccess) || (channelType === 'googleads' && setupStatus)
                    ? false
                    : true
                }
                setupInProgress={channelType === 'googleads' && workspaceDetailedList?.length > 0 ? true : false}
              />
            )}
          {(disableButtons || workspaceDetailedList[selectedWorkspace]?.pagesDetails?.length == 0) &&
            !isLoading &&
            (channelType == textConstants.facebookPages || channelType == textConstants.googleSearchConsole) && (
              <ChannelErrorPage redirectionURL={redirectionURL} isFacebook={channelType == textConstants.facebookPages} />
            )}
        </div>
      </div>
      {addUserModal && <AddUserModal setIsOpen={setAddUserModal} isOpen={addUserModal} showRoleSelector={true} />}
      {addUserModal && (
        <AddUserModal
          setIsOpen={setAddUserModal}
          isOpen={addUserModal}
          showRoleSelector={true}
          roleList={roleList}
          permissionList={permissionList}
          handleEmailInput={value => handleModalInput(value, 'email')}
          handleRoleInput={value => handleRoleInput(value)}
          handlePermissionInput={value => handlePermissionInput(value)}
          fromChannel={true}
          selectedChannel={channelType}
          handleSubmit={() => handleSubmit()}
        />
      )}
      <ConfirmationModal
        isConfirmModalOpen={confirmModal}
        setIsConfirmModalOpen={setConfirmModal}
        handleClose={() => setConfirmModal(false)}
        handleConfirm={() => {
          const channelDetails = getChannelDetails(channelType);
          refreshChannel(channelDetails?.provider, channelType, channelDetails?.title, '/workspace-detailed');
        }}
        message={'Channel needs to be refreshed for the latest updates'}
        subMessage={'Do you wish to refresh channel?'}
        isOwner={false}
        showCancel={true}
      />
      <ConfirmationModal
        isConfirmModalOpen={refreshConfirm}
        setIsConfirmModalOpen={setRefreshConfirm}
        handleClose={() => setRefreshConfirm(false)}
        handleConfirm={() => {
          const channelDetails = getChannelDetails(channelType);
          authenticateChannel(
            channelDetails?.provider,
            channelType,
            channelDetails?.title,
            '/workspace-detailed',
            workspaceId,
            navData[0].userType
          );
        }}
        message={commonConstants.error.editPermission}
        subMessage={'Do you wish to authenticate channel?'}
        isOwner={false}
        showCancel={true}
      />
      <SetupChannelModal
        isSetupModalOpen={isSetupModalOpen}
        setIsSetupModalOpen={setIsSetupModalOpen}
        // constants={constants}
        message={
          'The channel has been refreshed successfully. If the latest changes have not been reflected, refresh the page after some time.'
        }
        showICon={true}
      />
    </CommonDashboard>
  );
};
export default WorkspacesDetailed;
