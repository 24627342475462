import React, { useState } from 'react';
import userImage from '../../../assets/temp-images/userImage.png';
import NoData from '../../../components/Nodata';
import { userActivity } from './constants';

const UserActivityList = ({ classes, alertsArray, isLoading }) => {
  const [displayCount, setDisplayCount] = useState(5);

  const handleLoadMore = () => {
    setDisplayCount(displayCount + 5);
  };

  return isLoading ? null : (
    <>
      {alertsArray.length === 0 && <NoData message={userActivity.noalerts} showImage={false} />}
      <ul className={classes.alertList}>
        {alertsArray?.slice(0, displayCount).map((element, index) => {
          return (
            <li key={`userActivityList_${index}`}>
              <div className={classes.alertNoti}>
                <img src={userImage} />
              </div>
              <div className={classes.alertContent}>
                <div className={classes.alertText}>
                  {element.activity === userActivity.externalUpdate && (
                    <>
                      <span>{element.firstSentence}</span>
                      {element?.userTag && (
                        <>
                          <span className={classes.userTag}>{element.userTag}</span>
                          <span>{element.secondSentence}</span>
                        </>
                      )}
                    </>
                  )}
                  {element.activity === userActivity.addUserToChannel && (
                    <>
                      <span>{element.firstSentence}</span>
                      {element?.userTag && (
                        <>
                          <span className={classes.userTag}>{element.userTag}</span>
                          <span>{element.secondSentence}</span>
                        </>
                      )}
                    </>
                  )}
                  {element.activity === userActivity.removeUserFromChannel && (
                    <>
                      <span>{element.firstSentence}</span>
                      {element?.userTag && (
                        <>
                          <span className={classes.userTag}>{element.userTag}</span>
                          <span>{element.secondSentence}</span>
                        </>
                      )}
                    </>
                  )}
                  {element.activity === userActivity.editChannelUser && (
                    <>
                      <span>{element.firstSentence}</span>
                      {element?.userTag && (
                        <>
                          <span className={classes.userTag}>{element?.userTag}</span>
                          <span>{element.secondSentence}</span>
                        </>
                      )}
                    </>
                  )}
                  {element.activity === userActivity.successSubscription && (
                    <>
                      <span>{element.message}</span>
                    </>
                  )}
                </div>
                <div className={classes.date}>{element.dateValue}</div>
              </div>
              <div className={`${classes.alertIcon} ${element.iconClass}`}>{element.icon}</div>
            </li>
          );
        })}
      </ul>
      {displayCount < alertsArray.length && (
        <div className={classes.alertLoadMore}>
          <button aria-label="Load More Alerts" className={`btn-link ${classes.viewAll}`} onClick={handleLoadMore}>
            {userActivity.loadMore}
          </button>
        </div>
      )}
    </>
  );
};
export default UserActivityList;
