import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Icons from '../../../assets/Icons';
import GoogleIcon from '../../../assets/images/google_icon.svg';
import FacebookIcon from '../../../assets/images/facebook_icon.svg';
import LinkedinIcon from '../../../assets/images/linkedin_icon.svg';

export const ConfirmationModal = ({
  isConfirmModalOpen,
  message,
  subMessage,
  handleClose,
  handleConfirm,
  isOwner,
  showCancel,
  accountWarning = false,
  channel,
  removeConfirmation = false,
  isTrialPeriod = false,
}) => {
  return (
    <Transition appear show={isConfirmModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel
                className={`w-full  transform overflow-hidden rounded-lg  transition-all ${
                  removeConfirmation ? 'max-w-[650px]' : 'max-w-[550px]'
                }`}
              >
                <Dialog.Title as="div" className={`'md:pb-2.5' bg-white px-6 pt-6 pb-2.5 md:px-5 md:pt-5 md:pb-3`}>
                  <button className="absolute top-4 right-4 z-10 text-gray-dark1" onClick={() => handleClose()}>
                    <Icons.CloseIcon />
                  </button>
                </Dialog.Title>
                <div className="bg-white px-8 pb-6">
                  <div className="alertIcon z-10 flex justify-center pb-7 text-red-700">
                    <Icons.AlertIcon />
                  </div>
                  <h4 className="mb-1 mt-4 flex justify-center text-center text-lg font-semibold leading-normal text-black">
                    {message}
                  </h4>
                  <h4 className="font-regular mb-4 flex justify-center text-center leading-normal text-gray-dark">
                    {subMessage}
                  </h4>

                  <div className="footer-buttons flex w-full items-center justify-between space-x-3 pt-8 md:pt-8 xs:flex-col xs:space-y-2 xs:space-x-0">
                    {showCancel && (
                      <button
                        className="btn-secondary-outline  w-full !min-w-0 text-primary hover:!bg-white hover:text-primary"
                        onClick={() => handleClose()}
                      >
                        {'Cancel'}
                      </button>
                    )}
                    {accountWarning ? (
                      // <button className={'btn-secondary-outline w-full  !min-w-0 text-primary'}>
                      //   <img src={GoogleIcon} />
                      //   <span className="  ml-1.5 ">Sign in with {channel}</span>
                      // </button>
                      <button
                        className={
                          'btn-secondary-outline w-full  !min-w-0 !flex-row text-primary hover:!bg-white hover:!text-primary'
                        }
                        onClick={() => {
                          handleClose();
                          handleConfirm();
                        }}
                      >
                        <img
                          className="mr-2 w-[24px]"
                          src={channel == 'Facebook' ? FacebookIcon : channel == 'Linkedin' ? LinkedinIcon : GoogleIcon}
                        />
                        Sign in with {channel}
                      </button>
                    ) : (
                      <button
                        className="w-full !min-w-0 bg-primary text-white"
                        onClick={() => {
                          handleClose();
                          handleConfirm();
                        }}
                      >
                        {isOwner && !isTrialPeriod ? 'Proceed' : 'Ok'}
                      </button>
                    )}
                  </div>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
