import { del, get, patch, post } from '../utils/apiHandler';
import { API } from '../constants/api';
import { CustomError } from '../utils/types';

export const saveUserPermissionEdit = async (data, channel, accountId, userId, token, accountName) => {
  try {
    return await patch(
      `${API.edit_channel_view_permission}?channel=${channel}&token=${token}&accountId=${accountId}&userId=${userId}&accountName=${accountName}`,
      data
    );
  } catch (error) {
    throw new CustomError(error?.message, error?.code);
  }
};

export const getUserPermissionChannelDetail = async channelName => {
  try {
    return await (
      await get(`${API.channel_permission_detail}?channelName=${channelName}&limit=10&offset=0`)
    ).data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const getChannelRoles = async (channelName, token) => {
  try {
    return await (
      await get(`${API.get_channel_roles}?channel=${channelName}&token=${token}`)
    ).data;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const removeUser = async (channelName, token, userId, accountId, email, accountName) => {
  try {
    const data = { email: email };
    return await (
      await del(`${API.remove_user}?channel=${channelName}&token=${token}&userId=${userId}&accountId=${accountId}&accountName=${accountName}`, data)
    ).data;
  } catch (error) {
    throw new CustomError(error.message, error.code);
  }
};

export const addUser = async (channelName, token, accountId, data, accountName) => {
  try {
    return await post(`${API.remove_user}?channel=${channelName}&token=${token}&accountId=${accountId}&accountName=${accountName}`, data);
  } catch (error) {
    throw new Error(error.message);
  }
};

export const requestToRemoveUser = async (data) =>{
  try {
    return (await post(`${API.request_to_remove}`,data)).data;
  } catch (error) {
    throw new Error(error.message);
  }
}
