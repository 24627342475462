import { API } from '../../constants/api';
import { get } from '../../utils/apiHandler';

export const getWorkspaceDetailService = async workspaceId => {
  try {
    const response = await get(`${API.workspace_detailed}/${workspaceId}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const getChannelAccountList = async () => {
  try {
    return await (
      await get(`${API.workspace_channel_accounts_list}`)
    ).data;
  } catch (error) {
    throw new Error(error.message);
  }
};
