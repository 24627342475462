import { loginConstants } from '../../../components/Modal/LoginModal/constants';
import { getUserInfo, setUserInfo } from '../../../utils/getUserInfo';

export const saveProfileDetails = (profileInfo, setUserData) => {
  const existingUserData = JSON.parse(getUserInfo());

  existingUserData.user[loginConstants.loginCredentials.firstName] = profileInfo.firstName;
  existingUserData.user[loginConstants.loginCredentials.lastName] = profileInfo.lastName;
  existingUserData.user[loginConstants.loginCredentials.name] = `${profileInfo.firstName} ${profileInfo.lastName}`;
  existingUserData.user[loginConstants.loginCredentials.profileImage] = profileInfo.profileImage;
  existingUserData.user[loginConstants.loginCredentials.phone] = profileInfo.mobile;
  existingUserData.user[loginConstants.loginCredentials.countryCode] = profileInfo.countryCode;
  existingUserData.user[loginConstants.loginCredentials.isMobileVerified] = profileInfo.isMobileVerified;
  setUserInfo(JSON.stringify(existingUserData));
  setUserData(existingUserData);
};
