/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import { Fragment } from 'react';
import { Dialog, Transition, Tab } from '@headlessui/react';
import { addUserList } from '../../../services/userListService';
import { viewersList } from '../../../services';
import { userList as allUsersList } from '../../../services/userListService';
import { AlertModal } from '../../../components/Modal/AlertModal';
import { SetupChannelModal } from '../../../components/ChannelSetup/SetupChannelModal';
import { ConfirmationModal } from '../../../components/Modal/ConfirmationModal';
import { AdditionalLicenseModal } from '../../../components/Modal/AdditionalLicenseModal';
import { AddViewersModal } from '../AddViewers';
import { constants } from '../constants';
import { messageConstants } from './constants';
import useAuth from '../../../customHooks/useAuth';
import Icons from '../../../assets/Icons';
import { getUserInfo } from '../../../utils/getUserInfo';
import './custom.css';
import { getSubscriptionDetails } from '../../../services/paymentService';

function classNames(...classes) {
  return classes.filter(Boolean).join(' ');
}

export const AddAdminUsersModal = ({
  isSetupModalOpen,
  setIsSetupModalOpen,
  userList,
  createWorkspaceData,
  // eslint-disable-next-line no-unused-vars
  workspaceId,
  selectedUsers,
  setSelectedUsers,
  // workspaceActualInfo,
  isFormSubmitted,
  setIsFormSubmitted,
  isViewerFormSubmitted,
  setIsViewerFormSubmitted,
  handleSuccessToast,
  //viewers
  existingViewersList,
  newViewersList,
  newViewOnlyUsersList,
  setNewViewOnlyUsersList,
  addedViewers,
  removedViewerIds,
  removedInvitedViewerIds,
  invitedViewers,
  invitedViewersIds,
  viewOnlyUsers,
  setViewOnlyUsers,
  setCancel,
  cancel,
  renderViewer,
  setRenderViewer,
  deletedViewerIds,
  setDeletedViewerIds,
  deleteInvitedViewerIds,
  setDeleteInvitedViewerIds,
}) => {
  const userInfo = JSON.parse(getUserInfo());

  const [users, setUsers] = useState([]);
  const [userListReload, setUserListReload] = useState(true);
  const [additionalLicenseModal, setAdditionalLicenseModal] = useState(false);
  const [userListing, setUserListing] = useState([]);
  const [allUsers, setAllUsers] = useState([]);
  const [refresh, setRefresh] = useState(false);
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const [isTrialPeriod, setIsTrialPeriod] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [removeAlreadyAddedUserInfo, setRemoveAlreadyAddedUserInfo] = useState({});
  //viewers
  const [viewers, setViewers] = useState([]);
  const [newViewer, setNewViewer] = useState(newViewOnlyUsersList ? newViewOnlyUsersList : []);
  const [selectedViewers, setSelectedViewers] = useState(viewOnlyUsers ? viewOnlyUsers : []);
  const [selectedViewersIds, setSelectedViewersIds] = useState([]);
  const [userLists, setUserLists] = useState([]);
  const [text, setText] = useState('');
  const [isSetupAlertModalOpen, setIsSetupAlertModalOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [isOpen, setIsOpen] = useState(false);
  // eslint-disable-next-line no-unused-vars
  const [removeUserData, setRemoveUserData] = useState({});
  const [allViewers, setAllViewers] = useState([]);
  // const [allOriginalViewers, setAllOriginalViewers] = useState(addedViewers ? [...addedViewers, ...invitedViewers] : []);
  const [alreadySelectedMessage, setAlreadySelectedMessage] = useState(false);
  const [alertInfoModal, setAlertInfoModal] = useState(false);
  const { auth } = useAuth();
  const tenantId = auth.user ? auth.user.user.tenantId : null;

  const searchFunction = keyword => {
    let searchArray;
    setSearchKeyword(keyword);
    searchArray =
      keyword.length > 0
        ? users.filter(
            data =>
              data.firstName?.toLowerCase().includes(keyword.toLowerCase().trim()) ||
              data?.lastName?.toLowerCase().includes(keyword.toLowerCase().trim()) ||
              data?.fullName?.toLowerCase().includes(keyword.toLowerCase().trim())
          )
        : searchArray;

    if (keyword.length > 0) {
      setUserListing([...searchArray]);
    } else {
      setUserListing([...users]);
    }
    setRefresh(!refresh);
  };

  const handleSearchButton = () => {
    setUserListing([...users]);
    setSearchKeyword('');
    setSelectedViewersIds([]);
  };

  const getData = async () => {
    let [userList, viewerList, allUser] = await Promise.all([addUserList(), viewersList(), allUsersList(tenantId)]).catch(
      err => {
        console.log(err);
      }
    );
    let listArray = userList?.data?.data?.results.map(value => {
      value.fullName = `${value.firstName} ${value.lastName}`;
      if (selectedUsers.find(item => item == value.id)) {
        value.checked = true;
        return value;
      } else {
        value.checked = false;
        return value;
      }
    });
    setAllUsers(allUser.data.data);
    setUsers(listArray);
    setUserListing(listArray);
    setUserLists(viewerList?.data?.data?.results);
    setViewers(
      viewerList?.data?.data?.results.filter(viewer => {
        return viewer.type === 'user';
      })
    );
  };

  const getSubscriptionData = () => {
    getSubscriptionDetails(tenantId)
      .then(response => {
        if (Object.keys(response?.data?.data).length !== 0) {
          setIsTrialPeriod(response?.data?.data?.currentSubscription?.isTrial);
        }
      })
      .catch(error => {
        console.log('error', error);
      });
  };
  useEffect(() => {
    getData();
    getSubscriptionData();
    if (cancel && viewOnlyUsers.length === 0) {
      setSelectedViewers([]);
      setText('');
      setNewViewer([]);
    }
  }, [cancel, refresh]);

  const removeUnchecked = data => {
    const newCheckedUsers = [];
    selectedUsers.map(item => {
      if (item !== data.id) {
        newCheckedUsers.push(item);
      }
    });
    return newCheckedUsers;
  };

  const handleSelection = (event, data) => {
    if (event.target.checked && !selectedUsers.includes(data.id)) {
      selectedUsers.push(data.id);
      data.checked = true;
    } else if (createWorkspaceData?.users?.includes(data.id)) {
      setConfirmationModal(true);
      setRemoveAlreadyAddedUserInfo(data);
      // setSelectedUsers(removeUnchecked(data));
    } else {
      data.checked = false;
      setSelectedUsers(removeUnchecked(data));
    }
  };

  const handleAddUserClose = () => {
    setIsSetupModalOpen(false);
    setTimeout(() => {
      isFormSubmitted.submitted
        ? setSelectedUsers(JSON.parse(JSON.stringify(isFormSubmitted.formUsers)))
        : workspaceId
        ? setSelectedUsers(JSON.parse(JSON.stringify(createWorkspaceData?.users)))
        : setSelectedUsers([]);
    }, 100);
    if (isViewerFormSubmitted.added) {
      setSelectedViewersIds(JSON.parse(JSON.stringify(isViewerFormSubmitted.formViewers)));
    } else if (workspaceId) {
      setRenderViewer(false);
      setSelectedViewersIds(JSON.parse(JSON.stringify(createWorkspaceData?.existingViewers)));
    } else {
      setSelectedViewersIds([]);
    }
  };

  // const onUserRemove = data => {
  //   setIsOpen(true);
  //   setRemoveUserData(data);
  // };

  const handleSubmit = () => {
    setIsSetupModalOpen(false);
    userList(selectedUsers);
    setIsFormSubmitted({ submitted: true, formUsers: JSON.parse(JSON.stringify(selectedUsers)) });
    if (JSON.stringify(createWorkspaceData?.users?.sort()) !== JSON.stringify(selectedUsers?.sort())) {
      workspaceId
        ? handleSuccessToast(constants.validationErrorMessages.adminUsersUpdate)
        : handleSuccessToast(constants.validationErrorMessages.adminUsersAdd);
    }
  };

  const handleUserConfirm = () => {
    removeAlreadyAddedUserInfo.checked = false;
    setSelectedUsers(removeUnchecked(removeAlreadyAddedUserInfo));
  };

  const handleAlertConfirm = () => {
    setAlertInfoModal(false);
    if (userInfo?.user?.owner && !isTrialPeriod) {
      setAdditionalLicenseModal(true);
    }
  };

  return (
    <>
      <Transition appear show={isSetupModalOpen} as={Fragment}>
        <Dialog as="div" className="relative z-10" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[600px] transform overflow-hidden rounded-lg  transition-all">
                  {/*Authenticate modal */}
                  <Dialog.Title as="div" className="bg-white px-6 pt-6 pb-4 ">
                    <h2 className="text-left text-base font-semibold leading-6 text-gray-dark">Add Users</h2>
                    <button className="absolute top-4 right-4 z-10 text-gray-dark1" onClick={handleAddUserClose}>
                      <Icons.CloseIcon />
                    </button>
                  </Dialog.Title>
                  <div className=" custom-vertical-scrollbar bg-white">
                    <Tab.Group
                      onChange={event => {
                        if (event == 0) {
                          setRefresh(!refresh);
                        }
                      }}
                    >
                      <Tab.List className="align-center flex justify-items-start border-b border-white200 px-6">
                        <Tab
                          className={({ selected }) =>
                            classNames(
                              'mr-6 whitespace-nowrap rounded-none border-b-3 border-b-transparent pb-1.5 text-sm font-normal text-gray-medium',
                              selected ? '!border-b-blue-700  bg-white font-medium text-blue-700' : ' '
                            )
                          }
                        >
                          Admins
                        </Tab>
                        <Tab
                          className={({ selected }) =>
                            classNames(
                              'whitespace-nowrap rounded-none border-b-3 border-b-transparent pb-1.5 text-sm font-normal text-gray-medium',
                              selected ? '!border-b-blue-700  bg-white font-medium text-blue-700' : ' '
                            )
                          }
                        >
                          Viewers
                        </Tab>
                      </Tab.List>
                      <Tab.Panels>
                        <Tab.Panel>
                          <div className="relative mb-5 mt-6 flex flex-col px-5">
                            <input
                              type="text"
                              id="mailID"
                              placeholder="Search"
                              value={searchKeyword}
                              className="relative h-10.5 rounded-md border border-solid border-gray-medium py-3 pl-4 pr-8 text-sm leading-5.5 text-gray-dark"
                              onChange={event => searchFunction(event.target.value)}
                            />
                            <button
                              className="absolute right-8 top-0 h-full text-gray-dark1"
                              onClick={() => handleSearchButton()}
                            >
                              {searchKeyword.length == 0 ? <Icons.Search /> : <Icons.CloseIcon />}
                            </button>
                          </div>
                          <ul className="add-user-admin grid max-h-[320px] w-full gap-2 overflow-auto border-t border-solid border-white200 px-6 py-4">
                            {userListing?.length > 0 ? (
                              <>
                                {userListing.map((data, index) => {
                                  return (
                                    <li className="form-checkbox flex items-center" key={index}>
                                      <input
                                        type="checkbox"
                                        id={`${data.firstName}${data.lastName}${index}${data.checked}`}
                                        key={`${data.firstName}${data.lastName}${index}${data.checked}`}
                                        className="form-checkbox-input peer absolute right-0"
                                        checked={
                                          workspaceId
                                            ? selectedUsers?.find(item => item === data.id)
                                            : data.id === createWorkspaceData?.loggedinUser
                                            ? true
                                            : selectedUsers?.find(item => item === data.id)
                                        }
                                        onChange={event => handleSelection(event, data)}
                                        disabled={
                                          data.id === createWorkspaceData?.loggedinUser ||
                                          data.id === createWorkspaceData?.workspaceOwner
                                            ? true
                                            : false
                                        }
                                      />
                                      <div className="form-check-circle border- absolute right-4 !h-4 !w-4 !rounded-[5px]">
                                        <Icons.TickMedium />
                                      </div>
                                      <label
                                        htmlFor={`${data.firstName}${data.lastName}${index}${data.checked}`}
                                        className="inline-flex h-10.5 w-full cursor-pointer items-center justify-between rounded-lg border border-white200 bg-white px-4 text-gray-500  peer-checked:border-[2px] peer-checked:border-primary "
                                      >
                                        <div className="block">
                                          <div className="w-full text-xs text-gray-dark">{`${data.firstName} ${data.lastName}`}</div>
                                        </div>
                                      </label>
                                    </li>
                                  );
                                })}
                              </>
                            ) : (
                              <div className="w-full  text-center text-lg font-semibold leading-6 text-primary100">
                                No users Found
                              </div>
                            )}
                          </ul>
                          <div className="border-t border-white200 bg-white py-4 px-6">
                            <button
                              className="w-full rounded-full bg-primary p-2.5 text-sm font-semibold leading-6 text-white duration-200 ease-in hover:bg-primary500"
                              aria-label="Authenticate & add Channel"
                              onClick={handleSubmit}
                            >
                              Submit
                            </button>
                          </div>
                        </Tab.Panel>
                        <Tab.Panel>
                          <AddViewersModal
                            existingViewersList={existingViewersList}
                            newViewersList={newViewersList}
                            removedViewerIds={removedViewerIds}
                            removedInvitedViewerIds={removedInvitedViewerIds}
                            invitedViewersIds={invitedViewersIds}
                            viewOnlyUsers={viewOnlyUsers}
                            setViewOnlyUsers={setViewOnlyUsers}
                            setCancel={setCancel}
                            cancel={cancel}
                            allUsers={allUsers}
                            setIsSetupModalOpen={setIsSetupModalOpen}
                            // isViewerFormSubmitted={isViewerFormSubmitted}
                            setIsViewerFormSubmitted={setIsViewerFormSubmitted}
                            createWorkspaceData={createWorkspaceData}
                            workspaceId={workspaceId}
                            handleSuccessToast={handleSuccessToast}
                            users={users}
                            viewers={viewers}
                            userLists={userLists}
                            setIsSetupAlertModalOpen={setIsSetupAlertModalOpen}
                            setRemoveUserData={setRemoveUserData}
                            setAlreadySelectedMessage={setAlreadySelectedMessage}
                            setAlertInfoModal={setAlertInfoModal}
                            newViewer={newViewer}
                            setNewViewer={setNewViewer}
                            selectedViewers={selectedViewers}
                            setSelectedViewers={setSelectedViewers}
                            selectedViewersIds={selectedViewersIds}
                            setSelectedViewersIds={setSelectedViewersIds}
                            text={text}
                            setText={setText}
                            allViewers={allViewers}
                            setAllViewers={setAllViewers}
                            addedViewers={addedViewers}
                            invitedViewers={invitedViewers}
                            renderViewer={renderViewer}
                            setRenderViewer={setRenderViewer}
                            setNewViewOnlyUsersList={setNewViewOnlyUsersList}
                            deletedViewerIds={deletedViewerIds}
                            setDeletedViewerIds={setDeletedViewerIds}
                            deleteInvitedViewerIds={deleteInvitedViewerIds}
                            setDeleteInvitedViewerIds={setDeleteInvitedViewerIds}
                            userListReload={userListReload}
                          />
                        </Tab.Panel>
                      </Tab.Panels>
                    </Tab.Group>
                  </div>
                  {openConfirmationModal && (
                    <AlertModal
                      isOpen={true}
                      setIsOpen={setConfirmationModal}
                      heading={constants.label.messageHeading}
                      message={constants.infoMessages.modalUserMessage}
                      handleConfirm={() => handleUserConfirm()}
                      confirmAlert={constants.button.ok}
                    />
                  )}

                  {/* {isOpen && (
                    <AlertModal
                      isOpen={true}
                      setIsOpen={setIsOpen}
                      heading={'Alert'}
                      message={`${
                        constants.infoMessages.alertMessage
                      } ${`${removeUserData?.firstName}  ${removeUserData?.lastName}`} ?`}
                      handleConfirm={() => removeViewer(removeUserData.id)}
                    />
                  )} */}
                  {alertInfoModal && (
                    <ConfirmationModal
                      isConfirmModalOpen={alertInfoModal}
                      setIsConfirmModalOpen={setAlertInfoModal}
                      handleClose={() => setAlertInfoModal(false)}
                      handleConfirm={() => handleAlertConfirm()}
                      message={'The maximum number of licenses has been reached'}
                      subMessage={
                        isTrialPeriod
                          ? 'You will be able to purchase additional licenses once your free trial is over.'
                          : userInfo?.user?.owner
                          ? `To buy additional licenses click on 'Proceed'`
                          : messageConstants.alertInfoSubMessage
                      }
                      isOwner={userInfo?.user?.owner}
                      showCancel={false}
                    />
                  )}
                  {additionalLicenseModal && (
                    <AdditionalLicenseModal
                      setIsOpen={setAdditionalLicenseModal}
                      isOpen={additionalLicenseModal}
                      userListReload={userListReload}
                      setUserListReload={setUserListReload}
                    />
                  )}
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
      <SetupChannelModal
        isSetupModalOpen={isSetupAlertModalOpen}
        setIsSetupModalOpen={setIsSetupAlertModalOpen}
        message={alreadySelectedMessage ? messageConstants.alreadySelectedMessage : messageConstants.message}
        showICon={false}
      />
    </>
  );
};
