import { tabTypes, tabTitle } from './constants';

const filterDataByTab = (data, tab) => {
  switch (tab) {
    case tabTitle.pages:
      return data.filter(item => item.type === tabTypes.page);
    case tabTitle.workspaces:
      return data.filter(item => item.type === tabTypes.workspace);
    case tabTitle.users:
      return data.filter(item => item.type === tabTypes.user);
    case tabTitle.accountUsers:
      return data.filter(item => item.type === tabTypes.accountUser);
    default:
      return data;
  }
};

const getDataCount = data => {
  const pageCount = data.filter(item => item.type === tabTypes.page)?.length;
  const workspaceCount = data.filter(item => item.type === tabTypes.workspace)?.length;
  const userCount = data.filter(item => item.type === tabTypes.user)?.length;
  const accountUserCount = data.filter(item => item.type === tabTypes.accountUser)?.length;
  return [pageCount, workspaceCount, userCount, accountUserCount];
};

export { filterDataByTab, getDataCount };
