import { API } from '../constants/api';
import { get } from '../utils/apiHandler';

export const getAlerts = async () => {
  try {
    const response = await get(`${API.getAlerts}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
