import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { LoginModal } from '../Modal/LoginModal';
import Logo from '../../assets/images/logo.svg';
import Icons from '../../assets/Icons';

const Header = props => {
  const navigate = useNavigate();
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener('scroll', () => {
      const y = 150;
      setScroll(window.scrollY > y);
    });
  }, []);
  return (
    <main
      className={
        scroll
          ? 'animate__animated animate__fadeInDown fixed z-[10] w-full bg-gradient-to-r from-primaryMedium to-primaryDark py-3 shadow-md'
          : 'fixed z-10 w-full py-7 '
      }
    >
      <div className="mx-auto flex max-w-7xl justify-between px-5 xs:px-3">
        <img src={Logo} alt="Logo" className="w-auto lg:ml-[0] xs:w-[80px]" />
        <div className="ml-auto flex items-center space-x-11 md:space-x-4">
          <button
            className=" border-none bg-transparent text-sm font-medium text-white"
            onClick={() => navigate('/aboutUs')}
          >
            About Us
          </button>
          <button
            data-testid="login-button"
            className=" border-none bg-transparent text-sm font-medium text-white "
            onClick={() => props.setIsLoginModalOpen(!props.isLoginModalOpen)}
          >
            Login
          </button>
          <div
            className={
              scroll
                ? 'right-4 top-[22px] flex items-center  space-x-2 text-sm text-white xs:text-xs'
                : 'right-4 top-[38px] flex items-center  space-x-2 text-sm text-white xs:text-xs '
            }
          >
            <Icons.PhoneCall />
            <span> 020 3479 2172</span>
          </div>
        </div>
      </div>
      {/* Login modal */}
      {props.isLoginModalOpen && (
        <LoginModal
          isLoginModalOpen={props.isLoginModalOpen}
          setIsLoginModalOpen={props.setIsLoginModalOpen}
          setIsSignupModalOpen={props.setIsSignupModalOpen}
        />
      )}
    </main>
  );
};

export default Header;
