import { API } from '../constants/api';
import { post } from '../utils/apiHandler';

export const addUser = async (data, tenantName, isExpiringLicense) => {
  try {
    const postData = { tenant: tenantName, email: data.email, type: 'clientAdmin', isExpiringLicense: isExpiringLicense };
    const response = await post(API.add_user, postData);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
