import React from 'react';
import Icons from '../../../assets/Icons';
import userImage from '../../../assets/temp-images/userImage.jpg';
import classes from '.././dashboard.module.scss';

export const userLog = [
  {
    name: 'Savannah Nguye',
    userTag: '@Savannah Nguye',
    activity: 'logged in',
    date: '11.45 am, 23 July 2022',
    icons: <Icons.Restricted />,
    iconClass: classes.restricted,
    userImage,
  },
  {
    name: 'Floyd Miles',
    userTag: '@Floyd Miles',
    activity: 'logged in',
    date: '11.45 am, 24 July 2022',
    icons: <Icons.Caution />,
    iconClass: classes.caution,
    userImage,
  },
  {
    name: 'Savannah Nguyen',
    userTag: '@Savannah Nguyen',
    activity: 'Removed Edit permission for',
    date: '11.45 am, 25 July 2022',
    icons: <Icons.InfoIcon2 />,
    iconClass: classes.info,
    userImage,
  },
];

export const userActivity = {
  noalerts: 'No user activity found!',
  addUserToChannel: 'add-user-channel',
  editChannelUser: 'update-user-channel',
  removeUserFromChannel: 'delete-user-channel',
  loadMore: 'Load More Alerts',
  successSubscription: 'payment-success',
  externalUpdate: 'external-channel-update',
};
