/* eslint-disable no-unused-vars */
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { signupConstants } from './constants';
import { OtpVerificationModal } from '../OtpVerificationModal';
import Captcha from '../../Recaptcha';
import InputText from '../../InputText';
import PhoneInputField from '../../PhoneInputField';
import useFormHandler from '../../../customHooks/useFormHandler';
import { useToastBox } from '../../../customHooks/useToastBox';
import Icons from '../../../assets/Icons';
import { subscriptionPlans } from '../../../constants/constants';
import { createCheckoutSession } from '../../../services/paymentService';

export const SignUpModal = props => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [loading, setLoading] = useState(false);
  const [retypePasswordShown, setRetypePasswordShown] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [apiError, setApiError] = useState(false);
  const [resetCaptcha, setResetCaptcha] = useState(false);
  const [user, setUser] = useState({});
  const selectInputRef = useRef();
  const [phoneNumber, setPhoneNumber] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [acceptConditions, setAcceptConditions] = useState(false);

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };
  const toggleConfirmPassword = () => {
    setRetypePasswordShown(!retypePasswordShown);
  };

  const isLogin = false;
  const [signupFormData, setSignupFormData] = useState(signupConstants.formDataInitialValue);
  const navigateToLogin = () => {
    resetForm();
    props.setIsSignupModalOpen(false);
    props.setIsLoginModalOpen(true);
    setOtpModal(false);
  };

  const emailIdProp = props.emailId || '';
  const {
    formError,
    setFormError,
    handleNameChange,
    handleEmailChange,
    handlePhoneChange,
    handlePasswordChange,
    handleConfirmPasswordChange,
    handleCaptchaChange,
    handleFormSubmit,
    handleFormReset,
    handleCountryCodeChange,
    handleOtpSubmit,
    resetForm,
    dynamicPasswordValidation,
    handleReferralCodeChange,
  } = useFormHandler(signupFormData, setSignupFormData, isLogin, emailIdProp);

  const { handleSuccessToast, handleErrorToast } = useToastBox();

  const handleSignUpSubmitResponse = res => {
    if (window.location.hostname === 'www.hihydra.com') {
      window.dataLayer.push({ event: 'signupCompleted' });
    }
    setPhoneNumber('');
    setLoading(false);
    const OK = 200;
    if (res.status === OK) {
      if (props.normalSignup) {
        sessionStorage.setItem('tenant_id', res?.data?.data?.tenantId);
        setTenantId(res?.data?.data?.tenantId);
      }
      setOtpModal(true);
      resetForm();
      return;
    }
    handleErrorToast(res.message);
    setApiError(res.message);
  };

  const handleSignUpSubmitFailure = err => {
    if (window.location.hostname === 'www.hihydra.com') {
      window.dataLayer.push({ event: 'signupError' });
    }
    setResetCaptcha(true);
    // eslint-disable-next-line no-unused-vars
    const { captchaToken, ...newFormData } = signupFormData;
    setSignupFormData(newFormData);
    setLoading(false);
    if (err.message) {
      handleErrorToast(err.message);
    }
    setApiError(err.message);
  };

  const handleSignUpSubmit = event => {
    setLoading(true);
    setApiError('');
    setUser(signupFormData);
    const tenantId = props.tenantId || null;
    const invitationDetails = props.invitationDetails || null;
    const code = props.code || null;
    const token = props.token || null;
    handleFormSubmit(event, tenantId, invitationDetails, code, token)
      .then(res => handleSignUpSubmitResponse(res))
      .catch(err => handleSignUpSubmitFailure(err));
  };

  const handleTwoFactorResponse = () => {
    setOtpModal(false);
    props.setIsLoginModalOpen(true);
    setFormError(values => ({
      ...values,
      [signupConstants.validationErrorMessages.apiError]: '',
    }));
    props.setIsSignupModalOpen(false);
    handleSuccessToast('Signup Successful');
    if (props.selectedPlan?.type?.length > 0 && props.normalSignup) {
      props.setIsSignupModalOpen(true);
      setLoading(true);
      handlePaymentNavigation(props.selectedPlan?.type, props.selectedPlan?.isTrial, props.selectedPlan?.annual);
    } else if (props.normalSignup) {
      navigate('/payment', { state: { user: signupFormData } });
    }
  };
  const handleTwoFactorFailure = error => {
    setFormError(values => ({
      ...values,
      [signupConstants.validationErrorMessages.apiError]: error.message,
    }));
    handleErrorToast(error.message);
  };

  const handleTwoFactorSubmit = event => {
    props.setIsLoginModalOpen(false);
    handleOtpSubmit(event)
      .then(() => handleTwoFactorResponse())
      .catch(error => handleTwoFactorFailure(error));
  };

  const handlePaymentNavigation = (type, isTrial, annual) => {
    if (type !== subscriptionPlans?.enterprise) {
      setLoading(true);
      let plan = { subscriptionType: annual && type === 'team' ? 'annual' : type, tenantId: tenantId, update: false };
      if (isTrial) {
        plan.trial = true;
      }
      createCheckoutSession(plan)
        .then(response => {
          if (response?.status === 201 || response?.status === 200) {
            window.location.href = response?.data?.data?.checkoutUrl;
            setLoading(false);
          }
        })
        .catch(err => {
          handleErrorToast(err.message);
          setLoading(false);
        });
    }
  };

  useEffect(() => {
    if (props.emailId) {
      setSignupFormData(values => ({ ...values, email: props.emailId }));
    }
  }, [props.emailId]);

  return (
    <Transition appear show={props.isSignupModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-50" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              {otpModal === false ? (
                <Dialog.Panel className="w-full max-w-[46.5rem] transform overflow-hidden rounded-lg  transition-all">
                  <>
                    <Dialog.Title
                      as="div"
                      className="modal-header relative flex  min-h-[7.938rem] items-center justify-center bg-primary300 p-4 text-black shadow-none"
                    >
                      <div className="ladewr z-10 text-white">
                        <h2 className="mb-3 text-2xl font-bold leading-9">{signupConstants.textConstants.modalHeading}</h2>
                        <p className="text-xs font-normal leading-[0.875rem]">
                          {signupConstants.textConstants.alreadyAUser}

                          <button className="ml-1 cursor-pointer !font-normal  underline" onClick={navigateToLogin}>
                            {signupConstants.textConstants.login}
                          </button>
                        </p>
                      </div>
                      <button
                        className="absolute top-4 right-4 text-white"
                        onClick={() => {
                          props.setIsSignupModalOpen(false);
                          resetForm();
                          setPhoneNumber('');
                        }}
                      >
                        <Icons.CloseIcon />
                      </button>
                    </Dialog.Title>
                    <div className="relative bg-white py-8 px-10 md:px-5 md:pt-6 md:pb-10">
                      {loading && (
                        <div className="signUploader flex items-center justify-center">
                          <div
                            className="mb-15 inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="user-details-grid grid w-full grid-cols-2 gap-6 md:grid-cols-1 md:gap-4">
                        <InputText
                          className="flex flex-col"
                          id="firstName"
                          placeholder="First Name"
                          type="text"
                          label="First Name"
                          name="firstName"
                          onChange={handleNameChange}
                          error={formError.firstName}
                        />

                        <InputText
                          className="flex flex-col"
                          id="lastName"
                          placeholder="Last Name"
                          type="text"
                          label="Last Name"
                          name="lastName"
                          onChange={handleNameChange}
                          error={formError.lastName}
                        />

                        <InputText
                          className="flex flex-col"
                          id="email"
                          placeholder="Email Address"
                          type="email"
                          label="Email Address"
                          name="email"
                          onChange={handleEmailChange}
                          error={formError.email}
                          emailId={props.emailId}
                        />

                        <PhoneInputField
                          className="flex flex-col"
                          id="phone"
                          placeholder="Phone Number"
                          type="tel"
                          label="Phone Number"
                          hint={true}
                          hintClassName="font-normal text-[0.625rem] text-gray-500 ml-2"
                          hintText="Required for two factor authentication"
                          name="phone"
                          countryCodes={signupConstants.countryCodes}
                          onPhoneChange={handlePhoneChange}
                          error={formError.phone}
                          onCountryChange={handleCountryCodeChange}
                          autoComplete="new-password"
                          selectInputRef={selectInputRef}
                          onPhoneInput={value => setPhoneNumber(value)}
                          phoneNumber={phoneNumber}
                        />

                        <InputText
                          className="flex flex-col"
                          id="password"
                          placeholder="Create Password"
                          type={passwordShown ? 'text' : 'password'}
                          label="Create Password"
                          isPassword={true}
                          onClick={togglePassword}
                          passwordShown={passwordShown}
                          name="password"
                          onChange={handlePasswordChange}
                          error={formError.password}
                          disabled={false}
                          autoComplete="new-password"
                          maxLength={15}
                        />

                        <InputText
                          className="flex flex-col"
                          id="confirmPassword"
                          placeholder="Retype Password"
                          type={retypePasswordShown ? 'text' : 'password'}
                          label="Retype Password"
                          isPassword={true}
                          onClick={toggleConfirmPassword}
                          passwordShown={retypePasswordShown}
                          name="confirmPassword"
                          onChange={handleConfirmPasswordChange}
                          error={formError.confirmPassword}
                          disabled={signupFormData.password ? false : true}
                          autoComplete="new-password"
                          maxLength={15}
                        />
                        {props.normalSignup && (
                          <InputText
                            className="flex flex-col"
                            id="referralCode"
                            placeholder="Referral Code"
                            type="text"
                            label="Referral Code"
                            hint={true}
                            hintClassName="font-normal text-[0.625rem] text-gray-500 ml-2"
                            hintText="Optional"
                            name="referralCode"
                            onChange={handleReferralCodeChange}
                            error={formError.referralCode}
                            isOptional={true}
                            maxLength={12}
                          />
                        )}
                      </div>
                      <div className="py-6">
                        <Captcha
                          onChange={handleCaptchaChange}
                          error={formError.captchaToken}
                          resetCaptcha={resetCaptcha}
                          setReset={() => setResetCaptcha(false)}
                        />
                      </div>
                      {apiError && (
                        <span
                          style={{
                            color: 'red',
                            fontSize: 'small',
                            alignSelf: 'self-start',
                          }}
                        >
                          {apiError}
                        </span>
                      )}

                      <div className="w-full rounded-lg bg-primaryLight p-4">
                        <p className="mb-2  overflow-hidden text-ellipsis whitespace-nowrap text-left text-xs font-semibold leading-[1.125rem] text-gray-dark">
                          {signupConstants.textConstants.passwordRequirement}
                        </p>
                        <ul className="password-container grid w-full grid-cols-3 gap-[0.375rem] text-left md:grid-cols-1">
                          {signupConstants.textConstants.passwordRequirements.map((el, i) => (
                            <>
                              <li key={i}>
                                <div
                                  className={`${
                                    dynamicPasswordValidation[el.identifier] ? 'channelTickIcon' : 'emptyTick'
                                  }  mr-2 flex `}
                                >
                                  <Icons.CircleTick />
                                  <span>{el.label}</span>
                                </div>
                              </li>
                            </>
                          ))}
                        </ul>
                      </div>
                      <label className="form-checkbox delete-later flex !cursor-default items-center pt-6">
                        <input
                          className="form-checkbox-input "
                          type="checkbox"
                          name=""
                          onChange={event => {
                            setAcceptConditions(event.target.checked);
                          }}
                        />
                        <div className="form-check-circle">
                          <Icons.TickSmall />
                        </div>
                        <span className=" text-base font-medium text-gray-500">
                          I have read and accept the{' '}
                          <a
                            href="/TermsAndConditions"
                            target="_blank"
                            className=" text-decoration-line: text-primary600 underline"
                          >
                            Terms and Conditions
                          </a>
                        </span>
                      </label>
                      <div className="footer-buttons flex w-full items-center justify-end pt-6 md:justify-center md:pt-4">
                        <button
                          className="mr-4 border-2 border-solid border-primary text-primary"
                          onClick={event => {
                            setApiError('');
                            handleFormReset(event, selectInputRef);
                            setResetCaptcha(true);
                            setPhoneNumber('');
                          }}
                        >
                          {signupConstants.textConstants.reset}
                        </button>
                        <button
                          className={`bg-primary text-white ${!acceptConditions ? 'cursor-not-allowed opacity-50' : ''}`}
                          onClick={handleSignUpSubmit}
                          disabled={!acceptConditions}
                        >
                          {signupConstants.title}
                        </button>
                      </div>
                    </div>
                  </>
                </Dialog.Panel>
              ) : (
                <Dialog.Panel className="w-full max-w-[27.375rem] transform overflow-hidden rounded-lg">
                  <OtpVerificationModal
                    constants={signupConstants}
                    setIsOpen={setOtpModal}
                    handleSubmit={handleTwoFactorSubmit}
                    handleReset={() =>
                      setFormError(values => ({
                        ...values,
                        [signupConstants.validationErrorMessages.apiError]: '',
                      }))
                    }
                    error={formError.apiError}
                    user={user}
                  />
                </Dialog.Panel>
              )}
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
