/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import CryptoJS from 'crypto-js';
import MultiTag from '../../../components/MultiTag';
import { validationErrorMessages } from '../AddAdminUsers/constants';
import { subscriptionPlans } from '../../../constants/constants';
import { constants } from '../constants';
import { filterUserTypes } from '../AddAdminUsers/helper';
import { EmailValidation } from '../../../utils/validations';
import Icons from '../../../assets/Icons';
import { listUserLicences } from '../../Dashboard/AlertBox/helper';
import useAuth from '../../../customHooks/useAuth';
import { getUserInfo } from '../../../utils/getUserInfo';
import { useToastBox } from '../../../customHooks/useToastBox';

export const AddViewersModal = props => {
  const {
    existingViewersList,
    newViewersList,
    removedViewerIds,
    removedInvitedViewerIds,
    invitedViewersIds,
    setViewOnlyUsers,
    setCancel,
    cancel,
    allUsers,
    setIsSetupModalOpen,
    setIsViewerFormSubmitted,
    createWorkspaceData,
    workspaceId,
    handleSuccessToast,
    users,
    viewers,
    userLists,
    setIsSetupAlertModalOpen,
    setRemoveUserData,
    setAlreadySelectedMessage,
    setAlertInfoModal,
    newViewer,
    setNewViewer,
    selectedViewers,
    setSelectedViewers,
    selectedViewersIds,
    setSelectedViewersIds,
    text,
    setText,
    allViewers,
    setAllViewers,
    addedViewers,
    invitedViewers,
    renderViewer,
    setRenderViewer,
    setNewViewOnlyUsersList,
    deletedViewerIds,
    setDeletedViewerIds,
    deleteInvitedViewerIds,
    setDeleteInvitedViewerIds,
    userListReload,
  } = props;

  const { auth } = useAuth();
  const { handleWarningToast } = useToastBox();
  const [emailError, setEmailError] = useState({});
  const [suggestions, setSuggestions] = useState([]);
  const [input, setInput] = useState('');
  const [isKeyReleased, setIsKeyReleased] = useState(false);
  const [dropDown, setDropDown] = useState(false);
  const [showSelectedAfterRemove, setShowSelectedAfterRemove] = useState(false);
  const tenantId = auth.user ? auth.user.user.tenantId : null;
  const [userLicenseData, setUserLicenseData] = useState({
    additionalLicenses: 0,
    purchasedunUsedLicenses: 0,
    totalLicenses: 0,
    totalUsers: 0,
    unUsedLicenses: 0,
  });

  const [deletedInvitedViewers, setDeletedInvitedViewers] = useState([]);
  const [expiringViewers, setExpiringViewers] = useState([]);
  const userInfo = JSON.parse(getUserInfo());
  const subscriptionCode = userInfo?.user?.subscriptionType;
  const subscriptionType = subscriptionCode
    ? CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8)
    : '';
  useEffect(() => {
    if (!renderViewer) {
      setAllViewers(addedViewers ? [...addedViewers, ...invitedViewers] : []);
    }
  }, [addedViewers, invitedViewers, cancel]);

  const displayUserLicences = () => {
    listUserLicences(tenantId)
      .then(res => {
        setUserLicenseData(res?.data?.data);
      })
      .catch(err => {
        console.log('error', err);
      });
  };

  useEffect(() => {
    if (tenantId) {
      displayUserLicences();
    }
  }, [tenantId, userListReload]);
  const handleEmailChange = event => {
    if (!event.target.value.trim() || event.target.value === undefined) {
      setEmailError(values => ({
        ...values,
        [event.target.name]: validationErrorMessages.email.required,
      }));
    } else if (!EmailValidation(event.target.value)) {
      setEmailError(values => ({
        ...values,
        [event.target.name]: validationErrorMessages.email.validateError,
      }));
    } else {
      setEmailError(values => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
      setEmailError(values => ({
        ...values,
        [event.target.name]: validationErrorMessages.emptyString,
      }));
    }
  };

  const onChangeHandler = event => {
    const text = event.target.value;
    let matches = [];
    if (text.length > 0) {
      matches = userLists.filter(user => {
        const escapedText = text.replace(/[.*+?^${}()|[\]\\]/g, '\\$&');
        const regex = new RegExp(`${escapedText}`, 'gi');
        return user.email.match(regex);
      });
      if (matches.length > 0) {
        let filterMatches = filterUserTypes(matches);
        setSuggestions(filterMatches);
        setDropDown(true);
      } else {
        handleEmailChange(event);
        setSuggestions([]);
        setInput(text);
      }
      setSuggestions(matches);
      setDropDown(true);
    } else {
      setDropDown(false);
    }
    setText(text);
  };

  const onSuggestHandler = (email, type) => {
    if (type === 'user') {
      setText(email);
      const filterViewers = viewers.filter(viewer => {
        if (viewer.email === email) {
          selectedViewersIds.push(viewer.id);
        }
        return viewer.email === email;
      });

      if (allViewers.length > 0) {
        const isSelected = allViewers.some(obj => obj.email === filterViewers[0].email);

        if (!isSelected) {
          setSelectedViewers([...selectedViewers, ...filterViewers]);
          setAllViewers([...allViewers, ...filterViewers]);
        }
      } else {
        // setSelectedViewers(filterViewers);
        const isAlreadySelctedViewer = selectedViewers.some(obj => obj.email === filterViewers[0].email);

        if (isAlreadySelctedViewer) {
          setIsSetupAlertModalOpen(true);
          setAlreadySelectedMessage(true);
        } else {
          setSelectedViewers(Array.from(new Set([...selectedViewers, ...filterViewers])));
        }
      }
      setSuggestions([]);
      setText('');
    } else {
      setIsSetupAlertModalOpen(true);
    }
  };
  const checkIsExpiringViewer = viewers => {
    return (
      (userLicenseData.availableActiveLicenses === 0 || userLicenseData.availableActiveLicenses < viewers.length) &&
      userLicenseData.availableExpiringLiceses > 0 &&
      (subscriptionType === subscriptionPlans.team || subscriptionType === subscriptionPlans.annual)
    );
  };
  const addToWorkspace = () => {
    setRenderViewer(true);
    let selectedIds = [...new Set(selectedViewersIds)];
    const newInvitingViewers = [];
    if (text.length) {
      newInvitingViewers.push(text);
    }
    let newViewers =
      newInvitingViewers.length > 0 ? [...new Set(newViewer.concat(newInvitingViewers))] : [...new Set(newViewer)];

    if (newInvitingViewers.length > 0 && checkIsExpiringViewer(newViewers)) {
      handleWarningToast(
        'The latest added viewer is being added to an expiring license and will be removed at the end of the billing cycle.'
      );
      expiringViewers.push(text);
    }
    let expiringViewerList = JSON.stringify(expiringViewers);
    sessionStorage.setItem('expiringViewers', expiringViewerList);

    if (
      (subscriptionType === subscriptionPlans.team || subscriptionType === subscriptionPlans.annual) &&
      newViewers.length > userLicenseData.availableLicenses
    ) {
      setAlertInfoModal(true);
    } else {
      const hasCommonEmail = allViewers.some(viewer => newViewers.includes(viewer.email));

      if (hasCommonEmail) {
        setIsSetupAlertModalOpen(true);
        setAlreadySelectedMessage(true);
      } else {
        setIsSetupModalOpen(false);
        existingViewersList(selectedIds);
        newViewersList(newViewers);
        setViewOnlyUsers(selectedViewers);
        setNewViewOnlyUsersList(newViewers);
        removedInvitedViewerIds(deletedInvitedViewers);
        setCancel(false);
        setIsViewerFormSubmitted({
          added: true,
          formViewers: selectedViewers.length > 0 ? selectedViewersIds.slice() : [],
        });
        const allViewerIds = allViewers.map(viewerObj => viewerObj.id);
        const combinedViewers = [...createWorkspaceData.existingViewers, ...createWorkspaceData.deleteInvitedViewers];

        if (JSON.stringify(combinedViewers?.sort()) !== JSON.stringify(allViewerIds?.sort())) {
          handleToastDisplay(newInvitingViewers.length > 0 && checkIsExpiringViewer(newViewers));
        } else if (JSON.stringify(createWorkspaceData?.newViewers?.sort()) !== JSON.stringify(newViewers?.sort())) {
          handleToastDisplay(newInvitingViewers.length > 0 && checkIsExpiringViewer(newViewers));
        } else {
          setRenderViewer(false);
          console.log('NO change');
        }
      }
    }
  };

  const handleToastDisplay = hideToaster => {
    if (!hideToaster) {
      if (workspaceId) {
        handleSuccessToast(constants.validationErrorMessages.viewersUpdate);
      } else {
        handleSuccessToast(constants.validationErrorMessages.viewersAdd);
      }
    }
  };

  const removeViewer = id => {
    let deletedInvitedViewersIds = [];
    let flag = false;
    const allSelectedViewersIds = [...createWorkspaceData.existingViewers, ...selectedViewersIds];
    const uniqueSelectedViewersIds = [...new Set(allSelectedViewersIds)];
    if (invitedViewersIds.length > 0) {
      if (invitedViewersIds.includes(id)) {
        let copyInvitedDeletedIds = [];
        deletedInvitedViewersIds = invitedViewersIds.filter(invitedId => {
          return invitedId === id;
        });
        copyInvitedDeletedIds = [...deleteInvitedViewerIds, ...deletedInvitedViewersIds];
        setDeletedInvitedViewers(copyInvitedDeletedIds);
        setDeleteInvitedViewerIds(deletedInvitedViewersIds);
        flag = true;
      }
    }
    const allselectedViewers = [...allViewers, ...selectedViewers];
    const uniqueSelectedViewers = [...new Set(allselectedViewers)];
    // const allSelectedViewersIds = [...createWorkspaceData.existingViewers, ...selectedViewersIds];
    // const uniqueSelectedViewersIds = [...new Set(allSelectedViewersIds)];
    const removedViewerList = uniqueSelectedViewers.filter(viewer => {
      return viewer.id !== id;
    });

    setSelectedViewers(removedViewerList);
    setAllViewers(removedViewerList);
    let removedIds = uniqueSelectedViewersIds.filter(selectedId => {
      return selectedId !== id;
    });
    let copyDeletedIds = [];
    const deletedIds = uniqueSelectedViewersIds.filter(selectedId => {
      return selectedId === id;
    });
    copyDeletedIds = [...deletedViewerIds, ...deletedIds];
    removedIds = removedIds.filter(item => !copyDeletedIds.includes(item));
    const filtereArray = removedViewerList.filter(item => removedIds.includes(item.id));
    removedIds = filtereArray.map(item => item.id);
    setSelectedViewersIds(removedIds);
    removedViewerIds(copyDeletedIds);
    setDeletedViewerIds(copyDeletedIds);
    if (deletedInvitedViewersIds.length > 0 && !!flag) {
      const deletedIds = uniqueSelectedViewersIds.filter(selectedId => {
        return selectedId === id;
      });
      removedInvitedViewerIds(deletedIds);
    }
    setShowSelectedAfterRemove(true);
    setRemoveUserData({});
  };

  const onKeyDown = e => {
    const { key } = e;
    const trimmedInput = input.trim();
    if (key === 'Enter' && trimmedInput.length && !newViewer.includes(trimmedInput) && !emailError.email) {
      e.preventDefault();
      const userCount = allUsers.noOfResults;
      if (
        (subscriptionType === subscriptionPlans.team || subscriptionType === subscriptionPlans.annual) &&
        newViewer.length >= userLicenseData.availableLicenses
      ) {
        setAlertInfoModal(true);
        setText('');
      } else {
        const isFound = users.some(obj => obj.email === trimmedInput);
        const isAlreadySelcted = allViewers.some(obj => obj.email === trimmedInput);

        if (isFound) {
          setIsSetupAlertModalOpen(true);
        } else if (isAlreadySelcted) {
          setIsSetupAlertModalOpen(true);
          setAlreadySelectedMessage(true);
        } else {
          setNewViewer(prevState => [...prevState, trimmedInput]);
          setInput('');
          setText('');

          if (
            (userLicenseData.availableActiveLicenses === newViewer.length ||
              userLicenseData.availableActiveLicenses === 0 ||
              userLicenseData.availableActiveLicenses < newViewer.length) &&
            userLicenseData.availableExpiringLiceses > 0 &&
            (subscriptionType === subscriptionPlans.team || subscriptionType === subscriptionPlans.annual)
          ) {
            handleWarningToast(
              'This user is being added to an expiring license and will be removed at the end of the billing cycle.'
            );
            setExpiringViewers(prevState => [...prevState, trimmedInput]);
          }
        }
      }
    }
    if (key === 'Backspace' && !input.length && newViewer.length && isKeyReleased) {
      e.preventDefault();
      let inputValue = [];
      const newViewerCopy = [...newViewer];
      inputValue.push(e.target.value);
      const poppedTag = inputValue.pop();

      setNewViewer(newViewerCopy);
      setInput(poppedTag);
    }
    setIsKeyReleased(false);
  };

  const onKeyUp = () => {
    setIsKeyReleased(true);
  };

  const deleteTag = (index, viewerMail) => {
    setNewViewer(prevState => prevState.filter((tag, i) => i !== index));
    setExpiringViewers(prevState => prevState.filter(i => i !== viewerMail));
  };

  useEffect(() => {
    if (newViewer?.length === 0) {
      sessionStorage.setItem('expiringViewers', []);
    }
  }, [newViewer]);

  return (
    <>
      <div className="mailID_Tag relative mb-5 mt-6 flex flex-col px-5">
        {emailError?.email && (
          <div
            style={{
              color: 'red',
              fontSize: 'small',
              alignSelf: 'self-start',
            }}
          >
            {emailError.email}
          </div>
        )}
        <label
          htmlFor="mailID"
          className="mb-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-left text-xs font-normal leading-4.5 text-gray-dark"
        >
          Mail ID
        </label>
        <MultiTag
          handleChange={event => onChangeHandler(event)}
          handleKeyDown={onKeyDown}
          handleKeyUp={onKeyUp}
          value={text}
        />
        <div className="tagsHolder">
          {newViewer?.map((viewerMail, index) => (
            <div className="tag " key={index}>
              {viewerMail}
              <button onClick={() => deleteTag(index, viewerMail)}>x</button>
            </div>
          ))}
        </div>
        {dropDown ? (
          <div
            className={`${
              suggestions.length > 0 ? 'block' : 'hidden'
            } absolute left-5 right-5 top-[80px] z-20 max-h-[100px] overflow-auto rounded-md border border-solid border-gray-medium bg-white`}
          >
            {suggestions &&
              suggestions.map((suggestion, index) => (
                <div
                  key={index}
                  className="suggestion   py-2 px-4 text-left text-xs  text-gray-dark "
                  onClick={() => onSuggestHandler(suggestion.email, suggestion.type)}
                >
                  <span title={suggestion?.email}>
                    {suggestion.firstName
                      ? `${suggestion.firstName}  ${suggestion.lastName}(${suggestion.userType})`
                      : `${suggestion.email} (${suggestion.userType})`}
                  </span>
                </div>
              ))}
          </div>
        ) : (
          ''
        )}
      </div>
      <ul className="grid max-h-[210px] w-full gap-2 overflow-auto  border-t border-white200   px-6 py-4">
        {!showSelectedAfterRemove && workspaceId && allViewers.length > 0 ? (
          <>
            {allViewers.map((viewer, index) => {
              return (
                <li className="form-checkbox flex items-center" key={index}>
                  <label
                    htmlFor="react-option"
                    className="inline-flex h-10.5 w-full items-center justify-between rounded-lg border border-white200 bg-white px-4 text-gray-500  peer-checked:border-[2px] peer-checked:border-primary "
                  >
                    <div className="block">
                      <Tippy content={`${viewer.email}`} placement="top" arrow={true}>
                        <div className="w-full max-w-[220px] truncate text-xs text-gray-dark">
                          {viewer.firstName
                            ? `${viewer.firstName}${' '}${viewer.lastName} (${viewer.email})`
                            : `${viewer.email}`}
                        </div>
                      </Tippy>
                      <button
                        className="absolute top-3 right-4 z-10 text-gray-dark1"
                        onClick={() => removeViewer(viewer.id)}
                      >
                        <Icons.DeleteIcon />
                      </button>
                    </div>
                  </label>
                </li>
              );
            })}
          </>
        ) : (
          selectedViewers &&
          selectedViewers?.map((data, index) => {
            return (
              <li className="form-checkbox flex items-center" key={index}>
                <label
                  htmlFor="react-option"
                  className="inline-flex h-10.5 w-full items-center justify-between rounded-lg border border-white200 bg-white px-4 text-gray-500  peer-checked:border-[2px] peer-checked:border-primary "
                >
                  <div className="block">
                    <Tippy content={data.email} placement="top" arrow={true}>
                      <div className="w-full max-w-[220px] truncate text-xs text-gray-dark">
                        {data.firstName ? `${data.firstName}${' '}${data.lastName} (${data.email})` : `${data.email}`}
                      </div>
                    </Tippy>
                    <button className="absolute top-3 right-4 z-10 text-gray-dark1" onClick={() => removeViewer(data.id)}>
                      <Icons.DeleteIcon />
                    </button>
                  </div>
                </label>
              </li>
            );
          })
        )}
      </ul>
      <div className="bg-white py-4 px-6">
        <button
          className="w-full rounded-full bg-primary p-2.5 text-sm font-semibold leading-6 text-white duration-200 ease-in hover:bg-primary500"
          aria-label="Authenticate & add Channel"
          onClick={addToWorkspace}
        >
          Add to workspace
        </button>
      </div>
    </>
  );
};
