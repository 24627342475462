import React from 'react';
import Select from 'react-select';
import { signupConstants } from '../Modal/SignUpModal/constants';
import Icons from '../../assets/Icons';
import { addLicenseOwnerConstants } from '../../features/LicenseAddingPage/constants';

const InputText = props => {
  const textInput =
    props.type === 'tel' ? (
      <div className="flex items-center">
        <Select
          ref={props.selectInputRef}
          options={props.countryCodes}
          id="countryCode"
          className="formSelector"
          placeholder="+91"
          defaultValue={signupConstants.defaultCountryCode}
          onChange={e => props.selectOnChange(e, 'countryCode')}
        />
        <input
          type={props.type}
          id={props.id}
          placeholder={props.placeholder}
          required={props.required}
          className="w-full"
          onChange={props.onChange}
          name={props.name}
          autoComplete={props.autoComplete}
        />
      </div>
    ) : props.emailId ? (
      <input
        type={props.type}
        id={props.id}
        placeholder={props.placeholder}
        name={props.name}
        onChange={props.onChange}
        required={props.required}
        disabled={props.emailId ? true : false}
        value={props.emailId ? props.emailId : ''}
      />
    ) : props.type === 'subscription' ? (
      <div className="flex items-center">
        <Select
          options={props.subscriptions}
          id="subscription"
          className="formSelector_v2"
          placeholder="Enterprise"
          defaultValue={addLicenseOwnerConstants.defaultSubscription}
          onChange={e => props.selectOnChange(e, 'subscription')}
          disabled={true}
        />
      </div>
    ) : (
      <input
        type={props.type}
        id={props.id}
        placeholder={props.placeholder}
        name={props.name}
        onChange={props.onChange}
        required={props.required}
        autoComplete={props.autoComplete}
        value={props.value}
        maxLength={props.maxLength}
        onKeyDown={e => {
          if (e && props.handleKeyDown) {
            props.handleKeyDown(e);
          }
        }}
      />
    );

  const passwordInput = (
    <div className="relative">
      <input
        type={props.passwordShown ? 'text' : 'password'}
        id={props.id}
        required={props.required}
        className="w-full !pr-13"
        placeholder={props.placeholder}
        name={props.name}
        onChange={props.onChange}
        maxLength={props.maxLength}
        disabled={props.disabled}
        autoComplete={props.autoComplete}
        onPaste={e => {
          if (props.id == 'confirmPassword') {
            e.preventDefault();
            return false;
          }
        }}
      />
      <button
        className="absolute right-4 top-4 text-xs font-medium leading-[0.875rem] text-primary underline"
        onClick={props.onClick}
      >
        {props.passwordShown ? 'Hide' : 'Show'}
      </button>
    </div>
  );

  return (
    <div className={props.className}>
      <label htmlFor={props.id}>
        {props.label}
        {!props.isOptional && (
          <span className="absolute top-1">
            <Icons.AsteriskIcon />
          </span>
        )}
        {props.hint && <span className={props.hintClassName}>({props.hintText})</span>}
      </label>
      {props.isPassword ? passwordInput : textInput}
      {props.error !== '' ? (
        <span style={{ color: 'red', fontSize: 'small', alignSelf: 'self-start' }}>{props.error}</span>
      ) : null}
    </div>
  );
};

export default InputText;
