import { API } from '../constants/api';
import { post } from '../utils/apiHandler';

export const addLicenseOwner = async data => {
  try {
    const postData = {
      countryCode: data.countryCode,
      email: data.email,
      firstName: data.firstName,
      lastName: data.lastName,
      mobile: data.phone,
      subscription: data.subscription,
      tenantName: data.tenantName,
      referralCode: data.referralCode,
    };
    const response = await post(API.add_tenant_user, postData);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
