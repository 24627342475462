/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import Tippy from '@tippyjs/react';
import CryptoJS from 'crypto-js';
import { Disclosure } from '@headlessui/react';
import { userInfo, userDetails, alertModalConstants, deleteNowModalConstants } from './constants';
import { handleDeleteUserConfimation, handleRevokeUserConfimation, listUsers } from './helper';
import { useOutsideClick } from '../../../customHooks/useOutsideClick';
import useAuth from '../../../customHooks/useAuth';
import Table from '../../../components/Table';
import Icons from '../../../assets/Icons';
import Loader from '../../../components/Loader';
import NoData from '../../../components/Nodata';
import { useToastBox } from '../../../customHooks/useToastBox';
import Pagination from '../../../components/Pagination';
import { UserDeletionModal } from '../UserDeletionModal';
import { ConfirmationModal } from '../../../components/Modal/ConfirmationModal';
import { getUserInfo } from '../../../utils/getUserInfo';
import { subscriptionPlans } from '../../../constants/constants';

const UserList = ({ setCount, userListReload, onLoad, setUserListReload, isTrialPeriod, userLicenseData }) => {
  const [userList, setUserList] = useState([]);
  const [loading, setIsLoading] = useState(true);
  const [metaData, setMetaData] = useState();
  const [page, setPage] = useState(1);
  const [pageRange, setPageRange] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [userCount, setUserCount] = useState(0);
  const [deleteUser, setDeleteUser] = useState({ email: '', deletedBy: '' });
  const [revokeUser, setRevokeUser] = useState({ email: '' });
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const { auth } = useAuth();
  const { handleSuccessToast, handleErrorToast } = useToastBox();
  const tenantId = auth.user ? auth.user.user.tenantId : null;
  const owner = auth.user ? auth.user.user.owner : false;
  const loggedinUser = {
    id: auth?.user?.user?.id,
    email: auth?.user?.user?.email,
  };
  const [isMenuOpen, setIsMenuOpen] = useState({ id: '', isOpen: false });
  const [deleteNow, setDeleteNow] = useState(false);
  const [confirmModal, setConfirmModal] = useState(false);
  const [revokeErrorModal, setRevokeErrorModal] = useState(false);

  let subscriptionType;
  const userData = JSON.parse(getUserInfo());
  const subscriptionCode = userData?.user?.subscriptionType;
  if (subscriptionCode) {
    subscriptionType = CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8);
  }

  const closeAllDropdowns = () => {
    setIsMenuOpen({ id: '', isOpen: false });
  };

  const dropdownRef = useOutsideClick(closeAllDropdowns);

  const handleDropDownClick = id => {
    if (id === isMenuOpen.id) {
      setIsMenuOpen({ id: id, isOpen: !isMenuOpen.isOpen });
    } else {
      setIsMenuOpen({ id: id, isOpen: true });
    }
  };

  const handleDeleteLater = value => {
    if (value) {
      setDeleteUser(prevState => ({
        ...prevState,
        scheduledRemoval: true,
      }));
    } else {
      setDeleteUser(prevState => ({
        ...prevState,
        scheduledRemoval: false,
      }));
    }
  };

  const tableColumns = [
    {
      Header: 'Name',
      accessor: item =>
        item?.firstName && item?.lastName ? `${item.firstName} ${item?.lastName}` : item?.firstName ? item.firstName : '-',
    },
    {
      Header: 'User',
      accessor: item => (
        <>
          <div className="mr col-span-2 flex items-center pl-4 xl:!hidden">
            <Tippy placement="bottom" arrow={true} content={<span>Hydra Authenticated User</span>}>
              <button className=" float-left mr-1" aria-label="Add the Channel">
                <Icons.Shield />
              </button>
            </Tippy>
            {item.email}
          </div>
        </>
      ),
    },
    {
      Header: 'Type',
      accessor: item =>
        item.type === 'clientAdmin' && item.owner === true
          ? userDetails.licenseOwner
          : item.type === 'clientAdmin' && item.owner === false
          ? userDetails.adminUser
          : item.type == 'user'
          ? 'Viewer'
          : '-',
    },
    {
      Header: 'Added By',
      accessor: item => (item.invitedBy ? item.invitedBy : '-'),
    },
    {
      Header: 'Status',
      accessor: item => (
        <>
          <div
            className={`col-span-2 flex items-center pl-4 xl:!hidden ${
              item.InvitationStatus == 'verified'
                ? 'verified'
                : item.InvitationStatus == 'Remove at next billing'
                ? 'remove_billing'
                : 'not_verified'
            }`}
          >
            {item.InvitationStatus}
          </div>
        </>
      ),
    },
    {
      Header: 'Last Login',
      accessor: item => item.lastLogin,
    },
    {
      Header: 'Actions',
      accessor: (item, index) => (
        <>
          {item.InvitationStatus == 'Remove at next billing' ? (
            <div
              onClick={() => {
                if (item?.email !== loggedinUser?.email) {
                  handleDropDownClick(index);
                }
              }}
              className={`relative ml-6 flex h-[20px] w-[20px]  items-center justify-center ${
                item?.email === loggedinUser?.email ? ' opacity-30' : 'cursor-pointer'
              }`}
              ref={dropdownRef}
            >
              <label
                tabIndex={0}
                className={`flex h-full w-full rotate-90  items-center justify-center text-[24px] font-medium normal-case text-black ${
                  item?.email === loggedinUser?.email ? 'cursor-not-allowed' : 'cursor-pointer'
                }`}
              >
                <span className="h-[27px]">{'...'}</span>
              </label>
              <ul
                tabIndex={0}
                className={`${isMenuOpen.isOpen && isMenuOpen.id === index ? '' : 'hidden'} table-dropdown-content `}
              >
                <li key={item.label} className=" ">
                  <button
                    onClick={() => {
                      setDeleteNow(true);
                      setOpenAlertModal(true);
                      setDeleteUser({ email: item?.email, deletedBy: loggedinUser?.id });
                    }}
                    className="w-full p-3 pb-[6px] text-start hover:bg-[#f9f9f9]"
                  >
                    <span className=" text-[12px] ">{'Delete Now'}</span>
                  </button>
                  <button
                    onClick={() => {
                      setRevokeUser({ email: item?.email });
                      setConfirmModal(true);
                    }}
                    className="w-full p-3 pt-[6px] text-start hover:bg-[#f9f9f9]"
                  >
                    <span className="text-[12px] ltr:order-1 rtl:-order-1 rtl:!ml-auto">{'Activate'}</span>
                  </button>
                </li>
              </ul>
            </div>
          ) : (
            <button
              className={`btn-link ml-6 flex items-center gap-x-0.5 md:mt-4`}
              aria-label="Request to Remove"
              onClick={() => {
                setOpenAlertModal(true);
                setDeleteUser({ email: item?.email, deletedBy: loggedinUser?.id });
              }}
              disabled={item?.email === loggedinUser?.email || item?.owner ? true : false}
            >
              <Icons.TrashIcon />
            </button>
          )}
        </>
      ),
    },
  ];

  const displayUserList = () => {
    listUsers(tenantId, page, pageSize)
      .then(response => {
        setMetaData(response?.data?.data);
        const listOfUsers = response?.data?.data?.results,
          numberOfUSers = response?.data?.data?.noOfResults,
          totalUsers = response?.data?.data?.totalResults;

        filterUserList(listOfUsers);
        setCount(totalUsers);
        setUserCount(numberOfUSers);
        onLoad(false);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        handleErrorToast(err.message);
        onLoad(false);
      });
  };

  const formatDate = dateString => {
    if (dateString) {
      const date = new Date(dateString),
        displayTime = date.toLocaleTimeString('en-US', {
          month: 'short',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit',
        });
      return displayTime;
    } else {
      const notVerifiedDate = ' --:-- ';
      return notVerifiedDate;
    }
  };

  const addInvitedUser = () => {
    const whoIsAdded = userDetails.invitedByYou,
      addedName = userDetails.licenseOwner;

    return (
      <div className="item-center flex">
        {whoIsAdded}
        <span className="bg-gray-1400 mx-2 mt-2 h-1 w-1 rounded-full"></span>
        {owner ? addedName : ''}
      </div>
    );
  };

  const filterUserList = response => {
    response?.map(result => {
      const loginData = formatDate(result.lastLogin);

      result.lastLogin = loginData;
      result.icon = (
        <div className="absolute -right-3 top-4 z-50 flex items-center xl:!hidden">
          <Tippy placement="bottom" arrow={true} content={<>{userInfo}</>}>
            <button className=" float-left" aria-label="Add the Channel">
              <Icons.Shield />
            </button>
          </Tippy>
        </div>
      );

      if (result.invitedBy === auth.user.user.name) {
        const invitedBy = addInvitedUser();

        result.invitedBy = invitedBy;
      }

      if (result.InvitationStatus === 'verified') {
        return (
          <span key={result.id} className="verified">
            {result.InvitationStatus}
          </span>
        );
      } else if (result.InvitationStatus === 'not verified') {
        return (
          <span key={result.id} className="unVerified">
            {result.InvitationStatus}
          </span>
        );
      }
    });
    setUserList(response);
  };

  useEffect(() => {
    if (tenantId) {
      displayUserList();
    }
  }, [tenantId, userListReload, page, pageSize]);

  return loading ? (
    <Loader />
  ) : (
    <div className="overflow-auto p-4.5 pb-20 xl:max-h-[calc(100vh-192px)] xl:min-h-[calc(100vh-192px)] xl:pb-0">
      <div className=" overflow-hidden rounded-lg border-none border-white200 xl:h-full xl:overflow-auto xl:rounded-none xl:border-none">
        <ul className="userListWrapper hidden xl:block xl:h-full xl:space-y-2">
          {userList && userList.length == 0 && <NoData message={'No users found'} showImage={true} />}
          {userList &&
            userList?.length > 0 &&
            userList?.map((data, index) => {
              return (
                <li
                  className="grid grid-cols-5 border-0 border-white200 bg-white py-4 hover:bg-primaryLight xl:rounded-lg xl:border xl:py-3 xl:px-4 xl:pr-9"
                  role="presentation"
                  key={`dashboardChannelList__${index}`}
                >
                  <div className="col-span-5 !hidden w-full flex-col xl:!flex">
                    <Disclosure>
                      {({ open }) => (
                        <>
                          <Disclosure.Button className=" relative flex w-full flex-col justify-between rounded-lg !bg-transparent p-0 text-left  text-sm">
                            <div className="w-full justify-between">
                              <span className="truncate pr-1  text-xs font-medium text-gray-dark">
                                {data?.firstName && data?.lastName
                                  ? `${data.firstName} ${data?.lastName}`
                                  : data?.firstName
                                  ? data.firstName
                                  : '-'}
                              </span>
                              <div>
                                <span
                                  className={
                                    data.InvitationStatus === 'verified'
                                      ? 'verified'
                                      : data.InvitationStatus == 'Remove at next billing'
                                      ? 'remove_billing'
                                      : 'unVerified'
                                  }
                                >
                                  {data.InvitationStatus}
                                </span>
                              </div>
                            </div>
                            <div className="w-full justify-between">
                              <span className="truncate pr-1  text-xs font-medium text-gray-dark">{data.email}</span>
                            </div>
                            <div
                              className={`${
                                open ? '!hidden' : ''
                              } mt-[0.125rem] w-full text-[0.625rem] font-medium leading-4 text-gray-medium`}
                            >
                              {userInfo}
                            </div>

                            <div className={`${open ? 'rotate-180 transform' : ''} absolute top-1.5 -right-5.5`}>
                              <Icons.DownArrow />
                            </div>
                          </Disclosure.Button>
                          <Disclosure.Panel className="!grid w-full !grid-cols-2 pt-2 text-sm text-gray-500">
                            <div className=" addedBy flex-col !items-start !justify-start text-xs font-medium leading-4  text-gray-dark">
                              <label className=" mb-[0.125rem] text-[0.625rem] leading-4 text-gray-dark1">Added by</label>
                              <div>{data.invitedBy}</div>
                            </div>

                            <div className=" lastLogin flex-col !items-end !justify-end text-xs font-medium leading-4  text-gray-dark">
                              <label className=" mb-[0.125rem] text-[0.625rem] leading-4 text-gray-dark1">Last login</label>
                              <div>
                                <span>{data.lastLogin}</span>
                              </div>
                            </div>
                            <div className="addedBy mt-2 flex-col !items-start !justify-start text-xs font-medium leading-4  text-gray-dark">
                              <label className=" mb-[0.125rem] text-[0.625rem] leading-4 text-gray-dark1">Type</label>
                              <div>
                                {data.type === 'clientAdmin' && data.owner === true
                                  ? userDetails.licenseOwner
                                  : data.type === 'clientAdmin' && data.owner === false
                                  ? userDetails.adminUser
                                  : data.type === 'user'
                                  ? 'Viewer'
                                  : '-'}
                              </div>
                            </div>
                            <div>
                              {/* <button
                                className={`btn-link ml-auto flex items-center gap-x-0.5`}
                                aria-label="Request to Remove"
                                onClick={() => {
                                  setOpenAlertModal(true);
                                  setDeleteUser({ email: data?.email, deletedBy: loggedinUser?.id });
                                }}
                                disabled={data?.email === loggedinUser?.email || data?.owner ? true : false}
                              >
                                <Icons.TrashIcon />
                              </button> */}
                              <div className="item-center flex w-full justify-end gap-3">
                                <button
                                  onClick={() => {
                                    if (data?.InvitationStatus == 'Remove at next billing') {
                                      setDeleteNow(true);
                                    }
                                    setOpenAlertModal(true);
                                    setDeleteUser({ email: data?.email, deletedBy: loggedinUser?.id });
                                  }}
                                  className={`${data?.email === loggedinUser?.email ? 'cursor-not-allowed opacity-30' : ''}`}
                                  disabled={data?.email === loggedinUser?.email}
                                >
                                  <span className=" text-[12px] text-red-dark" title="delete">
                                    <Icons.TrashIcon />
                                  </span>
                                </button>
                                {data?.InvitationStatus == 'Remove at next billing' ? (
                                  <button
                                    onClick={() => {
                                      setRevokeUser({ email: data?.email });
                                      setConfirmModal(true);
                                    }}
                                    className={`${
                                      data?.email === loggedinUser?.email ? 'cursor-not-allowed opacity-30' : ''
                                    }`}
                                    disabled={data?.email === loggedinUser?.email}
                                  >
                                    <span className="text-[12px]  text-primary" title="revoke">
                                      <Icons.Refresh />
                                    </span>
                                  </button>
                                ) : null}
                              </div>
                            </div>
                          </Disclosure.Panel>
                        </>
                      )}
                    </Disclosure>
                  </div>
                </li>
              );
            })}
          {metaData?.totalResults > 10 && (
            <Pagination
              metaData={metaData}
              page={page}
              setPageSize={setPageSize}
              setPage={setPage}
              pageSize={pageSize}
              pageRange={pageRange}
              setPageRange={value => setPageRange(value)}
            />
          )}
        </ul>
      </div>
      <div className="userManagementList xl:!hidden">
        {userList?.length > 0 ? (
          <Table
            data={userList}
            metaData={metaData}
            columns={tableColumns}
            pagination={userCount > 10 ? true : false}
            page={page}
            pageRange={pageRange}
            setPageRange={value => setPageRange(value)}
            pageSize={pageSize}
            paging={userCount > 10 ? true : false}
            setPageSize={value => setPageSize(value)}
            setPage={value => setPage(value)}
            showPagination={metaData?.totalResults <= 10 ? false : true}
          />
        ) : (
          <div className=" mt-32 ">
            <NoData message={'No records found'} showImage={true} />
          </div>
        )}
      </div>
      {openAlertModal && (
        <UserDeletionModal
          isOpen={true}
          setIsOpen={setOpenAlertModal}
          setDeleteLater={value => handleDeleteLater(value)}
          heading={deleteNow ? deleteNowModalConstants.heading : alertModalConstants.heading}
          message={deleteNow ? '' : alertModalConstants.message}
          handleConfirm={() => {
            setPage(1);
            handleDeleteUserConfimation(
              deleteUser,
              setDeleteUser,
              handleSuccessToast,
              handleErrorToast,
              setUserListReload,
              userListReload,
              setIsLoading,
              setDeleteNow
            );
          }}
          handleCancel={() => {
            setDeleteUser({ email: '', deletedBy: '' });
            setDeleteNow(false);
          }}
          deleteNow={deleteNow}
          hideCheckbox={isTrialPeriod || subscriptionType === subscriptionPlans.enterprise}
        />
      )}
      {confirmModal && (
        <ConfirmationModal
          isConfirmModalOpen={confirmModal}
          setIsConfirmModalOpen={setConfirmModal}
          handleClose={() => setConfirmModal(false)}
          handleConfirm={() => {
            setPage(1);
            handleRevokeUserConfimation(
              revokeUser,
              setRevokeUser,
              handleSuccessToast,
              handleErrorToast,
              setUserListReload,
              userListReload,
              setIsLoading,
              setRevokeErrorModal
            );
          }}
          message={`Are you sure you want to make the user active?`}
          isOwner={false}
          showCancel={true}
        />
      )}
      {revokeErrorModal && (
        <ConfirmationModal
          isConfirmModalOpen={revokeErrorModal}
          setIsConfirmModalOpen={setRevokeErrorModal}
          handleClose={() => setRevokeErrorModal(false)}
          handleConfirm={() => {
            setRevokeErrorModal(false);
          }}
          message={`You need to purchase additional licenses to prevent this user from being removed at the next billing cycle`}
          isOwner={false}
          showCancel={true}
        />
      )}
    </div>
  );
};
export default UserList;
