/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Icons from '../../assets/Icons';
import { useNavigate } from 'react-router-dom';
// import NoData from '../../components/Nodata';

const TermsandConditions = () => {
  const navigate = useNavigate();
  return (
    <>
      {/* <section className="app-l-terms-holder">
        <section className="relative overflow-hidden bg-gradient-to-r from-primaryMedium to-primaryDark py-4 pt-6 ">
          <div className="relative z-10 mx-auto max-w-7xl px-5">
            <button
              className=" flex items-center text-sm font-medium text-white hover:opacity-50"
              onClick={() => navigate('/')}
            >
              <Icons.leftArrow />
              <span className=" ml-2.5">Back</span>
            </button>
            <h1 className=" m-0 p-0 text-2.1xl font-semibold leading-[2.75rem] text-white md:mt-1.5 md:text-base">
              Terms & Conditions
            </h1>
          </div>
          <div className=" rotating absolute -bottom-27 -right-8.5 h-[180px] w-[180px] text-gray-medium1 md:-right-24 md:-bottom-28">
            <Icons.paymentShape1 />
          </div>
          <div className=" rotating absolute -top-27 -left-8.5 h-[180px] w-[180px] text-gray-medium1 md:-top-[133px] md:-left-17">
            <Icons.paymentShape1 />
          </div>
        </section>
       
      </section> */}
      <section className="app-l-terms-holder">
        <section className="relative overflow-hidden bg-gradient-to-r from-primaryMedium to-primaryDark py-4 pt-6 ">
          <div className="relative z-10 mx-auto max-w-7xl px-5">
            <button
              className=" flex items-center text-sm font-medium text-white hover:opacity-50"
              onClick={() => navigate('/')}
            >
              <Icons.leftArrow />
              <span className=" ml-2.5">Back</span>
            </button>
            <h1 className=" m-0 p-0 text-2.1xl font-semibold leading-[2.75rem] text-white md:mt-1.5 md:text-base">
              Hydra Terms of Service
            </h1>
          </div>
          <div className=" rotating absolute -bottom-27 -right-8.5 h-[180px] w-[180px] text-gray-medium1 md:-right-24 md:-bottom-28">
            <Icons.paymentShape1 />
          </div>
          <div className=" rotating absolute -top-27 -left-8.5 h-[180px] w-[180px] text-gray-medium1 md:-top-[133px] md:-left-17">
            <Icons.paymentShape1 />
          </div>
        </section>
        <section className="mx-auto max-w-7xl px-5 pt-5 pb-10">
          <div className="mb-4 w-full">
            <h2 className="mb-2 text-xl font-semibold leading-6 text-gray-dark">Hydra Terms of Service</h2>
            <p className="text-sm font-bold text-gray-medium">Last Modified: May, 2023</p>
          </div>
          <div className="flex w-full flex-col items-start justify-start pt-6">
            <div className="flex flex-col space-y-3">
              <p>
                These Terms and Conditions of are entered into by and between you (<strong>"Customer"</strong>,
                <strong>“User”</strong> or <strong>"You"</strong>) and Hi Hydra Limited (<strong>"Provider,"</strong>
                <strong> "we,"</strong> or <strong>"us"</strong>). Provider and Customer may be referred to individually as a
                <strong>"Party"</strong> and collectively as the <strong>"Parties." </strong>The following terms and
                conditions, together with any Order Forms or other documents they expressly incorporate by reference
                (collectively, the <strong>"Agreement"</strong>), govern your access to and use of <u>www.hihydra.com</u> and
                our Services.
              </p>
            </div>
            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">FOR VISITORS TO OUR WEBSITE:</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p>
                  Please read this Agreement carefully before you start to use the Website. By using the Website, you accept
                  and agree to be bound and abide by this Agreement, and our Privacy Policy, found at
                  https://www.hihydra.com/privacyPolicy, incorporated herein by reference. If you do not want to agree to
                  this Agreement or the Privacy Policy, you must not access or use the Website.
                </p>
                <p>
                  This Website is offered and available to users who are 18 years of age or older. By using this Website, you
                  represent and warrant that you are of legal age to form a binding contract with Provider. If you do not
                  meet all of these requirements, you must not access or use the Website.
                </p>
                <p>
                  We may revise and update the visitor terms from time to time at our sole discretion. All changes are
                  effective immediately when we post them here and apply to all access to and use of the Website thereafter.
                </p>
                <p>
                  Your continued use of the Website following the posting of any revisions means that you accept and agree to
                  the changes. You are expected to check this page from time to time so you are aware of any changes, as they
                  are binding on you.
                </p>
                <p>
                  We reserve the right to withdraw or amend this Website, and any service or material we provide on the
                  Website, at our sole discretion without notice. We will not be liable if for any reason all or any part of
                  the Website is unavailable at any time or for any period. From time to time, we may restrict access to some
                  parts of the Website, or the entire website, to users, including registered users.
                </p>
                <p>
                  The Website and its entire contents, features, and functionality (including but not limited to all
                  information, software, text, displays, images, video, and audio, and the design, selection, and arrangement
                  thereof) are owned by the Provider, its licensors, or other providers of such material and are protected by
                  United Kingdom and international copyright, trademark, patent, trade secret, and other intellectual
                  property or proprietary rights laws. You must not reproduce, distribute, modify, create derivative works
                  of, publicly display, publicly perform, republish, download, store, or transmit any of the material on our
                  website, except as expressly permitted by us.
                </p>
                <p>
                  You may use the Website only for lawful purposes and in accordance with this Agreement. Additionally, you
                  agree not to: use the Website in any manner that could disable, overburden, damage, or impair the site or
                  interfere with any other party’s use of the Website, including their ability to engage in real time
                  activities through the Website; or use any robot, spider, or other automatic device, process, or means to
                  access the Website for any purpose, including monitoring or copying any of the material on the Website.
                </p>
                <p>
                  The information presented on or through the Website is made available solely for general information
                  purposes. We do not warrant the accuracy, completeness, or usefulness of this information. Any reliance you
                  place on such information is strictly at your own risk. We disclaim all liability and responsibility
                  arising from any reliance placed on such materials by you or any other visitor to the Website, or by anyone
                  who may be informed of any of its contents.
                </p>
                <p>
                  This Website may include content provided by third parties, including materials provided by other users,
                  bloggers, and third-party licensors, syndicators, aggregators, and/or reporting services. All statements
                  and/or opinions expressed in these materials, and all articles and responses to questions and other
                  content, other than the content provided by Provider, are solely the opinions and the responsibility of the
                  person or entity providing those materials. These materials do not necessarily reflect the opinion of
                  Provider. We are not responsible, or liable to you or any third party, for the content or accuracy of any
                  materials provided by any third parties. If the Website contains links to other sites and resources
                  provided by third parties, these links are provided for your convenience only. This includes links
                  contained in advertisements, including banner advertisements and sponsored links. We have no control over
                  the contents of those sites or resources, and accept no responsibility for them or for any loss or damage
                  that may arise from your use of them. If you decide to access any of the third-party websites linked to
                  this Website, you do so entirely at your own risk and subject to the terms and conditions of use for such
                  websites.
                </p>
                <p>
                  TO THE FULLEST EXTENT PROVIDED BY LAW, WE WILL NOT BE LIABLE FOR ANY LOSS OR DAMAGE CAUSED BY A DISTRIBUTED
                  DENIAL-OF-SERVICE ATTACK, VIRUSES, OR OTHER TECHNOLOGICALLY HARMFUL MATERIAL THAT MAY INFECT YOUR COMPUTER
                  EQUIPMENT, COMPUTER PROGRAMS, DATA, OR OTHER PROPRIETARY MATERIAL DUE TO YOUR USE OF THE WEBSITE OR ITEMS
                  OBTAINED THROUGH THE WEBSITE OR TO YOUR DOWNLOADING OF ANY MATERIAL POSTED ON IT, OR ON ANY WEBSITE LINKED
                  TO IT.
                </p>
                <p>
                  THE WEBSITE AND ITS CONTENT ARE PROVIDED ON AN "AS IS" AND "AS AVAILABLE" BASIS, WITHOUT ANY WARRANTIES OF
                  ANY KIND, EITHER EXPRESS OR IMPLIED. NEITHER PROVIDER NOR ANY PERSON ASSOCIATED WITH PROVIDER MAKES ANY
                  WARRANTY OR REPRESENTATION WITH RESPECT TO THE COMPLETENESS, SECURITY, RELIABILITY, QUALITY, ACCURACY, OR
                  AVAILABILITY OF THE WEBSITE. TO THE FULLEST EXTENT PROVIDED BY LAW, PROVIDER HEREBY DISCLAIMS ALL
                  WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED, STATUTORY, OR OTHERWISE, INCLUDING BUT NOT LIMITED TO
                  ANY WARRANTIES OF MERCHANTABILITY, NON-INFRINGEMENT, AND FITNESS FOR PARTICULAR PURPOSE.
                </p>
                <p>THE FOREGOING DOES NOT AFFECT ANY WARRANTIES THAT CANNOT BE EXCLUDED OR LIMITED UNDER APPLICABLE LAW.</p>
                <p>
                  All other feedback, comments, requests for technical support, and other communications relating to the
                  Website should be directed to: <u className="text-primary600">info@hihydra.com .</u>
                </p>
              </div>
            </div>
            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">
                <u>FOR SUBSCRIBERS/CUSTOMERS:</u>
              </h4>

              <div className="my-10 flex flex-col space-y-3">
                <p>
                  By signing-up for Hydra and confirming that you have read and agree to these online Terms and Conditions,
                  You and Provider each signify that you have read, understand, and agree to be bound by the terms and
                  conditions hereof. For good and valuable consideration, the receipt and sufficiency of which are hereby
                  acknowledged, the Parties agree to these terms and conditions through their undersigned authorized
                  representatives.
                </p>
              </div>
            </div>
            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold">1. DEFINITIONS.</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p className="ml-12">
                  1. <strong>"Access Credentials"</strong> means any username, identification number, password, license or
                  security key, security token, PIN, or other security code, method, technology, or device used, alone or in
                  combination, to verify an individual’s identity and authorization to access and use the Services.
                </p>
                <p className="ml-12">
                  2. <strong>"Action"</strong> means any claim, action, cause of action, demand, lawsuit, arbitration,
                  inquiry, audit, notice of violation, proceeding, litigation, citation, summons, subpoena, or investigation
                  of any nature, civil, criminal, administrative, regulatory, or other, whether at law, in equity, or
                  otherwise.
                </p>
                <p className="ml-12">
                  3. <strong>"Aggregated Statistics"</strong> means data and information related to Customer’s use of the
                  Services that is used by Provider in an aggregate and anonymized manner, including to compile statistical
                  and performance information related to the provision and operation of the Services.
                </p>
                <p className="ml-12">
                  4. <strong>"Authorized User"</strong> means Customer’s employees, consultants, contractors, and agents (i)
                  who are authorized by Customer to access and use the Services under the rights granted to Customer pursuant
                  to this Agreement and (ii) for whom access to the Services has been purchased hereunder.
                </p>
                <p className="ml-12">
                  5. <strong>"Customer Data"</strong> means, other than Aggregated Statistics, information, data, and other
                  content, in any form or medium, that is submitted, posted, or otherwise transmitted by or on behalf of
                  Customer or an Authorized User through the Services.
                </p>
                <p className="ml-12">
                  6. <strong>"Customer Systems"</strong> means the Customer’s information technology infrastructure,
                  including computers, software, hardware, databases, electronic systems (including database management
                  systems), and networks, whether operated directly by Customer or through the use of third-party services.
                </p>
                <p className="ml-12">
                  7. <strong>"Disabling Device"</strong> means any software, hardware, or other technology, device, or means
                  (including any back door, time bomb, time out, drop dead device, software routine, or other disabling
                  device) used by Provider or its designee to disable Customer’s or any Authorized User’s access to or use of
                  the Services automatically with the passage of time or under the positive control of Provider or its
                  designee.
                </p>
                <p className="ml-12">
                  8. <strong>"Documentation"</strong> means Provider’s user manuals, handbooks, and guides relating to the
                  Services provided by Provider to Customer either electronically or in hard copy form.
                </p>
                <p className="ml-12">
                  9.<strong>"Intellectual Property Rights"</strong> means any and all registered and unregistered rights
                  granted, applied for, or otherwise now or hereafter in existence under or related to any patent, copyright,
                  trademark, trade secret, database protection, or other intellectual property rights laws, and all similar
                  or equivalent rights or forms of protection, in any part of the world.
                </p>
                <p className="ml-12">
                  10. <strong>"Law"</strong> means any statute, law, ordinance, regulation, rule, code, order, constitution,
                  treaty, common law, judgment, decree, or other requirement of any federal, state, local, or foreign
                  government or political subdivision thereof, or any arbitrator, court, or tribunal of competent
                  jurisdiction.
                </p>
                <p className="ml-12">
                  11. <strong>"Losses"</strong> means any and all losses, damages, deficiencies, claims, actions, judgments,
                  settlements, interest, awards, penalties, fines, costs, or expenses of whatever kind, including reasonable
                  attorneys’ fees and the costs of enforcing any right to indemnification hereunder and the cost of pursuing
                  any insurance providers.
                </p>
                <p className="ml-12">
                  12. <strong>"Person"</strong> means an individual, corporation, partnership, joint venture, limited
                  liability entity, governmental authority, unincorporated organization, trust, association, or other entity.
                </p>
                <p className="ml-12">
                  13. <strong>"Provider IP"</strong> means the Services, the Documentation, and any and all intellectual
                  property provided to Customer or any Authorized User in connection with the foregoing. For the avoidance of
                  doubt, Provider IP includes Aggregated Statistics and any information, data, or other content derived from
                  Provider's monitoring of Customer’s access to or use of the Services, but does not include Customer Data.
                </p>
                <p className="ml-12">
                  14. <strong>"Services"</strong> means the Hydra software-as-a-service offered by Provider.
                </p>
                <p className="ml-12">
                  15. <strong>"Subscription Term"</strong> means the then-current Initial Term or Renewal Term of the
                  applicable Order Form(s) during which Authorized Users are authorized to use or access the Service pursuant
                  to the terms set forth in this Agreement, unless earlier terminated as set forth in Section 13.3.
                </p>
                <p className="ml-12">
                  16. <strong>"Third-Party Products"</strong> means any third-party products provided with or incorporated
                  into the Services.
                </p>
              </div>
            </div>

            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold">2. ACCESS AND USE.</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p className="ml-12">
                  1. <strong>Provision of Access.</strong> Subject to the terms and conditions of this Agreement, Provider
                  hereby grants Customer a non-exclusive, non-transferable (except in compliance with Section 14.6) right to
                  access and use the Services during the Term, solely for use by Authorized Users in accordance with the
                  terms and conditions herein. Such use is limited to Customer’s internal business use and access granted by
                  the Customer to their Customers in the case of an agency. Provider shall provide to Customer the necessary
                  passwords and network links or connections to allow Customer to access the Services.
                </p>
                <p className="ml-12">
                  2. <strong>Documentation License.</strong> Subject to the terms and conditions contained in this Agreement,
                  Provider hereby grants to Customer a non-exclusive, non-sublicenseable, non-transferable (except in
                  compliance with Section 14.6) license to use the Documentation during the Term solely for Customer’s
                  internal business purposes in connection with its use of the Services.
                </p>
                <p className="ml-12">
                  3. <strong>Use Restrictions.</strong> Customer shall not use the Services for any purposes beyond the scope
                  of the access granted in this Agreement. Customer shall not at any time, directly or indirectly, and shall
                  not permit any Authorized Users to: (i) copy, modify, or create derivative works of the Services or
                  Documentation, in whole or in part; (ii) rent, lease, lend, sell, license, sublicense, assign, distribute,
                  publish, transfer, or otherwise make available the Services or Documentation; (iii) reverse engineer,
                  disassemble, decompile, decode, adapt, or otherwise attempt to derive or gain access to any software
                  component of the Services, in whole or in part; (iv) remove any proprietary notices from the Services or
                  Documentation; or (v) use the Services or Documentation in any manner or for any purpose that infringes,
                  misappropriates, or otherwise violates any Intellectual Property Right or other right of any person, or
                  that violates any applicable law.
                </p>
                <p className="ml-12">
                  4. <strong>Reservation of Rights.</strong> Provider reserves all rights not expressly granted to Customer
                  in this Agreement. Except for the limited rights and licenses expressly granted under this Agreement,
                  nothing in this Agreement grants, by implication, waiver, estoppel, or otherwise, to Customer or any third
                  party any Intellectual Property Rights or other right, title, or interest in or to the Provider IP.
                </p>
                <p className="ml-12">
                  5. <strong>Suspension.</strong> Notwithstanding anything to the contrary in this Agreement, Provider may
                  temporarily suspend Customer’s and any Authorized User’s access to any portion or all of the Services if:
                  (i) Provider reasonably determines that (A) there is a threat or attack on any of the Provider IP; (B)
                  Customer’s or any Authorized User’s use of the Provider IP disrupts or poses a security risk to the
                  Provider IP or to any other customer or vendor of Provider; (C) Customer, or any Authorized User, is using
                  the Provider IP for fraudulent or illegal activities; (D) subject to applicable law, Customer has ceased to
                  continue its business in the ordinary course, made an assignment for the benefit of creditors or similar
                  disposition of its assets, or become the subject of any bankruptcy, reorganization, liquidation,
                  dissolution, or similar proceeding; or (E) Provider’s provision of the Services to Customer or any
                  Authorized User is prohibited by applicable law; or (ii) any vendor of Provider has suspended or terminated
                  Provider's access to or use of any third-party services or products required to enable Customer to access
                  the Services. Provider shall use commercially reasonable efforts to provide written notice of any Service
                  Suspension to Customer and to provide updates regarding resumption of access to the Services following any
                  Service Suspension. Provider shall use commercially reasonable efforts to resume providing access to the
                  Services as soon as reasonably possible after the event giving rise to the Service Suspension is cured.
                  Provider will have no liability for any damage, liabilities, losses (including any loss of data or
                  profits), or any other consequences that Customer or any Authorized User may incur as a result of a Service
                  Suspension.
                </p>
                <p className="ml-12">
                  6. <strong>Aggregated Statistics.</strong> Notwithstanding anything to the contrary in this Agreement,
                  Provider may monitor Customer’s use of the Services and collect and compile Aggregated Statistics. As
                  between Provider and Customer, all right, title, and interest in Aggregated Statistics, and all
                  intellectual property rights therein, belong to and are retained solely by Provider. Customer acknowledges
                  that Provider may compile Aggregated Statistics based on Customer Data input into the Services. Customer
                  agrees that Provider may (i) make Aggregated Statistics publicly available in compliance with applicable
                  law, and (ii) use Aggregated Statistics to the extent and in the manner permitted under applicable law;
                  provided that such Aggregated Statistics do not identify Customer or Customer’s Confidential Information.
                </p>
                <p className="ml-12">
                  7. <strong>Service and System Control.</strong> Except as otherwise expressly provided in this Agreement,
                  as between the parties:
                  <p className="ml-12 mt-2">
                    {' '}
                    1. Provider has and will retain sole control over the operation, provision, maintenance, and management
                    of the Provider IP; and{' '}
                  </p>
                  <p className="ml-12 mt-2">
                    2. Customer has and will retain sole control over the operation, maintenance, and management of, and all
                    access to and use of, the Customer Systems, and sole responsibility for all access to and use of the
                    Provider IP by any Person by or through the Customer Systems or any other means controlled by Customer or
                    any Authorized User, including any: (i) information, instructions, or materials provided by any of them
                    to the Services or Provider; (ii) results obtained from any use of the Services or Provider IP; and (iii)
                    conclusions, decisions, or actions based on such use.
                  </p>
                </p>
                <p className="ml-12">
                  8. <strong>Service Management.</strong> Each party shall, throughout the Term, maintain within its
                  organization a service manager to serve as such party’s primary point of contact for day-to-day
                  communications, consultation, and decision-making regarding this Agreement. Each service manager shall be
                  responsible for providing all day-to-day consents and approvals on behalf of such party under this
                  Agreement. Each party shall ensure its service manager has the requisite organizational authority, skill,
                  experience, and other qualifications to perform in such capacity.
                </p>
                <p className="ml-12">
                  9. <strong>Changes.</strong> Provider reserves the right, in its sole discretion, to make any changes to
                  the Services and Provider IP that it deems necessary or useful to: (a) maintain or enhance: (i) the quality
                  or delivery of Provider’s services to its customers; (ii) the competitive strength of or market for
                  Provider’s services; or (iii) the Services’ cost efficiency or performance; or (b) to comply with
                  applicable Law. Without limiting the foregoing, either party may, at any time during the Term, request in
                  writing changes to the Services. The parties shall evaluate and, if agreed, implement all such requested
                  changes. No requested changes will be effective unless and until memorialized in a written change order
                  signed by both parties.
                </p>
                <p className="ml-12">
                  10. <strong>Subcontractors.</strong> Provider may from time to time in its discretion engage third parties
                  to perform Services (each, a <strong>"Subcontractor"</strong>).
                </p>
                <p className="ml-12">
                  11. <strong>Suspension or Termination of Services.</strong> Provider may, directly or indirectly, and by
                  use of a Provider Disabling Device or any other lawful means, suspend, terminate, or otherwise deny
                  Customer’s, any Authorized User’s, or any other Person’s access to or use of all or any part of the
                  Services or Provider IP, without incurring any resulting obligation or liability, if: (a) Provider receives
                  a judicial or other governmental demand or order, subpoena, or law enforcement request that expressly or by
                  reasonable implication requires Provider to do so; or (b) Provider believes, in its sole discretion, that:
                  (i) Customer or any Authorized User has failed to comply with any term of this Agreement, or accessed or
                  used the Services beyond the scope of the rights granted or for a purpose not authorized under this
                  Agreement or in any manner that does not comply with any instruction or requirement of the Specifications;
                  (ii) Customer or any Authorized User is, has been, or is likely to be involved in any fraudulent,
                  misleading, or unlawful activities; or (iii) this Agreement expires or is terminated. This Section 2.11
                  does not limit any of Provider’s other rights or remedies, whether at law, in equity, or under this
                  Agreement.
                </p>
              </div>
            </div>

            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold">3. CUSTOMERS OBLIGATIONS.</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p className="ml-12">
                  1. <strong>General.</strong> Customer is responsible and liable for all uses of the Services and
                  Documentation resulting from access provided by Customer, directly or indirectly, whether such access or
                  use is permitted by or in violation of this Agreement. Without limiting the generality of the foregoing,
                  Customer is responsible for all acts and omissions of Authorized Users, and any act or omission by an
                  Authorized User that would constitute a breach of this Agreement if taken by Customer will be deemed a
                  breach of this Agreement by Customer. Customer shall use reasonable efforts to make all Authorized Users
                  aware of this Agreement’s provisions as applicable to such Authorized User’s use of the Services, and shall
                  cause Authorized Users to comply with such provisions.
                </p>
                <p className="ml-12">
                  2. <strong>Third-Party Products.</strong> Provider may from time to time make Third-Party Pro this
                  Agreement, such Third-Party Products are subject to their own terms and conditions. If Customer does not
                  agree to abide by the applicable terms for any such Third-Party Products, then Customer should not install
                  or use such Third-Party Products.
                </p>
              </div>
            </div>

            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold">4. SERVICE.</h4>
              <span>
                Subject to the terms and conditions of this Agreement, Provider shall use commercially reasonable efforts to
                make the Services available.
              </span>
            </div>

            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold">5. DATA BACKUP.</h4>
              <span>
                The Services do not replace the need for Customer to maintain regular data backups or redundant data
                archives. PROVIDER HAS NO OBLIGATION OR LIABILITY FOR ANY LOSS, ALTERATION, DESTRUCTION, DAMAGE, CORRUPTION,
                OR RECOVERY OF CUSTOMER DATA.
              </span>
            </div>

            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold">6. SECURITY.</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p className="ml-12">
                  1. <strong>Information Security.</strong> Provider will employ security measures in accordance with
                  Provider’s data privacy and security policy as amended from time to time.
                </p>
                <p className="ml-12">
                  2. <strong>Customer Control and Responsibility.</strong> Customer has and will retain sole responsibility
                  for: (a) all Customer Data, including its content and use; (b) all information, instructions, and materials
                  provided by or on behalf of Customer or any Authorized User in connection with the Services; (c) Customer’s
                  information technology infrastructure, including computers, software, databases, electronic systems
                  (including database management systems), and networks, whether operated directly by Customer or through the
                  use of third-party services (<strong>"Customer Systems"</strong>); (d) the security and use of Customer’s
                  and its Authorized Users’ Access Credentials; and (e) all access to and use of the Services and Provider IP
                  directly or indirectly by or through the Customer Systems or its or its Authorized Users’ Access
                  Credentials, with or without Customer’s knowledge or consent, including all results obtained from, and all
                  conclusions, decisions, and actions based on, such access or use.
                </p>
                <p className="ml-12">
                  3. <strong>Access and Security.</strong> Customer shall employ all physical, administrative, and technical
                  controls, screening, and security procedures and other safeguards necessary to: (a) securely administer the
                  distribution and use of all Access Credentials and protect against any unauthorized access to or use of the
                  Services; and (b) control the content and use of Customer Data, including the uploading or other provision
                  of Customer Data for Processing by the Services.
                </p>
              </div>
            </div>

            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold">7. FEES AND PAYMENT.</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p className="ml-12">
                  1. <strong>Fees.</strong> Customer shall pay all fees specified in all applicable Order Form(s) (
                  <strong>"Fees"</strong>). Except as otherwise specified herein or in any Order Form, all Fees are quoted
                  and payable in GBP, payment obligations are non-cancelable, and Fees paid are non-refundable. Fees for the
                  Service are based on Subscriptions purchased and not actual usage. For purposes of clarity, the
                  Subscription purchased cannot be decreased during a Term.
                </p>
                <p className="ml-12">
                  2. <strong>Payment.</strong> Unless otherwise set forth in an Order Form, Customer will pay to Provider (by
                  wire transfer or other method mutually acceptable to the Parties) all Fees on receipt of the applicable
                  invoice. Late payment of Fees may be subject to interest on the past due amount at the lesser of 1.5% per
                  month or the maximum rate permitted by applicable law. Customer is responsible for providing complete and
                  accurate billing address and contact information to Provider. If Customer believes a particular invoice is
                  incorrect, Customer must contact Provider in writing at info@hihydra.com within sixty (60) days of such
                  invoice date to be eligible to receive an adjustment or credit.
                </p>
                <p className="ml-12">
                  3. <strong>Taxes.</strong> All Fees and other amounts payable by Customer under this Agreement are
                  exclusive of taxes and similar assessments. Without limiting the foregoing, Customer is responsible for all
                  sales, use, and excise taxes, and any other similar taxes, duties, and charges of any kind imposed by any
                  federal, state, or local governmental or regulatory authority on any amounts payable by Customer hereunder,
                  other than any taxes imposed on Provider’s income.
                </p>
                <p className="ml-12">
                  4. <strong>Suspension.</strong> If Customer is thirty (30) days or more overdue (except for charges then
                  under reasonable and good faith dispute), then, following five (5) business days’ written notice and
                  opportunity to cure (which notice may be provided via email), in addition to any of its other rights or
                  remedies, Provider reserves the right but not obligation to suspend Customer’s access to the Service until
                  such amounts are paid in full. For purpose of clarity, and avoidance of doubt, Customer will continue to be
                  charged for the Subscription during any period of suspension.
                </p>
              </div>
            </div>

            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold">8. CONFIDENTIAL INFORMATION.</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p className="ml-12">
                  1. <strong>Confidential Information.</strong> In connection with this Agreement each party (as the
                  <strong>"Disclosing Party"</strong>) may disclose or make available Confidential Information to the other
                  party (as the <strong>"Receiving Party"</strong>). Subject to Section 8.2,{' '}
                  <strong>"Confidential Information"</strong> means information in any form or medium (whether oral, written,
                  electronic, or other) that the Disclosing Party considers confidential or proprietary, including
                  information consisting of or relating to the Disclosing Party’s technology, trade secrets, know-how,
                  business operations, plans, strategies, customers, and pricing, and information with respect to which the
                  Disclosing Party has contractual or other confidentiality obligations, in each case whether or not marked,
                  designated, or otherwise identified as "confidential". Without limiting the foregoing: all Provider IP are
                  the Confidential Information of Provider and the financial terms and existence of this Agreement are the
                  Confidential Information of each of the Parties.
                </p>
                <p className="ml-12">
                  2. <strong>Exclusions.</strong> Confidential Information does not include information that: (a) was
                  rightfully known to the Receiving Party without restriction on use or disclosure prior to such
                  information’s being disclosed or made available to the Receiving Party in connection with this Agreement;
                  (b) was or becomes generally known by the public other than by the Receiving Party’s or any of its
                  Representatives’ noncompliance with this Agreement; (c) was or is received by the Receiving Party on a
                  non-confidential basis from a third party that, to the Receiving Party’s knowledge, was not or is not, at
                  the time of such receipt, under any obligation to maintain its confidentiality; or (d) was or is
                  independently developed by the Receiving Party without reference to or use of any Confidential Information.
                </p>
                <p className="ml-12">
                  3. <strong>Protection of Confidential Information.</strong> As a condition to being provided with any
                  disclosure of or access to Confidential Information, the Receiving Party shall:
                  <p className="ml-12 mt-2">
                    1. not access or use Confidential Information other than as necessary to exercise its rights or perform
                    its obligations under and in accordance with this Agreement;
                  </p>
                  <p className="ml-12  mt-2">
                    2. except as may be permitted by and subject to its compliance with Section 8.4, not disclose or permit
                    access to Confidential Information other than to its Representatives who: (i) need to know such
                    Confidential Information for purposes of the Receiving Party’s exercise of its rights or performance of
                    its obligations under and in accordance with this Agreement; (ii) have been informed of the confidential
                    nature of the Confidential Information and the Receiving Party’s obligations under this Section 8.3; and
                    (iii) are bound by [written] confidentiality and restricted use obligations at least as protective of the
                    Confidential Information as the terms set forth in this Section 8;
                  </p>
                  <p className="ml-12  mt-2">
                    3. safeguard the Confidential Information from unauthorized use, access, or disclosure using at least the
                    degree of care it uses to protect its similarly sensitive information and in no event less than a
                    reasonable degree of care;
                  </p>
                  <p className="ml-12  mt-2">
                    4. promptly notify the Disclosing Party of any unauthorized use or disclosure of Confidential Information
                    and use its best efforts to prevent further unauthorized use or disclosure; and
                  </p>
                  <p className="ml-12  mt-2">
                    5. ensure its Representatives’ compliance with, and be responsible and liable for any of its
                    Representatives’ non-compliance with, the terms of this Section 8.
                  </p>
                  <p className="ml-12  mt-2">
                    6. Notwithstanding any other provisions of this Agreement, the Receiving Party’s obligations under this
                    Section 8 with respect to any Confidential Information that constitutes a trade secret under any
                    applicable Law will continue until such time, if ever, as such Confidential Information ceases to qualify
                    for trade secret protection under one or more such applicable Laws other than as a result of any act or
                    omission of the Receiving Party or any of its Representatives.
                  </p>
                </p>
                <p className="ml-12">
                  4. <strong>Compelled Disclosures.</strong> If the Receiving Party or any of its Representatives is
                  compelled by applicable Law to disclose any Confidential Information then, to the extent permitted by
                  applicable Law, the Receiving Party shall: (a) promptly, and prior to such disclosure, notify the
                  Disclosing Party in writing of such requirement so that the Disclosing Party can seek a protective order or
                  other remedy or waive its rights under Section 8.3; and (b) provide reasonable assistance to the Disclosing
                  Party, at the Disclosing Party’s sole cost and expense, in opposing such disclosure or seeking a protective
                  order or other limitations on disclosure. If the Disclosing Party waives compliance or, after providing the
                  notice and assistance required under this Section 8.4, the Receiving Party remains required by Law to
                  disclose any Confidential Information, the Receiving Party shall disclose only that portion of the
                  Confidential Information that the Receiving Party is legally required to disclose and, on the Disclosing
                  Party’s request, shall use commercially reasonable efforts to obtain assurances from the applicable court
                  or other presiding authority that such Confidential Information will be afforded confidential treatment.
                </p>
              </div>
            </div>

            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold">9. INTELLECTUAL PROPERTY OWNERSHIP; FEEDBACK.</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p className="ml-12">
                  1. <strong>Provider IP.</strong> Customer acknowledges that, as between Customer and Provider, Provider
                  owns all right, title, and interest, including all intellectual property rights, in and to the Provider IP
                  and, with respect to Third-Party Products, the applicable third-party providers own all right, title, and
                  interest, including all intellectual property rights, in and to the Third-Party Products.
                </p>
                <p className="ml-12">
                  2. <strong>Customer Data.</strong> Provider acknowledges that, as between Provider and Customer, Customer
                  owns all right, title, and interest, including all intellectual property rights, in and to the Customer
                  Data. Customer hereby grants to Provider a non-exclusive, royalty-free, worldwide license to reproduce,
                  distribute, and otherwise use and display the Customer Data and perform all acts with respect to the
                  Customer Data as may be necessary for Provider to provide the Services to Customer, and a non-exclusive,
                  perpetual, irrevocable, royalty-free, worldwide license to reproduce, distribute, modify, and otherwise use
                  and display Customer Data incorporated within the Aggregated Statistics.
                </p>
                <p className="ml-12">
                  3. <strong>Feedback.</strong> If Customer or any of its employees or contractors sends or transmits any
                  communications or materials to Provider by mail, email, telephone, or otherwise, suggesting or recommending
                  changes to the Provider IP, including without limitation, new features or functionality relating thereto,
                  or any comments, questions, suggestions, or the like ("Feedback"), Provider is free to use such Feedback
                  irrespective of any other obligation or limitation between the Parties governing such Feedback. Customer
                  hereby assigns to Provider on Customer’s behalf, and on behalf of its employees, contractors and/or agents,
                  all right, title, and interest in, and Provider is free to use, without any attribution or compensation to
                  any party, any ideas, know-how, concepts, techniques, or other intellectual property rights contained in
                  the Feedback, for any purpose whatsoever, although Provider is not required to use any Feedback.
                </p>
              </div>
            </div>

            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold">10. REPRESENTATIONS AND WARRANTIES.</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p className="ml-12">
                  1. <strong>Mutual Representations and Warranties.</strong> Each Party represents and warrants to the other
                  Party that:
                  <p className="ml-12 mt-2">
                    1. it is duly organized, validly existing, and in good standing as a corporation or other entity under
                    the Laws of the jurisdiction of its incorporation or other organization;
                  </p>
                  <p className="ml-12 mt-2">
                    2. it has the full right, power, and authority to enter into and perform its obligations and grant the
                    rights, licenses, consents, and authorizations it grants or is required to grant under this Agreement;
                  </p>
                  <p className="ml-12 mt-2">
                    3. the execution of this Agreement by its representative whose signature is set forth at the end of this
                    Agreement has been duly authorized by all necessary corporate or organizational action of such party; and
                  </p>
                  <p className="ml-12 mt-2">
                    4. when executed and delivered by both parties, this Agreement will constitute the legal, valid, and
                    binding obligation of such party, enforceable against such party in accordance with its terms.
                  </p>
                </p>
                <p className="ml-12">
                  2. <strong>Additional Provider Representations, Warranties, and Covenants.</strong> Provider represents,
                  warrants, and covenants to Customer that Provider will perform the Services using personnel of required
                  skill, experience, and qualifications and in a professional and workmanlike manner in accordance with
                  generally recognized industry standards for similar services and will devote adequate resources to meet its
                  obligations under this Agreement.
                </p>
                <p className="ml-12">
                  3. <strong>Additional Customer Representations, Warranties, and Covenants.</strong> Customer represents,
                  warrants, and covenants to Provider that Customer owns or otherwise has and will have the necessary rights
                  and consents in and relating to the Customer Data so that, as received by Provider and processed in
                  accordance with this Agreement, they do not and will not infringe, misappropriate, or otherwise violate any
                  Intellectual Property Rights, or any privacy or other rights, of any third party or violate any applicable
                  Law.
                </p>
                <p className="ml-12">
                  4. <strong>DISCLAIMER OF WARRANTIES.</strong> EXCEPT FOR THE EXPRESS WARRANTIES SET FORTH IN SECTION 10.1
                  AND SECTION 10.2, ALL SERVICES AND PROVIDER IP ARE PROVIDED "AS IS." PROVIDER SPECIFICALLY DISCLAIMS ALL
                  IMPLIED WARRANTIES OF MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE, TITLE, AND NON-INFRINGEMENT, AND
                  ALL WARRANTIES ARISING FROM COURSE OF DEALING, USAGE, OR TRADE PRACTICE. WITHOUT LIMITING THE FOREGOING,
                  PROVIDER MAKES NO WARRANTY OF ANY KIND THAT THE SERVICES OR PROVIDER IP, OR ANY PRODUCTS OR RESULTS OF THE
                  USE THEREOF, WILL MEET CUSTOMER’S OR ANY OTHER PERSON’S REQUIREMENTS, OPERATE WITHOUT INTERRUPTION, ACHIEVE
                  ANY INTENDED RESULT, BE COMPATIBLE OR WORK WITH ANY SOFTWARE, SYSTEM, OR OTHER SERVICES, OR BE SECURE,
                  ACCURATE, COMPLETE, FREE OF HARMFUL CODE, OR ERROR FREE. ALL THIRD-PARTY MATERIALS ARE PROVIDED "AS IS" AND
                  ANY REPRESENTATION OR WARRANTY OF OR CONCERNING ANY THIRD-PARTY MATERIALS IS STRICTLY BETWEEN CUSTOMER AND
                  THE THIRD-PARTY OWNER OR DISTRIBUTOR OF THE THIRD-PARTY MATERIALS.
                </p>
              </div>
            </div>

            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold">11. INDEMNIFICATION.</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p className="ml-12">
                  1. <strong>Provider Indemnification.</strong> Provider shall indemnify, defend, and hold harmless Customer
                  and Customer’s officers, directors, employees, agents, permitted successors, and permitted assigns (each, a
                  <strong>"Customer Indemnitee"</strong>) from and against any and all Losses incurred by Customer Indemnitee
                  resulting from any Action by a third party (other than an Affiliate of a Customer Indemnitee) that
                  Customer’s use of the Services (excluding Customer Data and Third-Party Materials) in accordance with this
                  Agreement (including the Specifications) infringes or misappropriates such third party’s US Intellectual
                  Property Rights. The foregoing obligation does not apply to the extent that the alleged infringement arises
                  from:
                  <p className="ml-12 mt-2">1. Third-Party Materials or Customer Data;</p>
                  <p className="ml-12 mt-2">
                    2. access to or use of the Provider IP in combination with any hardware, system, software, network, or
                    other materials or service not provided by Provider or specified for Customer’s use in the Documentation,
                    unless otherwise expressly permitted by Provider in writing;
                  </p>
                  <p className="ml-12 mt-2">
                    3. modification of the Provider IP other than: (i) by or on behalf of Provider; or (ii) with Provider’s
                    written approval in accordance with Provider’s written specification;
                  </p>
                  <p className="ml-12 mt-2">
                    4. failure to timely implement any modifications, upgrades, replacements, or enhancements made available
                    to Customer by or on behalf of Provider; or
                  </p>
                  <p className="ml-12 mt-2">
                    5. act, omission, or other matter described in Section 12.1.1, Section 12.1.2, Section 12.1.3, or Section
                    12.1.4, whether or not the same results in any Action against or Losses by any Provider Indemnitee
                  </p>
                </p>
                <p className="ml-12 mt-2">
                  Customer Indemnification. Customer shall indemnify, defend, and each of its respective officers, directors,
                  employees, agents, successors, and assigns (each, a "Provider Indemnitee") from and against any and all
                  Losses incurred by such Provider Indemnitee resulting from any Action by a third party (other than an
                  Affiliate of a Provider Indemnitee) that arise out of or result from, or are alleged to arise out of or
                  result from:
                  <p className="ml-12 mt-2">
                    1. Customer Data, including any Processing of Customer Data by or on behalf of Provider in accordance
                    with this Agreement;
                  </p>
                  <p className="ml-12 mt-2">
                    2. any other materials or information (including any documents, data, specifications, software, content,
                    or technology) provided by or on behalf of Customer or any Authorized User, including Provider’s
                    compliance with any specifications or directions provided by or on behalf of Customer or any Authorized
                    User to the extent prepared without any contribution by Provider;
                  </p>
                  <p className="ml-12 mt-2">
                    3. allegation of facts that, if true, would constitute Customer’s breach of any of its representations,
                    warranties, covenants, or obligations under this Agreement; or
                  </p>
                  <p className="ml-12 mt-2">
                    4. negligence or more culpable act or omission (including recklessness or willful misconduct) by
                    Customer, any Authorized User, or any third party on behalf of Customer or any Authorized User, in
                    connection with this Agreement.
                  </p>
                </p>
                <p className="ml-12">
                  3. <strong>Indemnification Procedure.</strong> Each party shall promptly notify the other party in writing
                  of any Action for which such party believes it is entitled to be indemnified pursuant to Section 11.1 or
                  Section 11.2, as the case may be. The party seeking indemnification (the "Indemnitee") shall cooperate with
                  the other party (the "Indemnitor") at the Indemnitor’s sole cost and expense. The Indemnitor shall promptly
                  assume control of the defense and shall employ counsel reasonably acceptable to the Indemnitee to handle
                  and defend the same, at the Indemnitor’s sole cost and expense. The Indemnitee may participate in and
                  observe the proceedings at its own cost and expense with counsel of its own choosing. The Indemnitor shall
                  not settle any Action on any terms or in any manner that adversely affects the rights of any Indemnitee
                  without the Indemnitee’s prior written consent, which shall not be unreasonably withheld or delayed. If the
                  Indemnitor fails or refuses to assume control of the defense of such Action, the Indemnitee shall have the
                  right, but no obligation, to defend against such Action, including settling such Action after giving notice
                  to the Indemnitor, in each case in such manner and on such terms as the Indemnitee may deem appropriate.
                  The Indemnitee’s failure to perform any obligations under this Section 11.3 will not relieve the Indemnitor
                  of its obligations under this Section 11, except to the extent that the Indemnitor can demonstrate that it
                  has been materially prejudiced as a result of such failure.
                </p>
                <p className="ml-12">
                  4.<strong>Mitigation.</strong> If any of the Services or Provider IP are, or in Provider’s opinion are
                  likely to be, claimed to infringe, misappropriate, or otherwise violate any third-party Intellectual
                  Property Right, or if Customer’s or any Authorized User’s use of the Services or Provider IP is enjoined or
                  threatened to be enjoined, Provider may, at its option and sole cost and expense:
                  <p className="ml-12 mt-2">
                    1. obtain the right for Customer to continue to use the Services and Provider IP as contemplated by this
                    Agreement; or
                  </p>
                  <p className="ml-12 mt-2">
                    2. modify or replace the Services and Provider IP, in whole or in part, to seek to make the Services and
                    Provider IP (as so modified or replaced) non-infringing, while providing materially equivalent features
                    and functionality, in which case such modifications or replacements will constitute Services and Provider
                    IP, as applicable, under this Agreement.
                  </p>
                </p>
                <p className="ml-12">
                  5. <strong>Sole Remedy.</strong> THIS SECTION 12 SETS FORTH CUSTOMER’S SOLE REMEDIES AND PROVIDER’S SOLE
                  LIABILITY AND OBLIGATION FOR ANY ACTUAL, THREATENED, OR ALLEGED CLAIMS THAT THE SERVICES AND PROVIDER IP OR
                  ANY SUBJECT MATTER OF THIS AGREEMENT INFRINGES, MISAPPROPRIATES, OR OTHERWISE VIOLATES ANY INTELLECTUAL
                  PROPERTY RIGHTS OF ANY THIRD PARTY.
                </p>
              </div>
            </div>

            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold">12. LIMITATIONS OF LIABILITY.</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p className="ml-12">
                  1. <strong>EXCLUSION OF DAMAGES.</strong> IN NO EVENT WILL EITHER PARTY BE LIABLE UNDER OR IN CONNECTION
                  WITH THIS AGREEMENT OR ITS SUBJECT MATTER UNDER ANY LEGAL OR EQUITABLE THEORY, INCLUDING BREACH OF
                  CONTRACT, TORT (INCLUDING NEGLIGENCE), STRICT LIABILITY, AND OTHERWISE, FOR ANY: (a) LOSS OF PRODUCTION,
                  USE, BUSINESS, REVENUE, OR PROFIT OR DIMINUTION IN VALUE; (b) IMPAIRMENT, INABILITY TO USE OR LOSS,
                  INTERRUPTION OR DELAY OF THE SERVICES, OTHER THAN FOR THE ISSUANCE OF ANY APPLICABLE SERVICE CREDITS
                  PURSUANT TO THE SLA; (c) LOSS, DAMAGE, CORRUPTION OR RECOVERY OF DATA, OR BREACH OF DATA OR SYSTEM
                  SECURITY; (d) COST OF REPLACEMENT GOODS OR SERVICES; (e) LOSS OF GOODWILL OR REPUTATION; OR (f)
                  CONSEQUENTIAL, INCIDENTAL, INDIRECT, EXEMPLARY, SPECIAL, ENHANCED, OR PUNITIVE DAMAGES, REGARDLESS OF
                  WHETHER SUCH PERSONS WERE ADVISED OF THE POSSIBILITY OF SUCH LOSSES OR DAMAGES OR SUCH LOSSES OR DAMAGES
                  WERE OTHERWISE FORESEEABLE, AND NOTWITHSTANDING THE FAILURE OF ANY AGREED OR OTHER REMEDY OF ITS ESSENTIAL
                  PURPOSE.
                </p>
                <p className="ml-12">
                  2. <strong>CAP ON MONETARY LIABILITY.</strong> EXCEPT FOR PROVIDER’S INDEMNIFICATION AND CONFIDENTIALITY
                  OBLIGATIONS HEREUNDER, IN NO EVENT WILL THE COLLECTIVE AGGREGATE LIABILITY OF PROVIDER ARISING OUT OF OR
                  RELATED TO THIS AGREEMENT, WHETHER ARISING UNDER OR RELATED TO BREACH OF CONTRACT, TORT (INCLUDING
                  NEGLIGENCE), STRICT LIABILITY, OR ANY OTHER LEGAL OR EQUITABLE THEORY, EXCEED THE TOTAL AMOUNTS PAID TO
                  PROVIDER UNDER THIS AGREEMENT. THE FOREGOING LIMITATIONS APPLY EVEN IF ANY REMEDY FAILS OF ITS ESSENTIAL
                  PURPOSE.
                </p>
              </div>
            </div>

            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold">13. TERM AND TERMINATION.</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p className="ml-12">
                  1. <strong>Term of Agreement.</strong> The <strong>"Term"</strong> of the Agreement shall be the duration
                  of the then-current Initial Term or Renewal Term(s) of the applicable Order Form(s). For purposes of
                  clarity, the Term of this Agreement will commence on the Effective Date specified in the first Order Form
                  and will continue until either (a) the Order Form(s) are terminated in accordance with Section 13.3 herein,
                  or (b) all Order Forms under this Agreement have expired.
                </p>
                <p className="ml-12">
                  2. <strong>Term of Subscriptions.</strong> The <strong>"Initial Term"</strong> of each Order Form will
                  commence on the Effective Date set forth on such Order Form and will continue for the subscription period
                  outlined on such Order Form. Unless otherwise set forth on the relevant Order Form, each Order Form will
                  automatically renew after the Initial Term for successive one-month periods or twelve-month periods,
                  whichever is applicable according to the billing cycle on the relevant Order Form (each a "Renewal Term"),
                  unless either Party gives prior written notice (Section 14.1) of its intent not to renew such Order Form at
                  least thirty (30) days prior to the end of the Initial Term or then-current Renewal Term.
                </p>
                <p className="ml-12">
                  3. <strong>Termination.</strong> A Party may terminate this Agreement, an Order Form, or a Statement of
                  Work for cause: (i) if the other Party is in material breach under this Agreement and fails to cure such
                  breach within thirty (30) days of receipt of written notice (Section 14.1) of such material breach from the
                  non-breaching Party; or (ii) if the other Party becomes the subject of a petition in bankruptcy or any
                  other proceeding relating to insolvency, receivership, liquidation or assignment for the benefit of
                  creditors and such proceeding is not favourably resolved within sixty (60) days.
                </p>
                <p className="ml-12">
                  4. <strong>Return of Customer Data.</strong> At any time during the then-current Term, Provider will
                  provide Customer with access to Customer Data in the then-current standard export format or another
                  industry-standard format mutually agreed by the Parties. In the event this Agreement is terminated, if
                  Customer requests Customer Data within 30 days of termination, Provider will make available to Customer an
                  electronic copy of the Customer Data for an additional fee at Provider’s then-current rates. After such
                  30-day period, Provider shall have no obligation to maintain or provide any Customer Data and shall
                  thereafter, unless legally prohibited, delete all Customer Data in its systems or otherwise in its
                  possession or under its control in accordance with Provider’s then-current practices on the same. Provider
                  will not provide extracts of Customer Data unless all Fees have been paid by Customer. Provider’s
                  obligations under this Section 14.4.2 do not apply to any Aggregated Statistics.
                </p>
                <p className="ml-12">
                  5. <strong>Effect of Expiration or Termination.</strong> Upon any expiration or termination of this
                  Agreement, except as expressly otherwise provided in this Agreement:
                  <p className="ml-12 mt-2">
                    1. all rights, licenses, consents, and authorizations granted by either party to the other hereunder will
                    immediately terminate;
                  </p>
                  <p className="ml-12 mt-2">
                    2. Customer shall immediately cease all use of any Services or Provider IP and (i) promptly return to
                    Provider, or at Provider’s written request destroy, all documents and tangible materials containing,
                    reflecting, incorporating, or based on any Provider IP or Provider’s Confidential Information; and (ii)
                    permanently erase all Provider IP and Provider’s Confidential Information from all systems Customer
                    directly or indirectly controls; and (iii) certify to Provider in a signed written instrument that it has
                    complied with the requirements of this Section 13.5.2;
                  </p>
                  <p className="ml-12 mt-2">
                    3. notwithstanding anything to the contrary in this Agreement, with respect to information and materials
                    then in its possession or control: (i) the Receiving Party may retain the Disclosing Party’s Confidential
                    Information; (ii) Provider may retain Customer Data; and (iii) Customer may retain Provider IP, in the
                    case of each of subclause (i), (ii) and (iii) in its then current state and solely to the extent and for
                    so long as required by applicable Law; (iv) Provider may also retain Customer Data in its backups,
                    archives, and disaster recovery systems until such Customer Data is deleted in the ordinary course; and
                    (v) all information and materials described in this Section 14.4.4 will remain subject to all
                    confidentiality, security, and other applicable requirements of this Agreement;
                  </p>
                  <p className="ml-12 mt-2">
                    4. Provider may disable all Customer and Authorized User access to the Provider IP;
                  </p>
                  <p className="ml-12 mt-2">
                    5. if Customer terminates this Agreement due to material breach by Provider, Customer will be relieved of
                    any obligation to pay any Fees attributable to the period after the effective date of such termination
                    and Provider will refund to Customer Fees paid in advance for Services that Provider has not performed as
                    of the effective date of termination; and
                  </p>
                  <p className="ml-12 mt-2">
                    6. if Provider terminates this Agreement pursuant to Section 13.3, all Fees that would have become
                    payable had the Agreement remained in effect until expiration of the Term will become immediately due and
                    payable, and Customer shall pay such Fees, together with all previously-accrued but not yet paid Fees, on
                    receipt of Provider’s invoice therefor.
                  </p>
                </p>
                <p className="ml-12">
                  6. <strong>Surviving Terms.</strong> The provisions set forth in the following sections, and any other
                  right or obligation of the parties in this Agreement that, by its nature, should survive termination or
                  expiration of this Agreement, will survive any expiration or termination of this Agreement: Section 2.3,
                  Section 8, Section 10.4, Section 11, Section 12, Section 13.4, this Section 13.6, and Section 14.
                </p>
              </div>
            </div>

            <div className="my-5 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold">14. MISCELLANEOUS.</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p className="ml-12">
                  1. <strong>Notices.</strong> All notices, requests, consents, claims, demands, waivers, and other
                  communications hereunder (each, a <strong>"Notice"</strong>) must be in writing and addressed to the
                  Parties at the addresses set forth on the Order Form(s) (or to such other address that may be designated by
                  the Party giving Notice from time to time in accordance with this Section). All Notices must be delivered
                  by personal delivery, nationally recognized overnight courier (with all fees pre-paid), or email (with
                  confirmation of transmission) or certified or registered mail (in each case, return receipt requested,
                  postage pre-paid). Except as otherwise provided in this Agreement, a Notice is effective only: (a) upon
                  receipt by the receiving Party; and (b) if the Party giving the Notice has complied with the requirements
                  of this Section.
                </p>
                <p className="ml-12">
                  2. <strong>Force Majeure.</strong> In no event shall either Party be liable to the other Party, or be
                  deemed to have breached this Agreement, for any failure or delay in performing its obligations under this
                  Agreement, if and to the extent such failure or delay is caused by any circumstances beyond such Party’s
                  reasonable control, including but not limited to acts of God, flood, fire, earthquake, explosion, war,
                  terrorism, invasion, riot or other civil unrest, strikes, labour stoppages or slowdowns or other industrial
                  disturbances, or passage of law or any action taken by a governmental or public authority, including
                  imposing an embargo.
                </p>
                <p className="ml-12">
                  3. <strong>Amendment and Modification; Waiver.</strong> No amendment to or modification of this Agreement
                  is effective unless it is in writing and signed by an authorized representative of each Party. No waiver by
                  any Party of any of the provisions hereof will be effective unless explicitly set forth in writing and
                  signed by the Party so waiving. Except as otherwise set forth in this Agreement, (i) no failure to
                  exercise, or delay in exercising, any rights, remedy, power, or privilege arising from this Agreement will
                  operate or be construed as a waiver thereof and (ii) no single or partial exercise of any right, remedy,
                  power, or privilege hereunder will preclude any other or further exercise thereof or the exercise of any
                  other right, remedy, power, or privilege.
                </p>
                <p className="ml-12">
                  4. <strong>Severability.</strong> If any provision of this Agreement is invalid, illegal, or unenforceable
                  in any jurisdiction, such invalidity, illegality, or unenforceability will not affect any other term or
                  provision of this Agreement or invalidate or render unenforceable such term or provision in any other
                  jurisdiction. Upon such determination that any term or other provision is invalid, illegal, or
                  unenforceable, the Parties shall negotiate in good faith to modify this Agreement so as to effect their
                  original intent as closely as possible in a mutually acceptable manner in order that the transactions
                  contemplated hereby be consummated as originally contemplated to the greatest extent possible.
                </p>
                <p className="ml-12">
                  5.<strong>Governing Law;</strong> Submission to Jurisdiction. This Agreement is governed by and construed
                  in accordance with the internal laws of the United Kingdom without giving effect to any choice or conflict
                  of law provision or rule that would require or permit the application of the laws of any jurisdiction other
                  than those of the UK. Any legal suit, action, or proceeding arising out of [or related to] this Agreement
                  or the licenses granted hereunder will be instituted exclusively in the courts of the United Kingdom and
                  each Party irrevocably submits to the exclusive jurisdiction of such courts in any such suit, action, or
                  proceeding.
                </p>
                <p className="ml-12">
                  6. <strong>Assignment.</strong> Customer may not assign any of its rights or delegate any of its
                  obligations hereunder, in each case whether voluntarily, involuntarily, by operation of law or otherwise,
                  without the prior written consent of Provider, which consent shall not be unreasonably withheld,
                  conditioned, or delayed. Any purported assignment or delegation in violation of this Section will be null
                  and void. No assignment or delegation will relieve the assigning or delegating Party of any of its
                  obligations hereunder. This Agreement is binding upon and inures to the benefit of the Parties and their
                  respective permitted successors and assigns.
                </p>
                <p className="ml-12">
                  7. <strong>Export Regulation.</strong> Customer shall comply with all applicable federal laws, regulations,
                  and rules, and complete all required undertakings (including obtaining any necessary export license or
                  other governmental approval), that prohibit or restrict the export or re-export of the Services or any
                  Customer Data outside the US.
                </p>
                <p className="ml-12">
                  8. <strong>Equitable Relief.</strong> Each Party acknowledges and agrees that a breach or threatened breach
                  by such Party of any of its obligations under Section 5 or, in the case of Customer, Section 2.3 would
                  cause the other Party irreparable harm for which monetary damages would not be an adequate remedy and
                  agrees that, in the event of such breach or threatened breach, the other Party will be entitled to
                  equitable relief, including a restraining order, an injunction, specific performance and any other relief
                  that may be available from any court, without any requirement to post a bond or other security, or to prove
                  actual damages or that monetary damages are not an adequate remedy. Such remedies are not exclusive and are
                  in addition to all other remedies that may be available at law, in equity or otherwise.
                </p>
                <p className="ml-12">
                  9. <strong>Entire Agreement.</strong> This Agreement, together with any other documents incorporated herein
                  by reference and all related Exhibits, constitutes the sole and entire agreement of the Parties with
                  respect to the subject matter of this Agreement and supersedes all prior and contemporaneous
                  understandings, agreements, and representations and warranties, both written and oral, with respect to such
                  subject matter. In the event of any inconsistency between the statements made in the body of this
                  Agreement, the related Exhibits, and any other documents incorporated herein by reference, the following
                  order of precedence governs: (a) first, this Agreement, excluding its Exhibits; (b) second, the Exhibits to
                  this Agreement as of the Effective Date; and (c) third, any other documents incorporated herein by
                  reference.
                </p>
                <p className="ml-12">
                  10. <strong>Nature of Service.</strong> Hydra aggregates a user’s permissions into a single platform and
                  where possible, and if the user’s permissions allow, enables a user to change another user’s access or
                  remove them entirely from the end channel. Hydra does not allow a user to access or change a permission
                  from its platform that the user couldn’t enforce in the end channel directly. It is the Customer’s
                  responsibility to ensure that anyone with admin access to ad accounts and social pages is entitled and
                  approved to do so. Hydra is an aid to monitoring and managing access permissions by providing observability
                  and tools to manage permissions but is not a replacement for internal processes for joiners and leavers and
                  external agency relationships.
                </p>
                <p className="ml-12">
                  11. <strong>API Tokens.</strong> Authentication in the end channels takes place directly in the end
                  channels and Hydra will not ask for or store credentials on its platform. Each channel will enforce
                  re-authentication of credentials at its own discretion and impose 2-factor authentication as defined by the
                  user and under its own terms and conditions. Hydra has no control over APIs with third parties and is not
                  liable should a service become unavailable due to changes in a third-parties’ policy on integrations. Hydra
                  will make all Customers aware of impending loss of service to a specific channel wherever possible.
                </p>
                <p>
                  For any clarification on the above please email <u className="text-primary600">info@hihydra.com.</u>
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default TermsandConditions;
