import React, { useEffect, useState } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import CryptoJS from 'crypto-js';
import { constants } from '../constants';
import { loginConstants } from '../../../components/Modal/LoginModal/constants';
import { workspaceSummaryService } from '../../../services/workspaceSummeryService';
import useAuth from '../../../customHooks/useAuth';
import Icons from '../../../assets/Icons';
import { constants as commonConstants } from '../../../constants/constants';
import Nodata from '../../../components/Nodata';
import { getUserInfo } from '../../../utils/getUserInfo';

const WorkSpaces = ({ classes, onLoad, isLoading }) => {
  const navigate = useNavigate();
  const [workspaceSummary, setWorkspaceSummary] = useState([]);
  const { setWorkspaceCount } = useAuth();
  const { auth } = useAuth();
  const userInfo = JSON.parse(getUserInfo());
  const userType = auth?.user?.user?.type ? auth?.user?.user?.type : userInfo?.user?.role;
  const decryptedRole = CryptoJS.AES.decrypt(userType, loginConstants.localKey).toString(CryptoJS.enc.Utf8);

  const displayWorkSpaceSummary = () => {
    workspaceSummaryService()
      .then(response => {
        onLoad();
        const responseData = response?.data?.data,
          workspaceCount = responseData?.length;
        setWorkspaceCount(workspaceCount);
        setWorkspaceSummary(responseData);
      })
      .catch(error => {
        onLoad();
        console.error(error);
      });
  };

  const handleWorkspaceList = () => {
    navigate('/workspacesList');
  };

  useEffect(() => {
    displayWorkSpaceSummary();
  }, []);

  const handleCreateWorkspaceClick = () => {
    const createWorkspaceInfo = {
      isWorkspaceCreate: true,
      workspaceId: null,
    };
    navigate(commonConstants.routes.createWorkspace, {
      state: createWorkspaceInfo,
    });
    localStorage.setItem(commonConstants.localStorageWorkspaceInfo, JSON.stringify(createWorkspaceInfo));
  };
  return (
    <>
      {workspaceSummary && !isLoading && (
        <div className={`${classes.channelConsole} ${classes.card}`}>
          <div className={classes.channelHeader}>
            {workspaceSummary.length > 0 && (
              <h3>
                {workspaceSummary?.length}{' '}
                {workspaceSummary.length === 1 ? constants.workspaceSummaryTitle : constants.workspaceSummaryHeading}
              </h3>
            )}
            <div className={classes.btnWrapper}>
              {workspaceSummary.length > 0 && (
                <button aria-label="View All" className={`btn-link ${classes.viewAll}`} onClick={handleWorkspaceList}>
                  {constants.workspaceViewAll}
                </button>
              )}
              {decryptedRole !== 'user' && (
                <button
                  aria-label="Create Workspace"
                  className={`btn-outline ${classes.viewAll}`}
                  onClick={handleCreateWorkspaceClick}
                >
                  <Icons.Plus /> <span>{constants.createWorkspace}</span>
                </button>
              )}
            </div>
          </div>
          <div className={classes.channelBody}>
            {workspaceSummary.length == 0 && !isLoading && (
              <div className={`${classes.cardEmpty}`}>
                <Nodata message={'No workspaces found!'} showImage={false} />
              </div>
            )}
            {workspaceSummary?.map((element, index) => {
              return (
                <div
                  className={`${classes.card} `}
                  key={`workspaceList__${index}`}
                  onClick={() => {
                    navigate('/workspace-detailed', {
                      state: { id: element.id, name: element.name, fromDashBoard: true },
                    });
                  }}
                >
                  <div className={classes.cardHeader}>
                    <div className={classes.cardHeaderDetails}>
                      <div className={classes.cardTitle}>{element.name}</div>
                    </div>
                    <div
                      className={classes.externalLink}
                      onClick={e => {
                        e.stopPropagation();
                        navigate('/workspace-detailed', {
                          state: { id: element.id, name: element.name, fromDashBoard: true },
                        });
                      }}
                    >
                      <Tippy content="View Workspace" placement="bottom" arrow={true}>
                        <Link>
                          <Icons.Export />
                        </Link>
                      </Tippy>
                    </div>
                  </div>
                  <div className={classes.cardRow}>
                    <div className={classes.legendIcon}>
                      <Icons.User />
                    </div>
                    <div className={classes.legendTitle}>
                      {element?.accountCount} {element?.accountCount == 1 ? constants.Account : constants.Accounts}
                    </div>
                  </div>
                  <div className={classes.cardRow}>
                    <div className={classes.legendIcon}>
                      <Icons.AdminIcon />
                    </div>
                    <div className={classes.legendTitle}>
                      {element?.userCount} {element?.userCount == 1 ? constants.Admin : constants.Admins}
                    </div>
                  </div>
                </div>
              );
            })}
          </div>
        </div>
      )}
    </>
  );
};
export default WorkSpaces;
