import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Icons from '../../../../assets/Icons';
// import { AddUsersModal } from '../../AddUsers';
import { AlertModal } from '../../../../components/Modal/AlertModal';
import { useToastBox } from '../../../../customHooks/useToastBox';
import {
  handleWebCancel,
  handleDragOver,
  handleDrop,
  handleSave,
  handleWorkspaceName,
  handleWebEditCancel,
} from '../../helper';
import CompanyCard from '../ChannelsList/CompanyCard';
import { constants as commonConstants } from '../../../../constants/constants';
import { constants as modalConstants } from '../../constants';
import { AddAdminUsersModal } from '../../AddAdminUsers';
import { createWorkspaceService } from '../../../../services';
import Loader from '../../../../components/Loader';
const DroppableArea = ({
  render,
  setRender,
  pageList,
  setPageList,
  workspaceRender,
  setWorkspaceRender,
  constants,
  droppedData,
  setDroppedData,
  addedViewers,
  setAddedViewers,
  invitedViewers,
  setInvitedViewers,
  invitedViewersIds,
  setInvitedViewersIds,
  invitedViewersList,
  setInvitedViewersList,
  createWorkspaceFormError,
  setCreateWorkspaceFormError,
  createWorkspaceData,
  setCreateWorkspaceData,
  mobileview,
  tempId,
  temp,
  // edit workspace
  workspaceId,
  workspaceDetailData,
  workspaceActualInfo,
}) => {
  const { handleSuccessToast, handleErrorToast } = useToastBox();
  const [isSetupModalOpen, setIsSetupModalOpen] = useState(false);
  // eslint-disable-next-line
  const [userList, setUserList] = useState();

  const [selectedUsers, setSelectedUsers] = useState(JSON.parse(JSON.stringify(createWorkspaceData?.users))); //Object passing without reference
  const [isFormSubmitted, setIsFormSubmitted] = useState({ submitted: false, formUsers: [] });
  //Viewers
  const [existingViewersList, setExistingViewersList] = useState([]);
  const [removedViewerIds, setRemovedViewerIds] = useState([]);
  const [deletedViewerIds, setDeletedViewerIds] = useState(removedViewerIds ? [...removedViewerIds] : []);
  const [deleteInvitedViewerIds, setDeleteInvitedViewerIds] = useState([]);
  const [removedInvitedViewerIds, setRemovedInvitedViewerIds] = useState([]);
  const [newViewersList, setNewViewersList] = useState([]);
  const [newViewOnlyUsersList, setNewViewOnlyUsersList] = useState([]);
  const [failedEmailIdsList, setFailedEmailIdsList] = useState([]);
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  const [viewOnlyUsers, setViewOnlyUsers] = useState([]);
  const [cancel, setCancel] = useState(false);
  const [isViewerFormSubmitted, setIsViewerFormSubmitted] = useState({ added: false, formViewers: [] });
  const [renderViewer, setRenderViewer] = useState(false);
  const [toastMessage, setToastMessage] = useState('');
  const navigate = useNavigate();

  const handleConfirmation = () => {
    handleSuccessToast(toastMessage);
    navigate(commonConstants.routes.dashboard);
  };
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setSelectedUsers(JSON.parse(JSON.stringify(createWorkspaceData?.users)));
    if (failedEmailIdsList.length > 0) {
      setConfirmationModal(true);
    }
  }, [createWorkspaceData?.users, failedEmailIdsList]);

  return isLoading ? (
    <Loader />
  ) : (
    <div className="w-[330px] flex-shrink-0 flex-grow-0 basis-[330px] border-r border-solid border-white200 pt-6">
      <div className="h-[calc(100%-64px)] pl-4 pr-4 pb-4">
        <h2 className="mb-4 text-xs font-semibold leading-4.5 text-gray-dark">
          {workspaceId ? constants.label.editWorkspace : constants.label.createWorkspace}
        </h2>
        <div className="mb-4 flex flex-col">
          <label
            htmlFor="workspaceName"
            className="mb-1.5 overflow-hidden text-ellipsis whitespace-nowrap text-left text-xs font-normal leading-[1.125rem] text-gray-dark"
          >
            {constants.label.workspaceName}
          </label>
          <input
            type="text"
            id={constants.droppableFormTitles.workspaceId}
            name={constants.droppableFormTitles.workspaceName}
            placeholder="Workspace Name"
            defaultValue={workspaceId ? createWorkspaceData?.name : null}
            className="h-9 rounded-md border border-solid border-gray-dark1 py-2.5 px-3 text-xs font-medium leading-[1.125rem] text-gray-dark"
            onChange={event => handleWorkspaceName(event, setCreateWorkspaceData, setCreateWorkspaceFormError)}
          />
        </div>

        <div
          className="custom-vertical-scrollbar mb-4 h-[calc(100%-158px)] overflow-auto rounded-lg border-[1.5px] border-dashed border-primary bg-white300 p-7 px-0 pb-0"
          onDragOver={handleDragOver}
          onDrop={event => handleDrop(event, createWorkspaceData, pageList, setDroppedData, setPageList, setRender, render)}
        >
          <div className="h-[calc(100%-28px)] overflow-auto px-7">
            <div className="flex h-full w-full flex-col items-center justify-start">
              <div className="h-full w-full">
                {/* Drop target content goes here */}
                {droppedData.length > 0 ? (
                  <div className="h-[calc(100%+290px)]">
                    {droppedData.map((item, index) => {
                      if (item?.accountId) {
                        return (
                          <CompanyCard
                            PageDetails={item}
                            key={index}
                            constants={constants}
                            enableClose={true}
                            pageList={pageList}
                            setPageList={setPageList}
                            droppedData={droppedData}
                            setDroppedData={setDroppedData}
                            tempId={tempId}
                            workspaceRender={workspaceRender}
                            setWorkspaceRender={setWorkspaceRender}
                            createWorkspaceData={createWorkspaceData}
                          />
                        );
                      }
                    })}
                  </div>
                ) : (
                  <div className="item-center flex h-full w-full flex-col justify-center">
                    <div className="item-center flex justify-center text-gray-dark1">
                      <Icons.DragAndDropIcon />
                    </div>
                    <p className="mt-2 text-center text-xs font-medium leading-4.5 text-gray-dark1">
                      {constants.infoMessages.dragDrop}
                    </p>
                  </div>
                )}
              </div>
            </div>
          </div>
        </div>
        <button
          onClick={() => setIsSetupModalOpen(true)}
          aria-label="add user button"
          className="mb-4 h-8 w-full rounded-2xl border-[1.5px] border-solid border-primary pb-2 pt-[5px] text-xs font-semibold leading-4.5 text-primary"
        >
          {constants.button.addUser}
        </button>
      </div>
      <div className=" grid h-16 grid-cols-2 items-center gap-2 border-t border-solid border-white200 p-4">
        <button
          data-testid="cancel-button"
          aria-label="Cancel button"
          className="h-8 rounded-2xl border-[1.5px] border-solid border-primary pb-2 pt-[5px] text-xs font-semibold leading-4.5 text-primary"
          onClick={() => {
            workspaceId
              ? handleWebEditCancel(
                  setDroppedData,
                  setCreateWorkspaceData,
                  setCreateWorkspaceFormError,
                  setRender,
                  render,
                  setWorkspaceRender,
                  workspaceRender,
                  setPageList,
                  temp,
                  workspaceDetailData,
                  setSelectedUsers,
                  setViewOnlyUsers,
                  setIsFormSubmitted,
                  setIsViewerFormSubmitted,
                  setCancel,
                  setRenderViewer,
                  setExistingViewersList,
                  setRemovedViewerIds
                )
              : handleWebCancel(
                  setDroppedData,
                  setCreateWorkspaceData,
                  setCreateWorkspaceFormError,
                  setRender,
                  render,
                  setWorkspaceRender,
                  workspaceRender,
                  setPageList,
                  temp,
                  setSelectedUsers,
                  setViewOnlyUsers,
                  setCancel,
                  createWorkspaceData.loggedinUser,
                  setIsFormSubmitted,
                  setIsViewerFormSubmitted,
                  setRenderViewer
                );
          }}
        >
          {constants.button.cancel}
        </button>
        <button
          data-testid="save-button"
          aria-label="Save button"
          className="h-8 rounded-2xl bg-primary pb-2 pt-[5px] text-xs font-semibold leading-4.5 text-white"
          onClick={() => {
            setIsLoading(true);
            handleSave(
              createWorkspaceData,
              createWorkspaceFormError,
              handleSuccessToast,
              handleErrorToast,
              createWorkspaceService,
              setDroppedData,
              setCreateWorkspaceData,
              setCreateWorkspaceFormError,
              mobileview,
              setWorkspaceRender,
              workspaceRender,
              setPageList,
              temp,
              navigate,
              workspaceId ? workspaceId : null,
              workspaceActualInfo ? workspaceActualInfo : null,
              selectedUsers,
              setSelectedUsers,
              setIsFormSubmitted,
              setIsViewerFormSubmitted,
              setRender,
              render,
              userList,
              existingViewersList,
              newViewersList,
              removedViewerIds,
              removedInvitedViewerIds,
              setRemovedInvitedViewerIds,
              setFailedEmailIdsList,
              setViewOnlyUsers,
              setCancel,
              setIsLoading,
              setToastMessage
            );
          }}
        >
          {constants.button.save}
        </button>
      </div>
      {/* <AddUsersModal
        isSetupModalOpen={isSetupModalOpen}
        setIsSetupModalOpen={setIsSetupModalOpen}
        userList={value => setUserList(value)}
        createWorkspaceData={createWorkspaceData}
        workspaceId={workspaceId}
        // newedit
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        workspaceActualInfo={workspaceActualInfo}
        existingViewersList={value => setExistingViewersList(value)}
        newViewersList={value => setNewViewersList(value)}
      /> */}
      <AddAdminUsersModal
        isSetupModalOpen={isSetupModalOpen}
        setIsSetupModalOpen={setIsSetupModalOpen}
        userList={value => setUserList(value)}
        createWorkspaceData={createWorkspaceData}
        workspaceId={workspaceId}
        // newedit
        selectedUsers={selectedUsers}
        setSelectedUsers={setSelectedUsers}
        addedViewers={addedViewers}
        setAddedViewers={setAddedViewers}
        invitedViewers={invitedViewers}
        setInvitedViewers={setInvitedViewers}
        invitedViewersIds={invitedViewersIds}
        setInvitedViewersIds={setInvitedViewersIds}
        invitedViewersList={invitedViewersList}
        setInvitedViewersList={setInvitedViewersList}
        setViewOnlyUsers={setViewOnlyUsers}
        viewOnlyUsers={viewOnlyUsers}
        setCancel={setCancel}
        cancel={cancel}
        workspaceActualInfo={workspaceActualInfo}
        isFormSubmitted={isFormSubmitted}
        setIsFormSubmitted={setIsFormSubmitted}
        isViewerFormSubmitted={isViewerFormSubmitted}
        setIsViewerFormSubmitted={setIsViewerFormSubmitted}
        handleSuccessToast={handleSuccessToast}
        //viewer
        existingViewersList={value => setExistingViewersList(value)}
        newViewersList={value => setNewViewersList(value)}
        newViewOnlyUsersList={newViewOnlyUsersList}
        setNewViewOnlyUsersList={setNewViewOnlyUsersList}
        removedViewerIds={value => setRemovedViewerIds(value)}
        removedInvitedViewerIds={value => setRemovedInvitedViewerIds(value)}
        renderViewer={renderViewer}
        setRenderViewer={setRenderViewer}
        deletedViewerIds={deletedViewerIds}
        setDeletedViewerIds={setDeletedViewerIds}
        deleteInvitedViewerIds={deleteInvitedViewerIds}
        setDeleteInvitedViewerIds={setDeleteInvitedViewerIds}
      />
      {openConfirmationModal && (
        <AlertModal
          isOpen={true}
          setIsOpen={setConfirmationModal}
          heading={modalConstants.infoMessages.viewerInvitationStatusMessage}
          message={failedEmailIdsList}
          handleConfirm={() => handleConfirmation()}
          confirmAlert={modalConstants.button.ok}
          hideCancel={true}
        />
      )}
    </div>
  );
};
export default DroppableArea;
