import React from 'react';
import CommonDashboard from '../../components/CommonDashboard';
import ChannelList from '../../components/ChannelSetup';

import {
  general,
  facebookAds,
  googleAds,
  googleAnalytics,
  googleMerchantCentre,
  otherChannels,
  permissions,
  workspace,
  reports,
  payments,
} from './constants';
import HelpCard from '../../components/HelpCard';
const Help = () => {
  sessionStorage.setItem('page', 'Help');
  return (
    <div className="md:bg-gray-light1">
      <CommonDashboard hideHeader={false}>
        <div className="xl:hidden">
          <ChannelList shouldAuthenticate={false} onLoad={() => {}} isLoading={false} />
        </div>
        <div className="flex w-full flex-col overflow-hidden bg-gray-light1 md:-mx-5 md:-mb-5 md:w-[calc(100%+40px)]">
          <div className="flexCenter justify-between border-b border-white200 bg-white p-4 sm:flex-col sm:items-start sm:justify-start sm:pt-0 ">
            <div className="flexCenter">
              <span className=" text-base font-semibold leading-6 text-gray-dark"> Frequently Asked Questions </span>
            </div>
            {/* <div className="md:hidden">
                          <button className="btn-secondary-outline xs:w-full xs:justify-center">Ask a Question</button>
                        </div> */}
          </div>
          <div className="custom-vertical-scrollbar inset-0 m-4 overflow-auto rounded-lg border border-white200 bg-white p-4 pt-6 md:border-none md:bg-transparent md:p-0">
            <div className=" ml-8 w-11/12 pb-30 md:ml-0 md:w-full">
              <div className=" mb-4 text-xl  font-semibold leading-5 text-gray-dark"> General </div>
              <div className="  space-y-4  ">
                {general.map(data => {
                  return <HelpCard data={data} key={data.id} />;
                })}
              </div>
              <div className="mb-4 mt-6 text-xl   font-semibold leading-5 text-gray-dark"> Channels </div>
              <div className=" space-y-4 ">
                <div className=" !-mb-1.5 !mt-6 flex text-sm font-semibold  leading-5 text-gray-dark"> Facebook Ads </div>
                {facebookAds.map(data => {
                  return <HelpCard data={data} key={data.id} />;
                })}
                <div className="!-mb-1.5 !mt-6 flex text-sm font-semibold  leading-5 text-gray-dark"> Google Ads </div>
                {googleAds.map(data => {
                  return <HelpCard data={data} key={data.id} />;
                })}
                <div className="!-mb-1.5 !mt-6 flex text-sm font-semibold leading-5 text-gray-dark"> Google Analytics </div>
                {googleAnalytics.map(data => {
                  return <HelpCard data={data} key={data.id} />;
                })}
                <div className="!-mb-1.5 !mt-6 flex text-sm font-semibold  leading-5 text-gray-dark">
                  Google Merchant Centre
                </div>
                {googleMerchantCentre.map(data => {
                  return <HelpCard data={data} key={data.id} />;
                })}
                <div className="!-mb-1.5 !mt-6 flex text-sm font-semibold  leading-5 text-gray-dark">
                  Google Search Console, LinkedIn Pages, LinkedIn Ads
                </div>
                {otherChannels.map(data => {
                  return <HelpCard data={data} key={data.id} />;
                })}
              </div>
              <div className="mb-4 mt-6 text-xl   font-semibold leading-5 text-gray-dark"> Permissions </div>
              <div className=" space-y-4 ">
                {permissions.map(data => {
                  return <HelpCard data={data} key={data.id} />;
                })}
              </div>
              <div className="mb-4 mt-6 text-xl   font-semibold leading-5 text-gray-dark"> Workspace </div>
              <div className=" space-y-4 ">
                {workspace.map(data => {
                  return <HelpCard data={data} key={data.id} />;
                })}
              </div>
              <div className="mb-4 mt-6 text-xl   font-semibold leading-5 text-gray-dark"> Reports </div>
              <div className=" space-y-4 ">
                {reports.map(data => {
                  return <HelpCard data={data} key={data.id} />;
                })}
              </div>
              <div className="mb-4 mt-6 text-xl   font-semibold leading-5 text-gray-dark">Payment and subscription</div>
              <div className=" space-y-4 ">
                {payments.map(data => {
                  return <HelpCard data={data} key={data.id} />;
                })}
                {/* <div className=" !-mb-1.5 !mt-6 flex text-sm font-semibold  leading-5 text-gray-dark">
                  Purchase of Additional Licenses - Proration
                </div>
                {proration.map(data => {
                  return <HelpCard data={data} key={data.id} />;
                })} */}
              </div>
            </div>
          </div>
        </div>
        {/* <div className=" fixed bottom-0 left-0 hidden w-full border-t border-white200 bg-white py-6 px-5 shadow-stickyBottomCard md:flex">
                      <button className="btn-secondary-outline h-11 xs:w-full xs:justify-center">Ask a Question</button>
                    </div> */}
      </CommonDashboard>
    </div>
  );
};
export default Help;
