import { API } from '../../constants/api';
import { post, get, put } from '../../utils/apiHandler';

export const sendBroadcast = async body => {
  try {
    const response = await post(API.postMessage, body);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const listMessages = async () => {
  try {
    const response = await get(API.postMessage);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const markAsRead = async () => {
  try {
    const response = await put(API.readMessage);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
