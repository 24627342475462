import React from 'react';
import Icons from '../../assets/Icons';

export const sidebar = [
  {
    title: 'Dashboard',
    icon: <Icons.Home />,
    label: 'Home',
    route: '/dashboard',
  },
  {
    title: 'Report',
    icon: <Icons.Diagram />,
    label: 'Trends',
    route: '/reports',
  },
  {
    title: 'Workspace List',
    icon: <Icons.workspaceIcon />,
    label: 'Workspace List',
    route: '/workspacesList',
  },
  {
    title: 'Messaging',
    icon: <Icons.Message />,
    label: 'Messaging',
    route: '/messaging',
    count:'10',
  },
  {
    title: 'User',
    icon: <Icons.User />,
    label: 'User',
    route: '/userManagement',
  },
  // {
  //   title: 'Settings',
  //   icon: <Icons.SettingsIcon />,
  //   label: 'Settings',
  //   // route: '/userManagement',
  // },
];
