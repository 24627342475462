/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import IndividualChannel from '../../components/EditUserPermissionPages/components/IndividualChannel';
import BreadCrumb from '../../components/BreadCrumb';
import CommonDashboard from '../../components/CommonDashboard';
import WorkspaceList from '../../components/EditUserPermissionPages/components/WorkspaceList';
import { AddUserModal } from '../../components/Modal/AddUserModal';
import { ConfirmationModal } from '../../components/Modal/ConfirmationModal';
import { subscriptionPlans } from '../../constants/constants';
import { navData, textConstants } from './constants';
import { useLocation } from 'react-router-dom';
import useAuth from '../../customHooks/useAuth';
import Tippy from '@tippyjs/react';
import Icons from '../../assets/Icons';
import {
  getUserPermissionChannelDetail,
  getChannelRoles,
  saveUserPermissionEdit,
  removeUser,
  addUser,
  requestToRemoveUser,
} from '../../services/userPermissionChannelViewService';
import { getAccountService } from '../../services';
import { constants as commonConstants } from '../../constants/constants';
import { useToastBox } from '../../customHooks/useToastBox';
import { processPermissionListChannelView } from './helper';
import { useCookies } from 'react-cookie';
import { AlertModal } from '../../components/Modal/AlertModal';
import NoData from '../../components/Nodata';
import Loader from '../../components/Loader';
import { authenticateChannel } from '../../components/ChannelSetup/helper';
import { subbedChannelIconsConstants } from '../Dashboard/ChannelSubbed/constants';
import { getChannelDetails } from '../WorkspacesDetailed/helper';
import ChannelErrorPage from '../../components/ChannelErrorPage';
import { getUserInfo } from '../../utils/getUserInfo';
import { SetupChannelModal } from '../../components/ChannelSetup/SetupChannelModal';
import { saveChecker, addAuthenticationHandler, labelDisplay, displaySubheader } from '../../utils/helpers';

const UsersPermissionsChannelView = () => {
  let subscriptionType;
  const userInfo = JSON.parse(getUserInfo());
  const subscriptionCode = userInfo?.user?.subscriptionType;
  if (subscriptionCode) {
    subscriptionType = CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8);
  }
  sessionStorage.setItem('page', 'User Permission');
  const { handleErrorToast, handleSuccessToast } = useToastBox();
  const [rolePermission, setRolePermission] = useState({ role: {}, permission: {} });
  const location = useLocation();
  const { auth } = useAuth();
  const [cookies] = useCookies();

  const userId = auth?.user?.user?.id ? auth?.user?.user?.id : userInfo?.user?.id;

  navData[0].pathHistory[0].title = auth.user?.user?.name;
  navData[0].pathHistory[1].title = location?.state?.channelName
    ? location?.state?.channelName
    : getChannelDetails(location?.state?.channelType).title;
  navData[0].pathHistory[1].showInfo = true;
  const channelToken = cookies[`${location?.state?.channelType}Token_${auth.user?.user?.id}`];
  const channelType = location?.state?.channelType;

  const disableButtons = channelType == textConstants.googleSearchConsole || channelType == textConstants.twitterAds;

  const [editMode, setEditMode] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [editUser, setEditUser] = useState(0);
  const [requestToRemove, setRequestToRemove] = useState(false);
  const [selectedWorkspace, setSelectedWorkspace] = useState(null);
  const [selectedAccountId, setSelectedAccountId] = useState(null);
  const [userPermissionList, setUserPermissionList] = useState([]);
  const [editUserData, setEditUserData] = useState({ email: '', roles: [] });
  const [roleList, setRoleList] = useState([]);
  const [permissionList, setPermissionList] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [removeUserData, setRemoveUserData] = useState({});
  const [addUserModal, setAddUserModal] = useState(false);
  const [userData, setUserData] = useState({ email: '', roles: [] });
  const [rolePermissionLabel, setRolePermissionLabel] = useState({ role: 'Role', permission: 'Permission' });
  const [redirectionURL, setRedirectionURL] = useState(null);
  const [confirmModal, setConfirmModal] = useState(false);
  const [redirected, setRedirected] = useState(false);
  const [accountName, setAccountName] = useState('');
  const [isSetupModalOpen, setIsSetupModalOpen] = useState(false);
  const [searchKeyword, setSearchKeyword] = useState('');
  const [searchResults, setSearchResults] = useState([]);
  const [setupStatus, setSetupStatus] = useState('');

  const searchFunction = keyword => {
    let searchArray;
    setSearchKeyword(keyword);
    searchArray =
      keyword.length > 0
        ? userPermissionList[selectedWorkspace]?.pagesDetails?.filter(data =>
            data.title?.toLowerCase().includes(keyword.toLowerCase().trim())
          )
        : searchArray;

    if (keyword.length > 0) {
      setSearchResults([...searchArray]);
    } else if (keyword.length == 0) {
      setSearchResults([]);
    }
  };

  const setInitialData = path => {
    setIsLoading(true);
    getChannelRoles(channelType, channelToken)
      .then(res => {
        const displayNames = { role: '', permission: '' };
        displayNames.role = res.data?.roles?.displayName;
        setRolePermissionLabel({
          ...rolePermissionLabel,
          role: res?.data?.roles?.displayName,
          permission: res?.data?.permissions?.displayName,
        });

        let userRoleList = res?.data?.roles?.data;

        const roleOptions = [];
        userRoleList?.forEach(roles => {
          const role = {
            label: roles?.name,
            value: roles?.id,
            isDisabled:
              channelType == textConstants.facebookPages || channelType == textConstants.googleAds ? roles?.disabled : false,
          };
          roleOptions.push(role);
        });
        setRoleList(roleOptions);
        let userPermissionList = null;
        if (channelType === textConstants.googleTagManager || channelType === textConstants.googleAnalytics) {
          displayNames.permission = res?.data?.permissions?.displayName;
          userPermissionList = res?.data?.permissions?.data ? res?.data?.permissions?.data : null;
          const permissionOption = [];
          userPermissionList?.forEach(permissions => {
            const permission = { label: permissions.name, value: permissions.id };
            permissionOption.push(permission);
          });
          setPermissionList(permissionOption);
        }
        getUserPermissionChannelDetail(location?.state?.channelType)
          .then(res => {
            navData[0].pathHistory[2].title = res.data?.noOfResults
              ? `${res.data.noOfResults} ${labelDisplay(res.data?.noOfResults, channelType)}`
              : '-';
            processPermissionListChannelView(
              res.data.results,
              userRoleList,
              userPermissionList,
              displayNames,
              channelType
            ).then(response => {
              if (path == 'fromRefresh') {
                setIsSetupModalOpen(true);
              }
              if (selectedWorkspace === null) {
                setDefaultAccount(response[0]);
              }
              setUserPermissionList(response);
            });
            setIsLoading(false);
          })
          .catch(err => {
            if (path == 'fromRefresh') {
              handleErrorToast('something went wrong');
            }
            handleErrorToast(err.message);
            setIsLoading(false);
          });
      })
      .catch(err => {
        if (path == 'fromRefresh') {
          handleErrorToast('something went wrong');
        }
        handleErrorToast(err.message);
        setIsLoading(false);
      });
  };

  const onEditClick = data => {
    if (channelType === textConstants.googleTagManager || channelType === textConstants.googleAnalytics) {
      setRolePermission({
        ...rolePermission,
        role: data.role,
        permission: data?.permission !== null ? data.permission : [],
      });
    } else {
      setRolePermission({ ...rolePermission, role: data.role, permission: {} });
    }
  };

  const saveEditChanges = data => {
    setIsLoading(true);
    if (channelToken === undefined) {
      handleErrorToast(`${commonConstants?.error?.tokenNotFound}. ${commonConstants?.error?.autheticateChannel}`);
      setTimeout(() => {
        authenticateChannel(
          location?.state?.channelProvider,
          location?.state?.channelType,
          location?.state?.channelName,
          '/userPermissionChannelView'
        );
      }, 3500);
    } else {
      if (saveChecker(rolePermission, data, channelType, 'channel')) {
        saveUserPermissionEdit(
          editUserData,
          location?.state?.channelType,
          data.accountId,
          data.channelUserId,
          channelToken,
          accountName
        )
          .then(response => {
            if (response?.data?.data === commonConstants?.customMessages?.refreshRequired) {
              handleSuccessToast(`${response?.data?.data}. ${commonConstants.error.refreshChannel}`);
              refreshChannel(
                location?.state?.channelProvider,
                location?.state?.channelType,
                location?.state?.channelName,
                '/userPermissionChannelView'
              );
            } else {
              handleSuccessToast('User Updated Successfully');
              setIsLoading(false);
              setInitialData();
            }
            setEditUser(null);
          })
          .catch(error => {
            setIsLoading(false);
            if (
              error?.message === commonConstants.error.tokenExpired ||
              error?.message === commonConstants.error.refreshRequired
            ) {
              setIsLoading(true);
              handleErrorToast(`${error?.message}. ${commonConstants.error.autheticateChannel}`);
              setTimeout(() => {
                authenticateChannel(
                  location?.state?.channelProvider,
                  location?.state?.channelType,
                  location?.state?.channelName,
                  '/userPermissionChannelView'
                );
              }, 3500);
            } else {
              setIsLoading(false);
              setInitialData();
              handleErrorToast(`${error?.message}`);
              setEditUser(null);
            }
          });
      } else {
        setIsLoading(false);
        setEditUser(null);
      }
    }
  };

  const onRoleChange = (e, data) => {
    if (channelType === textConstants.googleTagManager) {
      setEditUserData({ ...editUserData, email: data, roles: [e.value], permissions: [rolePermission.permission?.value] });
    } else if (channelType === textConstants.googleAnalytics) {
      let tempPermissions = [];
      rolePermission.permission?.map(element => {
        tempPermissions.push(element.value);
      });
      if (tempPermissions?.length) {
        setEditUserData({ ...editUserData, email: data, roles: [e.value], permissions: [...tempPermissions] });
      } else {
        setEditUserData({ ...editUserData, email: data, roles: [e.value], permissions: [] });
      }
    } else if (
      channelType === textConstants.facebookAds ||
      channelType === textConstants.facebookPages ||
      channelType === textConstants.googleMerchantCentre
    ) {
      {
        let tempRoles = [];
        e?.map(element => {
          tempRoles.push(element.value);
        });

        if (tempRoles?.length) {
          setEditUserData({ ...editUserData, email: data, roles: [...tempRoles] });
        } else {
          setEditUserData({ ...editUserData, email: data, roles: [] });
        }
      }
    } else {
      setEditUserData({ ...editUserData, email: data, roles: [e.value] });
    }
    setRolePermission({ ...rolePermission, role: e });
  };

  const onPermissionChange = (e, data) => {
    if (channelType == textConstants.googleAnalytics) {
      let tempPermissions = [];
      e?.map(element => {
        tempPermissions.push(element.value);
      });

      if (tempPermissions?.length) {
        setEditUserData({
          ...editUserData,
          permissions: [...tempPermissions],
          roles: [rolePermission.role.value],
          email: data,
        });
      } else {
        setEditUserData({
          ...editUserData,
          permissions: [],
          roles: [rolePermission.role.value],
          email: data,
        });
      }
    } else {
      setEditUserData({ ...editUserData, permissions: [e.value], roles: [rolePermission.role?.value], email: data });
    }
    setRolePermission({ ...rolePermission, permission: e });
  };

  const onUserRemove = data => {
    setIsOpen(true);
    setRemoveUserData(data);
  };

  const onRemoveConfirm = () => {
    setIsLoading(true);
    removeUser(
      channelType,
      channelToken,
      removeUserData.channelUserId,
      removeUserData.accountId,
      removeUserData.email,
      accountName
    )
      .then(res => {
        setIsLoading(false);
        setIsOpen(false);
        handleSuccessToast(res.data);
        setInitialData();
        setEditUser(0);
      })
      .catch(error => {
        setIsLoading(false);
        if (
          error?.message === commonConstants.error.tokenExpired
          // || error?.code === commonConstants?.errorCode?.tokenExpired
        ) {
          handleErrorToast(`${error?.message}. ${commonConstants.error.autheticateChannel}`);
          const channelDetails = getChannelDetails(channelType);
          setIsLoading(true);
          setTimeout(() => {
            authenticateChannel(
              channelDetails.provider,
              channelDetails.channelType,
              channelDetails.title,
              '/userPermissionChannelView'
            );
          }, 3500);
        } else {
          handleErrorToast(`${error?.message}`);
          setIsLoading(false);
          if (error.message === commonConstants.error.noPermission) {
            setRequestToRemove(true);
          }
        }
      });
  };

  const handleModalInput = (data, type) => {
    let temp = { ...userData };
    switch (type) {
      case 'email':
        temp.email = data;
        break;
    }
    setUserData(temp);
  };

  const handlePermissionInput = data => {
    if (channelType == textConstants.googleAnalytics) {
      let tempPermissions = [];
      data?.map(element => {
        tempPermissions.push(element.value);
      });

      if (tempPermissions?.length) {
        setUserData({
          ...userData,
          permissions: [...tempPermissions],
        });
      }
    } else {
      setUserData({
        ...userData,
        permissions: [data.value],
      });
    }
  };

  const handleRoleInput = data => {
    if (channelType == textConstants.googleMerchantCentre) {
      let tempRoles = [];
      data?.map(element => {
        tempRoles.push(element.value);
      });

      if (tempRoles?.length) {
        setUserData({
          ...userData,
          roles: [...tempRoles],
        });
      }
    } else {
      setUserData({
        ...userData,
        roles: [data.value],
      });
    }
  };

  const handleSubmit = () => {
    if (channelToken === undefined) {
      setIsLoading(true);
      handleErrorToast(`${commonConstants?.error?.tokenNotFound}. ${commonConstants?.error?.autheticateChannel}`);
      setTimeout(() => {
        authenticateChannel(
          location?.state?.channelProvider,
          location?.state?.channelType,
          location?.state?.channelName,
          '/userPermissionChannelView'
        );
      }, 3500);
    } else if (addAuthenticationHandler(channelType, userData)) {
      setIsLoading(true);
      addUser(channelType, channelToken, selectedAccountId, userData, accountName)
        .then(res => {
          setIsLoading(false);
          if (res.data?.data == commonConstants.error.addedInvitationSent) {
            handleSuccessToast(commonConstants.error.addedInvitationSent);
            setUserData({ email: '', roles: [] });
          } else {
            handleSuccessToast('User added successfully');
            setUserData({ email: '', roles: [] });
          }
          setInitialData();
          setAddUserModal(false);
        })
        .catch(error => {
          setIsLoading(false);
          if (
            error?.message === commonConstants.error.tokenExpired
            // || error?.code === commonConstants?.errorCode?.tokenExpired
          ) {
            handleErrorToast(`${error?.message}. ${commonConstants.error.autheticateChannel}`);
            setIsLoading(true);
            setTimeout(() => {
              authenticateChannel(
                location?.state?.channelProvider,
                location?.state?.channelType,
                location?.state?.channelName,
                '/userPermissionChannelView'
              );
            }, 3500);
          } else {
            handleErrorToast(error?.message);
          }
        });
    }
  };

  function showEditButton(channelType) {
    switch (channelType) {
      case textConstants.twitterAds:
      case textConstants.facebookAds:
      case textConstants.facebookPages:
      case textConstants.linkedinPages:
      case textConstants.linkedinAds:
        return true;
      default:
        return false;
    }
  }

  function showGoToChannelButton(channelType) {
    switch (channelType) {
      case textConstants.googleAds:
      case textConstants.googleAnalytics:
        return true;
      default:
        return false;
    }
  }

  const onRequestToRemoveConfirm = () => {
    setIsLoading(true);
    const data = {
      channel: channelType,
      channelAccountId: removeUserData?.channelAccountId,
      action: 'remove',
      channelAccountUserId: removeUserData?.id,
    };
    requestToRemoveUser(data)
      .then(() => {
        handleSuccessToast('Request to remove sent to Admin User with access');
        setRequestToRemove(false);
        setIsLoading(false);
      })
      .catch(err => {
        handleErrorToast(err.message);
        setIsLoading(false);
      });
  };

  const setDefaultAccount = data => {
    if (data !== undefined) {
      setAccountName(data.title);
      setSelectedAccountId(data?.accountId);
      setRedirectionURL(data?.redirectionURL);
      setSelectedWorkspace(0);
      if (channelType == textConstants.googleAds) {
        setSetupStatus(data?.setupInProgress);
      }
    }
  };

  const refreshChannel = (provider, channel, title, path) => {
    setIsLoading(true);
    if (`${channel}Token_${userId}` in cookies) {
      const token = cookies[`${channel}Token_${userId}`];
      let tokenSecret = '';
      if (
        provider === subbedChannelIconsConstants?.provider?.twitterAdsProvider ||
        provider === subbedChannelIconsConstants?.provider?.twitterPagesProvider
      ) {
        tokenSecret = cookies[`${channel}TokenSecret_${userId}`];
      }
      getAccountService(channel, token, tokenSecret)
        .then(() => {
          setTimeout(() => {
            setInitialData('fromRefresh');
          }, 3500);
        })
        .catch(error => {
          if (
            error?.message === commonConstants.error.tokenExpired ||
            error?.code === commonConstants?.errorCode?.tokenExpired
          ) {
            handleErrorToast(`${error?.message}. ${commonConstants.error.autheticateChannel}`);
            setIsLoading(true);
            setTimeout(() => {
              authenticateChannel(provider, channel, title, path);
            }, 3500);
          } else {
            handleErrorToast(`${error?.message}`);
          }
        });
    } else {
      authenticateChannel(provider, channel, title, path);
    }
  };

  useEffect(() => {
    if (channelType) {
      setInitialData();
    }
  }, [channelToken, channelType]);

  useEffect(() => {
    document.addEventListener(
      'visibilitychange',
      function () {
        if (redirected) {
          setConfirmModal(true);
          setRedirected(false);
        }
      },
      { once: true }
    );
  }, [redirected]);

  return (
    <CommonDashboard classList="flex flex-col md:p-0 md:bg-gray-light1 md:h-screen">
      {isLoading && <Loader />}
      <BreadCrumb
        navData={navData}
        classList="bg-white"
        prevPage={
          location?.state?.fromDashBoard
            ? '/dashboard'
            : location?.state?.fromAccountUser
            ? '/accountUsersView'
            : '/UserPermissionChannel'
        }
        accountUserId={location?.state?.id}
        userName={location?.state?.name}
        channelType={channelType}
      >
        {selectedWorkspace != null && subscriptionType !== subscriptionPlans.individual && !disableButtons && (
          <div className="edit-icon ml-auto flex items-center">
            {/* {showEditButton(channelType) && (
              <Tippy
                placement="left"
                arrow={true}
                content={
                  redirectionURL?.length
                    ? 'Verified by Hydra officials'
                    : channelType == textConstants.facebookAds || channelType == textConstants.facebookPages
                    ? 'The account cannot be edited since it is not associated with a business'
                    : 'Could not redirect'
                }
              >
                <span>
                  <Icons.InfoIcon />
                </span>
              </Tippy>
            )} */}

            {showEditButton(channelType) && (
              <a
                className={`btn-outline  ove mr-3 flex cursor-pointer items-center justify-center px-2 text-white ${
                  redirectionURL?.length == 0 ? 'link-disabled' : null
                }`}
                href={redirectionURL?.length ? redirectionURL : null}
                target="_blank"
                rel="noopener noreferrer"
                onClick={() => {
                  redirectionURL?.length ? setRedirected(true) : null;
                }}
              >
                <span className="text-sm">Edit</span>
              </a>
            )}

            {(userPermissionList?.length == 0 ||
              userPermissionList[selectedWorkspace]?.pagesDetails?.length == 0 ||
              userPermissionList[selectedWorkspace]?.pagesDetails[0].title == null) &&
              showGoToChannelButton(channelType) && (
                <a
                  className={`btn-outline  ove mr-3 flex cursor-pointer items-center justify-center px-2 text-white ${
                    redirectionURL?.length == 0 ? 'link-disabled' : null
                  }`}
                  href={redirectionURL?.length ? redirectionURL : null}
                  target="_blank"
                  rel="noopener noreferrer"
                  onClick={() => {
                    redirectionURL?.length ? setRedirected(true) : null;
                  }}
                >
                  <span className="text-sm">Go to Channel</span>
                </a>
              )}
            {!showEditButton(channelType) &&
              userPermissionList[selectedWorkspace]?.pagesDetails[0].title !== null &&
              userPermissionList[selectedWorkspace]?.pagesDetails?.length > 0 && (
                <button
                  className="btn-secondary-outline bg-primary px-4.5 text-white md:w-full "
                  onClick={() => {
                    setAddUserModal(true);
                    setEditUser(0);
                    setUserData({ email: '', roles: [] });
                  }}
                >
                  <span>{textConstants.addUser}</span>
                </button>
              )}
          </div>
        )}
      </BreadCrumb>
      <div className="flex h-[calc(100%-81px)] md:h-auto md:flex-col md:overflow-visible">
        <WorkspaceList
          channelList={userPermissionList}
          selectedWorkspace={selectedWorkspace}
          setSelectedWorkspace={setSelectedWorkspace}
          setSelectedAccountId={setSelectedAccountId}
          setRedirectionURL={setRedirectionURL}
          editMode={editMode}
          setEditMode={setEditMode}
          setAccountName={setAccountName}
          subscriptionType={subscriptionType}
          setSearchResults={setSearchResults}
          setSearchKeyword={setSearchKeyword}
          setSetupStatus={setSetupStatus}
        />

        <div className=" h-full w-full  overflow-auto bg-gray-light1 p-4 pb-16 md:flex-col md:border-t md:border-white200">
          {displaySubheader(channelType) &&
            userPermissionList[selectedWorkspace] &&
            userPermissionList?.length !== 0 &&
            userPermissionList[selectedWorkspace]?.pagesDetails?.length !== 0 &&
            userPermissionList[selectedWorkspace]?.pagesDetails[0].title !== null && (
              <div className="-m-4 mb-4 -mr-[20px] flex w-[calc(100%+30px)] items-center border-b border-white200 bg-white p-4 pr-4 md:flex-col md:items-start">
                {' '}
                <div className="flex items-center xs:flex-wrap">
                  <div className=" text-sm font-semibold capitalize text-gray-dark">{accountName}</div>
                  <div className=" mx-2 h-1 w-1 rounded-full bg-slate-400"></div>
                  <div className="text-sm  text-gray-medium">
                    {userPermissionList[selectedWorkspace]?.pagesDetails?.length}{' '}
                    {userPermissionList[selectedWorkspace]?.pagesDetails?.length == 1 ? 'User' : 'Users'}
                  </div>
                </div>
                <div className="relative ml-auto mt-auto flex w-[300px] items-center sm:w-full md:my-5">
                  <input
                    placeholder="search for a user"
                    type="text"
                    className=" h-8 w-full rounded-full border border-gray-medium/30 pl-2 pr-14 text-sm !outline-none placeholder:text-xs"
                    onChange={event => searchFunction(event.target.value)}
                    value={searchKeyword}
                  />
                  {searchKeyword?.length > 0 && (
                    <button
                      className="absolute right-10 top-[5px] flex items-center justify-center text-gray-dark1"
                      onClick={() => {
                        setSearchKeyword('');
                        setSearchResults([]);
                      }}
                    >
                      <div className=" h-4 w-4">
                        <Icons.CloseIcon />
                      </div>
                    </button>
                  )}
                  <button className=" absolute right-0 flex h-8 w-8 items-center justify-center rounded-full bg-primary text-white shadow-2xl">
                    {' '}
                    <Icons.Search />
                  </button>
                </div>
              </div>
            )}

          <div className="grid grid-cols-3 gap-4 md:grid-cols-1">
            {isOpen && (
              <AlertModal
                isOpen={isOpen}
                setIsOpen={setIsOpen}
                handleConfirm={() => onRemoveConfirm()}
                heading={textConstants.alertTitle}
                message={`${textConstants.alertMessage} ${removeUserData.accountName} ?`}
              />
            )}
            {requestToRemove && (
              <AlertModal
                isOpen={requestToRemove}
                setIsOpen={setRequestToRemove}
                handleConfirm={() => onRequestToRemoveConfirm()}
                heading={textConstants.alertTitle}
                message={textConstants.requestToRemove}
              />
            )}
            {userPermissionList.length !== 0 &&
            !disableButtons &&
            userPermissionList[selectedWorkspace]?.pagesDetails[0].title !== null
              ? (searchResults?.length == 0 && searchKeyword?.length == 0
                  ? userPermissionList[selectedWorkspace].pagesDetails
                  : searchResults
                ).map((element, index) => {
                  if (element.title) {
                    return (
                      <IndividualChannel
                        itemIndex={index}
                        data={element}
                        currentUser={auth.user?.user?.email}
                        key={`individualChannel__${index}`}
                        selectedWorkspace={selectedWorkspace}
                        editUser={editUser}
                        setEditUser={setEditUser}
                        saveEditChanges={saveEditChanges}
                        setEditMode={setEditMode}
                        onRoleChange={onRoleChange}
                        channelType={channelType}
                        onPermissionChange={onPermissionChange}
                        roleList={roleList}
                        permissionList={permissionList.length ? permissionList : null}
                        onUserRemove={onUserRemove}
                        onEditClick={onEditClick}
                        rolePermission={rolePermission}
                        rolePermissionLabel={rolePermissionLabel}
                        channel={location?.state?.channelName}
                        subscriptionType={subscriptionType}
                      />
                    );
                  }
                })
              : null}
          </div>
          {searchResults?.length == 0 && searchKeyword?.length > 0 && (
            <NoData message={'No results found'} showImage={true} />
          )}
          {(userPermissionList?.length == 0 ||
            userPermissionList[selectedWorkspace]?.pagesDetails?.length == 0 ||
            userPermissionList[selectedWorkspace]?.pagesDetails[0].title == null) &&
            channelType !== textConstants.facebookPages &&
            !isLoading &&
            !disableButtons && (
              <NoData
                message={
                  channelType === 'googleads' && userPermissionList?.length > 0 && setupStatus == 'true'
                    ? textConstants.gadsNoData
                    : userPermissionList?.length == 0
                    ? 'No accounts found'
                    : textConstants.noData
                }
                showImage={true}
                accountLocked={false}
                setupInProgress={channelType === 'googleads' && userPermissionList?.length > 0 ? true : false}
              />
            )}
          {disableButtons && userPermissionList?.length == 0 && (
            <NoData
              message={'No accounts found'}
              showImage={true}
              accountLocked={userPermissionList?.length == 0 || setupStatus == 'true' ? false : true}
              setupInProgress={false}
            />
          )}
          {(disableButtons ||
            (channelType == textConstants.facebookPages && !userPermissionList[selectedWorkspace]?.pagesDetails[0].title)) &&
            !isLoading && (
              <ChannelErrorPage redirectionURL={redirectionURL} isFacebook={channelType == textConstants.facebookPages} />
            )}
        </div>
      </div>
      {addUserModal && (
        <AddUserModal
          setIsOpen={setAddUserModal}
          isOpen={addUserModal}
          showRoleSelector={true}
          roleList={roleList}
          permissionList={permissionList}
          handleEmailInput={value => handleModalInput(value, 'email')}
          //handleRoleInput={value => handleModalInput(value, 'role')}
          handleRoleInput={value => handleRoleInput(value)}
          //handlePermissionInput={value => handleModalInput(value, 'permission')}
          handlePermissionInput={value => handlePermissionInput(value)}
          fromChannel={true}
          selectedChannel={channelType}
          handleSubmit={() => handleSubmit()}
        />
      )}
      <ConfirmationModal
        isConfirmModalOpen={confirmModal}
        setIsConfirmModalOpen={setConfirmModal}
        handleClose={() => setConfirmModal(false)}
        handleConfirm={() => {
          refreshChannel(
            location?.state?.channelProvider,
            location?.state?.channelType,
            location?.state?.channelName,
            '/userPermissionChannelView'
          );
        }}
        message={'Channel needs to be refreshed for the latest updates'}
        subMessage={'Do you wish to refresh channel?'}
        isOwner={false}
        showCancel={true}
      />
      <SetupChannelModal
        isSetupModalOpen={isSetupModalOpen}
        setIsSetupModalOpen={setIsSetupModalOpen}
        // constants={constants}
        message={
          'The channel has been refreshed successfully. If the latest changes have not been reflected, refresh the page after some time.'
        }
        showICon={true}
      />
    </CommonDashboard>
  );
};
export default UsersPermissionsChannelView;
