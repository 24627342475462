import { API } from '../constants/api';
import { get } from '../utils/apiHandler';

export const userCount = async tenantId => {
  try {
    const response = await get(`${API.users}/${tenantId}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
