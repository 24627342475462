import React, { useState } from 'react';
import { Dialog } from '@headlessui/react';
import OTPInput from 'otp-input-react';
import { otpConstants } from './constants';
import { resendOtp } from '../../../services';
import Icons from '../../../assets/Icons';

export const OtpVerificationModal = props => {
  const [otp, setOtp] = useState('');
  const [resendOtpMessage, setResendOtpMessage] = useState('');
  const handleResendOtp = async () => {
    props.handleReset();
    // for signup -its phone and for login - its mobile
    const mobile = props.user.mobile || props.user.phone;
    const countryCode = props.user.countryCode;
    const email = props.user.email;
    if (mobile && countryCode && email) {
      const data = {};
      // when phone and mobile have same name, use the below code
      // otpConstants.otpTitles.forEach((title) => {
      //   data[title] = props.user[title];
      // });
      data['mobile'] = mobile;
      data['countryCode'] = countryCode;
      data['email'] = email;
      setOtp('');
      resendOtp(data)
        .then(() => {
          setResendOtpMessage({
            message: props.verifyOnlyPhone ? otpConstants.resendOtp.onPhoneSuccess : otpConstants.resendOtp.onSuccess,
            type: 'success',
          });
        })
        .catch(() => {
          setResendOtpMessage({
            message: otpConstants.resendOtp.onFailure,
            type: 'error',
          });
        });
    }
  };
  return (
    <>
      <Dialog.Title
        as="div"
        className="login-modal-header relative flex  min-h-[5.813rem] items-center justify-center overflow-hidden bg-primary300 p-4 text-black shadow-none md:min-h-[6.25rem]"
      >
        <div className="z-10 text-white">
          <h2 className="mb-2 text-2xl font-bold leading-9 md:mb-3">{props.constants.title}</h2>
        </div>
        <button
          className="absolute top-4 right-4 z-10 text-white"
          onClick={() => {
            props.setIsOpen(false);
            setResendOtpMessage('');
          }}
        >
          <Icons.CloseIcon />
        </button>
      </Dialog.Title>
      <div className="bg-white  p-8 md:px-5 md:pt-6 md:pb-10">
        <>
          <div className="w-full text-left">
            <h2 className="mb-2 text-base font-semibold leading-4.5 text-black">{props.constants.otpModal.subHeading}</h2>
            <p className="mb-6 text-xs leading-4.5 text-gray-dark">{props.constants.otpModal.details}</p>
          </div>
          <div className="flex items-center">
            <OTPInput
              value={otp}
              onChange={setOtp}
              autoFocus
              OTPLength={4}
              otpType="number"
              disabled={false}
              className="otpInput"
            />
          </div>
          {props.error !== '' && resendOtpMessage == '' ? (
            <span style={{ color: 'red', fontSize: 'small', alignSelf: 'center' }}>{props.error}</span>
          ) : null}

          <br />
          {resendOtpMessage !== '' ? (
            <span
              style={{
                color: resendOtpMessage?.type === 'success' ? 'green' : 'red',
                fontSize: 'small',
                alignSelf: 'center',
              }}
            >
              {resendOtpMessage.message}
            </span>
          ) : null}
          <div className="footer-buttons flex w-full items-center justify-between pt-8 md:pt-8">
            <button
              className="mr-4 cursor-pointer whitespace-nowrap text-xs !font-normal leading-[0.875rem] text-primary underline"
              onClick={handleResendOtp}
            >
              {props.constants.otpModal.resendCode}
            </button>
            <button
              className="bg-primary text-white"
              onClick={() => {
                props.handleSubmit(otp);
                setResendOtpMessage('');
              }}
            >
              {props.resetPassword || props.verifyPhone ? props.constants.shortTitle : props.constants.title}
            </button>
          </div>
        </>
      </div>
    </>
  );
};
