/* eslint-disable no-unused-vars */
import React, { Fragment, useContext, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import { Dialog, Transition } from '@headlessui/react';
import { loginConstants } from './constants';
import { manageLoginCredentials } from './helper';
import { resetConstants } from '../ResetPasswordModal/constants';
import { OtpVerificationModal } from '../OtpVerificationModal';
import { ResetPasswordModal } from '../ResetPasswordModal';
import { SetupChannelModal } from '../../ChannelSetup/SetupChannelModal';
import { ConfirmationModal } from '../ConfirmationModal';
import InputText from '../../InputText';
import CheckBox from '../../Checkbox';
import Captcha from '../../Recaptcha';
import useFormHandler from '../../../customHooks/useFormHandler';
import { useToastBox } from '../../../customHooks/useToastBox';
import { createCheckoutSession } from '../../../services/paymentService';
import Icons from '../../../assets/Icons';
import { signupConstants } from '../SignUpModal/constants';
import useAuth from '../../../customHooks/useAuth';
import { SocketContext } from '../../../context/socketIoContext/socketIoContext';
import Loader from '../../Loader';

export const LoginModal = props => {
  const navigate = useNavigate();
  const [passwordShown, setPasswordShown] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [resetPasswordModal, setResetPasswordModal] = useState(false);
  const [subscriptionStatus, setSubscriptionStatus] = useState(true);
  const [retryPaymentData, setRetryPaymentData] = useState({ tenantId: '', type: '' });
  const [selectPayment, setSelectPayment] = useState(false);
  const [isOwner, setIsOwner] = useState(null);
  const { setUserData } = useAuth();
  const { setNewUser } = useContext(SocketContext);
  const [subscriptionType, setSubscriptionType] = useState('');

  const togglePassword = () => {
    // When the handler is invoked
    // inverse the boolean state of passwordShown
    setPasswordShown(!passwordShown);
  };

  const isLogin = true;
  const [loginFormData, setLoginFormData] = useState({});
  const [rememberMe, setRememberMe] = useState(false);
  const [resetCaptcha, setResetCaptcha] = useState(false);
  const {
    user,
    setUser,
    formError,
    setFormError,
    handleEmailChange,
    handlePasswordChange,
    handleFormSubmit,
    handleOtpSubmit,
    handleCaptchaChange,
    resetForm,
  } = useFormHandler(loginFormData, setLoginFormData, isLogin);

  const { handleSuccessToast, handleErrorToast } = useToastBox();

  const handleLoginSubmitError = error => {
    setResetCaptcha(true);
    const { captchaToken, ...newFormData } = loginFormData;
    setLoginFormData(newFormData);

    if (!formError?.captchaToken?.trim('') && !formError?.email?.trim('') && !formError?.password?.trim('')) {
      if (error.message !== loginConstants.incorrectResponseError) {
        setFormError(values => ({
          ...values,
          [loginConstants.validationErrorMessages.passwordMatchError]: error.message,
        }));
      }
      if (error.message) {
        handleErrorToast(error.message);
      }
    }
  };

  const handleLoginSubmit = event => {
    setIsLoading(true);
    handleFormSubmit(event)
      .then(response => {
        let subscription_type = CryptoJS?.AES?.encrypt(
          response?.data?.data?.subscriptiontype,
          'subscription_type'
        )?.toString();
        setSubscriptionType(subscription_type);
        sessionStorage?.setItem('subscription_type', subscription_type);
        sessionStorage.setItem('tenant_id', response?.data?.data?.tenantId);
        setIsOwner(response?.data?.data.owner);
        //Login check for stripe active subscription
        if (Object.keys(response?.data?.data?.subscriptionDetails).length > 0) {
          setUser(response.data.data);
          setOtpModal(true);
          setIsLoading(false);
          if (response?.data?.data?.subscriptionDetails?.active) {
            setLoginFormData({});
            setFormError({});
          } else {
            const subscriptionId = response?.data?.data?.subscriptionDetails.subscriptionId;
            const reSubscribe = subscriptionId ? true : false;
            setRetryPaymentData({
              tenantId: response?.data?.data?.tenantId,
              type: response?.data?.data?.subscriptiontype,
              reSubscribe,
            });
            if (subscriptionId) setSubscriptionStatus(false);
            else setSelectPayment(true);
          }
        } else {
          setUser(response.data.data);
          setOtpModal(true);
          setLoginFormData({});
          setFormError({});
          setIsLoading(false);
        }
      })
      .catch(error => {
        handleLoginSubmitError(error);
        setIsLoading(false);
      });
  };

  const handleTwoFactorAuthentication = event => {
    handleOtpSubmit(event)
      .then(response => {
        if (!subscriptionStatus) {
          setIsConfirmModalOpen(true);
        } else if (selectPayment) {
          navigate('/payment', { state: { user: response.data.data } });
        } else {
          const loginCredentials = response.data.data;
          manageLoginCredentials(response.data.data, rememberMe, setUserData, setNewUser, subscriptionType);
          handleSuccessToast('Login Success ');
          if (loginCredentials.user.type === 'user') {
            navigate('/workspacesList');
          } else if (loginCredentials.user.type === 'admin') {
            navigate('/add-License');
          } else {
            navigate('/dashboard');
          }
        }
        setOtpModal(false);
        setFormError(values => ({
          ...values,
          [loginConstants.validationErrorMessages.loginError]: '',
        }));
      })
      .catch(error => {
        setFormError(values => ({
          ...values,
          [loginConstants.validationErrorMessages.loginError]: error.message,
        }));
        handleErrorToast(error.message);
      });
  };

  const navigateToSignup = () => {
    resetForm();
    props.setIsLoginModalOpen(false);
    props.setIsSignupModalOpen(true);
  };

  const handleClose = () => {
    setIsConfirmModalOpen(false);
    setSubscriptionStatus(true);
  };

  const handleConfirm = () => {
    setIsLoading(true);
    setIsConfirmModalOpen(false);
    setSubscriptionStatus(true);
    // navigate('/payment', {
    //   state: {
    //     retryPayment: true,
    //   },
    // });
    let plan = {
      subscriptionType: retryPaymentData.type,
      tenantId: retryPaymentData.tenantId,
      ...(retryPaymentData.reSubscribe ? { reSubscribe: true } : { update: false }),
    };
    createCheckoutSession(plan)
      .then(response => {
        if (response?.status === 201 || response?.status === 200) {
          setIsLoading(false);
          window.location.href = response?.data?.data?.checkoutUrl;
          //setIsLoading(false);
        }
      })
      .catch(err => {
        handleErrorToast(err.message);
        setIsLoading(false);
      });
  };

  return (
    <Transition appear show={props.isLoginModalOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[27.375rem] transform overflow-hidden rounded-lg  transition-all">
                {/*Login details input modal */}
                {otpModal === false && resetPasswordModal === false && isConfirmModalOpen === false ? (
                  <>
                    <Dialog.Title
                      as="div"
                      className="login-modal-header relative flex  min-h-[6.5rem] items-center justify-center overflow-hidden bg-primary300 p-4 text-black shadow-none md:min-h-[7.938rem]"
                    >
                      <div className="z-10 text-white">
                        <h2 className="mb-2 text-2xl font-bold leading-9 md:mb-3">{loginConstants.title}</h2>
                        <p className="text-xs font-normal leading-[0.875rem]">
                          {loginConstants.textConstants.newUser}
                          <button className="ml-1 cursor-pointer !font-normal underline" onClick={navigateToSignup}>
                            {signupConstants.title}
                          </button>
                          {/* <NavLink to="/forgot-password"> {loginConstants.textConstants.signupNow}</NavLink> */}
                        </p>
                      </div>
                      <button
                        className="absolute top-4 right-4 z-10 text-white"
                        onClick={() => {
                          props.setIsLoginModalOpen(false);
                          setFormError({});
                          setLoginFormData({});
                        }}
                      >
                        <Icons.CloseIcon />
                      </button>
                    </Dialog.Title>
                    <div className="bg-white  p-8 md:px-4.5 md:pt-6 md:pb-10">
                      {isLoading && (
                        <div className="signUploader flex items-center justify-center">
                          <div
                            className="mt-5 inline-block h-12 w-12 animate-spin rounded-full border-4 border-solid border-current border-r-transparent align-[-0.125em] text-primary motion-reduce:animate-[spin_1.5s_linear_infinite]"
                            role="status"
                          >
                            <span className="!absolute !-m-px !h-px !w-px !overflow-hidden !whitespace-nowrap !border-0 !p-0 ![clip:rect(0,0,0,0)]">
                              Loading...
                            </span>
                          </div>
                        </div>
                      )}
                      <div className="user-details-grid grid w-full grid-cols-1 gap-6 md:gap-4">
                        <InputText
                          className="flex flex-col"
                          id="email"
                          placeholder="Email Address"
                          type="email"
                          label="Email Address"
                          name="email"
                          onChange={handleEmailChange}
                          error={formError.email}
                        />

                        <InputText
                          className="flex flex-col"
                          id="password"
                          placeholder="Password"
                          type={passwordShown ? 'text' : 'password'}
                          label="Password"
                          isPassword={true}
                          onClick={togglePassword}
                          passwordShown={passwordShown}
                          name="password"
                          disabled={false}
                          onChange={handlePasswordChange}
                          error={formError.password}
                          maxLength={15}
                        />
                        {formError.passwordMatch ? (
                          <div
                            style={{
                              color: 'red',
                              fontSize: 'small',
                              alignSelf: 'self-start',
                            }}
                          >
                            {formError.passwordMatch}
                          </div>
                        ) : null}

                        <div className="flex flex-col">
                          <CheckBox
                            id="s1"
                            name="A3-confirmation"
                            className="absolute h-5 w-5 cursor-pointer opacity-0"
                            htmlFor="rememberPassword"
                            label={loginConstants.textConstants.rememberMe}
                            onClick={event => {
                              setRememberMe(event.target.checked);
                            }}
                          />
                        </div>
                        <Captcha
                          onChange={event => {
                            handleCaptchaChange(event);
                          }}
                          error={formError.captchaToken}
                          resetCaptcha={resetCaptcha}
                          setReset={() => setResetCaptcha(false)}
                        />
                      </div>
                      <div className="footer-buttons flex w-full items-center justify-between pt-6 md:pt-8">
                        <button
                          className="mr-4 cursor-pointer whitespace-nowrap text-xs !font-normal leading-[0.875rem] text-primary underline"
                          onClick={() => {
                            setResetPasswordModal(true);
                          }}
                        >
                          {loginConstants.textConstants.forgotPassword}
                        </button>
                        <button className="bg-primary text-white" onClick={handleLoginSubmit}>
                          {loginConstants.title}
                        </button>
                      </div>
                    </div>
                  </>
                ) : resetPasswordModal ? (
                  <ResetPasswordModal
                    setIsOpen={setResetPasswordModal}
                    constants={resetConstants}
                    confirmPassword={false}
                    isOpen={resetPasswordModal}
                  />
                ) : subscriptionStatus === true || otpModal ? (
                  <OtpVerificationModal
                    setIsOpen={setOtpModal}
                    constants={loginConstants}
                    handleSubmit={handleTwoFactorAuthentication}
                    handleReset={() =>
                      setFormError(values => ({
                        ...values,
                        [loginConstants.validationErrorMessages.loginError]: '',
                      }))
                    }
                    user={user}
                    error={formError.loginError}
                  />
                ) : isConfirmModalOpen ? (
                  <ConfirmationModal
                    isConfirmModalOpen={isConfirmModalOpen}
                    setIsConfirmModalOpen={setIsConfirmModalOpen}
                    handleClose={() => handleClose()}
                    handleConfirm={() => {
                      isOwner ? handleConfirm() : handleClose();
                    }}
                    message={'User does not have an active subscription '}
                    subMessage={isOwner ? 'Do you wish to take a new subscription?' : 'Please contact your license owner'}
                    isOwner={isOwner}
                    showCancel={isOwner ? true : false}
                  />
                ) : null}
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
