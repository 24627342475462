export const otpConstants = {
  otpTitles: ['countryCode', 'mobile', 'email'],
  errorMessages: {
    inCompleteOtp: 'Incomplete OTP',
    unknownOtpError: 'Something went wrong',
  },
  resendOtp: {
    onSuccess: 'Successfully sent OTP to your registered phone and email',
    onPhoneSuccess: 'Successfully sent OTP to your registered phone',
    onFailure: 'Failed to sent OTP to your phone',
  },
  otpLength: 4,
};
