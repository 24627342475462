/* eslint-disable no-unused-vars */
import React, { useContext, useEffect, useRef, useState } from 'react';
import classes from './reports.module.scss';
import Select, { components } from 'react-select';
import Icons from '../../assets/Icons';
import ChannelList from '../../components/ChannelSetup';
import NoData from '../../components/Nodata';
import { AlertModal } from '../../components/Modal/AlertModal';
import Table from '../../components/Table';
import CommonDashboard from '../../components/CommonDashboard';
import ChannelListContext from '../../context/channelListContext';
import { Disclosure } from '@headlessui/react';

import {
  formatChannelSelectList,
  formatPageSelectList,
  handleDistributionSave,
  handleDownload,
  handlePageSelection,
  processUserList,
  regularityFinder,
  channelFinder,
  pageFinder,
  userFinder,
} from './helper';
import { getUserPermissionChannelDetail } from '../../services/userPermissionChannelViewService';
import useDate from '../../customHooks/useDate';
import { useToastBox } from '../../customHooks/useToastBox';
import { viewersList } from '../../services';
import { constants, regularities } from './constants';
import { distributeReport, getReportDetails, deleteReport } from '../../services/reportService';
import Loader from '../../components/Loader';
import { processChannels } from '../../components/ChannelSetup/helper';
import { loginConstants } from '../../components/Modal/LoginModal/constants';
import { subbedChannelList } from '../../services/channelListService';
import { getUserInfo } from '../../utils/getUserInfo';
import { channelLabelSelector } from '../../utils/helpers';
import { ListingModal } from '../../components/Modal/ListingModal';

const Reports = () => {
  const dateInputRef = useRef(null);

  const handleClick = () => {
    if (dateInputRef.current) {
      // Handle the calendar icon click event here
      console.log('Calendar icon clicked!');
    }
  };
  const userInfo = JSON.parse(getUserInfo());
  const userId = userInfo?.user?.id;
  sessionStorage.setItem('page', 'Trends');
  const [reportType, setReportType] = useState('generate');
  const [metaData, setMetaData] = useState();
  const [page, setPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const { channelList } = useContext(ChannelListContext);
  const [channelOptions, setChannelOptions] = useState([]);
  const [pageOptions, setPageOptions] = useState([]);
  const [channelValue, setChannelValue] = useState('');
  const [reportContent, setReportContent] = useState({
    reportType: reportType,
    channel: '',
    page: [],
    groupName: '',
    userList: [],
    regularity: '',
    pageName: '',
  });
  const [refresh, setRefresh] = useState(false);
  const [userList, setUserList] = useState([]);
  const [reportsList, setReportsList] = useState([]);
  const [deleteUser, setDeleteUser] = useState();
  const { todaysDate } = useDate();
  const { handleSuccessToast, handleErrorToast } = useToastBox();
  const [isLoading, setIsLoading] = useState(true);
  const [openAlertModal, setOpenAlertModal] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [groupId, setGroupId] = useState('');
  const [openListingModal, setOpenListingModal] = useState(false);
  const [modalData, setModalData] = useState({ Header: '', users: [] });
  const [pageRange, setPageRange] = useState(1);

  const today = todaysDate();
  const selectPageRef = useRef();
  const pageRef = useRef(null);

  const convertDate = dateTime => {
    const options = {
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    const date = new Date(dateTime);

    return date.toLocaleString('en-US', options);
  };
  const onDistributeSelect = () => {
    setReportContent({ ...reportContent, groupName: '', userList: [] });
    viewersList().then(res => {
      processUserList(res?.data?.data?.results).then(res => setUserList(res));
    });
  };
  const onNameChange = e => {
    setReportContent({ ...reportContent, groupName: e.target.value });
  };

  const handleEditClick = async item => {
    setGroupId(item?.id);
    if (item?.channel) {
      setEditMode(true);
      pageRef.current.scrollTo({
        top: 0,
        behavior: 'smooth',
      });
      await getUserPermissionChannelDetail(item?.channel)
        .then(response => {
          setPageOptions(formatPageSelectList(response?.data));
          setReportContent({
            ...reportContent,
            channel: channelFinder(channelOptions, item?.channel),
            page: pageFinder(formatPageSelectList(response?.data), item?.accountId),
            groupName: item?.name,
            userList: userFinder(userList, item?.recipients),
            regularity: regularityFinder(item?.regularity),
          });
        })
        .catch(error => {
          handleErrorToast(error.message);
        });
    }
  };
  const onRegularityChange = e => {
    setReportContent({ ...reportContent, regularity: e });
  };

  const tableColumns = [
    {
      Header: 'Group Name',
      accessor: item => item.name,
    },
    {
      Header: 'Channel',
      accessor: item => (
        <>
          <div className="mr col-span-2 flex items-center pl-4 xl:!hidden">{channelLabelSelector(item.channel)}</div>
        </>
      ),
    },
    {
      Header: 'Pages',
      accessor: item =>
        item?.accountNames?.length > 2 ? (
          <>
            {item?.accountNames?.slice(0, 2).map((value, index) => (
              <div key={index}>{value} </div>
            ))}

            <button
              className=" mt-[2px] inline-flex cursor-pointer rounded-full !bg-primary !px-2 !py-[2px] text-xs !text-white"
              onClick={() => {
                setModalData({ header: 'Pages', users: item?.accountNames });
                setOpenListingModal(true);
              }}
            >
              +{item?.accountNames?.length - 2} more
            </button>
          </>
        ) : (
          <>
            {item?.accountNames?.map((value, index) => (
              <div key={index}>{value} </div>
            ))}
          </>
        ),
    },

    {
      Header: 'recipients',
      accessor: item =>
        item?.recipients?.length > 2 ? (
          <>
            {item?.recipients?.slice(0, 2).map((value, index) => (
              <div key={index}>{value?.label} </div>
            ))}

            <button
              onClick={() => {
                setModalData({ header: 'Recipients', users: item?.recipients });
                setOpenListingModal(true);
              }}
              className=" mt-[2px] inline-flex cursor-pointer rounded-full !bg-primary !px-2 !py-[2px] text-xs !text-white"
            >
              +{item?.recipients?.length - 2} more
            </button>
          </>
        ) : (
          <>
            {item?.recipients?.map((value, index) => (
              <div key={index}>{value?.label} </div>
            ))}
          </>
        ),
    },
    {
      Header: 'Frequency',
      accessor: item => (
        <>
          <div className="mr col-span-2 flex items-center pl-4 capitalize xl:!hidden">{item?.regularity}</div>
        </>
      ),
    },
    {
      Header: 'Next Report Date',
      accessor: item => (
        <>
          <div className="mr col-span-2 flex items-center pl-4 capitalize xl:!hidden">
            {convertDate(item?.nextReportDate)}
          </div>
        </>
      ),
    },
    {
      Header: 'Actions',
      accessor: item => (
        <div className="flex items-center">
          <button
            className={`btn-link flex items-center gap-x-0.5 md:mt-4`}
            aria-label="Request to Remove"
            onClick={() => {
              setOpenAlertModal(true);
              setDeleteUser({ id: item?.id });
            }}
          >
            <Icons.TrashIcon />
          </button>
          <button
            className={`btn-link ml-3 flex items-center gap-x-0.5 md:mt-4`}
            aria-label="Request to Remove"
            onClick={() => {
              handleEditClick(item);
            }}
          >
            <Icons.EditIcon />
          </button>
        </div>
      ),
    },
  ];

  const onUserSelect = e => {
    let isAllSelected = false;
    e.map(x => {
      if (x.value === 'All') {
        isAllSelected = true;
      }
    });
    if (isAllSelected) {
      setReportContent({ ...reportContent, userList: [constants.allOption] });
    } else {
      setReportContent({ ...reportContent, userList: e });
    }
  };

  const getReportData = () => {
    setIsLoading(true);
    getReportDetails(userId, page, pageSize)
      .then(response => {
        setIsLoading(false);
        setMetaData(response?.data?.data);
        setReportsList(response?.data?.data?.result);
      })
      .catch(err => {
        setIsLoading(false);
        handleErrorToast(err.message);
      });
  };

  const handleRemove = () => {
    setIsLoading(true);
    deleteReport(deleteUser?.id)
      .then(response => {
        setEditMode(false);
        setReportContent({ ...reportContent, groupName: '', userList: [], regularity: '', channel: '', page: [] });
        getReportData();
        handleSuccessToast(response?.data?.data);
        setIsLoading(false);
      })
      .catch(err => {
        setIsLoading(false);
        handleErrorToast(err.message);
      });
  };

  const onDistributeSave = () => {
    setIsLoading(true);
    const flag = handleDistributionSave(reportContent, pageOptions, handleErrorToast);

    if (flag) {
      const pages = [];
      let users = [];
      if (reportContent?.page[0]?.label === 'All') {
        pageOptions.map(x => (x.value !== 'All' ? pages.push(x.value) : null));
      } else {
        pages.push(reportContent?.page[0]?.value);
        reportContent.pageName = reportContent?.page.length > 1 ? 'All' : reportContent?.page[0]?.label;
      }
      if (reportContent.userList[0].value === 'All') {
        users = userList.slice(1);
      }

      distributeReport(reportContent, pages, users, editMode, groupId)
        .then(() => {
          setEditMode(false);
          handleSuccessToast(editMode ? 'Report updated successfully' : 'Report distributed successfully');
          getReportData();
          setReportContent({ ...reportContent, groupName: '', userList: [], regularity: '', channel: '', page: [] });
          setIsLoading(false);
        })
        .catch(error => {
          setIsLoading(false);
          handleErrorToast(error.message);
        });
    } else {
      setIsLoading(false);
    }
  };

  const { Option } = components;
  const IconOption = props => (
    <Option {...props}>
      {props.data.label}{' '}
      {props?.data?.label !== 'All' ? (
        props?.data?.isAdmin ? (
          <button
            className={` border-blue-medium min-w-[60px] rounded-md border border-solid text-xs font-semibold text-primary `}
          >
            {'Admin'}
          </button>
        ) : (
          <button
            className={` min-w-[60px] rounded-md border border-solid border-yellow-medium text-xs font-semibold text-red-400 `}
          >
            {'Viewer'}
          </button>
        )
      ) : null}
    </Option>
  );

  useEffect(() => {
    subbedChannelList()
      .then(response => {
        const channels = JSON.parse(sessionStorage.getItem(loginConstants.localChannelKey));
        const processedChannels = processChannels(channels, response?.data?.data);
        setChannelOptions(formatChannelSelectList(processedChannels));
      })
      .catch(err => {
        handleErrorToast(err.message);
      });

    setIsLoading(false);

    if (channelValue) {
      getUserPermissionChannelDetail(channelValue)
        .then(response => {
          setPageOptions(formatPageSelectList(response?.data));
        })
        .catch(error => {
          handleErrorToast(error.message);
        });
    }
  }, [channelList, channelValue]);

  useEffect(() => {
    getReportData();
  }, [page, pageSize]);

  return (
    <CommonDashboard classList={classes.messagingWrapper} hideHeader={false}>
      {isLoading && <Loader />}
      <span className={classes.dvOnly}>
        <ChannelList shouldAuthenticate={false} onLoad={() => {}} isLoading={false} />
      </span>
      <div className={classes.coreConsole} ref={pageRef}>
        <div className={classes.coreHeader}>
          <h3 className=" !text-lg">Reports</h3>
        </div>
        <div className={classes.coreCard}>
          <div className={classes.card}>
            <div className={classes.reportWrapper}>
              <div className={classes.reportRow}>
                <label className={`form-checkbox -card-options ${classes.reportType}`}>
                  <input
                    className="form-checkbox-input"
                    type="radio"
                    name="report"
                    checked={reportType === 'generate' ? true : false}
                    onChange={() => {
                      setReportType('generate');
                      setReportContent(values => ({ ...values, reportType: 'generate' }));
                    }}
                  />
                  <div className="form-check-circle">
                    <Icons.TickSmall />
                  </div>
                  <span className="label-text">Generate Report</span>
                </label>
                <label className={`form-checkbox -card-options ${classes.reportType}`}>
                  <input
                    className="form-checkbox-input"
                    type="radio"
                    name="report"
                    checked={reportType === 'distribute' ? true : false}
                    onChange={() => {
                      onDistributeSelect();
                      setReportType('distribute');
                      setReportContent(values => ({ ...values, reportType: 'distribute' }));
                    }}
                  />
                  <div className="form-check-circle">
                    <Icons.TickSmall />
                  </div>
                  <span className="label-text">Distribute Report</span>
                </label>
              </div>
              <div className={classes.reportRow}>
                <div className={classes.formControlWrapper}>
                  <label className={classes.formControlLabel}>
                    <span className="relative">
                      Select a Channel
                      <span className="top absolute">
                        <Icons.AsteriskIcon />
                      </span>
                    </span>
                  </label>
                  <Select
                    options={channelOptions}
                    placeholder="Select a Channel"
                    classNamePrefix="hydra-dropdown"
                    onChange={event => {
                      setChannelValue(event.value);
                      selectPageRef?.current?.setValue('');
                      setReportContent(values => ({ ...values, channel: event, page: '' }));
                    }}
                    value={reportContent?.channel}
                  />
                </div>
                <div className={classes.formControlWrapper}>
                  <label className={classes.formControlLabel}>
                    <span className="relative">
                      Select a Page
                      <span className="top absolute">
                        <Icons.AsteriskIcon />
                      </span>
                    </span>
                  </label>
                  <Select
                    options={pageOptions}
                    placeholder="Select a Page"
                    classNamePrefix="hydra-dropdown"
                    onChange={event => {
                      handlePageSelection(event, reportContent, setReportContent, pageOptions, refresh, setRefresh);
                      // setReportContent(values => ({ ...values, page: event.value }));
                    }}
                    ref={selectPageRef}
                    value={reportContent?.page?.length > 0 ? reportContent?.page[0] : null}
                  />
                </div>
              </div>
              {reportType === 'generate' && (
                <div className={classes.reportRow}>
                  <div className={`${classes.formControl}`}>
                    <label className={classes.formControlLabel}>
                      <span className="relative">
                        From
                        <span className="top absolute">
                          <Icons.AsteriskIcon />
                        </span>
                      </span>
                    </label>
                    <input
                      className={classes.formInput}
                      type={'date'}
                      placeholder="DD/MM/YYYY"
                      min={'2022-01-01'}
                      max={reportContent?.to ? reportContent.to : today}
                      // defaultValue={'2022-01-01'}
                      onChange={event => {
                        setReportContent(values => ({ ...values, from: event.target.value }));
                      }}
                    />
                  </div>
                  <div className={`${classes.formControl}`}>
                    <label className={classes.formControlLabel}>
                      <span className="relative">
                        To
                        <span className="top absolute">
                          <Icons.AsteriskIcon />
                        </span>
                      </span>
                    </label>
                    <input
                      className={classes.formInput}
                      placeholder="DD/MM/YYYY"
                      type={'date'}
                      min={reportContent?.from ? reportContent.from : '2022-01-01'}
                      max={today}
                      // defaultValue={today}
                      ref={dateInputRef}
                      onClick={handleClick}
                      onChange={event => {
                        setReportContent(values => ({ ...values, to: event.target.value }));
                      }}
                    />
                  </div>
                </div>
              )}
              {reportType === 'distribute' && (
                <>
                  <div className={classes.reportRow}>
                    {/* <div className={classes.formControlWrapper}>
                      <label className={classes.formControlLabel}>Regularity</label>
                      <Select options={options} placeholder="Regularity" value={''} classNamePrefix="hydra-dropdown" />
                    </div> */}
                    <div className={classes.formControlWrapper}>
                      <label className={classes.formControlLabel}>
                        <span className="relative">
                          Group name
                          <span className="top absolute">
                            <Icons.AsteriskIcon />
                          </span>
                        </span>
                      </label>
                      <input
                        placeholder="Enter group name"
                        value={reportContent.groupName}
                        onChange={onNameChange}
                        maxLength={50}
                        className="mt-1.5 block w-full rounded-md border border-gray-light bg-white py-3 px-3 text-xs font-medium leading-4.5 text-gray-dark shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
                      />
                    </div>

                    <div className={`${classes.formControlWrapper} ${classes.receipient}`}>
                      <label className={classes.formControlLabel}>
                        <span className="relative">
                          Recipients
                          <span className="top absolute">
                            <Icons.AsteriskIcon />
                          </span>
                        </span>
                      </label>
                      <Select
                        options={userList}
                        isMulti={true}
                        onChange={onUserSelect}
                        placeholder="Enter email address and press return/enter"
                        classNamePrefix="hydra-dropdown"
                        value={reportContent.userList}
                        components={{ Option: IconOption }}
                      />
                    </div>
                  </div>
                  <div className={classes.reportRow}>
                    <div className={`${classes.formControlWrapper} ${classes.receipient}`}>
                      <label className={classes.formControlLabel}>
                        <span className="relative">
                          Frequency
                          <span className="top absolute">
                            <Icons.AsteriskIcon />
                          </span>
                        </span>
                      </label>
                      <Select
                        options={regularities}
                        onChange={onRegularityChange}
                        placeholder="Select a Frequency"
                        classNamePrefix="hydra-dropdown"
                        value={reportContent?.regularity}
                      />
                    </div>
                    <div className="w-full"></div>
                  </div>
                  <div className={classes.reportRow}>
                    <p className={classes.information} style={{ maxWidth: '100%' }}>
                      {/* Report will be generated for the Channel & Page combinations you have selected and will be sent to the
                      recipients every week on Monday. */}
                      Reports can be sent on a daily, weekly, monthly or quarterly basis for your selected channels and
                      pages.
                    </p>
                  </div>
                </>
              )}
              <div className={classes.reportRow}>
                {reportType === 'generate' && (
                  <button
                    aria-label="Download Report"
                    className={`btn-primary-1 ${classes.viewAll}`}
                    disabled={reportType === ''}
                    onClick={() => {
                      handleDownload(reportContent, pageOptions, handleSuccessToast, handleErrorToast, setIsLoading);
                    }}
                  >
                    <span>Download Report</span>
                  </button>
                )}
                {reportType === 'distribute' && (
                  <button
                    aria-label="Save"
                    className={`btn-primary-1 ${classes.viewAll}`}
                    disabled={reportType === ''}
                    onClick={onDistributeSave}
                  >
                    <span>{editMode ? 'Update' : 'Save'}</span>
                  </button>
                )}
              </div>
            </div>

            {reportType === 'distribute' && (
              <div className="inset-0 mt-3 !hidden overflow-auto rounded-lg border border-white200 bg-white p-4 pt-6 md:border-none md:bg-transparent md:p-0 xl:!block">
                <div className=" mb-4 font-semibold leading-5 text-gray-dark">Reports Listing</div>
                <div className=" overflow-hidden rounded-lg border-none border-white200 pb-20 xl:h-full xl:overflow-auto xl:rounded-none xl:border-none">
                  <ul className="userListWrapper hidden xl:block xl:h-full xl:space-y-2">
                    {reportsList && reportsList?.length == 0 && <NoData message={'No users found'} showImage={true} />}
                    {reportsList &&
                      reportsList?.length > 0 &&
                      reportsList?.map((value, index) => (
                        <li
                          className="grid grid-cols-5 border-0 border-white200 bg-white py-4 hover:bg-primaryLight xl:rounded-lg xl:border xl:py-3 xl:px-4 xl:pr-9"
                          key={index}
                        >
                          <div className="col-span-5 !hidden w-full flex-col xl:!flex">
                            <Disclosure>
                              {({ open }) => (
                                <>
                                  <Disclosure.Button className=" relative flex w-full flex-col justify-between rounded-lg !bg-transparent p-0 text-left  text-sm">
                                    <div className="w-full justify-between">
                                      <span className="truncate pr-1  text-xs font-medium text-gray-dark">
                                        {value?.name}
                                      </span>
                                      <div>
                                        <span className="text-xs font-medium text-gray-dark">
                                          {channelLabelSelector(value?.channel)}
                                        </span>
                                      </div>
                                    </div>
                                    <div
                                      className={`${
                                        open ? '!hidden' : ''
                                      } mt-[0.125rem] w-full text-[0.625rem] font-medium leading-4 text-gray-medium`}
                                    ></div>

                                    <div className={`${open ? 'rotate-180 transform' : ''} absolute top-1.5 -right-5.5`}>
                                      <Icons.DownArrow />
                                    </div>
                                  </Disclosure.Button>
                                  <Disclosure.Panel className="!flex w-full pt-2">
                                    <div className="!grid w-full !grid-cols-2 pt-2 text-sm text-gray-500 xs:!grid-cols-1 xs:gap-y-2">
                                      <div className=" addedBy flex-col !items-start !justify-start text-xs font-medium leading-4  text-gray-dark">
                                        <label className=" mb-[0.125rem] text-[0.625rem] leading-4 text-gray-dark1">
                                          {' '}
                                          RECIPIENTS
                                        </label>
                                        {value?.recipients?.length > 2 ? (
                                          <>
                                            {value?.recipients?.slice(0, 2).map((value, index) => (
                                              <div key={index}>{value?.label} </div>
                                            ))}
                                            <button
                                              onClick={() => {
                                                setModalData({ header: 'Recipients', users: value?.recipients });
                                                setOpenListingModal(true);
                                              }}
                                              className=" mt-[2px] inline-flex cursor-pointer rounded-full !bg-primary !px-2 !py-[2px] text-xs !text-white"
                                            >
                                              +{value?.recipients?.length - 2} more
                                            </button>
                                          </>
                                        ) : (
                                          <>
                                            {value?.recipients?.map((value, index) => (
                                              <div key={index}>{value?.label} </div>
                                            ))}
                                          </>
                                        )}
                                      </div>
                                      <div className="flex">
                                        <div className=" addedBy w-full flex-col !items-start !justify-start text-xs font-medium leading-4  text-gray-dark">
                                          <label className=" mb-[0.125rem] text-[0.625rem] leading-4 text-gray-dark1">
                                            {' '}
                                            PAGES
                                          </label>
                                          {value?.accountNames?.length > 2 ? (
                                            <>
                                              {value?.accountNames?.slice(0, 2).map((value, index) => (
                                                <div key={index}>{value} </div>
                                              ))}

                                              <button
                                                className=" mt-[2px] inline-flex cursor-pointer rounded-full !bg-primary !px-2 !py-[2px] text-xs !text-white"
                                                onClick={() => {
                                                  setModalData({ header: 'Pages', users: value?.accountNames });
                                                  setOpenListingModal(true);
                                                }}
                                              >
                                                +{value?.accountNames?.length - 2} more
                                              </button>
                                            </>
                                          ) : (
                                            <>
                                              {value?.accountNames?.map((value, index) => (
                                                <div key={index}>{value} </div>
                                              ))}
                                            </>
                                          )}
                                        </div>
                                        <div className=" addedBy w-full flex-col !items-start !justify-start text-xs font-medium leading-4  text-gray-dark">
                                          <label className=" mb-[0.125rem] text-[0.625rem] leading-4 text-gray-dark1">
                                            {' '}
                                            FREQUENCY
                                          </label>
                                          <div className="capitalize">{value?.regularity}</div>
                                        </div>
                                      </div>
                                    </div>

                                    <div className="-mr-6 flex space-x-3">
                                      <button
                                        className={`btn-link ml-auto flex items-center gap-x-0.5`}
                                        aria-label="Request to Remove"
                                        onClick={() => {
                                          setOpenAlertModal(true);
                                          setDeleteUser({ id: value?.id });
                                        }}
                                      >
                                        <Icons.TrashIcon />
                                      </button>
                                      <button
                                        className={`btn-link ml-auto flex items-center gap-x-0.5`}
                                        aria-label="Request to Remove"
                                        onClick={() => {
                                          handleEditClick(value);
                                        }}
                                      >
                                        <Icons.EditIcon />
                                      </button>
                                    </div>
                                  </Disclosure.Panel>
                                </>
                              )}
                            </Disclosure>
                          </div>
                        </li>
                      ))}
                  </ul>
                </div>
              </div>
            )}
          </div>
          {reportType === 'distribute' && (
            <div className="inset-0 mt-3 mb-10 block overflow-auto rounded-lg border border-white200 bg-white p-4 pt-6 md:border-none md:bg-transparent  md:p-0 xl:!hidden">
              <div className=" mb-4 cursor-default font-semibold leading-5 text-gray-dark">Reports Listing</div>
              {reportsList?.length > 0 ? (
                <Table
                  data={reportsList}
                  metaData={metaData}
                  columns={tableColumns}
                  pagination={true}
                  page={page}
                  pageSize={pageSize}
                  pageRange={pageRange}
                  setPageRange={value => setPageRange(value)}
                  paging={false}
                  setPageSize={value => setPageSize(value)}
                  setPage={value => setPage(value)}
                  showPagination={metaData?.totalResults <= 10 ? false : true}
                />
              ) : (
                <NoData message={'No records found'} showImage={true} />
              )}
            </div>
          )}
        </div>
      </div>
      <AlertModal
        isOpen={openAlertModal}
        setIsOpen={setOpenAlertModal}
        handleConfirm={() => handleRemove()}
        heading={'Alert'}
        message={`Are you sure you want to delete the report distribution group?`}
      />
      <ListingModal setIsOpen={setOpenListingModal} isOpen={openListingModal} modalData={modalData} />
    </CommonDashboard>
  );
};
export default Reports;
