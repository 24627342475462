import { API } from '../constants/api';
import { get, put, patch } from '../utils/apiHandler';

export const userList = async (tenantId, page, pageSize) => {
  try {
    const response = page
      ? await get(`${API.user_list}?tenantId=${tenantId}&limit=${pageSize}&offset=${page}`)
      : await get(`${API.user_list}?tenantId=${tenantId}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const addUserList = async () => {
  try {
    const response = await get(`${API.users}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const deleteUserFromList = async data => {
  try {
    const response = await put(`${API.remove_admin_user}`, data);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const revokeUserFromList = async data => {
  try {
    const response = await patch(`${API.revoke_user}`, data);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
