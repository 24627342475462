import React, { useContext, useEffect, useReducer } from 'react';
import AuthContext, { initialState } from './authContext';
import authReducer from './Reducer';
import { LOGIN, LOGOUT, SET_CHANNEL_COUNT, SET_USER_DATA, SET_WORKSPACE_COUNT } from './actions';
import { useLocation, useNavigate } from 'react-router-dom';
import { constants } from '../../constants/constants';
import CryptoJS from 'crypto-js';
import { loginConstants } from '../../components/Modal/LoginModal/constants';
import { getUserInfo } from '../../utils/getUserInfo';
import { SocketContext } from '../socketIoContext/socketIoContext';
function AuthProvider(props) {
  const location = useLocation();
  const navigate = useNavigate();
  const [state, dispatch] = useReducer(authReducer, initialState);
  const { setNotificationCount, setNotifications, setAlerts } = useContext(SocketContext);

  const checkLogin = () => {
    const localStorageData = getUserInfo();
    const userData = JSON.parse(localStorageData) || '';
    const decryptedRole = userData?.user?.role
      ? CryptoJS.AES.decrypt(userData?.user?.role, loginConstants.localKey).toString(CryptoJS.enc.Utf8)
      : null;

    if (decryptedRole === 'admin' && userData) {
      navigate('/add-License');
    } else {
      if (!constants.externalLinks.includes(location.pathname)) {
        if (userData) {
          dispatch({ type: LOGIN, payload: userData });
          if (location.pathname !== '/') {
            navigate(location.pathname, { state: location.state });
          } else {
            navigate(constants.routes.dashboard);
          }
        } else {
          navigate('/');
        }
      } else if (userData) {
        dispatch({ type: LOGIN, payload: userData });
      }
    }
  };

  useEffect(() => {
    checkLogin();
  }, []);

  const setUserData = userData => {
    dispatch({ type: SET_USER_DATA, payload: userData });
  };

  const logout = () => {
    // localStorage.removeItem(loginConstants.localKey);
    // sessionStorage.removeItem(loginConstants.localKey);
    localStorage.clear();
    sessionStorage.clear();
    setNotificationCount(0);
    setAlerts([]);
    setNotifications([]);
    dispatch({ type: LOGOUT });
    navigate('/');
  };

  const clearData = () => {
    localStorage.clear();
    sessionStorage.clear();
    setNotificationCount(0);
    setAlerts([]);
    setNotifications([]);
    dispatch({ type: LOGOUT });
  };

  const setChannelCount = count => {
    dispatch({ type: SET_CHANNEL_COUNT, payload: count });
  };

  const setWorkspaceCount = count => {
    dispatch({ type: SET_WORKSPACE_COUNT, payload: count });
  };

  return (
    <AuthContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        auth: state.auth,
        logout,
        clearData,
        setUserData,
        checkLogin,
        setChannelCount,
        setWorkspaceCount,
      }}
    >
      {props.children}
    </AuthContext.Provider>
  );
}

export default AuthProvider;
