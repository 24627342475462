import { API } from '../constants/api';
import { post } from '../utils/apiHandler';

export const forgotPassword = async data => {
  try {
    const response = await post(API.forgot_password, data);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
