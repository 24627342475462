import { subbedChannelIconsConstants } from '../Dashboard/ChannelSubbed/constants';
import { monthNames } from './constants';
import { getChannelLogo } from '../../utils/helpers';

export const processChannelList = (data, accountUserId, accountUserName) => {
  const pageList = [];
  let pageDetails = [];
  data?.forEach(element => {
    const channel = element.channelType;
    const obj = { checked: true, channel: channel };
    switch (channel) {
      case subbedChannelIconsConstants.channelType.googleAdsTitle:
        obj.logo = subbedChannelIconsConstants.icons.googleAds;
        obj.channelName = subbedChannelIconsConstants.title.googleAdsTitle;

        break;
      case subbedChannelIconsConstants.channelType.googleMerchant:
        obj.logo = subbedChannelIconsConstants.icons.googleMerchantCentre;
        obj.channelName = subbedChannelIconsConstants.title.googleMerchantTitle;

        break;
      case subbedChannelIconsConstants.channelType.googleAnalyticsTitle:
        obj.logo = subbedChannelIconsConstants.icons.googleAnalytics;
        obj.channelName = subbedChannelIconsConstants.title.googleAnalyticsTitle;

        break;
      case subbedChannelIconsConstants.channelType.googleBusiness:
        obj.logo = subbedChannelIconsConstants.icons.googleBusiness;
        obj.channelName = subbedChannelIconsConstants.title.googleBusinessTitle;

        break;

      case subbedChannelIconsConstants.channelType.googleMyBusiness:
        obj.logo = subbedChannelIconsConstants.icons.googleBusiness;
        obj.channelName = subbedChannelIconsConstants.title.googleMyBusinessTitle;

        break;

      case subbedChannelIconsConstants.channelType.googleSearchConsole:
        obj.logo = subbedChannelIconsConstants.icons.googleSearchConsole;
        obj.channelName = subbedChannelIconsConstants.title.googleSearchConsoleTitle;

        break;

      case subbedChannelIconsConstants.channelType.googleTagManager:
        obj.logo = subbedChannelIconsConstants.icons.googleTagManager;
        obj.channelName = subbedChannelIconsConstants.title.googleTagManagerTitle;

        break;
      case subbedChannelIconsConstants.channelType.bingAds:
        obj.logo = subbedChannelIconsConstants.icons.bing;
        obj.channelName = subbedChannelIconsConstants.title.bingAds;

        break;
      case subbedChannelIconsConstants.channelType.facebookAdTitle:
        obj.logo = subbedChannelIconsConstants.icons.facebookAds;
        obj.channelName = subbedChannelIconsConstants.title.facebookAdTitle;

        break;
      case subbedChannelIconsConstants.channelType.facebookPagesTitle:
        obj.logo = subbedChannelIconsConstants.icons.facebook;
        obj.channelName = subbedChannelIconsConstants.title.facebookPagesTitle;

        break;
      case subbedChannelIconsConstants.channelType.linkedInAds:
        obj.logo = subbedChannelIconsConstants.icons.linkedin;
        obj.channelName = subbedChannelIconsConstants.title.linkedInAdsTitle;

        break;
      case subbedChannelIconsConstants.channelType.linkedInPages:
        obj.logo = subbedChannelIconsConstants.icons.linkedin;
        obj.channelName = subbedChannelIconsConstants.title.linkedInPagesTitle;

        break;

      case subbedChannelIconsConstants.channelType.twitterAds:
        obj.logo = subbedChannelIconsConstants.icons.twitter;
        obj.channelName = subbedChannelIconsConstants.title.twitterAdsTitle;

        break;
      case subbedChannelIconsConstants.channelType.twitterPages:
        obj.logo = subbedChannelIconsConstants.icons.twitter;
        obj.channelName = subbedChannelIconsConstants.title.twitterPagesTitle;

        break;

      default:
        break;
    }

    element.accounts?.forEach(element => {
      const channelDetails = getChannelLogo(channel);
      const page = {};
      page.channel = channel;
      page.email = element.email;
      page.logo = channelDetails.imgUrl;
      page.id = element.id;
      page.accountId = element.accountId;
      page.title = element.accountName;
      page.accountUserId = accountUserId;
      page.accountUserName = accountUserName;
      page.lastLogin =
        element.location && element.lastLogin
          ? [
              {
                location: element.location,
                dateTime: processLastLoginDate(element.lastLogin),
              },
            ]
          : [
              {
                location: '-',
                dateTime: '',
              },
            ];
      page.roleOptions = [];
      page.role = element.rolesLabel ? element.rolesLabel : ['-'];
      page.permission = element.details?.permissions ? element.details?.permissions : ['-'];
      page.selected = false;
      page.mobileChecked = false;

      pageDetails.push(page);
    });
    obj.pagesDetails = pageDetails;
    pageList.push(obj);
    pageDetails = [];
  });

  return pageList;
};

export const getCompanyList = data => {
  const companyList = [{ value: 'All', label: 'All' }];
  data?.forEach(element => {
    element?.pagesDetails?.forEach(name => {
      const obj = { value: name.title, label: name.title };
      if (!companyList.filter(e => e.value === name.title).length) {
        companyList.push(obj);
      }
    });
  });

  return companyList;
};

export const processLastLoginDate = date => {
  const newDate = new Date(date);
  const result = `${newDate.getDay()} ${monthNames[newDate.getMonth()]},${newDate.getUTCHours()}:${newDate.getMinutes()}`;
  return result;
};
