import React from 'react';
import { Link } from 'react-router-dom';
import Tippy from '@tippyjs/react';
import Icons from '../../assets/Icons';
import classes from './breadcrumb.module.scss';
import { getInfoContent } from '../../utils/helpers';

const BreadCrumb = props => {
  const showButton = (props.role === 'user' && !!props.backToPrev) || props.role !== 'user';

  const TooltipMessage = ({ channel }) => {
    return (
      <div className="max-w-[325px]">
        <div className=" flex items-center">
          <div className="flex flex-col pl-2">
            <ul className=" list-disc pl-4">
              {getInfoContent(channel)?.map((value, index) => (
                <li key={index} className=" mb-1 text-xs font-normal leading-normal text-white">
                  {value}
                </li>
              ))}
            </ul>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className={`${classes.breadcrumbWrapper}  ${props.classList ? props.classList : ''} `}>
      <div className={classes.breadcrumbLayer}>
        <div className="flex pl-[6px]">
          {showButton && (
            <div className=" pr-1.5 pt-[1px]">
              <Link
                // to={props.prevPage ? props.prevPage : '/dashboard'}
                to={{ pathname: props.prevPage ? props.prevPage : '/dashboard' }}
                state={{ id: props.accountUserId ? props.accountUserId : {}, name: props.userName ? props.userName : {} }}
                className={classes.navigate}
                aria-label="Go back to previous screen"
              >
                <Icons.ChevronLeft />
              </Link>
            </div>
          )}
          <div className="pl-[0.625rem]">
            <div className={classes.breadRow}>
              <div className={classes.userName}>
                <div className={`${classes.userType} ${props.navData[0].highlight ? classes.highlight : ''}`}>
                  {props.navData[0].userType}
                </div>
                {props.navData[0].protectOn && (
                  <div className={classes.verificationStatus}>
                    <Tippy content="Verified by Hydra officials" placement="bottom" arrow={true}>
                      <Icons.ShieldVerify />
                    </Tippy>
                  </div>
                )}
              </div>
            </div>
            <div className={classes.breadRow}>
              <ul className={classes.pathList}>
                {props.navData[0].pathHistory?.map((element, index) => {
                  return (
                    <>
                      <li
                        role="presentation"
                        key={`pathHistory__${index}`}
                        className={element.highlight ? classes.highlight : ''}
                      >
                        <div className={classes.pathName}>{element.title}</div>
                      </li>
                      {element?.showInfo && (
                        <Tippy placement="bottom" arrow={true} content={<TooltipMessage channel={props?.channelType} />}>
                          <span className=" -ml-2 cursor-pointer text-primary">
                            <Icons.InfoIcon />
                          </span>
                        </Tippy>
                      )}
                    </>
                  );
                })}
              </ul>
            </div>
          </div>
        </div>
      </div>
      {props.children}
    </div>
  );
};
export default BreadCrumb;
