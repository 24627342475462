import { API } from '../constants/api';
import { get } from '../utils/apiHandler';

export const channelListService = async () => {
  try {
    const response = await get(API.channel);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const subbedChannelList = async () => {
  try {
    const response = await get(API.subbed_channel);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const channelCount = async () => {
  try {
    const response = await get(API.channel_count);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
