import { alertsActivityContants, alertIcons } from './constants';
import classes from '.././dashboard.module.scss';

export const processTimestamp = alertsData => {
  alertsData.forEach(alert => {
    const { timeStamp, activity, message, userTag } = alert;
    const options = {
      hour: 'numeric',
      minute: 'numeric',
      hour12: true,
      day: 'numeric',
      month: 'long',
      year: 'numeric',
    };
    const date = new Date(timeStamp);

    alert.dateValue = date.toLocaleString('en-US', options);
    switch (activity) {
      case alertsActivityContants.add:
        alert.icon = alertIcons.addICon;
        alert.iconClass = classes.restricted;
        if (userTag) {
          const processedMessage = processMessage(message, userTag);

          alert.firstSentence = processedMessage.firstPart;
          alert.secondSentence = processedMessage.secondPart;
        } else {
          alert.firstSentence = message;
        }
        break;
      case alertsActivityContants.externalUpdate: {
        const iconDetails = findIcon(message);
        alert.icon = iconDetails.icon;
        alert.iconClass = iconDetails.iconClass;
        if (userTag) {
          const processedMessage = processMessage(message, userTag);

          alert.firstSentence = processedMessage.firstPart;
          alert.secondSentence = processedMessage.secondPart;
        } else {
          alert.firstSentence = message;
        }
        break;
      }
      case alertsActivityContants.edit:
        alert.icon = alertIcons.editIcon;
        alert.iconClass = classes.info;
        if (userTag) {
          const processedMessage = processMessage(message, userTag);

          alert.firstSentence = processedMessage.firstPart;
          alert.secondSentence = processedMessage.secondPart;
        } else {
          alert.firstSentence = message;
        }
        break;
      case alertsActivityContants.remove:
        alert.icon = alertIcons.removeIcon;
        alert.iconClass = classes.caution;
        if (userTag) {
          const processedMessage = processMessage(message, userTag);

          alert.firstSentence = processedMessage.firstPart;
          alert.secondSentence = processedMessage.secondPart;
        } else {
          alert.firstSentence = message;
        }
        break;
      case alertsActivityContants.subscriptionSuccess:
        alert.icon = alertIcons.success;
        alert.iconClass = classes.info;
        break;
      default:
        break;
    }
  });
  return alertsData;
};

const findIcon = message => {
  if (message.includes('added')) return { icon: alertIcons.addICon, iconClass: classes.restricted };
  if (message.includes('removed')) return { icon: alertIcons.removeIcon, iconClass: classes.caution };
  return { icon: alertIcons.editIcon, iconClass: classes.info };
};

const processMessage = (message, userTag) => {
  const tagIndex = message.indexOf(userTag); // get the starting index of the userTag in the sentence
  const firstPart = message.substring(0, tagIndex); // get the substring before the userTag
  const secondPart = message.substring(tagIndex + userTag.length); // get the substring after the userTag

  return { firstPart, secondPart };
};
