/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import { useTable } from 'react-table';
import Pagination from '../Pagination';

const Table = ({
  data,
  metaData,
  columns,
  page,
  setPageSize,
  setPage,
  pageSize,
  showPagination,
  pageRange,
  setPageRange,
}) => {
  const { getTableBodyProps, headerGroups, rows, prepareRow } = useTable({
    columns,
    data,
  });
  return (
    <div className="tableStyle" style={{ width: '100%', height: '100%' }}>
      <table>
        <thead>
          {headerGroups.map((headerGroup, headIndex) => (
            <tr {...headerGroup.getHeaderGroupProps()} key={`Head${headIndex}`}>
              {headerGroup.headers.map((column, colIndex) => (
                <>
                  <th {...column.getHeaderProps()} key={`head-${colIndex}`}>
                    <button className="app-c-table__label cursor-default" type="button">
                      {column.render('Header').toUpperCase()}
                    </button>
                  </th>
                </>
              ))}
            </tr>
          ))}
        </thead>
        <tbody {...getTableBodyProps()}>
          {rows.map((row, rowIndex) => {
            prepareRow(row);
            return (
              <tr {...row.getRowProps()} key={`row-${rowIndex}`}>
                {row.cells.map((cell, cellIndex) => (
                  <td {...cell.getCellProps()} key={`cell${cellIndex}`} className="cursor-default">
                    {cell.render('Cell')}
                  </td>
                ))}
              </tr>
            );
          })}
        </tbody>
      </table>
      {showPagination && (
        <Pagination
          metaData={metaData}
          page={page}
          setPageSize={setPageSize}
          setPage={setPage}
          pageSize={pageSize}
          pageRange={pageRange}
          setPageRange={setPageRange}
        />
      )}
    </div>
  );
};

export default Table;
