/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Icons from '../../assets/Icons';
import { useNavigate } from 'react-router-dom';

const PrivacyPolicies = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="app-l-terms-holder">
        <section className="relative overflow-hidden bg-gradient-to-r from-primaryMedium to-primaryDark py-4 pt-6 ">
          <div className="relative z-10 mx-auto max-w-7xl px-5">
            <button
              className=" flex items-center text-sm font-medium text-white hover:opacity-50"
              onClick={() => navigate('/')}
            >
              <Icons.leftArrow />
              <span className=" ml-2.5">Back</span>
            </button>
            <h1 className=" m-0 p-0 text-2.1xl font-semibold leading-[2.75rem] text-white md:mt-1.5 md:text-base">
              Hydra Privacy Policy
            </h1>
          </div>
          <div className=" rotating absolute -bottom-27 -right-8.5 h-[180px] w-[180px] text-gray-medium1 md:-right-24 md:-bottom-28">
            <Icons.paymentShape1 />
          </div>
          <div className=" rotating absolute -top-27 -left-8.5 h-[180px] w-[180px] text-gray-medium1 md:-top-[133px] md:-left-17">
            <Icons.paymentShape1 />
          </div>
        </section>
        <section className="mx-auto max-w-7xl px-5 pt-5 pb-10">
          <div className="mb-4 w-full">
            <h2 className="mb-2 text-xl font-semibold leading-6 text-gray-dark">Hydra Privacy Policy</h2>
            <p className="text-sm font-bold text-gray-medium">Last Modified: April 16, 2023</p>
          </div>
          <div className="flex w-full flex-col items-start justify-start pt-6">
            <div className="flex flex-col space-y-3">
              <p>
                Hi Hydra Limited (<strong>"Company"</strong> or <strong>"We"</strong>) respect your privacy and are committed
                to protecting it through our compliance with this policy.
              </p>
              <p>
                This policy describes the types of information we may collect from you or that you may provide when you visit
                the website https://www.hihydra.com/ (our <strong>"Website"</strong>) and our practices for collecting,
                using, maintaining, protecting, and disclosing that information.
              </p>
            </div>
            <p className=" mt-10 font-medium">This policy applies to information we collect:</p>
            <ul className="my-3 list-disc space-y-2 pl-8">
              <li>On this Website.</li>
              <li>In email, text, and other electronic messages between you and us or this Website.</li>
              <li>
                When you register to use our Website or any tools/applications/software available on/through our Website.
              </li>
              <li>
                When you interact with our advertising and applications on third-party websites and services, if those
                applications or advertising include links to this policy, such as when you complete forms in Facebook or
                LinkedIn lead ads.
              </li>
            </ul>

            <p className=" mt-10 font-medium">It does not apply to information collected by:</p>
            <ul className="my-3 list-disc space-y-2 pl-8">
              <li>
                Us offline or through any other means, including on any other website operated by a Company or any third
                party (including our affiliates and subsidiaries); or
              </li>
              <li>
                Any third party (including our affiliates and subsidiaries), including through any application or content
                (including advertising) that may link to or be accessible from or on the Website.
              </li>
            </ul>

            <div className="my-10 flex flex-col space-y-3">
              <p>
                Please read this policy carefully to understand our policies and practices regarding your information and how
                we will treat it. If you do not agree with our policies and practices, your choice is not to use our Website.
                By accessing or using this Website, you agree to this privacy policy. This policy may change from time to
                time (see <strong>Changes to Our Privacy Policy</strong>). Your continued use of this Website after we make
                changes is deemed acceptance of those changes, so please check the policy periodically for updates.
              </p>
              <p>
                Notice to EU individuals: this Privacy Policy is intended to comply with Regulation (EU) 2016/679 of the
                European Parliament and of the Council of the European Union 27 April 2016 (the "
                <strong>General Data Protection Regulation</strong>" or "<strong>GDPR</strong>") and provide appropriate
                protection and care with respect to the treatment of your user information in accordance with the GDPR.
              </p>
              <p>
                Notice to California residents: this Privacy Policy is intended to comply with the California Consumer
                Privacy Act (<strong>"CCPA"</strong>) and other applicable California law. Please contact us as provided
                below.
              </p>
              <p>
                Notice to Nevada consumers: this Privacy Policy is intended to comply with Nevada Senate Bill 220. Please
                contact us as provided below.
              </p>
              <p>
                Notice to Nevada consumers: this Privacy Policy is intended to comply with Nevada Senate Bill 220. Please
                contact us as provided below.
              </p>
              <p>
                Hydra's use and transfer to any other app of information received from Google APIs will adhere to{' '}
                <a
                  href="https://developers.google.com/terms/api-services-user-data-policy#additional_requirements_for_specific_api_scopes"
                  target="blank"
                  className=" text-decoration-line: text-primary600 underline"
                >
                  Google API Services User Data Policy
                </a>{' '}
                , including the Limited Use requirements.
              </p>
            </div>
            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Not for Use by Children</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p>
                  Our Website is not intended for children under 18 years of age. No one under age 18 may provide any
                  information to or on the Website. We do not knowingly collect personal information from children under 18.
                  If you are under 18, do not use or provide any information on this Website or through any of its features,
                  register on the Website, make any purchases through the Website, use any of the interactive or public
                  comment features of this Website, or provide any information about yourself to us. If we learn we have
                  collected or received personal information from a child under 18 without verification of parental consent,
                  we will delete that information. If you believe that we might have any information from or about a child
                  under 18, please contact us at info@hihydra.com.
                </p>
                <p>
                  California residents under 16 years of age may have additional rights regarding the collection and sale of
                  their personal information. Please see <strong>Your California Privacy Rights</strong> for more
                  information.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Information We Collect About You</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p>
                  Personal data, or personal information, means any information about an individual from which such person
                  can be identified. It does not include data where the identity has been removed (anonymous data).
                </p>
                <p>
                  We may collect, use, store and transfer different kinds of personal data about you which we have grouped
                  together as follows ("<strong>personal information</strong>"):
                </p>
              </div>
            </div>
            <ul className="my-3 list-disc space-y-2 pl-8">
              <li>
                <strong> Identity Data</strong> includes first name, last name, username, Twitter user ID or similar
                identifier.
              </li>
              <li>
                <strong>Contact Data</strong> includes billing address, email address and telephone numbers.
              </li>
              <li>
                <strong>Financial Data</strong> We use third party online payment processing platform such as Stripe to
                process the payment. The third-party online payment processing platform may collect and store your payment
                card details but our Website doesn’t collect or store such information.
              </li>
              <li>
                <strong>Transaction Data</strong> We may access the transactional data through secured connection to the
                third-party online payment processing platform but we don’t collect or store transaction data.
              </li>
              <li>
                <strong>Technical Data</strong> a includes your login data, browser type and version, time zone setting and
                location, language settings, browser plug-in types and versions, operating system and platform, and other
                technology on the devices you use to access this website.
              </li>
              <li>
                <strong>Profile Data</strong> includes your username and password, purchases made by you, your feedback and
                survey responses.
              </li>
              <li>
                <strong>Usage Data</strong> a includes information about how you use our Website as well as products and
                services available on/through our Website.
              </li>
              <li>
                <strong>Marketing and Communications Data</strong> includes your preferences in receiving marketing from us
                and your communication preferences.
              </li>
            </ul>
            <div className="my-10 flex flex-col space-y-3">
              <p>
                We also collect, use and share <strong>Aggregated Data</strong> such as statistical or demographic data for
                any purpose. Aggregated Data could be derived from your personal data but is not considered personal data in
                law as this data will not directly or indirectly reveal your identity. For example, we may aggregate your
                Usage Data to calculate the percentage of users accessing a specific website feature. However, if we combine
                or connect Aggregated Data with your personal data so that it can directly or indirectly identify you, we
                treat the combined data as personal data which will be used in accordance with this privacy policy.
              </p>
              <p>
                We do not collect any<strong> Special Categories of Personal Data</strong> about you (this includes details
                about your race or ethnicity, religious or philosophical beliefs, sex life, sexual orientation, political
                opinions, trade union membership, information about your health, and genetic and biometric data). Nor do we
                collect any information about criminal convictions and offences.
              </p>
            </div>
            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">How We Collect Information About You</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p>We use different methods to collect data from and about you including through:</p>
              </div>
            </div>

            <ul className="my-3 list-disc space-y-2 pl-8">
              <li>
                <strong>Direct interactions</strong> You may give us your Identity, Contact and Financial Data by filling in
                forms or by corresponding with us by phone, email or otherwise. This includes personal data you provide when
                you:
                <ul className="mt-3 list-[circle] space-y-2 pl-10">
                  <li>
                    create an account on our website or on any tools/applications/software available on/through our Website;
                  </li>
                  <li>subscribe to our email contents; </li>
                  <li>request marketing to be sent to you;</li>
                  <li>enter a competition, promotion or survey; or</li>
                  <li>give us feedback or contact us.</li>
                </ul>
              </li>
              <li>
                <strong>Automated technologies or interactions</strong> As you interact with our website, we will
                automatically collect Technical Data about your equipment, browsing actions and patterns. We collect this
                personal data by using cookies, server logs and other similar technologies. We may also receive Technical
                Data about you if you visit other websites employing our cookies. Please see our cookie policy for further
                details.
              </li>
            </ul>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Information You Provide to Us</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p>The information we collect on or through our Website may include:</p>
              </div>
              <ul className="my-3 list-disc space-y-2 pl-8">
                <li>
                  Information that you provide by filling in forms on our Website. This includes information provided at the
                  time of registering to use our software application or features/tools/applications on our Website,
                  subscribing to our service, subscribing to our email contents, or requesting further services. We may also
                  ask you for information when you report a problem with our Website and/or software application.
                </li>
                <li>Records and copies of your correspondence (including email addresses), if you contact us.</li>
                <li>Your responses to surveys that we might ask you to complete for research purposes.</li>
                <li>
                  Details of transactions you carry out on our Website and of the fulfilment of your orders. You may be
                  required to provide financial information before making any payment on our Website.
                </li>
                <li>
                  Your search queries on the Website or on the tools/applications/software available on/through the Website.
                </li>
              </ul>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Information We Collect Automatically</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p>
                  As you navigate through and interact with our Website and software, we may use automatic data collection
                  technologies to collect certain information about your equipment, browsing actions, and patterns,
                  including:
                </p>
              </div>
              <ul className="my-3 list-disc space-y-2 pl-8">
                <li>
                  Details of your visits to our Website, including traffic data, location data, logs, and other communication
                  data and the resources that you access and use on the Website.
                </li>
                <li>
                  Information about your computer and internet connection, including your operating system, browser type and
                  language setting.
                </li>
              </ul>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  We also may use these technologies to collect information about your online activities over time and across
                  third-party websites or other online services (behavioural tracking).{' '}
                  <a
                    href="https://allaboutdnt.com/"
                    target="blank"
                    className=" text-decoration-line: text-primary600 underline"
                  >
                    Click here
                  </a>{' '}
                  for information on how you can opt out of behavioural tracking on this website and how we respond to web
                  browser signals and other mechanisms that enable consumers to exercise choice about behavioural tracking.
                </p>
                <p>
                  The information we collect automatically may include personal information, or we may maintain it or
                  associate it with personal information we collect in other ways or receive from third parties. It helps us
                  to improve our Website and software and to deliver a better and more personalized service, including by
                  enabling us to:
                </p>
              </div>
              <ul className="my-3 list-disc space-y-2 pl-8">
                <li>Estimate our audience size and usage patterns.</li>
                <li>
                  Store information about your preferences, allowing us to customize our Website and software according to
                  your individual interests.
                </li>
                <li>Speed up the features that you use.</li>
                <li>Recognize you when you return to our Website and login to our software.</li>
              </ul>
              <div className="my-10 flex flex-col space-y-3">
                <p>The technologies we use for this automatic data collection may include:</p>
              </div>

              <ul className="my-3 list-disc space-y-2 pl-8">
                <li>
                  <strong>Cookies (or browser cookies).</strong> A cookie is a small file placed on the hard drive of your
                  computer. You may refuse to accept browser cookies by activating the appropriate setting on your browser.
                  However, if you select this setting you may be unable to access certain parts of our Website and software.
                  Unless you have adjusted your browser setting so that it will refuse cookies, our system will issue cookies
                  when you direct your browser to our Website.
                </li>
                <li>
                  <strong>Flash Cookies.</strong>Certain features of our Website may use local stored objects (or Flash
                  cookies) to collect and store information about your preferences and navigation to, from, and on our
                  Website. Flash cookies are not managed by the same browser settings as are used for browser cookies. For
                  information about managing your privacy and security settings for Flash cookies, see Choices About How We
                  Use and Disclose Your Information.
                </li>
                <li>
                  <strong>Web Beacons.</strong>Pages of our Website and our e-mails may contain small electronic files known
                  as web beacons (also referred to as clear gifs, pixel tags, and single-pixel gifs) that permit the Company,
                  for example, to count users who have visited those pages or opened an email and for other related website
                  statistics (for example, recording the popularity of certain website content and verifying system and
                  server integrity).
                </li>
              </ul>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  We do not collect personal information automatically, but we may tie this information to personal
                  information about you that we collect from other sources or you provide to us.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">
                Third-Party Use of Cookies and Other Tracking Technologies
              </h4>

              <div className="my-10 flex flex-col space-y-3">
                <p>
                  Some content or applications on the Website are served by third parties, including advertisers, ad networks
                  and servers, content providers, and application providers. These third parties may use cookies in
                  conjunction with web beacons or other tracking technologies to collect information about you when you use
                  our website. The information they collect may be associated with your personal information or they may
                  collect information, including personal information, about your online activities over time and across
                  different websites and other online services. They may use this information to provide you with
                  interest-based (behavioural) advertising or other targeted content.
                </p>
                <p>
                  We do not control these third parties’ tracking technologies or how they may be used. If you have any
                  questions about an advertisement or other targeted content, you should contact the responsible provider
                  directly. For information about how you can opt out of receiving targeted advertising from many providers,
                  see<strong> Choices About How We Use and Disclose Your Information.</strong>
                </p>
              </div>
            </div>
            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Third Party Links</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p>
                  This website will include links to third-party websites, plug-ins and applications. Clicking on those links
                  or enabling those connections may allow third parties to collect or share data about you. We do not control
                  these third-party websites, plug-ins and applications and are not responsible for their privacy statements.
                  When you leave our website or use third party plug-ins and applications on our Website, we encourage you to
                  read the privacy policy first.
                </p>
              </div>
            </div>
            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">How We Use Your Information</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p>
                  We use information that we collect about you or that you provide to us, including any personal information,
                  in the following circumstances:
                </p>
              </div>
              <ul className="my-3 list-disc space-y-2 pl-8">
                <li>To present our Website and software and their contents to you.</li>
                <li>To provide you with information, products, or services that you request from us.</li>
                <li>To fulfil any other purpose for which you provide it.</li>
                <li>
                  To provide you with notices about your account and subscription, including expiration and renewal notices.
                </li>
                <li>
                  To carry out our obligations and enforce our rights arising from any contracts entered into between you and
                  us, including for billing and collection.
                </li>
                <li>
                  To notify you about changes to our Website or any products or services we offer or provide through it.
                </li>
                <li>To allow you to participate in interactive features on our Website.</li>
                <li>In any other way we may describe when you provide the information.</li>
                <li>
                  Where we need to perform the contract we are about to enter into or have entered into with you. Here,{' '}
                  <strong>"performance of contract"</strong> means processing your data where it is necessary for the
                  performance of a contract to which you are a party or to take steps at your request before entering into
                  such a contract.
                </li>
                <li>
                  Where it is necessary for our legitimate interests (or those of a third party) and your interests and
                  fundamental rights do not override those interests. Here, <strong>"legitimate interest"</strong> means the
                  interest of our business in conducting and managing our business to enable us to give you the best
                  service/product and the best and most secure experience. We make sure we consider and balance any potential
                  impact on you (both positive and negative) and your rights before we process your personal data for our
                  legitimate interests. We do not use your personal data for activities where our interests are overridden by
                  the impact on you (unless we have your consent or are otherwise required or permitted to by law). You can
                  obtain further information about how we assess our legitimate interests against any potential impact on you
                  in respect of specific activities by contacting us.
                </li>
                <li>
                  Where we need to comply with a legal obligation. Here, <strong>"comply with a legal obligation"</strong>{' '}
                  means processing your personal data where it is necessary for compliance with a legal obligation that we
                  are subject to.
                </li>
                <li>For any other purpose with your consent.</li>
              </ul>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  We may also use your information to contact you about our own products and services that may be of interest
                  to you. If you do not want us to use your information in this way, please check the relevant box located on
                  the form on which we collect your data (the registration form) or adjust your user preferences in your
                  account or email us at info@hihydra.com. For more information, see{' '}
                  <strong>Choices About How We Use and Disclose Your Information.</strong>
                </p>
                <p>
                  We may use the information we have collected from you to enable us to display advertisements to our
                  advertisers’ target audiences. Even though we do not disclose your personal information for these purposes
                  without your consent, if you click on or otherwise interact with an advertisement, the advertiser may
                  assume that you meet its target criteria.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Disclosure of Your Information</h4>

              <div className="my-10 flex flex-col space-y-3">
                <p>
                  We may disclose aggregated information about our users, and information that does not identify any
                  individual, without restriction.
                </p>
                <p>
                  We will not disclose personal information that we collect or you provide as described in this privacy
                  policy:
                </p>
              </div>
              <ul className="my-3 list-disc space-y-2 pl-8">
                <li>To our subsidiaries and affiliates.</li>
                <li>
                  To contractors, service providers, and other third parties we use to support our business and who are bound
                  by contractual obligations to keep personal information confidential and use it only for the purposes for
                  which we disclose it to them.
                </li>
              </ul>
              <div className="my-10 flex flex-col space-y-3">
                <p>We may disclose your personal information:</p>
              </div>
              <ul className="my-3 list-disc space-y-2 pl-8">
                <li>
                  To a buyer or other successor in the event of a merger, divestiture, restructuring, reorganization,
                  dissolution, or other sale or transfer of some or all of our assets, whether as a going concern or as part
                  of bankruptcy, liquidation, or similar proceeding, in which personal information held by us about our
                  Website and software users is among the assets transferred.
                </li>
                <li>
                  To fulfil the purpose for which you provide it. For example, if you give us an email address to subscribe
                  to our newsletter.
                </li>
                <li>For any other purpose disclosed by us when you provide the information.</li>
                <li>With your consent.</li>
              </ul>
              <div className="my-10 flex flex-col space-y-3">
                <p>We may also disclose your personal information:</p>
              </div>

              <ul className="my-3 list-disc space-y-2 pl-8">
                <li>
                  To comply with any court order, law, or legal process, including to respond to any government or regulatory
                  request.
                </li>
                <li>
                  To enforce or apply our Terms and Conditions and other agreements, including for billing and collection
                  purposes.
                </li>
                <li>
                  If we believe disclosure is necessary or appropriate to protect the rights, property, or safety of the
                  Company, our customers, or others. This includes exchanging information with other companies and
                  organizations for the purposes of fraud protection and credit risk reduction.
                </li>
              </ul>
            </div>
            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Choices About How We Use and Disclose Your Information</h4>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  We strive to provide you with choices regarding the personal information you provide to us. We have created
                  mechanisms to provide you with the following control over your information:
                </p>
              </div>
              <ul className="my-3 list-disc space-y-2 pl-8">
                <li>
                  <strong>Tracking Technologies and Advertising.</strong> You can set your browser to refuse all or some
                  browser cookies, or to alert you when cookies are being sent. To learn how you can manage your Flash cookie
                  settings, visit the Flash player settings page on Adobe’s website. If you disable or refuse cookies, please
                  note that some parts of this site may then be inaccessible or not function properly.
                </li>
                <li>
                  <strong>Disclosure of Your Information for Third-Party Advertising.</strong> If you do not want us to share
                  your personal information with unaffiliated or non-agent third parties for promotional purposes, you can
                  opt-out by checking the relevant box located on the form on which we collect your data (the registration
                  form). You can also always opt-out by sending us an email with your request to info@hihydra.com.
                </li>
                <li>
                  <strong>Promotional Offers from the Company.</strong> If you do not wish to have your email address or
                  contact information used by us to promote our own products or services, you can opt-out by checking the
                  relevant box located on the form on which we collect your data (the registration form) or at any other time
                  by sending us an email stating your request to info@hihydra.com. If we have sent you a promotional email,
                  you may send us a return email asking to be omitted from future email distributions. This opt out does not
                  apply to information provided to the Company as a result of a product purchase, product service experience
                  or other transactions.
                </li>
              </ul>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  We do not control third parties’ collection or use of your information to serve interest-based advertising.
                  However, these third parties may provide you with ways to choose not to have your information collected or
                  used in this way. You can opt out of receiving targeted ads from members of the Network Advertising
                  Initiative <strong>("NAI") </strong>on the NAI’s website.
                </p>
                <p>
                  California residents may have additional personal information rights and choices. Please see
                  <strong>Your California Privacy Rights</strong> for more information.
                </p>
                <p>
                  Nevada residents who wish to exercise their rights pursuant to Nevada Senate Bill 220, please submit a
                  request to info@hihydra.com.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Accessing and Correcting Your Information</h4>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  You can review and change your personal information by logging into the Website and visiting your account
                  profile page.
                </p>
                <p>
                  You may also send us an email at info@hihydra.com to request access to, correct or delete any personal
                  information that you have provided to us. Sometimes we cannot delete your personal information except by
                  also deleting your user account. We may not accommodate a request to change information if we believe the
                  change would violate any law or legal requirement or cause the information to be incorrect.
                </p>
                <p>
                  California residents may have additional personal information rights and choices. Please see
                  <strong>Your California Privacy Rights</strong> for more information.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Your California Privacy Rights</h4>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  If you are a California resident, California law may provide you with additional rights regarding our use
                  of your personal information. We prepare this section to comply with the CCPA in particular and any terms
                  defined in the CCPA have the same meaning when used in this section.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Notice of Collection</h4>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  Although the information we collect is described in detail in Information We Collect About You above, the
                  categories of personal information that we have collected in the past 12 months – as described by the CCPA
                  – are as follows:
                </p>
              </div>
              <ul className="my-3 list-disc space-y-2 pl-8">
                <li>
                  <strong>Identifiers</strong> including first name, last name, alias, online identifier, account name, email
                  address, Twitter username and Twitter ID.
                </li>
                <li>
                  <strong>
                    Personal information categories listed in the California Customer Records statute Cal. Civ. Code §
                    1798.80(e),
                  </strong>
                  including name, phone number, and email address.
                </li>
                <li>
                  <strong>Demographics</strong> such as your age or gender. This category includes data that may qualify as
                  protected classifications under other California or federal laws.
                </li>
                <li>
                  <strong>Commercial information</strong> including purchases and engagement with the products and services
                  available on the Website.
                </li>
                <li>
                  <strong>Internet activity,</strong> including your interactions with our Website, browsing history and
                  search history.
                </li>
                <li>
                  <strong>Professional or employment-related information or non-public education information,</strong> such
                  as job title information.
                </li>
              </ul>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  For more detail on the information we collect and when, including the sources we receive information from,
                  please review<strong> How We Collect Information About You</strong> above.
                </p>
                <p>
                  We do not generally sell information as the term “sell” is traditionally understood. However, to the extent
                  “sale” under the CCPA is interpreted to include advertising technology activities as a “sale” and to
                  include processing and sharing publicly available personal information from social media platforms and
                  other websites as a “sale,” we will comply with applicable law as to such activity. And such “sale”
                  activity we engage is restricted to sharing the abovementioned categories of information with our
                  registered users who run searches with our products.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Right to Know and Delete</h4>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  California residents have the right to delete the personal information we have collected from you and the
                  right to know certain information about our data practices in the preceding 12 months. In particular, you
                  have the right to request the following from us:
                </p>
              </div>
              <ul className="my-3 list-disc space-y-2 pl-8">
                <li>The categories of personal information we have collected about you;</li>
                <li>The categories of sources from which the personal information was collected;</li>
                <li>The categories of personal information about you we disclosed for a business purpose or sold;</li>
                <li>The business or commercial purpose for collecting or selling the personal information; and</li>
                <li>The specific pieces of personal information we have collected about you.</li>
              </ul>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  To exercise any of these rights, please submit a request to info@hihydra.com. In the request, please
                  specify which right you are seeking to exercise and the scope of the request. Please also provide
                  sufficient information that allows us to verify that you are the person about whom we collected personal
                  information or an authorized representative in the request. We will only use personal information you
                  provide in a request to verify your identity or authority to make the request.
                </p>
                <p>
                  We may require further information from you to help us verify your identity and process your request. If we
                  are unable to verify your identity or your authority to make the request, we may deny your requests to know
                  or delete. We may also deny your deletion request if retaining the information is necessary for us or our
                  service provider(s) to:
                </p>
              </div>
              <ul className="my-3 list-disc space-y-2 pl-8">
                <li>
                  Complete the transaction for which we collected the personal information, provide a good or service that
                  you requested, take actions reasonably anticipated within the context of our ongoing business relationship
                  with you, fulfill the terms of a written warranty or product recall conducted in accordance with federal
                  law, or otherwise perform our contract with you
                </li>
                <li>
                  Detect security incidents, protect against malicious, deceptive, fraudulent, or illegal activity, or
                  prosecute those responsible for such activities.
                </li>
                <li>Debug products to identify and repair errors that impair existing intended functionality.</li>
                <li>
                  Exercise free speech, ensure the right of another consumer to exercise their free speech rights, or
                  exercise another right provided for by law.
                </li>
                <li>Comply with the California Electronic Communications Privacy Act (Cal. Penal Code § 1546 et. seq.).</li>
                <li>
                  Engage in public or peer-reviewed scientific, historical, or statistical research in the public interest
                  that adheres to all other applicable ethics and privacy laws, when the information’s deletion may likely
                  render impossible or seriously impair the research’s achievement, if you previously provided informed
                  consent.
                </li>
                <li>
                  Enable solely internal uses that are reasonably aligned with consumer expectations based on your
                  relationship with us.
                </li>
                <li>Comply with a legal obligation.</li>
                <li>
                  Make other internal and lawful uses of that information that are compatible with the context in which you
                  provided it.
                </li>
              </ul>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Response Time and Format</h4>
              <div className="my-10 flex flex-col space-y-3">
                <p>We will confirm receipt of your request to know or delete within 14 days.</p>
                <p>
                  We endeavour to respond to a request within forty-five (45) days after we can reasonably verify your
                  identity and authority to make the request. If we require more time (up to 45 days), we will inform you of
                  the reason and extension period in writing. We will deliver our written response to the email address from
                  which you send us the request or the email address on your account (if you have registered an account with
                  us).
                </p>
                <p>
                  Any disclosures we provide will only cover the 12-month period preceding our receipt of your request. The
                  response we provide will also explain the reasons we cannot comply with a request, if applicable. For any
                  request to know, we will select a format to provide your personal information that is readily useable and
                  should allow you to transmit the information.
                </p>
                <p>
                  We do not charge a fee to process or respond to your request unless it is excessive, repetitive, or
                  manifestly unfounded. If we determine that the request warrants a fee, we will tell you why we made that
                  decision and provide you with a cost estimate before completing your request.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Right to Opt-Out and Opt-In</h4>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  To the extent we sell your personal information as the term "sell" is defined under the CCPA, you have the
                  right to opt-out of the sale of your personal information by us to third parties at any time. You may
                  submit a request to opt-out by emailing info@hihydra.com.
                </p>
                <p>If you change your mind, you may opt back in any time by emailing info@hihydra.com.</p>
                <p>
                  Once you make an opt-out request, we will wait at least twelve (12) months before asking you to
                  re-authorize any personal information sales.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Authorized Agent</h4>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  You can designate an authorized agent to submit requests on your behalf. You may also act as an agent and
                  make a verifiable request on behalf of your minor child. However, we will require written proof of the
                  agent’s permission to do so and will verify your identity (or your minor child’s identity) directly.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Right to Non-Discrimination</h4>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  You have the right not to receive discriminatory treatment by us for the exercise of any your rights under
                  CCPA.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Children</h4>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  The website is not intent for minors under the age of 16. And we do not knowingly collect or sell personal
                  information of minors under 16 years old, however, to the extent we knowingly “sell,” as that term is
                  defined under the CCPA, the personal information of minors under 16 years old who are California residents,
                  we either obtain prior affirmative authorization from such minor who is at least 13 and opt-in to the sale
                  of the minor’s personal information or obtain affirmative authorization from the minor’s parent or
                  guardian. A minor, or parent/guardian, who has opted in to the sale of the minor’s personal information has
                  the right to opt-out at any time. To exercise your right to opt-out, see
                  <strong> Right to Opt-Out and Opt-In</strong> for more information.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Shine the Light</h4>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  California’s “Shine the Light” law (Civil Code Section§1798.83) permits users of our Website that are
                  California residents to request certain information regarding our disclosure of personal information to
                  third parties for their direct marketing purposes.
                </p>
                <p>
                  We may share personal information as defined by "Shine the Light" with third parties and/or our affiliates
                  for their direct marketing purposes. If this law applies to you and you wish to obtain further information
                  about our sharing, please send an email to info@hihydra.com.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Data Security</h4>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  We have implemented measures designed to secure your personal information from accidental loss and from
                  unauthorized access, use, alteration, and disclosure. All information you provide to us is stored on our
                  secure servers behind firewalls. Any payment transactions will be encrypted.
                </p>
                <p>
                  The safety and security of your information also depends on you. Where we have given you (or where you have
                  chosen) a password for access to certain parts of our Website, you are responsible for keeping this
                  password confidential. We ask you not to share your password with anyone. We urge you to be careful about
                  giving out information in public areas of the Website like message boards. The information you share in
                  public areas may be viewed by any user of the Website.
                </p>
                <p>
                  Unfortunately, the transmission of information via the internet is not completely secure. Although we do
                  our best to protect your personal information, we cannot guarantee the security of your personal
                  information transmitted to our Website. Any transmission of personal information is at your own risk. We
                  are not responsible for circumvention of any privacy settings or security measures contained on the
                  Website.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Changes to Our Privacy Policy</h4>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  It is our policy to post any changes we make to our privacy policy on this page. If we make material
                  changes to how we treat our users’ personal information, we will notify you by email to the primary email
                  address specified in your account or through a notice on the Website home page. The date the privacy policy
                  was last revised is identified at the top of the page. You are responsible for ensuring we have an
                  up-to-date active and deliverable email address for you, and for periodically visiting our Website and this
                  privacy policy to check for any changes.
                </p>
              </div>
            </div>

            <div className="my-10 flex flex-col space-y-3">
              <h4 className=" text-2xl font-bold text-primary600">Controller & Contact Information</h4>
              <div className="my-10 flex flex-col space-y-3">
                <p>
                  The Company is the controller and responsible for your personal data. We have appointed a data protection
                  officer (the <strong>"DPO"</strong>) who is responsible for overseeing questions in relation to this
                  privacy policy. If you have any questions about this privacy policy or our privacy practices, including any
                  requests to exercise your legal rights, please contact the DPO using the details set out below.
                </p>
                <p>Full name of legal entity: Hi Hydra Limited</p>
                <p>Email address: info@hihydra.com</p>
                <p>Postal address: Studio 112, The Print Rooms, 164-180 Union Street, London, SE1 0LH.</p>
                <p>
                  You have the right to make a complaint at any time to the supervisory authority for data protection issues
                  in your jurisdiction. We would, however, appreciate the chance to deal with your concerns before you
                  approach the supervisory authority so please contact us in the first instance.
                </p>
              </div>
            </div>
          </div>
        </section>
      </section>
    </>
  );
};

export default PrivacyPolicies;
