/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Icons from '../../../assets/Icons';

export const ListingModal = props => {
  return (
    <>
      <Transition appear show={props.isOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50" onClose={() => {}}>
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-black bg-opacity-60" />
          </Transition.Child>

          <div className="fixed inset-0 overflow-y-auto">
            <div className="flex min-h-full items-center justify-center p-4 text-center ">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 scale-95"
                enterTo="opacity-100 scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 scale-100"
                leaveTo="opacity-0 scale-95"
              >
                <Dialog.Panel className="w-full max-w-[450px] transform rounded-lg  transition-all">
                  <>
                    <Dialog.Title
                      as="div"
                      className="login-modal-header relative flex min-h-[6.5rem]  items-center justify-center overflow-hidden rounded-t-lg bg-primary300 p-4 text-black shadow-none md:min-h-[7.938rem]"
                    >
                      <div className="z-10 text-white">
                        <h2 className="mb-2 text-2xl font-bold leading-9 md:mb-3">{props?.modalData?.header}</h2>
                      </div>
                      <button className="absolute top-4 right-4 z-10 text-white" onClick={() => props.setIsOpen(false)}>
                        <Icons.CloseIcon />
                      </button>
                    </Dialog.Title>
                    <div className="rounded-b-lg  bg-white p-8 md:px-4.5 md:pt-6 md:pb-10">
                      {/* {props?.modalData?.users.map((value, index) => (
                        <div
                          className="user-details-grid grid max-h-[200px] w-full grid-cols-1 gap-2 overflow-auto"
                          key={index}
                        >
                          <p>{value}</p>
                        </div>
                      ))} */}
                      <div className="user-details-grid grid max-h-[200px] w-full grid-cols-1 gap-2 overflow-auto">
                        <ul className=" list-disc pl-4">
                          {props?.modalData?.users?.map((data, index) => {
                            return (
                              <label
                                key={index}
                                htmlFor={`${data?.label}${data}${index}`}
                                className="inline-flex h-10.5 w-full cursor-pointer items-center justify-between rounded-lg border border-white200 bg-white px-4 text-gray-500   "
                              >
                                <div className="block">
                                  <div className="w-full  text-xs text-gray-dark">
                                    {index + 1}. {props?.modalData?.header == 'Recipients' ? data?.label : data}
                                  </div>
                                </div>
                              </label>
                            );
                          })}
                        </ul>
                      </div>

                      <div className="footer-buttons flex flex items-center justify-center pt-8">
                        <button className="w-29 !min-w-0 bg-primary text-white" onClick={() => props.setIsOpen(false)}>
                          {'Close'}
                        </button>
                      </div>
                    </div>
                  </>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition>
    </>
  );
};
