import React, { useState, useEffect, Fragment } from 'react';
import AvatarInput from '../../../components/AvatarInput';
import { profileEdit } from '../../../services';
import useAuth from '../../../customHooks/useAuth';
import { useToastBox } from '../../../customHooks/useToastBox';
import { saveProfileDetails } from './helper';
import { OnlyAlphabetsAndWhiteSpaces, OnlyDigitsValidation } from '../../../utils/validations';
import { profileConstants } from '../constants';
import Loader from '../../../components/Loader';
import PhoneInputField from '../../../components/PhoneInputField';
import { signupConstants } from '../../../components/Modal/SignUpModal/constants';
import { Dialog, Transition } from '@headlessui/react';
import { OtpVerificationModal } from '../../../components/Modal/OtpVerificationModal';
import { mobileOtp } from '../../../services/Profile/mobileOtpService';
import { verifyMobileOtp } from '../../../services/Profile/verifyMobileOtpService';
import Tippy from '@tippyjs/react';

const ProfileEdit = ({ setEditProfile, editProfile, profileInfo, nameInitials }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [phoneNumber, setPhoneNumber] = useState(`${profileInfo.countryCode} ${profileInfo.phone}`);
  const [isVerified, setIsVerified] = useState(profileInfo.isMobileVerified);
  const [profileDetails, setProfileDetails] = useState({
    firstName: profileInfo.firstName,
    lastName: profileInfo.lastName,
    mobile: profileInfo.phone,
    countryCode: profileInfo.countryCode,
    isMobileVerified: profileInfo.isMobileVerified,
    ...(profileInfo.profileImage && { profileImage: profileInfo.profileImage }),
  });
  const [formErrors, setFormError] = useState({
    firstName: '',
    lastName: '',
    mobile: '',
  });
  const [countryCode, setCountryCode] = useState('');
  const [profileImageUrl, setprofileImageUrl] = useState('');
  const [avatarChange, setAvatarChange] = useState('');
  const { handleSuccessToast, handleErrorToast } = useToastBox();
  const { setUserData } = useAuth();

  useEffect(() => {
    if (avatarChange) {
      if (profileImageUrl.length === 0) {
        // eslint-disable-next-line
        const { profileImage, ...newProfileDetails } = profileDetails;
        setProfileDetails({ ...newProfileDetails, removeImage: true });
      } else {
        if (profileDetails.removeImage) {
          // remove removeImage key from profileDetails
          removeKey();
        } else {
          setProfileDetails({ ...profileDetails, profileImage: profileImageUrl });
        }
      }
      setAvatarChange(false);
    }
  }, [avatarChange]);

  const removeKey = () => {
    setProfileDetails(current => {
      // remove removeImage key from object
      // eslint-disable-next-line
      const { removeImage, ...rest } = current;
      return rest;
    });
  };

  const validatePhoneNumber = phoneNumber => {
    const minLenPhone = 10;
    const maxLenPhone = 15;
    let error = '';
    if (!phoneNumber.trim() || phoneNumber === undefined) {
      error = profileConstants.validationErrorMessages?.phone?.required;
    } else if (!OnlyDigitsValidation(phoneNumber)) {
      error = profileConstants.validationErrorMessages.phone.validateError;
    } else if (phoneNumber?.length > maxLenPhone) {
      error = profileConstants.validationErrorMessages?.phone?.maxLimit;
    } else if (phoneNumber?.length < minLenPhone) {
      error = profileConstants.validationErrorMessages?.phone?.minLimit;
    }
    return error;
  };

  const validateUserName = (userName, type) => {
    let error = '';
    if (!userName) {
      error = profileConstants.validationErrorMessages[type].required;
    } else if (!OnlyAlphabetsAndWhiteSpaces(userName)) {
      error = profileConstants.validationErrorMessages[type]?.onlyAlphabets;
    }
    return error;
  };

  const handleInputChange = event => {
    const { name, value } = event.target;
    setFormError({ ...formErrors, [name]: validateUserName(value, name) });
    setProfileDetails({ ...profileDetails, [name]: value });
  };

  const onPhoneNumberChange = value => {
    const phoneNumber = profileInfo.countryCode + profileInfo.phone;
    if ((profileInfo.isMobileVerified || profileDetails.isMobileVerified) && phoneNumber !== `+${value}`) {
      setIsVerified(false);
    } else if (profileInfo.isMobileVerified) setIsVerified(true);
    setPhoneNumber(value);
    setFormError({ ...formErrors, mobile: validatePhoneNumber(value) });
  };

  const handlePhoneChange = number => {
    setProfileDetails({ ...profileDetails, mobile: number });
  };

  const handleCountryCodeChange = code => {
    setProfileDetails({ ...profileDetails, countryCode: code });
    setCountryCode(code);
  };

  const handleSave = () => {
    if (!formErrors.firstName && !formErrors.lastName && !formErrors.mobile && isVerified) {
      profileDetails.firstName = profileDetails.firstName.trim();
      profileDetails.lastName = profileDetails.lastName.trim();
      profileDetails.countryCode = `${countryCode ? `+${countryCode}` : profileDetails.countryCode}`;
      profileEdit(profileInfo.id, profileDetails)
        .then(response => {
          saveProfileDetails(response?.data?.data, setUserData);
          setIsLoading(false);
          handleSuccessToast(profileConstants.sucessMessage);
          setEditProfile(!editProfile);
        })
        .catch(error => {
          setIsLoading(false);
          handleErrorToast(error.message);
          console.error(error);
        });
    }
  };

  const onVerifyClick = () => {
    const data = {};
    data['mobile'] = profileDetails.mobile.toString();
    data['countryCode'] = countryCode || profileDetails.countryCode;
    data['email'] = profileInfo.email;
    mobileOtp(data)
      .then(() => {
        setOtpModal(true);
      })
      .catch(() => {
        handleErrorToast(profileConstants.verifyPhoneModal.sendOtpFail);
      });
  };

  const handleVerifySubmit = otp => {
    const data = {};
    data['otp'] = otp;
    data['email'] = profileInfo.email;
    verifyMobileOtp(data)
      .then(() => {
        setProfileDetails({ ...profileDetails, isMobileVerified: true });
        setIsVerified(true);
        setOtpModal(false);
        handleSuccessToast(profileConstants.verifyPhoneModal.otpVerificationSuccess);
      })
      .catch(() => {
        handleErrorToast(profileConstants.verifyPhoneModal.otpVerificationFail);
        setOtpModal(false);
      });
  };

  return isLoading ? (
    <Loader />
  ) : (
    <>
      <div className="flex w-full flex-col overflow-hidden bg-gray-light1 md:-mx-5 md:mb-4 md:w-[calc(100%+40px)]  md:overflow-visible">
        <div className="flexCenter justify-between border-b border-white200 bg-white p-4 sm:flex-col sm:items-start sm:justify-start ">
          <div className="flexCenter">
            <span className=" text-base font-semibold leading-6 text-gray-dark">{profileConstants.profileHeading}</span>
          </div>
          <div className="md:hidden">
            <Tippy
              placement="bottom"
              arrow={true}
              disabled={isVerified}
              content={!isVerified ? 'Please verify your phone number to save changes.' : ''}
            >
              <button
                className={`h-8 rounded-full ${
                  !isVerified ? 'bg-gray-light10' : 'bg-primary'
                } px-3.5 text-xs font-semibold leading-4.5 text-white xs:w-full xs:justify-center`}
                onClick={handleSave}
                data-testid="save-button"
              >
                {profileConstants.save}
              </button>
            </Tippy>
          </div>
        </div>
        <div className=" p-4 md:pt-8">
          <div className=" flex items-center rounded-lg border border-white200 bg-white p-8 md:flex-col md:justify-center md:border-none md:bg-transparent md:p-0">
            <div className="flex w-[130px] flex-col justify-center">
              <AvatarInput
                setprofileImageUrl={setprofileImageUrl}
                setAvatarChange={setAvatarChange}
                profileInfo={profileInfo}
                nameInitials={nameInitials}
              />
            </div>
            <div className="grid w-full grid-cols-2 gap-6 px-10 pr-[250px] md:grid-cols-1 md:px-0 md:pt-10">
              <div className=" flex flex-col">
                <label className=" text-xs leading-4.5 text-gray-dark">{profileConstants.firstName}</label>
                <input
                  className="mt-1.5 block w-full rounded-md border border-gray-medium bg-white py-2 px-3 text-xs font-medium leading-4.5 text-gray-dark shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
                  type="text"
                  name="firstName"
                  id="firstName"
                  required={true}
                  value={profileDetails.firstName}
                  onChange={handleInputChange}
                />
                <div className=" text-sm text-red-600">{formErrors.firstName}</div>
              </div>
              <div className=" flex flex-col">
                <label className=" text-xs leading-4.5 text-gray-dark">{profileConstants.lastName}</label>
                <input
                  className="mt-1.5 block w-full rounded-md border border-gray-medium bg-white py-2 px-3 text-xs font-medium leading-4.5 text-gray-dark shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
                  type="text"
                  name="lastName"
                  id="lastName"
                  required={true}
                  value={profileDetails.lastName}
                  onChange={handleInputChange}
                />
                <div className=" text-sm text-red-600">{formErrors.lastName}</div>
              </div>
              <PhoneInputField
                className="flex flex-col"
                id="phone"
                placeholder="Phone Number"
                type="tel"
                label="Phone Number"
                hintClassName="font-normal text-[0.625rem] text-gray-400 ml-2"
                name="phone"
                countryCodes={signupConstants.countryCodes}
                onPhoneChange={handlePhoneChange}
                error={formErrors.mobile}
                onCountryChange={handleCountryCodeChange}
                autoComplete="new-password"
                onPhoneInput={onPhoneNumberChange}
                phoneNumber={phoneNumber}
                onVerifyClick={onVerifyClick}
                showVerified={true}
                isVerifyDisabled={formErrors.mobile}
                isVerified={profileDetails.isMobileVerified && isVerified}
              />
              <div className=" flex flex-col ">
                <label className=" text-xs  leading-4.5 text-gray-dark">{profileConstants.email}</label>
                <input
                  className="mt-1.5  block w-full truncate rounded-md border border-gray-medium bg-white py-2 px-3 text-xs font-medium leading-4.5 text-gray-dark opacity-50 shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
                  type="text"
                  name="email"
                  id="email"
                  value={profileInfo.email}
                  disabled={true}
                />
                {/* <span className="mt-2 text-xs leading-[0.9375rem] text-gray-medium">{profileConstants.emailEditInfo}</span> */}
              </div>
              <div className=" flex flex-col ">
                <label className=" text-xs leading-4.5 text-gray-dark">{profileConstants.alternateEmail}</label>
                <input
                  className="mt-1.5 block w-full rounded-md border border-gray-medium bg-white py-2 px-3 text-xs font-medium leading-4.5 text-gray-dark opacity-50 shadow-sm focus:border-primary focus:outline-none focus:ring-1 focus:ring-primary sm:text-sm"
                  type="text"
                  name="email"
                  id="email"
                  value={profileConstants.nil}
                  disabled={true}
                />
                <span className="mt-2 text-xs leading-[0.9375rem] text-gray-medium">
                  {/* {profileConstants.alternateEmailInfo} */}
                </span>
              </div>
              <div className="hidden md:flex">
                <button
                  className=" h-11 rounded-full bg-primary text-sm font-medium leading-[1.3125rem] text-white md:w-full xs:justify-center"
                  data-testid="save-button1"
                  onClick={handleSave}
                >
                  {profileConstants.save}
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
      {otpModal && (
        <Transition appear show={otpModal} as={Fragment}>
          <Dialog as="div" className="relative z-10" open={otpModal} onClose={() => setOtpModal(false)}>
            <div className="fixed inset-0 overflow-y-auto bg-black bg-opacity-60">
              <div className="flex min-h-full items-center justify-center p-4 text-center ">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-[28.375rem] transform overflow-hidden rounded-lg  transition-all">
                    <OtpVerificationModal
                      verifyPhone={true}
                      setIsOpen={setOtpModal}
                      constants={profileConstants.verifyPhoneModal}
                      handleSubmit={handleVerifySubmit}
                      handleReset={() =>
                        setFormError(values => ({
                          ...values,
                          loginError: '',
                        }))
                      }
                      verifyOnlyPhone={true}
                      user={{
                        mobile: profileDetails.mobile.toString(),
                        countryCode: countryCode || profileDetails.countryCode,
                        email: profileInfo.email,
                      }}
                      error={''}
                    />
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
    </>
  );
};
export default ProfileEdit;
