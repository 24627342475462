export const viewersList = [
  {
    viewers: 'Bessie Cooper',
  },
  {
    viewers: 'Esther Howard',
  },
  {
    viewers: 'Cameron Williamson',
  },
  {
    viewers: 'Bessie Cooper',
  },
  {
    viewers: 'Esther Howard',
  },
  {
    viewers: 'Cameron Williamson',
  },
];
export const messageConstants = {
  message: 'Admin user mail IDs cannot be selected',
  alreadySelectedMessage: 'Already selected this viewer',
  alertInfoMessage: 'The maximum number of licenses has been reached',
  alertInfoSubMessage: 'Please contact your license owner',
  alertOwnerInfoSubMessage: 'Please contact your administrator',
  // alertInfoMessage: 'License Limit Exceeded',
  // alertInfoSubMessage: `You've reached the license limit. Please reach out to the license owner to purchase additional licenses.`,
};

export const userTypes = {
  licenceOwner: 'Licence Owner',
  adminUser: 'Admin User',
  viewer: 'Viewer',
  user: 'user',
  clientAdmin: 'clientAdmin',
};

export const validationErrorMessages = {
  emptyString: '',
  addUserError: 'addUserError',
  email: {
    required: 'Please enter a  email address',
    validateError: 'Please enter a valid email address',
    title: 'email',
  },
};
