import { subbedChannelIconsConstants } from './constants';

const getImageUrls = data => {
  data?.forEach(element => {
    switch (element.channelType) {
      case subbedChannelIconsConstants.channelType.googleAdsTitle:
        element.imgUrl = subbedChannelIconsConstants.icons.googleAds;
        element.title = subbedChannelIconsConstants.title.googleAdsTitle;
        element.provider = subbedChannelIconsConstants.provider.googleAdsProvider;
        break;
      case subbedChannelIconsConstants.channelType.googleAnalyticsTitle:
        element.imgUrl = subbedChannelIconsConstants.icons.googleAnalytics;
        element.title = subbedChannelIconsConstants.title.googleAnalyticsTitle;
        element.provider = subbedChannelIconsConstants.provider.googleAnalyticsProvider;
        break;
      case subbedChannelIconsConstants.channelType.googleMerchant:
        element.imgUrl = subbedChannelIconsConstants.icons.googleMerchantCentre;
        element.title = subbedChannelIconsConstants.title.googleMerchantTitle;
        element.provider = subbedChannelIconsConstants.provider.googleBusinessProvider;
        break;
      case subbedChannelIconsConstants.channelType.googleMyBusiness:
        element.imgUrl = subbedChannelIconsConstants.icons.googleBusiness;
        element.title = subbedChannelIconsConstants.title.googleMyBusinessTitle;
        element.provider = subbedChannelIconsConstants.provider.googleMyBusinessProvider;
        break;
      case subbedChannelIconsConstants.channelType.googleSearchConsole:
        element.imgUrl = subbedChannelIconsConstants.icons.googleSearchConsole;
        element.title = subbedChannelIconsConstants.title.googleSearchConsoleTitle;
        element.provider = subbedChannelIconsConstants.provider.googleSearchProvider;
        break;
      case subbedChannelIconsConstants.channelType.googleTagManager:
        element.imgUrl = subbedChannelIconsConstants.icons.googleTagManager;
        element.title = subbedChannelIconsConstants.title.googleTagManagerTitle;
        element.provider = subbedChannelIconsConstants.provider.googleTagManagerProvider;
        break;
      case subbedChannelIconsConstants.channelType.bingAds:
        element.imgUrl = subbedChannelIconsConstants.icons.bing;
        element.title = subbedChannelIconsConstants.title.bingAdsTitle;
        element.provider = subbedChannelIconsConstants.provider.bingAdsProvider;
        break;
      case subbedChannelIconsConstants.channelType.facebookAdTitle:
        element.imgUrl = subbedChannelIconsConstants.icons.facebookAds;
        element.title = subbedChannelIconsConstants.title.facebookAdTitle;
        element.provider = subbedChannelIconsConstants.provider.facebookAdProvider;
        break;
      case subbedChannelIconsConstants.channelType.facebookPagesTitle:
        element.imgUrl = subbedChannelIconsConstants.icons.facebook;
        element.title = subbedChannelIconsConstants.title.facebookPagesTitle;
        element.provider = subbedChannelIconsConstants.provider.facebookPagesProvider;
        break;
      case subbedChannelIconsConstants.channelType.linkedInAds:
        element.imgUrl = subbedChannelIconsConstants.icons.linkedin;
        element.title = subbedChannelIconsConstants.title.linkedInAdsTitle;
        element.provider = subbedChannelIconsConstants.provider.linkedInAdsProvider;
        break;
      case subbedChannelIconsConstants.channelType.linkedInPages:
        element.imgUrl = subbedChannelIconsConstants.icons.linkedin;
        element.title = subbedChannelIconsConstants.title.linkedInPagesTitle;
        element.provider = subbedChannelIconsConstants.provider.linkedInPagesProvider;
        break;
      case subbedChannelIconsConstants.channelType.twitterAds:
        element.imgUrl = subbedChannelIconsConstants.icons.twitter;
        element.title = subbedChannelIconsConstants.title.twitterAdsTitle;
        element.provider = subbedChannelIconsConstants.provider.twitterAdsProvider;
        break;
      case subbedChannelIconsConstants.channelType.twitterPages:
        element.imgUrl = subbedChannelIconsConstants.icons.twitter;
        element.title = subbedChannelIconsConstants.title.twitterPagesTitle;
        element.provider = subbedChannelIconsConstants.provider.twitterPagesProvider;
        break;
      default:
        break;
    }
  });
  return data;
};

export { getImageUrls };
