/* eslint-disable no-unused-vars */
import React, { useState, Fragment, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Icons from '../../assets/Icons';
import Footer from '../../components/Footer';
import Header from '../../components/Header';
import PaymentMethods from './MethodOfPayment';
import Plans from './Plans';
import PricingPlans from '../../components/PricingPlans';
import SuccessPage from './SuccessMessage';
import { Dialog, Transition } from '@headlessui/react';
import { createCheckoutSession } from '../../services/paymentService';
import { subscriptionPlans } from '../../constants/constants';
import { signupConstants } from '../../components/Modal/SignUpModal/constants';
import { OtpVerificationModal } from '../../components/Modal/OtpVerificationModal';
import { LoginModal } from '../../components/Modal/LoginModal';
import { useToastBox } from '../../customHooks/useToastBox';
import useFormHandler from '../../customHooks/useFormHandler';
import Loader from '../../components/Loader';

const Payment = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const { handleSuccessToast, handleErrorToast } = useToastBox();
  const tenantId = sessionStorage.getItem('tenant_id');
  const user = location?.state?.user;
  const retryPayment = location?.state?.retryPayment;
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(false);
  const [loading, setIsLoading] = useState(false);
  const [otpModal, setOtpModal] = useState(false);
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(false);
  const [paymentComplete, setPaymentComplete] = useState(false);
  const [render, setRender] = useState(false);

  const { handleOtpSubmit, setFormError } = useFormHandler();

  const handleTwoFactorResponse = () => {
    setOtpModal(false);
    setIsLoginModalOpen(true);
    // setFormError(values => ({
    //   ...values,
    //   [signupConstants.validationErrorMessages.apiError]: '',
    // }));
    // handleSuccessToast('Signup Successful');
  };

  const handleTwoFactorSubmit = event => {
    handleOtpSubmit(event, false, user)
      .then(() => handleTwoFactorResponse())
      .catch(error => handleTwoFactorFailure(error));
  };

  const handleTwoFactorFailure = error => {
    setFormError(values => ({
      ...values,
      [signupConstants.validationErrorMessages.apiError]: error.message,
    }));
    handleErrorToast(error.message);
  };

  const handleBackClick = error => {
    if (paymentComplete) {
      setPaymentComplete(false);
    } else {
      navigate('/');
    }
  };

  const handleSubmit = (type, isTrial, annualPlan) => {
    if (type !== subscriptionPlans?.enterprise) {
      setIsLoading(true);
      let plan = { subscriptionType: annualPlan && type === 'team' ? 'annual' : type, tenantId: tenantId, update: false };
      if (retryPayment) {
        plan.reSubscribe = true;
      }
      if (isTrial) {
        plan.trial = true;
      }
      createCheckoutSession(plan)
        .then(response => {
          if (response?.status === 201 || response?.status === 200) {
            window.location.href = response?.data?.data?.checkoutUrl;
            //setIsLoading(false);
          }
        })
        .catch(err => {
          handleErrorToast(err.message);
          setIsLoading(false);
        });
    }
  };

  useEffect(() => {
    setRender(!render);
  }, [tenantId]);

  return loading ? (
    <Loader />
  ) : (
    <div className="relative">
      {/* <Header
        setIsSignupModalOpen={setIsSignupModalOpen}
        isLoginModalOpen={isLoginModalOpen}
        setIsLoginModalOpen={setIsLoginModalOpen}
      /> */}

      <section className="relative overflow-hidden bg-gradient-to-r from-primaryMedium to-primaryDark py-4 pt-6  md:pt-28 md:pb-5">
        <div className="relative z-10 mx-auto max-w-7xl px-5">
          <button className=" flex items-center text-sm font-medium text-white hover:opacity-50">
            <Icons.leftArrow />
            <span className=" ml-2.5" onClick={() => handleBackClick()}>
              Back
            </span>
          </button>
          <h1 className=" m-0 p-0 text-2.1xl font-semibold leading-[2.75rem] text-white md:mt-1.5 md:text-base">
            Pricing & Payment
          </h1>
        </div>
        <div className=" rotating absolute -bottom-27 -right-8.5 h-[180px] w-[180px] text-gray-medium1 md:-right-24 md:-bottom-28">
          <Icons.paymentShape1 />
        </div>
        <div className=" rotating absolute -top-27 -left-8.5 h-[180px] w-[180px] text-gray-medium1 md:-top-[133px] md:-left-17">
          <Icons.paymentShape1 />
        </div>
      </section>
      {!paymentComplete ? (
        <>
          <section className="mx-auto max-w-7xl px-5 pt-10 pb-16 md:pt-4 md:pb-10">
            <div className="mb-4 w-full md:hidden">
              <h2 className="mb-2 text-xl font-semibold leading-6 text-gray-dark">Payment</h2>
              <p className="text-sm font-normal text-gray-medium">Please complete the payment and enjoy HYDRA</p>
            </div>
            <div className="flex w-full items-start justify-center pt-16 md:flex-col">
              {/* <PaymentMethods onPaymentClick={value => setPaymentComplete(value)} /> */}
              <PricingPlans onSelect={(type, isTrial, annualPlan) => handleSubmit(type, isTrial, annualPlan)} />
            </div>
          </section>
        </>
      ) : (
        <SuccessPage />
      )}
      {otpModal && (
        <Transition appear show={true} as={Fragment}>
          <Dialog as="div" className="relative z-50" onClose={() => {}}>
            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center ">
                <Dialog.Panel className="w-full max-w-[27.375rem] transform overflow-hidden rounded-lg">
                  <OtpVerificationModal
                    constants={signupConstants}
                    setIsOpen={setOtpModal}
                    handleSubmit={handleTwoFactorSubmit}
                    handleReset={() =>
                      setFormError(values => ({
                        ...values,
                        apiError: '',
                      }))
                    }
                    //error={formError.apiError}
                    user={user}
                  />
                </Dialog.Panel>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
      <LoginModal
        isLoginModalOpen={isLoginModalOpen}
        setIsLoginModalOpen={setIsLoginModalOpen}
        setIsSignupModalOpen={setIsSignupModalOpen}
      />
      <section className="w-full">
        <Footer />
      </section>
    </div>
  );
};

export default Payment;
