/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import IndividualChannel from './components/IndividualChannel';
import Icons from '../../assets/Icons';
import BreadCrumb from '../../components/BreadCrumb';
import CommonDashboard from '../../components/CommonDashboard';
import { navData } from './constants';
import { useNavigate, useLocation } from 'react-router-dom';
import classes from './channel.module.scss';
import useAuth from '../../customHooks/useAuth';
import { processChannelList } from './helper';
import { getChannelCount, getChannelPermissionList } from '../../services/userPermissionChannelService';
import { accountUser } from '../../services/searchService';
import { useEffect } from 'react';
import { useToastBox } from '../../customHooks/useToastBox';
import NoData from '../../components/Nodata';
import Loader from '../../components/Loader';
import { labelDisplay } from '../../utils/helpers';

const AccountUsersView = () => {
  const [isLoading, setIsLoading] = useState(true);
  const [channelMobileSelection, setChannelMobileSelection] = useState(0);
  const [channelPageList, setChannelPageList] = useState([]);
  const [channelFilter, setChannelFilter] = useState({});
  const [channelCount, setChannelCount] = useState(0);
  const { auth } = useAuth();
  const location = useLocation();
  const accountUserId = location?.state?.id;
  const accountUserName = location?.state?.name;

  const setInitialData = () => {
    if (accountUserId) {
      accountUser(accountUserId)
        .then(response => {
          setChannelPageList(processChannelList(response?.data?.data, accountUserId, accountUserName));
          setIsLoading(false);
        })
        .catch(error => {
          console.error(error);
          setIsLoading(false);
        });
    }
  };

  const navigate = useNavigate();
  navData[0].pathHistory[0].title = location?.state?.name;
  navData[0].pathHistory[1].title =
    channelPageList?.length == 1
      ? `${channelPageList?.length} Channel`
      : channelPageList?.length > 1
      ? `${channelPageList?.length} Channels`
      : 'No Channels';

  useEffect(() => {
    setInitialData();
  }, []);

  return isLoading ? (
    <Loader />
  ) : (
    <CommonDashboard classList={classes.userPermissionWrapper}>
      <BreadCrumb navData={navData} channelCount={channelCount} prevPage={'/search-results'}></BreadCrumb>
      <div className={classes.coreConsole}>
        <div className={classes.pageHeaderMobWrapper}>
          {channelPageList.length !== 0 &&
            channelPageList.map((data, index) => {
              if (data.checked) {
                return (
                  <div
                    className={`${classes.channelCardHeader} ${channelMobileSelection === index && classes.selected}`}
                    key={`individualChannelHeader__${index}`}
                    onClick={() => setChannelMobileSelection(index)}
                  >
                    <div className={classes.logo}>
                      <img src={data.logo} alt={data.channelName} />
                    </div>
                    <div className={classes.channelTitleWrapper}>
                      <div className={classes.channelTitle}>{data.channelName}</div>
                      <div className={classes.channelPageCount}>
                        {data.pagesDetails.length} {labelDisplay(data.pagesDetails.length, data.channel)}
                      </div>
                    </div>
                  </div>
                );
              }
            })}
        </div>
        {channelPageList.length !== 0 ? (
          channelPageList.map((element, index) => {
            if (element.checked) {
              return (
                <>
                  <IndividualChannel
                    itemIndex={index}
                    data={element}
                    key={`individualChannel__${index}`}
                    editMode={true}
                    selectedCard={channelMobileSelection}
                    filter={channelFilter}
                    navigate={navigate}
                  />
                </>
              );
            }
          })
        ) : !isLoading ? (
          <div className={classes.noRecords}>
            <NoData message={'No channels to display'} showImage={true} />
          </div>
        ) : null}
      </div>
    </CommonDashboard>
  );
};
export default AccountUsersView;
