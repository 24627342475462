import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Icons from '../../../assets/Icons';
import classes from './channel.module.scss';
import RoleDP from './RoleSlidePanel';
import Tippy from '@tippyjs/react';
import { labelDisplay } from '../../../utils/helpers';

const PageBuilder = ({ pageData }) => {
  const navigate = useNavigate();
  return (
    <div
      className={`${classes.pageWrapper} `}
      onClick={() => {
        navigate('/userPermissionChannelView', {
          state: {
            channelName: pageData?.title,
            channelType: pageData?.channel,
            fromAccountUser: true,
            id: pageData?.accountUserId,
            name: pageData?.accountUserName,
          },
        });
      }}
    >
      <div
        className={classes.pageHeader}
        onClick={() => {
          navigate('/userPermissionChannelView', {
            state: {
              channelName: pageData?.title,
              channelType: pageData?.channel,
              fromAccountUser: true,
              id: pageData?.accountUserId,
              name: pageData?.accountUserName,
            },
          });
        }}
      >
        <div className={classes.pageLogo}>
          <img src={pageData.logo} alt={pageData.title} />
        </div>
        <div className={classes.title}>{pageData.title}</div>
        <div className={classes.pageChecker}></div>
      </div>
      <div className={classes.pageBody}>
        <div className={classes.pageRow}>
          <div className={`${classes.pageProperty}`}>
            <div className={classes.typeIcon}>
              <Icons.Lock />
            </div>
            <div className={classes.typeDetails}>
              <div className={classes.typeDetailsTitle}>Permission</div>
              <div className={classes.typeDetailsStatus}>
                {pageData?.permission?.length
                  ? pageData.permission.map((element, index) => {
                      return (
                        <span className={classes.typeRoles} key={`permissionList__${index}`}>
                          {element.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())}
                        </span>
                      );
                    })
                  : '-'}
              </div>
            </div>
          </div>

          <div className={`${classes.pageProperty}`}>
            <div className={classes.typeIcon}>
              <Icons.UserVerify />
            </div>
            <div className={classes.typeDetails}>
              <div className={classes.typeDetailsTitle}>Role</div>
              <div className="text-xs font-medium leading-4.5 text-black">
                {pageData.role?.length
                  ? pageData.role.map((element, index) => {
                      return (
                        // <span className={classes.typeRoles} key={`role__${index}`}>
                        //   {element.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())}
                        // </span>
                        <span key={`role__${index}`}>{element ? (index ? '  ,  ' : '') + element : null}</span>
                      );
                    })
                  : '-'}
              </div>
            </div>
          </div>
        </div>
        <div className={classes.pageRow}>
          <div className={`${classes.pageProperty}`}>
            <div className={classes.typeIcon}>
              <Icons.MailIcon />
            </div>
            <div className={classes.typeDetails}>
              <div className={classes.typeDetailsTitle}>Email</div>
              <div className={classes.typeDetailsStatus}>
                <Tippy placement="bottom" arrow={true} content={pageData.email}>
                  <span className={classes.typeRoles}>{pageData.email}</span>
                </Tippy>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

const IndividualChannel = ({ data, editMode, selectedCard, itemIndex, filter }) => {
  const [openRoleSelector, setOpenRoleSelector] = useState({
    isOpen: false,
    optionList: [],
  });
  return (
    <>
      <div className={`${classes.channelWrap} ${selectedCard === itemIndex ? classes.activeChannel : ''}`}>
        <div className={`${classes.card} ${classes.channelCard}`}>
          <div className={classes.channelCardHeader}>
            <div className={classes.logo}>
              <img src={data.logo} alt={data.channelName} />
            </div>
            <div className={classes.channelTitleWrapper}>
              <div className={classes.channelTitle}>{data.channelName}</div>
              <div className={classes.channelPageCount}>
                {data.pagesDetails.length} {labelDisplay(data.pagesDetails.length, data.channel)}
              </div>
            </div>
          </div>
          <div className={classes.channelCardBody}>
            {data.pagesDetails?.map((element, index) => {
              if (Object.values(filter).length && filter.value !== 'All') {
                if (filter.value === element.title) {
                  return (
                    <PageBuilder
                      pageData={element}
                      key={`pageBuilder__${index}`}
                      editMode={editMode}
                      setOpenRoleSelector={setOpenRoleSelector}
                    />
                  );
                }
              } else {
                return (
                  <PageBuilder
                    pageData={element}
                    key={`pageBuilder__${index}`}
                    editMode={editMode}
                    setOpenRoleSelector={setOpenRoleSelector}
                  />
                );
              }
            })}
          </div>
        </div>
      </div>
      {/* OPEN THIS ONLY ON MOBILE , NEED JS FUNCTIONALITY ON ROLE DROPDOWN and should be open on mobile focus on react-select, Open only one at a time */}
      {openRoleSelector.isOpen && <RoleDP setFilter={setOpenRoleSelector} options={openRoleSelector.optionList} />}
    </>
  );
};
export default IndividualChannel;
