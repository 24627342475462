import React from 'react';
import Tippy from '@tippyjs/react';
import Icons from '../../assets/Icons';
import Logo from '../../assets/images/logo.svg';

const Footer = () => {
  return (
    <div className="landing-page-footer pt-28 md:pt-16">
      <div className="pb-12.5 md:pb-9.5 2xl:bg-blue-dark">
        <div className="animate__animated animate__fadeInUp delay01 mx-auto flex max-w-7xl items-start justify-between px-5 md:flex-col">
          <img src={Logo} alt="Logo" className="mr-12 md:mb-8 md:h-6 md:w-[86px]" />
          <div className="flex w-full items-start justify-around md:mb-6 md:justify-between">
            <div>
              <p className="mb-4 text-sm font-semibold leading-5.5 text-white">About us</p>
              <ul className="text-sm font-normal leading-5.5 text-gray-light4">
                <li className="mb-3 cursor-pointer">
                  <a href="/aboutUs">About</a>
                </li>
                <li className="mb-3 cursor-pointer">
                  <a href="/meetTheTeam">Meet the team</a>
                </li>
              </ul>
            </div>
            <div>
              <p className="mb-4 text-sm font-semibold leading-5.5 text-white">Useful links</p>
              <ul className="text-sm font-normal leading-5.5 text-gray-light4">
                <li className="mb-3 cursor-pointer">
                  <a href="/TermsAndConditions">Terms and Conditions</a>
                </li>
                <li className="mb-3 cursor-pointer">
                  <a href="/privacyPolicy">Privacy</a>
                </li>
              </ul>
            </div>
          </div>
          <div>
            <p className="mb-4 truncate text-sm font-semibold leading-5.5 text-white">Follow Us on</p>
            <div className="flex items-center gap-x-4 text-white">
              <Tippy content="Twitter" placement="top" trigger="mouseenter" arrow={true} theme="light">
                <a
                  className="cursor-pointer transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 hover:opacity-50"
                  href="https://twitter.com/HiHydraSoftware"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icons.TwitterIcon />
                </a>
              </Tippy>
              <Tippy content="LinkedIn" placement="top" trigger="mouseenter" arrow={true} theme="light">
                <a
                  className="cursor-pointer transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 hover:opacity-50"
                  href="https://www.linkedin.com/company/74566146/admin/feed/posts/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icons.LinkedInIcon />
                </a>
              </Tippy>

              <Tippy content="Facebook" placement="top" trigger="mouseenter" arrow={true} theme="light">
                <a
                  className="cursor-pointer transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 hover:opacity-50"
                  href="https://www.facebook.com/hihydralimited/"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icons.FacebookIcon />
                </a>
              </Tippy>
              <Tippy content="Youtube" placement="top" trigger="mouseenter" arrow={true} theme="light">
                <a
                  className="cursor-pointer transition delay-150 duration-300 ease-in-out hover:-translate-y-1 hover:scale-110 hover:opacity-50"
                  href="https://www.youtube.com/@hydra7097"
                  target="_blank"
                  rel="noreferrer"
                >
                  <Icons.YoutubeIcon />
                </a>
              </Tippy>
            </div>
          </div>
        </div>
      </div>
      {/* <button className="fixed right-5 bottom-10 z-20">
        <Icons.ChatIcon />
      </button> */}
    </div>
  );
};

export default Footer;
