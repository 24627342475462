/* eslint-disable no-unused-vars */
import React, { useState } from 'react';
import Select from 'react-select';
import Icons from '../../../assets/Icons';
import { textConstants } from '../../../features/UsersPermissionsChannelView/constants';
import { subscriptionPlans } from '../../../constants/constants';
import { toast } from 'react-toastify';
import RoleDP from './RoleSlidePanel';
import Tippy from '@tippyjs/react';

const TooltipMessage = () => {
  return (
    <div className="max-w-[12.8125rem]">
      <div className="flex gap-x-1">
        <div className="tooltip-icon">
          <Icons.EditIcon />
        </div>
        <div className="pb-1 text-[10px] font-medium leading-[15px] text-white">
          You can edit/request to edit user since you are an Admin on Hydra.
        </div>
      </div>
    </div>
  );
};

function hideEditButton(channelType) {
  switch (channelType) {
    case textConstants.twitterAds:
    case textConstants.googleSearchConsole:
    case textConstants.linkedinPages:
    case textConstants.linkedinAds:
    case textConstants.facebookPages:
      return true;
    default:
      return false;
  }
}

const PageBuilder = ({
  pageData,
  setOpenRoleSelector,
  setOpenPermissionSelector,
  channelRole,
  selectedWorkspace,
  editUser,
  setEditUser,
  saveEditChanges,
  onRoleChange,
  channelType,
  onPermissionChange,
  roleList,
  permissionList,
  onUserRemove,
  currentUser,
  onEditClick,
  rolePermission,
  rolePermissionLabel,
  userRole,
  subscriptionType,
}) => {
  const filteredOptions = roleList?.filter(option => !option.isDisabled);

  const disableEdit =
    (channelType == textConstants.facebookPages || channelType == textConstants.facebookAds) &&
    pageData?.isBusinessAssociated == false;

  const merchantDisableEdit = pageData?.isMainAccountUser && pageData?.isSubAccount;

  const disableRemove = channelType == textConstants.facebookPages || channelType == textConstants.facebookAds;
  return !pageData.removed ? (
    <div className="my-[1px] flex h-auto w-full cursor-default flex-col rounded-md outline outline-1 outline-transparent transition-all duration-300 hover:outline-1 hover:outline-gray-light">
      <div className="flex items-center gap-x-[15px] rounded-t-md border border-solid border-white200 bg-gray-light7 py-2.5 px-4 ">
        <div className="truncate text-xs font-semibold leading-4.5 text-gray-dark">
          {pageData?.title == 'undefined undefined' ? 'Private User' : pageData?.title}
        </div>
        {currentUser === pageData.title && (
          <div className="current-user relative top-0 text-[11px] font-medium capitalize leading-4 text-gray-medium">
            you
          </div>
        )}
        <div>
          {userRole !== 'user' &&
            !hideEditButton(channelType) &&
            subscriptionType !== subscriptionPlans.individual &&
            selectedWorkspace !== null &&
            (editUser !== pageData.id ? (
              <Tippy
                placement="bottom"
                arrow={true}
                content={
                  disableEdit ? (
                    'You cannot edit/request to edit user since this user is not business associated.'
                  ) : merchantDisableEdit ? (
                    'You cannot edit/request to edit user since this user is a user in main account.'
                  ) : (
                    <TooltipMessage />
                  )
                }
              >
                <button
                  className={`button ml-auto -mt-0.5 flex text-gray-dark1 ${
                    disableEdit || merchantDisableEdit ? 'button-disabled' : null
                  }`}
                  aria-label="Edit Permission"
                  onClick={e => {
                    if (!disableEdit && !merchantDisableEdit) {
                      e.stopPropagation();
                      setEditUser(pageData.id);
                      onEditClick(pageData);
                    }
                  }}
                >
                  <Icons.EditIcon />
                </button>
              </Tippy>
            ) : (
              <Tippy placement="bottom" arrow={true} content={<TooltipMessage />}>
                {/* <button
                  className="button ml-auto -mt-0.5 flex text-gray-dark1 "
                  aria-label="Save Permission"
                  onClick={e => {
                    e.stopPropagation();
                    saveEditChanges(pageData);
                  }}
                >
                  <Icons.SaveIcon />
                </button> */}
                <button
                  className="btn-secondary-outline ml-auto h-auto bg-primary px-4.5 py-1 text-white"
                  onClick={e => {
                    e.stopPropagation();
                    saveEditChanges(pageData);
                  }}
                >
                  <span>Save</span>
                </button>
              </Tippy>
            ))}
        </div>
        {/* Added for future use */}
        {/* <button className="btn-secondary-outline ml-auto h-auto bg-primary px-4.5 py-1 text-white">
          <span>Edit</span>
        </button> */}
      </div>
      <div
        className={`flex h-auto  flex-col rounded-b-md border border-t-0 border-solid border-white200 bg-white p-4 ${
          channelType == textConstants.facebookPages || channelType == textConstants.facebookAds ? 'min-h-[100px]' : null
        }`}
      >
        {/* <div className="relative flex grow gap-x-4 pb-4  last:pb-0">
          <div className="flex grow gap-x-2">
            <div className="login-icon mt-1">
              <Icons.LoginIcon />
            </div>
            <div>
              <div className="text-xs font-normal leading-4.5 text-gray-medium">Last Login</div>
              <div className="text-xs font-medium leading-4.5 text-gray-dark">
                {pageData.lastLogin[0] && (
                  <>
                    <span>
                      {pageData.lastLogin[0].location} {pageData.lastLogin[0].dateTime}
                    </span>
                  </>
                )}
              </div>
            </div>
          </div>
        </div> */}
        {editUser !== pageData.id && (
          <>
            <div className="relative flex space-x-2 pb-2">
              <div className=" text-gray-medium">
                <Icons.UserVerify />
              </div>
              <div className="flex flex-col">
                <label className="whitespace-nowrap text-xs font-normal leading-4.5 text-gray-medium">
                  {pageData.roleLabel}:
                </label>
                <div className="text-xs font-medium leading-4.5 text-gray-medium">
                  <span>
                    {pageData?.role?.label
                      ? pageData?.role?.label
                      : pageData.displayRole?.length > 0 && Array.isArray(pageData.displayRole)
                      ? pageData?.displayRole?.map((item, index8) => (
                          <span key={index8}>{item ? (index8 ? '  ,  ' : '') + item : null}</span>
                        ))
                      : pageData.displayRole?.length > 0
                      ? pageData.displayRole
                      : '-'}
                  </span>
                </div>
              </div>
            </div>

            {(channelType === textConstants.googleTagManager || channelType === textConstants.googleAnalytics) && (
              <div className="relative flex space-x-2 pb-2">
                <div className=" text-gray-medium">
                  <Icons.Lock />
                </div>
                <div className="flex flex-col">
                  <label className="mb-1.5 text-xs font-normal leading-4.5 text-gray-medium">
                    {pageData.permissionLabel
                      ? pageData.permissionLabel
                      : rolePermissionLabel?.permission
                      ? rolePermissionLabel?.permission
                      : '-'}
                    :
                  </label>
                  <div className="text-xs font-medium leading-4.5 text-gray-medium">
                    <span>
                      {pageData?.displayPermission?.length > 0 &&
                      channelType == textConstants.googleAnalytics &&
                      Array.isArray(pageData?.displayPermission)
                        ? pageData?.displayPermission?.map((item, index8) => (
                            <span key={index8}>{item ? (index8 ? '  ,  ' : '') + item : null}</span>
                          ))
                        : pageData?.permission?.label
                        ? pageData?.permission?.label
                        : pageData?.permission?.length
                        ? pageData?.permission[0]
                        : pageData?.displayPermission?.length
                        ? pageData?.displayPermission
                        : '-'}
                    </span>
                  </div>
                </div>
              </div>
            )}
          </>
        )}
        {editUser === pageData.id && (
          <>
            <div
              className="mb-4"
              onClick={() =>
                setOpenRoleSelector({
                  isOpen: true,
                  optionList: filteredOptions,
                })
              }
            >
              <label className="mb-1.5  text-xs font-normal leading-4.5 text-gray-medium">{rolePermissionLabel.role}</label>
              <Select
                options={filteredOptions}
                isMulti={
                  channelType === textConstants.facebookAds ||
                  channelType === textConstants.facebookPages ||
                  channelType === textConstants.googleMerchantCentre
                    ? true
                    : false
                }
                placeholder="Select"
                classNamePrefix="hydra-dropdown"
                value={rolePermission.role}
                onChange={e => {
                  onRoleChange(e, pageData.email);
                }}
              />
            </div>
            <div
              className="mb-4"
              onClick={() =>
                setOpenPermissionSelector({
                  isOpen: true,
                  optionList: permissionList,
                })
              }
            >
              {(channelType === textConstants.googleTagManager || channelType === textConstants.googleAnalytics) && (
                <>
                  <label className="mb-1.5 text-xs font-normal leading-4.5 text-gray-medium">
                    {rolePermissionLabel.permission}
                  </label>
                  <Select
                    options={permissionList}
                    isMulti={channelType === textConstants.googleAnalytics ? true : false}
                    value={rolePermission.permission}
                    onChange={e => {
                      onPermissionChange(e, pageData.email);
                    }}
                    placeholder={permissionList != null ? 'Select' : `No ${rolePermissionLabel.permission}`}
                    classNamePrefix="hydra-dropdown"
                  />
                </>
              )}
            </div>
            {channelRole === 'Admin' && !disableRemove ? (
              <button
                className="btn-link ml-auto flex items-center gap-x-1.5 md:mt-4"
                aria-label="Request to Remove"
                onClick={() => {
                  toast.info(
                    <div className={'toastContent'}>
                      A request to remove <b>jamescameroonpriv@gmail.com</b> has been sent to the administration
                    </div>,
                    {
                      icon: <Icons.InfoIcon3 />,
                    }
                  );
                }}
              >
                <span>Request to Remove</span>
                <Icons.TrashIcon />
              </button>
            ) : !disableRemove ? (
              <button
                className="btn-link ml-auto flex items-center gap-x-1.5 md:mt-4"
                aria-label="Remove"
                onClick={() => {
                  onUserRemove(pageData);
                }}
              >
                <span>Remove</span>
                <Icons.TrashIcon />
              </button>
            ) : null}
          </>
        )}
      </div>
    </div>
  ) : (
    <RemovedPageBuilder />
  );
};
const RemovedPageBuilder = () => {
  return (
    <div className="remove-card flex w-full max-w-[17.0625rem] shrink-0 cursor-pointer items-start gap-2 rounded-lg border border-dashed border-red-dark bg-red-light p-4 md:max-w-full">
      <div className="trashIcon">
        <Icons.Trashed />
      </div>
      <div className="text-xs font-medium leading-4.5 text-gray-dark">Company ABC has been removed</div>
      <button aria-label="Undo removal" className="btn-link ml-auto mt-0.5 leading-4.5">
        Undo
      </button>
    </div>
  );
};
const IndividualChannel = ({
  data,
  currentUser,
  selectedWorkspace,
  editUser,
  setEditUser,
  saveEditChanges,
  onRoleChange,
  onPermissionChange,
  channelType,
  roleList,
  permissionList,
  onUserRemove,
  onEditClick,
  rolePermission,
  rolePermissionLabel,
  userRole,
  subscriptionType,
}) => {
  const [openRoleSelector, setOpenRoleSelector] = useState({
    isOpen: false,
    optionList: [],
  });
  const [openPermissionSelector, setOpenPermissionSelector] = useState({
    isOpen: false,
    optionList: [],
  });

  return (
    <>
      <div className="flex h-full w-full shrink-0 flex-col items-start gap-y-4 gap-x-4 overflow-visible md:border-0 md:bg-transparent md:p-0">
        {Object.keys(data).length !== 0 ? (
          <PageBuilder
            pageData={data}
            key={`pageBuilder__${data}`}
            channelRole={data.channelName}
            setOpenRoleSelector={setOpenRoleSelector}
            setOpenPermissionSelector={setOpenPermissionSelector}
            selectedWorkspace={selectedWorkspace}
            editUser={editUser}
            setEditUser={setEditUser}
            saveEditChanges={saveEditChanges}
            onRoleChange={onRoleChange}
            onPermissionChange={onPermissionChange}
            channelType={channelType}
            roleList={roleList}
            permissionList={permissionList}
            onUserRemove={onUserRemove}
            currentUser={currentUser}
            onEditClick={onEditClick}
            rolePermission={rolePermission}
            rolePermissionLabel={rolePermissionLabel}
            userRole={userRole}
            subscriptionType={subscriptionType}
          />
        ) : (
          <>
            <div className="flex min-h-[3rem] w-full  justify-center rounded-md border border-solid border-gray-light6 bg-white  p-4 text-xs">
              No data Available.
            </div>
          </>
        )}
      </div>
      {/* OPEN THIS ONLY ON MOBILE , NEED JS FUNCTIONALITY ON ROLE DROPDOWN and should be open on mobile focus on react-select, Open only one at a time */}
      {/* {openRoleSelector.isOpen && (
        <RoleDP
          setFilter={setOpenRoleSelector}
          options={openRoleSelector.optionList}
          title={rolePermissionLabel.role}
          onClick={onRoleChange}
          pageData={data}
          rolePermission={rolePermission}
        />
      )}
      {openPermissionSelector.isOpen && (
        <RoleDP
          setFilter={setOpenPermissionSelector}
          options={openPermissionSelector.optionList}
          title={rolePermissionLabel.permission}
          onClick={onPermissionChange}
          pageData={data}
          rolePermission={rolePermission}
          isPermission
        />
      )} */}
    </>
  );
};
export default IndividualChannel;
