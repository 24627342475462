import { API } from '../../constants/api';
import { get } from '../../utils/apiHandler';

export const getAuthTokenService = async (provider, authCode, verifier) => {
  let authParams = '';
  if (verifier !== '') {
    //twitter api
    authParams = `provider=${provider}&code=${authCode}&verifier=${verifier}`;
  } else {
    //for all other apis
    authParams = `provider=${provider}&code=${authCode}`;
  }
  try {
    const response = await get(`${API.get_auth_token}?${authParams}`);
    return response;
  } catch (error) {
    console.log('authtoken getauthtokenservice error', error);
    throw new Error(error?.message);
  }
};
