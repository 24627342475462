/* eslint-disable no-unused-vars */
import React, { Fragment, useState } from 'react';
import CryptoJS from 'crypto-js';
import { Dialog, Transition } from '@headlessui/react';
import Icons from '../../../assets/Icons';
import InputText from '../../InputText';
import useFormHandler from '../../../customHooks/useFormHandler';
import { useToastBox } from '../../../customHooks/useToastBox';
import { subscriptionPlans } from '../../../constants/constants';
import Loader from '../../Loader';
import { addAdditionalLicense } from '../../../services';
import { ConfirmationModal } from '../ConfirmationModal';
import { getUserInfo } from '../../../utils/getUserInfo';

export const AdditionalLicenseModal = props => {
  const userInfo = JSON.parse(getUserInfo());
  const tenantId = userInfo?.user?.tenantId;

  let subscriptionType;
  const subscriptionCode = userInfo?.user?.subscriptionType;
  if (subscriptionCode) {
    subscriptionType = CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8);
  }

  const { handleErrorToast } = useToastBox();
  const [addUserFormData, setAddUserFormData] = useState({});
  const [confirmModal, setConfirmModal] = useState(false);

  const [loading, setIsLoading] = useState(false);

  const [count, setCount] = useState(0);

  const { formError } = useFormHandler(addUserFormData, setAddUserFormData);

  const handleProceed = () => {
    setConfirmModal(false);
    setIsLoading(true);
    addAdditionalLicense(count, tenantId, false)
      .then(response => {
        setIsLoading(false);
        if (response?.status === 201 || response?.status === 200) {
          window.location.href = response?.data?.data?.sessionUrl;
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.log('error', error);
        handleErrorToast(error.message);
      });
    props.setIsOpen(false);
  };

  return (
    <>
      {loading ? (
        <Loader />
      ) : (
        <Transition appear show={props.isOpen} as={Fragment}>
          <Dialog as="div" className="relative z-10" onClose={() => {}}>
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0"
              enterTo="opacity-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100"
              leaveTo="opacity-0"
            >
              <div className="fixed inset-0 bg-black bg-opacity-60" />
            </Transition.Child>

            <div className="fixed inset-0 overflow-y-auto">
              <div className="flex min-h-full items-center justify-center p-4 text-center ">
                <Transition.Child
                  as={Fragment}
                  enter="ease-out duration-300"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="ease-in duration-200"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <Dialog.Panel className="w-full max-w-[500px] transform rounded-lg  transition-all">
                    <>
                      <Dialog.Title
                        as="div"
                        className="login-modal-header relative flex min-h-[6.5rem]  items-center justify-center overflow-hidden rounded-t-lg bg-primary300 p-4 text-black shadow-none md:min-h-[7.938rem]"
                      >
                        <div className="z-10 text-white">
                          <h2 className="mb-2 text-2xl font-bold leading-9 md:mb-3">{'Purchase Additional License'}</h2>
                        </div>
                        <button className="absolute top-4 right-4 z-10 text-white" onClick={() => props.setIsOpen(false)}>
                          <Icons.CloseIcon />
                        </button>
                      </Dialog.Title>
                      <div className="rounded-b-lg  bg-white p-8 md:px-4.5 md:pt-6 md:pb-10">
                        <div className=" mb-4 flex items-center justify-center gap-2">
                          <p className="text-base font-semibold text-gray-dark">
                            {subscriptionType === subscriptionPlans.annual
                              ? '£15/month for each additional license'
                              : '£15 per Additional License'}
                          </p>
                        </div>

                        <div className="my-[10px]">
                          <div className=" flex  items-center justify-center gap-3">
                            <button
                              className="btn-secondary-outline flex  h-[40px]  w-[40px] !min-w-0  items-center justify-center rounded-md py-[5px] text-[20px] text-primary hover:!bg-white hover:text-primary"
                              onClick={() => setCount(count - 1)}
                              disabled={count === 0}
                            >
                              -
                            </button>
                            <div className="user-details-grid additional-license-modal-input">
                              <InputText
                                className="payment-inputs flex flex-col"
                                id="number"
                                placeholder=""
                                type="number"
                                value={count}
                                onChange={event => {
                                  let value = event.target.value;
                                  value = value.replace(/^0+/, '');
                                  if (value >= 0 && value <= 100) {
                                    setCount(Number(value));
                                  }
                                }}
                                handleKeyDown={e => {
                                  ['e', 'E', '+', '-', '.'].includes(e.key) && e.preventDefault();
                                }}
                              />
                            </div>
                            <button
                              className="flex  h-[40px] w-[40px] !min-w-0 items-center justify-center rounded-md bg-primary px-[14px] py-[5px] text-[20px] text-white"
                              onClick={() => setCount(count + 1)}
                              disabled={count === 100}
                            >
                              +
                            </button>
                          </div>
                          {/* <p className="mt-[30px] font-semibold">Total cost:</p> */}
                        </div>

                        <div className="footer-buttons grid w-full  grid-cols-2 items-center justify-between gap-2 pt-8 md:pt-8">
                          <button
                            className="btn-secondary-outline  w-full !min-w-0 text-primary hover:!bg-white hover:text-primary"
                            onClick={() => props.setIsOpen(false)}
                          >
                            {'Cancel'}
                          </button>
                          <button
                            className={`w-full !min-w-0 bg-primary text-white ${count === 0 ? 'opacity-70' : null}`}
                            onClick={() => setConfirmModal(true)}
                            disabled={count === 0}
                          >
                            {'Proceed'}
                          </button>
                        </div>
                      </div>
                    </>
                  </Dialog.Panel>
                </Transition.Child>
              </div>
            </div>
          </Dialog>
        </Transition>
      )}
      {confirmModal && (
        <ConfirmationModal
          isConfirmModalOpen={confirmModal}
          setIsConfirmModalOpen={setConfirmModal}
          handleClose={() => setConfirmModal(false)}
          handleConfirm={() => handleProceed()}
          message={`Are you sure you want to proceed with the purchase of ${count} additional license(s) ?`}
          subMessage={`By continuing, you will be charged for the remaining days left in the current billing cycle and a recurring charge for the additional licenses will be deducted starting from the next billing cycle.`}
          isOwner={false}
          showCancel={true}
          removeConfirmation
        />
      )}
    </>
  );
};
