/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import CryptoJS from 'crypto-js';
import Tippy from '@tippyjs/react';
import Icons from '../../assets/Icons';
import ChannelList from '../../components/ChannelSetup';
import CommonDashboard from '../../components/CommonDashboard';
import { AddUserModal } from '../../components/Modal/AddUserModal';
import UserList from './UserList';
import useAuth from '../../customHooks/useAuth';
import { AdditionalLicenseModal } from '../../components/Modal/AdditionalLicenseModal';
import { listUserLicences } from '../Dashboard/AlertBox/helper';
import { ConfirmationModal } from '../../components/Modal/ConfirmationModal';
import { useToastBox } from '../../customHooks/useToastBox';
import { RemoveLicenseModal } from '../../components/Modal/RemoveLicenseModal';
import { subscriptionPlans } from '../../constants/constants';
import { getUserInfo } from '../../utils/getUserInfo';
import { getSubscriptionDetails } from '../../services/paymentService';

const UserManagement = () => {
  const search = useLocation().search;
  const [confirmModal, setConfirmModal] = useState(false);
  const [addUserModal, setAddUserModal] = useState(false);
  const { handleSuccessToast, handleErrorToast } = useToastBox();
  const [additionalLicenseModal, setAdditionalLicenseModal] = useState(false);
  const [removeLicenseModal, setRemoveLicenseModal] = useState(false);
  const [userLicenseData, setUserLicenseData] = useState({
    additionalLicenses: 0,
    purchasedunUsedLicenses: 0,
    totalLicenses: 0,
    totalUsers: 0,
    unUsedLicenses: 0,
  });
  const [loading, setIsLoading] = useState(true);

  const [subDataloading, setSubDataLoading] = useState(true);

  const [count, setCount] = useState();
  const [isTrialPeriod, setIsTrialPeriod] = useState(false);
  const [userListReload, setUserListReload] = useState(true);
  const { auth } = useAuth();
  const owner = auth.user ? auth.user.user.owner : false;
  sessionStorage.setItem('page', 'User');
  const tenantId = auth.user ? auth.user.user.tenantId : null;
  const additionalSeatPurchase = new URLSearchParams(search).get('purchaseCompleted') === 'true' ? true : false;
  let subscriptionType;
  const userInfo = JSON.parse(getUserInfo());
  const subscriptionCode = userInfo?.user?.subscriptionType;
  if (subscriptionCode) {
    subscriptionType = CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8);
  }
  const displayUserLicences = () => {
    listUserLicences(tenantId)
      .then(res => {
        setUserLicenseData(res?.data?.data);
      })
      .catch(err => {
        handleErrorToast(err.message);
      });
  };

  const formatDate = endDate => {
    if (endDate) {
      const date = new Date(endDate);
      const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      return formattedDate;
    } else {
      const notVerifiedDate = '-';
      return notVerifiedDate;
    }
  };

  const TooltipMessage = () => {
    return (
      <div className="min-w-full">
        <div className=" flex items-center">
          <div className="flex flex-col pl-2">
            <ul className=" list-disc p-3 pl-4">
              <li className=" mb-2 text-xs font-normal leading-normal text-white">
                The <strong>Base Licenses</strong> count includes the default licenses provided with the Teams subscription.
              </li>
              <li className=" mb-2 text-xs font-normal leading-normal text-white">
                The <strong>Available Licenses</strong> count reflects the number of unused licenses you have, including any
                unused Base Licenses, Additional Licenses, and Expiring Licenses.
              </li>
              <li className=" mb-2 text-xs font-normal leading-normal text-white">
                When you remove an additional license, it becomes an <strong>Expiring License</strong>. Expiring licenses
                remain valid until the end of the current billing cycle, after which they are removed.
              </li>
              <li className=" mb-2 text-xs font-normal leading-normal text-white">
                The count of <strong>Available Expiring Licenses</strong> reflects the number of expiring licenses that are
                currently unused.
              </li>
              <li className=" mb-2 text-xs font-normal leading-normal text-white">
                The count of <strong>Used Expiring Licenses</strong> reflects the number of expiring licenses that are
                currently used.
              </li>
              <li className=" mb-2 text-xs font-normal leading-normal text-white">
                <strong>Remove at next billing</strong>
                {` users are those marked for deletion using the 'Delete later' feature or users added to expiring licenses. These users will remain active until the end of the current billing cycle and will be removed thereafter.`}
              </li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const getSubscriptionData = () => {
    setSubDataLoading(true);
    getSubscriptionDetails(tenantId)
      .then(response => {
        setSubDataLoading(false);
        if (Object.keys(response?.data?.data).length !== 0) {
          setIsTrialPeriod(response?.data?.data?.currentSubscription?.isTrial);
        }
      })
      .catch(error => {
        setSubDataLoading(false);
        console.log('error', error);
      });
  };

  useEffect(() => {
    if (tenantId) {
      displayUserLicences();
    }
    if (additionalSeatPurchase) {
      handleSuccessToast('Successfully purchased additional licenses!');
    }
  }, [tenantId, additionalLicenseModal, removeLicenseModal, userListReload, additionalSeatPurchase]);

  useEffect(() => {
    if (tenantId) {
      getSubscriptionData();
    }
  }, [tenantId]);

  return (
    <CommonDashboard>
      {!loading && (
        <div className="xl:hidden">
          <ChannelList onLoad={() => {}} isLoading={false} />
        </div>
      )}
      <div className="flex w-full flex-col overflow-hidden bg-gray-light1 md:-mx-5 md:w-[calc(100%+40px)]">
        {!loading && (
          <div className="flexCenter justify-between border-b border-white200 bg-white p-4 sm:flex-col sm:items-start sm:justify-start sm:pt-0 ">
            <div className="flexCenter sm:pb-4">
              <span className=" text-base  font-medium leading-6 text-gray-dark">User Management</span>
              <span className=" mx-2 h-1 w-1 rounded-full bg-gray-light5"></span>
              <span className=" cursor-default text-xs leading-[18px] text-gray-medium ">
                {count === 1 ? `${count} User` : `${count} Users`}{' '}
              </span>

              <Tippy placement="bottom" arrow={true} content={<TooltipMessage />} maxWidth={650}>
                <span className="  ml-2 cursor-pointer text-primary">
                  <Icons.InfoIcon />
                </span>
              </Tippy>
            </div>
            <div className="flexCenter  user-management-buttons space-x-2 sm:w-full">
              <button
                className={
                  owner
                    ? 'flexCenter h-7.5 cursor-not-allowed rounded-full border border-red-dark bg-red-light px-3.5 text-xs font-medium text-red-dark hover:bg-red-dark  hover:text-white xs:w-full xs:justify-center'
                    : 'flexCenter h-7.5 cursor-not-allowed rounded-full border border-red-dark bg-red-light px-3.5 text-xs font-medium text-red-dark  opacity-40 xs:w-full xs:justify-center'
                }
                disabled={!owner}
              >
                <Icons.Slash />
                <span className=" ml-1.5">Suspend all users</span>
              </button>
              <button
                className="btn-secondary-outline xs:w-full xs:justify-center"
                onClick={() => {
                  if (userLicenseData.availableActiveLicenses === 0 && userLicenseData.availableExpiringLiceses > 0) {
                    setConfirmModal(true);
                  } else {
                    setAddUserModal(true);
                  }
                }}
              >
                <Icons.Plus />
                <span className=" ml-1.5">Add User</span>
              </button>
            </div>
          </div>
        )}
        {addUserModal && (
          <AddUserModal
            setIsOpen={setAddUserModal}
            isOpen={addUserModal}
            userListReload={userListReload}
            setUserListReload={setUserListReload}
            isExpiringLicense={userLicenseData.availableActiveLicenses === 0 && userLicenseData.availableExpiringLiceses > 0}
            isTrialPeriod={isTrialPeriod}
          />
        )}
        {confirmModal && (
          <ConfirmationModal
            isConfirmModalOpen={confirmModal}
            setIsConfirmModalOpen={setConfirmModal}
            handleClose={() => setConfirmModal(false)}
            handleConfirm={() => setAddUserModal(true)}
            message={'This user is being added to an expiring license and will be removed at the end of the billing cycle.'}
            isOwner={false}
            showCancel
          />
        )}
        {additionalLicenseModal && (
          <AdditionalLicenseModal
            setIsOpen={setAdditionalLicenseModal}
            isOpen={additionalLicenseModal}
            userListReload={userListReload}
            setUserListReload={setUserListReload}
          />
        )}
        {removeLicenseModal && (
          <RemoveLicenseModal
            setIsOpen={setRemoveLicenseModal}
            isOpen={removeLicenseModal}
            userListReload={userListReload}
            setUserListReload={setUserListReload}
            userLicenseData={userLicenseData}
          />
        )}
        {subscriptionType !== subscriptionPlans.enterprise && (
          <div className="user-management-card-main flex !items-center justify-between gap-4 p-4.5 md:flex-col md:items-stretch md:gap-4">
            <div className="user-management-cards-section grid w-full grid-cols-4 gap-2  xs:grid-cols-1 xs:gap-2">
              <div className="license-card relative  w-full  cursor-pointer   rounded-md  border  border-solid border-gray-300 bg-white p-6 shadow-lg">
                <h2 className="mb-2 text-base font-medium text-gray-medium">Licenses</h2>
                <div className="flex flex-col items-start justify-between gap-[10px]">
                  <div className="flex items-center gap-[14px]">
                    <div className="user-management-icon user-license-icon flex h-10 w-10 items-center justify-center overflow-hidden rounded-full border border-[#c2dece] bg-[#EFFDF5]">
                      <Icons.LicenseIconNew />
                    </div>
                    <p className="text-3xl  text-black">{userLicenseData.totalLicenses ?? 0}</p>
                  </div>
                  <div className="contents-section">
                    <div className="flex w-auto flex-col justify-center gap-0 overflow-hidden">
                      <p className="flex items-center justify-between gap-2 rounded-md   px-1 text-[12px] font-medium text-white 2xl:text-[10px]">
                        <span className="mr-1 ">
                          <span className="text-base font-bold">{userLicenseData.base ?? 10}</span> Base Licenses
                        </span>
                      </p>
                      <p className="flex items-center justify-between rounded-md  py-[1px] px-1 text-[12px] font-medium text-white 2xl:text-[10px]">
                        <span className="mr-1">
                          <span className="text-base font-bold">{userLicenseData.additionalLicenses ?? 0}</span> Additional
                          Licenses
                        </span>
                      </p>
                      <p className="flex  items-center justify-between rounded-md  py-[1px] px-1 text-[12px] font-medium text-white 2xl:text-[10px]">
                        <span className="mr-1">
                          <span className="text-base font-bold">{userLicenseData.expiringLicenses ?? 0}</span> Expiring
                          Licenses
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="license-card relative  w-full  cursor-pointer   rounded-md  border  border-solid border-gray-300 bg-white p-6 shadow-lg">
                <h2 className="mb-2 text-base font-medium text-gray-medium">Available Licenses</h2>
                <div className="flex flex-col items-start justify-between gap-[10px]">
                  <div className="flex items-center gap-[14px]">
                    <div className="user-management-icon user-license-icon flex h-10 w-10 items-center justify-center overflow-hidden rounded-full border border-[#c2dece] bg-[#EFFDF5]">
                      <Icons.LicenseIconNew />
                    </div>
                    <p className="text-3xl font-normal text-black">{userLicenseData.availableLicenses ?? 0}</p>
                  </div>
                  {userLicenseData.availableLicenses > 0 ? (
                    <div className="contents-section">
                      <div className="flex w-auto flex-col justify-center gap-0 overflow-hidden">
                        <p className="flex items-center justify-between gap-2 rounded-md   px-1 text-[12px] font-medium text-white 2xl:text-[10px]">
                          <span className="mr-1  ">
                            <span className="text-base font-bold">{userLicenseData.availableBaseLicenses ?? 0}</span>{' '}
                            Available Base Licenses
                          </span>
                        </p>
                        <p className="flex items-center justify-between rounded-md  py-[1px] px-1 text-[12px] font-medium text-white 2xl:text-[10px]">
                          <span className="mr-1 ">
                            <span className="text-base font-bold">{userLicenseData.availableAdditionalLicenses ?? 0}</span>{' '}
                            Available Additional Licenses
                          </span>
                        </p>
                        <p className="flex  items-center justify-between rounded-md  py-[1px] px-1 text-[12px] font-medium text-white 2xl:text-[10px]">
                          <span className="mr-1 ">
                            <span className="text-base font-bold">{userLicenseData.availableExpiringLiceses ?? 0}</span>{' '}
                            Available Expiring Licenses
                          </span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="contents-section">
                      <p className="flex items-center justify-between rounded-md  py-[1px] px-1 text-[12px] font-medium text-white 2xl:text-[10px]">
                        <span className="ml-4 text-sm ">No Available Licenses</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="license-card n relative w-full cursor-pointer  rounded-md border  border-solid  border-gray-300  bg-white p-6 shadow-lg ">
                <h2 className="mb-2 text-base font-medium text-gray-medium">Expiring Licenses</h2>
                <div className="flex flex-col items-start justify-between gap-[10px]">
                  <div className="flex items-center gap-[14px]">
                    <div className="user-management-icon  user-license-expiry-icon  flex h-10 w-10 items-center justify-center overflow-hidden rounded-full border border-[#f0e7cc] bg-[#FFFAEB]">
                      <Icons.LicenseExpiryIconNew />
                    </div>
                    <p className="text-3xl  text-black">{userLicenseData.expiringLicenses ?? 0}</p>
                  </div>
                  {userLicenseData.expiringLicenses > 0 ? (
                    <div className="contents-section">
                      <div className="flex w-auto flex-col justify-center gap-0 overflow-hidden">
                        <p className="flex items-center justify-between rounded-md  py-[1px] px-1 text-[12px] font-medium text-white 2xl:text-[10px]">
                          <span className="mr-1">
                            <span className="text-base font-bold">{userLicenseData.availableExpiringLiceses ?? 0}</span>{' '}
                            Available Expiring Licenses
                          </span>
                        </p>
                        <p className="flex items-center justify-between rounded-md  py-[1px] px-1 text-[12px] font-medium text-white 2xl:text-[10px]">
                          <span className="mr-1">
                            <span className="text-base font-bold">{userLicenseData.usedExpiringLicenses ?? 0}</span> Used
                            Expiring Licenses
                          </span>
                        </p>

                        <p className="flex items-center rounded-md  py-[1px] px-1 text-[12px] font-medium text-white 2xl:text-[10px]">
                          Expires on <span className="ml-1">{formatDate(userLicenseData.expiryDate)}</span>
                        </p>
                      </div>
                    </div>
                  ) : (
                    <div className="contents-section">
                      <p className="flex items-center justify-between rounded-md  py-[1px] px-1 text-[12px] font-medium text-white 2xl:text-[10px]">
                        <span className="ml-6 text-sm ">No Expiring Licenses</span>
                      </p>
                    </div>
                  )}
                </div>
              </div>
              <div className="shadow-100 license-card relative w-full  cursor-pointer rounded-md  border  border-solid border-gray-300   bg-white p-6 shadow-lg">
                <h2 className="mb-2 text-base font-medium text-gray-medium">Users</h2>
                <div className="flex flex-col items-start justify-between gap-[10px]">
                  <div className="flex items-center gap-[14px]">
                    <div className="user-management-icon flex h-10 w-10 items-center justify-center overflow-hidden rounded-full border border-[#c9cfe1] bg-[#F4F6FA] text-[#1d337c]">
                      <Icons.usersIconNew />
                    </div>
                    <p className="text-3xl  text-black">{userLicenseData.licensesUsed ?? 0}</p>
                  </div>
                  <div className="contents-section ">
                    <div className="flex w-auto flex-col justify-center gap-0 overflow-hidden">
                      <p className="flex items-center justify-between rounded-md  py-[1px] px-1 text-[12px] font-medium text-white 2xl:text-[10px]">
                        <span className="mr-1">
                          <span className="text-base font-bold">{userLicenseData.activeUsers ?? 0} </span>
                          Active Users
                        </span>
                      </p>
                      <p className="flex items-center justify-between rounded-md  py-[1px] px-1 text-[12px] font-medium text-white 2xl:text-[10px]">
                        <span className="mr-1">
                          <span className="text-base font-bold">{userLicenseData.scheduledRemovalUsers ?? 0}</span>
                          {` 'Remove at next billing' Users`}
                        </span>
                      </p>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {owner && (
              <div className="user-management-buttons !mt-0 flex flex-[160px] flex-shrink-0 flex-grow-0 flex-col items-center justify-center gap-4 md:flex-[unset] md:flex-row lg:flex-1 lg:whitespace-nowrap xs:flex-col">
                {isTrialPeriod ? (
                  <Tippy
                    content="You will be able to purchase additional licenses once your free trial is over."
                    placement="bottom"
                    arrow={true}
                  >
                    <button
                      className={`btn-secondary-outline additional-license-button w-full cursor-not-allowed justify-center opacity-70 xs:w-full xs:justify-center`}
                      onClick={() => {}}
                    >
                      <Icons.User />
                      <span className=" ml-1.5">Purchase license</span>
                    </button>
                  </Tippy>
                ) : (
                  <button
                    className={`btn-secondary-outline additional-license-button w-full justify-center xs:w-full xs:justify-center ${
                      userLicenseData.additionalLicenses === 100 || subDataloading ? 'cursor-not-allowed opacity-70' : ''
                    }`}
                    onClick={() => {
                      if (!subDataloading) {
                        setAdditionalLicenseModal(true);
                      }
                    }}
                    disabled={userLicenseData.additionalLicenses === 100 || subDataloading}
                  >
                    <Icons.User />
                    <span className=" ml-1.5">Purchase license</span>
                  </button>
                )}
                <button
                  className={`flex h-7.5 w-full  items-center justify-center rounded-full border border-red-dark bg-red-light px-3.5 text-xs font-medium text-red-dark  hover:bg-red-dark hover:text-white xs:w-full xs:justify-center ${
                    userLicenseData.removableLicenses === 0 || userLicenseData.removableLicenses === null
                      ? 'cursor-not-allowed opacity-70'
                      : ''
                  }`}
                  onClick={() => setRemoveLicenseModal(true)}
                  disabled={userLicenseData.removableLicenses === 0 || userLicenseData.removableLicenses === null}
                >
                  <Icons.TrashIcon />
                  <span className=" ml-1.5">Remove license</span>
                </button>
              </div>
            )}
          </div>
        )}
        <UserList
          setCount={setCount}
          addUserModal={addUserModal}
          userListReload={userListReload}
          setUserListReload={setUserListReload}
          onLoad={value => {
            if (!additionalSeatPurchase) {
              setIsLoading(value);
            }
          }}
          userLicenseData={userLicenseData}
          isTrialPeriod={isTrialPeriod}
        />
      </div>
    </CommonDashboard>
  );
};
export default UserManagement;
