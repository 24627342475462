/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from 'react';
import Tippy from '@tippyjs/react';
import Icons from '../../assets/Icons';
import CryptoJS from 'crypto-js';
import { useLocation } from 'react-router-dom';
import classes from './billing.module.scss';
// import Select from "react-select";
// import Icons from "../../assets/Icons";
import {
  getSubscriptionDetails,
  cancelSubscription,
  createCheckoutSession,
  updatePaymentDetails,
  getInvoiceDetails,
} from '../../services/paymentService';
import VisaCard from '../../assets/images/visacard.jpg';
import CreditCard from '../../assets/images/credit_card_icon.svg';
import Mastercard from '../../assets/images/mastercard.jpg';
import { useToastBox } from '../../customHooks/useToastBox';
import ChannelList from '../../components/ChannelSetup';
import { cardSaved, subscriptionsInvoices } from './constants';
import CommonDashboard from '../../components/CommonDashboard';
import Loader from '../../components/Loader';
import { AlertModal } from '../../components/Modal/AlertModal';
import NoData from '../../components/Nodata';
import { getTrailEndDate, getUpcomingMonth } from '../../utils/helpers';
import useAuth from '../../customHooks/useAuth';
import { LicenseUpgradeModal } from '../../components/Modal/LicenseUpgradeModal';
import { getUserInfo } from '../../utils/getUserInfo';
import { subscriptionPlans } from '../../constants/constants';
const Billing = () => {
  const { logout } = useAuth();
  const userInfo = JSON.parse(getUserInfo());
  sessionStorage.setItem('page', 'Billing');
  const search = useLocation().search;
  let subscriptionType;
  const subscriptionCode = userInfo?.user?.subscriptionType;
  if (subscriptionCode) {
    subscriptionType = CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8);
  }
  const sessionId = new URLSearchParams(search).get('session_id');
  const update = new URLSearchParams(search).get('update');
  const invoice = new URLSearchParams(search).get('invoice');
  const { handleSuccessToast, handleErrorToast } = useToastBox();
  const tenantId = userInfo?.user?.tenantId;
  const [loading, setIsLoading] = useState(true);
  const [isOpen, setIsOpen] = useState(false);
  const [subscriptionData, setSubscriptionData] = useState(null);
  const [invoiceData, setInvoiceData] = useState([]);
  const [paymentInfoEdit, setPaymentInfoEdit] = useState(false);
  const [billingInfoEdit, setBillingInfoEdit] = useState(false);
  const [commonEditInfo, setCommonEditInfo] = useState(false);
  const [licenseUpgradeModal, setLicenseUpgradeModal] = useState(false);
  const [refresh, setRefresh] = useState(false);
  //sample boolean for preview purposes,on mobile if any one of them are in edit mode change commoneditinfo to true
  const switchCommonInfoEditMode = status => {
    setPaymentInfoEdit(status);
    setBillingInfoEdit(status);
    setCommonEditInfo(status);
  };

  const currencyFinder = (code, rate) => {
    switch (code) {
      case 'usd':
        return `$${rate}`;
      case 'gbp':
        return `£${rate}`;
      default:
        return `${rate} ${code?.toUpperCase()}`;
    }
  };

  const getSubscriptionData = type => {
    setIsLoading(true);
    getSubscriptionDetails(tenantId)
      .then(response => {
        getInvoiceDetails(tenantId)
          .then(response => {
            setIsLoading(false);
            setInvoiceData(response?.data?.data);
          })
          .catch(error => {
            setIsLoading(false);
            handleErrorToast('Could not fetch subscription details');
          });
        if (Object.keys(response?.data?.data).length === 0) {
          setSubscriptionData(null);
        } else {
          setSubscriptionData(response?.data?.data);
          setRefresh(!refresh);
        }

        if (type == 'update') {
          handleSuccessToast('Payment details updated successfully');
        }
        if (type == 'cancel') {
          handleSuccessToast('Subscription cancelled successfully');
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.log('error', error);
        handleErrorToast('Could not fetch subscription details');
      });
  };

  const formatDate = endDate => {
    if (endDate) {
      const date = new Date(endDate);
      const formattedDate = date.toLocaleDateString('en-US', {
        year: 'numeric',
        month: 'long',
        day: 'numeric',
      });

      return formattedDate;
    } else {
      const notVerifiedDate = ' -- ';
      return notVerifiedDate;
    }
  };

  const getCountryName = code => {
    const countryName = new Intl.DisplayNames(['en'], { type: 'region' }).of(code);
    return countryName;
  };

  const handleCancel = () => {
    setIsLoading(true);
    cancelSubscription(tenantId, subscriptionData?.currentSubscription?.isTrial)
      .then(response => {
        if (response?.status === 201 || response?.status === 200) {
          if (subscriptionData?.currentSubscription?.isTrial) {
            handleSuccessToast('Subscription cancelled successfully');
            setTimeout(() => {
              logout();
            }, 1500);
          } else {
            getSubscriptionData('cancel');
          }
        }
      })
      .catch(error => {
        setIsLoading(false);
        console.log('error', error);
        handleErrorToast('something went wrong');
      });
  };

  const handlePaymentUpdate = () => {
    setIsLoading(true);
    let plan = { subscriptionType: subscriptionType, tenantId: tenantId, update: true };
    createCheckoutSession(plan)
      .then(response => {
        if (response?.status === 201 || response?.status === 200) {
          window.location.href = response?.data?.data?.checkoutUrl;
        }
      })
      .catch(err => {
        handleErrorToast(err.message);
        setIsLoading(false);
      });
  };

  const updatePaymentInfo = () => {
    setIsLoading(true);
    updatePaymentDetails(sessionId, tenantId, update, invoice)
      .then(response => {
        if (response?.status === 201 || response?.status === 200) {
          getSubscriptionData('update');
        }
      })
      .catch(err => {
        handleErrorToast(err.message);
        setIsLoading(false);
      });
  };

  useEffect(() => {
    if (sessionId !== undefined && sessionId !== null) {
      updatePaymentInfo();
    }
  }, [sessionId]);

  useEffect(() => {
    getSubscriptionData();
  }, []);

  const TooltipMessage = () => {
    return (
      <div className="max-w-[325px]">
        <div className=" flex items-center">
          <div className="flex flex-col pl-2">
            <ul className=" list-disc pl-4">
              <li className=" mb-1 text-xs font-normal leading-normal text-white">
                When you add or remove licenses in the middle of your billing cycle, Hydra automatically calculates and
                adjusts your charges.
              </li>
              <li className=" mb-1 text-xs font-normal leading-normal text-white">
                You will only be billed for the portion of the service you have used, and any remaining credit will be
                reflected in your next invoice.
              </li>
              <li className=" mb-1 text-xs font-normal leading-normal text-white">Questions? Refer to our FAQ.</li>
            </ul>
          </div>
        </div>
      </div>
    );
  };

  const PaymentInfoCard = ({ classes, element, editButton, subscriptionData }) => {
    return (
      <div className={classes.card}>
        <div className={classes.payment}>
          <div className={classes.paymentWrapper}>
            <div className={classes.cardDetails}>
              <p className={classes.clientName}>{subscriptionData?.userInfo?.name}</p>
              <p className={classes.cardNumber}>
                {subscriptionData?.paymentInfo?.brand?.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())} ending in{' '}
                {subscriptionData?.paymentInfo?.last4}
              </p>
            </div>

            {paymentInfoEdit && (
              <div className={classes.editCard}>
                {!element.default && (
                  <button className={`btn-link ${classes.plainBtn} ${classes.editCardItem}`} aria-label="Set as default">
                    Set as default
                  </button>
                )}

                <button className={`btn-link ${classes.plainBtn}  ${classes.editCardItem}`} aria-label="Set as default">
                  Delete
                </button>
                {element.default && (
                  <p className={` ${classes.editCardItem} ${classes.defaulted}`}>Default Payment Method</p>
                )}
              </div>
            )}
          </div>
          <div className={classes.cardType}>
            <img
              src={
                subscriptionData?.paymentInfo?.brand == 'visa'
                  ? VisaCard
                  : subscriptionData?.paymentInfo?.brand == 'mastercard'
                  ? Mastercard
                  : CreditCard
              }
              alt="Card Logo"
            />
          </div>
        </div>
        {/* {editButton && (
          <>
            <div className={`${classes.coreBtnWrapper} ${classes.coreMv}`}>
              <button aria-label="Edit" className={`btn-outline`} onClick={() => switchCommonInfoEditMode(true)}>
                {paymentInfoEdit ? <span>Save</span> : <span>Edit</span>}
              </button>
              <button aria-label="Edit" className={`btn-outline`}>
                <span>New Payment Method</span>
              </button>
            </div>
          </>
        )} */}
      </div>
    );
  };
  return loading ? (
    <Loader />
  ) : (
    <CommonDashboard classList={classes.messagingWrapper} hideHeader={false}>
      <span className={classes.dvOnly}>
        <ChannelList shouldAuthenticate={false} onLoad={() => {}} isLoading={false} />
      </span>

      <div className={classes.coreConsole}>
        <div className={classes.coreHeader}>
          <h3>Billing & Subscription Settings</h3>
          {/* <div className={`${classes.coreButton} ${commonEditInfo ? classes.hideMobileBtn : ''}`}>
            <button
              aria-label="Transfer Admin Control"
              className={`btn-outline ${classes.viewAll} ${commonEditInfo ? classes.hideDvBtn : ''}`}
              // onClick={() => setCustomModalOpen(true)}
            >
              <span>Transfer Admin Control</span>
            </button>
          </div> */}
          {commonEditInfo && (
            <div className={`${classes.coreButton} ${commonEditInfo ? classes.hideDvBtn : ''}`}>
              <button
                aria-label="Edit"
                className={`${classes.viewAll} ${commonEditInfo ? 'btn-primary-1' : 'btn-outline'}`}
                onClick={() => switchCommonInfoEditMode(!commonEditInfo)}
              >
                {commonEditInfo ? <span>Save</span> : <span>Edit</span>}
              </button>
            </div>
          )}
        </div>
        {subscriptionData !== null ? (
          <div className={classes.coreCard}>
            <div className={`${classes.card} ${classes.coreSplit}`}>
              <div className={classes.personalDetails}>
                <div className={classes.coreCardWrapper}>
                  <div className={classes.coreDTitle}>
                    <h3 className={classes.coreTitle}>Current Subscription</h3>
                  </div>
                  <div className={classes.coreDetails}>
                    <div
                      className={`${classes.card} ${classes.subscription} ${
                        subscriptionType === subscriptionPlans.individual ? classes.individualsCard : ''
                      }`}
                    >
                      <div className={classes.cardSubLeft}>
                        <div className={classes.cardSubTitle}>
                          {subscriptionData?.currentSubscription?.type?.toLowerCase().replace(/\b\w/g, s => s.toUpperCase())}
                        </div>
                        {!subscriptionData?.currentSubscription?.isTrial ? (
                          <div className={classes.cardSubExp}>
                            {subscriptionData?.currentSubscription?.cancelAt !== null
                              ? `Your license will get cancelled on  ${formatDate(
                                  subscriptionData?.currentSubscription?.cancelAt
                                )}`
                              : `Your license will auto-renew on ${formatDate(
                                  subscriptionData?.currentSubscription?.currentPeriodEnd
                                )}`}
                          </div>
                        ) : null}
                        {subscriptionData?.currentSubscription?.isTrial ? (
                          <div className={classes.cardSubExp}>
                            {getTrailEndDate(subscriptionData?.currentSubscription?.trialEnd)}
                          </div>
                        ) : null}
                      </div>
                      <div
                        className={`${classes.cardSubRight} ${
                          subscriptionType === subscriptionPlans.individual ? classes.individualsCardSubRight : ''
                        }`}
                      >
                        {subscriptionData?.currentSubscription?.cancelAt == null && (
                          <button
                            aria-label="Renew Now"
                            className={`flexCenter min-h-[30px] rounded-full border border-red-dark bg-red-light px-3.5 py-1 text-xs font-medium text-red-dark  hover:bg-red-dark hover:text-white xs:w-full xs:justify-center`}
                            onClick={() => setIsOpen(true)}
                          >
                            <span>Cancel Subscription</span>
                          </button>
                        )}
                        {subscriptionType === subscriptionPlans.individual ? (
                          <button
                            aria-label="Renew Now"
                            className={`btn-primary-1 mt-2 justify-center`}
                            onClick={() => setLicenseUpgradeModal(true)}
                          >
                            <span>Upgrade to Teams</span>
                          </button>
                        ) : null}
                      </div>
                    </div>
                  </div>
                </div>
                <div className={classes.coreCardWrapper}>
                  <div className={classes.coreDTitle}>
                    <h3 className={classes.coreTitle}>Billing Address</h3>
                    {/* <div className={`${classes.coreBtnWrapper} ${classes.coreDv}`}>
                    <button aria-label="Edit" className={`btn-outline`} onClick={() => setBillingInfoEdit(!billingInfoEdit)}>
                      {billingInfoEdit ? <span>Save</span> : <span>Edit</span>}
                    </button>
                  </div> */}
                  </div>
                  <div className={`${classes.coreDetails} ${billingInfoEdit ? classes.editModeOn : ''}`}>
                    {billingInfoEdit ? (
                      <div className={classes.addressWrapper}>
                        <div className={classes.addRow}>
                          <div className={`${classes.formControl}`}>
                            <label className={classes.formControlLabel}>First Name</label>
                            <input className={classes.formInput} type={'text'} placeholder="First Name" />
                          </div>
                          <div className={`${classes.formControl}`}>
                            <label className={classes.formControlLabel}>Last Name</label>
                            <input className={classes.formInput} type={'text'} placeholder="Last Name" />
                          </div>
                        </div>
                        <div className={classes.addRow}>
                          <div className={`${classes.formControl}`}>
                            <label className={classes.formControlLabel}>Address Line 1</label>
                            <textarea className={classes.formInput} type={'text'} placeholder="Address Line 1" />
                          </div>
                        </div>
                        <div className={classes.addRow}>
                          <div className={`${classes.formControl}`}>
                            <label className={classes.formControlLabel}>Address Line 2</label>
                            <textarea className={classes.formInput} type={'text'} placeholder="Address Line 2" />
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div className={classes.card}>
                        <div className={classes.address}>
                          {subscriptionData?.billingAddress?.line1 && <p>{subscriptionData?.billingAddress?.line1}</p>}
                          {subscriptionData?.billingAddress?.line2 && <p>{subscriptionData?.billingAddress?.line2}</p>}
                          {subscriptionData?.billingAddress?.city && <p>{subscriptionData?.billingAddress?.city}</p>}
                          {subscriptionData?.billingAddress?.state && <p>{subscriptionData?.billingAddress?.state}</p>}
                          {subscriptionData?.billingAddress?.country && (
                            <p>{getCountryName(subscriptionData?.billingAddress?.country)}</p>
                          )}
                          {subscriptionData?.billingAddress?.postal_code && (
                            <p>{subscriptionData?.billingAddress?.postal_code}</p>
                          )}
                        </div>

                        {/* <div className={`${classes.coreBtnWrapper} ${classes.coreMv}`}>
                          <button aria-label="Edit" className={`btn-outline`} onClick={() => switchCommonInfoEditMode(true)}>
                            {billingInfoEdit ? <span>Save</span> : <span>Edit</span>}
                          </button>
                        </div> */}
                      </div>
                    )}
                  </div>
                </div>
                <div className={classes.coreCardWrapper}>
                  <div className={classes.coreDTitle}>
                    <h3 className={classes.coreTitle}>Payment Info</h3>
                    <div className={`${classes.coreBtnWrapper}`}>
                      {/* <button aria-label="Edit" className={`btn-outline`} onClick={() => setPaymentInfoEdit(!paymentInfoEdit)}>
                      {paymentInfoEdit ? <span>Save</span> : <span>Edit</span>}
                    </button> */}
                      <button aria-label="Edit" className={`btn-outline`} onClick={() => handlePaymentUpdate()}>
                        <span>Update Payment Method</span>
                      </button>
                    </div>
                  </div>
                  <div className={`${classes.coreDetails} ${paymentInfoEdit ? classes.editModeOn : ''}`}>
                    {cardSaved?.length !== 0
                      ? cardSaved?.map((element, index) => {
                          if (!paymentInfoEdit) {
                            return (
                              element.default && (
                                <PaymentInfoCard
                                  element={element}
                                  classes={classes}
                                  index={index}
                                  editButton={true}
                                  key={`cardSaved__${index}`}
                                  subscriptionData={subscriptionData}
                                />
                              )
                            );
                          } else {
                            return (
                              <PaymentInfoCard
                                element={element}
                                classes={classes}
                                index={index}
                                key={`cardSaved__${index}`}
                                subscriptionData={subscriptionData}
                              />
                            );
                          }
                        })
                      : ''}
                  </div>
                </div>
                {subscriptionType === subscriptionPlans.team || subscriptionType === subscriptionPlans.annual ? (
                  <>
                    <h3 className={classes.coreTitle}>Next Invoice Details</h3>
                    <div className={` ${classes.card} ${classes.upcomingSubInvoiceCard}`}>
                      <div className={classes.subHeader}></div>
                      <div className={classes.subBody}>
                        <div className={classes.subRow}>
                          <div className={classes.subCap}>Teams Plan Cost</div>
                          <div className={classes.subValue}>£{subscriptionData?.nextPaymentDetails?.teamsPlan ?? 0}</div>
                        </div>

                        <div className={classes.subRow}>
                          <div className={classes.subCap}>Additional Licenses Cost</div>
                          <div className={classes.subValue}>
                            £{subscriptionData?.nextPaymentDetails?.additionalCost ?? 0}
                          </div>
                        </div>

                        <div className={classes.subRow}>
                          <div className={classes.subTotal}>Total Cost</div>
                          <div className={classes.subValue}>£{subscriptionData?.nextPaymentDetails?.totalCost ?? 0}</div>
                        </div>
                      </div>
                    </div>
                  </>
                ) : null}
              </div>
              <div className={classes.invoicesDetails}>
                <h3 className={classes.coreTitle}>Subscription Invoices</h3>
                {invoiceData?.invoices?.length !== 0 ? (
                  invoiceData?.invoices?.map((element, index) => {
                    return (
                      <div className={` ${classes.card} ${classes.subInvoiceCard}`} key={`subscription__${index}`}>
                        <div className={classes.subHeader}>
                          <h5 className={classes.subTitle}>{element.planName}</h5>
                          <div className={classes.btnWrapper}>
                            {/* <button
                                className={`btn-link ${classes.subInvoicebtn}`}
                                aria-label="Download Invoice"
                                onClick={() => {}}
                              >
                                Download Invoice
                              </button> */}
                            <a
                              id="download-link"
                              className={`btn-link ${classes.subInvoicebtn}`}
                              href={element?.invoicePdf}
                              download
                            >
                              Download Invoice
                            </a>
                          </div>
                        </div>
                        <div className={classes.subBody}>
                          <div className={classes.subRow}>
                            <div className={classes.subCap}>Total</div>
                            <div className={`${classes.subValue} ${classes.price}`}>
                              {currencyFinder(element.currency, element.amountPaid)}
                            </div>
                          </div>
                          <div className={classes.subRow}>
                            <div className={classes.subCap}> Paid on</div>
                            <div className={`${classes.subValue} ${classes.date}`}>{formatDate(element.paidDate)}</div>
                          </div>
                          <div className={classes.subRow}>
                            <div className={classes.subCap}>Paid using</div>
                            <div className={`${classes.subValue} ${classes.cardPaid}`}>
                              <span className={classes.cardLogo}>
                                <img
                                  src={
                                    element?.cardType == 'visa'
                                      ? VisaCard
                                      : element?.cardType == 'mastercard'
                                      ? Mastercard
                                      : CreditCard
                                  }
                                  alt="Card company logo"
                                />
                              </span>
                              <span className={classes.cardNumber}>{`**** **** **** ${element.cardNumber}`}</span>
                            </div>
                          </div>
                        </div>
                      </div>
                    );
                  })
                ) : (
                  <NoData message={'No data Found!'} showImage={false} isLinkPresent={false} />
                )}
              </div>
            </div>
          </div>
        ) : !loading ? (
          <NoData message={'No data Found'} showImage={true} isLinkPresent={false} />
        ) : null}
      </div>
      <AlertModal
        isOpen={isOpen}
        setIsOpen={setIsOpen}
        handleConfirm={() => handleCancel()}
        heading={
          subscriptionData?.currentSubscription?.isTrial
            ? `Are you certain about canceling your free trial?`
            : 'Are you certain about canceling your subscription?'
        }
        message={
          subscriptionType === subscriptionPlans.individual
            ? `We're sad to see you go, but you can always resubscribe by logging into Hydra with your credentials.`
            : subscriptionData?.currentSubscription?.isTrial
            ? 'Hydra will erase all user data after 7 days. If you wish to keep it, consider resubscribing before then.'
            : 'Hydra will erase all user data 7 days after the current billing cycle. If you wish to keep it, consider resubscribing before then.'
        }
      />
      <LicenseUpgradeModal isOpen={licenseUpgradeModal} setIsOpen={setLicenseUpgradeModal} />
    </CommonDashboard>
  );
};
export default Billing;
