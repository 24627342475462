import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { CookiesProvider } from 'react-cookie';
import SocketProvider from './context/socketIoContext/socketProvider';
import { Router } from './router';
import AuthProvider from './context/auth/Provider';
import reportWebVitals from './reportWebVitals';
import 'animate.css';
import './index.css';
import './assets/css/main.scss';
import 'tippy.js/dist/tippy.css';
import 'react-toastify/dist/ReactToastify.css';
const root = ReactDOM.createRoot(document.getElementById('root'));
//Router
root.render(
  <BrowserRouter>
    <CookiesProvider>
    <SocketProvider>
      <AuthProvider>
          <Router />
      </AuthProvider>
      </SocketProvider>
    </CookiesProvider>
  </BrowserRouter>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
