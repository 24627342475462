import React, { useEffect, useState } from 'react';
import { useLocation, useSearchParams } from 'react-router-dom';
import { SignUpModal } from '../../components/Modal/SignUpModal';
import Icons from '../../assets/Icons';
import Header from '../../components/Header';
import Dashboard from '../../assets/images/Dashboard.svg';
import PricingPlans from '../../components/PricingPlans';
import { verifyInvitationLink } from '../../services/signUpService';
import { useToastBox } from '../../customHooks/useToastBox';
import Footer from '../../components/Footer';

const Landing = () => {
  const location = useLocation();
  const externalSignup = location.pathname == '/signup' ? true : false;
  const [isSignupModalOpen, setIsSignupModalOpen] = useState(externalSignup ? true : false);
  const [selectedPlan, setSelectedPlan] = useState({ type: '', isTrial: false, annual: false });
  const [isLoginModalOpen, setIsLoginModalOpen] = useState(location?.state?.fromPayment ? true : false);
  const [emailId, setEmailId] = useState('');
  const [tenantId, setTenantId] = useState('');
  const [invitationDetails, setInvitationDetails] = useState({});
  const [normalSignup, setNormalSignup] = useState(false);

  // eslint-disable-next-line
  const [searchParams] = useSearchParams();
  const { handleSuccessToast, handleErrorToast } = useToastBox();

  const handleSignupClick = () => {
    setIsSignupModalOpen(!isSignupModalOpen);
    setSelectedPlan({ type: '', isTrial: false, annual: false });
    if (window.location.hostname === 'www.hihydra.com') {
      window.dataLayer.push({ event: 'signupModalOpened' });
    }
  };

  const handlePlanSelection = (type, isTrial, annual) => {
    setSelectedPlan({ type: type, isTrial: isTrial, annual: annual });
    setIsSignupModalOpen(!isSignupModalOpen);
  };

  useEffect(() => {
    if (location.pathname === '/invitation') {
      if (searchParams.get('code') && searchParams.get('token')) {
        verifyInvitationLink(searchParams.get('code'), searchParams.get('token'))
          .then(res => {
            //For signup testing
            if (res?.data?.data?.workspaceId) {
              let invitaionData = {
                type: res.data.data.type,
                workspaceId: res.data.data.workspaceId,
                invitationId: res.data.data.id,
              };
              setInvitationDetails(invitaionData);
            }
            handleSuccessToast('Link verified successfully');
            setEmailId(res.data.data.email);
            setTenantId(res.data.data.tenantId);
            setIsSignupModalOpen(true);
          })
          .catch(err => {
            handleErrorToast(err.message);
          });
      }
    } else {
      setNormalSignup(true);
    }
  }, []);

  return (
    <main>
      <Header
        setIsSignupModalOpen={setIsSignupModalOpen}
        isLoginModalOpen={isLoginModalOpen}
        setIsLoginModalOpen={setIsLoginModalOpen}
      />

      <section className="home relative z-0 overflow-hidden bg-gradient-to-r from-primaryMedium to-primaryDark pt-41 pb-[378px] sm:pb-35 md:pt-28">
        <div className="relative z-10 mx-auto max-w-7xl px-5">
          <h1 className="animate__animated animate__fadeInUp delay1 w-full text-center text-4.4xl font-bold leading-14.5 text-white md:px-[10%]  md:text-1xl  md:leading-8">
            Visibility, Security, Control
            {/* <span className="md:hidden">
              <br></br>
            </span>{' '}
            your social media visibility,security
            <span className="md:hidden">
              <br></br>
            </span>
            and controls? */}
          </h1>

          <p className="animate__animated animate__fadeInUp delay2 mt-4 w-full text-center text-lg text-white100 md:mt-2 md:text-sm md:leading-normal">
            Manage user permissions across major social channels and ad managers from a single platform
            <span className="hidden h-0 md:block">
              <br />
            </span>{' '}
            {/* A genie who can do it all. */}
          </p>
          <div className="animate__animated animate__fadeInUp delay3 flex items-center justify-center space-x-4 pt-8 md:space-x-2 md:pt-6">
            {/* <button className="btn-primary-outline" data-testid="demo-button">
              Book a Demo
            </button> */}
            <a className="btn-primary-outline" href="https://calendly.com/hydra-jt/" target="_blank" rel="noreferrer">
              <span>Book a Demo</span>
            </a>
            <button className="btn-primary" onClick={() => handleSignupClick()} data-testid="signup-button">
              Sign Up
            </button>
          </div>
          <div className="rotating absolute -top-[53px] left-12.5 h-[63px] w-[63px] md:hidden">
            <Icons.Shape2 />
          </div>
          <div className="rotating absolute -top-[38px] left-6 hidden h-[38px] w-[38px] md:block">
            <Icons.Shape2Small />
          </div>
        </div>

        <div className="rotating absolute -top-11 -right-16 h-[350px] w-[350px] text-gray-dark6 md:hidden">
          <Icons.Shape1 />
        </div>
        <div className=" rotating absolute -top-15 -right-20 hidden h-[240px] w-[240px] text-gray-dark6 md:block">
          <Icons.Shape1Small />
        </div>
        <div className="rotating absolute bottom-0 -left-[232px] h-[350px] w-[350px] text-gray-medium1 md:hidden">
          <Icons.Shape1 />
        </div>
        <div className=" rotating absolute -bottom-15 -left-30 hidden h-[240px] w-[240px] text-gray-medium1 md:block">
          <Icons.Shape1Small />
        </div>
      </section>

      <section className=" relative bottom-0 flex w-full justify-center ">
        <div className="relative mx-auto max-w-7xl">
          <div className="rotating absolute -top-[356px] left-1 h-[47px] w-[47px] md:hidden">
            <Icons.Shape3 />
          </div>
          <div className="rotating absolute -top-[336px] right-1 h-[119px] w-[123px] md:hidden">
            <Icons.Shape4 />
          </div>
          <div className="rotating absolute -top-17 -right-[8px] hidden h-[45px] w-[45px] md:block">
            <Icons.Shape4Small />
          </div>
          <img
            className="animate__animated animate__fadeInUp delay4 relative z-[1px] -mt-[350px] sm:-mt-[110px]"
            src={Dashboard}
            alt="Dashboard"
          />
        </div>
      </section>

      <section className="home overflow-x-hidden bg-gradient-to-b from-white to-gray-light3 pt-25  pb-[120px] md:pt-[50px] md:pb-16.5">
        <div className="animate__animated animate__fadeInUp delay01 relative mx-auto max-w-7xl px-5">
          <h1 className=" w-full text-center text-3.1xl font-semibold leading-[2.6875rem] text-gray-dark md:text-1xl">
            {/* Tailor-made features */}
            Built for digital teams in brands and agencies
          </h1>
          <p className="mt-3 mb-7.5 w-full text-center text-sm leading-[1.3125rem] text-gray-medium md:hidden">
            {/* Lorem ipsum is common placeholder text used to demonstrate the
            <br /> graphic elements of a document or visual presentation. */}
          </p>
        </div>
        <div className="w-full md:mt-8">
          <div className="mx-auto flex max-w-7xl justify-between px-5">
            <div className="tailorMadeFeatures grid w-full grid-cols-3 md:grid-cols-1 md:gap-6">
              <div className="card w-full text-center">
                <span className="flex justify-center">
                  <Icons.RobustWorkflow />
                </span>
                <h3 className=" mb-2.5 mt-6 text-xl font-medium leading-7.5 text-gray-dark md:mt-2 md:text-base">
                  Visibility
                </h3>
                <p className="px-[15%] text-sm leading-[1.3125rem] text-gray-medium md:px-0 md:text-xs">
                  Monitor the pages, profiles and ad accounts where you have admin access
                </p>
              </div>
              <div className="card w-full text-center ">
                <span className="flex justify-center">
                  <Icons.Flexibility />
                </span>
                <h3 className=" mb-2.5 mt-6 text-xl font-medium leading-7.5 text-gray-dark">Security</h3>
                <p className="px-[15%] text-sm leading-[1.3125rem] text-gray-medium md:px-0">
                  Monitor who else has access to your digital ecosystem and track changes
                </p>
              </div>
              <div className="card w-full text-center ">
                <span className="flex justify-center">
                  <Icons.UserFriendly />
                </span>
                <h3 className=" mb-2.5 mt-6 text-xl font-medium leading-7.5 text-gray-dark">Control</h3>
                <p className="px-[15%] text-sm leading-[1.3125rem] text-gray-medium md:px-0">
                  Edit permissions and remove users from the major social channels and ad managers
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className=" relative overflow-hidden bg-yellow-light1 pt-28 pb-22 md:pt-10 md:pb-5">
        <div className="animate__animated animate__fadeInUp delay01 mx-auto max-w-7xl px-5">
          <div className="relative z-10">
            <h1 className=" w-full text-center text-3.1xl font-semibold leading-[2.6875rem] text-gray-dark md:text-1xl">
              Select Your Plan Below
            </h1>
            <p className="mt-3 mb-16 w-full text-center text-sm leading-[1.3125rem] text-gray-medium md:mb-6.5 md:text-sm">
              Simple, transparent pricing for individuals, teams and enterprises of any size
              {/* <span className="md:hidden">
                <br></br>
              </span>{' '}
              Try any plan for 30 days. */}
            </p>
            <PricingPlans onSelect={(type, isTrial, annualPlan) => handlePlanSelection(type, isTrial, annualPlan)} />
          </div>
          <div className="rotating absolute top-25 right-40 h-[360px] w-[360px] md:hidden">
            <Icons.LinearGradientShape />
          </div>
          <div className="rotating absolute -bottom-5 left-40 h-[320px] w-[320px] md:hidden">
            <Icons.LinearGradientShape1 />
          </div>
          <div className="rotating absolute top-52 left-20 h-8 w-8  md:hidden">
            <Icons.LinearGradientShapeSmall />
          </div>
          <div className="rotating absolute top-0 -left-10 hidden  h-[200px] w-[200px] md:block">
            <Icons.LinearGradientShapeMobile />
          </div>
        </div>
      </section>

      <section className="bg-white pt-28 md:pt-5">
        <div className="animate__animated animate__fadeInUp delay01 mx-auto flex max-w-7xl flex-col items-center justify-end px-[12.375rem] md:justify-center md:px-5 ">
          <div className=" mb-[-3.125rem] flex w-full flex-col items-center justify-center rounded-[2rem] border border-solid border-primary200 bg-white p-12.5 shadow-helpCard md:mb-7.5 md:p-4 md:shadow-helpCardMD">
            <h2 className="mb-4 truncate text-3.1xl font-semibold text-gray-dark md:mb-2 md:text-1xl">
              We are here to help
            </h2>
            <p className="mb-8 max-w-[31.563rem] text-center text-sm font-medium leading-6 text-gray-medium md:mb-3 md:text-xs">
              Drop a message in our support chat, schedule a demo or email
              <span>
                <a
                  href="mailto:info@hihydra.com?subject=Need assistance with: &body=Briefly describe your issue or query"
                  className="pl-1 text-primary underline"
                >
                  info@hihydra.com
                </a>
              </span>
            </p>
            {/* <button className="btn-primary flex h-12 items-center border-primary text-primary  hover:border-primary500">
              <span className="animate__animated animate__tada animate__infinite">
                <Icons.PhoneCall />
              </span>
              <span className="ml-2">Schedule a Free Call</span>
            </button> */}
            <a
              className="btn-primary flex h-12 items-center border-primary text-primary  hover:border-primary500"
              href="https://calendly.com/hydra-jt/"
              target="_blank"
              rel="noreferrer"
            >
              <span className="animate__animated animate__tada animate__infinite">
                <Icons.PhoneCall />
              </span>
              <span className="ml-2">Schedule a Free Call</span>
            </a>
          </div>
        </div>

        <Footer />
      </section>
      {/* Signup modal */}
      {isSignupModalOpen && (
        <SignUpModal
          isSignupModalOpen={isSignupModalOpen}
          setIsSignupModalOpen={setIsSignupModalOpen}
          setIsLoginModalOpen={setIsLoginModalOpen}
          emailId={emailId}
          tenantId={tenantId}
          normalSignup={normalSignup}
          invitationDetails={invitationDetails}
          code={searchParams.get('code') ? searchParams.get('code') : null}
          token={searchParams.get('token') ? searchParams.get('token') : null}
          selectedPlan={selectedPlan}
        />
      )}
    </main>
  );
};
export default Landing;
