import fbSquare from '../../assets/images/fbSquare.png';
import blueRidge from '../../assets/temp-images/blueRidge.jpg';
import gAnalyticsSquare from '../../assets/images/gAnalyticsSquare.png';
import linkedinSquare from '../../assets/images/linkedinSquare.png';
import twitterSquare from '../../assets/images/twitterSquare.png';

export const navData = [
  {
    userType: 'Hydra Authenticated User',
    protectOn: true,
    pathHistory: [{ title: 'Chris Martin', highlight: true }, { title: '11 Channels' }],
  },
];
export const pageList = [
  {
    channelName: 'Facebook',
    logo: fbSquare,
    settingUrl: '#',
    pagesDetails: [
      {
        logo: blueRidge,
        title: 'BlueRidge Production',
        permission: ['Edit', 'View'],
        role: ['Edit', 'View'],
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
        selected: false,
        removed: true,
      },
      {
        logo: blueRidge,
        title: 'BlueRidge Production1',
        permission: ['Edit', 'View'],
        role: ['Edit', 'View'],
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
        selected: true,
      },
      {
        logo: blueRidge,
        title: 'BlueRidge Production2',
        permission: ['Edit', 'View'],
        role: ['Edit', 'View'],
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
        selected: false,
      },
      {
        logo: blueRidge,
        title: 'BlueRidge Production',
        permission: ['Edit', 'View'],
        role: ['Edit', 'View'],
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
        selected: false,
      },
      {
        logo: blueRidge,
        title: 'BlueRidge Production',
        permission: ['Edit', 'View'],
        role: ['Edit', 'View'],
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
        selected: false,
      },
    ],
  },
  {
    channelName: 'Linkedin',
    logo: linkedinSquare,
    settingUrl: '#',
    pagesDetails: [
      {
        logo: blueRidge,
        title: 'BlueRidge Production1',
        permission: ['Edit', 'View'],
        role: ['Edit', 'View'],
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
        selected: false,
      },
    ],
  },
  {
    channelName: 'Twitter',
    logo: twitterSquare,
    settingUrl: '#',
    pagesDetails: [
      {
        logo: blueRidge,
        title: 'BlueRidge Production3',
        permission: ['Edit', 'View'],
        role: ['Edit', 'View'],
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
        selected: false,
      },
      {
        logo: blueRidge,
        title: 'BlueRidge Production',
        permission: ['Edit', 'View'],
        role: ['Edit', 'View'],
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
        selected: false,
      },
    ],
  },
  {
    channelName: 'Google Analytics',
    logo: gAnalyticsSquare,
    settingUrl: '#',
    pagesDetails: [
      {
        logo: blueRidge,
        title: 'BlueRidge Production',
        permission: ['Edit', 'View'],
        role: ['Edit', 'View'],
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
        selected: false,
      },
      {
        logo: blueRidge,
        title: 'BlueRidge Production',
        permission: ['Edit', 'View'],
        role: ['Edit', 'View'],
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
        selected: false,
      },
    ],
  },
];

export const textConstants = {
  filter: 'Filter',
  edit: 'Edit',
  save: 'Save Changes',
  noDataMessage: 'There is no channels available, Please select a channel',
  pages: 'Pages',
  accounts: 'Accounts',
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const channelNames = {
  facebookPages: 'facebookpages',
  linkedinPages: 'linkedinpages',
};
