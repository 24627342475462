import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Select from 'react-select';
import Icons from '../../../assets/Icons';
import Tippy from '@tippyjs/react';
import classes from './channel.module.scss';
import RoleDP from './RoleSlidePanel';
import { getChannelDetails } from '../../UsersPermissionsChannelView/helper';
import { labelDisplay } from '../../../utils/helpers';

const PageBuilder = ({ pageData, editMode, setOpenRoleSelector, channel }) => {
  const [selectedToRemove, setSelectedToRemove] = useState(pageData.selected);
  return (
    //selected class for checking checkbox
    !pageData.removed ? (
      <div className={`${classes.pageWrapper} ${selectedToRemove && editMode ? '' : classes.edit}`}>
        {editMode && (
          <div
            className={classes.formControlWrapper}
            onClick={() =>
              setOpenRoleSelector({
                isOpen: true,
                optionList: pageData.roleOptions,
              })
            }
          >
            <label className={classes.formControlLabel}>Role</label>
            <Select options={pageData.roleOptions} placeholder="Select" classNamePrefix="hydra-dropdown" />
          </div>
        )}

        <div className={classes.pageHeader}>
          <div className={classes.pageLogo}>
            <img
              src={pageData.logo}
              alt={pageData.title}
              onError={event => {
                event.currentTarget.src = getChannelDetails(channel).imgUrl;
              }}
            />
          </div>
          <div className={classes.title}>
            {' '}
            {pageData?.title === pageData?.accountId && pageData?.channel === 'googleads'
              ? `Account Id: ${pageData.title.split('/')[1]}`
              : pageData?.title}
          </div>
          <div className={classes.pageChecker}>
            {editMode && (
              <label className="form-checkbox">
                <input
                  className="form-checkbox-input"
                  type="checkbox"
                  name=""
                  onChange={e => {
                    setSelectedToRemove(e.value);
                  }}
                  checked={selectedToRemove}
                />
                <div className="form-check-circle">
                  <Icons.TickSmall />
                </div>
              </label>
            )}
          </div>
        </div>
        <div className={classes.pageBody}>
          <div className={classes.pageRow}>
            <div className={`${classes.pageProperty}`}>
              <div className={classes.typeIcon}>
                <Icons.UserVerify />
              </div>
              <div className={classes.typeDetails}>
                <div className={classes.typeDetailsTitle}>{pageData.roleLabel}</div>
                <div className={classes.typeDetailsStatus}>
                  {pageData?.displayRole?.map((element, index) => {
                    return (
                      <span className={classes.typeRoles} key={`role__${index}`}>
                        {element}
                      </span>
                    );
                  })}
                </div>
              </div>
            </div>

            {pageData.channel === 'googletagmanager' && (
              <div className={`${classes.pageProperty}`}>
                <div className={classes.typeIcon}>
                  <Icons.Lock />
                </div>
                <div className={classes.typeDetails}>
                  <div className={classes.typeDetailsTitle}>{pageData.permissionLabel}</div>
                  <div className={classes.typeDetailsStatus}>
                    {pageData?.displayPermission?.map((element, index) => {
                      return (
                        <span className={classes.typeRoles} key={`permissionList__${index}`}>
                          {element}
                        </span>
                      );
                    })}
                  </div>
                </div>
              </div>
            )}
          </div>
          {/* <div className={classes.pageRow}>
            <div className={`${classes.pageProperty}`}>
              <div className={classes.typeIcon}>
                <Icons.LoginIcon />
              </div>
              <div className={classes.typeDetails}>
                <div className={classes.typeDetailsTitle}>Last Login</div>
                <div className={classes.typeDetailsStatus}>
                  {pageData.lastLogin[0] && (
                    <>
                      <span className={classes.typeRoles}>
                        {pageData.lastLogin[0].location} {pageData.lastLogin[0].dateTime}
                      </span>
                    </>
                  )}
                </div>
              </div>
            </div>
          </div> */}
        </div>
      </div>
    ) : (
      <RemovedPageBuilder />
    )
  );
};
const RemovedPageBuilder = () => {
  return (
    <div className={classes.removedItem}>
      <div className={classes.trashIcon}>
        <Icons.Trashed />
      </div>
      <div className={classes.trashMessage}>Company ABC has been removed</div>
      <button aria-label="Undo removal" className={`btn-link ${classes.undoBtn}`}>
        Undo
      </button>
    </div>
  );
};
const IndividualChannel = ({ data, editMode, selectedCard, itemIndex, filter, navigate }) => {
  const [openRoleSelector, setOpenRoleSelector] = useState({
    isOpen: false,
    optionList: [],
  });
  return (
    <>
      <div className={`${classes.channelWrap} ${selectedCard === itemIndex ? classes.activeChannel : ''}`}>
        <div className={`${classes.card} ${classes.channelCard}`}>
          <div className={classes.channelCardHeader}>
            <div className={classes.logo}>
              <img src={data.logo} alt={data.channelName} />
            </div>
            <div className={classes.channelTitleWrapper}>
              <div className={classes.channelTitle}>{data.channelName}</div>
              <div className={classes.channelPageCount}>
                {data.pagesDetails.length} {labelDisplay(data.pagesDetails.length, data.channel)}
              </div>
            </div>
            <div
              className={classes.externalLink}
              onClick={() => {
                navigate('/userPermissionChannelView', {
                  state: { channelName: data.channelName, channelType: data.channel, channelProvider: data.provider },
                });
              }}
            >
              <Tippy content="View Channel" placement="bottom" arrow={true}>
                <Link to={'#'}>
                  <Icons.Export />
                </Link>
              </Tippy>
            </div>
          </div>
          <div className={classes.channelCardBody}>
            {data.pagesDetails?.map((element, index) => {
              if (Object.values(filter).length && filter.value !== 'All') {
                if (filter.value === element.title) {
                  return (
                    <PageBuilder
                      pageData={element}
                      key={`pageBuilder__${index}`}
                      editMode={editMode}
                      setOpenRoleSelector={setOpenRoleSelector}
                      channel={data.channel}
                    />
                  );
                }
              } else {
                return (
                  <PageBuilder
                    pageData={element}
                    key={`pageBuilder__${index}`}
                    editMode={editMode}
                    setOpenRoleSelector={setOpenRoleSelector}
                    channel={data.channel}
                  />
                );
              }
            })}
            {editMode && (
              <div className={classes.floatAction}>
                <button aria-label="Select All" className="btn-outline">
                  <span>Select All</span>
                </button>
                <button aria-label="Remove Selected" className="btn-outline">
                  <span>Remove Selected</span>
                </button>
              </div>
            )}
          </div>
        </div>
      </div>
      {/* OPEN THIS ONLY ON MOBILE , NEED JS FUNCTIONALITY ON ROLE DROPDOWN and should be open on mobile focus on react-select, Open only one at a time */}
      {openRoleSelector.isOpen && <RoleDP setFilter={setOpenRoleSelector} options={openRoleSelector.optionList} />}
    </>
  );
};
export default IndividualChannel;
