import { API } from '../../constants/api';
import { get, put, del } from '../../utils/apiHandler';

export const LicenseOwnerList = async (adminPage, adminPageSize, searchKeyword, type) => {
  let req = {};
  let url = `${API.getUsers}?limit=${adminPageSize}&offset=${adminPage}`;
  if (searchKeyword && searchKeyword?.trim().length && type !== 'clear') {
    url += `&name=${searchKeyword}`;
  }
  req = {
    url,
  };
  try {
    const response = await get(req?.url);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const AllUserList = async (page, pageSize, searchKeyword, type) => {
  let req = {};
  let url = `${API.getAllUsers}?limit=${pageSize}&offset=${page}`;
  if (searchKeyword && searchKeyword?.trim().length && type !== 'clear') {
    url += `&name=${searchKeyword}`;
  }
  req = {
    url,
  };
  try {
    const response = await get(req?.url);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const BlockUser = async tenantId => {
  try {
    const response = await put(`${API.blockUser}?tenantId=${tenantId}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const DeleteUser = async userId => {
  try {
    const response = await del(`${API.deleteUser}/${userId}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const DeleteTenant = async tenantId => {
  try {
    const response = await del(`${API.deleteTenant}/${tenantId}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const UnblockUser = async tenantId => {
  try {
    const response = await put(`${API.unblockUser}?tenantId=${tenantId}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
