import { API } from '../constants/api';
import { put } from '../utils/apiHandler';

export const addAdditionalLicense = async (count, tenantId, isDelete) => {
  try {
    const response = await put(
      `${API.addAdditionalLicense}?tenantId=${tenantId}&quantity=${count}${isDelete ? `&isDelete=${isDelete}` : ''}`
    );
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
