/* eslint-disable react/no-unescaped-entities */
import React from 'react';
import Icons from '../../assets/Icons';
import { useNavigate } from 'react-router-dom';
// import NoData from '../../components/Nodata';
import TeamImageOne from '../../assets/images/Team-img-1.png';
import TeamImageTwo from '../../assets/images/Team-img-2.png';
import TeamImageThree from '../../assets/images/Team-img-3.png';
import TeamImageFour from '../../assets/images/Team-img-4.png';
import TeamImageFive from '../../assets/images/Team-img-5.png';

const MeetTheTeam = () => {
  const navigate = useNavigate();
  return (
    <>
      <section className="app-l-terms-holder">
        <section className="relative overflow-hidden bg-gradient-to-r from-primaryMedium to-primaryDark py-4 pt-6 ">
          <div className="relative z-10 mx-auto max-w-7xl px-5">
            <button
              className=" flex items-center text-sm font-medium text-white hover:opacity-50"
              onClick={() => navigate('/')}
            >
              <Icons.leftArrow />
              <span className=" ml-2.5"> Back </span>
            </button>
            <h1 className=" m-0 p-0 text-2.1xl font-semibold leading-[2.75rem] text-white md:mt-1.5 md:text-base">
              Meet the team
            </h1>
          </div>
          <div className=" rotating absolute -bottom-27 -right-8.5 h-[180px] w-[180px] text-gray-medium1 md:-right-24 md:-bottom-28">
            <Icons.paymentShape1 />
          </div>
          <div className=" rotating absolute -top-27 -left-8.5 h-[180px] w-[180px] text-gray-medium1 md:-top-[133px] md:-left-17">
            <Icons.paymentShape1 />
          </div>
        </section>
        <section className="mx-auto max-w-7xl px-5 py-10">
          <div className=" grid grid-cols-3 gap-7 gap-y-0 sm:grid-cols-2 xs:grid-cols-1">
            <a href="https://www.linkedin.com/in/justinthornemsc/" target="_blank" rel="noreferrer">
              <div className="animate__animated animate__fadeInUp delay2 flex cursor-pointer flex-col items-center justify-center rounded-2xl pb-7 hover:shadow-md">
                <img className=" relative z-[1px] w-4/5 " src={TeamImageOne} alt="Dashboard" />
                <h2 className="w-full text-center text-xl font-bold text-primary700"> Justin Jon Thorne </h2>
                <p className="w-full text-center text-lg font-medium text-primary700"> CEO & Co - Founder </p>
              </div>
            </a>
            <a href="https://www.linkedin.com/in/daryllscott/" target="_blank" rel="noreferrer">
              <div className="animate__animated animate__fadeInUp delay2 flex cursor-pointer flex-col items-center justify-center rounded-2xl pb-7 hover:shadow-md">
                <img className=" relative z-[1px] w-4/5 " src={TeamImageTwo} alt="Dashboard" />
                <h2 className="w-full text-center text-xl font-bold text-primary700"> Daryll Scott </h2>
                <p className="w-full text-center text-lg font-medium text-primary700"> Chairman & Co - Founder </p>
              </div>
            </a>
            <a href="https://www.linkedin.com/in/lana-thorne-82684b73/" target="_blank" rel="noreferrer">
              <div className="animate__animated animate__fadeInUp delay2 flex cursor-pointer flex-col items-center justify-center rounded-2xl pb-7 hover:shadow-md">
                <img className=" relative z-[1px] w-4/5 " src={TeamImageThree} alt="Dashboard" />
                <h2 className="w-full text-center text-xl font-bold text-primary700"> Lana Thorne </h2>
                <p className="w-full text-center text-lg font-medium text-primary700"> Co - Founder </p>
              </div>
            </a>
            <a href="https://www.linkedin.com/in/paul-court-b0959238/" target="_blank" rel="noreferrer">
              <div className="animate__animated animate__fadeInUp delay2 flex cursor-pointer flex-col items-center justify-center rounded-2xl pb-7 hover:shadow-md">
                <img className=" relative z-[1px] w-4/5 " src={TeamImageFour} alt="Dashboard" />
                <h2 className="w-full text-center text-xl font-bold text-primary700"> Paul Court </h2>
                <p className="w-full text-center text-lg font-medium text-primary700"> Co - Founder </p>
              </div>
            </a>
            <a href="https://www.linkedin.com/in/christian-ruetimann/" target="_blank" rel="noreferrer">
              <div className="animate__animated animate__fadeInUp delay2 flex cursor-pointer flex-col items-center justify-center rounded-2xl pb-7 hover:shadow-md">
                <img className=" relative z-[1px] w-4/5 " src={TeamImageFive} alt="Dashboard" />
                <h2 className="w-full text-center text-xl font-bold text-primary700"> Christian Ruetimann </h2>
                <p className="w-full text-center text-lg font-medium text-primary700"> Product Manager </p>
              </div>
            </a>
          </div>
        </section>
        {/* <NoData message={'No Data Found'} showImage={true} /> */}
      </section>
    </>
  );
};

export default MeetTheTeam;
