import React, { useState, useEffect } from 'react';
import CryptoJS from 'crypto-js';
import { Routes, Route, useLocation } from 'react-router-dom';
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import Landing from '../features/Landing';
import Dashboard from '../features/Dashboard';
import Payment from '../features/Payment';
import SuccessMessage from '../features/Payment/SuccessMessage';
import { ConfirmPasswordModal } from '../components/Modal/ConfirmPasswordModal';
import UserManagement from '../features/UserManagement';
import WorkspacesList from '../features/WorkspacesList';
import UserPermissionChannel from '../features/UserPermissionChannel';
import Profile from '../features/Profile';
import CreateWorkspace from '../features/CreateWorkspace';
import WorkspacesDetailed from '../features/WorkspacesDetailed';
import SearchResults from '../features/SearchResults';
import UsersPermissionsChannelView from '../features/UsersPermissionsChannelView';
import UserPermissionPageAccount from '../features/UserPermissionPageAccount';
import Messaging from '../features/Messaging';
import Reports from '../features/Reports';
import Billing from '../features/Billing';
import AddLicense from '../features/LicenseAddingPage';
import AccountUsersView from '../features/AccountUsers';
import { constants as commonConstants, subscriptionPlans } from '../constants/constants';
import PrivacyPolicies from '../features/PrivacyPolicy';
import TermsandConditions from '../features/Terms&Conditions';
import Disclaimer from '../features/Disclaimer';
import Sitemap from '../features/Sitemap';
import AboutUs from '../features/AboutUs';
import MeetTheTeam from '../features/MeetTheTeam';
import JoinTeam from '../features/JoinTeam';

import Help from '../features/Help';
import SearchContext from '../context/auth/searchContext';
import InputContext from '../context/auth/inputContext';
import ChannelListContext from '../context/channelListContext';
import NoAccessPage from '../features/NoAccessPage';
import ErrorPage from '../features/ErrorPage';
import { getUserInfo } from '../utils/getUserInfo';
import CheckAuthenticateContext from '../context/checkAuthenticateContext';

toast.configure();

export function Router() {
  const [connectionInfo, setConnectionInfo] = useState(navigator?.connection);
  const currentUrl = window.location.pathname;
  let subscriptionType;
  let userRole;
  const userInfo = JSON.parse(getUserInfo());
  const subscriptionCode = userInfo?.user?.subscriptionType;
  const userRoleCode = JSON.parse(getUserInfo())?.user.role;
  if (subscriptionCode) {
    subscriptionType = CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8);
  }
  if (userRoleCode) {
    userRole = CryptoJS?.AES?.decrypt(userRoleCode, commonConstants.localStorageUser)?.toString(CryptoJS.enc.Utf8);
  }

  const isLoggedIn = JSON.parse(getUserInfo())?.isLoggedIn;

  const [isOpen, setIsOpen] = useState(true);
  const [searchResults, setsearchResults] = useState([]);
  const [inputValue, setInputValue] = useState('');
  const [channelList, setChannelList] = useState([]);
  const [profileInfo, setProfileInfo] = useState(false);
  const location = useLocation();
  const background = location.state && location.state.background;

  function LicenseCheck({ children }) {
    if (connectionInfo?.downlink < 0.2) {
      return <ErrorPage networkDown={true} />;
    } else if (userRole == undefined) {
      return <Landing />;
    } else if (subscriptionType == subscriptionPlans.individual || userRole == 'admin') {
      return <NoAccessPage type={'AccessDenied'} userRole={userRole} />;
    } else if (userRole == 'user') {
      return <NoAccessPage type={'AccessDenied'} userRole={userRole} />;
    } else {
      return <>{children}</>;
    }
  }

  function AdminRoute({ children }) {
    if (connectionInfo?.downlink < 0.2) {
      return <ErrorPage networkDown={true} />;
    } else if (userRole == undefined) {
      return <Landing />;
    } else if (userRole !== 'admin') {
      return <NoAccessPage type={'AccessDenied'} userRole={userRole} />;
    } else {
      return <>{children}</>;
    }
  }

  function NetworkCheck({ children }) {
    if (connectionInfo?.downlink < 0.2) {
      return <ErrorPage networkDown={true} />;
    } else {
      return <>{children}</>;
    }
  }

  function PrivateRoutes({ children }) {
    if (connectionInfo?.downlink < 0.2) {
      return <ErrorPage networkDown={true} />;
    } else if (userRole !== undefined && userRole !== 'admin' && userRole !== 'user') {
      return <>{children}</>;
    } else if (userRole == 'user') {
      return <NoAccessPage type={'AccessDenied'} userRole={userRole} />;
    } else {
      return (
        <>
          <Landing />
        </>
      );
    }
  }

  function ViewerIncluded({ children }) {
    if (connectionInfo?.downlink < 0.2) {
      return <ErrorPage networkDown={true} />;
    } else if (userRole == undefined) {
      return <Landing />;
    } else if (subscriptionType == subscriptionPlans.individual && userRole !== 'user' && currentUrl !== '/profile') {
      return <NoAccessPage type={'AccessDenied'} userRole={userRole} />;
    } else if (userRole == 'admin') {
      return <NoAccessPage type={'AccessDenied'} userRole={userRole} />;
    } else {
      return <>{children}</>;
    }
  }

  useEffect(() => {
    const updateConnectionInfo = () => {
      setConnectionInfo(navigator?.connection);
    };
    navigator?.connection?.addEventListener('change', updateConnectionInfo);
    return () => navigator?.connection?.removeEventListener('change', updateConnectionInfo);
  }, []);

  return (
    <SearchContext.Provider value={{ searchResults, setsearchResults }}>
      <InputContext.Provider value={{ inputValue, setInputValue }}>
        <ChannelListContext.Provider value={{ channelList, setChannelList }}>
          <CheckAuthenticateContext.Provider value={{ profileInfo, setProfileInfo }}>
            <div>
              <Routes location={background || location}>
                <>
                  <Route path="*" element={<NoAccessPage type={'PageNotFound '} userRole={userRole} />} />

                  <Route
                    path="/"
                    element={
                      isLoggedIn === true && userRole && userRole === 'admin' ? (
                        <NetworkCheck>
                          <AddLicense />
                        </NetworkCheck>
                      ) : isLoggedIn === true && userRole && userRole === 'clientAdmin' ? (
                        <NetworkCheck>
                          <Dashboard />
                        </NetworkCheck>
                      ) : (
                        <NetworkCheck>
                          <Landing />
                        </NetworkCheck>
                      )
                    }
                  />
                  <Route
                    path="/signup"
                    element={
                      <PrivateRoutes>
                        <Landing />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path="/dashboard"
                    element={
                      <PrivateRoutes>
                        <Dashboard />
                      </PrivateRoutes>
                    }
                  />
                  <Route path="/invitation" element={<Landing />} />
                  <Route path="/lo-invitation" element={<Landing />} />
                  <Route
                    path="/payment"
                    element={
                      <NetworkCheck>
                        <Payment />
                      </NetworkCheck>
                    }
                  />
                  <Route
                    path="/payment-confirmation"
                    element={
                      <NetworkCheck>
                        <SuccessMessage />
                      </NetworkCheck>
                    }
                  />
                  <Route
                    path="/workspacesList"
                    element={
                      <ViewerIncluded>
                        <WorkspacesList />
                      </ViewerIncluded>
                    }
                  />
                  <Route
                    path="/createWorkspace"
                    element={
                      <LicenseCheck>
                        <CreateWorkspace />
                      </LicenseCheck>
                    }
                  />
                  <Route
                    path="/userManagement"
                    element={
                      <LicenseCheck>
                        <UserManagement />
                      </LicenseCheck>
                    }
                  />
                  <Route
                    path="/payment-complete"
                    element={
                      <NetworkCheck>
                        <SuccessMessage />
                      </NetworkCheck>
                    }
                  />
                  <Route
                    path="/UserPermissionChannel"
                    element={
                      <PrivateRoutes>
                        <UserPermissionChannel />
                      </PrivateRoutes>
                    }
                  />
                  {/* <Route
          path="auth/reset-password/"
          element={<ConfirmPasswordModal location={location.search} isOpen={isOpen} setIsOpen={setIsOpen} />}
        /> */}
                  <Route
                    path="/reset-password"
                    element={<ConfirmPasswordModal location={location.search} isOpen={isOpen} setIsOpen={setIsOpen} />}
                  />
                  <Route
                    path="/Profile"
                    element={
                      <ViewerIncluded>
                        <Profile />
                      </ViewerIncluded>
                    }
                  />
                  <Route
                    path="/workspace-detailed"
                    element={
                      <ViewerIncluded>
                        <WorkspacesDetailed />
                      </ViewerIncluded>
                    }
                  />
                  <Route
                    path="/search-results"
                    element={
                      // <NetworkCheck>
                      <SearchResults />
                      // </NetworkCheck>
                    }
                  />
                  <Route path="/google/callback" element={<Dashboard />} />
                  <Route path="/facebook/callback" element={<Dashboard />} />
                  <Route path="/linkedin/callback" element={<Dashboard />} />
                  <Route path="/twitter/callback" element={<Dashboard />} />
                  <Route
                    path="/userPermissionChannelView"
                    element={
                      <PrivateRoutes>
                        <UsersPermissionsChannelView />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path="/messaging"
                    element={
                      <PrivateRoutes>
                        <Messaging />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path="/reports"
                    element={
                      <PrivateRoutes>
                        <Reports />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path="/billing"
                    element={
                      <PrivateRoutes>
                        <Billing />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path="/userPermissionPageAccount"
                    element={
                      <PrivateRoutes>
                        <UserPermissionPageAccount />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path="/accountUsersView"
                    element={
                      <PrivateRoutes>
                        <AccountUsersView />
                      </PrivateRoutes>
                    }
                  />
                  <Route
                    path="/add-License"
                    element={
                      <AdminRoute>
                        <AddLicense />
                      </AdminRoute>
                    }
                  />
                  <Route
                    path="/help"
                    element={
                      <NetworkCheck>
                        <Help />
                      </NetworkCheck>
                    }
                  />
                  <Route
                    path="/privacyPolicy"
                    element={
                      <NetworkCheck>
                        <PrivacyPolicies />
                      </NetworkCheck>
                    }
                  />
                  <Route path="/error" element={<ErrorPage />} />
                  <Route
                    path="/TermsAndConditions"
                    element={
                      <NetworkCheck>
                        <TermsandConditions />
                      </NetworkCheck>
                    }
                  />
                  <Route
                    path="/disclaimer"
                    element={
                      <NetworkCheck>
                        <Disclaimer />
                      </NetworkCheck>
                    }
                  />
                  <Route
                    path="/siteMap"
                    element={
                      <NetworkCheck>
                        <Sitemap />
                      </NetworkCheck>
                    }
                  />
                  <Route
                    path="/aboutUs"
                    element={
                      <NetworkCheck>
                        <AboutUs />
                      </NetworkCheck>
                    }
                  />
                  <Route
                    path="/meetTheTeam"
                    element={
                      <NetworkCheck>
                        <MeetTheTeam />
                      </NetworkCheck>
                    }
                  />
                  <Route
                    path="/joinTeam"
                    element={
                      <NetworkCheck>
                        <JoinTeam />
                      </NetworkCheck>
                    }
                  />
                </>
              </Routes>
            </div>
          </CheckAuthenticateContext.Provider>
        </ChannelListContext.Provider>
      </InputContext.Provider>
    </SearchContext.Provider>
  );
}
