import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';

import { search } from '../../services/searchService';
import { constants } from '../../constants/constants';
import SearchContext from '../../context/auth/searchContext';
//import InputContext from '../../context/auth/inputContext';
import Icons from '../../assets/Icons';
import { useToastBox } from '../../customHooks/useToastBox';
import Loader from '../Loader';

const Searchbar = ({ classes, openMobileSearchHandle }) => {
  let searchKeyword = sessionStorage.getItem('searchKeyword');
  const { handleErrorToast } = useToastBox();
  const navigate = useNavigate();
  const location = useLocation();
  //const { inputValue, setInputValue } = useContext(InputContext);
  const { setsearchResults } = useContext(SearchContext);
  const [keyword, setKeyword] = useState('');
  const [loading, setLoading] = useState(false);

  const handleSearchApi = searchParam => {
    return search(searchParam)
      .then(searchResponse => {
        return searchResponse;
      })
      .catch(error => console.error(error));
  };

  const handleSearchResult = searchValue => {
    setLoading(true);
    handleSearchApi(searchValue)
      .then(searchData => {
        setLoading(false);
        const searchDataResponse = searchData?.data?.data;
        if (searchDataResponse?.length > 0) {
          setsearchResults(searchDataResponse);
          navigate(constants.routes.searchResults, { state: searchValue });
        } else {
          setsearchResults([]);
          navigate(constants.routes.searchResults, { state: searchValue });
        }
      })
      .catch(err => console.error(err));
  };

  const handleSearch = searchValue => {
    sessionStorage.setItem('searchKeyword', searchValue);
    //setInputValue(searchValue);
    handleSearchResult(searchValue);
  };

  // const handleKeyDown = event => {
  //   if (event.key === 'Enter') {
  //     const searchParam = event.target.value;
  //     handleSearchResult(searchParam);
  //   }
  // };

  const clearSearchField = () => {
    //setInputValue('');
    setKeyword('');
    sessionStorage.setItem('searchKeyword', '');
  };

  useEffect(() => {
    if (location?.pathname != '/search-results') {
      //setInputValue('');
      setKeyword('');
    } else if (location?.pathname == '/search-results' && searchKeyword !== '') {
      handleSearch(searchKeyword);
      setKeyword(searchKeyword);
    } else {
      setKeyword(searchKeyword);
    }
  }, []);

  return loading ? (
    <Loader />
  ) : (
    <div className={`${classes.formControl} ${classes.searchPanel} ${classes.panelOpen}`}>
      <span className={classes.formControlIconPrefix}>
        <Icons.Search />
      </span>
      <input
        className={`${classes.formInput}`}
        placeholder="Type and press enter to search..."
        value={keyword}
        onChange={e => setKeyword(e.target.value)}
        onKeyUp={e => {
          if (e.key === 'Enter' && e?.target?.value?.length >= 3) {
            handleSearch(e.target.value);
          } else if (e.key === 'Enter' && e?.target?.value?.length < 3) {
            handleErrorToast('Please enter at least 3 characters');
          }
        }}
      />
      <button
        aria-label="Clear field"
        className={classes.formControlIconClear}
        onClick={() => {
          openMobileSearchHandle(false);
          clearSearchField();
        }}
      >
        <Icons.CloseIcon />
      </button>
      <div className={`${classes.dropdownSearch} ${classes.bottom}`} style={{ display: 'none' }}>
        {/* Dropdown for search - IDK */}
        <ul className={classes.dpList}>
          <li role={'presentation'} className={classes.dpItem}>
            <Link to={'#'} className={classes.dpLink}>
              <span className={classes.title}>
                <span className={classes.matchedWord}>Steve</span> Aoki
              </span>
              <span className={classes.category}>User</span>
            </Link>
          </li>
          <li role={'presentation'} className={classes.dpItem}>
            <Link to={'#'} className={classes.dpLink}>
              <span className={classes.title}>
                <span className={classes.matchedWord}>Steve</span> Aoki
              </span>
              <span className={classes.category}>User</span>
            </Link>
          </li>
          <li role={'presentation'} className={classes.dpItem}>
            <Link to={'#'} className={classes.dpLink}>
              <span className={classes.title}>
                <span className={classes.matchedWord}>Steve</span> Aoki
              </span>
              <span className={classes.category}>User</span>
            </Link>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Searchbar;
