import React from 'react';

const Pagination = ({ pageRange, setPageRange, metaData, page, setPageSize, setPage, pageSize }) => {
  const pageNumbers = [];

  for (let i = pageRange; i < pageRange + 5 && i <= metaData?.totalPages; i += 1) {
    pageNumbers.push(i);
  }

  return (
    <div className="flex items-center justify-between border-t border-gray-200 bg-white px-4 py-3 px-6">
      <div className="flex flex-1 items-center justify-between  xl:flex-col xl:justify-center">
        <div className="flex items-center xl:flex-col xl:justify-center">
          <div className="flex items-center whitespace-nowrap">
            <label htmlFor="country" className="block text-sm font-medium text-gray-700">
              Rows per page:
            </label>
            <select
              id="pagesize"
              name="pagesize"
              className="ml-2 mr-4 block w-full rounded-md border border-gray-300 bg-white py-1 px-3 shadow-sm focus:border-indigo-500 focus:outline-none focus:ring-indigo-500 sm:text-sm"
              onChange={e => {
                setPageSize(Number(e.target.value));
                setPage(1);
                setPageRange(1);
              }}
              value={pageSize}
            >
              {[10, 20, 30, 40, 50].map(pageSize => (
                <option key={pageSize} value={pageSize}>
                  {pageSize}
                </option>
              ))}
            </select>
          </div>
          <div className="xl:my-4">
            <p className="text-sm text-gray-700">
              Showing{' '}
              <span className="font-medium">
                {metaData ? (metaData?.totalResults > 0 ? (page - 1) * pageSize + 1 : 0) : ''}{' '}
              </span>
              to{' '}
              <span className="font-medium">
                {' '}
                {metaData
                  ? page && pageSize
                    ? page * pageSize > metaData?.totalResults
                      ? metaData?.totalResults
                      : page && pageSize
                      ? page * pageSize
                      : 0
                    : 0
                  : ''}{' '}
              </span>
              of
              <span className="font-medium"> {metaData?.totalResults} </span>
              results
            </p>
          </div>
        </div>

        <div>
          <nav className="isolate inline-flex -space-x-px rounded-md shadow-sm" aria-label="Pagination">
            <button
              className="relative inline-flex items-center  border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
              onClick={() => {
                setPageRange(pageRange - 5);
                setPage(pageRange - 5);
              }}
              disabled={pageRange === 1}
            >
              <svg
                className="-mr-[0.875rem] h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                  clipRule="evenodd"
                />
              </svg>
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              className="relative inline-flex items-center rounded-l-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
              onClick={() => {
                setPage(page - 1);
              }}
              disabled={page === 1 || pageNumbers.length === 1 || page === pageNumbers[0]}
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M12.79 5.23a.75.75 0 01-.02 1.06L8.832 10l3.938 3.71a.75.75 0 11-1.04 1.08l-4.5-4.25a.75.75 0 010-1.08l4.5-4.25a.75.75 0 011.06.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>

            {pageRange === 1 ? (
              ''
            ) : (
              <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
                ...
              </span>
            )}
            {pageNumbers.map((item, index) => (
              <button
                key={index}
                className={
                  item == page
                    ? 'relative z-10 inline-flex items-center border border-indigo-500 bg-indigo-50 px-4 py-2 text-sm font-medium text-indigo-600 focus:z-20'
                    : 'relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20'
                }
                onClick={() => setPage(item)}
              >
                {item}
              </button>
            ))}
            {~~(pageRange / 5) * 5 >= metaData?.totalPages - 5 ? (
              ''
            ) : (
              <span className="relative inline-flex items-center border border-gray-300 bg-white px-4 py-2 text-sm font-medium text-gray-700">
                ...
              </span>
            )}

            <button
              className="relative inline-flex items-center rounded-r-md border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
              onClick={() => {
                setPage(page + 1);
              }}
              disabled={
                page == metaData?.totalPages || page === pageNumbers[pageNumbers.length - 1] || pageNumbers.length === 1
              }
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
            <button
              className="relative inline-flex items-center  border border-gray-300 bg-white px-2 py-2 text-sm font-medium text-gray-500 hover:bg-gray-50 focus:z-20 disabled:opacity-50"
              onClick={() => {
                setPageRange(pageRange + 5);
                setPage(pageRange + 5);
              }}
              disabled={~~(pageRange / 5) * 5 >= metaData?.totalPages - 5}
            >
              <svg
                className="h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
              <svg
                className="-ml-[0.875rem] h-5 w-5"
                xmlns="http://www.w3.org/2000/svg"
                viewBox="0 0 20 20"
                fill="currentColor"
                aria-hidden="true"
              >
                <path
                  fillRule="evenodd"
                  d="M7.21 14.77a.75.75 0 01.02-1.06L11.168 10 7.23 6.29a.75.75 0 111.04-1.08l4.5 4.25a.75.75 0 010 1.08l-4.5 4.25a.75.75 0 01-1.06-.02z"
                  clipRule="evenodd"
                />
              </svg>
            </button>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
