import { API } from '../../constants/api';
import { get } from '../../utils/apiHandler';

export const workspaceDetailed = async workspaceId => {
  try {
    const response = await (await get(`${API.workspace_list}/${workspaceId}`)).data;
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
