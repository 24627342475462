import { LOGIN, LOGOUT, SET_USER_DATA, SET_CHANNEL_COUNT, SET_WORKSPACE_COUNT } from './actions';

const handleLogin = (state, action) => {
  return {
    ...state,
    auth: {
      user: action.payload,
      isLoggedIn: true,
      channelCount: action.payload.channelCount,
      workspaceCount: action.payload.workspaceCount,
    },
  };
};

const handleLogout = state => {
  return {
    ...state,
    auth: {
      user: null,
      isLoggedIn: false,
      channelCount: state.auth.channelCount,
      workspaceCount: state.auth.workspaceCount,
    },
  };
};

const setUserData = (state, action) => {
  return {
    ...state,
    auth: {
      user: action.payload,
      isLoggedIn: true,
      channelCount: state.auth.channelCount,
      workspaceCount: state.auth.workspaceCount,
    },
  };
};

const setChannelCount = (state, action) => {
  return {
    auth: {
      user: state.auth.user,
      isLoggedIn: true,
      channelCount: action.payload,
    },
  };
};

const setWorkspaceCount = (state, action) => {
  return {
    auth: {
      user: state.auth.user,
      isLoggedIn: true,
      workspaceCount: action.payload,
    },
  };
};
const authReducer = (state, action) => {
  switch (action.type) {
    case LOGIN:
      return handleLogin(state, action);
    case LOGOUT:
      return handleLogout(state);
    case SET_USER_DATA:
      return setUserData(state, action);
    case SET_CHANNEL_COUNT:
      return setChannelCount(state, action);
    case SET_WORKSPACE_COUNT:
      return setWorkspaceCount(state, action);
    default:
      return state;
  }
};

export default authReducer;
