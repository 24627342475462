import React from 'react';
import { useNavigate } from 'react-router-dom';
import CommonDashboard from '../../components/CommonDashboard';
import NoData from '../../components/Nodata';
import Icons from '../../assets/Icons';

const NoAccessPage = ({ type, userRole }) => {
  const navigate = useNavigate();
  sessionStorage.setItem('page', 'Nodata');
  return (
    <CommonDashboard>
      <div className=" flex h-full w-full items-center justify-center ">
        {type == 'AccessDenied' ? (
          <div className="flex h-[calc(100%-56px)] min-h-[calc(100%-56px)]  flex-col items-center justify-center overflow-auto">
            <div className=" mb-14 ">
              <Icons.NoAccessibleIcon />
            </div>
            <h2 className="w-full text-center text-2xl font-semibold leading-6 text-primary100">Page not accessible!</h2>
            <h3 className="w-full pt-3 text-center text-sm  text-gray-medium">
              Your current role does not have the permission to view this page.
            </h3>
            <button
              className="mt-6 rounded-full bg-primary p-2.5 px-6 text-sm font-semibold leading-6 text-white duration-200 ease-in hover:bg-primary500"
              onClick={() => (userRole !== 'user' ? navigate('/dashboard') : navigate('/workspacesList'))}
            >
              {userRole !== 'user' ? 'Take me to Dashboard' : 'Take me to Workspace List'}
            </button>
          </div>
        ) : (
          <NoData showImage={true} message={'Page not found'} />
        )}
      </div>
    </CommonDashboard>
  );
};

export default NoAccessPage;
