import blueRidge from '../../assets/temp-images/blueRidge.jpg';
//import IBMIcon from "../../assets/temp-images/ibm.png";
//import InfyIcon from "../../assets/temp-images/infy.png";
import FBICon from '../../assets/images/fbIcon.png';
import LinkedinIcon from '../../assets/images/linkedin.png';

export const navData = [
  {
    userType: 'Use accounts for',
    protectOn: false,
    pathHistory: [{ title: 'Company ABC', highlight: true }, { title: '' }],
  },
];
export const textConstants = {
  googleTagManager: 'googletagmanager',
  googleAnalytics: 'ganalytics',
  googleMerchantCentre: 'googlemerchantcenter',
  noData: 'You do not have the required admin permissions to view other users of this account.',
  noAccounts: 'No accounts found',
  facebookAds: 'facebookads',
  facebookPages: 'facebookpages',
  linkedinPages: 'linkedinpages',
  linkedinAds: 'linkedinads',
  googleAds: 'googleads',
  addUser: 'Add User',
  googleSearchConsole: 'googlesearchconsole',
  googleMyBusiness: 'googlemybusiness',
  twitterAds: 'twitterads',
  alertMessage: 'Are you sure you want to remove the user from ',
  alertTitle: 'Alert!',
  requestToRemove: 'You do not have the right access. Request to remove the user?',
  pageUserType: 'Use pages for',
  accountUserType: 'Use accounts for',
  gadsNoData: 'Account setup in progress.',
};
export const currentUser = 'Steveharvey911@gmail.com';
export const pageList = [
  {
    title: 'Linkedin Pages',
    imgUrl: [LinkedinIcon][LinkedinIcon],
    roles: [
      {
        channelName: 'Admin',
        pagesDetails: [
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
            removed: true,
          },
          {
            logo: blueRidge,
            title: 'SJakesulla@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
          {
            logo: blueRidge,
            title: 'Charliemunger23@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
        ],
      },
      {
        channelName: 'Editor',
        pagesDetails: [
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
        ],
      },
      {
        channelName: 'Viewer',
        pagesDetails: [
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
        ],
      },
    ],
  },
  {
    title: 'Facebook',
    imgUrl: FBICon,
    roles: [
      {
        channelName: 'Admin',
        pagesDetails: [
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],

            removed: true,
          },
          {
            logo: blueRidge,
            title: 'SJakesulla@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
          {
            logo: blueRidge,
            title: 'Charliemunger23@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
        ],
      },
      {
        channelName: 'Editor',
        pagesDetails: [
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
        ],
      },
      {
        channelName: 'Viewer',
        pagesDetails: [],
      },
    ],
  },
  {
    title: 'Linkedin',
    imgUrl: LinkedinIcon,
    roles: [
      {
        channelName: 'Admin',
        pagesDetails: [
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],

            removed: true,
          },
          {
            logo: blueRidge,
            title: 'SJakesulla@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
          {
            logo: blueRidge,
            title: 'Charliemunger23@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
        ],
      },
      {
        channelName: 'Editor',
        pagesDetails: [
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
          {
            logo: blueRidge,
            title: 'Steveharvey911@gmail.com',
            lastLogin: [
              {
                location: 'Chester, UK',
                dateTime: '11 Oct, 11:13 AM',
              },
            ],
            roleOptions: [
              { value: 'Admin', label: 'Admin' },
              { value: 'Editor', label: 'Editor' },
              { value: 'Viewer', label: 'Viewer' },
            ],
          },
        ],
      },
      {
        channelName: 'Viewer',
        pagesDetails: [],
      },
    ],
  },
];
