import fbSquare from '../../assets/images/fbSquare.png';
import gAnalyticsSquare from '../../assets/images/gAnalyticsSquare.png';
import linkedinSquare from '../../assets/images/linkedinSquare.png';
import twitterSquare from '../../assets/images/twitterSquare.png';
import CompanyLogo from '../../assets/images/LogoCompany.png';

export const pageList = [
  {
    channelName: 'Facebook',
    logo: fbSquare,
    pageCount: 5,
    pagesDetails: [
      {
        id: 'fb_01',
        logo: CompanyLogo,
        title: 'Company ABC',
        edit: true,
      },
      {
        id: 'fb_02',
        logo: CompanyLogo,
        title: 'Company DEF',
        edit: false,
      },
      {
        id: 'fb_03',
        logo: CompanyLogo,
        title: 'Company BGG',
        edit: false,
      },
      {
        id: 'fb_04',
        logo: CompanyLogo,
        title: 'Company ABD',
        edit: true,
      },
    ],
  },
  {
    channelName: 'Linkedin',
    logo: linkedinSquare,
    pageCount: 1,
    pagesDetails: [
      {
        id: 'li_01',
        logo: CompanyLogo,
        title: 'Company ABC',
        edit: true,
      },
    ],
  },
  {
    channelName: 'Twitter',
    logo: twitterSquare,
    pageCount: 3,
    pagesDetails: [
      {
        id: 'ti_01',
        logo: CompanyLogo,
        title: 'Company ABC',
        edit: true,
      },
      {
        id: 'ti_02',
        logo: CompanyLogo,
        title: 'Company DEF',
        edit: false,
      },
      {
        id: 'ti_03',
        logo: CompanyLogo,
        title: 'Company BGG',
        edit: false,
      },
    ],
  },
  {
    channelName: 'Google Analytics',
    logo: gAnalyticsSquare,
    pageCount: 2,
    pagesDetails: [
      {
        id: 'ga_01',
        logo: CompanyLogo,
        title: 'Company ABC',
        edit: true,
      },
      {
        id: 'ga_02',
        logo: CompanyLogo,
        title: 'Company DEF',
        edit: false,
      },
    ],
  },
];

export const constants = {
  label: {
    workspaceName: 'Workspace Name',
    createWorkspace: 'Create Workspace',
    editWorkspace: 'Edit Workspace',
    pages: 'Pages',
    customReorder: 'Custom Reorder',
    filter: 'Filter',
    filterByCompany: 'Filter by Company',
    messageHeading: 'Are you sure?',
    invitaionFailedAlert: 'Alert',
  },
  infoMessages: {
    noData: 'No Data',
    dragDrop: ' Drag and drop pages to create workspace and then add users',
    filter: 'Drag to change the order of the channels. Show/hide the channels using the check mark.',
    modalMessage:
      'You are trying to remove an account added by some other admin, once saved you cant add this account by yourself. Are you sure you want to continue?',
    modalUserMessage: 'All accounts added by this user will also get deleted. Are you sure you want to continue?',
    viewerInvitationStatusMessage: 'Following invitation email(s) was not successfully sent.',
    alertMessage: 'Are you sure you want to remove the user ',
    deleteWorkspaceMessage: "Once deleted you can't recover this workspace. Are you sure you want to continue?",
  },
  button: {
    addUser: 'Add/Edit Users',
    addPages: 'Add Pages',
    addSelectedPages: 'Add Selected Pages',
    cancel: 'Cancel',
    save: 'Save',
    apply: 'Apply',
    confirm: 'Confirm',
    ok: 'OK',
  },
  permissions: {
    title: 'Permission',
    editView: 'Edit & View',
    view: 'View',
  },
  validationErrorMessages: {
    name: 'Workspace name cannot be empty',
    accounts: 'Please select any channel',
    emptyString: '',
    nochange: 'No changes to update',
    adminUsersUpdate: 'Users updated successfully',
    adminUsersAdd: 'Users added successfully',
    viewersUpdate: 'Viewers updated successfully',
    viewersAdd: 'Viewers added successfully',
  },
  droppableFormTitles: {
    workspaceName: 'name',
    workspaceId: 'workspaceName',
    workspaceMobileId: 'workspaceNameMobile',
    channelSelected: 'accounts',
    successMessage: 'Successfully added workspace',
    checkboxName: 'channelCard',
  },
};
