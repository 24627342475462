import React, { useEffect, useState } from 'react';
import FilterReorderingDP from '../../../components/FilterReorderingDP';
import BreadCrumb from '../../../components/BreadCrumb';
import DroppableArea from './DroppableArea';
import ChannelsList from './ChannelsList';
import NoData from '../../../components/Nodata';
import Icons from '../../../assets/Icons';
import { handleDeleteWorkspace } from '../helper';
import { useToastBox } from '../../../customHooks/useToastBox';
import { useNavigate } from 'react-router-dom';
import { AlertModal } from '../../../components/Modal/AlertModal';

const CreateWorkspaceWebView = ({
  pageList,
  setPageList,
  workspaceRender,
  setWorkspaceRender,
  constants,
  navData,
  droppedData,
  setDroppedData,
  createWorkspaceFormError,
  setCreateWorkspaceFormError,
  createWorkspaceData,
  setCreateWorkspaceData,
  mobileview,
  tempId,
  temp,
  // edit  workspace
  workspaceId,
  workspaceDetailData,
  workspaceActualInfo,
  // newedit
  addedViewers,
  setAddedViewers,
  invitedViewers,
  setInvitedViewers,
  invitedViewersIds,
  setInvitedViewersIds,
  invitedViewersList,
  setInvitedViewersList,
}) => {
  const [filterDP, setFilterDP] = useState(false);
  const [render, setRender] = useState(true);
  const [channelFilter, setChannelFilter] = useState({});
  const { handleSuccessToast, handleErrorToast } = useToastBox();
  const navigate = useNavigate();
  const [isDeleteConfirmOpen, setIsDeleteConfirmOpen] = useState(false);

  useEffect(() => {}, [render]);
  const onFilterApply = (data, company) => {
    setPageList(data);
    setChannelFilter(company);
  };

  return (
    <div className="flex h-[calc(100vh-3.25rem)] w-full overflow-hidden md:hidden">
      <DroppableArea
        render={render}
        setRender={setRender}
        pageList={pageList}
        setPageList={setPageList}
        workspaceRender={workspaceRender}
        setWorkspaceRender={setWorkspaceRender}
        constants={constants}
        droppedData={droppedData}
        setDroppedData={setDroppedData}
        createWorkspaceFormError={createWorkspaceFormError}
        setCreateWorkspaceFormError={setCreateWorkspaceFormError}
        createWorkspaceData={createWorkspaceData}
        setCreateWorkspaceData={setCreateWorkspaceData}
        mobileview={mobileview}
        tempId={tempId}
        temp={temp}
        // edit workspace
        workspaceId={workspaceId}
        workspaceDetailData={workspaceDetailData}
        workspaceActualInfo={workspaceActualInfo}
        // newedit
        //viewer
        addedViewers={addedViewers}
        setAddedViewers={setAddedViewers}
        invitedViewers={invitedViewers}
        setInvitedViewers={setInvitedViewers}
        invitedViewersIds={invitedViewersIds}
        setInvitedViewersIds={setInvitedViewersIds}
        invitedViewersList={invitedViewersList}
        setInvitedViewersList={setInvitedViewersList}
      />
      <div className="custom-horizontal-scrollbar flex w-full flex-col overflow-hidden ">
        <BreadCrumb navData={navData}>
          <div className="ml-auto flex items-center space-x-2">
            <div className=" relative">
              <button
                aria-label="Filter"
                className="rounded-full border border-primary px-5.5 py-1.5 text-xs font-semibold leading-4.5 text-primary hover:bg-primary hover:text-white"
                onClick={() => setFilterDP(true)}
              >
                {constants.label.filter}
              </button>
              {filterDP && (
                <FilterReorderingDP data={pageList} setFilter={setFilterDP} constants={constants} onApply={onFilterApply} />
              )}
            </div>
            {workspaceId && createWorkspaceData?.workspaceOwner === createWorkspaceData?.loggedinUser && (
              <button
                className={
                  'flexCenter h-7.5 rounded-full border border-red-dark bg-red-light px-3.5 text-xs font-medium text-red-dark hover:bg-red-dark hover:text-white xs:w-full xs:justify-center'
                }
                onClick={() => setIsDeleteConfirmOpen(true)}
              >
                <Icons.DeleteIcon strokeColour={'currentColor'} />

                <span className=" ml-1.5">Delete Workspace</span>
              </button>
            )}
          </div>
        </BreadCrumb>

        <div className="flex h-full  w-full  gap-x-4 overflow-x-auto overflow-y-hidden bg-gray-light1 p-4.5 pb-16">
          {pageList.length !== 0 ? (
            pageList.map((element, index) => {
              if (element.checked) {
                return (
                  <ChannelsList
                    data={element}
                    key={`individualChannel__${index}`}
                    constants={constants}
                    filter={channelFilter}
                    pageList={pageList}
                    setPageList={setPageList}
                    droppedData={droppedData}
                    setDroppedData={setDroppedData}
                    tempId={tempId}
                    workspaceRender={workspaceRender}
                    setWorkspaceRender={setWorkspaceRender}
                    createWorkspaceData={createWorkspaceData}
                  />
                );
              }
            })
          ) : (
            <NoData message={constants.infoMessages.noData} showImage={true} />
          )}
          <AlertModal
            isOpen={isDeleteConfirmOpen}
            setIsOpen={setIsDeleteConfirmOpen}
            heading={constants.label.messageHeading}
            message={constants.infoMessages.deleteWorkspaceMessage}
            handleConfirm={() => handleDeleteWorkspace(createWorkspaceData, handleSuccessToast, handleErrorToast, navigate)}
            confirmAlert={constants.button.ok}
          />
        </div>
      </div>
    </div>
  );
};
export default CreateWorkspaceWebView;
