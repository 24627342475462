import { loginConstants } from '../components/Modal/LoginModal/constants';
import { constants } from '../constants/constants';

export const getUserInfo = () => {
  const rememberMe = JSON.parse(localStorage.getItem(loginConstants.loginCredentials.rememberMe));
  let userInfo;
  if (rememberMe) {
    userInfo = localStorage.getItem(constants.localStorageUser);
  } else {
    userInfo = sessionStorage.getItem(constants.localStorageUser);
  }

  return userInfo;
};

export const setUserInfo = userInfo => {
  const rememberMe = JSON.parse(localStorage.getItem(loginConstants.loginCredentials.rememberMe));
  if (rememberMe) {
    localStorage.setItem(constants.localStorageUser, userInfo);
  } else {
    sessionStorage.setItem(constants.localStorageUser, userInfo);
  }
};

export const logout = () => {
  localStorage.clear();
  sessionStorage.clear();
  window.location.pathname = '/';
};
