import React from 'react';
import classes from './modal.module.scss';
import Icons from '../../assets/Icons';
const CustomModal = ({ content, headerTitle, setCustomModalOpen, size, classList }) => {
  return (
    <>
      <div className={`${classes.customModalWrapper} ${size ? size : null} ${classList ? classList : null}`}>
        <div className={classes.customModalContent}>
          <div className={classes.customModalHeader}>
            <h4>{headerTitle ? headerTitle : ''}</h4>
            <button
              aria-label="Close modal"
              data-testid="close-button"
              className={classes.btnClose}
              onClick={() => setCustomModalOpen(false)}
            >
              <Icons.CloseIcon />
            </button>
          </div>
          <div className={classes.customModalBody}>{content}</div>
        </div>
      </div>
      <div className={classes.backdrop}></div>
    </>
  );
};
export default CustomModal;
