export const loginConstants = {
  title: 'Login',
  loginTitles: ['email', 'password', 'captchaToken'],
  incorrectResponseError: 'Incorrect email or password',
  loginCredentials: {
    accessToken: 'accessToken',
    refreshToken: 'refreshToken',
    role: 'role',
    id: 'id',
    name: 'name',
    rememberMe: 'rememberMe',
    isLoggedIn: 'isLoggedIn',
    tenantId: 'tenantId',
    currentWindow: 'windowLocation',
    email: 'email',
    Tenant: {
      name: 'tenantName',
    },
    owner: 'owner',
    firstName: 'firstName',
    lastName: 'lastName',
    phone: 'phone',
    countryCode: 'countryCode',
    isMobileVerified: 'isMobileVerified',
    profileImage: 'profileImage',
    trialEnd: 'trialEnd',
    subscriptionType: 'subscriptionType',
  },
  localKey: 'userInfo',
  localChannelKey: 'channelList',
  subbedChannelKey: 'subbedChannels',
  channelStoreKey: 'channelStoreKey',
  textConstants: {
    newUser: 'New User?',
    signupNow: 'Sign up now',
    email: 'Email Address',
    password: 'Password',
    rememberMe: 'Remember me',
    forgotPassword: 'Forgot Password?',
    resetPassword: 'Reset Password',
  },
  otpModal: {
    subHeading: 'Authenticate Your Account',
    details:
      'Protecting your data is our highest priority. Please confirm your account by entering the authentication code sent to your registered mobile number and email',
    resendCode: 'Resend Code',
  },
  validationErrorMessages: {
    emptyString: '',
    passwordMatchError: 'passwordMatch',
    loginError: 'loginError',
    email: {
      required: 'Please enter a  email address',
      validateError: 'Please enter a valid email address',
    },
    password: {
      required: 'Please enter your password',
    },
    captchaToken: {
      required: 'Select captcha',
    },
  },
};
