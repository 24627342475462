/* eslint-disable no-unused-vars */
import { API } from '../../constants/api';
import { post, del } from '../../utils/apiHandler';

export const createWorkspaceService = async createWorkspaceData => {
  const expiringViewerData = sessionStorage.getItem('expiringViewers');
  let expiringViewers = [];
  let newViewersList = createWorkspaceData?.newViewers;
  if (expiringViewerData) {
    expiringViewers = JSON.parse(expiringViewerData);
    newViewersList = createWorkspaceData?.newViewers.filter(function (item) {
      return !expiringViewers.includes(item);
    });
  }
  const expiringViewersSet = new Set(expiringViewers);
  const existingViewersSet = new Set(createWorkspaceData?.existingViewers);
  const newViewersSet = new Set(newViewersList);

  try {
    const data = {
      name: createWorkspaceData.name,
      accounts: createWorkspaceData.accounts,
      users: createWorkspaceData.users,
      existingViewers: [...existingViewersSet],
      newViewers: [...newViewersSet],
      newExpiringViewers: [...expiringViewersSet],
    };
    const response = await post(API.workspace_list, data);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const deleteWorkspaceService = async (workspaceName, workspaceOwner) => {
  try {
    const response = await del(`${API.workspace_list}/${workspaceName}?createdBy=${workspaceOwner}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
