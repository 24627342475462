import React, { useState } from 'react';
import Compressor from 'compressorjs';
import EasyCrop from './easyCrop';
import { cropConstants } from './constants';
import Icons from '../../assets/Icons';
import { useToastBox } from '../../customHooks/useToastBox';
import Loader from '../Loader';
import { ACCEPTED_IMAGE_TYPES } from '../../constants/constants';

const AvatarInput = props => {
  const [loading, setIsLoading] = useState(false);
  const { setprofileImageUrl, setAvatarChange, profileInfo, nameInitials } = props;
  const [isOpen, setIsOpen] = useState(true);
  const [image, setImage] = useState(null);
  const [croppedImage, setCroppedImage] = useState(null);
  const [showCropDialogue, setShowCropDialogue] = useState(false);
  const [removePhoto, setRemovePhoto] = useState(false);
  const { handleErrorToast } = useToastBox();

  const avatarSelect = compressedFile => {
    // Read in the image file
    const file = compressedFile;
    const reader = new FileReader();
    reader.onload = () => {
      setImage(reader.result);
      setShowCropDialogue(true);
    };
    reader.readAsDataURL(file);
  };

  const handleImageCompression = file => {
    new Compressor(file, {
      quality: 0.6,
      success(result) {
        setIsLoading(false);
        if (result?.size >= 10485760) {
          handleErrorToast(cropConstants.uploadError);
        } else {
          const compressedFile = new File([result], file.name, { type: file.type });
          avatarSelect(compressedFile);
        }
      },
      error(err) {
        console.log(err.message);
      },
    });
  };

  const handleRemove = () => {
    setprofileImageUrl('');
    setAvatarChange(true);
    setCroppedImage(null);
    setRemovePhoto(true);
  };

  const handleInputChange = event => {
    if (ACCEPTED_IMAGE_TYPES.includes(event.target.files[0].type)) {
      setIsLoading(true);
      handleImageCompression(event.target.files[0]);
    } else {
      handleErrorToast('Please ensure you upload a .jpg, .png, .webp or .svg file');
    }
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="flex w-[130px] flex-col justify-center">
      <div className=" flex h-[130px] w-[130px] min-w-[130px] items-center justify-center overflow-hidden rounded-full bg-gray-dark1 text-5.3xl font-semibold leading-15 text-white">
        {profileInfo.profileImage && !croppedImage && !removePhoto ? (
          <img className="h-[130px] w-[130px] object-cover" src={profileInfo.profileImage} alt="profile image" />
        ) : croppedImage && !showCropDialogue ? (
          <img className="h-[130px] w-[130px] object-cover" src={croppedImage} alt="profile image" />
        ) : (
          <span>{nameInitials}</span>
        )}

        {image && showCropDialogue && (
          <EasyCrop
            image={image}
            setCroppedImage={setCroppedImage}
            setShowCropDialogue={setShowCropDialogue}
            setprofileImageUrl={setprofileImageUrl}
            setAvatarChange={setAvatarChange}
            isOpen={isOpen}
            setIsOpen={setIsOpen}
          />
        )}
      </div>
      <div className="relative mb-4 w-full space-y-3 overflow-hidden pt-4">
        <button className=" bg-indigo hover:bg-indigo-dark flex w-full cursor-pointer items-center justify-center p-0 text-xs font-medium text-primary">
          <span className="mr-2">{cropConstants.uploadPhoto}</span>
          <Icons.Upload />
        </button>
        {(profileInfo.profileImage || croppedImage) && (
          <button
            className={
              removePhoto && !croppedImage
                ? ' bg-indigo hover:bg-indigo-dark flex w-full cursor-pointer items-center justify-center p-0 text-xs font-medium text-primary opacity-40'
                : ' bg-indigo hover:bg-indigo-dark flex w-full cursor-pointer items-center justify-center p-0 text-xs font-medium text-primary'
            }
            onClick={handleRemove}
            disabled={removePhoto && !croppedImage}
          >
            <span className="mr-2">{cropConstants.removePhoto}</span>
            <Icons.DeleteIcon />
          </button>
        )}
        <input
          type="file"
          className="absolute top-0 block w-full cursor-pointer opacity-0"
          accept="image/*"
          name="avatar"
          onChange={event => {
            handleInputChange(event);
            setIsOpen(true);
          }}
          onClick={event => {
            event.target.value = null;
          }}
        />
      </div>
    </div>
  );
};

export default AvatarInput;
