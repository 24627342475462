import { API } from '../constants/api';
import { get } from '../utils/apiHandler';

export const listWorkspace = async () => {
  try {
    const response = await get(API.workspace_list);

    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const workspaceSummaryService = async () => {
  try {
    const response = await get(API.workspace_summary);

    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
