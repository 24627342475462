import React from 'react';
import Select from 'react-select';
import classes from './filter.module.scss';
// import { options } from './constants';
import Icons from '../../assets/Icons';

const FilterComponentDP = ({ setFilter, channelOptions, setChannelValue, onApply }) => {
  return (
    <div className={`${classes.dropdownCustom} ${classes.bottom} ${classes.mobFloat}`}>
      <div className={classes.dropdownBackdrop} />
      <div className={classes.dropdownContent}>
        <div className={classes.dropdownHeader}>
          <h3 className={classes.title}>Filter Alerts</h3>
          <div className={classes.actiongroup}>
            <button
              className={`btn-icon ${classes.closeBtn}`}
              data-testid="filter-close"
              aria-label="Close Filter Alert"
              onClick={() => setFilter(false)}
            >
              <Icons.Cross />
            </button>
          </div>
        </div>
        <div className={classes.dropdownBody}>
          <form>
            <div className={classes.formControlWrapper}>
              <label className={classes.formControlLabel}>Filter by Channel</label>
              <Select
                options={channelOptions}
                placeholder="Select a Channel"
                // value={''}
                classNamePrefix="hydra-dropdown"
                onChange={event => {
                  setChannelValue(event.value);
                }}
              />
            </div>
            {/* Commented beacuse there is no workspace updates for alerts */}

            {/* <div className={classes.formControlWrapper}>
              <label className={classes.formControlLabel}>Filter by Workspace</label>
              <Select options={options} placeholder="Select" value={''} classNamePrefix="hydra-dropdown" />
            </div> */}
          </form>
        </div>
        <div className={classes.dropdownFooter}>
          <div className={classes.buttonGroupRight}>
            <button aria-label="Apply this filter" className="btn-outline -type-2" onClick={onApply}>
              Apply
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};
export default FilterComponentDP;
