import { API } from '../../constants/api';
import { post } from '../../utils/apiHandler';

export const mobileOtp = async data => {
  try {
    const response = await post(API.sendMobileOtp, data);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
