export const options = [
  { value: 'chocolate', label: 'Chocolate' },
  { value: 'strawberry', label: 'Strawberry' },
  { value: 'vanilla', label: 'Vanilla' },
];

export const regularities = [
  { value: 'daily', label: 'Daily' },
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'quarterly', label: 'Quarterly' },
];

export const constants = {
  isEmptyString: '',
  validationMessages: {
    isEmptyMessages: {
      channel: 'Please select a channel',
      page: 'Please select a page',
      date: 'Please check the dates',
      noAccounts: 'Sorry, no accounts found with this channel',
      groupName: 'Please Enter a group Name',
      groupNameMax: 'Group Name too long',
      groupNameMin: 'Group Name too short',
      userList: 'Please select at least one user',
      regularity: 'Please select a frequency',
    },
    noExcelSheetMessage: 'No updates found during this period',
    downloadReportMessage: 'Report Downloaded',
  },
  allOption: { value: 'All', label: 'All', key: 0 },
  nonEditableChannels: ['googleads', 'googlesearchconsole', 'linkedinads', 'linkedinpages', 'twitterads'],
};

export const dummyData = {
  create: [
    {
      id: 1,
      temaIndicador: 'Indian',
      codigo: '001',
      observaciones: 'Interactions Specialist tertiary Regional Tennessee',
      activo: 'SI',
      color: 'cyan',
      createdAt: '2022-01-26T18:48:36.002Z',
    },
    {
      id: 2,
      temaIndicador: 'Indian',
      codigo: '001',
      observaciones: 'Interactions Specialist tertiary Regional Tennessee',
      activo: 'SI',
      color: 'cyan',
      createdAt: '2022-01-26T18:48:36.002Z',
    },
    {
      id: 3,
      temaIndicador: 'Indian',
      codigo: '001',
      observaciones: 'Interactions Specialist tertiary Regional Tennessee',
      activo: 'SI',
      color: 'cyan',
      createdAt: '2022-01-26T18:48:36.002Z',
    },
  ],
  delete: [
    {
      id: 1,
      temaIndicador: 'Indian',
      codigo: '001',
      observaciones: 'Interactions Specialist tertiary Regional Tennessee',
      activo: 'SI',
      color: 'cyan',
      createdAt: '2022-01-26T18:48:36.002Z',
    },
    {
      id: 2,
      temaIndicador: 'Indian',
      codigo: '001',
      observaciones: 'Interactions Specialist tertiary Regional Tennessee',
      activo: 'SI',
      color: 'cyan',
      createdAt: '2022-01-26T18:48:36.002Z',
    },
    {
      id: 3,
      temaIndicador: 'Indian',
      codigo: '001',
      observaciones: 'Interactions Specialist tertiary Regional Tennessee',
      activo: 'SI',
      color: 'cyan',
      createdAt: '2022-01-26T18:48:36.002Z',
    },
  ],
  update: [
    {
      id: 1,
      temaIndicador: 'Indian',
      codigo: '001',
      observaciones: 'Interactions Specialist tertiary Regional Tennessee',
      activo: 'SI',
      color: 'cyan',
      createdAt: '2022-01-26T18:48:36.002Z',
    },
    {
      id: 2,
      temaIndicador: 'Indian',
      codigo: '001',
      observaciones: 'Interactions Specialist tertiary Regional Tennessee',
      activo: 'SI',
      color: 'cyan',
      createdAt: '2022-01-26T18:48:36.002Z',
    },
    {
      id: 3,
      temaIndicador: 'Indian',
      codigo: '001',
      observaciones: 'Interactions Specialist tertiary Regional Tennessee',
      activo: 'SI',
      color: 'cyan',
      createdAt: '2022-01-26T18:48:36.002Z',
    },
  ],
};
