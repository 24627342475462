import React, { useEffect, useState } from 'react';
import CryptoJS from 'crypto-js';
import { useNavigate } from 'react-router-dom';
import Icons from '../../../assets/Icons';
import { constants } from '../constants';
import { listUserLicences } from './helper';
import useAuth from '../../../customHooks/useAuth';
import { subscriptionPlans } from '../../../constants/constants';
import { useToastBox } from '../../../customHooks/useToastBox';
import { getUserInfo } from '../../../utils/getUserInfo';

const AlertBox = ({ classes }) => {
  let subscriptionType;
  const userInfo = JSON.parse(getUserInfo());
  const subscriptionCode = userInfo?.user?.subscriptionType;
  if (subscriptionCode) {
    subscriptionType = CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8);
  }

  const navigate = useNavigate();
  const [userLicenseData, setUserLicenseData] = useState({
    activeUsers: 0,
    additionalLicenses: 0,
    availableActiveLicenses: 0,
    availableExpiringLiceses: 0,
    availableLicenses: 0,
    expiringLicenses: 0,
    licensesUsed: 0,
    removableLicenses: 0,
    scheduledRemovalUsers: 0,
    totalLicenses: 0,
  });

  const { auth } = useAuth();
  const tenantId = auth.user ? auth.user.user.tenantId : null;
  const owner = auth.user ? auth.user.user.owner : false;
  const { handleErrorToast } = useToastBox();

  const displayUserLicences = () => {
    listUserLicences(tenantId)
      .then(res => {
        setUserLicenseData(res?.data?.data);
      })
      .catch(err => {
        handleErrorToast(err.message);
      });
  };

  useEffect(() => {
    if (tenantId) {
      displayUserLicences();
    }
  }, [tenantId]);

  return (
    <div className={`${classes.alert} ${classes.success}`}>
      <div className={classes.alertIcon}>
        <Icons.InfoIcon />
      </div>
      {subscriptionType === subscriptionPlans.enterprise ? (
        <div className={classes.alertTitle}>
          {userLicenseData?.licensesUsed ? userLicenseData?.licensesUsed : 0} {constants.alertBox.pluralMessage}
        </div>
      ) : (
        <div className={classes.alertTitle}>
          {userLicenseData?.licensesUsed ? userLicenseData?.licensesUsed : 0} out of {userLicenseData?.totalLicenses}{' '}
          {constants.alertBox.pluralMessage}
        </div>
      )}
      {owner && subscriptionType !== subscriptionPlans.individual && (
        <button
          aria-label={constants.alertBox.button}
          className={`btn-link ${classes.viewAll}`}
          onClick={() => navigate('/userManagement')}
        >
          {constants.alertBox.button}
        </button>
      )}
    </div>
  );
};

export default AlertBox;
