import { API } from '../../constants/api';
import { get } from '../../utils/apiHandler';

export const getAuthUrlService = async provider => {
  try {
    const response = await get(`${API.get_auth_url}?provider=${provider}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
