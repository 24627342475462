export const searchConstants = {
  type: {
    user: 'user',
    workspace: 'workspace',
    page: 'page',
    nonAdminUsers: 'accountUser',
  },
  naviagationConstants: {
    user: '/UserPermissionChannel',
    workspace: '/workspace-detailed',
    page: '/userPermissionPageAccount',
    nonAdminUsers: '/accountUsersView',
  },
};
