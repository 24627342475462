import React from 'react';
import SetupinProgress from '../../assets/images/Search_setup.svg';

const ChannelErrorPage = ({ redirectionURL, isFacebook }) => {
  sessionStorage.setItem('page', 'Nodata');
  return (
    <div className=" flex h-full w-full items-center justify-center ">
      <div className="flex h-[calc(100%-56px)] min-h-[calc(100%-56px)] flex-col items-center justify-center">
        <div className=" mb-14 ">
          <img src={SetupinProgress} className="  mb-12 h-full w-full" alt="Access Locked" />
        </div>
        {/* <h2 className="w-full text-center text-2xl font-semibold leading-6 text-primary100">Could not fetch data!</h2> */}
        <h2 className="w-full text-center text-xl font-semibold leading-6 text-primary100">
          {`We are working on providing ${isFacebook ? 'Facebook Page' : 'Google Search Console'} permissions.`}
        </h2>
        {redirectionURL?.length > 0 && (
          <h2 className="mt-3 w-full text-center text-xl font-semibold leading-6 text-primary100">
            If you are an admin user, you will be able to access your account using the button below.
          </h2>
        )}
        {redirectionURL?.length > 0 && (
          <a
            className={
              'hover:bg-primary500" mt-6  rounded-full bg-primary p-2.5 px-6 text-sm font-semibold leading-6 text-white duration-200 ease-in'
            }
            href={redirectionURL?.length ? redirectionURL : null}
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="text-sm">Redirect to Account</span>
          </a>
        )}
      </div>
    </div>
  );
};

export default ChannelErrorPage;
