import { API } from '../../constants/api';
import { patch } from '../../utils/apiHandler';

export const profileEdit = async (userId, data) => {
  try {
    const response = await patch(`${API.updateUser}/${userId}`, data);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
