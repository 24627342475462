import blueRidge from '../../assets/temp-images/blueRidge.jpg';
import IBMIcon from '../../assets/temp-images/ibm.png';
import InfyIcon from '../../assets/temp-images/infy.png';

export const navData = [
  {
    userType: 'Hydra Authenticated User',
    highlight: false,
    protectOn: true,
    pathHistory: [{ title: 'Chris Martin', highlight: true }, { title: 'Facebook' }, { title: '11 Channels' }],
  },
];
export const currentUser = 'Steveharvey911@gmail.com';
export const textConstants = {
  googleTagManager: 'googletagmanager',
  googleAnalytics: 'ganalytics',
  googleSearchConsole: 'googlesearchconsole',
  googleMyBusiness: 'googlemybusiness',
  facebookAds: 'facebookads',
  facebookPages: 'facebookpages',
  linkedinPages: 'linkedinpages',
  linkedinAds: 'linkedinads',
  addUser: 'Add User',
  twitterAds: 'twitterads',
  googleAds: 'googleads',
  googleMerchantCentre: 'googlemerchantcenter',
  noData: 'You do not have the required admin permissions to view other users of this account.',
  noAccounts: 'No accounts found',
  gadsNoData: 'Account setup in progress.',
  alertMessage: 'Are you sure you want to remove the user from ',
  alertTitle: 'Alert!',
  requestToRemove: 'You do not have the right access. Request to remove the user?',
  removeError: 'The caller does not have permission',
};
export const pageList = [
  {
    title: 'Infosys Blog',
    imgUrl: InfyIcon,
    pagesDetails: [
      {
        logo: blueRidge,
        title: 'Steveharvey911@gmail.com',
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
        removed: false,
      },
      {
        logo: blueRidge,
        title: 'SJakesulla@gmail.com',
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
      },
      {
        logo: blueRidge,
        title: 'Charliemunger23@gmail.com',
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
      },
      {
        logo: blueRidge,
        title: 'Steveharvey911@gmail.com',
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
      },
      {
        logo: blueRidge,
        title: 'Steveharvey911@gmail.com',
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
      },
    ],
  },
  {
    title: 'IBM Facebook',
    imgUrl: IBMIcon,
    pagesDetails: [
      {
        logo: blueRidge,
        title: 'Steveharvey2342@gmail.com',
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '12 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],

        removed: true,
      },
      {
        logo: blueRidge,
        title: 'CharliePack@gmail.com',
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
      },
      {
        logo: blueRidge,
        title: 'SJakesulla1223@gmail.com',
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '21 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
      },
      {
        logo: blueRidge,
        title: 'Steveharvey911@gmail.com',
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
      },
      {
        logo: blueRidge,
        title: 'Steveharvey911@gmail.com',
        lastLogin: [
          {
            location: 'Chester, UK',
            dateTime: '11 Oct, 11:13 AM',
          },
        ],
        roleOptions: [
          { value: 'Admin', label: 'Admin' },
          { value: 'Editor', label: 'Editor' },
          { value: 'Viewer', label: 'Viewer' },
        ],
      },
    ],
  },
];
