export const cropConstants = {
  zoom: 'Zoom',
  crop: 'Crop & Save',
  cancel: 'Cancel',
  cropImage: 'Crop Image',
  uploadPhoto: 'Upload Photo',
  removePhoto: 'Remove Photo',
  profileImageUploadToServerUrl: 'https://hydra-dev-user-data.s3.ap-south-1.amazonaws.com/',
  uploadError: 'Please select an image less than 10 MB',
};
