import { textConstants } from './constants';
import { subbedChannelIconsConstants } from '../Dashboard/ChannelSubbed/constants';

export const processPermissionListChannelView = async (data, roleList, permissionList = null, displayName, channelType) => {
  const pageList = [];
  data?.forEach(element => {
    const channelDetails = getChannelDetails(channelType);
    const userList = [];
    const obj = {
      title: element?.accountName,
      accountId: element?.accountId,
      redirectionURL: element?.manageUsersUrl,
      channelType: channelType,
      setupInProgress: element?.setupInProgress,
    };

    obj.imgUrl = element?.picture ? element?.picture : channelDetails.imgUrl;
    element?.users?.forEach(async user => {
      const userDetails = { removed: false, permission: [], role: [] };
      userDetails.accountName = element.accountName;
      userDetails.id = user.id;
      userDetails.channelAccountId = user.channelAccountId;
      userDetails.channelUserId = user.channelUserId;
      userDetails.accountId = element.accountId;
      userDetails.email = user.userEmail;
      userDetails.title = channelType === textConstants.googleAds ? user.userEmail : user.userName;
      userDetails.removed = false;
      userDetails.logo = null;
      userDetails.lastLogin = [{ dateTime: '-', location: '-' }];
      userDetails.roleLabel = displayName.role;
      userDetails.permissionLabel = displayName.permission ? displayName.permission : '';

      userDetails.accountName = element.accountName;
      userDetails.isBusinessAssociated = user?.userDetails?.isBusinessAssociated;

      if (
        roleList?.length &&
        (channelType == textConstants.googleMerchantCentre ||
          channelType == textConstants.facebookAds ||
          channelType == textConstants.facebookPages)
      ) {
        userDetails.displayRole = user?.userDetails?.rolesLabel ? user?.userDetails?.rolesLabel : [];

        if (user?.userRole) {
          let temp = [];
          const checkId = user?.userRole;

          if (checkId?.length) {
            checkId?.forEach(item => {
              let role = roleList.filter(r => r.id === item);
              if (role) {
                temp?.push({ label: role[0].name, value: role[0].id });
              }
            });
            if (temp.length) {
              userDetails.role.push(...temp);
            } else {
              userDetails.role = null;
            }
          }
        } else {
          userDetails.role = null;
        }
      } else {
        userDetails.displayRole = user?.userDetails?.rolesLabel ? user?.userDetails?.rolesLabel[0] : '-';
        const userRole = user.userRole ? await roleList.filter(r => r.id === user.userRole[0])[0] : null;
        if (userRole) {
          userDetails.role = { label: userRole.name, value: userRole.id };
        } else {
          userDetails.role = { label: '-', value: '-' };
        }
      }

      if (permissionList && channelType == textConstants.googleAnalytics) {
        userDetails.displayPermission = user?.userDetails?.permissionsLabel ? user?.userDetails?.permissionsLabel : [];
        if (user.userDetails?.permissions) {
          let temp = [];
          const checkId = user.userDetails?.permissions;
          if (checkId?.length) {
            checkId?.forEach(item => {
              let permission = permissionList.filter(r => r.id === item);
              if (permission) {
                temp?.push({ label: permission[0].name, value: permission[0].id });
              }
            });
            if (temp.length) {
              userDetails.permission.push(...temp);
            } else {
              userDetails.permission = null;
            }
          }
        } else {
          userDetails.permission = null;
        }
      } else if (permissionList && channelType != textConstants.googleAnalytics) {
        userDetails.displayPermission = user?.userDetails?.permissionsLabel ? user?.userDetails?.permissionsLabel[0] : '-';

        if (user.userDetails?.containerAccess || user.userDetails?.permissions) {
          //for gtag - containeraccess & for ganalytics - permissions
          const checkId = user.userDetails?.containerAccess
            ? user.userDetails?.containerAccess[0]?.permission
            : user.userDetails?.permissions[0];

          const userPermission = await permissionList.filter(r => r.id === checkId)[0];
          if (userPermission) {
            userDetails.permission = { label: userPermission.name, value: userPermission.id };
          } else {
            userDetails.permission = null;
          }
        } else {
          userDetails.permission = null;
        }
      }
      if (channelType === textConstants.googleMerchantCentre) {
        userDetails.isMainAccountUser = user?.userDetails?.isMainAccountUser ? true : false;
        userDetails.isSubAccount = element?.accountDetails[0]?.details?.isSubAccount ? true : false;
      }
      userList.push(userDetails);
    });
    obj.pagesDetails = userList;
    pageList.push(obj);
  });

  return pageList;
};

export const getChannelDetails = channelType => {
  const element = { channelType: channelType, imgUrl: '', title: '', provider: '', roleLabel: '' };
  switch (element.channelType) {
    case subbedChannelIconsConstants.channelType.googleAdsTitle:
      element.imgUrl = subbedChannelIconsConstants.icons.googleAds;

      break;
    case subbedChannelIconsConstants.channelType.googleAnalyticsTitle:
      element.imgUrl = subbedChannelIconsConstants.icons.googleAnalytics;

      break;
    case subbedChannelIconsConstants.channelType.googleMerchant:
      element.imgUrl = subbedChannelIconsConstants.icons.googleMerchantCentre;

      break;
    case subbedChannelIconsConstants.channelType.googleMyBusiness:
      element.imgUrl = subbedChannelIconsConstants.icons.googleBusiness;

      break;
    case subbedChannelIconsConstants.channelType.googleSearchConsole:
      element.imgUrl = subbedChannelIconsConstants.icons.googleSearchConsole;

      break;
    case subbedChannelIconsConstants.channelType.googleTagManager:
      element.imgUrl = subbedChannelIconsConstants.icons.googleTagManager;

      break;
    case subbedChannelIconsConstants.channelType.bingAds:
      element.imgUrl = subbedChannelIconsConstants.icons.bing;

      break;
    case subbedChannelIconsConstants.channelType.facebookAdTitle:
      element.imgUrl = subbedChannelIconsConstants.icons.facebookAds;

      break;
    case subbedChannelIconsConstants.channelType.facebookPagesTitle:
      element.imgUrl = subbedChannelIconsConstants.icons.facebook;

      break;
    case subbedChannelIconsConstants.channelType.linkedInAds:
      element.imgUrl = subbedChannelIconsConstants.icons.linkedin;

      break;
    case subbedChannelIconsConstants.channelType.linkedInPages:
      element.imgUrl = subbedChannelIconsConstants.icons.linkedin;

      break;
    case subbedChannelIconsConstants.channelType.twitterAds:
      element.imgUrl = subbedChannelIconsConstants.icons.twitter;

      break;
    case subbedChannelIconsConstants.channelType.twitterPages:
      element.imgUrl = subbedChannelIconsConstants.icons.twitter;
      break;
    default:
      break;
  }
  return element;
};
