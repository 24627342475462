import { useEffect, useState } from 'react';
import React, { Disclosure } from '@headlessui/react';
import Highlighter from 'react-highlight-words';
import { useNavigate } from 'react-router-dom';
import { individualSearch } from '../../../services/searchService';
import { processNavigation } from '../Webview/helper';
import { channelLabelSelector } from '../../../utils/helpers';

import Icons from '../../../assets/Icons';
export const MobileView = ({ item, highlightWords }) => {
  const navigate = useNavigate();
  const [refresh, setRefresh] = useState(false);
  const handleTypeSearch = (type, id, name) => {
    individualSearch(type, id)
      .then(response => {
        if (response?.data?.data) {
          const navigateToResultPage = processNavigation(type);
          navigate(`${navigateToResultPage}`, { state: { id, name } });
        }
      })
      .catch(error => console.error(error));
  };
  useEffect(() => {
    setRefresh(!refresh);
  }, [highlightWords]);
  return (
    <div
      className="mb-2 hidden md:block"
      onClick={() =>
        item.type === 'accountUser'
          ? handleTypeSearch(item.type, item.id, item.name)
          : handleTypeSearch(item.type, item.referenceId, item.name)
      }
    >
      <Disclosure>
        {({ open }) => (
          /* Use the `open` state to conditionally change the direction of an icon. */
          <div className="rounded-[9px] border border-solid border-white200 bg-white ">
            <Disclosure.Button className="w-full px-4 py-3 text-left">
              <div className="relative">
                <div className="flex items-center">
                  {/* <img src={item.companyLogo} alt={item.detailsTitle} /> */}

                  <h2
                    className="ml-2 max-w-[150px] truncate truncate text-xs font-medium leading-4.5 text-gray-dark"
                    title={item.name}
                  >
                    <Highlighter
                      key={item}
                      searchWords={highlightWords}
                      highlightStyle={{ fontWeight: 'bold' }}
                      autoEscape={true}
                      textToHighlight={item.name}
                    />
                  </h2>
                  <ul className="text-gray-1500 ml-6 list-disc text-xs font-medium leading-4.5">
                    <li>{item.type.charAt(0).toUpperCase() + item.type.slice(1)}</li>
                  </ul>
                </div>
                <div className={`${open ? 'rotate-180 transform' : ''} absolute top-1.5 right-0`}>
                  <Icons.DownArrow />
                </div>
              </div>
            </Disclosure.Button>
            <Disclosure.Panel>
              <div className="px-4 py-3 ">
                {/* <div className="mb-3">
                  <h2 className="mb-0.5 text-[10px] font-normal leading-4 text-gray-dark1">
                    Role
                  </h2>
                  <p
                    className="truncate text-xs font-medium text-gray-dark"
                    title={item.pagePermission}
                  >
                    {item.pagePermission}
                  </p>
                </div> */}
                <div
                  className="flex items-center justify-between gap-x-2"
                  onClick={() =>
                    item.type === 'accountUser'
                      ? handleTypeSearch(item.type, item.id, item.name)
                      : handleTypeSearch(item.type, item.referenceId, item.name)
                  }
                >
                  {item.channelType && (
                    <div className="w-[calc(100%/3)] md:w-full">
                      <h2 className="mb-0.5 text-[10px] font-normal leading-4 text-gray-dark1">Channel</h2>
                      <p
                        className="h-[17px] truncate text-xs font-medium text-gray-dark md:!overflow-auto"
                        title={item.channelType}
                      >
                        {channelLabelSelector(item.channelType)}
                      </p>
                    </div>
                  )}

                  {/* Commented for future needs */}

                  {/* <div className="w-[calc((100%/5)-16px)]">
                    <h2 className="mb-0.5 text-[10px] font-normal leading-4 text-gray-dark1">
                      Pages
                    </h2>
                    <p
                      className="truncate text-xs font-medium text-gray-dark"
                      title={item.type}
                    >
                      {item.type}
                    </p>
                  </div> */}
                  {/* <div className=" w-[calc(100%/3)] ">
                    <h2 className="mb-0.5 text-end text-[10px] font-normal leading-4 text-gray-dark1">
                      Workspaces
                    </h2>
                    <p
                      className="truncate text-end text-xs font-medium text-gray-dark"
                      title={item.accountDetails}
                    >
                      {item.accountDetails}
                    </p>
                  </div> */}
                </div>
              </div>
            </Disclosure.Panel>
          </div>
        )}
      </Disclosure>
    </div>
  );
};
