import { useState } from 'react';
import {
  userLogin,
  twoFactorAuthentication,
  forgotPassword,
  resetPassword,
  verifyEmail,
  userSignUp,
  channelListService,
} from '../services';
import {
  EmailValidation,
  OnlyAlphabetsAndWhiteSpaces,
  OnlyDigitsValidation,
  PasswordValidation,
} from '../utils/validations';
import { signupConstants } from '../components/Modal/SignUpModal/constants';
import { loginConstants } from '../components/Modal/LoginModal/constants';
import { otpConstants } from '../components/Modal/OtpVerificationModal/constants';
import { addUser } from '../services/addUserService';
import { addLicenseOwner } from '../services/addLicenseOwner';
import { addLicenseOwnerConstants } from '../features/LicenseAddingPage/constants';

const useFormHandler = (formData, setFormData, isLogin, emailId, isLicenseOwner) => {
  const [user, setUser] = useState({});
  const [formError, setFormError] = useState({});
  const [dynamicPasswordValidation, setDynamicValidation] = useState(signupConstants.dynamicValidationInitialState);
  let constants, checkArrayTitles;
  if (isLogin) {
    constants = Object.assign(loginConstants);
    checkArrayTitles = loginConstants.loginTitles;
  } else if (isLicenseOwner) {
    constants = Object.assign(addLicenseOwnerConstants);
    checkArrayTitles = addLicenseOwnerConstants.licenseOwnerTitles;
  } else {
    constants = Object.assign(signupConstants);
    checkArrayTitles = signupConstants.signupTitles;
  }

  const handleNameChange = event => {
    if (!event.target.value.trim() || event.target.value === undefined) {
      setFormError(values => ({
        ...values,
        [event.target.name]: constants.validationErrorMessages[event.target.name].required,
      }));
    } else if (!OnlyAlphabetsAndWhiteSpaces(event.target.value)) {
      setFormError(values => ({
        ...values,
        [event.target.name]: constants.validationErrorMessages[event.target.name].onlyAlphabets,
      }));
    } else {
      setFormData(values => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
      setFormError(values => ({
        ...values,
        [event.target.name]: constants.validationErrorMessages.emptyString,
      }));
    }
  };

  const handleTenantNameChange = event => {
    if (!event.target.value.trim() || event.target.value === undefined) {
      setFormError(values => ({
        ...values,
        [event.target.name]: addLicenseOwnerConstants.validationErrorMessages[event.target.name].required,
      }));
    } else {
      setFormData(values => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
      setFormError(values => ({
        ...values,
        [event.target.name]: addLicenseOwnerConstants.validationErrorMessages.emptyString,
      }));
    }
  };

  const handleSubscriptionChange = event => {
    if (!event.target.value.trim() || event.target.value === undefined) {
      setFormError(values => ({
        ...values,
        [event.target.name]: addLicenseOwnerConstants.validationErrorMessages[event.target.name].required,
      }));
    } else {
      setFormData(values => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
      setFormError(values => ({
        ...values,
        [event.target.name]: addLicenseOwnerConstants.validationErrorMessages.emptyString,
      }));
    }
  };

  const handleReferralCodeChange = event => {
    if (event.target.value.trim() || event.target.value !== undefined) {
      setFormData(values => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
    }
  };

  const handleEmailChange = event => {
    if (!event.target.value.trim() || event.target.value === undefined) {
      setFormError(values => ({
        ...values,
        [event.target.name]: constants?.validationErrorMessages?.email?.required,
      }));
    } else if (!EmailValidation(event.target.value)) {
      setFormError(values => ({
        ...values,
        [event.target.name]: constants?.validationErrorMessages?.email?.validateError,
      }));
    } else if (EmailValidation(event.target.value) && isLogin) {
      setFormData(values => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
      setPasswordMatchFormError();
    } else {
      setFormData(values => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
      setFormError(values => ({
        ...values,
        [event.target.name]: constants?.validationErrorMessages?.emptyString,
      }));
    }
  };

  const setPasswordMatchFormError = () => {
    setFormError(values => ({
      ...values,
      [event.target.name]: constants?.validationErrorMessages?.emptyString,
      [constants.validationErrorMessages.passwordMatchError]: constants?.validationErrorMessages?.emptyString,
    }));
  };

  const handleCountryCodeChange = (event, name) => {
    setFormData(values => ({
      ...values,
      [name]: `+${event}`,
    }));
  };

  const handlePhoneChange = (phone, name) => {
    const minLenPhone = 10;
    const maxLenPhone = 14;
    if (!phone.trim() || phone === undefined) {
      setFormError(values => ({
        ...values,
        [name]: constants?.validationErrorMessages?.phone?.required,
      }));
    } else if (!OnlyDigitsValidation(phone)) {
      setFormError(values => ({
        ...values,
        [name]: constants?.validationErrorMessages?.phone?.validateError,
      }));
    } else if (phone?.length > maxLenPhone) {
      setFormError(values => ({
        ...values,
        [name]: constants?.validationErrorMessages?.phone?.maxLimit,
      }));
    } else if (phone?.length < minLenPhone) {
      setFormError(values => ({
        ...values,
        [name]: constants?.validationErrorMessages?.phone?.minLimit,
      }));
    } else {
      setFormData(values => ({
        ...values,
        [name]: phone,
      }));
      setFormError(values => ({
        ...values,
        [name]: constants?.validationErrorMessages?.emptyString,
      }));
    }
    setFormData(values => ({
      ...values,
      [name]: phone,
    }));
  };

  const handlePasswordChange = event => {
    if (!event.target.value.trim() || event.target.value === undefined) {
      setDynamicValidation(signupConstants.dynamicValidationInitialState);
      setFormError(values => ({
        ...values,

        [event.target.name]: constants.validationErrorMessages.password.required,
      }));
    } else if (PasswordValidation(event.target.value, setDynamicValidation) && !isLogin) {
      if (event.target.value === formData.confirmPassword) {
        setFormError(values => ({
          ...values,
          ['confirmPassword']: constants.validationErrorMessages.emptyString,
        }));
        setFormData(values => ({
          ...values,

          [event.target.name]: event.target.value,
        }));
        setFormError(values => ({
          ...values,
          [event.target.name]: constants.validationErrorMessages.emptyString,
        }));
      } else {
        setFormError(values => ({
          ...values,
          ['confirmPassword']: constants.validationErrorMessages.confirmPassword.validateError,
        }));
        setFormData(values => ({
          ...values,

          [event.target.name]: event.target.value,
        }));

        setPasswordMatchFormError();
      }
    } else if (isLogin) {
      setFormData(values => ({
        ...values,

        [event.target.name]: event.target.value,
      }));

      setPasswordMatchFormError();
    } else {
      setFormError(values => ({
        ...values,

        [event.target.name]: constants.validationErrorMessages.password.validateError,
      }));
    }
  };

  const handleConfirmPasswordChange = event => {
    if (formData.password === event.target.value) {
      setFormData(values => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
      setFormError(values => ({
        ...values,
        [event.target.name]: constants.validationErrorMessages.emptyString,
      }));
    } else {
      setFormData(values => ({
        ...values,
        [event.target.name]: event.target.value,
      }));
      setFormError(values => ({
        ...values,
        [event.target.name]: constants.validationErrorMessages.confirmPassword.validateError,
      }));
    }
  };

  const handleCaptchaChange = response => {
    const captchaConstant = 'captchaToken';
    if (!response) {
      setFormError(values => ({
        ...values,
        [captchaConstant]: constants.validationErrorMessages[captchaConstant].required,
      }));
    } else {
      setFormData(values => ({
        ...values,
        [captchaConstant]: response,
      }));
      setFormError(values => ({
        ...values,
        [captchaConstant]: constants.validationErrorMessages.emptyString,
      }));
    }
  };

  const resetForm = (selectInputRef = '') => {
    selectInputRef?.current?.setValue('');
    setFormData(constants.formDataInitialValue);
    setDynamicValidation(signupConstants.dynamicValidationInitialState);
    if (!isLogin && emailId) {
      setFormData(values => ({ ...values, email: emailId }));
    }
    setFormError({});
    checkArrayTitles?.forEach(el => {
      if (el !== 'captchaToken' && el !== 'countryCode') {
        if (document.getElementById(el) !== null) {
          document.getElementById(el).value = '';
        }
      }
    });
  };

  const handleFormSubmit = async (event, tenantId = null, invitationDetails = null, code = null, token = null) => {
    event.preventDefault();
    const required = checkRequiredEmpty();
    const errorFlag = Object.values(formError).every(value => value === undefined || value === '');

    let response;
    if (!required && errorFlag) {
      if (isLogin) {
        response = await userLogin(formData);
      } else {
        setUser({ countryCode: formData.countryCode, mobile: formData.phone, email: formData.email });
        //For testing purpose
        response = await userSignUp(formData, tenantId, invitationDetails, code, token);
      }
      return response;
    }

    // Fixes the display message issue on login double click
    const error = {
      message: formError.passwordMatch,
      error: {
        statusCode: 401,
      },
    };
    throw new Error(error?.message);
  };

  const handleFormReset = (event, selectInputRef) => {
    event.preventDefault();
    resetForm(selectInputRef);
  };

  const checkRequiredEmpty = () => {
    let requiredFlag = false;
    checkArrayTitles?.forEach(title => {
      if (formData[title] === undefined && title !== 'referralCode') {
        requiredFlag = true;
        if (!formError[title]) {
          setFormError(values => ({
            ...values,
            [title]: constants?.validationErrorMessages[title]?.required,
          }));
        }
      }
    });
    return requiredFlag;
  };

  const handleOtpSubmit = async (otp, isForgotPassword, userDetails) => {
    if (userDetails) {
      setUser({ countryCode: userDetails.countryCode, mobile: userDetails.phone });
    }
    if (otp.length !== otpConstants.otpLength) {
      const error = {
        message: otpConstants.errorMessages.inCompleteOtp,
      };
      throw new Error(error?.message);
    }

    if ((user?.mobile || user?.email) && user?.countryCode && otp.length === otpConstants.otpLength) {
      const radix = 10;
      const data = { otp: parseInt(otp, radix) };
      otpConstants?.otpTitles?.forEach(title => {
        data[title] = user[title];
      });
      const response = isForgotPassword
        ? await twoFactorAuthentication(data, isForgotPassword)
        : await twoFactorAuthentication(data);
      return response;
    } else {
      const error = {
        message: otpConstants.errorMessages.unknownOtpError,
      };
      throw new Error(error?.message);
    }
  };

  const handleResetFormSubmit = async (event, resetError, email, isEmptyMessage) => {
    event.preventDefault();
    let response;
    const sucessFlag = !formError[resetError] && !formError[email];
    if (formData.email !== undefined && sucessFlag) {
      response = await forgotPassword(formData);
    } else {
      const error = {
        message: isEmptyMessage,
      };
      throw new Error(error?.message);
    }
    return response;
  };

  const handleAddUserSubmit = async (event, resetError, email, isEmptyMessage, tenantName, isExpiringLicense) => {
    event.preventDefault();
    let response;
    const successFlag = !formError[resetError] && !formError[email];
    if (formData.email !== undefined && successFlag) {
      response = await addUser(formData, tenantName, isExpiringLicense);
    } else {
      const error = {
        message: isEmptyMessage,
      };
      throw new Error(error?.message);
    }
    return response;
  };

  const handleVerfiyEmail = async actualToken => {
    let response;
    if (actualToken) {
      response = await verifyEmail(actualToken);
    } else {
      console.log('Cannot submit', formError); //NOSONAR
    }
    return response;
  };

  const generateData = (responseData, code, otpResponseData, actualToken) => {
    let data;
    if (!code) {
      data = { email: otpResponseData.email, token: otpResponseData.token, password: formData.password };
    } else {
      data = { email: responseData.email ?? otpResponseData.email, password: formData.password, code, token: actualToken };
    }
    return data;
  };

  const handlePasswordResetError = (successPassword, passwordRequiredMessage, successMail, emailVerifyMessage) => {
    const error = {
      message: !successPassword ? passwordRequiredMessage : !successMail ? emailVerifyMessage : '',
    };
    throw new Error(error?.message);
  };

  const listChannels = async () => {
    try {
      const response = await channelListService();
      return response;
    } catch (error) {
      console.error(error); //NOSONAR
    }
  };

  const handlePasswordReset = async (
    event,
    responseData,
    passwordRequiredMessage,
    emailVerifyMessage,
    code = 0,
    actualToken,
    otpResponseData
  ) => {
    event.preventDefault();
    let response;
    const successMail = otpResponseData ? !!otpResponseData.email : !!responseData.email;
    const successToken = otpResponseData ? !!otpResponseData.token : true;
    const successPassword = !!formData.password && !!formData.confirmPassword;
    const successPasswordMatch = formData.password === formData.confirmPassword;
    const successErrorMessage = !formError.resetError && !formError.password && !formError.confirmPassword;

    if (successMail && successPassword && successPasswordMatch && successErrorMessage && successToken) {
      const data = generateData(responseData, code, otpResponseData, actualToken);
      response = await resetPassword(data);
    } else {
      handlePasswordResetError(successPassword, passwordRequiredMessage, successMail, emailVerifyMessage);
    }
    return response;
  };

  const handleAddLicenseOwner = async event => {
    event.preventDefault();
    const required = checkRequiredEmpty();
    const errorFlag = Object.values(formError).every(value => value === undefined || value === '');
    let response;
    if (!required && errorFlag) {
      response = await addLicenseOwner(formData);
      return response;
    } else {
      const error = {
        message: addLicenseOwnerConstants.isEmptyMessage,
      };
      throw new Error(error?.message);
    }
  };

  return {
    formData,
    formError,
    user,
    setUser,
    setFormError,
    handleNameChange,
    handleEmailChange,
    handlePhoneChange,
    handlePasswordChange,
    handleConfirmPasswordChange,
    handleCaptchaChange,
    handleFormSubmit,
    handleFormReset,
    handleCountryCodeChange,
    handleOtpSubmit,
    handleResetFormSubmit,
    handlePasswordReset,
    handleVerfiyEmail,
    resetForm,
    listChannels,
    handleAddUserSubmit,
    handleAddLicenseOwner,
    handleTenantNameChange,
    handleSubscriptionChange,
    dynamicPasswordValidation,
    handleReferralCodeChange,
  };
};

export default useFormHandler;
