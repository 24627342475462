import React from 'react';
import CryptoJS from 'crypto-js';
import { Link } from 'react-router-dom';
import { sidebar } from './constants';
import Icons from '../../assets/Icons';
import { subscriptionPlans } from '../../constants/constants';
import { getUserInfo } from '../../utils/getUserInfo';

const Sidebar = ({ classes, role }) => {
  let subscriptionType;
  let messageCount = sessionStorage.getItem('message_count');
  const userInfo = JSON.parse(getUserInfo());
  const subscriptionCode = userInfo?.user?.subscriptionType;
  if (subscriptionCode) {
    subscriptionType = CryptoJS?.AES?.decrypt(subscriptionCode, 'subscription_type')?.toString(CryptoJS.enc.Utf8);
  }
  const currentPage = sessionStorage.getItem('page');
  let sideBarList = [];

  const handleClick = label => {
    localStorage.setItem('page', label);
  };

  const filterSidebar = indices => {
    return sidebar.filter((element, index) => {
      return !indices.includes(index);
    });
  };

  const workspaceListIndex = sidebar.findIndex(item => item.title === 'Workspace List');

  const dashboardIndex = sidebar.findIndex(item => item.title === 'Dashboard');
  const reportsIndex = sidebar.findIndex(item => item.title === 'Report');
  const messagesIndex = sidebar.findIndex(item => item.title === 'Messaging');

  if (role === 'user') {
    let indicesToIncludeForUser = [workspaceListIndex];
    let filteredSidebar = sidebar.filter((element, index) => {
      return indicesToIncludeForUser.includes(index);
    });

    sideBarList = filteredSidebar;
  } else if (subscriptionType === subscriptionPlans.individual) {
    let indicesToIncludeForUser = [dashboardIndex, reportsIndex, messagesIndex];
    let filteredSidebar = sidebar.filter((element, index) => {
      return indicesToIncludeForUser.includes(index);
    });

    sideBarList = filteredSidebar;
  } else {
    let indicesToExcludeForAll = [workspaceListIndex];
    sideBarList = filterSidebar(indicesToExcludeForAll);
  }

  return (
    <>
      <ul className={classes.navList}>
        {sideBarList?.map((item, index) => {
          return (
            <li role="presentation" key={`individualTitle__${index}`}>
              <Link
                onClick={() => handleClick(item.label)}
                className={currentPage === item.label ? `${classes.navItem} ${classes.navActive}` : `${classes.navItem}`}
                aria-label={item.label}
                to={item.route}
              >
                {item.icon}
                <span className={classes.navText}>{item.title}</span>
                {item.title == 'Messaging' && messageCount > 0 && <span className={classes.navCount}>{messageCount}</span>}
              </Link>
            </li>
          );
        })}
      </ul>

      {/* For phone sidebar */}
      <ul className={`${classes.otherLinks} ${classes.navList}`}>
        <li role="presentation">
          <Link className={`${classes.navItem}`} aria-label="Home">
            <Icons.Help />
            <span className={classes.navText}>Help</span>
          </Link>
        </li>
      </ul>
    </>
  );
};

export default Sidebar;
