import Workspace from '../../assets/images/Workspace.svg';
import logo1 from '../../assets/images/Infosys.svg';
import logo2 from '../../assets/images/accenture.svg';
import logo3 from '../../assets/images/IBM.svg';

export const navData = [
  {
    userType: 'Workspaces for',
    protectOn: false,
    pathHistory: [{ title: 'Chris Martin', highlight: true }, { title: '7 Workspaces' }],
  },
];
export const pageList = [
  {
    channelName: 'Workspace A',
    logo: Workspace,
    settingUrl: '#',
    pagesDetails: [
      {
        logo: logo1,
        title: 'Infosys Blog',
        link: 'www.infosys.com/blog',
      },
      {
        logo: logo3,
        title: 'IBM Facebook',
        link: 'www.ibm.com/blog',
      },
      {
        logo: logo3,
        title: 'IBM Blog',
        link: 'www.ibm.com/blog',
      },
      {
        logo: logo1,
        title: 'Axis Medium',
        link: 'www.medium.com/axis',
      },
    ],
  },
  {
    channelName: 'Workspace A',
    logo: Workspace,
    settingUrl: '#',
    pagesDetails: [
      {
        logo: logo1,
        title: 'Infosys LinkedIn',
        link: 'www.infosys.com/blog',
      },
      {
        logo: logo1,
        title: 'Infosys LinkedIn Pages',
        link: 'www.linkedin.com/pages/infosys',
      },
    ],
  },
  {
    channelName: 'Workspace A',
    logo: Workspace,
    settingUrl: '#',
    pagesDetails: [
      {
        logo: logo2,
        title: 'Infosys Blog',
        link: 'www.infosys.com/blog',
      },
    ],
  },
  {
    channelName: 'Workspace A',
    logo: Workspace,
    settingUrl: '#',
    pagesDetails: [
      {
        logo: logo2,
        title: 'Accenture Blog',
        link: 'www.accenture.com/blog',
      },
      {
        logo: logo2,
        title: 'Accenture Medium',
        link: 'www.medium.com/accenture',
      },
    ],
  },
];
