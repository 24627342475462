import React, { Fragment } from 'react';
import { Dialog, Transition } from '@headlessui/react';
import Icons from '../../../assets/Icons';
export const MessageModal = ({
  isOpen,
  setIsOpen,
  message,
  setResetPasswordModalIsOpen,
  heading,
  buttonConfirmLabel = '',
  buttonCancelLabel = '',
  confirmAlert = '',
  subMessage = '',
  handleConfirm = () => {},
}) => {
  const buttonNeeded = buttonConfirmLabel ? true : false;
  const handleClose = () => {
    setResetPasswordModalIsOpen(false); //dont know who u r
    setIsOpen(false);
  };

  return (
    <Transition appear show={isOpen} as={Fragment}>
      <Dialog as="div" className="relative z-10" onClose={() => {}}>
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-black bg-opacity-60" />
        </Transition.Child>

        <div className="fixed inset-0 overflow-y-auto">
          <div className="flex min-h-full items-center justify-center p-4 text-center ">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 scale-95"
              enterTo="opacity-100 scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 scale-100"
              leaveTo="opacity-0 scale-95"
            >
              <Dialog.Panel className="w-full max-w-[27.375rem] transform overflow-hidden rounded-lg  transition-all">
                <>
                  <Dialog.Title
                    as="div"
                    className="login-modal-header relative flex  min-h-[6.5rem] items-center justify-center overflow-hidden bg-primary300 p-4 text-black shadow-none md:min-h-[7.938rem]"
                  >
                    <div className="z-10 text-white">
                      <h2 className="mb-2 text-2xl font-bold leading-9 md:mb-3">{heading}</h2>
                    </div>
                    <button className="absolute top-4 right-4 z-10 text-white" onClick={handleClose}>
                      <Icons.CloseIcon />
                    </button>
                  </Dialog.Title>
                  <div className="bg-white  p-8 md:px-4.5 md:pt-6 md:pb-10">
                    <div className="user-details-grid grid w-full grid-cols-1 gap-6 md:gap-4"></div>
                    {message}
                    <div>
                      <h4 style={{ color: 'red' }}>{subMessage}</h4>
                    </div>
                    {buttonNeeded && (
                      <div className="footer-buttons grid w-full  grid-cols-2 items-center justify-between gap-2 pt-8 md:pt-8">
                        <button
                          className="btn-secondary-outline  w-full !min-w-0 text-primary hover:!bg-white hover:text-primary"
                          onClick={handleClose}
                        >
                          {buttonCancelLabel}
                        </button>
                        <button
                          className="w-full !min-w-0 bg-primary text-white"
                          onClick={() => {
                            handleClose();
                            handleConfirm();
                          }}
                        >
                          {buttonConfirmLabel}
                        </button>
                      </div>
                    )}
                    {confirmAlert && (
                      <div className="footer-buttons grid w-full  grid-cols-1 items-center justify-between gap-2 pt-8 md:pt-8">
                        <button
                          className="w-full !min-w-0 bg-primary text-white"
                          onClick={() => {
                            handleConfirm();
                            handleClose();
                          }}
                        >
                          {confirmAlert}
                        </button>
                      </div>
                    )}
                  </div>
                </>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition>
  );
};
