import { API } from '../constants/api';
import { get } from '../utils/apiHandler';

export const search = async params => {
  try {
    const response = await get(`${API.search}?name=${params}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const individualSearch = async (type, id) => {
  try {
    const response = await get(`${API.individualSearch}/${type}/${id}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const pageAccount = async pageId => {
  try {
    const response = await get(`${API.pageAccount}/${pageId}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const accountUser = async accountId => {
  try {
    const response = await get(`${API.accountUser}/${accountId}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
