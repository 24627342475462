/* eslint-disable no-unused-vars */
import React, { useState, useContext, useEffect } from 'react';
import ChannelList from '../../components/ChannelSetup';
import CommonDashboard from '../../components/CommonDashboard';
import NoData from '../../components/Nodata';
import { tabs, textConstants, tabTypes, tabTitle } from './constants';
import { MobileView } from './MobileView';
import { WebView } from './Webview';
import SearchContext from '../../context/auth/searchContext';
import { filterDataByTab, getDataCount } from './helper';
import Loader from '../../components/Loader';

const getTabDataCount = (tab, data) => {
  switch (tab) {
    case tabTitle.pages:
      return data.pageCount;
    case tabTitle.workspaces:
      return data.workspaceCount;
    case tabTitle.users:
      return data.userCount;
    case tabTitle.accountUsers:
      return data.accountUserCount;
    default:
      return data.allCount;
  }
};

const TabHeader = ({ activeTab, onTabClicked, dataCount }) => {
  return (
    <div className="flex items-center overflow-auto">
      {tabs.map((tab, index) => (
        <button
          key={`tabs_${index}`}
          type="button"
          onClick={() => onTabClicked(tab)}
          className={`flex items-center whitespace-nowrap px-4 pb-2 text-xs font-normal leading-4.5 md:pb-[15px] md:text-sm md:leading-[21px] ${
            activeTab === tab
              ? 'border-b-3 border-solid border-primary !pb-[5px] font-medium text-primary duration-150 md:!pb-3 '
              : 'text-gray-1500 font-normal'
          }`}
        >
          {tab}{' '}
          <div className=" ml-2 flex h-4 min-w-[16px] items-center justify-center rounded-full bg-primary p-1 text-[10px] font-medium text-white">
            {getTabDataCount(tab, dataCount)}
          </div>
          {}
        </button>
      ))}
    </div>
  );
};
const SearchResults = () => {
  let searchKeyword = sessionStorage.getItem('searchKeyword');

  const { searchResults } = useContext(SearchContext);
  const [loading, setIsLoading] = useState(true);
  const [activeTab, setActiveTab] = useState('All');
  const [item, setItem] = useState(searchResults);
  const [highlightWords, setHighlightWords] = useState([]);
  const [dataCount, setDataCount] = useState({
    allCount: 0,
    pageCount: 0,
    workspaceCount: 0,
    userCount: 0,
    accountUserCount: 0,
  });

  sessionStorage.setItem('page', 'search');

  useEffect(() => {
    setItem(searchResults);
    setActiveTab('All');
    highlightWords.splice(0, highlightWords?.length);
    highlightWords.push(searchKeyword?.trim());
    let words = searchKeyword.split(' ');
    words.map(word => {
      if (word?.trim()?.length > 2) {
        highlightWords.push(word?.trim());
      }
    });
    const dataCount = getDataCount(searchResults);

    setDataCount({
      allCount: searchResults?.length,
      pageCount: dataCount[0],
      workspaceCount: dataCount[1],
      userCount: dataCount[2],
      accountUserCount: dataCount[3],
    });
    setIsLoading(false);
  }, [searchResults, highlightWords]);

  const handleTabClicked = tab => {
    const filteredData = filterDataByTab(searchResults, tab);

    setItem(filteredData);
    setActiveTab(tab);
  };

  return loading ? (
    <Loader />
  ) : (
    <div className="search-results h-full bg-white300">
      <CommonDashboard hideHeader={false}>
        <div className="xl:hidden">
          <ChannelList shouldAuthenticate={false} onLoad={() => {}} isLoading={false} />
        </div>
        <div className="custom-vertical-scrollbar h-full w-full overflow-hidden  md:-mx-5 md:-mt-4 md:block md:w-[calc(100%+40px)]">
          <div className="w-full border-b border-solid border-white200 bg-white px-4 pt-4 ">
            <div className="mb-3.5 flex items-center md:mb-4">
              <h2 className="mr-2 text-sm font-semibold leading-[21px] text-gray-dark md:text-base md:font-medium md:leading-6">
                Search results for {searchKeyword}
              </h2>
              <ul className="text-gray-1500 ml-6 list-disc text-sm font-medium leading-[21px] md:text-base md:font-normal md:leading-6">
                <li>
                  {searchResults?.length} {searchResults?.length == 1 ? 'result' : 'results'}
                </li>
              </ul>
            </div>
            <div className="custom-horizontal-scrollbar">
              <TabHeader activeTab={activeTab} onTabClicked={handleTabClicked} dataCount={dataCount} />
            </div>
          </div>
          <div className="h-full max-h-[calc(100vh-220px)] min-h-[calc(100vh-220px)] overflow-auto bg-white300 p-4 md:h-[calc(100%-95px)] md:px-4 md:pt-4 md:pb-0">
            {item?.length > 0 ? (
              item.map((element, index) => {
                return (
                  <>
                    <WebView item={element} key={`components_${index}`} highlightWords={highlightWords} />
                    <MobileView item={element} key={`mobileComponents_${index}`} highlightWords={highlightWords} />
                  </>
                );
              })
            ) : !loading ? (
              <div className="flex h-full items-center justify-center">
                <NoData message={textConstants.noData} showImage={true} />
              </div>
            ) : null}
          </div>
        </div>
      </CommonDashboard>
    </div>
  );
};
export default SearchResults;
