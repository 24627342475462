import CryptoJS from 'crypto-js';
import { loginConstants } from './constants';

const manageLoginCredentials = (loginCredentials, rememberMe, setUserData, setNewUser, subscriptionType) => {
  if (loginCredentials) {
    const userInfo = { user: {}, token: {}, subscriptionDetails: {} };
    const encrypteRole = CryptoJS.AES.encrypt(loginCredentials.user.type, loginConstants.localKey).toString();

    userInfo.user[loginConstants.loginCredentials.id] = loginCredentials.user.id;
    userInfo.user[loginConstants.loginCredentials.role] = encrypteRole;
    userInfo.user[loginConstants.loginCredentials.email] = loginCredentials.user.email;
    userInfo.user[loginConstants.loginCredentials.firstName] = loginCredentials.user.firstName;
    userInfo.user[loginConstants.loginCredentials.lastName] = loginCredentials.user.lastName;
    userInfo.user[loginConstants.loginCredentials.phone] = loginCredentials.user.mobile;
    userInfo.user[loginConstants.loginCredentials.countryCode] = loginCredentials.user.countryCode;
    userInfo.user[loginConstants.loginCredentials.isMobileVerified] = loginCredentials.user.isMobileVerified;
    userInfo.user[loginConstants.loginCredentials.profileImage] = loginCredentials.user.profileImage;
    userInfo.user[
      loginConstants.loginCredentials.name
    ] = `${loginCredentials.user.firstName} ${loginCredentials.user.lastName}`;
    userInfo.user[loginConstants.loginCredentials.tenantId] = loginCredentials.user.tenantId;
    userInfo.user[loginConstants.loginCredentials.owner] = loginCredentials.user.owner;
    userInfo.user[loginConstants.loginCredentials.Tenant.name] = loginCredentials.user?.Tenant?.name;
    userInfo.token[loginConstants.loginCredentials.accessToken] = loginCredentials.tokens.access.token;
    userInfo.token[loginConstants.loginCredentials.refreshToken] = loginCredentials.tokens.refresh.token;
    // userInfo[loginConstants.loginCredentials.rememberMe] = rememberMe;
    userInfo[loginConstants.loginCredentials.isLoggedIn] = true;
    userInfo.user[loginConstants.loginCredentials.subscriptionType] = subscriptionType;
    if (loginCredentials.subscriptionDetails) {
      userInfo.subscriptionDetails[loginConstants.loginCredentials.trialEnd] = loginCredentials.subscriptionDetails.trialEnd;
    }
    localStorage.setItem(loginConstants.loginCredentials.rememberMe, rememberMe);
    if (rememberMe) {
      localStorage.setItem(loginConstants.localKey, JSON.stringify(userInfo));
    } else {
      sessionStorage.setItem(loginConstants.localKey, JSON.stringify(userInfo));
    }
    setUserData(userInfo);
    setNewUser(loginCredentials.user.id);
  }
};

const handleChannelList = (data, setChannelList) => {
  setChannelList(data);
  sessionStorage.setItem(loginConstants.localChannelKey, JSON.stringify(data));
};

const handleEncryption = data => {
  const channelList = [...data];
  channelList?.forEach(channel => {
    const stringValue = channel?.isDisabled?.toString();
    channel.isDisabled = CryptoJS.AES.encrypt(stringValue, loginConstants.channelStoreKey).toString();
    return channel;
  });
  return channelList;
};

const storeSubbedChannelList = (data, setSubbedChannels) => {
  setSubbedChannels(data);
  localStorage.setItem(loginConstants.subbedChannelKey, JSON.stringify(data));
};

export { manageLoginCredentials, handleChannelList, storeSubbedChannelList, handleEncryption };
