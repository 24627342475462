import { API } from '../../constants/api';
import { get } from '../../utils/apiHandler';

export const viewersList = async () => {
  try {
    const response = await get(`${API.listUsers}?allUsers=${true}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
