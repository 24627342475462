import { API } from '../constants/api';
import { post } from '../utils/apiHandler';

// eslint-disable-next-line
export const userSignUp = async (data, tenantId, invitationDetails, code, token) => {
  //For testing purpose
  const invitationDetailsLength = Object.keys(invitationDetails).length;
  const isInvitationPresent = invitationDetailsLength > 0 && tenantId;
  try {
    const postData = isInvitationPresent
      ? {
          type: invitationDetails.type,
          email: data.email,
          password: data.password,
          firstName: data.firstName,
          lastName: data.lastName,
          countryCode: data.countryCode,
          mobile: data.phone,
          captchaToken: data.captchaToken,
          workspaceId: invitationDetails.workspaceId,
          tenantId,
          invitationId: invitationDetails.invitationId,
          code: code,
          token: token,
        }
      : tenantId
      ? {
          type: 'clientAdmin',
          email: data.email,
          password: data.password,
          firstName: data.firstName,
          lastName: data.lastName,
          countryCode: data.countryCode,
          mobile: data.phone,
          captchaToken: data.captchaToken,
          tenantId,
          code: code,
          token: token,
          referralCode: data.referralCode,
        }
      : {
          type: 'clientAdmin',
          email: data.email,
          password: data.password,
          firstName: data.firstName,
          lastName: data.lastName,
          countryCode: data.countryCode,
          mobile: data.phone,
          captchaToken: data.captchaToken,
          code: code,
          token: token,
          referralCode: data.referralCode,
        };

    const response = await post(API.signUp, postData);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const verifyRegistrationLink = async (code, token) => {
  try {
    const postData = {
      token,
      code,
    };

    const response = await post(API.verifyLink, postData);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};

export const verifyInvitationLink = async (code, token) => {
  try {
    const postData = {
      token,
      code,
    };

    const response = await post(API.verify_invitation_link, postData);
    return response;
  } catch (error) {
    throw new Error(error.message);
  }
};
