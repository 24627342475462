import { API } from '../../constants/api';
import { get, del } from '../../utils/apiHandler';

export const getProfiles = async (provider, userId, channel) => {
  try {
    const response = await get(`${API.getChannelProfiles}?provider=${provider}&userId=${userId}&channel=${channel}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};

export const removeProfile = async (profileId, channel, email) => {
  try {
    const response = await del(`${API.getChannelProfiles}?channel=${channel}&userProfileId=${profileId}&email=${email}`);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
