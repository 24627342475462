/* eslint-disable no-unused-vars */
import axios from 'axios';
import { BASE_URL, constants } from '../constants/constants';
import { refreshTokens } from '../services/refreshTokenService';
import { getUserInfo, logout } from './getUserInfo';
import { CustomError } from './types';

const axiosInstance = axios.create({
  baseURL: BASE_URL,
});

const onResponseFailed = async error => {
  try {
    if (error?.message?.includes('Network Error')) {
      //return Promise.reject(new Error(constants.error.serverError));
      window.location.pathname = '/error';
    }
    const {
      config: originalRequest,
      response: { status = 0 },
      response: response,
    } = error;
    if (status === 401 && response?.data?.message === constants.error.blockedUser) {
      logout();
      return Promise.reject(new Error(error?.response?.data?.message));
    }
    if (status === 423) {
      setTimeout(() => {
        logout();
      }, 1500);
      return Promise.reject(new Error(error?.response?.data?.message));
    }
    if (
      status === 401 &&
      response?.data?.message == 'Please authenticate' &&
      !originalRequest?.url.includes('login') &&
      !originalRequest?.url.includes('verify-otp')
    ) {
      const userInfo = JSON.parse(getUserInfo());

      const refreshToken = userInfo?.token?.refreshToken;

      const res = await refreshTokens(refreshToken);
      originalRequest.headers.Authorization = `Bearer ${res}`;

      return await axios(originalRequest);
    } else {
      if (error?.response?.data && typeof error?.response?.data?.message === 'string') {
        return Promise.reject(error?.response?.data);
      }
      return Promise.reject(error);
    }
  } catch (error) {
    return Promise.reject(error);
  }
};

const onResponseSuccess = success => {
  return success;
};

const requestHandler = request => {
  const userInfo = JSON.parse(getUserInfo());

  const accessToken = userInfo?.token?.accessToken;
  const refreshToken = userInfo?.token?.refreshToken;

  request.headers.platform = navigator.platform;
  if (accessToken) {
    request.headers.Authorization = `Bearer ${accessToken}`;
    request.headers.RefreshToken = refreshToken;
  }
  return request;
};

const handleAPIError = error => {
  if (axios.isAxiosError(error)) {
    throw new CustomError(error?.message, error?.code);
  } else if (error) {
    if (error?.message === constants.error.undefined) {
      throw new CustomError(constants.error.undefinedMessage, error?.code);
    } else {
      throw new CustomError(error?.message, error?.code);
    }
  } else {
    throw new CustomError(constants.error.undefinedMessage, error?.code);
  }
};

axiosInstance.interceptors.request.use(config => requestHandler(config));
axiosInstance.interceptors.response.use(
  response => onResponseSuccess(response),
  async error => await onResponseFailed(error)
);

export const get = async url => {
  try {
    return await axiosInstance.get(url);
  } catch (error) {
    return handleAPIError(error);
  }
};

export const post = async (url, body = '') => {
  try {
    const response = await axiosInstance.post(url, body);

    return response;
  } catch (error) {
    return handleAPIError(error);
  }
};

export const del = async (url, body) => {
  try {
    const response = await axiosInstance.delete(url, { data: body });
    return response;
  } catch (error) {
    return handleAPIError(error);
  }
};

export const put = async (url, body) => {
  try {
    const response = await axiosInstance.put(url, body);
    return response;
  } catch (error) {
    return handleAPIError(error);
  }
};

export const patch = async (url, body) => {
  try {
    const response = await axiosInstance.patch(url, body);
    return response;
  } catch (error) {
    return handleAPIError(error);
  }
};
