import { API } from "../constants/api";
import { get } from "../utils/apiHandler"

export const getNotifications = async() =>{
    try {
        return (await get(`${API.get_notification}`))?.data;
    } catch (error) {
        throw new Error(error.message);
    }
}
