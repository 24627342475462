import { API } from '../../constants/api';
import { post } from '../../utils/apiHandler';

export const profileUpload = async (profileImage) => {
  try {
    const response = await post(`${API.uploadProfileImage}?context=profile`,profileImage);
    return response;
  } catch (error) {
    throw new Error(error?.message);
  }
};
