import { createContext } from 'react';

export const initialState = {
  auth: {
    user: null,
    isLoggedIn: false,
    errorMessage: '',
    channelCount: null,
    workspaceCount: null,
  },
  login: () => {},
  logout: () => {},
  clearData: () => {},
  handleInitialData: () => {},
  setUserData: () => {},
  setChannelCount: () => {},
  setWorkspaceCount: () => {},
  loading: false,
};

const AuthContext = createContext(initialState);

export default AuthContext;
