import React, { useState } from 'react';
import Icons from '../../../../../assets/Icons';
import { MessageModal } from '../../../../../components/Modal/MessageModal';
import { getChannelDetails } from '../../../../UsersPermissionsChannelView/helper';
import { constants } from '../../../constants';
import { handleCardClose, handleConfirmClick } from '../../../helper';
const CompanyCard = ({
  PageDetails,
  // constants,
  enableClose,
  pageList,
  setPageList,
  droppedData,
  setDroppedData,
  tempId,
  workspaceRender,
  setWorkspaceRender,
  createWorkspaceData,
}) => {
  const [openConfirmationModal, setConfirmationModal] = useState(false);
  return (
    <div
      className="mb-4 w-full cursor-move overflow-hidden rounded-lg border border-solid border-white200 bg-gray-light7"
      draggable
    >
      <div className=" bg-gray-1700 flex items-center justify-between px-2 py-3">
        <div className="flex items-center truncate">
          <div className="bg-gray-1700 flex h-7 w-7 items-center justify-center overflow-hidden rounded-md border border-solid border-white200 object-contain">
            <img
              src={PageDetails.logo}
              alt="company logo"
              onError={event => {
                event.currentTarget.src = getChannelDetails(PageDetails?.channel).imgUrl;
              }}
            />
          </div>
          <h2
            className="ml-2 w-[calc(100%-30px)] truncate pr-2 text-xs font-semibold leading-4.5 text-gray-dark"
            title={PageDetails.title}
          >
            {PageDetails?.title === PageDetails?.accountId && PageDetails?.channel === 'googleads'
              ? `Account Id: ${PageDetails.title.split('/')[1]}`
              : PageDetails?.title}
          </h2>
        </div>
        {enableClose ? (
          <button
            className="pr-3"
            onClick={() => {
              handleCardClose(
                PageDetails,
                pageList,
                setPageList,
                droppedData,
                setDroppedData,
                tempId,
                workspaceRender,
                setWorkspaceRender,
                createWorkspaceData,
                setConfirmationModal
              );
            }}
          >
            <Icons.TrashIcon />
          </button>
        ) : null}
      </div>
      {/* --------------- Needed -------------------*/}
      {/* <div className="flex items-start p-4 text-gray-1800">
        <Icons.LockIcon />
        <div className="ml-2">
          <p className="text-xs font-normal leading-4.5 text-gray-medium">{constants.permissions.title}</p> */}

      {/* {PageDetails.edit ? (
            <p className="text-xs font-medium leading-4.5 text-gray-dark">{constants.permissions.editView}</p>
          ) : (
            <p className="text-xs font-medium leading-4.5 text-gray-dark">{constants.permissions.view}</p>
          )} */}
      {/* <p className="text-xs font-medium leading-4.5 text-gray-dark">-</p>
        </div>
      </div> */}
      {openConfirmationModal && (
        <MessageModal
          isOpen={true}
          setIsOpen={setConfirmationModal}
          message={constants.infoMessages.modalMessage}
          setResetPasswordModalIsOpen={setConfirmationModal}
          heading={constants.label.messageHeading}
          buttonConfirmLabel={constants.button.confirm}
          buttonCancelLabel={constants.button.cancel}
          handleConfirm={() =>
            handleConfirmClick(
              PageDetails,
              droppedData,
              createWorkspaceData,
              setDroppedData,
              workspaceRender,
              setWorkspaceRender,
              tempId,
              pageList,
              setPageList
            )
          }
        />
      )}
    </div>
  );
};
export default CompanyCard;
