import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import classes from './filter.module.scss';
import Icons from '../../assets/Icons';
import { getCompanyList } from '../../features/UserPermissionChannel/helper';
import { getWorkspaceCompanyList } from '../../features/WorkspacesList/helper';
import { constants } from '../../features/CreateWorkspace/constants';

const FilterReorderingDP = ({ setFilter, data, onApply, workspace }) => {
  const [channelList, setChannelList] = useState(data);
  const [companyList, setCompanyList] = useState([]);
  const [companyFilter, setCompanyFilter] = useState({ value: 'All', label: 'All' });

  const handleDragReorder = result => {
    const { source, destination } = result;

    const newData = [...channelList];

    // Reordering the data array
    const draggedItem = newData.splice(source.index, 1)[0];
    newData.splice(destination.index, 0, draggedItem);

    setChannelList(newData);
  };

  const updateCheckState = (e, index) => {
    const newData = channelList.map((item, i) => {
      if (index === i) {
        return { ...item, [e.target.name]: e.target.checked };
      } else {
        return item;
      }
    });
    setChannelList(newData);
  };

  const companyNameChange = e => {
    setCompanyFilter(e);
  };

  useEffect(() => {
    let dropDownList = [];
    if (workspace) {
      dropDownList = getWorkspaceCompanyList(channelList);
      setCompanyList(dropDownList);
    } else {
      dropDownList = getCompanyList(channelList);
      setCompanyList(dropDownList);
    }
  }, []);

  return (
    <div className={`${classes.dropdownCustom} ${classes.bottom} ${classes.mobilePanel}`}>
      <div className={classes.dropdownBackdrop} />
      <div className={classes.dropdownContent}>
        <div className={classes.dropdownHeader}>
          <h3 className={classes.title}>{constants.label.filter}</h3>
          <div className={classes.actiongroup}>
            <button
              className={`btn-icon ${classes.closeBtn}`}
              data-testid="close-button"
              aria-label="Close Filter Alert"
              onClick={() => setFilter(false)}
            >
              <Icons.Cross />
            </button>
          </div>
        </div>
        <div className={classes.dropdownBody}>
          <form>
            <div className={classes.formControlWrapper}>
              <label className={classes.formControlLabel}>Filter by Company</label>
              <Select
                options={companyList}
                placeholder="Select"
                value={companyFilter}
                classNamePrefix="hydra-dropdown"
                onChange={companyNameChange}
              />
            </div>
          </form>
        </div>
        <div className={classes.dropdownReorder}>
          <div className={classes.customReorderTitle}>
            <h3>{constants.label.customReorder}</h3>
            <p>{constants.infoMessages.filter}</p>
          </div>
          <ul className={classes.dragAndDrop} >
            <DragDropContext onDragEnd={handleDragReorder}>
              <Droppable droppableId="dp1">
                {provided => (
                  <div ref={provided.innerRef} {...provided.droppableProps}>
                    {channelList.map((element, index) => {
                      return (
                        <Draggable key={index} draggableId={`${index}`} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              className={snapshot.isDragging ? '!top-[300px] !left-0 !right-0' : ''}
                            >
                              <li role="presentation" key={`uniqueFilterReorder_${index}`}>
                                <button
                                  aria-label="Drag this item"
                                  className={classes.dragHandle}
                                  {...provided.dragHandleProps}
                                >
                                  <Icons.DragHandle />
                                </button>
                                <div className={classes.logo}>
                                  <img src={element.logo} alt={element.channelName} />
                                </div>
                                <div className={classes.title}>{workspace ? element.name : element.channelName}</div>
                                <div className={classes.showStatus}>
                                  <label className="form-checkbox">
                                    <input
                                      className="form-checkbox-input"
                                      type="checkbox"
                                      name="checked"
                                      checked={element.checked}
                                      onChange={e => {
                                        updateCheckState(e, index);
                                      }}
                                    />
                                    <div className="form-check-circle">
                                      <Icons.TickSmall />
                                    </div>
                                  </label>
                                </div>
                              </li>
                              {provided.placeholder}
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </ul>
        </div>
        <div className={classes.dropdownFooter}>
          <div className={classes.buttonGroupRight}>
            <button
              aria-label="Apply this filter"
              className="btn-outline -type-2"
              onClick={() => onApply(channelList, companyFilter)}
            >
              {constants.button.apply}
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FilterReorderingDP;
