/* eslint-disable no-unused-vars */
import { API } from "../constants/api"
import axios from 'axios';
import { BASE_URL } from '../constants/constants';
import { getUserInfo, logout, setUserInfo } from "../utils/getUserInfo";

const axiosInstance = axios.create({
    baseURL: BASE_URL,
  });


export const refreshTokens = async(refreshToken) =>{
    try {
        const body = { refreshToken}
        const response = await axiosInstance.post(`${API.refresh_token}`,body);
        if(response.data?.data?.access){
        const userInfo = JSON.parse(getUserInfo());

        userInfo.token.accessToken = response?.data?.data?.access?.token;
        userInfo.token.refreshToken = response?.data?.data?.refresh?.token;
        setUserInfo(JSON.stringify(userInfo));
        }
        return response.data?.data?.access?.token;
    } catch (error) {
        logout();
        throw new Error(error.message);
    }
}